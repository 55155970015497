import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  DropdownInput,
  RadioInput,
  TextInput,
} from "../../../../../components/inputs";

const ProfessionalExecutor = () => {
  const isJoint = useSelector((state) => state?.instruction?.isJoint);
  const arr = useSelector((state) => state?.instruction?.arr);
  const { willQuestionnaire_executor_type } = useSelector(
    (state) => state?.lookupData
  );
  const { getValues, watch } = useFormContext();

  return (
    <>
      <Grid
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={isJoint ? "space-between" : "center"}
        borderTop={`1px solid #DCDCE1`}
        borderBottom={`1px solid #DCDCE1`}
      >
        {arr?.map((item, index) => {
          let executorTypeName = `InstructionWillQuestionnaire.${index}.professionalExecutor.executors`;
          let selected_executor_type = watch(executorTypeName);

          return (
            <Grid
              width={isJoint ? "50%" : "62%"}
              borderRight={index === 0 && isJoint ? `1px solid #DCDCE1` : null}
              display={"flex"}
              flexDirection={"column"}
              gap={2}
              paddingRight={index === 0 ? 2 : 0}
              paddingLeft={index === 1 ? 2 : 0}
              py={2}
            >
              <Grid
                display={"flex"}
                width={"100%"}
                justifyContent={"space-between"}
                gap={2}
              >
                <Grid width={"48%"}>
                  <DropdownInput
                    name={`InstructionWillQuestionnaire.${index}.professionalExecutor.executors`}
                    label="Select Executor"
                    options={willQuestionnaire_executor_type}
                  />
                </Grid>
                <Grid
                  width={"48%"}
                  display={
                    selected_executor_type === "Squiggle" ? "none" : "flex-item"
                  }
                >
                  <TextInput
                    label={`Mention Full Name`}
                    name={`InstructionWillQuestionnaire.${index}.professionalExecutor.fullName`}
                    placeholder="Enter name"
                  />
                </Grid>
              </Grid>

              <Grid
                display={
                  selected_executor_type === "Squiggle" ? "none" : "flex"
                }
                width={"100%"}
                justifyContent={"space-between"}
                flexWrap={"wrap"}
              >
                <Grid width={"100%"}>
                  <TextInput
                    label={`Address`}
                    name={`InstructionWillQuestionnaire.${index}.professionalExecutor.address.street1`}
                    placeholder="Street 1"
                  />
                </Grid>
                <Grid width={"100%"} pt={1.4}>
                  <TextInput
                    // label={`Mention Full Name`}
                    name={`InstructionWillQuestionnaire.${index}.professionalExecutor.address.street2`}
                    placeholder="Street 2"
                  />
                </Grid>
                <Grid width={"48%"} pt={1.4}>
                  <TextInput
                    // label={`Mention Full Name`}
                    name={`InstructionWillQuestionnaire.${index}.professionalExecutor.address.city`}
                    placeholder="Town"
                  />
                </Grid>
                <Grid width={"48%"} pt={1.4}>
                  <TextInput
                    // label={`Mention Full Name`}
                    name={`InstructionWillQuestionnaire.${index}.professionalExecutor.address.state`}
                    placeholder="County"
                  />
                </Grid>
                <Grid width={"48%"} pt={1.4}>
                  <TextInput
                    // label={`Mention Full Name`}
                    name={`InstructionWillQuestionnaire.${index}.professionalExecutor.address.zip`}
                    placeholder="Post Code"
                  />
                </Grid>
                <Grid width={"48%"} pt={1.4}>
                  <TextInput
                    // label={`Mention Full Name`}
                    name={`InstructionWillQuestionnaire.${index}.professionalExecutor.address.country`}
                    placeholder="Country"
                    disabled={true}
                  />
                </Grid>
              </Grid>

              <RadioInput
                label="Are you aware of fees ?"
                name={`InstructionWillQuestionnaire.${index}.professionalExecutor.feesAwareness`}
                options={[
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ]}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default ProfessionalExecutor;
