import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DataGrid } from "@mui/x-data-grid";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import BackButton from "../../../assets/images/BUTTON_Back.svg";
import Loader from "../../../components/Loader";
import AlertComponent from "../../../components/alert";
import { ContainedButton, OutlinedButton } from "../../../components/buttons";
import { showSnackbar } from "../../../slice/snackbarSlice";
import { createMultipleClients } from "../../../thunks/client";
import { NOTIFICATION_MESSAGE, VALIDATION_MSG } from "../../../utils/constant";
import {
  convertArrayValuesToString,
  emailValidation,
} from "../../../utils/util";

const ImportExcel = () => {
  //hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //
  const lookups = useSelector((state) => state?.lookupData);
  let { "Source Type": sourceType } = lookups || {};
  //states
  const [showAlert, setShowAlert] = useState(false);
  const [disableAddButton, setDisableAddButton] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [isLoader, setLoader] = useState(false);
  const [downloadUnsaved, setDownloadUnsaved] = useState(false);
  const [sourceSubType, setSourceSubType] = useState("");
  const [selectedSourceType, setSelectedSourceType] = useState();
  const inputRef = useRef(null);
  const [rowData, setRowData] = useState([]);
  const [isError, setIsError] = useState(false);

  //Excel sheet columns
  const columns = [
    {
      field: "firstName",
      headerName: "First Name",
      width: 140,
      editable: true,
    },
    {
      field: "middleName",
      headerName: "Middle Name",
      width: 140,
      editable: true,
    },
    { field: "lastName", headerName: "Last Name", width: 140, editable: true },
    { field: "mobileNo", headerName: "Mobile No", width: 130, editable: true },
    {
      field: "postalCode",
      headerName: "Post Code",
      width: 130,
      editable: true,
    },
    { field: "email", headerName: "Email", width: 230, editable: true },
    {
      field: "consultantEmail",
      headerName: "Consultant Email",
      width: 230,
      editable: true,
    },
    {
      field: "lead",
      headerName: "Status",
      editable: false,
    },
    {
      field: "",
      headerName: "Action",
      width: 60,
      editable: false,
      renderCell: (params) => (
        <DeleteOutlinedIcon
          sx={{ color: "#ff0000", textAlign: "center" }}
          onClick={() => {
            setShowAlert(true);
            setSelectedId(params.row.id);
          }}
        >
          Delete
        </DeleteOutlinedIcon>
      ),
    },
  ];

  // excel specific functions
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const binaryString = e.target.result;
      const workbook = XLSX.read(binaryString, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      const excelData = XLSX.utils.sheet_to_json(worksheet);
      if (excelData?.length > 100) {
        dispatch(
          showSnackbar({
            message: NOTIFICATION_MESSAGE?.IMPORT_CLIENT_RECORD_ERROR,
            severity: "error",
          })
        );
      } else {
        // Validate the data
        let temp = excelData?.map((d, index) => {
          return {
            id: index + 1,
            firstName: d["First Name"] || "",
            middleName: d["Middle Name"] || "",
            lastName: d["Last Name"] || "",
            mobileNo: d["Mobile No"] || "",
            postalCode: d["Postal Code"] || "",
            email: d["Email"] || "",
            consultantEmail: d["Consultant Email"] || "",
          };
        });

        const dataWithValidation = validateData(temp);
        setRowData([...dataWithValidation]);
      }
    };

    reader.readAsBinaryString(file);
  };
  // method for validate data
  const validateData = (excelData) => {
    let tempData = excelData?.map((row) => {
      let fieldName = [];
      let error = { value: false, message: "" };

      if (row.firstName === "") {
        error.value = true;
        fieldName.push("firstName");
        error.message = "First Name is missing";
      }

      if (!row.lastName) {
        error.value = true;
        fieldName.push("lastName");
        error.message = "Last Name is missing";
      }
      if (row.email && !emailValidation(row.email)) {
        error.value = true;
        fieldName.push("email");
        error.message = "Email format is invalid";
      }
      if (row.consultantEmail && !emailValidation(row.consultantEmail)) {
        error.value = true;
        fieldName.push("consultantEmail");
        error.message = "Consultant Email format is invalid";
      }

      if (!row.mobileNo) {
        error.value = true;
        fieldName.push("mobileNo");
        error.message = "Required mobile number";
      }

      error = { ...error, fieldName };

      return { ...row, error };
    });

    return tempData;
  };

  // export users into excel file
  const jsonToExcel = (inputData = []) => {
    let userData = [{}];
    let data1 = inputData?.length ? inputData : userData;
    if (inputData?.length === 0 || downloadUnsaved) {
      dispatch(
        showSnackbar({
          message: NOTIFICATION_MESSAGE?.DATA_NOT_AVAILABLE,
          severity: "error",
        })
      );
      return;
    }
    let jsonData = JSON.parse(JSON.stringify(data1));
    let requiredUserData = jsonData?.map((d, index) => ({
      "First Name": d?.middleName || "",
      "Last Name": d?.lastName || "",
      "Mobile No": d?.mobileNo || "",
      "Postal Code": d?.postalCode || "",
      Email: d?.email || "",
      "Consultant Email": d?.consultantEmail || "",
    }));

    try {
      // Create a worksheet
      const ws = XLSX.utils.json_to_sheet(requiredUserData);

      // Create a workbook
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      // Generate a Blob containing the Excel file
      const blob = XLSX.writeFile(wb, "ClientList.xlsx", {
        compression: true,
      });

      // Create a URL for the Blob
      const blobUrl = URL.createObjectURL(blob);

      // Create a temporary download link
      const downloadLink = document.createElement("a");
      downloadLink.href = blobUrl;
      downloadLink.download = "data.xlsx";

      // Trigger the download
      downloadLink.click();
    } catch (error) {}
  };

  const downloadErrorData = (inputData) => {
    let jsonData = JSON.parse(JSON.stringify(inputData));
    let requiredUserData = jsonData?.map((d, index) => ({
      "First Name": d?.middleName || "",
      "Middle Name": d?.middleName || "",
      "Last Name": d?.lastName || "",
      "Mobile No": d?.mobileNo || "",
      "Postal Code": d?.postalCode || "",
      Email: d?.email || "",
      "Consultant Email": d?.consultantEmail || "",
    }));

    try {
      // Create a worksheet
      const ws = XLSX.utils.json_to_sheet(requiredUserData);

      // Create a workbook
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      // Generate a Blob containing the Excel file
      const blob = XLSX.writeFile(wb, "Unsaved Data.xlsx", {
        compression: true,
      });

      // Create a URL for the Blob
      const blobUrl = URL.createObjectURL(blob);

      // Create a temporary download link
      const downloadLink = document.createElement("a");
      downloadLink.href = blobUrl;
      downloadLink.download = "data.xlsx";

      // Trigger the download
      downloadLink.click();
    } catch (error) {}
  };

  // Get count of strings in error fieldName for all objects
  const totalFieldNameCount = rowData?.reduce(
    (total, item) => total + item.error?.fieldName?.length,
    0
  );

  const customStyles = makeStyles({
    root: (props) => ({
      "error-row": {
        backgroundColor: "#ff0000",
      },
    }),
    yellowRow: {
      backgroundColor: "yellow",
    },
    lightRed: {
      backgroundColor: "rgb(255, 205, 205)",
      marginTop: "5px",
      marginBottom: "5px",
      paddingBottom: "5px",
      borderRadius: "10px",
      border: "none",
      borderBottom: "none", // Remove the border between rows
    },
    textRed: {
      color: "#ff0000",
      fontWeight: 600,
    },
  });

  const classes = customStyles();

  const handleDelete = () => {
    let temp = rowData?.filter((obj, index) => obj.id !== selectedId);
    setRowData([...temp]);
    setSelectedId("");
    setShowAlert(false);
    if (!temp.length) {
      inputRef.current.value = null;
    }
  };
  // method for edit excel sheet cell
  const handleEditCellChange = (params, event) => {
    if (event && event.target && event.target.value !== undefined) {
      const updatedRows = rowData?.map((row) => {
        if (row.id === params.id) {
          return { ...params.row, [params.field]: event.target.value || "" };
        }
        return row;
      });

      let dataWithValidation = validateData([...updatedRows]);
      setRowData([...dataWithValidation]);
    } else {
    }
  };

  const processResponse = (data) => {
    let dataWithError = data?.filter((obj) => obj.hasOwnProperty("lead"));

    let errorValue = false;
    let temp = dataWithError.map((objectData, index) => {
      let tempObj = objectData;

      errorValue = true;
      tempObj = {
        id: index + 1,
        ...tempObj,
        error: {
          value: false,
          message: "",
          fieldName: ["lead"],
        },
        status: "Failed",
      };
      return tempObj;
    });

    setRowData([...temp]);
    return [errorValue, temp.length];
  };

  // handle save

  const handleSave = async () => {
    if (!sourceType || !sourceSubType || handleDisableAddButton()) {
      setIsError(true);
      dispatch(
        showSnackbar({
          message: NOTIFICATION_MESSAGE?.RESOLVED_ERROR_OR_REQUIRED,
          severity: "error",
        })
      );
    } else {
      setIsError(false);
      try {
        setLoader(true);
        let payload = rowData?.map((obj, index) => {
          let { id, error, ...others } = obj;
          return others;
        });
        let convertedToString = convertArrayValuesToString(payload);

        let finalPayload = {
          leadClientCreate: convertedToString,
          sourceType: selectedSourceType.name,
          sourceTypeLookupId: selectedSourceType.id,
          sourceSubType: sourceSubType,
        };
        const response = await dispatch(
          createMultipleClients(finalPayload)
        ).unwrap();

        let checkResponse = await processResponse(response);
        if (!checkResponse[0]) {
          dispatch(
            showSnackbar({
              message: NOTIFICATION_MESSAGE?.DATA_SAVE_SUCCESS,
              severity: "success",
            })
          );
          navigate("/app/client/list");
        } else {
          dispatch(
            showSnackbar({
              message: `Failed to save ${checkResponse[1]} out of ${response?.length} client data`,
              severity: "error",
              duration: 5000,
            })
          );

          setDisableAddButton(true);
          setTimeout(() => {
            setDisableAddButton(false);
          }, 6000);
        }
      } catch (error) {
        setIsError(false);
        dispatch(
          showSnackbar({
            message: error?.message,
            severity: "error",
          })
        );
      } finally {
        setLoader(false);
      }
    }
  };
  // handle change instruction type
  const handleChangeInstructionType = async (value) => {
    let findSourceObj = sourceType.find((item, index) => item.name === value);
    if (findSourceObj) {
      setSelectedSourceType(findSourceObj);
    }
  };
  // handle disable add button
  const handleDisableAddButton = () => {
    let emptyValueArray = [null, undefined, "", "null"];

    let isFileUploaded =
      !emptyValueArray.includes(inputRef.current?.value) || false;

    let dataLength = rowData?.length > 0;

    let isError = rowData?.filter((obj) => obj.error.value === true).length > 0;

    let arr = [isFileUploaded, !isError, dataLength, !disableAddButton];

    return arr.includes(false) ? true : false;
  };
  //  handle download unsaved
  const handleDownloadUnsaved = () => {
    let payload = rowData?.filter((obj, index) => obj.status !== "Success");
    let temp = downloadErrorData(payload);
  };

  return (
    <>
      {isLoader && <Loader />}
      {/* notification component */}
      <AlertComponent
        open={showAlert}
        handleSuccess={handleDelete}
        handleClose={() => setShowAlert(false)}
        message="Are you sure you want to delete ?"
      />
      {/* Main Layout Container */}
      <Grid container spacing={3}>
        <Grid item xs={12} display={"flex"} flexDirection={"row"}>
          <Grid
            item
            xs={2.5}
            p={1}
            className="InstructionDashboardWrapper instruction_dashboard_scrollbar"
            style={{
              overflow: "auto",
              height: "100vh",
              marginLeft: "-12px",
              paddingTop: "10px",
              marginRight: "10px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ display: "flex", gap: 10 }}>
                {/* import list label and back button */}
                <img
                  src={BackButton}
                  onClick={() => navigate("/app/instructions/list")}
                  style={{ cursor: "pointer", marginLeft: "10px" }}
                />
                <Typography className="serif_display_regular_26">
                  Import List
                </Typography>
              </Grid>

              {/* Source Type */}

              <Grid item xs={12} mt={3}>
                <FormControl
                  required
                  error={isError && !selectedSourceType?.name}
                  fullWidth
                  size="small"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "30px",
                      backgroundColor: "white",
                    },
                  }}
                >
                  <InputLabel className="normal_normal_medium_14_Manrope dark_grey">
                    Source Type
                  </InputLabel>
                  <Select
                    className="textField_outlined_border"
                    label="Source Type"
                    value={selectedSourceType?.name}
                    onChange={(e) => {
                      handleChangeInstructionType(e?.target?.value);
                    }}
                  >
                    {sourceType?.map((d) => {
                      return (
                        <MenuItem
                          key={d?.id}
                          value={d?.name}
                          className="normal_normal_medium_14_Manrope dark_grey"
                        >
                          {d?.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {isError && !selectedSourceType?.name && (
                    <FormHelperText className="red">
                      {VALIDATION_MSG.REQUIRED}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              {/* Source Sub Type */}

              <Grid item xs={12} mt={1}>
                <TextField
                  label="Source sub type"
                  required
                  error={isError && !sourceSubType}
                  helperText={
                    isError && !sourceSubType && VALIDATION_MSG.REQUIRED
                  }
                  fullWidth
                  size="small"
                  className="normal_normal_medium_14_Manrope dark_grey border_style inputTextField"
                  placeholder="Source Sub Type"
                  value={sourceSubType}
                  onChange={(e) => setSourceSubType(e.target.value)}
                  variant="standard"
                />
              </Grid>

              {/* Upload Excel Sheet  */}

              <Grid item xs={12} mt={1}>
                <Grid
                  item
                  xs={10}
                  sx={{
                    padding: 5,
                    boxSizing: "border-box",
                    borderRadius: "20px",
                    backgroundColor: "white",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginLeft: "16px",
                    gap: 1,
                  }}
                >
                  {[null, undefined, ""].includes(inputRef.current?.value) ? (
                    <>
                      <FileUploadOutlinedIcon
                        onClick={() => {
                          inputRef.current.click();
                        }}
                        sx={{
                          fontSize: "60px",
                          cursor: "pointer",
                          color: "#00CE3F",
                        }}
                      />
                      <Typography
                        className="normal_normal_semibold_14_Manrope dark_grey"
                        sx={{ textAlign: "center" }}
                      >
                        UPLOAD EXCEL SHEET
                      </Typography>
                    </>
                  ) : (
                    <Grid
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"center"}
                    >
                      <Typography
                        sx={{
                          textAlign: "center",
                          color: "#00CE3F",
                          fontSize: "14px",
                        }}
                      >
                        File Name :
                      </Typography>
                      <Typography sx={{ textAlign: "center" }}>
                        {inputRef.current?.files[0]?.name}
                      </Typography>
                      <HighlightOffOutlinedIcon
                        onClick={() => {
                          setDownloadUnsaved(false);
                          inputRef.current.value = null;
                          setRowData([]);
                        }}
                        sx={{
                          fontSize: "40px",
                          cursor: "pointer",
                          color: "#ff0000",
                        }}
                      />
                    </Grid>
                  )}
                  <input
                    type="file"
                    ref={inputRef}
                    // src={userImage}
                    style={{ display: "none" }}
                    // onChange={handleImageUpload}
                    id="excelFile"
                    label="Choose an Excel file"
                    onChange={handleFileChange}
                    accept=".xlsx"
                  />
                </Grid>
              </Grid>

              {/* Download Sample Button */}

              <Grid item xs={12} mt={5}>
                <Grid item xs={12} textAlign={"center"}>
                  <button
                    onClick={jsonToExcel}
                    className="normal_normal_semibold_14_Manrope dark_grey roundOutlinedButton"
                  >
                    Download Sample
                  </button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Table Content */}
          <Grid item xs={9.5}>
            {rowData?.length ? (
              <Grid sx={{ width: "100%", height: "75vh" }}>
                <DataGrid
                  style={{
                    border: "0px solid red",
                    margin: 0,
                    padding: 0,
                    borderRadius: 0,
                  }}
                  className="normal_normal_16_Manrope dark_grey"
                  rows={rowData}
                  columns={columns}
                  getRowClassName={(params) => {
                    if (params.row.error.value === true) {
                      return classes.lightRed; // Ensure that the class name is directly returned
                    }
                    return ""; // Ensure that an empty string is returned for no class
                  }}
                  getCellClassName={(params) => {
                    return params.row.error.fieldName?.includes(params.field)
                      ? classes.textRed
                      : "";
                  }}
                  sx={{
                    "& .MuiDataGrid-row": {
                      height: "10px",
                      padding: "0px",
                    },
                    "& .MuiDataGrid-row:hover": {
                      backgroundColor: "#E5FAEB",
                      cursor: "pointer",
                      borderRadius: "0px",
                    },
                  }}
                  hideFooter
                  columnHeaderHeight={40}
                  density="standard"
                  stickyHeader
                  onCellEditStop={handleEditCellChange}
                />
              </Grid>
            ) : (
              <Grid
                sx={{
                  width: "100%",
                  height: "88%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="normal_normal_18_Manrope dark_grey"
              >
                Import file to view data
              </Grid>
            )}
            <Grid
              sx={{
                width: "100%",
                height: "12%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Grid className="normal_normal_bold_18_Manrope">
                <span style={{ color: "#ff0000" }}>
                  {totalFieldNameCount}{" "}
                  {totalFieldNameCount > 1 ? "Errors" : "Error"}
                </span>
                <span> / </span>
                <span>
                  {rowData?.length} {rowData > 1 ? "Rows" : "Row"}
                </span>
              </Grid>
              <Grid sx={{ ml: 3, mr: 2 }}>
                {downloadUnsaved ? (
                  <OutlinedButton
                    label="Download Unsaved data"
                    color="#3D4740"
                    onClick={handleDownloadUnsaved}
                  />
                ) : (
                  <ContainedButton
                    disabled={!inputRef.current?.value}
                    label="Add to Client's list"
                    onClick={handleSave}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ImportExcel;
