import { Grid } from "@mui/material";
import { useFieldArray } from "react-hook-form";
import { useSelector } from "react-redux";
import { AddButton, RemoveButton } from "../../../../../components/buttons";
import { RadioInput, TextInput } from "../../../../../components/inputs";
import { VALIDATION_MSG } from "../../../../../utils/constant";
import {
  attorneyAppointFor,
  attorneyObject,
} from "./lpaQuestionnaireFormConstants";

const AttorneysItem = () => {
  const isJoint = useSelector((state) => state?.instruction?.isJoint);
  const arr = useSelector((state) => state?.instruction?.arr);

  const client1 = useFieldArray({
    name: `InstructionLPA_Questionnaire.[0].attorneys`,
  });

  const { append, remove, fields } = client1;

  const client2 = useFieldArray({
    name: `InstructionLPA_Questionnaire.[1].attorneys`,
  });

  const {
    append: secondaryAppend,
    remove: secondaryRemove,
    fields: secondaryFields,
  } = client2;

  const handleAdd = (clientNumber, arrayLength) => {
    clientNumber === 0
      ? append(arrayLength === 0 ? attorneyObject : attorneyObject)
      : secondaryAppend(arrayLength === 0 ? attorneyObject : attorneyObject);
  };

  const handleRemove = (clientNumber, ind) => {
    if (clientNumber === 1) {
      remove(ind);
    } else {
      secondaryRemove(ind);
    }
  };
  return (
    <>
      <Grid width={"100%"} display={"flex"} flexDirection={"row"}>
        {arr?.map((item, index) => {
          return (
            <Grid
              width={isJoint ? "50%" : "100%"}
              borderTop={`1px solid #DCDCE1`}
              borderBottom={`1px solid #DCDCE1`}
              borderRight={index === 0 ? `1px solid #DCDCE1` : null}
              paddingY={4}
              display={"flex"}
              flexDirection={"column"}
              gap={3}
              paddingRight={index === 0 ? 2 : 0}
              paddingLeft={index === 1 ? 2 : 0}
            >
              {(index === 0 ? fields : secondaryFields).map((card, ind) => {
                return (
                  <AttorneysForm
                    key={card.id}
                    isJoint={isJoint}
                    index={index}
                    cardNo={ind}
                    handleRemove={() => handleRemove(item, ind)}
                    pathName={`InstructionLPA_Questionnaire.${index}.attorneys.${ind}`}
                  />
                );
              })}
              <Grid textAlign={"center"}>
                <AddButton
                  onClick={() =>
                    handleAdd(
                      index,
                      (item === 1 ? fields : secondaryFields).length
                    )
                  }
                />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default AttorneysItem;

const AttorneysForm = ({ index, cardNo, handleRemove, pathName }) => {
  // const { getValues, setValue } = useFormContext();
  // const handleJoint = () => {
  //   let isJoint = getValues(`${pathName}.isJoint`) || false;
  //   setValue(`${pathName}.isJoint`, !isJoint);
  // };

  // const isCardJoint = useWatch({
  //   name: `${pathName}.isJoint`,
  // });

  let isClient2 = index == 1;

  return (
    <Grid
      boxShadow={"1px 2px 6px #00000029"}
      borderBottom={`1px solid #DCDCE1`}
      // border={"2px solid red"}
      borderRadius={"16px"}
      borderRight={index === 0 ? `1px solid #DCDCE1` : null}
      padding={2}
      paddingY={3}
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      position={"relative"}
    >
      <TextInput
        label={`Full Name`}
        name={`InstructionLPA_Questionnaire.${index}.attorneys.[${cardNo}].fullName`}
        placeholder="Full Name"
        rules={{
          required: {
            value: !isClient2,
            message: VALIDATION_MSG?.REQUIRED,
          },
        }}
      />

      <RadioInput
        label="Appoint for"
        name={`InstructionLPA_Questionnaire.${index}.attorneys.[${cardNo}].appointFor`}
        options={attorneyAppointFor}
        orientation="column"
      />

      <div style={{ textAlign: "right" }}>
        <RemoveButton label={"Joint"} onClick={handleRemove} />
      </div>
    </Grid>
  );
};
