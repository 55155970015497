import { createSlice } from "@reduxjs/toolkit";
import {
  createProduct,
  getProduct,
  getProductById,
  getProductDisbursementAsPerInstructionType,
  getProductQuestionnaire,
  getProductQuestionnaireAsPerInstructionType,
  getProductTaskAsPerInstructionType,
  updateProduct,
  updateProductTask,
} from "../thunks/product";

const productDataSlice = createSlice({
  name: "productData",
  initialState: {
    loading: false,
    productsData: [],
    productQuestionnaire: [],
    createdOrUpdatedProduct: {},
    productTasks: [],
    disbursementData: [],
  },
  extraReducers: (builder) => {
    builder

      // Get Product thunk
      .addCase(getProduct.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(getProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.productsData = action?.payload;
      })

      .addCase(getProduct.rejected, (state, action) => {
        state.loading = false;
        state.productsData = [];
      })

      // Get Product Questionnaire As Per Instruction Type thunk
      .addCase(
        getProductQuestionnaireAsPerInstructionType.pending,
        (state, action) => {
          state.loading = true;
        }
      )

      .addCase(
        getProductQuestionnaireAsPerInstructionType.fulfilled,
        (state, action) => {
          state.loading = false;
          state.productQuestionnaire = action?.payload;
        }
      )

      .addCase(
        getProductQuestionnaireAsPerInstructionType.rejected,
        (state, action) => {
          state.loading = false;
          state.productQuestionnaire = [];
        }
      )

      // Create Product thunk
      .addCase(createProduct.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(createProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.createdOrUpdatedProduct = action?.payload;
      })

      .addCase(createProduct.rejected, (state, action) => {
        state.loading = false;
        state.createdOrUpdatedProduct = {};
      })

      // Update Product thunk
      .addCase(updateProduct.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(updateProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.createdOrUpdatedProduct = action?.payload;
      })

      .addCase(updateProduct.rejected, (state, action) => {
        state.loading = false;
        state.createdOrUpdatedProduct = {};
      })

      // Get Product Task As Per Instrution Type thunk
      .addCase(getProductTaskAsPerInstructionType.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(
        getProductTaskAsPerInstructionType.fulfilled,
        (state, action) => {
          state.loading = false;
          state.productTasks = action?.payload;
        }
      )

      .addCase(getProductTaskAsPerInstructionType.rejected, (state, action) => {
        state.loading = false;
        state.productTasks = [];
      })

      // Get Disbusment As Per Instruction Type thunk
      .addCase(
        getProductDisbursementAsPerInstructionType.pending,
        (state, action) => {
          state.loading = true;
        }
      )

      .addCase(
        getProductDisbursementAsPerInstructionType.fulfilled,
        (state, action) => {
          state.loading = false;
          state.disbursementData = action?.payload;
        }
      )

      .addCase(
        getProductDisbursementAsPerInstructionType.rejected,
        (state, action) => {
          state.loading = false;
          state.disbursementData = [];
        }
      )

      // Get Product By ID thunk
      .addCase(getProductById.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(getProductById.fulfilled, (state, action) => {
        state.loading = false;
      })

      .addCase(getProductById.rejected, (state, action) => {
        state.loading = false;
      })

      // Get All Product thunk
      .addCase(getProductQuestionnaire.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(getProductQuestionnaire.fulfilled, (state, action) => {
        state.loading = false;
      })

      .addCase(getProductQuestionnaire.rejected, (state, action) => {
        state.loading = false;
      })

      // Update Product task
      .addCase(updateProductTask.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(updateProductTask.fulfilled, (state, action) => {
        state.loading = false;
      })

      .addCase(updateProductTask.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default productDataSlice.reducer;
