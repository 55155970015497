export const dependentObject = {
  isJoint: null,
  fullName: "",
  gender: "",
  dob: "",
  capacityIssues: false,
  appointGuardians: false,
  appointAsBeneficiary: false,
  other_config: { isWeb: true },
};

export const giftObject = {
  to: "",
  date: "",
  value: "",
};

export const propertyObject = {
  type: "",
  address: "",
  isJoint: "",
  tenentType: "",
  isRegistered: "",
  value: "",
  mortgage: "",
  term: "",
  netValue: "",
  note: "",
};

export const savingObject = {
  isJoint: null,
  savingsArea: "",
  account: "",
  balance: "",
};

export const sharesAndInvestmentObject = {
  isJoint: null,
  savingArea: "",
  inheritanceTaxTreatment: "",
  description: "",
  value: "",
};

export const lifeInsurenceObject = {
  isJoint: null,
  type: "",
  provider: "",
  isUptoDateDeathBenefitForms: "",
  value: "",
};

export const assetObject = {
  isJoint: null,
  type: "",
  description: "",
  value: "",
};

export const businessAssetObject = {
  isJoint: null,
  companyName: "",
  percentageShare: "",
  isTrading: "",
  shareValue: "",
  includedInEstate: "",
  notIncludedInEstate: "",
  partnership: "",
  properties: "",
};
export const businessProperyState = {
  businessProperty: "",
  netValue: null,
  mortgage: null,
  notIncludedInEstate: null,
  includedInEstate: null,
};

export const pensionObject = {
  type: "",
  status: "",
  provider: "",
  isUptoDateDeathBenefitForms: "",
  value: "",
};

export const liabilitiesObject = {
  type: "",
  lender: "",
  value: "",
};

export const beneficiariesObject = {
  isJoint: null,
  name: "",
  gender: "",
  dob: "",
  relationship: "",
  Vulnerable: "",
  notes: "",
  other_config: { isWeb: true },
};

export const exclusionsObject = {
  isMirror: null,
  name: "",
  gender: "",
  dob: "",
  relationship: "",
  reasonForExclusion: "",
  other_config: { isWeb: true },
};
