import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { RadioInput, TextInput } from "../../../../../components/inputs";

const TestatorItem = () => {
  const isJoint = useSelector((state) => state?.instruction?.isJoint);
  const arr = useSelector((state) => state?.instruction?.arr);

  return (
    <>
      <Grid
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={isJoint ? "space-between" : "center"}
        borderTop={`1px solid #DCDCE1`}
        borderBottom={`1px solid #DCDCE1`}
      
      >
        {arr?.map((item, index) => {
          return (
            <Grid
              width={isJoint ? "50%" : "62%"}
              borderRight={index === 0 && isJoint ? `1px solid #DCDCE1` : null}
              paddingRight={index === 0 ? 2 : 0}
              paddingLeft={index === 1 ? 2 : 0}
              display={"flex"}
              flexDirection={"column"}
              gap={2}
              py={4}
            >
              <TextInput
                label={`Testator ${index + 1}`}
                name={`InstructionWillQuestionnaire.[${index}].testators.fullName`}
                placeholder="Enter name"
              />
              <RadioInput
                label="Do you wish your Spouse/partner to be an Executor/Trustee?"
                name={`InstructionWillQuestionnaire.${index}.testators.makeExecutorOrTrustee`}
                options={[
                  { label: "Jointly", value: "Jointly" },
                  { label: "Solely", value: "Solely" },
                  { label: "No", value: "No" },
                ]}
              />
              {index === 0 ? (
                <RadioInput
                  label="Marriage Clause"
                  name={`InstructionWillQuestionnaire.${index}.testators.marriageClause`}
                  options={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                />
              ) : null}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default TestatorItem;
