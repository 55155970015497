import { Grid, Typography } from "@mui/material";
import React from "react";

import { useSelector } from "react-redux";
import {
  DropdownInput,
  RadioInput,
  TextInput,
} from "../../../../../components/inputs";
import { VALIDATION_MSG, YES_NO_DATA } from "../../../../../utils/constant";

const BasicDetailForm = () => {
  const isJoint = useSelector((state) => state?.instruction?.isJoint);
  const arr = useSelector((state) => state?.instruction?.arr);
  let RELATIONSHIP = useSelector((state) => state?.lookupData?.RELATIONSHIP);
  const applicationType = useSelector(
    (state) => state?.instruction?.tempInstructionData?.applicationType
  );

  const prefix_client1 = "InstructionClients[0]";
  const prefix_client2 = "InstructionClients[1]";
  return (
    <>
      <Grid container spacing={3} mt={5} paddingX={2} paddingY={3}>
        <Grid item xs={6}>
          <Typography className="normal_normal_600_16_Manrope green">
            Application Type
          </Typography>
          <Typography className="normal_normal_18_Manrope dark_grey">
            {applicationType || "NA"}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          {isJoint ? (
            <>
              <Typography className="normal_normal_600_16_Manrope green">
                Relationship
              </Typography>
              <DropdownInput
                name={
                  prefix_client2 +
                  ".InstructionFactFind.clientDetails.relationship"
                }
                label=""
                options={RELATIONSHIP}
                rules={{
                  required: {
                    value: true,
                    message: VALIDATION_MSG.REQUIRED,
                  },
                }}
              />
            </>
          ) : (
            <>
              <RadioInput
                label={`Have you been widowed`}
                name={`${prefix_client1}.InstructionFactFind.clientDetails.isWidowed`}
                options={YES_NO_DATA}
              />
            </>
          )}
        </Grid>

        {/* Factfind client name details with firstName, middleName, lastName */}
        {arr?.length > 0 &&
          arr?.map((d, ind) => (
            <>
              <Grid
                key={d}
                item
                xs={6}
                style={{
                  borderRight: ind === 0 && isJoint ? "1px solid #DCDCE1" : "",
                }}
              >
                {isJoint ? (
                  <Typography className="instruction_fact_find_client_heading_typo">
                    Client {ind + 1}
                  </Typography>
                ) : null}
                <Grid container spacing={3} mt={1}>
                  <Grid item xs={4}>
                    <TextInput
                      label={`First Name`}
                      name={`InstructionClients[${ind}].firstName`}
                      placeholder="First Name"
                      rules={{
                        required: {
                          value: true,
                          message: VALIDATION_MSG.REQUIRED,
                        },
                      }}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextInput
                      label={`Middle Name`}
                      name={`InstructionClients[${ind}].middleName`}
                      placeholder="Middle Name"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4} pr={1}>
                    <TextInput
                      label={`Last Name`}
                      name={`InstructionClients[${ind}].lastName`}
                      placeholder="Last Name"
                      disabled
                      rules={{
                        required: {
                          value: true,
                          message: VALIDATION_MSG.REQUIRED,
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          ))}
      </Grid>
    </>
  );
};

export default BasicDetailForm;
