import { Grid, Typography } from "@mui/material";
import { useFieldArray } from "react-hook-form";
import { AddButton, RemoveButton } from "../../../../../../components/buttons";
import {
  NumberInput,
  RadioInput,
  TextInput,
} from "../../../../../../components/inputs";
import { VALIDATION_FORMAT } from "../../../../../../utils/constant";
import { beneficiariesObject } from "../willQuestionnaireFormConstants";

const FlitItem = ({ arr, isJoint }) => {
  const client1 = useFieldArray({
    name: `InstructionWillQuestionnaire.[0].willTrusts.flexibleLifeInterestTrust.beneficiary`,
  });

  const { append, remove, fields } = client1;

  const client2 = useFieldArray({
    name: `InstructionWillQuestionnaire.[1].willTrusts.flexibleLifeInterestTrust.beneficiary`,
  });

  const {
    append: secondaryAppend,
    remove: secondaryRemove,
    fields: secondaryFields,
  } = client2;

  const handleAdd = (clientNumber) => {
    clientNumber === 0
      ? append(beneficiariesObject)
      : secondaryAppend(beneficiariesObject);
  };

  const handleRemove = (clientNumber, ind) => {
    if (clientNumber === 1) {
      remove(ind);
    } else {
      secondaryRemove(ind);
    }
  };

  return (
    <>
      <Grid width={"100%"}>
        <Grid
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          borderBottom={`1px solid #DCDCE1`}
          padding={2}
        >
          <Grid
            width={"85%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            gap={1}
          >
            <Typography>
              Flexible Life Interest Trust “FLIT” (Recommended if the client
              have their own "What if...?")
            </Typography>
            <Typography sx={{ color: "#00CE3F", fontSize: "12px" }}>
              Giving a person a right to reside for a set period of time after
              death of both parents.
            </Typography>
          </Grid>
          <Grid width={"15%"}>
            <RadioInput
              label=""
              name={`InstructionWillQuestionnaire[0].willTrusts.isFLIT`}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
            />
          </Grid>
        </Grid>

        <Grid
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={isJoint ? "space-between" : "center"}
          borderTop={`1px solid #DCDCE1`}
          borderBottom={`1px solid #DCDCE1`}
        >
          {arr?.map((item, index) => {
            return (
              <Grid
                width={isJoint ? "50%" : "62%"}
                borderRight={
                  index === 0 && isJoint ? `1px solid #DCDCE1` : null
                }
                padding={2}
                py={4}
                display={"flex"}
                flexDirection={"column"}
                gap={2}
              >
                <TextInput
                  label={`Name of Life Tenant`}
                  name={`InstructionWillQuestionnaire.[${index}].willTrusts.flexibleLifeInterestTrust.lifeTenantName`}
                  placeholder="Enter name"
                />
                <Grid display={"flex"} flexDirection={"column"} gap={2}>
                  <Typography sx={{ fontSize: "12px", color: "#00CE3F" }}>
                    Life Interest to cease upon
                  </Typography>

                  <Grid display={"flex"} justifyContent={"space-between"}>
                    <Grid width={"65%"}>Death of Life Tenant</Grid>
                    <Grid width={"30%"}>
                      <RadioInput
                        label=""
                        name={`InstructionWillQuestionnaire.[${index}].willTrusts.flexibleLifeInterestTrust.lifeTenantDeath`}
                        options={[
                          { label: "Yes", value: true },
                          { label: "No", value: false },
                        ]}
                      />
                    </Grid>
                  </Grid>
                  <Grid display={"flex"} justifyContent={"space-between"}>
                    <Grid width={"65%"}>Specific Age</Grid>
                    <Grid width={"30%"}>
                      <TextInput
                        label=""
                        name={`InstructionWillQuestionnaire.[${index}].willTrusts.flexibleLifeInterestTrust.specificAge.age`}
                        placeholder="Enter age"
                      />
                    </Grid>
                  </Grid>
                  <Grid display={"flex"} justifyContent={"space-between"}>
                    <Grid width={"65%"}>Marriage/Remarriage</Grid>
                    <Grid width={"30%"}>
                      <RadioInput
                        label=""
                        name={`InstructionWillQuestionnaire.[${index}].willTrusts.flexibleLifeInterestTrust.marriageOrRemarriage`}
                        options={[
                          { label: "Yes", value: true },
                          { label: "No", value: false },
                        ]}
                      />
                    </Grid>
                  </Grid>
                  <Grid display={"flex"} justifyContent={"space-between"}>
                    <Grid width={"65%"}>Cohabitation with new partner</Grid>
                    <Grid width={"30%"}>
                      <RadioInput
                        label=""
                        name={`InstructionWillQuestionnaire.[${index}].willTrusts.flexibleLifeInterestTrust.cohabitationWithNewPartner`}
                        options={[
                          { label: "Yes", value: true },
                          { label: "No", value: false },
                        ]}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid width={"100%"} display={"flex"} flexDirection={"row"}>
                  <Grid
                    width={"100%"}
                    paddingY={4}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={3}
                  >
                    {(index === 0 ? fields : secondaryFields).map(
                      (card, ind) => {
                        return (
                          <FlitForm
                            isJoint={isJoint}
                            index={index}
                            cardNo={ind}
                            handleRemove={() => handleRemove(item, ind)}
                            pathName={`InstructionWillQuestionnaire.[${index}].willTrusts.flexibleLifeInterestTrust.beneficiary.[${ind}]`}
                          />
                        );
                      }
                    )}
                    <Grid textAlign={"center"}>
                      <AddButton onClick={() => handleAdd(index)} />
                    </Grid>
                  </Grid>
                  {/* ); */}
                  {/* })} */}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
};

export default FlitItem;

const FlitForm = ({ index, cardNo, handleRemove, pathName }) => {
  return (
    <Grid
      boxShadow={"1px 2px 6px #00000029"}
      borderRadius={"16px"}
      borderRight={index === 0 ? `1px solid #DCDCE1` : null}
      padding={2}
      paddingY={3}
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      position={"relative"}
    >
      <TextInput
        label={` Beneficiary ${cardNo + 1}`}
        name={`${pathName}.ultimateBeneficiary`}
        placeholder="Enter name"
      />
      <Grid width={"100%"}>
        <RadioInput
          label="Beneficiaries Type"
          name={`${pathName}.classification`}
          options={[
            { label: "Named Beneficiary", value: "Named Beneficiary" },
            { label: "Class of Beneficiary", value: "Class of Beneficiary" },
          ]}
        />
      </Grid>
      <Grid
        display={"flex"}
        width={"100%"}
        justifyContent={"space-between"}
        gap={2}
      >
        <Grid width={"32%"}>
          <RadioInput
            label="Per Stirpes"
            name={`${pathName}.perStripes`}
            options={[
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
          />
        </Grid>
        <Grid width={"30%"}>
          <TextInput
            label="Age of Inheritance"
            name={`${pathName}.ageOfInheritance`}
            placeholder="Enter age"
          />
        </Grid>
        <Grid width={"32%"}>
          <NumberInput
            name={`${pathName}.percentageShare`}
            label="Percentage Share"
            placeholder="Percentage Share"
            rules={{
              pattern: {
                value: VALIDATION_FORMAT.percentageOnly.pattern,
                message: VALIDATION_FORMAT.percentageOnly.message,
              },
            }}
          />
        </Grid>
      </Grid>

      <div style={{ textAlign: "right" }}>
        <RemoveButton label={"Joint"} onClick={handleRemove} />
      </div>
    </Grid>
  );
};
