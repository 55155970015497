import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import { showSnackbar } from "../../../slice/snackbarSlice";
import {
  createNewTask,
  getGenericTasksForNewTasks,
  getInstructionProductsTaskByIdForNew,
  getUserForAssignTask,
} from "../../../thunks/instruction";
import { NOTIFICATION_MESSAGE, USER_ROLE } from "../../../utils/constant";

const CreateNewTask = ({ open, handleClose, instructionId, products }) => {
  const TASK_TYPES = [
    {
      id: 1,
      name: "Duplicate",
      value: "Duplicate",
    },
    {
      id: 2,
      name: "Custom",
      value: "Custom",
    },
  ];
  const dispatch = useDispatch();
  const tempProducts = products?.map((obj) => ({
    ...obj,
    label: obj?.productName,
  }));
  const lookups = useSelector((state) => state?.lookupData);
  let { slaUnit: slaUnit } = lookups || {};

  const [insturctionTask, setInstructionTask] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [assignUsers, setAssignUsers] = useState([]);
  const [isLoader, setLoader] = useState(false);
  const [isError, setIsError] = useState(false);
  const [taskData, setTaskData] = useState({
    taskId: null,
    assignedToId: null,
    name: null,
    sla: null,
    slaUnitId: null,
    dueDate: null,
  });
  const [isOfficeAdmin, setOfficeAdmin] = useState(false);
  const [selectedTaskType, setSelectedTaskType] = useState("Duplicate");

  // Method to handlechange product
  const handleChangeProducts = (id) => {
    setSelectedTask(null);
    getInstructionTasks(id || "");
  };

  // Method to handle change tasks
  const handleChangeTasks = (value, key) => {
    setTaskData({ ...taskData, [key]: value });
  };

  // Method to get instruction tasks
  const getInstructionTasks = async (data) => {
    if (data?.productName === "Instruction") {
      getGenericTasksForInstructions();
    } else {
      const payload = {
        instructionId: instructionId,
        productId: data?.id,
      };
      try {
        setLoader(true);
        const response = await dispatch(
          getInstructionProductsTaskByIdForNew({
            payload,
            query: { isCompleted: false, isInstructionsIndividualTask: true },
          })
        ).unwrap();
        if (response) {
          const tempTasks = response?.map((obj) => ({
            ...obj,
            label: obj?.name,
          }));
          setInstructionTask(tempTasks);
          setLoader(false);
        } else {
          setInstructionTask([]);
          setLoader(false);
        }
      } catch (error) {
        setInstructionTask([]);
        setLoader(false);
        return error;
      }
    }
  };

  // Method to get generic tasks
  const getGenericTasksForInstructions = async () => {
    try {
      setLoader(true);
      const response = await dispatch(
        getGenericTasksForNewTasks({
          instructionId,
          query: { isCompleted: false, isInstructionsIndividualTask: true },
        })
      ).unwrap();
      if (response) {
        const tempTasks = response?.map((obj) => ({
          ...obj,
          label: obj?.name,
        }));
        setInstructionTask(tempTasks);
        setLoader(false);
      } else {
        setInstructionTask([]);
        setLoader(false);
      }
    } catch (error) {
      setInstructionTask([]);
      setLoader(false);
    }
  };

  // Method to get assign user
  const getAssignUser = async () => {
    try {
      const response = await dispatch(
        getUserForAssignTask(instructionId)
      ).unwrap();
      if (response) {
        const tempUsers = response?.map((obj) => ({
          ...obj,
          label: `${obj?.firstName} ${obj.lastName} (${obj.role})`,
        }));
        setAssignUsers(tempUsers);
      } else {
        setAssignUsers([]);
      }
    } catch (error) {
      setAssignUsers([]);
      return error;
    }
  };

  // Method to create new task
  const handleCreateNewTask = async () => {
    if ((!isOfficeAdmin && !taskData?.assignedToId) || !taskData?.dueDate) {
      setIsError(true);
    } else {
      setIsError(false);
      try {
        const payload = {
          id: taskData?.taskId,
          assignedToId: taskData?.assignedToId,
          name: taskData?.name,
          instructionId: Number(instructionId),
          sla: Number(taskData?.sla),
          slaUnitId: taskData?.slaUnitId,
          actor: isOfficeAdmin ? USER_ROLE?.OFFICE_ADMIN : null,
          dueDate: taskData?.dueDate || null,
        };
        setLoader(true);
        const response = await dispatch(createNewTask(payload)).unwrap();
        if (response) {
          setLoader(false);
          dispatch(
            showSnackbar({
              message: "Successfully created task",
              severity: "success",
            })
          );
          handleClose();
        } else {
          setLoader(false);
          handleClose();
        }
      } catch (error) {
        setLoader(false);
        dispatch(
          showSnackbar({
            message: `${error?.message}`,
            severity: "error",
          })
        );
        handleClose();
      }
    }
  };

  // Method to change task type
  const handleChangeTaskType = (value) => {
    setSelectedTaskType(value);
    setIsError(false);
    setOfficeAdmin(false);
    setTaskData({
      ...taskData,
      taskId: null,
      assignedToId: null,
      name: null,
      sla: null,
      slaUnitId: null,
      dueDate: null,
    });
  };

  // Method to change checkbox for is office admin
  const handleChangeIsOfficeAdmin = (checked) => {
    setOfficeAdmin(checked);
    setTaskData({ ...taskData, assignedToId: null });
  };

  useEffect(() => {
    getAssignUser();
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"xs"}
        PaperProps={{ sx: { borderRadius: "28px", paddingBottom: 0 } }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle
          className="serif_display_regular_20 green"
          textAlign={"center"}
        >
          Task Types
        </DialogTitle>
        <DialogContent
          dividers={true}
          sx={{ marginRight: "5px" }}
          className="instruction_dashboard_scrollbar"
        >
          {isLoader && <Loader />}
          <>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "30px",
                    },
                  }}
                >
                  <InputLabel className="normal_normal_medium_14_Manrope dark_grey">
                    Task Type
                  </InputLabel>
                  <Select
                    className="textField_outlined_border"
                    label="Task Type"
                    value={selectedTaskType}
                    onChange={(e) => handleChangeTaskType(e?.target?.value)}
                  >
                    {TASK_TYPES?.length > 0 &&
                      TASK_TYPES.map((d) => (
                        <MenuItem
                          key={d?.id}
                          value={d?.value}
                          className="normal_normal_medium_14_Manrope dark_grey"
                        >
                          {d?.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            {selectedTaskType === "Duplicate" && (
              <Grid container spacing={3} mt={1} pb={3}>
                {/* Select Product Section */}
                <Grid item xs={12}>
                  <Autocomplete
                    options={tempProducts || []}
                    onChange={(e, value) => handleChangeProducts(value)}
                    filterSelectedOptions={Boolean(products?.length)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="normal_normal_medium_14_Manropee textField_outlined_border"
                        variant="outlined"
                        size="small"
                        label="Products"
                        placeholder="Select Product"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "30px",
                            height: 38,
                          },
                        }}
                      />
                    )}
                  />
                </Grid>

                {/* Select Task Name Section */}
                <Grid item xs={12}>
                  <Autocomplete
                    options={insturctionTask || []}
                    // value={selectedTask?.taskId || null}
                    onChange={(e, value) =>
                      handleChangeTasks(value?.id, "taskId")
                    }
                    filterSelectedOptions={Boolean(insturctionTask?.length)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="normal_normal_medium_14_Manropee textField_outlined_border"
                        variant="outlined"
                        size="small"
                        label="Tasks"
                        placeholder="Select Task"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "30px",
                            height: 38,
                          },
                        }}
                      />
                    )}
                  />
                </Grid>

                {/* Select assign user section */}
                <Grid item xs={12}>
                  <Autocomplete
                    options={assignUsers || []}
                    onChange={(e, value) =>
                      handleChangeTasks(value?.id, "assignedToId")
                    }
                    filterSelectedOptions={Boolean(assignUsers?.length)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="normal_normal_medium_14_Manropee textField_outlined_border"
                        variant="outlined"
                        size="small"
                        label="Users"
                        placeholder="Select User"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "30px",
                            height: 38,
                          },
                        }}
                      />
                    )}
                  />
                  {isError && !taskData?.assignedToId && (
                    <FormHelperText className="red">
                      {NOTIFICATION_MESSAGE?.REQUIRED_FIELD_ERROR}
                    </FormHelperText>
                  )}
                </Grid>

                {/* Date Time Picker --> Due date */}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        slotProps={{
                          textField: {
                            InputLabelProps: { shrink: true },
                            readOnly: true,
                          },
                        }}
                        label="Due Date"
                        className="normal_normal_600_12_Manropee"
                        format={"dd/MM/yyyy hh:mm a"}
                        value={taskData?.dueDate}
                        onChange={(e) => handleChangeTasks(e, "dueDate")}
                        renderInput={(params) => (
                          <TextField {...params} style={{ width: "100%" }} />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                  {isError && !taskData?.dueDate && (
                    <FormHelperText className="red">
                      {NOTIFICATION_MESSAGE?.REQUIRED_FIELD_ERROR}
                    </FormHelperText>
                  )}
                </Grid>
                {/* SLA Field Section */}
                {/* <Grid item xs={6}>
                  <TextField
                    className="normal_normal_medium_14_Manropee textField_outlined_border"
                    label="SLA"
                    placeholder="SLA"
                    fullWidth
                    required
                    size="small"
                    value={taskData?.sla}
                    onChange={(e) => handleChangeTasks(e?.target?.value, "sla")}
                    error={
                      (isError && !taskData?.sla) ||
                      (isError && !slaValidation(taskData?.sla))
                    }
                    helperText={
                      (isError && !taskData?.sla && VALIDATION_MSG.REQUIRED) ||
                      (isError &&
                        !slaValidation(taskData?.sla) &&
                        VALIDATION_MSG.DIGIT_NUMBER)
                    }
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "30px",
                        height: 38,
                      },
                    }}
                  />
                </Grid> */}

                {/* SLA Unit Section */}
                {/* <Grid item xs={6}>
                  <FormControl
                    required
                    fullWidth
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "30px",
                      },
                    }}
                  >
                    <InputLabel className="normal_normal_medium_14_Manrope dark_grey">
                      SLA Unit
                    </InputLabel>
                    <Select
                      className="textField_outlined_border"
                      label="SLA Unit"
                      value={taskData?.slaUnitId}
                      onChange={(e) =>
                        handleChangeTasks(e?.target?.value, "slaUnitId")
                      }
                    >
                      {slaUnit?.length > 0 &&
                        slaUnit?.map((d) => (
                          <MenuItem key={d?.id} value={d?.id}>
                            {d?.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {isError && !taskData?.slaUnitId && (
                    <FormHelperText className="red">
                      {NOTIFICATION_MESSAGE?.REQUIRED_FIELD_ERROR}
                    </FormHelperText>
                  )}
                </Grid> */}

                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    onClick={handleCreateNewTask}
                    style={{
                      borderRadius: "24px",
                      border: "1px solid #00ce3f",
                    }}
                    size="small"
                    variant="outlined"
                    className="meeting_log_btn normal_normal_semibold_14_Manrope green"
                  >
                    Create
                  </Button>
                </Grid>
              </Grid>
            )}

            {selectedTaskType === "Custom" && (
              <Grid container spacing={3} mt={1} pb={3}>
                <Grid item xs={12}>
                  <TextField
                    required
                    className="inputTextField normal_normal_600_12_Manropee"
                    placeholder="Task Name"
                    fullWidth
                    size="small"
                    value={null}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    onChange={(e) =>
                      handleChangeTasks(e?.target?.value, "name")
                    }
                  />
                </Grid>

                {/* Is Office Admin Checkbox */}
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: "#00CE3F",
                          "&.Mui-checked": {
                            color: "#00CE3F",
                          },
                        }}
                        checked={isOfficeAdmin}
                        onChange={(e) =>
                          handleChangeIsOfficeAdmin(e?.target?.checked)
                        }
                      />
                    }
                    label="Is for Office Admin?"
                  />
                </Grid>

                {/* User Selction section */}
                {!isOfficeAdmin && (
                  <Grid item xs={12}>
                    <Autocomplete
                      disabled={isOfficeAdmin}
                      options={assignUsers || []}
                      onChange={(e, value) =>
                        handleChangeTasks(value?.id, "assignedToId")
                      }
                      filterSelectedOptions={Boolean(assignUsers?.length)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="normal_normal_medium_14_Manropee textField_outlined_border"
                          variant="outlined"
                          size="small"
                          label="Users"
                          placeholder="Select User"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "30px",
                              height: 38,
                            },
                          }}
                        />
                      )}
                    />
                    {isError && !taskData?.assignedToId && (
                      <FormHelperText className="red">
                        {NOTIFICATION_MESSAGE?.REQUIRED_FIELD_ERROR}
                      </FormHelperText>
                    )}
                  </Grid>
                )}

                {/* Date Time Picker --> Due date */}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        slotProps={{
                          textField: {
                            InputLabelProps: { shrink: true },
                            readOnly: true,
                          },
                        }}
                        label="Due Date"
                        className="normal_normal_600_12_Manropee"
                        format={"dd/MM/yyyy hh:mm a"}
                        value={taskData?.dueDate}
                        onChange={(e) => handleChangeTasks(e, "dueDate")}
                        renderInput={(params) => (
                          <TextField {...params} disabled />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                  {isError && !taskData?.dueDate && (
                    <FormHelperText className="red">
                      {NOTIFICATION_MESSAGE?.REQUIRED_FIELD_ERROR}
                    </FormHelperText>
                  )}
                </Grid>
                {/* SLA Field Section */}
                {/* <Grid item xs={6}>
                  <TextField
                    className="normal_normal_medium_14_Manropee textField_outlined_border"
                    label="SLA"
                    placeholder="SLA"
                    fullWidth
                    required
                    size="small"
                    value={taskData?.sla || null}
                    onChange={(e) => handleChangeTasks(e?.target?.value, "sla")}
                    error={
                      (isError && !taskData?.sla) ||
                      (isError && !slaValidation(taskData?.sla))
                    }
                    helperText={
                      (isError && !taskData?.sla && VALIDATION_MSG.REQUIRED) ||
                      (isError &&
                        !slaValidation(taskData?.sla) &&
                        VALIDATION_MSG.DIGIT_NUMBER)
                    }
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "30px",
                        height: 38,
                      },
                    }}
                  />
                </Grid> */}

                {/* SLA Unit Section */}
                {/* <Grid item xs={6}>
                  <FormControl
                    required
                    fullWidth
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "30px",
                      },
                    }}
                  >
                    <InputLabel className="normal_normal_medium_14_Manrope dark_grey">
                      SLA Unit
                    </InputLabel>
                    <Select
                      className="textField_outlined_border"
                      label="SLA Unit"
                      value={taskData?.slaUnitId}
                      onChange={(e) =>
                        handleChangeTasks(e?.target?.value, "slaUnitId")
                      }
                    >
                      {slaUnit?.length > 0 &&
                        slaUnit?.map((d) => (
                          <MenuItem key={d?.id} value={d?.id}>
                            {d?.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {isError && !taskData?.slaUnitId && (
                    <FormHelperText className="red">
                      {NOTIFICATION_MESSAGE?.REQUIRED_FIELD_ERROR}
                    </FormHelperText>
                  )}
                </Grid> */}

                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    onClick={handleCreateNewTask}
                    style={{
                      borderRadius: "24px",
                      border: "1px solid #00ce3f",
                    }}
                    size="small"
                    variant="outlined"
                    className="meeting_log_btn normal_normal_semibold_14_Manrope green"
                  >
                    Create
                  </Button>
                </Grid>
              </Grid>
            )}
          </>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default CreateNewTask;
