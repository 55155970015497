import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../../components/Loader";
import {
  clearEmailDocuments,
  clearNotesDocuments,
} from "../../../../slice/documents";
import { clearInstructionDashboardData } from "../../../../slice/instruction";
import { showSnackbar } from "../../../../slice/snackbarSlice";
import { ClearTaskData } from "../../../../slice/task";
import {
  getInstructionById,
  getInstructionProducts,
} from "../../../../thunks/instruction";
import { getLookups } from "../../../../thunks/lookup";
import Doc_notes_email_form from "./Doc_notes_email_form";
import InstructionDetails from "./InstructionDetails";
import TaskList from "./TaskList";

function Dashboard() {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const lookups = useSelector((state) => state?.lookupData);
  let {
    "Instruction Status": instructionStatusList,
    "Invoice Status": invoiceStatusList,
  } = lookups || {};
  const [isLoader, setLoader] = useState(false);

  /* Get instruction details by passing instruction ID */
  const getInstructionDataById = async () => {
    try {
      setLoader(true);
      if (!instructionStatusList || !invoiceStatusList) {
        const lookupKeys = ["Instruction Status", "Invoice Status"];
        await dispatch(
          getLookups({ searchStrings: JSON.stringify(lookupKeys) })
        ).unwrap();
      }
      const response = await dispatch(
        getInstructionById({ id: params?.id })
      ).unwrap();
      if (response?.id) {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      navigate("/app/instructions/list");
      dispatch(
        showSnackbar({
          message: error?.message,
          severity: "error",
        })
      );
    }
  };

  /* Methond to get instruction product on first render called in useEffect */
  const getInstructionProductsDataById = async () => {
    try {
      const response = await dispatch(
        getInstructionProducts(params?.id)
      ).unwrap();
    } catch (error) {}
  };

  /* Method to get document type data--> lookup */
  const getDocumentTypeData = async () => {
    try {
      const response = await dispatch(
        getLookups({ searchStrings: JSON.stringify(["Document Type"]) })
      ).unwrap();
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    getInstructionDataById();
    getInstructionProductsDataById();
    getDocumentTypeData();
  }, []);

  // Clear instruction form state when component unmount
  useEffect(() => {
    return () => {
      dispatch(clearInstructionDashboardData());
      dispatch(clearNotesDocuments());
      dispatch(clearEmailDocuments());
      dispatch(ClearTaskData());
    };
  }, []);

  return (
    <>
      {/* Loader Component */}
      {isLoader && <Loader />}
      <Grid container spacing={3}>
        <Grid item xs={12} display={"flex"} flexDirection={"row"}>
          {/* Client info and meeting log and plans */}
          <InstructionDetails />

          {/* Product list in tab view + Tasks list layout */}
          <Grid item xs={6.5}>
            <TaskList />
          </Grid>

          {/* Document, Forms, Notes & Remarks, Email, Task Section
            Right side accordion Section */}
          <Grid item xs={3}>
            <Doc_notes_email_form />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Dashboard;
