import ConsultantDashboardIcon from "../../../assets/images/consultant_dashboard_icon.svg";
import ConsultantInstructionsIcon from "../../../assets/images/consultant_instruction_icon.svg";
import loginUser from "../../../assets/images/loginUser_icon.png";
import ReportIcon from "../../../assets/images/report_icon.svg";

const officeAdminMenu = [
  {
    key: "routekey-home",
    label: "sidebar.dashboard",
    path: "/app/dashboard",
    icon: ConsultantDashboardIcon,
    vissbleOnSmallScrren: true,
  },
  {
    key: "routekey-instructions",
    label: "sidebar.instructions",
    path: "/app/instructions/list",
    icon: ConsultantInstructionsIcon,
    vissbleOnSmallScrren: true,
  },

  {
    key: "routekey-leads",
    label: "sidebar.clients",
    path: "/app/client/list",
    icon: loginUser,
    vissbleOnSmallScrren: true,
  },
  {
    key: "routekey-reports",
    label: "sidebar.reports",
    path: "/app/reports",
    icon: ReportIcon,
    vissbleOnSmallScrren: true,
  },
];

export default officeAdminMenu;
