import { createAsyncThunk } from "@reduxjs/toolkit";
import getAxios from "../helpers/axiosInterceptor";
import { API_ROUTES_PATHS } from "../utils/constant";

// Method to Get User API
export const getUser = createAsyncThunk(
  "user/getUser",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(API_ROUTES_PATHS.USERS_LIST, model);

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to Get Introducers API
export const getIntroducer = createAsyncThunk(
  "user/getIntroducer",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(API_ROUTES_PATHS.GET_INTRODUCERS);

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to Get Consultants API
export const getConsultant = createAsyncThunk(
  "user/getConsultant",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(API_ROUTES_PATHS.GET_CONSULTANT);

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to Create User API
export const createUser = createAsyncThunk(
  "user/createUser",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(API_ROUTES_PATHS.CREATE_USER, model);

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to Update User API
export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().patch(
        `${API_ROUTES_PATHS.UPDATE_USER}/${model?.id}`,
        model?.payload
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to Update User by ID API
export const updateMe = createAsyncThunk(
  "user/updateMe",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().patch(`${API_ROUTES_PATHS.EDIT_ME}`, model);

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to Get User By ID API
export const getUserDataById = createAsyncThunk(
  "user/getUserDataById",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.UPDATE_USER}/${model}`,
        model?.payload
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to Deactivate User API
export const deactivateUser = createAsyncThunk(
  "user/deactivateUser",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().patch(
        `${API_ROUTES_PATHS.UPDATE_USER}/${model?.id}`,
        model
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to Get My Users API
export const getMyUsers = createAsyncThunk(
  "user/getMyUsers",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(API_ROUTES_PATHS.GET_MY_USERS);

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get XERO user option set API
export const xeroOptionSet = createAsyncThunk(
  "xero/xeroOptionSet",
  async (model, thunkApi) => {
    try {
      const response = await getAxios().get(
        `${API_ROUTES_PATHS.GET_XERO_OPTION_SET}`
      );
      const responseData = response.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get XERO suspense user option API
export const xeroSuspenseData = createAsyncThunk(
  "xero/xeroSuspenseData",
  async (model, thunkApi) => {
    try {
      const response = await getAxios().get(
        `${API_ROUTES_PATHS.GET_XERO_SUSPENSE_USER}`
      );
      const responseData = response.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to Get All User API
export const getAllUser = createAsyncThunk(
  "user/getAllUser",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(API_ROUTES_PATHS.USERS_LIST, model);

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);
