import { createAsyncThunk } from "@reduxjs/toolkit";
import getAxios from "../helpers/axiosInterceptor";
import { API_ROUTES_PATHS } from "../utils/constant";

// Method to Get Plan API
export const getPlan = createAsyncThunk(
  "plan/getPlan",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(API_ROUTES_PATHS.GET_PLANS);

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get plan data by ID API
export const getPlanDataById = createAsyncThunk(
  "plan/getPlanDataById",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(`${API_ROUTES_PATHS.GET_PLANS}/${model}`);
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to Get Plan questionnaire API
export const getPlansWhatIfs = createAsyncThunk(
  "plan/getPlansWhatIfs",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(API_ROUTES_PATHS.GET_PLANS_WHAT_IFS);

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to Create plan API
export const createPlan = createAsyncThunk(
  "plan/createPlan",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().patch(API_ROUTES_PATHS.CREATE_PLAN, model);

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to Update plan API
export const updatePlan = createAsyncThunk(
  "plan/updatePlan",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().patch(API_ROUTES_PATHS.UPDATE_PLAN, model);

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);
