import { Grid, Typography } from "@mui/material";
import { useFieldArray } from "react-hook-form";
import { useSelector } from "react-redux";
import { AddButton, RemoveButton } from "../../../../../../components/buttons";
import { NumberInput, RadioInput, TextInput } from "../../../../../../components/inputs";
import { VALIDATION_FORMAT, VALIDATION_MSG, YES_NO_DATA } from "../../../../../../utils/constant";

const DDTItem = ({ arr, isJoint }) => {
  const client1 = useFieldArray({
    name: `InstructionWillQuestionnaire.[0].willTrusts.disabledDiscretionaryTrust.beneficiary`,
  });

  const { append, remove, fields } = client1;

  const client2 = useFieldArray({
    name: `InstructionWillQuestionnaire.[1].willTrusts.disabledDiscretionaryTrust.beneficiary`,
  });
  const {
    append: secondaryAppend,
    remove: secondaryRemove,
    fields: secondaryFields,
  } = client2;

  const beneficiariesObject = {
    property: "",
    fullName: "",
    subject: null,
    rightToSell: null,
    beneficiaries: [],
  };

  const handleAdd = (clientNumber) => {
    clientNumber === 0
      ? append(beneficiariesObject)
      : secondaryAppend(beneficiariesObject);
  };

  const handleRemove = (clientNumber, ind) => {
    if (clientNumber === 1) {
      remove(ind);
    } else {
      secondaryRemove(ind);
    }
  };

  return (
    <>
      <Grid width={"100%"}>
        <Grid
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          borderBottom={`1px solid #DCDCE1`}
          padding={2}
        >
          <Grid width={"85%"} display={"flex"} flexDirection={"column"} gap={1}>
            <Typography>
              Disabled Discretionary or Protective / Discretionary Trust
            </Typography>
            <Typography sx={{ color: "#00CE3F", fontSize: "12px" }}>
              For children who are looked after by others / disabled person
            </Typography>
          </Grid>
          <Grid width={"15%"}>
            <RadioInput
              label=""
              name={`InstructionWillQuestionnaire.[0].willTrusts.isDDT`}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
            />
          </Grid>
        </Grid>

        <Grid
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={isJoint ? "space-between" : "center"}
          borderTop={`1px solid #DCDCE1`}
          borderBottom={`1px solid #DCDCE1`}
        >
          {arr?.map((item, index) => {
            return (
              <Grid
                width={isJoint ? "50%" : "62%"}
                borderRight={
                  index === 0 && isJoint ? `1px solid #DCDCE1` : null
                }
                padding={2}
                display={"flex"}
                flexDirection={"column"}
                gap={2}
              >
                <TextInput
                  label={`Name of Principle Beneficiary`}
                  name={`InstructionWillQuestionnaire.[${index}].willTrusts.disabledDiscretionaryTrust.principleBeneficiaryName`}
                  placeholder="Enter name"
                />
                <Grid width={"100%"} display={"flex"} flexDirection={"row"}>
                  <Grid
                    width={"100%"}
                    paddingY={4}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={3}
                  >
                    {(index === 0 ? fields : secondaryFields).map(
                      (card, ind) => {
                        return (
                          <DDTForm
                            isJoint={isJoint}
                            index={index}
                            cardNo={ind}
                            pathName={`InstructionWillQuestionnaire.[${index}].willTrusts.disabledDiscretionaryTrust.beneficiary.[${ind}]`}
                            handleRemove={() => handleRemove(item, ind)}
                          />
                        );
                      }
                    )}
                    <Grid textAlign={"center"}>
                      <AddButton onClick={() => handleAdd(index)} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
};

export default DDTItem;

const DDTForm = ({ index, cardNo, handleRemove, pathName }) => {
  let { beneficiary_classification } = useSelector(
    (state) => state?.lookupData
  );

  return (
    <Grid
      boxShadow={"1px 2px 6px #00000029"}
      borderBottom={`1px solid #DCDCE1`}
      borderRadius={"16px"}
      borderRight={index === 0 ? `1px solid #DCDCE1` : null}
      padding={2}
      paddingY={3}
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      position={"relative"}
    >
      <TextInput
        label={`Secondary Beneficiary ${cardNo + 1}`}
        name={`${pathName}.fullName`}
        placeholder="Enter name"
      />
      <Grid item xs={12}>
        <RadioInput
          label={`Beneficiaries Type`}
          name={`${pathName}.classification`}
          options={beneficiary_classification || []}
          rules={{
            required: { value: true, message: VALIDATION_MSG.REQUIRED },
          }}
        />
      </Grid>

      <Grid
        display={"flex"}
        width={"100%"}
        justifyContent={"space-between"}
        gap={2}
      >
        <Grid width={"32%"}>
          <RadioInput
            label="Per Stirpes"
            name={`${pathName}.perStripes`}
            options={YES_NO_DATA}
          />
        </Grid>
        <Grid width={"32%"}>
          <TextInput
            label={`Age of Inheritance`}
            name={`${pathName}.ageOfInheritance`}
            placeholder="Enter value"
          />
        </Grid>
        <Grid width={"32%"}>
          <NumberInput
            name={`${pathName}.percentageShare`}
            label="Percentage Share"
            placeholder="Percentage Share"
            rules={{
              pattern: { value: VALIDATION_FORMAT.percentageOnly.pattern, message: VALIDATION_FORMAT.percentageOnly.message },
            }}
          />
        </Grid>
      </Grid>
      <div style={{ textAlign: "right" }}>
        <RemoveButton onClick={handleRemove} />
      </div>
    </Grid>
  );
};
