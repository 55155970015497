import { Grid } from "@mui/material";
import { memo, useMemo } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  AddButton,
  ContainedGreenButton,
  OutlinedGreenButton,
  RemoveButton,
} from "../../../../../components/buttons";
import {
  CustomCheckboxGroup,
  DateInput,
  RadioInput,
  TextInput,
} from "../../../../../components/inputs";
import { VALIDATION_MSG } from "../../../../../utils/constant";
import { mapLabelValue } from "../../../../../utils/util";
import { dependentObject } from "./factFindFormConstants";

const DependentForm = () => {
  const isJoint = useSelector((state) => state?.instruction?.isJoint);
  const arr = useSelector((state) => state?.instruction?.arr);

  const client1 = useFieldArray({
    name: `InstructionClients.[0].InstructionFactFind.dependants`,
  });

  const { append, remove, fields } = client1;

  const client2 = useFieldArray({
    name: `InstructionClients.[1].InstructionFactFind.dependants`,
  });

  const {
    append: secondaryAppend,
    remove: secondaryRemove,
    fields: secondaryFields,
  } = client2;

  const handleAdd = (clientNumber, arrayLength) => {
    clientNumber === 0
      ? append(dependentObject)
      : secondaryAppend(dependentObject);
  };

  const handleRemove = (clientNumber, ind) => {
    if (clientNumber === 1) {
      remove(ind);
    } else {
      secondaryRemove(ind);
    }
  };
  return (
    <>
      <Grid
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        borderTop={`1px solid #DCDCE1`}
        justifyContent={"center"}
      >
        {arr?.map((item, index) => {
          return (
            <Grid
              key={item}
              width={!isJoint ? "60%" : "100%"}
              borderRight={index === 0 && isJoint ? `1px solid #DCDCE1` : null}
              paddingY={4}
              display={"flex"}
              flexDirection={"column"}
              gap={3}
              paddingRight={index === 0 ? 2 : 0}
              paddingLeft={index === 1 ? 2 : 0}
            >
              {(index === 0 ? fields : secondaryFields).map((card, ind) => {
                return (
                  <DependentFormSection
                    key={card.id}
                    isJoint={isJoint}
                    index={index}
                    cardNo={ind}
                    handleRemove={() => handleRemove(item, ind)}
                    pathName={`InstructionClients.${index}.InstructionFactFind.dependants.${ind}`}
                    isApplicationJoint={isJoint}
                  />
                );
              })}
              <Grid textAlign={"center"}>
                <AddButton
                  onClick={() =>
                    handleAdd(
                      index,
                      (item === 1 ? fields : secondaryFields).length
                    )
                  }
                />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default DependentForm;

const DependentFormSection = memo(
  ({ index, cardNo, handleRemove, pathName, isApplicationJoint }) => {
    const { getValues, setValue } = useFormContext();
    const handleJoint = () => {
      let isJoint = getValues(`${pathName}.isJoint`) || false;
      setValue(`${pathName}.isJoint`, !isJoint);
    };

    const isCardJoint = useWatch({
      name: `${pathName}.isJoint`,
    });
    let { Gender } = useSelector((state) => state?.lookupData);
    let genderList = useMemo(() => mapLabelValue(Gender), [Gender]);

    return (
      <Grid
        boxShadow={"1px 2px 6px #00000029"}
        borderBottom={`1px solid #DCDCE1`}
        // border={"2px solid red"}
        borderRadius={"16px"}
        borderRight={index === 0 ? `1px solid #DCDCE1` : null}
        padding={2}
        paddingY={3}
        display={"flex"}
        flexDirection={"column"}
        gap={2}
        position={"relative"}
      >
        {index === 0 && isApplicationJoint ? (
          <div className="card-mirror-btn">
            {isCardJoint ? (
              <ContainedGreenButton label={"Joint"} onClick={handleJoint} />
            ) : (
              <OutlinedGreenButton label={"Joint"} onClick={handleJoint} />
            )}
          </div>
        ) : null}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextInput
              label={`Full Name`}
              name={`InstructionClients.${index}.InstructionFactFind.dependants.[${cardNo}].fullName`}
              placeholder="Full Name"
              rules={{
                required: { value: true, message: VALIDATION_MSG.REQUIRED },
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <RadioInput
              label={`Gender`}
              name={`InstructionClients.${index}.InstructionFactFind.dependants.[${cardNo}].gender`}
              options={genderList}
            />
          </Grid>

          <Grid item xs={6}>
            <DateInput
              name={`InstructionClients.${index}.InstructionFactFind.dependants.[${cardNo}].dob`}
              label="Date Of Birth"
            />
          </Grid>

          <Grid item xs={4}>
            <CustomCheckboxGroup
              // label="Owned"
              name={`${pathName}.dependantStatus.capacityIssues`}
              options={[
                {
                  label: "Capacity Issues",
                  value: true,
                },
              ]}
            />
          </Grid>

          <Grid item xs={4}>
            <CustomCheckboxGroup
              // label="Owned"
              name={`${pathName}.dependantStatus.appointGuardians`}
              options={[
                {
                  label: "Appoint Guardians",
                  value: true,
                },
              ]}
            />
          </Grid>

          <Grid item xs={4} mr={3}>
            <CustomCheckboxGroup
              // label="Owned"
              name={`${pathName}.dependantStatus.appointAsBeneficiary`}
              options={[
                {
                  label: "Appoint as Beneficiary",
                  value: true,
                },
              ]}
            />
          </Grid>
        </Grid>

        <div style={{ textAlign: "right" }}>
          <RemoveButton label={"Joint"} onClick={handleRemove} />
        </div>
      </Grid>
    );
  },
  (prevProps, nextProps) => prevProps?.cardNo === nextProps?.cardNo
);
