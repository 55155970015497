import SettingsIcon from "@mui/icons-material/Settings";
import ConsultantDashboardIcon from "../../../assets/images/consultant_dashboard_icon.svg";
import ConsultantInstructionsIcon from "../../../assets/images/consultant_instruction_icon.svg";
import loginUser from "../../../assets/images/loginUser_icon.png";
import ReportIcon from "../../../assets/images/report_icon.svg";
import PeopleIcon from "@mui/icons-material/People";
import TaskIcon from "@mui/icons-material/Task";
import CurrencyPoundIcon from "@mui/icons-material/CurrencyPound";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import EmailIcon from "@mui/icons-material/Email";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import ListAltIcon from "@mui/icons-material/ListAlt";
import BusinessIcon from "@mui/icons-material/Business";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
const AdminMenu = [
  {
    key: "routekey-home",
    label: "sidebar.dashboard",
    path: "/app/dashboard",
    icon: ConsultantDashboardIcon,
    vissbleOnSmallScrren: true,
  },
  {
    key: "routekey-reports",
    label: "sidebar.reports",
    path: "/app/reports",
    icon: ReportIcon,
    vissbleOnSmallScrren: true,
  },
  {
    key: "routekey-manage",
    label: "sidebar.manage",
    // path: "#",
    icon: <SettingsIcon fontSize="large" />,
    isManage: true,
    vissbleOnSmallScrren: true,
  },
  {
    key: "routekey-instructions",
    label: "sidebar.instructions",
    path: "/app/instructions/list",
    icon: ConsultantInstructionsIcon,
    vissbleOnSmallScrren: true,
  },
  {
    key: "routekey-leads",
    label: "sidebar.clients",
    path: "/app/client/list",
    icon: loginUser,
    vissbleOnSmallScrren: true,
  },
];

export const Manage_Submenu = [
  {
    key: 1,
    label: "Users",
    path: "/app/manage/users/list",
    icon: <PeopleIcon />,
  },
  {
    key: 2,
    label: "Products",
    path: "/app/manage/products/list",
    icon: <ListAltIcon />,
  },
  {
    key: 3,
    label: "Plans",
    path: "/app/manage/plan/list",
    icon: <PlaylistAddCheckIcon />,
  },
  {
    key: 4,
    label: "Dis.Price",
    path: "/app/manage/disbursementprice",
    icon: <CurrencyPoundIcon />,
  },
  {
    key: 5,
    label: "Tasks",
    path: "/app/manage/task/list",
    icon: <TaskIcon />,
  },
  {
    key: 6,
    label: "Email",
    path: "/app/manage/email/list",
    icon: <EmailIcon />,
  },
  {
    key: 7,
    label: "Document",
    path: "/app/manage/document/list",
    icon: <FileCopyIcon />,
  },
  {
    key: 8,
    label: "Campaign",
    path: "/app/manage/campaign/list",
    icon: <AddBusinessIcon />,
  },
  {
    key: 9,
    label: "Company",
    path: "/app/manage/company",
    icon: <BusinessIcon />,
  },
  {
    key: 10,
    label: "Status",
    path: "/app/manage/client-status",
    icon: <AssignmentTurnedInIcon />,
  },
];
export default AdminMenu;
