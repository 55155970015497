import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RadioInput } from "../../../../../components/inputs";

const ConsciousnessForm = () => {
  const isJoint = useSelector((state) => state?.instruction?.isJoint);
  const arr = useSelector((state) => state?.instruction?.arr);
  const [edit, setEdit] = useState(arr.length > 1 ? [false, false] : [false]);
  const { living_will_q_consiousness } = useSelector(
    (state) => state?.lookupData
  );
  const handleEdit = (clientNumber, value) => {
    setEdit((prev) => {
      let temp = prev;
      temp[clientNumber] = value;
      return [...temp];
    });
  };
  return (
    <>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          style={{ fontSize: "bold", marginBottom: "0.5rem" }}
        >
          Consciousness
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ marginBottom: "0.5rem" }}>
          <span style={{ color: "#00CE3F", fontWeight: "bold" }}>
            Persistently unresponsive -
          </span>{" "}
          when it’s been longer than four weeks.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ marginBottom: "0.5rem" }}>
          <span style={{ color: "#00CE3F", fontWeight: "bold" }}>
            Permanently unresponsive -
          </span>{" "}
          when it’s been more than six months if caused by a non-traumatic brain
          injury, or more than 12 months if caused by a traumatic brain injury.
        </Typography>
      </Grid>
      <Grid width={"100%"} display={"flex"} flexDirection={"row"}>
        {arr?.map((item, index) => {
          return (
            <Grid
              width={isJoint ? "50%" : "100%"}
              borderTop={`1px solid #DCDCE1`}
              borderBottom={`1px solid #DCDCE1`}
              borderRight={index === 0 ? `1px solid #DCDCE1` : null}
              paddingY={4}
              display={"flex"}
              flexDirection={"column"}
              gap={3}
              paddingRight={index === 0 ? 2 : 0}
              paddingLeft={index === 1 ? 2 : 0}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <RadioInput
                    orientation="column"
                    label={`Would the Testator like to REFUSE life sustaining treatment if they were to become permanently or persistently unresponsive?`}
                    name={`InstructionLivingWillQuestionnaire.[${index}].consciousness`}
                    options={living_will_q_consiousness}
                  />
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default ConsciousnessForm;
