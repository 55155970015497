import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RadioInput, TextInput } from "../../../../../components/inputs";

const GeneralPractitionalForm = () => {
  const isJoint = useSelector((state) => state?.instruction?.isJoint);
  const arr = useSelector((state) => state?.instruction?.arr);

  const [edit, setEdit] = useState(arr.length > 1 ? [false, false] : [false]);
  const { living_will_q_consiousness } = useSelector(
    (state) => state?.lookupData
  );
  const handleEdit = (clientNumber, value) => {
    setEdit((prev) => {
      let temp = prev;
      temp[clientNumber] = value;
      return [...temp];
    });
  };
  return (
    <>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          style={{ fontSize: "bold", marginBottom: "0.5rem" }}
        >
          General Practitioner
        </Typography>
      </Grid>
      <Grid width={"100%"} display={"flex"} flexDirection={"row"}>
        {arr?.map((item, index) => {
          return (
            <Grid
              width={isJoint ? "50%" : "100%"}
              borderTop={`1px solid #DCDCE1`}
              borderBottom={`1px solid #DCDCE1`}
              borderRight={index === 0 ? `1px solid #DCDCE1` : null}
              paddingY={4}
              display={"flex"}
              flexDirection={"column"}
              gap={3}
              paddingRight={index === 0 ? 2 : 0}
              paddingLeft={index === 1 ? 2 : 0}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <RadioInput
                    orientation="row"
                    label={`Are the GP details known?`}
                    name={`InstructionLivingWillQuestionnaire.[${index}].generalPractitioner.isGpKnown`}
                    options={[
                      { label: "Yes", value: true },
                      { label: "No", value: false },
                    ]}
                  />
                </Grid>

                <Grid item xs={12} pr={1}>
                  <TextInput
                    name={`InstructionLivingWillQuestionnaire.[${index}].generalPractitioner.practiceName`}
                    label="Name of Practice"
                    placeholder="Name of Practice"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography className="instruction_fact_find_address_heading_typo">
                    Address
                  </Typography>
                </Grid>

                <Grid item xs={12} pr={1}>
                  <TextInput
                    name={`InstructionLivingWillQuestionnaire.[${index}].generalPractitioner.address.street1`}
                    label="Line 1"
                    placeholder="Line 1"
                  />
                </Grid>
                <Grid item xs={12} pr={1}>
                  <TextInput
                    name={`InstructionLivingWillQuestionnaire.[${index}].generalPractitioner.address.street2`}
                    label="Line 2"
                    placeholder="Line 2"
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextInput
                    placeholder={"Town"}
                    name={`InstructionLivingWillQuestionnaire.[${index}].generalPractitioner.address.city`}
                    label="Town"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    name={`InstructionLivingWillQuestionnaire.[${index}].generalPractitioner.address.state`}
                    label="County"
                    placeholder="County"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    name={`InstructionLivingWillQuestionnaire.[${index}].generalPractitioner.address.zip`}
                    label="Post Code"
                    placeholder="Post Code"
                  />
                </Grid>
                <Grid item xs={6} pr={1}>
                  <TextInput
                    name={`InstructionLivingWillQuestionnaire.[${index}].generalPractitioner.address.country`}
                    label="Country"
                    placeholder="Country"
                  />
                </Grid>

                <Grid item xs={12} pr={1}>
                  <TextInput
                    name={`InstructionLivingWillQuestionnaire.[${index}].generalPractitioner.practitionerName`}
                    label="Name of Practitioner"
                    placeholder="Name of Practitioner"
                  />
                </Grid>

                <Grid item xs={12}>
                  <RadioInput
                    orientation="row"
                    label={`Will you provide a copy of this document to the practice?`}
                    name={`InstructionLivingWillQuestionnaire.[${index}].generalPractitioner.isDocumentCopyProvided`}
                    options={[
                      { label: "Yes", value: true },
                      { label: "No", value: false },
                    ]}
                  />
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default GeneralPractitionalForm;
