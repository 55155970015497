import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Loader from "../../../components/Loader";
import { showSnackbar } from "../../../slice/snackbarSlice";
import { NOTIFICATION_MESSAGE, USER_ROLE } from "../../../utils/constant";
import {
  createClientTask,
  getClientsUserForAssignTask,
} from "../../../thunks/client";
import { useParams } from "react-router-dom";

const CreateNewTaskClient = ({ open, handleClose, clientId, setReload }) => {
  //
  const params = useParams();
  const dispatch = useDispatch();
  //
  const [isLoader, setLoader] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState([]);
  const [isOfficeAdmin, setOfficeAdmin] = useState(false);
  const [taskData, setTaskData] = useState({
    // taskId: null,
    leadClientId: null,
    assignedToId: null,
    name: null,
    dueDate: null,
    actor: null,
  });

  // get clients pagination and filter state
  const [filterPayload, setFilterPayload] = useState({
    page: 1,
    limit: null,
    search: "",
    sourceTypeLookupId: null,
    instructionStatusId: null,
    orderByOnCreatedAt: "desc",
  });

  /* Method to get client data */
  const getClientData = async (payload = {}) => {
    try {
      setLoader(true);
      const responseData = await dispatch(
        getClientsUserForAssignTask(params?.id)
      ).unwrap();

      if (responseData) {
        const tempUsers = responseData?.map((obj) => ({
          ...obj,
          label: `${obj?.firstName} ${obj?.lastName} (${obj?.role})`,
        }));
        setData(tempUsers);
        setLoader(false);
      } else {
        setData([]);
        setLoader(false);
      }
    } catch (error) {
      setData([]);
      setLoader(false);
    }
  };

  // Method to handle change tasks
  const handleChangeTasks = (value, key) => {
    setTaskData({ ...taskData, [key]: value });
  };

  // Method to create new task
  const handleCreateNewTask = async () => {
    if ((!isOfficeAdmin && !taskData?.assignedToId) || !taskData?.dueDate) {
      setIsError(true);
    } else {
      setIsError(false);
      try {
        const payload = {
          leadClientId: Number(clientId),
          assignedToId: taskData?.assignedToId,
          name: taskData?.name,
          actor: isOfficeAdmin ? USER_ROLE?.OFFICE_ADMIN : null,
          dueDate: taskData?.dueDate || null,
        };
        setLoader(true);
        const response = await dispatch(createClientTask(payload)).unwrap();
        if (response) {
          setReload((prev) => !prev);
          setLoader(false);
          dispatch(
            showSnackbar({
              message: "Successfully created task",
              severity: "success",
            })
          );
          handleClose();
        } else {
          setLoader(false);
          handleClose();
        }
      } catch (error) {
        setLoader(false);
        dispatch(
          showSnackbar({
            message: `${error?.message}`,
            severity: "error",
          })
        );
        handleClose();
        window.location.reload();
      }
    }
  };

  // Method to change checkbox for is office admin
  const handleChangeIsOfficeAdmin = (checked) => {
    setOfficeAdmin(checked);
    setTaskData({ ...taskData, assignedToId: null });
  };

  useEffect(() => {
    getClientData(filterPayload);
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"xs"}
        PaperProps={{ sx: { borderRadius: "28px", paddingBottom: 0 } }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle
          className="serif_display_regular_20 green"
          textAlign={"center"}
        >
          Create Custom Task
        </DialogTitle>
        <DialogContent
          dividers={true}
          sx={{ marginRight: "5px" }}
          className="instruction_dashboard_scrollbar"
        >
          {isLoader && <Loader />}
          <>
            <Grid container spacing={3} mt={1} pb={3}>
              <Grid item xs={12}>
                <TextField
                  required
                  className="inputTextField normal_normal_600_12_Manropee"
                  placeholder="Task Name"
                  fullWidth
                  size="small"
                  value={null}
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                  onChange={(e) => handleChangeTasks(e?.target?.value, "name")}
                />
              </Grid>

              {/* Is Office Admin Checkbox */}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: "#00CE3F",
                        "&.Mui-checked": {
                          color: "#00CE3F",
                        },
                      }}
                      checked={isOfficeAdmin}
                      onChange={(e) =>
                        handleChangeIsOfficeAdmin(e?.target?.checked)
                      }
                    />
                  }
                  label="Is for Office Admin?"
                />
              </Grid>

              {/* User Selction section */}
              {!isOfficeAdmin && (
                <Grid item xs={12}>
                  <Autocomplete
                    disabled={isOfficeAdmin}
                    options={data || []}
                    onChange={(e, value) =>
                      handleChangeTasks(value?.id, "assignedToId")
                    }
                    filterSelectedOptions={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="normal_normal_medium_14_Manropee textField_outlined_border"
                        variant="outlined"
                        size="small"
                        label="Users"
                        placeholder="Select User"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "30px",
                            height: 38,
                          },
                        }}
                      />
                    )}
                  />
                  {isError && !taskData?.assignedToId && (
                    <FormHelperText className="red">
                      {NOTIFICATION_MESSAGE?.REQUIRED_FIELD_ERROR}
                    </FormHelperText>
                  )}
                </Grid>
              )}

              {/* Date Time Picker --> Due date */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      slotProps={{
                        textField: {
                          InputLabelProps: { shrink: true },
                          readOnly: true,
                        },
                      }}
                      label="Due Date"
                      className="normal_normal_600_12_Manropee"
                      format={"dd/MM/yyyy hh:mm a"}
                      value={taskData?.dueDate}
                      onChange={(e) => handleChangeTasks(e, "dueDate")}
                      renderInput={(params) => (
                        <TextField {...params} disabled />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
                {isError && !taskData?.dueDate && (
                  <FormHelperText className="red">
                    {NOTIFICATION_MESSAGE?.REQUIRED_FIELD_ERROR}
                  </FormHelperText>
                )}
              </Grid>

              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  onClick={handleCreateNewTask}
                  style={{
                    borderRadius: "24px",
                    border: "1px solid #00ce3f",
                  }}
                  size="small"
                  variant="outlined"
                  className="meeting_log_btn normal_normal_semibold_14_Manrope green"
                >
                  Create
                </Button>
              </Grid>
            </Grid>
          </>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateNewTaskClient;
