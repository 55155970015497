import { Delete } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { TextInput } from "../../../../../../components/inputs";

const TrusteeForm = ({ index, cardNo, pathName, handleRemove }) => {
  return (
    <Grid
      boxShadow={"1px 2px 6px #00000029"}
      borderRadius={"16px"}
      borderRight={index === 0 ? `1px solid #DCDCE1` : null}
      padding={2}
      paddingY={3}
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      position={"relative"}
    >
      <div className="card-mirror-btn">
        <Delete
          fontSize="small"
          style={{ color: "#ff0000", fontSize: "18px", cursor: "pointer" }}
          onClick={handleRemove}
        />
      </div>

      <TextInput
        label={`Trustee ${cardNo + 1}`}
        name={`${pathName}.fullName`}
        placeholder="Enter name"
      />
    </Grid>
  );
};

export default TrusteeForm;
