import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import { showSnackbar } from "../../../slice/snackbarSlice";
import {
  UpdateClientDocument,
  UpdateDocument,
  getDocumentTypeById,
} from "../../../thunks/documents";
import { NOTIFICATION_MESSAGE } from "../../../utils/constant";
import { useLocation } from "react-router-dom";

const ClientDocumentEdit = ({
  open,
  handleClose,
  doc,
  documentType,
  clientData,
  getDocumentsByType,
}) => {
  console.log("document", doc);
  const dispatch = useDispatch();
  const location = useLocation();
  const { clientUserData } = useSelector((store) => store.client);
  const DocType = documentType?.filter((d) =>
    d?.LookupView?.some((view) =>
      location?.pathname?.includes("/app/instructions/dashboard/")
        ? view?.viewOn === "Case"
        : view?.viewOn === "Client"
    )
  );
  const [isBoth, setIsBoth] = useState(false);
  const [isLoader, setLoader] = useState(false);
  const [docSubType, setDocSubType] = useState([]);
  const [selectedInstruction, setSelectedInstruction] = useState(
    doc?.Instruction?.id || null
  );
  const [selectedDocumentType, setSelectedDocumentType] = useState(
    doc?.documentTypeId || ""
  );
  const [selectedDocumentSubType, setSelectedDocumentSubType] = useState(
    doc?.subLookupId || ""
  );
  const columns = [
    {
      id: "clientName",
      label: (
        <Typography className="table_cell_heading">Client Name</Typography>
      ),
      minWidth: 100,
    },
    {
      id: "documentTypeId",
      label: (
        <Typography className="table_cell_heading">Document Type</Typography>
      ),
      minWidth: 100,
    },
    {
      id: "clienInstructions",
      label: (
        <Typography className="table_cell_heading">
          Client's Instruction
        </Typography>
      ),
      minWidth: 300,
    },
    {
      id: "documentSubTypeId",
      label: (
        <Typography className="table_cell_heading">Document SubType</Typography>
      ),
      minWidth: 150,
    },
  ];
  // Method to close dialog
  const handleCloseDialog = () => {
    handleClose();
  };

  const getDocumentTypeDetails = async (id) => {
    try {
      setLoader(true);
      const response = await dispatch(getDocumentTypeById(id)).unwrap();
      if (response) {
        setLoader(false);
        setDocSubType(response?.SubLookup);
      }
    } catch (error) {
      setLoader(false);
      return error;
    }
  };

  //METHOD TO GET DATA
  const handleChangeFormData = async (value, key) => {
    if (key === "InstructionId") {
      setSelectedInstruction(value);
    } else if (key === "documentTypeId") {
      setSelectedInstruction(null);
      const DocumentType = DocType?.filter((type) => value === type?.id);
      DocumentType?.[0]?.LookupView?.length > 1
        ? setIsBoth(true)
        : setIsBoth(false);
      setSelectedDocumentType(value);
      setSelectedDocumentSubType(null);
      await getDocumentTypeDetails(value);
    } else if (key === "documentSubTypeId") {
      setSelectedDocumentSubType(value);
    }
  };

  //METHOD TO UPDATE DOC APICALL
  const handleUpdateDoc = async () => {
    if (isBoth && !selectedInstruction) {
      dispatch(
        showSnackbar({
          show: true,
          message:
            clientUserData?.Instruction?.length !== 0
              ? `Select client instruction`
              : `Change document type on row since client has no instruction`,
          severity: "error",
        })
      );
      return;
    }
    if (!selectedDocumentSubType) {
      dispatch(
        showSnackbar({
          show: true,
          message: `Select document subtype`,
          severity: "error",
        })
      );
      return;
    }
   
    const payload = {
      leadClientId: doc?.leadClientId,
      leadDocumentId: doc?.id,
      instructionId: selectedInstruction,
      documentTypeId: selectedDocumentType,
      subDocumentTypeId: selectedDocumentSubType,
      forClient: `${doc?.LeadClient.firstName} ${doc?.LeadClient.lastName}`,
    };
    
    try {
      setLoader(true);
      const response = await dispatch(UpdateClientDocument(payload)).unwrap();
      setLoader(false);
      if (response) {
        getDocumentsByType();
        handleClose();
        dispatch(
          showSnackbar({
            show: true,
            message: NOTIFICATION_MESSAGE?.DOC_UPDATE_SUCCESS,
            severity: "success",
          })
        );
      }
    } catch (error) {
      setLoader(false);
      dispatch(
        showSnackbar({
          show: true,
          message: NOTIFICATION_MESSAGE?.DOC_UPDATE_FAILURE,
          severity: "error",
        })
      );
    }
  };

  useEffect(() => {
    getDocumentTypeDetails(selectedDocumentType);
    const DocumentType = DocType?.filter(
      (type) => doc?.documentTypeId === type?.id
    );
    DocumentType?.[0]?.LookupView?.length > 1
      ? setIsBoth(true)
      : setIsBoth(false);
  }, []);
  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        fullWidth
        maxWidth="md"
        padding={40}
      >
        {isLoader && <Loader />}
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>
          <Typography className="serif_display_regular_26 green">
            Edit docs
          </Typography>
        </DialogTitle>
        <Grid container xs={12}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer
              style={{
                maxWidth: "100%",
                cursor: "pointer",
              }}
              sx={{ maxHeight: 550 }}
              className="instruction_dashboard_scrollbar"
            >
              <Table
                stickyHeader
                aria-label="sticky-table a dense table"
                size="small"
              >
                <TableHead>
                  {/* Columns section */}
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    {/* CLIENT NAME  */}
                    <TableCell>
                      <FormControl fullWidth>
                        <Typography>{`${doc?.LeadClient?.firstName} ${doc?.LeadClient?.lastName}`}</Typography>
                      </FormControl>
                    </TableCell>
                    {/* DOC TYPE  */}
                    <TableCell>
                      <Grid
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 3,
                        }}
                      >
                        <FormControl fullWidth>
                          <Select
                            className="formControlField"
                            value={selectedDocumentType}
                            variant="standard"
                            size="small"
                            onChange={(event) =>
                              handleChangeFormData(
                                event?.target?.value,
                                "documentTypeId"
                              )
                            }
                          >
                            {DocType?.map((data) => (
                              <MenuItem key={data?.id} value={data?.id}>
                                {data?.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {isBoth && (
                          <Tooltip title="This document type is applicable to both case & client">
                            <GroupIcon style={{ color: "#01ce3f" }} />
                          </Tooltip>
                        )}
                      </Grid>
                    </TableCell>
                    {/* Instruction  */}
                    <TableCell>
                      <Grid>
                        <FormControl fullWidth>
                          <Select
                            className="formControlField"
                            value={selectedInstruction}
                            variant="standard"
                            size="small"
                            disabled={!isBoth}
                            onChange={(event) =>
                              handleChangeFormData(
                                event?.target?.value,
                                "InstructionId"
                              )
                            }
                          >
                            {clientUserData?.Instruction?.map((data) => (
                              <MenuItem key={data?.id} value={data?.id}>
                                <div>
                                  {`case:- ${data?.id} , ${data?.name?.replace(
                                    /&/g,
                                    " & "
                                  )} `}
                                  <br />
                                  {`${data?.InstructionPlanSelections?.plan?.planName} , ${data?.InstructionPlanSelections?.individualProducts?.[0]?.product}`}
                                </div>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {isBoth && !clientUserData?.Instruction?.length ? (
                          <span
                            style={{
                              fontSize: "12px",
                              color: "red",
                              mt: "2px",
                            }}
                          >
                            Client has no instructions
                          </span>
                        ) : null}
                      </Grid>
                    </TableCell>
                    {/* DOC SUB TYPE  */}
                    <TableCell>
                      <FormControl fullWidth>
                        <Select
                          className="formControlField"
                          value={selectedDocumentSubType}
                          variant="standard"
                          size="small"
                          onChange={(event) =>
                            handleChangeFormData(
                              event?.target?.value,
                              "documentSubTypeId"
                            )
                          }
                        >
                          {docSubType?.map((data) => (
                            <MenuItem key={data?.id} value={data?.id}>
                              {data?.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {/* BUTTON SECTION  */}
          <Grid
            item
            xs={12}
            style={{
              marginTop: "1rem",
              display: "flex",
              gap: 10,
              padding: 20,
              justifyContent: "center",
            }}
          >
            <Button className="cancel_button" onClick={() => handleClose()}>
              Cancel
            </Button>

            <Button className="save_button" onClick={() => handleUpdateDoc()}>
              {" "}
              Save
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default ClientDocumentEdit;
