import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RadioInput, TextInput } from "../../../../../components/inputs";

const ExistingDocForm2 = () => {
  const isJoint = useSelector((state) => state?.instruction?.isJoint);
  const arr = useSelector((state) => state?.instruction?.arr);
  const [edit, setEdit] = useState(arr.length > 1 ? [false, false] : [false]);
  const { living_will_q_consiousness } = useSelector(
    (state) => state?.lookupData
  );
  const handleEdit = (clientNumber, value) => {
    setEdit((prev) => {
      let temp = prev;
      temp[clientNumber] = value;
      return [...temp];
    });
  };
  return (
    <>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          style={{ fontSize: "bold", marginBottom: "0.5rem" }}
        >
          Existing Health
        </Typography>
      </Grid>
      <Grid width={"100%"} display={"flex"} flexDirection={"row"}>
        {arr?.map((item, index) => {
          return (
            <Grid
              width={isJoint ? "50%" : "100%"}
              borderTop={`1px solid #DCDCE1`}
              borderBottom={`1px solid #DCDCE1`}
              borderRight={index === 0 ? `1px solid #DCDCE1` : null}
              paddingY={4}
              display={"flex"}
              flexDirection={"column"}
              gap={3}
              paddingRight={index === 0 ? 2 : 0}
              paddingLeft={index === 1 ? 2 : 0}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <RadioInput
                    orientation="row"
                    label={`Does the Testator have any existing health issues?`}
                    name={`InstructionLivingWillQuestionnaire.[${index}].existingHealth.anyHealthIssuesToTestator`}
                    options={[
                      { label: "Yes", value: true },
                      { label: "No", value: false },
                    ]}
                  />
                </Grid>

                <Grid item xs={12}>
                  <RadioInput
                    orientation="row"
                    label={`If yes, do you want to include them in your Living Will?`}
                    name={`InstructionLivingWillQuestionnaire.[${index}].existingHealth.wantToIncludeInLivingWill`}
                    options={[
                      { label: "Yes", value: true },
                      { label: "No", value: false },
                    ]}
                  />
                </Grid>

                <Grid item xs={12} pr={1}>
                  <TextInput
                    name={`InstructionLivingWillQuestionnaire.[${index}].existingHealth.healthIssues`}
                    label="Mention your health issues"
                    placeholder="Mention your health issues"
                  />
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default ExistingDocForm2;
