import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PushPinIcon from "@mui/icons-material/PushPin";
import {
  AccordionDetails,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { readImage } from "../../../thunks/instruction";
import { downloadNotesDocument } from "../../../utils/util";
import SingleNoteContainer from "./SingleNoteContainer";

const NotesAndRemarks = ({
  notesAndDocumentsData,
  clientData,
  instructionId,
  isPinned = false,
}) => {
  const dispatch = useDispatch();
  const [selectedDocuments, setSelectedDocuments] = useState(null);
  const [showAllDocuments, setShowAllDocuments] = useState(false);
  const [showFullFileName, setShowFullFileName] = useState(false);
  // Method to read image
  const handleReadImage = async (doc, docIndex = null, isPinned = false) => {
    try {
      let requiredDocIndex = docIndex === null ? 0 : docIndex;
      const response = await dispatch(
        readImage({
          query: {
            filename:
              (!isPinned
                ? doc?.InstructionTaskDocuments[requiredDocIndex]?.documentUrl
                : doc?.LeadClientDocuments[requiredDocIndex]?.documentUrl) ||
              doc?.documentUrl,
          },
        })
      ).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickDownload = async (documentData) => {
    for (const document of documentData) {
      let path = encodeURIComponent(document?.documentUrl);
      let forClient = document?.forClient || null;
      let subType =
        document?.subDocumentType?.name || document?.documentType?.name;
      let version = document?.version;
      if (forClient?.toLowerCase() === "both") {
        forClient = `${clientData?.[0]?.firstName}_${clientData?.[0]?.lastName}_${clientData?.[1]?.firstName}_${clientData?.[1]?.lastName}`;
      }
      const finalUrl = `${process.env.REACT_APP_API_PATH}/file/download-image?path=${path}&filename=${instructionId}_${forClient}_${subType}&version=${version}`;
      window.open(finalUrl, "_blank");
    }
  };

  // Method to open documents dialog and pass document to component
  const handleClickOpenDocuments = (documents) => {
    // setIsDocuments(true);
    setShowAllDocuments(!showAllDocuments);
    setSelectedDocuments(documents || []);
  };

  //METHOD TO SHOW FULL NAME OF FILE
  const HandleShowfullName = (index) => {
    setShowFullFileName(showFullFileName === index ? null : index);
  };
  return (
    <>
      <Grid
        className="instruction_dashboard_scrollbar"
        style={{ overflow: "auto", height: "50vh" }}
      >
        {notesAndDocumentsData?.length > 0 ? (
          notesAndDocumentsData?.map((d, index) => {
            let isPinned = Boolean(d?.leadClientId && d?.leadClientId !== null);
            return (
              <>
                {!isPinned ? (
                  <AccordionDetails>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography className="normal_normal_600_14_Manrope dark_grey">
                        {`${d?.notesCreatedBy?.firstName || "NA"} ${
                          d?.notesCreatedBy?.lastName || "NA"
                        }`}
                      </Typography>
                      <Typography className="normal_normal_600_14_Manrope">
                        {moment(d?.createdAt).format("HH:mm")}
                        <span
                          style={{
                            marginLeft: "0.5rem",
                            marginRight: "0.5rem",
                          }}
                        >
                          |
                        </span>
                        {moment(d?.createdAt).format("MMM DD")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        {d?.notes ? (
                          typeof d?.notes === "object" ? (
                            Object.keys(d?.notes).map((key) => {
                              return (
                                <Grid
                                  className="normal_normal_16_Manrope"
                                  key={key}
                                >
                                  {d?.notes[key] !== "" ? (
                                    <SingleNoteContainer note={d?.notes[key]} />
                                  ) : (
                                    "N/A"
                                  )}
                                </Grid>
                              );
                            })
                          ) : (
                            <Grid className="normal_normal_16_Manrope">
                              {d?.notes !== "" ? (
                                <SingleNoteContainer note={d?.notes} />
                              ) : (
                                "N/A"
                              )}
                            </Grid>
                          )
                        ) : (
                          <Grid className="normal_normal_16_Manrope">N/A</Grid>
                        )}
                      </Typography>
                    </Grid>
                    {/* If Document URL available then show attaatchment application only for old notes. */}
                    {/* TO SHOW OLD INSTRUCTIONS DOCS  */}
                    {d?.documentUrl && d?.documentUrl !== "string" && (
                      <Grid
                        item
                        xs={12}
                        mt={1}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          style={{
                            color: "rgb(0, 206, 63)",
                            cursor: "pointer",
                          }}
                          onClick={() => handleReadImage(d)}
                          className="normal_normal_600_14_Manrope"
                        >
                          Attached Documents
                        </Typography>
                        <Grid item>
                          <IconButton
                            onClick={() =>
                              downloadNotesDocument(
                                d,
                                clientData,
                                null,
                                null,
                                false
                              )
                            }
                          >
                            <DownloadIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    )}

                    {d?.InstructionTaskDocuments?.length ? (
                      <Grid
                        item
                        xs={12}
                        mt={2}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Typography
                          style={{
                            color: "rgb(0, 206, 63)",
                            cursor: "pointer",
                          }}
                          className="normal_normal_600_14_Manrope"
                          onClick={() =>
                            handleClickDownload(d?.InstructionTaskDocuments)
                          }
                        >
                          Download all
                        </Typography>
                      </Grid>
                    ) : null}

                    {/* PREVIEW OF 1'ST DOCUMENT TO SHOW NEW INSTUCTION'S DOCS */}
                    {d?.InstructionTaskDocuments?.length > 0 &&
                    (!d?.documentUrl || d?.documentUrl === "string") &&
                    !showAllDocuments ? (
                      <Grid
                        container
                        xs={12}
                        sx={{ display: "flex", alignItems: "center" }}
                        key={index}
                      >
                        <Grid
                          item
                          xs={8}
                          key={index}
                          style={{ cursor: "pointer" }}
                          onClick={() => HandleShowfullName(index)}
                        >
                          <Tooltip
                            title={
                              d?.InstructionTaskDocuments[0]?.fileInfo
                                ?.modifiedFileName || ""
                            }
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <div
                                style={{
                                  whiteSpace:
                                    showFullFileName === index
                                      ? "normal"
                                      : "nowrap",
                                  overflow:
                                    showFullFileName === index
                                      ? "visible"
                                      : "hidden",
                                  textOverflow:
                                    showFullFileName === index
                                      ? "clip"
                                      : "ellipsis",
                                }}
                              >
                                {
                                  d?.InstructionTaskDocuments[0]?.fileInfo
                                    ?.modifiedFileName
                                }
                              </div>
                            </div>
                          </Tooltip>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          mt={1}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <IconButton>
                            <VisibilityIcon
                              color="action"
                              onClick={() => handleReadImage(d)}
                            />
                          </IconButton>

                          <IconButton
                            onClick={() =>
                              downloadNotesDocument(
                                d,
                                clientData,
                                null,
                                null,
                                false
                              )
                            }
                          >
                            <DownloadIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ) : null}

                    {/* PREVIEW OF ALL DOCUMENT TO SHOW NEW INSTUCTION'S DOCS */}
                    {d?.InstructionTaskDocuments?.length > 0 &&
                    (!d?.documentUrl || d?.documentUrl === "string") &&
                    showAllDocuments
                      ? d?.InstructionTaskDocuments?.map((doc, docIndex) => {
                          return (
                            <Grid
                              container
                              xs={12}
                              sx={{ display: "flex", alignItems: "center" }}
                              key={docIndex}
                            >
                              <Grid
                                item
                                xs={8}
                                key={docIndex}
                                style={{ cursor: "pointer" }}
                                onClick={() => HandleShowfullName(docIndex)}
                              >
                                <Tooltip
                                  title={doc?.fileInfo?.modifiedFileName || ""}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      width: "100%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        whiteSpace:
                                          showFullFileName === docIndex
                                            ? "normal"
                                            : "nowrap",
                                        overflow:
                                          showFullFileName === docIndex
                                            ? "visible"
                                            : "hidden",
                                        textOverflow:
                                          showFullFileName === docIndex
                                            ? "clip"
                                            : "ellipsis",
                                      }}
                                    >
                                      {doc?.fileInfo?.modifiedFileName}
                                    </div>
                                  </div>
                                </Tooltip>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                mt={1}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <IconButton>
                                  <VisibilityIcon
                                    color="action"
                                    onClick={() => handleReadImage(d, docIndex)}
                                  />
                                </IconButton>

                                <IconButton
                                  onClick={() =>
                                    downloadNotesDocument(
                                      d,
                                      clientData,
                                      docIndex,
                                      null,
                                      false
                                    )
                                  }
                                >
                                  <DownloadIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          );
                        })
                      : null}

                    {/* BTN TO SHOW ALL DOCS WHEN NO OF DOCS GREATER THAN 1 */}
                    {d?.InstructionTaskDocuments?.length > 1 && (
                      <>
                        <Grid
                          item
                          xs={12}
                          mt={1}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Typography
                            style={{
                              color: "rgb(0, 206, 63)",
                              cursor: "pointer",
                            }}
                            className="normal_normal_600_14_Manrope"
                            onClick={() =>
                              handleClickOpenDocuments(
                                d?.InstructionTaskDocuments || []
                              )
                            }
                          >
                            Load {showAllDocuments ? "Less" : " More"}
                          </Typography>
                        </Grid>
                      </>
                    )}

                    <Divider
                      sx={{
                        marginTop: "1rem",
                        border: "0.5px solid lightgrey",
                      }}
                    />
                  </AccordionDetails>
                ) : (
                  <AccordionDetails>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography className="normal_normal_600_14_Manrope dark_grey">
                        {`${d?.createdBy?.firstName || "NA"} ${
                          d?.createdBy?.lastName || "NA"
                        }  `}{" "}
                      </Typography>
                      <Typography className="normal_normal_600_14_Manrope">
                        {moment(d?.createdAt).format("HH:mm")}
                        <span
                          style={{
                            marginLeft: "0.5rem",
                            marginRight: "0.5rem",
                          }}
                        >
                          |
                        </span>
                        {moment(d?.createdAt).format("MMM DD")}
                      </Typography>
                      <Tooltip title="This is a pinned note of client">
                        <PushPinIcon
                          size="small"
                          color="action"
                          sx={{ color: "#00ce3f", fontSize: "18px" }}
                          onClick={() => {}}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        {d?.note ? (
                          typeof d?.note === "object" ? (
                            Object.keys(d?.note).map((key) => {
                              return (
                                <Grid
                                  className="normal_normal_16_Manrope"
                                  key={key}
                                >
                                  {d?.note[key] !== "" ? (
                                    <SingleNoteContainer note={d?.note[key]} />
                                  ) : (
                                    "N/A"
                                  )}
                                </Grid>
                              );
                            })
                          ) : (
                            <Grid className="normal_normal_16_Manrope">
                              {d?.note !== "" ? (
                                <SingleNoteContainer note={d?.note} />
                              ) : (
                                "N/A"
                              )}
                            </Grid>
                          )
                        ) : (
                          <Grid className="normal_normal_16_Manrope">N/A</Grid>
                        )}
                      </Typography>
                    </Grid>
                    {/* If Document URL available then show attaatchment application only for old notes. */}
                    {/* TO SHOW OLD INSTRUCTIONS DOCS  */}
                    {d?.documentUrl && d?.documentUrl !== "string" && (
                      <Grid
                        item
                        xs={12}
                        mt={1}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          style={{
                            color: "rgb(0, 206, 63)",
                            cursor: "pointer",
                          }}
                          onClick={() => handleReadImage(d, null, isPinned)}
                          className="normal_normal_600_14_Manrope"
                        >
                          Attached Documents
                        </Typography>
                        <Grid item>
                          <IconButton
                            onClick={() =>
                              downloadNotesDocument(
                                d,
                                clientData,
                                null,
                                null,
                                isPinned
                              )
                            }
                          >
                            <DownloadIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    )}

                    {d?.LeadClientDocuments?.length ? (
                      <Grid
                        item
                        xs={12}
                        mt={2}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Typography
                          style={{
                            color: "rgb(0, 206, 63)",
                            cursor: "pointer",
                          }}
                          className="normal_normal_600_14_Manrope"
                          onClick={() =>
                            handleClickDownload(d?.LeadClientDocuments)
                          }
                        >
                          Download all
                        </Typography>
                      </Grid>
                    ) : null}

                    {/* PREVIEW OF 1'ST DOCUMENT TO SHOW NEW INSTUCTION'S DOCS */}
                    {d?.LeadClientDocuments?.length > 0 &&
                    (!d?.documentUrl || d?.documentUrl === "string") &&
                    !showAllDocuments ? (
                      <Grid
                        container
                        xs={12}
                        sx={{ display: "flex", alignItems: "center" }}
                        key={index}
                      >
                        <Grid
                          item
                          xs={8}
                          key={index}
                          style={{ cursor: "pointer" }}
                          onClick={() => HandleShowfullName(index)}
                        >
                          <Tooltip
                            title={
                              d?.LeadClientDocuments[0]?.fileInfo
                                ?.modifiedFileName || ""
                            }
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <div
                                style={{
                                  whiteSpace:
                                    showFullFileName === index
                                      ? "normal"
                                      : "nowrap",
                                  overflow:
                                    showFullFileName === index
                                      ? "visible"
                                      : "hidden",
                                  textOverflow:
                                    showFullFileName === index
                                      ? "clip"
                                      : "ellipsis",
                                }}
                              >
                                {
                                  d?.LeadClientDocuments[0]?.fileInfo
                                    ?.modifiedFileName
                                }
                              </div>
                            </div>
                          </Tooltip>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          mt={1}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <IconButton>
                            <VisibilityIcon
                              color="action"
                              onClick={() => handleReadImage(d, null, isPinned)}
                            />
                          </IconButton>

                          <IconButton
                            onClick={() =>
                              downloadNotesDocument(
                                d,
                                clientData,
                                null,
                                null,
                                isPinned
                              )
                            }
                          >
                            <DownloadIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ) : null}

                    {/* PREVIEW OF ALL DOCUMENT TO SHOW NEW INSTUCTION'S DOCS */}
                    {d?.LeadClientDocuments?.length > 0 &&
                    (!d?.documentUrl || d?.documentUrl === "string") &&
                    showAllDocuments
                      ? d?.LeadClientDocuments?.map((doc, docIndex) => {
                          return (
                            <Grid
                              container
                              xs={12}
                              sx={{ display: "flex", alignItems: "center" }}
                              key={docIndex}
                            >
                              <Grid
                                item
                                xs={8}
                                key={docIndex}
                                style={{ cursor: "pointer" }}
                                onClick={() => HandleShowfullName(docIndex)}
                              >
                                <Tooltip
                                  title={doc?.fileInfo?.modifiedFileName || ""}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      width: "100%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        whiteSpace:
                                          showFullFileName === docIndex
                                            ? "normal"
                                            : "nowrap",
                                        overflow:
                                          showFullFileName === docIndex
                                            ? "visible"
                                            : "hidden",
                                        textOverflow:
                                          showFullFileName === docIndex
                                            ? "clip"
                                            : "ellipsis",
                                      }}
                                    >
                                      {doc?.fileInfo?.modifiedFileName}
                                    </div>
                                  </div>
                                </Tooltip>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                mt={1}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <IconButton>
                                  <VisibilityIcon
                                    color="action"
                                    onClick={() =>
                                      handleReadImage(d, docIndex, isPinned)
                                    }
                                  />
                                </IconButton>

                                <IconButton
                                  onClick={() =>
                                    downloadNotesDocument(
                                      d,
                                      clientData,
                                      docIndex,
                                      null,
                                      isPinned
                                    )
                                  }
                                >
                                  <DownloadIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          );
                        })
                      : null}

                    {/* BTN TO SHOW ALL DOCS WHEN NO OF DOCS GREATER THAN 1 */}
                    {d?.LeadClientDocuments?.length > 1 && (
                      <>
                        <Grid
                          item
                          xs={12}
                          mt={1}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Typography
                            style={{
                              color: "rgb(0, 206, 63)",
                              cursor: "pointer",
                            }}
                            className="normal_normal_600_14_Manrope"
                            onClick={() =>
                              handleClickOpenDocuments(
                                d?.LeadClientDocuments || []
                              )
                            }
                          >
                            Load {showAllDocuments ? "Less" : " More"}
                          </Typography>
                        </Grid>
                      </>
                    )}

                    <Divider
                      sx={{
                        marginTop: "1rem",
                        border: "0.5px solid lightgrey",
                      }}
                    />
                  </AccordionDetails>
                )}
              </>
            );
          })
        ) : (
          <Typography textAlign={"center"}>No Data</Typography>
        )}
      </Grid>
    </>
  );
};

export default NotesAndRemarks;
