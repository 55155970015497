import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updateInstruction } from "../../../../../thunks/instruction";
import { lpaQuestionnaireFromSections } from "../../../../../utils/constant";
import LPADeclaration from "./LPADeclaration";
import LPATop from "./LPATop";

const LPAQuestionnaireForm = () => {
  const dispatch = useDispatch();
  const instructionDataObject = useSelector(
    (state) => state?.instruction?.tempInstructionData
  );
  const isJoint = useSelector((state) => state?.instruction?.isJoint);
  const arr = useSelector((state) => state?.instruction?.arr);

  let LPAQuestionnaireData =
    instructionDataObject?.InstructionLPA_Questionnaire || [];

  const methods = useFormContext();
  const { control } = methods;

  const clientArray = useFieldArray({
    control,
    name: "InstructionLPA_Questionnaire",
  });

  const [formSections, setFormSection] = useState(
    lpaQuestionnaireFromSections()
  );

  useEffect(() => {
    setFormSection(lpaQuestionnaireFromSections());
  }, [instructionDataObject]);

  const handleSave = (data) => {
    const updatedData = {
      InstructionData: {
        ...instructionDataObject,
        InstructionLPA_Questionnaire: data.client,
      },
    };
    const res = dispatch(updateInstruction(updatedData));
  };

  return (
    <>
      <Grid display={"flex"} flexDirection={"column"}>
        {/* <FormHeader formName={"LPA Questionnaire"} /> */}
        <LPATop />
        <Grid width={"100%"} mb={4}>
          {formSections.map((d) => {
            return (
              <Accordion
                elevation={0}
                style={{ borderBottom: "1px solid #DCDCE1", width: "100%" }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <AccordionSummary
                  style={{
                    width: "100%",
                    padding: "10px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Grid
                    width={"100%"}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={1}
                  >
                    <Grid
                      width={"100%"}
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                    >
                      <span
                        className="normal_normal_semibold_20_Manrope green"
                        style={{
                          width: "100%",
                          marginRight: "auto",
                        }}
                      >
                        {d.title}
                        {d.titleSmallBlack ? (
                          <span
                            className="normal_normal_600_12_Manrope dark_grey"
                            style={{
                              width: "100%",
                              marginLeft: "10px",
                              marginRight: "auto",
                            }}
                          >
                            {d.titleSmallBlack}
                          </span>
                        ) : null}
                        {d.titleBigBlack ? (
                          <span
                            className="normal_normal_600_16_Manrope dark_grey"
                            style={{
                              width: "100%",
                              marginLeft: "10px",
                              marginRight: "auto",
                            }}
                          >
                            {d.titleBigBlack}
                          </span>
                        ) : null}
                      </span>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <>{d?.component}</>
                </AccordionDetails>
              </Accordion>
              // </Grid>
            );
          })}
        </Grid>
        <LPADeclaration arr={arr} isJoint={isJoint} />
        {/* <Grid width={"100%"} textAlign={"right"} mt={5}>
          <SubmitButton onClick={handleSubmit(handleSave, handleErrors)} />
        </Grid> */}
      </Grid>
    </>
  );
};

export default LPAQuestionnaireForm;
