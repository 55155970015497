import { Grid, Typography } from "@mui/material";
import React from "react";

import { OutlinedGreenButton } from "../../../../components/buttons";

const FormHeader = ({ formName, onSave, generatePDF }) => {
  return (
    <>
      <Grid
        className="factFind_label_save_draft_position_fixed"
        gap={1}
        alignItems={"center"}
        display={"flex"}
      >
        <Typography
          ml={formName === "Address Book" ? 4 : 0}
          pl={2}
          className="serif_display_regular_26 dark_grey"
        >
          {formName}
        </Typography>
        {onSave && (
          <Typography>
            <OutlinedGreenButton onClick={onSave} label="Save as Draft" />
          </Typography>
        )}
        {generatePDF && (
          <Typography>
            <OutlinedGreenButton onClick={generatePDF} label="Generate PDF" />
          </Typography>
        )}
      </Grid>
    </>
  );
};

export default FormHeader;
