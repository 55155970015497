import JoditEditor from "jodit-react";
import React, { useEffect, useMemo, useRef, useState } from "react";

function JoditEditotRichText(props) {
  const { value, onChange = () => {} } = props;

  const editor = useRef(null);

  // file picker

  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);

  // sync files from parents
  useEffect(() => {
    if (props?.files) setSelectedFiles(props?.files);

    return () => {
      setSelectedFiles([]);
    };
  }, [props?.files]);

  const handleFileChange = (event) => {
    let files = [...selectedFiles, event.target.files[0]];
    if (props?.filesHandler) {
      props?.filesHandler(files);
    }
  };

  const customFilePickerButton = {
    name: "upload",
    icon: "upload",
    tooltip: "File Attachment",
    exec: () => {
      fileInputRef.current.click();
    },
  };

  const configMemo = useMemo(
    () => ({
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      minHeight: 500,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      toolbarSticky: false,
      buttonsMD: [
        "bold",
        // "strikethrough",
        "underline",
        "italic",
        "ul",
        "ol",
        "outdent",
        "indent",
        "font",
        "fontsize",
        "brush",
        "paragraph",
        // "table",
        // "source",
        "align",
        // "undo",
        // "redo",
        // "hr",
        // "eraser",
        // "copyformat",
        // "print",
        // "fullsize",
        // "image",
        // "video",
        // "link",
        // "|",
        // customFilePickerButton,
        // "|",
      ],
      buttonsXS: [
        "bold",
        // "strikethrough",
        "underline",
        "italic",
        "ul",
        "ol",
        "outdent",
        "indent",
        "font",
        "fontsize",
        "brush",
        "paragraph",
        // "table",
        // "source",
        "align",
        // "undo",
        // "redo",
        // "hr",
        // "eraser",
        // "copyformat",
        // "print",
        // "fullsize",
        // "image",
        // "video",
        // "link",
        // "|",
        // customFilePickerButton,
        // "|",
      ],
      buttons: [
        "bold",
        // "strikethrough",
        "underline",
        "italic",
        "ul",
        "ol",
        "outdent",
        "indent",
        "font",
        "fontsize",
        "brush",
        "paragraph",
        // "table",
        // "source",
        "align",
        // "undo",
        // "redo",
        // "hr",
        // "eraser",
        // "copyformat",
        // "print",
        // "fullsize",
        // "image",
        // "video",
        // "link",
        // "|",
        // customFilePickerButton,
        // "|",
      ],
    }),
    []
  );

  return (
    <>
      <JoditEditor
        value={value}
        ref={props?.ref}
        config={configMemo}
        onChange={(newValue) => {
          onChange(newValue);
        }}
      />
      {props?.filesHandler && (
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          multiple
          style={{ display: "none" }}
        />
      )}
    </>
  );
}

export default JoditEditotRichText;
