import { Grid, Typography } from "@mui/material";
import { RadioInput, TextInput } from "../../../../../components/inputs";

const Restrictions = ({ arr, isJoint, trustIndex }) => {
  return (
    <>
      <Grid
        width={"100%"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Grid
          width={"100%"}
          paddingY={4}
          display={"flex"}
          flexDirection={"column"}
          borderBottom={`1px solid #DCDCE1`}
          gap={0}
        >
          <Typography className="normal_normal_semibold_20_Manrope dark_grey">
            Restrictions
          </Typography>
        </Grid>
        <Grid
          width={"65%"}
          paddingY={4}
          display={"flex"}
          flexDirection={"column"}
          gap={3}
          // border={"1px solid red"}
        >
          <Grid display={"flex"} flexDirection={"column"} gap={2}>
            <Grid display={"flex"} flexDirection={"column"} gap={0}>
              <Typography
                className="manropeRegular16"
                sx={{ color: "#00CE3F" }}
              >
                Use discretion to restrict distribution if a beneficiary is made
                bankrupt or becomes insolvent
              </Typography>
              <RadioInput
                name={`InstructionTrustQuestionnaire.[${trustIndex}].restrictions.isUseDiscretion`}
                label=""
                options={[
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ]}
              />
            </Grid>
            <Grid display={"flex"} flexDirection={"column"} gap={0}>
              <Typography
                className="manropeRegular16"
                sx={{ color: "#00CE3F" }}
              >
                Restrict distribution if a beneficiary is dependent on
                substances (drugs or alcohol)
              </Typography>
              <RadioInput
                name={`InstructionTrustQuestionnaire.[${trustIndex}].restrictions.isOnSubstances`}
                label=""
                options={[
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ]}
              />
            </Grid>
            <Grid display={"flex"} flexDirection={"column"} gap={0}>
              <Typography
                className="manropeRegular16"
                sx={{ color: "#00CE3F" }}
              >
                Restrict distribution if a beneficiary has a gambling addiction
              </Typography>
              <RadioInput
                name={`InstructionTrustQuestionnaire.[${trustIndex}].restrictions.isGamblingAddicted`}
                label=""
                options={[
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ]}
              />
            </Grid>
            <Grid display={"flex"} flexDirection={"column"} gap={0}>
              <Typography
                className="manropeRegular16"
                sx={{ color: "#00CE3F" }}
              >
                Restrict distribution of assets outright and restrict
                distribution to loans
              </Typography>
              <RadioInput
                name={`InstructionTrustQuestionnaire.[${trustIndex}].restrictions.isAssetOrLoanOutright`}
                label=""
                options={[
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ]}
              />
            </Grid>
            <TextInput
              name={`InstructionTrustQuestionnaire.[${trustIndex}].restrictions.AnyGuidance`}
              label="Any other guidance, if any"
              placeholder="Notes"
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Restrictions;
