import { Grid } from "@mui/material";
import { useMemo } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  AddButton,
  ContainedGreenButton,
  OutlinedGreenButton,
  RemoveButton,
} from "../../../../../components/buttons";
import {
  DateInput,
  DropdownInput,
  RadioInput,
  TextInput,
} from "../../../../../components/inputs";
import { getUniqueID, mapLabelValue } from "../../../../../utils/util";
import { exclusionObject } from "./willQuestionnaireFormConstants";

const Exclusions = () => {
  const isJoint = useSelector((state) => state?.instruction?.isJoint);
  const arr = useSelector((state) => state?.instruction?.arr);

  const { getValues, setValue } = useFormContext();

  const client1 = useFieldArray({
    name: `InstructionWillQuestionnaire.[0].exclusions`,
  });

  const { append, remove, fields } = client1;

  const client2 = useFieldArray({
    name: `InstructionWillQuestionnaire.[1].exclusions`,
  });

  const {
    append: secondaryAppend,
    remove: secondaryRemove,
    fields: secondaryFields,
  } = client2;

  const handleAdd = (clientNumber) => {
    let newObj = { ...exclusionObject, id: getUniqueID() };
    clientNumber === 0 ? append(newObj) : secondaryAppend(newObj);
  };

  const handleRemove = (clientNumber, ind, pathName1, pathName2) => {
    if (clientNumber === 1) {
      let isMirrored = getValues(`${pathName1}`)?.mirrored || false;
      remove(ind);
      if (isMirrored) {
        secondaryRemove(ind);
      }
    } else {
      let obj = getValues(`${pathName2}`);
      let isMirrored = getValues(`${pathName2}`)?.mirrored || false;
      if (clientNumber === 2 && isMirrored) {
        setValue(`${pathName1}.mirrored`, false);
        client1.update(ind, { ...obj, mirrored: false });
      }
      secondaryRemove(ind);
    }
  };

  return (
    <>
      <Grid
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={isJoint ? "space-between" : "center"}
        borderTop={`1px solid #DCDCE1`}
        borderBottom={`1px solid #DCDCE1`}
      >
        {arr?.map((item, index) => {
          return (
            <Grid
              key={"exclusion" + index}
              width={isJoint ? "50%" : "62%"}
              borderRight={index === 0 && isJoint ? `1px solid #DCDCE1` : null}
              paddingY={4}
              padding={2}
              display={"flex"}
              flexDirection={"column"}
              gap={3}
              paddingRight={index === 0 ? 2 : 0}
              paddingLeft={index === 1 ? 2 : 0}
            >
              {(index === 0 ? fields : secondaryFields).map((card, ind) => {
                return (
                  <div
                    key={card?.id}
                    // key = {`InstructionWillQuestionnaire.[${index}].exclusions.[${ind}]`}
                  >
                    <ExclusionsForm
                      isJoint={isJoint}
                      index={index}
                      cardNo={ind}
                      handleRemove={() =>
                        handleRemove(
                          item,
                          ind,
                          `InstructionWillQuestionnaire.[0].exclusions.[${ind}]`,
                          `InstructionWillQuestionnaire.[1].exclusions.[${ind}]`
                        )
                      }
                      pathName={`InstructionWillQuestionnaire.[${index}].exclusions.[${ind}]`}
                      cardData={card}
                      cardObject={client2}
                      removeCard={secondaryRemove}
                    />
                  </div>
                );
              })}
              <Grid textAlign={"center"}>
                <AddButton onClick={() => handleAdd(index)} />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default Exclusions;

const ExclusionsForm = ({
  index,
  cardNo,
  handleRemove,
  pathName,
  cardObject,
  cardData,
  removeCard,
  isJoint
}) => {
  const { getValues, setValue, watch } = useFormContext();
  const { relationshipAddr, Gender } = useSelector(
    (state) => state?.lookupData
  );
  let genderList = useMemo(() => mapLabelValue(Gender), [Gender]);
  const mirrorPath = `${pathName}.mirrored`;
  const isMirrored = watch(mirrorPath);

  const handleMirror = () => {
    if (!isMirrored) {
      let itemToCopy = getValues(`${pathName}`);
      setValue(mirrorPath, true);
      // cardObject.append({
      cardObject.insert(cardNo, {
        ...itemToCopy,
        mirrored: true,
        mirroredBy: cardData.id,
      });
    } else {
      let index = cardObject?.fields.findIndex(
        (f) => f.mirroredBy === cardData.id
      );
      setValue(mirrorPath, false);
      index !== -1 && removeCard(index);
    }
  };
  return (
    <Grid
      boxShadow={"1px 2px 6px #00000029"}
      borderBottom={`1px solid #DCDCE1`}
      borderRadius={"16px"}
      borderRight={index === 0 ? `1px solid #DCDCE1` : null}
      padding={2}
      paddingY={3}
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      position={"relative"}
    >
      {index === 0 && isJoint ? (
        <div className="card-mirror-btn">
          {isMirrored ? (
            <ContainedGreenButton label={"Mirror"} onClick={handleMirror} />
          ) : (
            <OutlinedGreenButton label={"Mirror"} onClick={handleMirror} />
          )}
        </div>
      ) : null}
      <TextInput
        label={`Name`}
        name={`${pathName}.name`}
        placeholder="Enter name"
      />
      <Grid
        display={"flex"}
        width={"100%"}
        justifyContent={"space-between"}
        gap={2}
      >
        <Grid width={"48%"}>
          <RadioInput
            label="Gender"
            name={`${pathName}.gender`}
            options={genderList}
          />
        </Grid>
        <Grid width={"48%"}>
          <DateInput
            label={`Date of Birth`}
            name={`${pathName}.dob`}
            placeholder="Enter DOB"
          />
        </Grid>
      </Grid>
      <DropdownInput
        name={`${pathName}.relationship`}
        label="Relationship"
        options={relationshipAddr}
      />
      <TextInput
        label={`Reason of Exclusion`}
        name={`${pathName}.reasonOfExclusion`}
        placeholder="Enter reason"
      />

      <div style={{ textAlign: "right" }}>
        <RemoveButton label={"Joint"} onClick={handleRemove} />
      </div>
    </Grid>
  );
};
