import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Fab,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import moment from "moment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DueDateDialog from "../../Instructions/components/DueDateDialog";
import UpdateTaskAnswerDialog from "../../Instructions/components/UpdateTaskAnswerDialog";
import AlertComponent from "../../../components/alert";
import CommentsDrawer from "../../Instructions/components/CommentsDrawer";
import Loader from "../../../components/Loader";
import { ClearTaskData } from "../../../slice/task";
import {
  allocateOrCompleteTask,
  getInstructionProducts,
  getInstructionProductsTaskById,
} from "../../../thunks/instruction";
import { updateTaskCompleteAndPick } from "../../../slice/instruction";
import { NOTIFICATION_MESSAGE, taskStatus } from "../../../utils/constant";
import { showSnackbar } from "../../../slice/snackbarSlice";
import {
  allocateOrCompleteClientTask,
  getClientTask,
} from "../../../thunks/client";
import { updateClientTaskCompleteAndPick } from "../../../slice/client";

const ClientTaskList = ({ reload }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { instructionProductId, instructionId, taskId } = useSelector(
    (state) => state?.task?.TaskTypeData
  );
  const {
    taskLoading,
    clientTasksData,
    clientUserData: clientInstruction,
  } = useSelector((store) => store?.client);
  const ClientsTasks = taskId
    ? clientTasksData?.filter((d) => d?.id === taskId)
    : clientTasksData;

  const instructionData = useSelector(
    (state) => state?.instruction?.instructionDashboardData
  );

  const [isLoader, setLoader] = useState(false);
  const [selectedDueDateData, setSelectedDueDateData] = useState(null);
  const [isTaskCompleted, setTaskComplete] = useState(false);
  const [completeTaskId, setCompleteTaskId] = useState(null);
  const [selectedTaskData, setSelectedTaskData] = useState(null);
  const [selectedAnswerData, setSelectedAnswerData] = useState(null);
  const [updateTaskAnswerDialog, setUpdateTaskAnswerDialog] = useState(false);
  const [isTaskPick, setTaskPick] = useState(false);
  const [pickTaskId, setPickTaskId] = useState(null);
  const [isTaskDroped, setTaskDroped] = useState(false);
  const [dropeTaskId, setDropTaskId] = useState(null);
  const [isTaskComment, setIsTaskComment] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);
  const [isOpenDueDate, setOpenDueDate] = useState(false);
  const [isViewOnly, setViewOnly] = useState(false);

  /* Method to get instruction task by providing product ID as parameter
  --> If product name === Instruction it returns generic task else product basis.
  */
  const getClientLevelTasks = async () => {
    try {
      setLoader(true);
      await dispatch(getClientTask(params?.id));
      setLoader(false);
    } catch (error) {
      setLoader(false);
      return error;
    }
  };

  /* Method to complete task */
  const handleClickCompleteTask = (data) => {
    setTaskComplete(true);
    setCompleteTaskId(data);
  };

  /* Method to open update tasks answer dialog 
    open once click on answer in tasks section. */
  const openUpdateTaskAnswerDialog = (answer, data, isIndividualTask) => {
    setSelectedTaskData({ ...data, isIndividualTask, isClientTask: true });
    setSelectedAnswerData(answer);
    setUpdateTaskAnswerDialog(true);
  };

  /* Method to click pick task */
  const handleClickPickTask = (data) => {
    setTaskPick(true);
    setPickTaskId(data?.id);
  };

  /* Method to get Task Comments */
  const viewTaskComments = (data) => {
    setSelectedTaskData({ ...data, isClientTask: true });
    setViewOnly(true);
    setUpdateTaskAnswerDialog(true);
  };

  /* Success method to update task status to Picked */
  const handlePickTaskSuccess = async (taskStatus) => {
    setTaskPick(false);
    const payload = {
      id: pickTaskId,
      status: taskStatus,
    };
    try {
      setLoader(true);
      const response = await dispatch(
        allocateOrCompleteClientTask(payload)
      ).unwrap();
      if (response) {
        getClientLevelTasks(); //To update ui
        setLoader(false);
        const updateTaskLocallyPayload = {
          id: pickTaskId,
          data: response,
        };
        dispatch(updateClientTaskCompleteAndPick(updateTaskLocallyPayload));
        dispatch(
          showSnackbar({
            show: true,
            message: NOTIFICATION_MESSAGE?.ASSIGN_TASK_SUCCESS,
            severity: "success",
          })
        );
      }
      setTaskPick(false);
    } catch (error) {
      setTaskPick(false);
      setLoader(false);
      dispatch(
        showSnackbar({
          show: true,
          message: error?.message,
          severity: "error",
        })
      );
    }
  };

  /* Success method to update task status to Completed */
  const handleCompleteTaskSuccess = async () => {
    setTaskComplete(false);
    const payload = {
      id: completeTaskId?.id,
      status: "Completed",
    };
    try {
      setLoader(true);
      const response = await dispatch(
        allocateOrCompleteClientTask(payload)
      ).unwrap();
      if (response) {
        // clear filter
        handleClearFilter();
        getClientLevelTasks(); //to update ui
        setLoader(false);
        const updateTaskLocallyPayload = {
          id: completeTaskId?.id,
          data: response,
        };
        dispatch(updateClientTaskCompleteAndPick(updateTaskLocallyPayload));
        dispatch(
          showSnackbar({
            show: true,
            message: NOTIFICATION_MESSAGE?.COMPLETE_TASK_SUCCESS,
            severity: "success",
          })
        );
      }
      setTaskPick(false);
    } catch (error) {
      setTaskPick(false);
      setLoader(false);
      dispatch(
        showSnackbar({
          show: true,
          message: error?.message,
          severity: "error",
        })
      );
    }
  };

  /* Success method to update task status to Droped */
  const handleDropTaskSuccess = async () => {
    setTaskDroped(false);
    const payload = {
      id: dropeTaskId,
      status: "Dropped",
    };
    try {
      setLoader(true);
      const response = await dispatch(allocateOrCompleteTask(payload)).unwrap();
      if (response) {
        setLoader(false);
        const updateTaskLocallyPayload = {
          id: dropeTaskId,
          data: response,
        };
        dispatch(updateTaskCompleteAndPick(updateTaskLocallyPayload));
        dispatch(
          showSnackbar({
            show: true,
            message: NOTIFICATION_MESSAGE?.DROP_TASK_SUCCESS,
            severity: "success",
          })
        );
      }
      setTaskDroped(false);
    } catch (error) {
      setTaskDroped(false);
      setLoader(false);
      dispatch(
        showSnackbar({
          show: true,
          message: error?.message,
          severity: "error",
        })
      );
    }
  };

  // Method to check date if date is greater then current date
  const checkDate = (data) => {
    // Parse the provided date into a Moment.js object
    const tempCurrentDate = moment().format("DD/MM/YYYY hh:mm A");
    const tempProvidedDate = moment(data?.dueDate || null).format(
      "DD/MM/YYYY hh:mm A"
    );
    const tempEndDate = moment(data?.endDate || null).format(
      "DD/MM/YYYY hh:mm A"
    );
    const providedDate = moment(tempProvidedDate, "DD/MM/YYYY hh:mm A");
    const currentDate = moment(tempCurrentDate, "DD/MM/YYYY hh:mm A");
    const endDate = moment(tempEndDate, "DD/MM/YYYY hh:mm A");
    // Compare the dates
    const isDateBeforeComplete = providedDate?.isBefore(currentDate);
    const isDateAfterComplete = providedDate?.isBefore(endDate);
    if (isDateBeforeComplete && !data?.isCompleted) {
      return true;
    } else if (isDateAfterComplete && data?.isCompleted) {
      return true;
    } else {
      return false;
    }
  };

  /* Method to open due date dialog if task status is not completed */
  const handleClickOpenDueDate = (data) => {
    let tempObj = {
      id: data?.id,
      dueDate: data?.dueDate,
      taskType: data?.taskType,
    };
    setSelectedDueDateData(tempObj);
    if (data?.status !== "Completed") {
      setOpenDueDate(true);
    }
  };

  //METHOD TO CLEAR FILTERS
  const handleClearFilter = () => {
    dispatch(ClearTaskData());
    getClientLevelTasks();
  };

  useEffect(() => {
    getClientLevelTasks();
  }, [reload]);

  return (
    <>
      {/* Tasks Comments Drawer */}
      {isTaskComment && (
        <CommentsDrawer
          open={isTaskComment}
          handleClose={() => setIsTaskComment(false)}
          selectedComment={selectedComment}
        />
      )}

      {/* Dialog for Pick Task Confirmation */}
      {isTaskPick && (
        <AlertComponent
          open={isTaskPick}
          handleSuccess={() => handlePickTaskSuccess(taskStatus?.picked)}
          handleClose={() => setTaskPick(false)}
          message="Are you sure, want to assign the task to yourself"
        />
      )}

      {/* Dialog for Complete Task Confirmation */}
      {isTaskCompleted && (
        <AlertComponent
          open={isTaskCompleted}
          handleSuccess={handleCompleteTaskSuccess}
          handleClose={() => setTaskComplete(false)}
          message="Are you sure you want to complete the task?"
        />
      )}

      {/* Dialog for Drop Task Confirmation */}
      {isTaskDroped && (
        <AlertComponent
          open={isTaskDroped}
          handleSuccess={handleDropTaskSuccess}
          handleClose={() => setTaskDroped(false)}
          message="Are you sure you want to drop the task?"
        />
      )}

      {/* Dialog for Update task Answers */}
      {updateTaskAnswerDialog && (
        <UpdateTaskAnswerDialog
          open={updateTaskAnswerDialog}
          handleClose={() => {
            setUpdateTaskAnswerDialog(false);
            setViewOnly(false);
          }}
          selectedAnswer={selectedAnswerData}
          selectedTask={selectedTaskData}
          instructionId={instructionData?.id}
          isViewOnly={isViewOnly}
          clientInstruction={clientInstruction}
        />
      )}

      {/* Dialog for update due date */}
      {isOpenDueDate && (
        <DueDateDialog
          open={isOpenDueDate}
          handleClose={() => {
            setOpenDueDate(false);
            setSelectedDueDateData(null);
            getClientLevelTasks();
          }}
          selectedData={selectedDueDateData}
          isClientTask={true}
        />
      )}

      {/* Loader Component */}
      {isLoader && <Loader />}

      {taskId && (
        <Tooltip title="Clear filter">
          <Fab
            color="primary"
            aria-label="add"
            style={{
              position: "absolute",
              top: "8.8%",
              left: "22.5%",
              backgroundColor: "#00ce3f",
              backgroundColor: "#00ce3f",
            }}
            size="small"
            onClick={() => handleClearFilter()}
          >
            <FilterAltOffIcon />
          </Fab>
        </Tooltip>
      )}
      <Grid
        style={{ borderRight: "1px solid #E5FAEB", height: "100%" }}
        border={0}
      >
        <Grid
          container
          justify="flex-start"
          mt={0}
          mb={1}
          border={0}
          padding={1}
          className="instruction_dashboard_scrollbar"
          style={{
            height: "100%",
            boxSizing: "border-box",
            overflow: "scroll",
          }}
        >
          {taskLoading ? (
            <Grid
              item
              xs={12}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography style={{ color: "#00CE3F", marginTop: "1rem" }}>
                Loading Tasks...
              </Typography>
            </Grid>
          ) : ClientsTasks?.length > 0 ? (
            ClientsTasks?.map((d) => (
              <Grid
                display={"flex"}
                flexDirection={"column"}
                width={"100%"}
                pr={2}
              >
                <Grid
                  key={d?.id}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width={"100%"}
                  pt={1}
                >
                  <Typography className="serif_display_regular_20">
                    {/* {d?.milestoneName || "NA"} */}
                    {d?.status === "Completed" ? (
                      <Tooltip title="Task is already completed">
                        <span
                          className={`normal_normal_600_14_Manrope ${
                            checkDate(d) ? "red" : "green"
                          }`}
                          style={{ marginLeft: "1rem" }}
                        >
                          (Due by:-{" "}
                          {d?.dueDate
                            ? moment(new Date(d?.dueDate)).format(
                                "DD/MM/YYYY hh:mm A"
                              )
                            : "NA"}
                          )
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Update Due Date">
                        <span
                          onClick={() => handleClickOpenDueDate(d)}
                          className={`normal_normal_600_14_Manrope ${
                            checkDate(d) ? "red" : "green"
                          }`}
                          style={{ marginLeft: "1rem", cursor: "pointer" }}
                        >
                          (Due by:-{" "}
                          {d?.dueDate
                            ? moment(new Date(d?.dueDate)).format(
                                "DD/MM/YYYY hh:mm A"
                              )
                            : "NA"}
                          )
                        </span>
                      </Tooltip>
                    )}
                  </Typography>
                  <Typography className="normal_normal_medium_14_Manrope dark_grey">
                    <span className="normal_normal_600_14_Manrope dark_grey">
                      Actor:-
                    </span>
                    {` ${d?.actor || "NA"}`}
                  </Typography>
                </Grid>
                <Grid
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width={"100%"}
                  py={1}
                >
                  <Typography className="normal_normal_18_Manrope">
                    <Tooltip
                      title={
                        d?.status === "Completed"
                          ? "Completed Task"
                          : "Click to complete task"
                      }
                    >
                      <IconButton
                        onClick={
                          d?.status === taskStatus?.picked
                            ? () => handleClickCompleteTask(d)
                            : null
                        }
                      >
                        <CheckCircleIcon
                          sx={{
                            color: `${
                              d?.status === "Completed" ? "#00CE3F" : "#dee2e6"
                            }`,
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                    {d?.name || "NA"}
                  </Typography>
                  <Typography className="normal_normal_medium_14_Manrope dark_grey">
                    <span className="normal_normal_600_14_Manrope dark_grey">
                      {`${
                        d?.status === "Completed"
                          ? "Completed By:- "
                          : "Assigned to:- "
                      }`}
                    </span>
                    {`${
                      d?.assigned
                        ? d?.assigned?.firstName + " " + d?.assigned?.lastName
                        : d?.actor || "NA"
                    }`}
                  </Typography>
                </Grid>

                {/* ---------------------------Assign button and Modified by section-------- */}
                {!d?.isCompleted ? (
                  <Grid
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    width={"100%"}
                    py={1}
                  >
                    {/* Assign task to yourself */}
                    <Grid>
                      {d?.isVisible ? (
                        <Button
                          size="small"
                          style={{
                            boxShadow: "none",
                            backgroundColor: "#00ce3f",
                            borderRadius: "24px",
                            // marginTop: "1rem",
                            marginLeft: "0.8rem",
                            textTransform: "none",
                            display: `${
                              !d?.isAssignedToCurrentUser ||
                              d?.status === "Picked" ||
                              d?.status === "Completed"
                                ? "none"
                                : ""
                            }`,
                          }}
                          variant="contained"
                          onClick={
                            d?.status === "Picked" || d?.status === "Completed"
                              ? null
                              : () => handleClickPickTask(d)
                          }
                        >
                          Add to in-progress
                        </Button>
                      ) : null}
                    </Grid>
                  </Grid>
                ) : null}
                {/*-----------------Comment button---------------  */}
                <Grid
                  mt={0}
                  pb={1}
                  style={{
                    display: `${
                      d?.status === "Picked" || d?.status === "Completed"
                        ? "flex"
                        : "none"
                    }`,
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  {d?.TaskAns?.length > 0 &&
                    d?.TaskAns?.map((answer) => (
                      <>
                        <Grid
                          key={d?.id}
                          style={{
                            display: "flex",
                            gap: 10,
                            alignItems: "center",
                          }}
                        >
                          <Button
                            disabled={
                              !d?.finalTruthWhatActionCanHeTakeOnTaskLikeGivingComment ||
                              d?.status === null
                            }
                            fullWidth
                            size="small"
                            style={{
                              borderRadius: "24px",
                              boxShadow: "none",
                              borderColor: "#3D4740",
                              marginLeft: "1rem",
                            }}
                            variant="outlined"
                            className={`normal_normal_semibold_14_Manrope ${
                              !d?.finalTruthWhatActionCanHeTakeOnTaskLikeGivingComment ||
                              d?.status === null
                                ? ""
                                : "dark_grey"
                            } roundOutlinedButton`}
                            onClick={() =>
                              openUpdateTaskAnswerDialog(answer, d)
                            }
                          >
                            {answer?.name || ""}
                          </Button>
                          <IconButton onClick={() => viewTaskComments(d)}>
                            <RemoveRedEyeIcon className="green" />
                          </IconButton>
                        </Grid>

                        {d?.isCompleted && (
                          <Grid
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                            }}
                          >
                            <span
                              className="normal_normal_600_14_Manrope green"
                              style={{ marginLeft: "1rem" }}
                            >
                              (Completed On:-{" "}
                              {d?.endDate
                                ? moment(new Date(d?.endDate)).format(
                                    "DD/MM/YYYY hh:mm A"
                                  )
                                : "NA"}
                              )
                            </span>
                          </Grid>
                        )}
                      </>
                    ))}
                </Grid>
                {/* ------------DIVIDER -------------- */}
                <Grid item xs={12} mt={1} sx={{ pl: 2 }}>
                  <Divider
                    orientation="horizontal"
                    sx={{ border: `1px solid #DCDCE1` }}
                  />
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid
              item
              xs={12}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography style={{ color: "#00CE3F", marginTop: "1rem" }}>
                No Tasks For this Client...
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ClientTaskList;
