import { Grid, Typography } from "@mui/material";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import {
  AddButton,
  ContainedButton,
  ContainedGreenButton,
  OutlinedGreenButton,
  RemoveButton,
} from "../../../../../../components/buttons";
import {
  DropdownInput,
  RadioInput,
  TextInput,
} from "../../../../../../components/inputs";
import {
  beneficiariesObject,
  pptPropertyObject,
} from "../willQuestionnaireFormConstants";
import RDTForm from "./RDTForm";

const PPTItem = ({ arr, isJoint }) => {
  const client1 = useFieldArray({
    name: `InstructionWillQuestionnaire.[0].willTrusts.propertyProtectionTrust`,
  });

  const { append, remove, fields } = client1;

  const client2 = useFieldArray({
    name: `InstructionWillQuestionnaire.[1].willTrusts.propertyProtectionTrust`,
  });

  const {
    append: secondaryAppend,
    remove: secondaryRemove,
    fields: secondaryFields,
  } = client2;

  const handleAdd = (clientNumber) => {
    clientNumber === 0
      ? append(pptPropertyObject)
      : secondaryAppend(pptPropertyObject);
  };

  const handleRemove = (clientNumber, ind) => {
    if (clientNumber === 1) {
      remove(ind);
    } else {
      secondaryRemove(ind);
    }
  };
  return (
    <>
      <Grid width={"100%"}>
        <Grid
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          borderBottom={`1px solid #DCDCE1`}
          padding={2}
        >
          <Grid width={"85%"} display={"flex"} flexDirection={"column"} gap={1}>
            <Typography>Property Protection Trust (PPT)</Typography>
            <Typography sx={{ color: "#00CE3F", fontSize: "12px" }}>
              To prevent redistribution of assets on 1st death
            </Typography>
          </Grid>
          <Grid width={"15%"}>
            <RadioInput
              label=""
              name={`InstructionWillQuestionnaire.[0].willTrusts.isPPT`}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
            />
          </Grid>
        </Grid>

        <Grid
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={isJoint ? "space-between" : "center"}
          borderTop={`1px solid #DCDCE1`}
          borderBottom={`1px solid #DCDCE1`}
        >
          {arr?.map((item, index) => {
            return (
              <Grid
                width={isJoint ? "50%" : "62%"}
                borderRight={
                  index === 0 && isJoint ? `1px solid #DCDCE1` : null
                }
                padding={2}
                display={"flex"}
                flexDirection={"column"}
                gap={2}
              >
                <Grid width={"100%"} display={"flex"} flexDirection={"row"}>
                  <Grid
                    width={"100%"}
                    paddingY={4}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={3}
                  >
                    {(index === 0 ? fields : secondaryFields).map(
                      (card, ind) => {
                        return (
                          <PPTForm
                            isInstructionJoint={isJoint}
                            index={index}
                            cardNo={ind}
                            pathName={`InstructionWillQuestionnaire.[${index}].willTrusts.propertyProtectionTrust[${ind}]`}
                            handleRemove={() => handleRemove(item, ind)}
                          />
                        );
                      }
                    )}
                    <Grid textAlign={"center"}>
                      <AddButton onClick={() => handleAdd(index)} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
};

export default PPTItem;

const PPTForm = ({ index, handleRemove, pathName, isInstructionJoint }) => {
  const { getValues, setValue } = useFormContext();
  const client1 = useFieldArray({
    name: `${pathName}.beneficiary`,
  });

  const { append, remove, fields } = client1;

  const client2 = useFieldArray({
    name: `${pathName}.beneficiary`,
  });

  const {
    append: secondaryAppend,
    remove: secondaryRemove,
    fields: secondaryFields,
  } = client2;

  let rto_dropdown_values = [];
  let client1Props = getValues(
    "InstructionClients.[0].InstructionFactFind.properties"
  );
  // isInstructionJoint && (rto_dropdown_values[1] = getValues('InstructionClients.[1].InstructionFactFind.properties'));
  const dropdown_val_1 = client1Props?.length
    ? client1Props?.map((item) => {
        return { label: item?.address?.street, value: item?.address?.street };
      })
    : [];
  let dropdown_val_2 = [];
  if (isInstructionJoint) {
    let client2Props = getValues(
      "InstructionClients.[1].InstructionFactFind.properties"
    );

    const dropdown_val_1_joint = client1Props?.length
      ? client1Props
          ?.filter((prop) => prop.isJoint === true)
          .map((item) => {
            return {
              label: item?.address?.street,
              value: item?.address?.street,
            };
          })
      : [];

    const dropdown_val_2_data = client2Props?.length
      ? client2Props?.map((item) => {
          return { label: item?.address?.street, value: item?.address?.street };
        })
      : [];

    dropdown_val_2 = [...dropdown_val_1_joint, ...dropdown_val_2_data];
  }

  const handleAdd1 = () => {
    append(beneficiariesObject);
  };

  const handleRemove1 = (ind) => {
    remove(ind);
  };

  // handle Joint
  const handleJoint = () => {
    let isJoint = getValues(`${pathName}.isJoint`) || false;
    setValue(`${pathName}.isJoint`, !isJoint);
  };

  const isCardJoint = useWatch({
    name: `${pathName}.isJoint`,
  });

  return (
    <Grid
      boxShadow={"1px 2px 6px #00000029"}
      borderBottom={`1px solid #DCDCE1`}
      borderRadius={"16px"}
      borderRight={index === 0 ? `1px solid #DCDCE1` : null}
      padding={2}
      paddingY={3}
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      position={"relative"}
    >
      {index === 0 && isInstructionJoint ? (
        <div className="card-mirror-btn">
          {isCardJoint ? (
            <ContainedGreenButton label={"Joint"} onClick={handleJoint} />
          ) : (
            <OutlinedGreenButton label={"Joint"} onClick={handleJoint} />
          )}
        </div>
      ) : null}
      {/* todo drp ref: rto_dropdown_values*/}
      <DropdownInput
        name={`${pathName}.property.name`}
        label="Property"
        options={index == 0 ? dropdown_val_1 : dropdown_val_2}
      />

      <Grid>
        <TextInput
          label={`Name of Life Tenant`}
          name={`${pathName}.lifeTenantName`}
          placeholder="Enter name"
        />
      </Grid>

      <Grid display={"flex"} flexDirection={"column"} gap={2}>
        <Typography sx={{ fontSize: "12px", color: "#00CE3F" }}>
          Do you wish to impose any of the following restrictions?
        </Typography>
        <Grid display={"flex"} justifyContent={"space-between"}>
          <Grid width={"65%"}>
            Subject to ongoing maintenance & insurance of property?
          </Grid>
          <Grid width={"30%"}>
            <RadioInput
              label=""
              name={`${pathName}.ongoingMaintenancePropertyRestriction`}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
            />
          </Grid>
        </Grid>
        <Grid display={"flex"} justifyContent={"space-between"}>
          <Grid width={"65%"}>
            Right to sell and purchase another house in the same trusts?
          </Grid>
          <Grid width={"30%"}>
            <RadioInput
              label=""
              name={`${pathName}.sellAndPurchaseHouseRestriction`}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
            />
          </Grid>
        </Grid>

        {/* child card */}
        <Grid
          width={"100%"}
          paddingY={4}
          display={"flex"}
          flexDirection={"column"}
          gap={3}
        >
          {fields?.map((card, ind) => {
            return (
              <RDTForm
                index={index}
                cardNo={ind}
                handleRemove={() => handleRemove1(ind)}
                pathName={`${pathName}.beneficiary.[${ind}]`}
              />
            );
          })}
          <Grid textAlign={"left"}>
            <ContainedButton label="Add Beneficiary" onClick={handleAdd1} />
          </Grid>
        </Grid>
      </Grid>
      <div style={{ textAlign: "right" }}>
        <RemoveButton label={"Joint"} onClick={handleRemove} />
      </div>
    </Grid>
  );
};
