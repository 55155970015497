import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AlertComponent from "../../../components/alert";
import DataGridComponent from "../../../components/DataGridComponent";
import Loader from "../../../components/Loader";
import { showSnackbar } from "../../../slice/snackbarSlice";
import { transferInstructionToConsultant } from "../../../thunks/instruction";
import { getIntroducer } from "../../../thunks/user";
import { NOTIFICATION_MESSAGE } from "../../../utils/constant";

const TransferIntroducerDialog = ({ open, handleClose, instructionId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoader, setLoader] = useState(false);
  const [isTransfer, setIsTransfer] = useState(false);
  const [selectedIntroducer, setSelectedIntroducer] = useState(null);
  const [introducers, setIntroducers] = useState([]);
  const [filterIntroducer, setFilterIntroducer] = useState([]);
  const handleClickTranserInstruction = (params) => {
    setSelectedIntroducer(params?.row?.id);
    setIsTransfer(true);
  };
  const columns = [
    {
      field: "id",
      headerName: "Id",
      minWidth: 10,
      sortable: false,
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 220,
      sortable: false,
      renderCell: (params) => {
        return <>{`${params?.row?.firstName} ${params?.row?.lastName}`}</>;
      },
    },
    {
      field: "action",
      headerName: "Transfer Instruction",
      minWidth: 180,
      sortable: false,
      renderCell: (params) => {
        return (
          <Button
            size="small"
            fullWidth
            variant="outlined"
            className="meeting_log_btn normal_normal_semibold_14_Manrope green"
            style={{
              borderRadius: "24px",
              margin: 1,
              border: "1px solid #00ce3f",
            }}
            onClick={() => handleClickTranserInstruction(params)}
          >
            Transfer
          </Button>
        );
      },
    },
  ];
  /* method to get Introducers */
  const getIntroducersData = async () => {
    try {
      setLoader(true);
      const response = await dispatch(getIntroducer());

      if (response?.payload?.length > 0) {
        // Create a copy of the payload array and sort it by firstName
        const sortedData = [...response?.payload].sort((a, b) => {
          return a?.firstName?.localeCompare(b?.firstName);
        });

        // Set sorted data to state
        setIntroducers(sortedData);
        setFilterIntroducer(sortedData);
      } else {
        setIntroducers([]);
        setFilterIntroducer([]);
      }
    } catch (error) {
      console.error("Error fetching introducers:", error);
    } finally {
      setLoader(false);
    }
  };

  //FINAL TRANSFER CALL
  const successTransferInstruction = async () => {
    try {
      setIsTransfer(false);
      setLoader(true);
      const payload = {
        instructionId: instructionId,
        counsultantIdTo: selectedIntroducer,
      };
      const response = await dispatch(
        transferInstructionToConsultant(payload)
      ).unwrap();
      if (response) {
        setLoader(false);
        dispatch(
          showSnackbar({
            show: true,
            message: NOTIFICATION_MESSAGE?.SUCCESS_TRANSFER_INSTRUCTION,
            severity: "success",
          })
        );
        handleClose();
        navigate("/app/instructions/list");
      }
    } catch (error) {
      setLoader(false);
      dispatch(
        showSnackbar({
          show: true,
          message: error?.message,
          severity: "error",
        })
      );
      handleClose();
    }
  };

  // Method to filter introducerData based on search with firstName and lastName with spacing between them
  const handleChangeSearch = (value) => {
    const searchValue = value.toLowerCase();
    const filteredData = filterIntroducer?.filter((data) => {
      const fullName = `${data?.firstName} ${data?.lastName}`.toLowerCase();
      return fullName.includes(searchValue);
    });
    setIntroducers(filteredData);
  };

  useEffect(() => {
    getIntroducersData();
  }, []);
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
        PaperProps={{ sx: { borderRadius: "28px", paddingBottom: 0 } }}
      >
        {isTransfer && (
          <AlertComponent
            open={isTransfer}
            handleSuccess={successTransferInstruction}
            handleClose={() => setIsTransfer(false)}
            message="Are you sure you want to transfer instruction?"
          />
        )}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        {/* Loader Component */}
        {isLoader && <Loader />}
        <DialogContent
          dividers={true}
          sx={{ marginRight: "5px" }}
          className="instruction_dashboard_scrollbar"
        >
          <Grid container spacing={3}>
            <Grid
              item
              xs={10}
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: 40,
                alignItems: "center",
              }}
            >
              <Typography
                className="serif_display_regular_26 green"
                textAlign={"center"}
              >
                Introducers
              </Typography>
              <TextField
                fullWidth
                className="textField_outlined_border"
                placeholder="search..."
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "30px",
                  },
                }}
                onChange={(e) => handleChangeSearch(e?.target?.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} style={{ width: "100%", height: "75vh" }}>
              <DataGridComponent
                columns={columns}
                rows={introducers}
                onRowClick={() => null}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TransferIntroducerDialog;
