import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import Loader from "../../../components/Loader";
import CloseIcon from "@mui/icons-material/Close";

const PhoneDialog = ({ handleClose, open }) => {
  return (
    <Dialog onClose={() => handleClose()} open={open} fullWidth maxWidth="lg">
      {false && <Loader />}
      <IconButton
        aria-label="close"
        onClick={() => handleClose()}
        sx={{
          position: "absolute",
          right: 8,
          top: 10,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle textAlign={"center"}>
        <Typography className="serif_display_regular_26 green">
          Phone
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid
          style={{
            minHeight:"300px",
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography>Feature coming soon</Typography>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PhoneDialog;
