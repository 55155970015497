import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import GroupIcon from "@mui/icons-material/Group";
import DownloadIcon from "@mui/icons-material/Download";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import {
  clearDocuments,
  documentData,
  removeDocument,
  updateFormData,
  uploadSuccessData,
} from "../../../slice/documents";
import { showSnackbar } from "../../../slice/snackbarSlice";
import { getDocumentTypeById, uploadFile } from "../../../thunks/documents";
import {
  getCustomInstructionTaskAnswerHistory,
  getInstructionTaskAnswerDetails,
  getInstructionTaskAnswerHistory,
  readImage,
  updateIndividualInstructionTaskAnswer,
  updateInstructionTaskAnswer,
} from "../../../thunks/instruction";
import { NOTIFICATION_MESSAGE } from "../../../utils/constant";
import { downloadDocument } from "../../../utils/util";
import { useLocation, useParams } from "react-router-dom";
import {
  getClientTaskAnswerHistory,
  updateClientTaskAnswer,
} from "../../../thunks/client";
import { wrap } from "lodash";
import { param } from "jquery";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const UpdateTaskAnswerDialog = ({
  open,
  handleClose,
  selectedAnswer,
  selectedTask,
  instructionId,
  isIndividualTask,
  isViewOnly = false,
  clientInstruction,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const documentsData = useSelector((state) => state?.document?.documentData);
  const lookups = useSelector((state) => state?.lookupData);
  let { "Document Type": documentType } = lookups || {};
  //Filter doc types for case
  const DocType = documentType?.filter((d) =>
    d?.LookupView?.some((view) =>
      location?.pathname?.includes("/app/instructions/dashboard/")
        ? view?.viewOn === "Case"
        : view?.viewOn === "Client"
    )
  );
  const [clientData, setClientData] = useState([]);
  const [answerHistory, setAnswerHistory] = useState([]);
  const [clientAnswerHistory, setClientAnswerHistory] = useState([]);
  const [lpaType, setLpaType] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isError, setError] = useState(false);
  const [fileDrag, setFileDrag] = useState(false);
  const [taskAnswerData, setTaskAnswerData] = useState({
    notes: "",
    documentUrl: "",
    ans: selectedAnswer?.name || "",
    ansId: selectedAnswer?.id || 0,
    documentId: null,
    clientId: 0,
    forClient: "",
    quantityNumber: "",
    lpaType: null,
    documentTypeId: null,
  });
  const [note, setNote] = useState(null);
  const [progress, setProgress] = useState("0/0");
  const [isUploading, setUploading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [isNote, setIsNote] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [isBoth, setIsBoth] = useState([]);
  // Method to Handle change tab value
  const handleChangeTabValue = (event, newVal) => {
    setTabValue(newVal);
  };

  // Method to read image
  const handleReadImage = async (doc) => {
    try {
      const response = await dispatch(
        readImage({ query: { filename: doc } })
      ).unwrap();
    } catch (error) {
      return error;
    }
  };

  // Method to view note
  const handleViewNote = (param) => {
    setSelectedNote(param?.notes);
    setIsNote(true);
  };

  const columns = [
    {
      id: "clientName",
      label: (
        <Typography className="table_cell_heading">Client Name</Typography>
      ),
      minWidth: 400,
    },
    {
      id: "documentTypeId",
      label: (
        <Typography className="table_cell_heading">Document Type</Typography>
      ),
      minWidth: 100,
    },
    {
      id: "documentSubTypeId",
      label: (
        <Typography className="table_cell_heading">Document SubType</Typography>
      ),
      minWidth: 150,
    },
    {
      id: "fileName",
      label: (
        <Typography className="table_cell_heading">
          Original file name
        </Typography>
      ),
      maxWidth: 100,
    },
    {
      id: "modifiedFileName",
      label: <Typography className="table_cell_heading">File name</Typography>,
      maxWidth: 100,
    },
    {
      id: "action",
      label: <Typography className="table_cell_heading">Action</Typography>,
      maxWidth: 100,
    },
  ];
  const clientColumns = [
    {
      id: "documentTypeId",
      label: (
        <Typography className="table_cell_heading">Document Type</Typography>
      ),
      minWidth: 100,
    },
    {
      id: "clienInstructions",
      label: (
        <Typography className="table_cell_heading">
          Client's Instruction
        </Typography>
      ),
      minWidth: 400,
    },
    {
      id: "documentSubTypeId",
      label: (
        <Typography className="table_cell_heading">Document SubType</Typography>
      ),
      minWidth: 150,
    },
    {
      id: "fileName",
      label: (
        <Typography className="table_cell_heading">
          Original file name
        </Typography>
      ),
      maxWidth: 100,
    },
    {
      id: "modifiedFileName",
      label: <Typography className="table_cell_heading">File name</Typography>,
      maxWidth: 100,
    },
    {
      id: "action",
      label: <Typography className="table_cell_heading">Action</Typography>,
      maxWidth: 100,
    },
  ];
  const UPDATE_TASK_ANSWER_COLUMNS = [
    {
      id: "clientName",
      label: (
        <Typography className="table_cell_heading">Client Name</Typography>
      ),
      minWidth: 150,
    },
    {
      id: "documentTypeId",
      label: (
        <Typography className="table_cell_heading">Document Type</Typography>
      ),
      minWidth: 200,
    },
    {
      id: "documentSubTypeId",
      label: (
        <Typography className="table_cell_heading">Document SubType</Typography>
      ),
      minWidth: 170,
    },
    {
      id: "modifiedFileName",
      label: <Typography className="table_cell_heading">File name</Typography>,
      minWidth: 250,
    },
    {
      id: "version",
      label: <Typography className="table_cell_heading">Version</Typography>,
      minWidth: 50,
    },
    {
      id: "note",
      label: <Typography className="table_cell_heading">Note</Typography>,
      minWidth: 50,
    },
    {
      id: "action",
      label: <Typography className="table_cell_heading">Action</Typography>,
      maxWidth: 100,
    },
    {
      id: "fileName",
      label: (
        <Typography className="table_cell_heading">
          Original file name
        </Typography>
      ),
      minWidth: 250,
    },
  ];
  const UPDATE_CLIENT_TASK_ANSWER_COLUMNS = [
    {
      id: "documentTypeId",
      label: (
        <Typography className="table_cell_heading">Document Type</Typography>
      ),
      minWidth: 250,
    },
    {
      id: "clienInstructions",
      label: (
        <Typography className="table_cell_heading">
          Client's Instruction
        </Typography>
      ),
      minWidth: 250,
    },
    {
      id: "documentSubTypeId",
      label: (
        <Typography className="table_cell_heading">Document SubType</Typography>
      ),
      minWidth: 170,
    },
    {
      id: "modifiedFileName",
      label: <Typography className="table_cell_heading">File name</Typography>,
      minWidth: 250,
    },
    {
      id: "version",
      label: <Typography className="table_cell_heading">Version</Typography>,
      minWidth: 50,
    },
    {
      id: "note",
      label: <Typography className="table_cell_heading">Note</Typography>,
      minWidth: 50,
    },
    {
      id: "action",
      label: <Typography className="table_cell_heading">Action</Typography>,
      maxWidth: 100,
    },
    {
      id: "fileName",
      label: (
        <Typography className="table_cell_heading">
          Original file name
        </Typography>
      ),
      minWidth: 250,
    },
  ];

  /* Method to get tasks answer details by passing instruction ID */
  const getTaskAnswerDetails = async () => {
    try {
      const response = await dispatch(
        getInstructionTaskAnswerDetails(instructionId)
      ).unwrap();
      if (response) {
        if (response?.length > 1) {
          setTaskAnswerData({
            ...taskAnswerData,
            forClient: "Both",
            clientId: null,
          });
        } else {
          setTaskAnswerData({
            ...taskAnswerData,
            forClient: `${response?.[0]?.firstName || ""} ${
              response?.[0]?.lastName || ""
            }`,
            clientId: response?.[0]?.id || null,
          });
        }
        setClientData(response);
      } else {
        setClientData([]);
      }
    } catch (error) {
      return error;
    }
  };

  /* Method to get instruction task answer history --> shows in table */
  const getInstructionTaskAnswerHistoryData = async () => {
    if (selectedTask?.taskType === "Custom") {
      try {
        setLoader(true);
        const response = await dispatch(
          getCustomInstructionTaskAnswerHistory(selectedTask?.id)
        ).unwrap();
        if (response) {
          setAnswerHistory(response);
          setLoader(false);
        } else {
          setAnswerHistory([]);
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);
        return error;
      }
    } else {
      try {
        setLoader(true);
        const id = selectedTask?.isIndividualTask
          ? selectedTask?.instructionTaskId
          : selectedTask?.id;
        const response = await dispatch(
          getInstructionTaskAnswerHistory(id)
        ).unwrap();
        if (response) {
          setAnswerHistory(response);
          setLoader(false);
        } else {
          setAnswerHistory([]);
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);
        return error;
      }
    }
  };
  const getClientTaskAnswerHistoryData = async () => {
    try {
      setLoader(true);
      const response = await dispatch(
        getClientTaskAnswerHistory(selectedTask?.id)
      ).unwrap();
      if (response) {
        setClientAnswerHistory(response);
        setLoader(false);
      } else {
        setClientAnswerHistory([]);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      return error;
    }
  };

  /* Method to handle add file with client name, documentType, subType */
  const handleFileUpload = (e, key) => {
    dispatch(documentData(e?.target?.files));
  };

  /* Method to save tasks and answer */
  const handleSaveTaskAndAnswer = async (data) => {
    if (data?.length > 0) {
      try {
        const payload = {
          tempData: data,
          id: selectedTask?.id,
          // instructionId: documentsData?.[0]?.clientId,
        };
        const updateMethod = selectedTask?.isIndividualTask
          ? updateIndividualInstructionTaskAnswer
          : selectedTask?.isClientTask
          ? updateClientTaskAnswer
          : updateInstructionTaskAnswer;
        setLoader(true);
        const response = await dispatch(updateMethod(payload)).unwrap();
        if (response) {
          setLoader(false);
          // handleCloseDialog();
          dispatch(
            showSnackbar({
              show: true,
              message: NOTIFICATION_MESSAGE?.TASK_ANSWER_UPDATE_SUCCESS,
              severity: "success",
            })
          );
          setNote("");
          dispatch(clearDocuments());
          getInstructionTaskAnswerHistoryData();
          selectedTask?.isClientTask && getClientTaskAnswerHistoryData();
        } else {
          setNote("");
          setLoader(false);
          // handleCloseDialog();
          dispatch(clearDocuments());
          getInstructionTaskAnswerHistoryData();
        }
      } catch (error) {
        setNote("");
        setLoader(false);
        dispatch(clearDocuments());
        handleCloseDialog();
        dispatch(
          showSnackbar({
            show: true,
            message: `${error?.message}`,
            severity: "error",
          })
        );
      }
    }
  };

  // HandleChange form data
  const handleChangeFormData = async (e, index, name) => {
    // If name === documentTypeId get subType and pass to slice method
    if (name === "documentTypeId") {
      const subType = await getDocumentTypeDetails(e);
      const payload = {
        e,
        index,
        name,
        subType,
      };
      dispatch(updateFormData(payload));
      //To identify selected DocumentType is Both case and client
      const DocumentType = DocType?.filter((type) => e === type?.id);
      DocumentType?.[0]?.LookupView?.length > 1
        ? setIsBoth((prev) => [...prev, index])
        : setIsBoth((prev) => prev.filter((i) => i !== index));
    }
    // If name === clientId get client selected ID client details
    else if (name === "clientId") {
      const selectedClient = clientData?.filter((d) => d?.id === e);
      const payload = {
        e,
        index,
        name,
        selectedClient: selectedTask?.isClientTask
          ? {
              firstName: clientInstruction?.firstName,
              lastName: clientInstruction?.lastName,
              isClient: selectedTask?.isClientTask && true,
            }
          : selectedClient || "Both",
      };
      dispatch(updateFormData(payload));
    } else {
      const payload = {
        e,
        index,
        name,
      };
      dispatch(updateFormData(payload));
    }
  };
  const getDocumentTypeDetails = async (id) => {
    try {
      const response = await dispatch(getDocumentTypeById(id)).unwrap();
      if (response) {
        return response;
      }
    } catch (error) {
      return error;
    }
  };

  // Handle upload documents
  const handleUpload = async () => {
    setError(false);

    // Check if document type is not selected as both if client has no instruction
    let idsOdDocumentTypeBoth = DocType?.filter(
      (d) => d?.LookupView?.length > 1
    )?.map((d) => d.id);
    let rowNumbers = [];

    for (let i = 0; i < documentsData?.length; i++) {
      let doc = documentsData[i];
      if (
        clientInstruction?.Instruction?.length === 0 &&
        idsOdDocumentTypeBoth?.includes(doc?.documentTypeId)
      ) {
        rowNumbers?.push(i + 1);
      }
    }

    if (rowNumbers?.length) {
      setError(false);
      let errorStr =
        rowNumbers?.length === 1
          ? `${rowNumbers[0]}`
          : rowNumbers
              ?.map((i, iIndex, iArr) => {
                if (iIndex === 0) {
                  return `${i}`;
                } else if (iIndex === iArr.length - 1) {
                  return ` & ${i}`;
                } else {
                  return `, ${i}`;
                }
              })
              .join("");

      dispatch(
        showSnackbar({
          show: true,
          message:
            `Change document type on row ` +
            errorStr +
            ` since client has no instruction`,
          severity: "error",
        })
      );
      return;
    }

    // Method to check clientName, documentType,documentSubType are present..
    const validateData = documentsData.some(
      (document, index) =>
        document.documentTypeId === null ||
        document.documentSubTypeId === null ||
        (isBoth?.includes(index) &&
          (document.clientName === null || document.clientId === null))
    );
    if (validateData) {
      setError(false);
      dispatch(
        showSnackbar({
          show: true,
          message: "Required client name or document type or document subType.",
          severity: "error",
        })
      );
    } else if (!note && !documentsData?.length > 0) {
      setError(true);
    } else {
      setError(false);
      let completed = 0;
      const total = documentsData?.length;

      let uploadFileResponse = "";
      const tempData = [];
      if (documentsData?.length > 0) {
        for (let index = 0; index < documentsData.length; index++) {
          const data = documentsData[index];
          let formData = new FormData();
          formData.append("myfile", data?.fileName, data?.fileName?.name);
          // Serialize the clientBody object to JSON
          //clientBodyData for instruction
          const clientBodyData = {
            clientName: data?.clientName,
            clientId: data?.clientId,
            documentTypeId: data?.documentTypeId,
            subDocumentTypeId: data?.documentSubTypeId,
            index: index,
          };
          //ClientScreenData is json for client dashboard
          const clientScreenData = {
            clientName: data?.clientName
              ? data?.clientName
              : `${clientInstruction?.firstName} ${clientInstruction?.lastName}`,
            instructionId: data?.clientId,
            documentTypeId: data?.documentTypeId,
            subDocumentTypeId: data?.documentSubTypeId,
            clientId: Number(params?.id),
            index: index,
          };
          formData.append(
            "clientBody",
            JSON.stringify(
              selectedTask?.isClientTask ? clientScreenData : clientBodyData
            )
          );
          const payload = {
            formData,
            url: selectedTask?.isClientTask
              ? `/leadclient/${clientInstruction?.id}/uploadDocs`
              : `/instruction/${instructionId}/uploadInstructionClientDocuments`,
          };
          try {
            setUploading(true);
            uploadFileResponse = await dispatch(uploadFile(payload)).unwrap();
            if (uploadFileResponse) {
              completed += 1;
              setProgress(`${completed}/${total}`);
              tempData.push({
                id: 0,
                notes: note,
                ans: taskAnswerData?.ans,
                ansId: taskAnswerData?.ansId,
                clientId: selectedTask?.isClientTask
                  ? Number(params?.id)
                  : data?.clientId || null,
                forClient:
                  (selectedTask?.isClientTask
                    ? `${clientInstruction?.firstName} ${clientInstruction?.lastName}`
                    : data?.clientName) || null,
                documentTypeId: data?.documentTypeId || null,
                subDocumentTypeId: data?.documentSubTypeId || null,
                fileInfo: uploadFileResponse,
                instructionId: data?.clientId, //clientId is instructionId incase of clientTasks.
              });
              dispatch(uploadSuccessData(uploadFileResponse));
              setUploading(false);
            } else {
              setUploading(false);
            }
          } catch (error) {
            setUploading(false);
            dispatch(
              showSnackbar({
                show: true,
                message: `${error?.message}`,
                severity: "error",
              })
            );
          }
        }
        await handleSaveTaskAndAnswer(tempData);
      } else {
        tempData.push({
          id: 0,
          notes: note,
        });
        await handleSaveTaskAndAnswer(tempData);
      }
    }
  };

  // Method to delete document locally
  const handleDeleteDocument = (index) => {
    dispatch(removeDocument(index));
  };

  // Method to clos dialog
  const handleCloseDialog = () => {
    dispatch(clearDocuments());
    handleClose();
  };

  // // handle default drag behaviour
  const handleFileDragOver = (e) => {
    e.preventDefault();
    setFileDrag(true);
  };

  // file drag function to add files to table
  const handleFileDrop = (e) => {
    e.preventDefault();
    dispatch(documentData(e.dataTransfer.files));
    setFileDrag(false);
  };

  /* UseEffect to getTaksAnswerDetails, getInstructionTaskAnswerHistoryData */
  useEffect(() => {
    const caseScreen = location?.pathname?.includes(
      "/app/instructions/dashboard/"
    );
    if (caseScreen) {
      getTaskAnswerDetails();
      getInstructionTaskAnswerHistoryData();
    } else {
      getClientTaskAnswerHistoryData();
    }
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        fullWidth={true}
        maxWidth={"xl"}
        fullScreen
      >
        {loader && <Loader />}

        {/* TaskAnswer View Notes Dialog */}
        {isNote && (
          <Dialog
            sx={{ border: 1 }}
            open={isNote}
            onClose={() => setIsNote(false)}
            fullWidth
            maxWidth={"sm"}
          >
            <IconButton
              aria-label="close"
              onClick={() => setIsNote(false)}
              sx={{
                position: "absolute",
                right: 15,
                top: 15,
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogTitle>
              {" "}
              <Typography
                className="serif_display_regular_26 green"
                textAlign={"center"}
              >
                Note
              </Typography>
            </DialogTitle>

            <DialogContent dividers={true}>
              <Typography className="normal_normal_14_Manrope dark_gray">
                {selectedNote || "Note is not available"}
              </Typography>
            </DialogContent>
          </Dialog>
        )}

        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "5px 10px",
          }}
        >
          <Grid style={{ display: "flex", gap: 10 }}>
            <Typography className="serif_display_regular_20 green">
              Task Name :-
            </Typography>
            <Typography className="normal_normal_18_Manrope dark_grey" mt={0.5}>
              {selectedTask?.name || "NA"}
            </Typography>
          </Grid>
          {/* Loading Section */}
          {isUploading && (
            <Grid item xs={8}>
              <>
                <LinearProgress color="success" style={{ width: "80%" }} />
                <Typography className="normal_normal_18_Manrope dark_grey">
                  Uploading... {progress}
                </Typography>
              </>
            </Grid>
          )}

          <IconButton aria-label="close" onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={true}>
          <>
            <Grid container spacing={3}>
              {/* LEFT HAND SIDE SECTION  */}
              {!isViewOnly && (
                <Grid item xs={3}>
                  {/* NOTE INPUT  */}
                  <TextField
                    label="Add Comment"
                    fullWidth
                    placeholder="Add Comment"
                    multiline
                    rows={10}
                    value={note || ""}
                    onChange={(e) => setNote(e?.target?.value)}
                    error={isError && !note}
                    helperText={
                      isError && !note && "Required note or document."
                    }
                  />
                  {/* ADD BTN  */}
                  <Grid xs={12}>
                    <Button
                      fullWidth
                      style={{
                        padding: "20px 0",
                        backgroundColor: "#E5FAEB",
                        cursor: "pointer",
                        borderRadius: "10px",
                        color: "rgb(0, 206, 63)",
                        boxShadow: "none",
                        marginTop: 10,
                      }}
                      component="label"
                      variant="contained"
                      startIcon={<FileUploadOutlinedIcon />}
                      type="file"
                      onChange={(event) => handleFileUpload(event)}
                    >
                      Add file
                      <VisuallyHiddenInput type="file" multiple />
                    </Button>
                  </Grid>

                  {/* Save Notes and Document */}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      fullWidth
                      style={{
                        boxShadow: "none",
                        backgroundColor: "rgb(0, 206, 63)",
                        borderRadius: "24px",
                        marginTop: 10,
                      }}
                      onClick={handleUpload}
                    >
                      Save & Upload
                    </Button>
                  </Grid>
                </Grid>
              )}
              {/* RIGHT HAND SIDE SCTION  */}
              <Grid
                item
                xs={!isViewOnly ? 9 : 12}
                onDragOver={handleFileDragOver}
                onDragEnter={() => setFileDrag(true)}
                onDragLeave={() => setFileDrag(false)}
                onDrop={handleFileDrop}
                backgroundColor={fileDrag ? "#f0fbff" : null}
                boxSizing={"border-box"}
              >
                {documentsData?.length > 0 && (
                  <>
                    {/* UPLOADED FILES  */}
                    {!selectedTask?.isClientTask && (
                      <Grid item>
                        <Grid sx={{ width: "100%", overflow: "hidden" }}>
                          <TableContainer
                            style={{ cursor: "pointer", maxHeight: 250 }}
                            className="instruction_dashboard_scrollbar"
                          >
                            <Table
                              stickyHeader
                              aria-label="sticky-table a dense table"
                              size="small"
                            >
                              <TableHead>
                                {/* Columns section */}
                                <TableRow>
                                  {columns.map((column) => (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                      style={{ minWidth: column.minWidth }}
                                    >
                                      {column.label}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                {documentsData?.map((d, index) => (
                                  <TableRow key={index}>
                                    <TableCell>
                                      <FormControl fullWidth>
                                        <Select
                                          className="formControlField"
                                          value={d?.clientId}
                                          variant="standard"
                                          size="small"
                                          onChange={(event) =>
                                            handleChangeFormData(
                                              event?.target?.value,
                                              index,
                                              "clientId"
                                            )
                                          }
                                        >
                                          {clientData?.map((data) => (
                                            <MenuItem
                                              key={data?.id}
                                              value={data?.id}
                                            >
                                              {`${data?.firstName} ${data?.lastName}`}
                                            </MenuItem>
                                          ))}
                                          <MenuItem
                                            value={"Both"}
                                            disabled={clientData?.length < 2}
                                          >
                                            Both
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    </TableCell>

                                    <TableCell>
                                      <Grid
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: 3,
                                        }}
                                      >
                                        <FormControl fullWidth>
                                          <Select
                                            className="formControlField"
                                            value={d?.documentTypeId}
                                            variant="standard"
                                            size="small"
                                            onChange={(event) =>
                                              handleChangeFormData(
                                                event?.target?.value,
                                                index,
                                                "documentTypeId"
                                              )
                                            }
                                          >
                                            {DocType?.map((data) => (
                                              <MenuItem
                                                key={data?.id}
                                                value={data?.id}
                                              >
                                                {data?.name}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                        {isBoth?.includes(index) && (
                                          <Tooltip title="This document type is applicable to both case & client">
                                            <GroupIcon
                                              style={{ color: "#01ce3f" }}
                                            />
                                          </Tooltip>
                                        )}
                                      </Grid>
                                    </TableCell>

                                    <TableCell>
                                      <FormControl fullWidth>
                                        <Select
                                          className="formControlField"
                                          value={d?.documentSubTypeId}
                                          variant="standard"
                                          size="small"
                                          onChange={(event) =>
                                            handleChangeFormData(
                                              event?.target?.value,
                                              index,
                                              "documentSubTypeId"
                                            )
                                          }
                                        >
                                          {d?.documentSubType?.map((data) => (
                                            <MenuItem
                                              key={data?.id}
                                              value={data?.id}
                                            >
                                              {data?.name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </TableCell>

                                    <TableCell>
                                      {d?.fileName?.name || "NA"}
                                    </TableCell>
                                    <TableCell>
                                      {d?.fileInfo?.modifiedFileName || "NA"}
                                    </TableCell>
                                    <TableCell>
                                      <IconButton
                                        onClick={() =>
                                          handleDeleteDocument(index)
                                        }
                                      >
                                        <DeleteIcon
                                          style={{ color: "#ff0000" }}
                                        />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </Grid>
                    )}
                    {/* ----TABLE FOR CLIENT SCREEN  */}
                    {selectedTask?.isClientTask && (
                      <Grid item>
                        <Grid sx={{ width: "100%", overflow: "hidden" }}>
                          <TableContainer
                            style={{ cursor: "pointer", maxHeight: 250 }}
                            className="instruction_dashboard_scrollbar"
                          >
                            <Table
                              stickyHeader
                              aria-label="sticky-table a dense table"
                              size="small"
                            >
                              <TableHead>
                                {/* Columns section */}
                                <TableRow>
                                  {clientColumns.map((column) => (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                      style={{ minWidth: column.minWidth }}
                                    >
                                      {column.label}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                {documentsData?.map((d, index) => (
                                  <TableRow key={index}>
                                    {/* doc type  */}
                                    <TableCell>
                                      <Grid
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: 3,
                                        }}
                                      >
                                        <FormControl fullWidth>
                                          <Select
                                            className="formControlField"
                                            value={d?.documentTypeId}
                                            variant="standard"
                                            size="small"
                                            onChange={(event) =>
                                              handleChangeFormData(
                                                event?.target?.value,
                                                index,
                                                "documentTypeId"
                                              )
                                            }
                                          >
                                            {DocType?.map((data) => (
                                              <MenuItem
                                                key={data?.id}
                                                value={data?.id}
                                              >
                                                {data?.name}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                        {isBoth?.includes(index) && (
                                          <Tooltip title="This document type is applicable to both case & client">
                                            <GroupIcon
                                              style={{ color: "#01ce3f" }}
                                            />
                                          </Tooltip>
                                        )}
                                      </Grid>
                                    </TableCell>
                                    {/* clients instructions  */}
                                    <TableCell>
                                      <Tooltip
                                        title={
                                          !isBoth?.includes(index)
                                            ? "Can't select instruction when doc type is client"
                                            : ""
                                        }
                                      >
                                        <FormControl fullWidth>
                                          <Select
                                            disabled={!isBoth?.includes(index)}
                                            className="formControlField"
                                            value={d?.clientId}
                                            variant="standard"
                                            size="small"
                                            onChange={(event) =>
                                              handleChangeFormData(
                                                event?.target?.value,
                                                index,
                                                "clientId"
                                              )
                                            }
                                          >
                                            {clientInstruction &&
                                              clientInstruction?.Instruction?.map(
                                                (data) => (
                                                  <MenuItem
                                                    key={data?.id}
                                                    value={data?.id}
                                                  >
                                                    <div>
                                                      {`case:- ${
                                                        data?.id
                                                      } , ${data?.name?.replace(
                                                        /&/g,
                                                        " & "
                                                      )} `}
                                                      <br />
                                                      {`${
                                                        data
                                                          ?.InstructionPlanSelections
                                                          ?.plan?.planName &&
                                                        data
                                                          ?.InstructionPlanSelections
                                                          ?.plan?.planName !==
                                                          ""
                                                          ? data
                                                              ?.InstructionPlanSelections
                                                              ?.plan?.planName
                                                          : "No Plan"
                                                      } , ${
                                                        data
                                                          ?.InstructionPlanSelections
                                                          ?.individualProducts
                                                          ?.length
                                                          ? data
                                                              ?.InstructionPlanSelections
                                                              ?.individualProducts?.[0]
                                                              ?.product
                                                          : "No Products"
                                                      }`}{" "}
                                                    </div>
                                                  </MenuItem>
                                                )
                                              )}
                                          </Select>
                                        </FormControl>
                                        {isBoth?.includes(index) &&
                                        clientInstruction &&
                                        !clientInstruction?.Instruction
                                          ?.length ? (
                                          <span
                                            style={{
                                              fontSize: "12px",
                                              color: "red",
                                              mt: "2px",
                                            }}
                                          >
                                            Client has no instructions
                                          </span>
                                        ) : null}
                                      </Tooltip>
                                    </TableCell>
                                    {/* doc sub type  */}
                                    <TableCell>
                                      <FormControl fullWidth>
                                        <Select
                                          className="formControlField"
                                          value={d?.documentSubTypeId}
                                          variant="standard"
                                          size="small"
                                          onChange={(event) =>
                                            handleChangeFormData(
                                              event?.target?.value,
                                              index,
                                              "documentSubTypeId"
                                            )
                                          }
                                        >
                                          {d?.documentSubType?.map((data) => (
                                            <MenuItem
                                              key={data?.id}
                                              value={data?.id}
                                            >
                                              {data?.name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </TableCell>

                                    <TableCell>
                                      {d?.fileName?.name || "NA"}
                                    </TableCell>
                                    <TableCell>
                                      {d?.fileInfo?.modifiedFileName || "NA"}
                                    </TableCell>
                                    <TableCell>
                                      <IconButton
                                        onClick={() =>
                                          handleDeleteDocument(index)
                                        }
                                      >
                                        <DeleteIcon
                                          style={{ color: "#ff0000" }}
                                        />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
                {documentsData?.length > 0 && answerHistory?.length > 0 && (
                  <>
                    {/* DIVIDER  */}
                    <Grid item xs={12}>
                      <Divider
                        sx={{
                          border: "4px solid #01ce3f",
                          margin: "1rem",
                        }}
                      />
                    </Grid>
                  </>
                )}
                {/* HISTORY OF FILES  */}
                {answerHistory?.length > 0 && !selectedTask?.isClientTask && (
                  <Grid>
                    <Grid sx={{ width: "100%", overflow: "hidden" }}>
                      <TableContainer
                        sx={{
                          maxHeight: !isViewOnly ? 400 : 550,
                          cursor: "pointer",
                        }}
                        className="instruction_dashboard_scrollbar"
                      >
                        <Table
                          stickyHeader
                          aria-label="sticky-table a dense table"
                          size="small"
                        >
                          <TableHead>
                            {/* Columns section */}
                            <TableRow>
                              {UPDATE_TASK_ANSWER_COLUMNS.map((column) => (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{ minWidth: column.minWidth }}
                                >
                                  {column.label}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {answerHistory?.map((d, index) => (
                              <TableRow key={index}>
                                {/* NAME  */}
                                <TableCell>
                                  <Typography>
                                    {d?.fileInfo?.clientName ||
                                      `${
                                        d?.InstructionClient?.firstName || "NA"
                                      } ${
                                        d?.InstructionClient?.lastName || "NA"
                                      }`}
                                  </Typography>
                                </TableCell>
                                {/* TYPE  */}
                                <TableCell>
                                  <Grid
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography>
                                      {d?.fileInfo?.documentTypeName ||
                                        d?.documentType?.name ||
                                        "NA"}
                                    </Typography>
                                    {d?.documentType?.LookupView?.length >
                                      1 && (
                                      <Tooltip title="This document type is applicable to both case & client">
                                        <GroupIcon
                                          style={{ color: "#01ce3f" }}
                                        />
                                      </Tooltip>
                                    )}
                                  </Grid>
                                </TableCell>
                                {/* SUB TYPE  */}
                                <TableCell>
                                  <Typography>
                                    {d?.fileInfo?.subdocumentTypeName || "NA"}
                                  </Typography>
                                </TableCell>
                                {/* FILE NAME  */}
                                <TableCell>
                                  <Typography
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    {d?.fileInfo?.modifiedFileName || "NA"}
                                  </Typography>
                                </TableCell>
                                {/* VERSION  */}
                                <TableCell>
                                  <Typography
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {d?.version}
                                  </Typography>
                                </TableCell>
                                {/* NOTE  */}
                                <TableCell>
                                  <IconButton onClick={() => handleViewNote(d)}>
                                    <SpeakerNotesIcon />
                                  </IconButton>
                                </TableCell>
                                {/* ACTION  */}
                                <TableCell>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-evenly",
                                      alignItems: "center",
                                      gap: 10,
                                    }}
                                  >
                                    {d?.documentUrl ? (
                                      <VisibilityIcon
                                        color="action"
                                        onClick={() =>
                                          d?.documentUrl
                                            ? handleReadImage(d?.documentUrl)
                                            : null
                                        }
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {d?.documentUrl ? (
                                      <IconButton
                                        onClick={() => {
                                          return d?.documentUrl
                                            ? downloadDocument(
                                                d,
                                                clientData,
                                                "",
                                                selectedTask?.isClientTask
                                              )
                                            : null;
                                        }}
                                      >
                                        <DownloadIcon />
                                      </IconButton>
                                    ) : (
                                      "NA"
                                    )}
                                  </Grid>
                                </TableCell>

                                {/* OG FILE NAME  */}
                                <TableCell>
                                  <Typography
                                  // style={{
                                  //   display: "flex",
                                  //   justifyContent: "center",
                                  // }}
                                  >
                                    {d?.fileInfo?.fileName || "NA"}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                )}
                {/* HISTORY OF CLIENTS DOCS  */}
                {clientAnswerHistory?.length > 0 &&
                  selectedTask?.isClientTask && (
                    <Grid>
                      <Grid sx={{ width: "100%", overflow: "hidden" }}>
                        <TableContainer
                          sx={{
                            maxHeight: !isViewOnly ? 400 : 550,
                            cursor: "pointer",
                          }}
                          className="instruction_dashboard_scrollbar"
                        >
                          <Table
                            stickyHeader
                            aria-label="sticky-table a dense table"
                            size="small"
                          >
                            <TableHead>
                              {/* Columns section */}
                              <TableRow>
                                {UPDATE_CLIENT_TASK_ANSWER_COLUMNS.map(
                                  (column) => (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                      style={{ minWidth: column.minWidth }}
                                    >
                                      {column.label}
                                    </TableCell>
                                  )
                                )}
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {clientAnswerHistory?.map((d, index) => (
                                <TableRow key={index}>
                                  {/* TYPE  */}
                                  <TableCell>
                                    <Grid
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Typography>
                                        {d?.fileInfo?.documentTypeName ||
                                          d?.documentType?.name ||
                                          "NA"}
                                      </Typography>
                                      {d?.documentType?.LookupView?.length >
                                        1 && (
                                        <Tooltip title="This document type is applicable to both case & client">
                                          <GroupIcon
                                            style={{ color: "#01ce3f" }}
                                          />
                                        </Tooltip>
                                      )}
                                    </Grid>
                                  </TableCell>
                                  {/* NAME  */}
                                  <TableCell>
                                    <Typography>
                                      {/* <Typography> */}
                                      {d?.Instruction?.ClientInstruction
                                        ?.length > 1
                                        ? `Case: ${d?.Instruction?.id}, ${
                                            d?.Instruction
                                              ?.InstructionClients?.[0]
                                              ?.firstName || "--"
                                          } ${
                                            d?.Instruction
                                              ?.InstructionClients?.[0]
                                              ?.lastName || "--"
                                          } & ${
                                            d?.Instruction
                                              .InstructionClients?.[1]
                                              ?.firstName || "--"
                                          } ${
                                            d?.Instruction
                                              .InstructionClients?.[1]
                                              ?.lastName || "--"
                                          }`
                                        : d?.Instruction?.InstructionClients
                                            ?.length === 1
                                        ? `Case: ${d?.Instruction?.id}, ${
                                            d?.Instruction
                                              .InstructionClients?.[0]
                                              ?.firstName || "--"
                                          } ${
                                            d?.Instruction
                                              .InstructionClients?.[0]
                                              ?.lastName || "--"
                                          }`
                                        : ` NA`}
                                      <br />
                                      {` ${
                                        d?.Instruction
                                          ?.InstructionPlanSelections?.[0]?.plan
                                          ?.planName || "--"
                                      }  ${
                                        d?.Instruction
                                          ?.InstructionPlanSelections?.[0]
                                          ?.individualProducts?.[0]?.name ||
                                        "--"
                                      }`}
                                      {/* </Typography> */}
                                    </Typography>
                                  </TableCell>
                                  {/* SUB TYPE  */}
                                  <TableCell>
                                    <Typography>
                                      {d?.fileInfo?.subdocumentTypeName || "NA"}
                                    </Typography>
                                  </TableCell>
                                  {/* FILE NAME  */}
                                  <TableCell>
                                    <Typography
                                      style={{
                                        display: "flex",
                                      }}
                                    >
                                      {d?.fileInfo?.modifiedFileName || "NA"}
                                    </Typography>
                                  </TableCell>
                                  {/* VERSION  */}
                                  <TableCell>
                                    <Typography
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {d?.version}
                                    </Typography>
                                  </TableCell>
                                  {/* NOTE  */}
                                  <TableCell>
                                    <IconButton
                                      onClick={() => handleViewNote(d)}
                                    >
                                      <SpeakerNotesIcon />
                                    </IconButton>
                                  </TableCell>
                                  {/* ACTION  */}
                                  <TableCell>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-evenly",
                                        alignItems: "center",
                                        gap: 10,
                                      }}
                                    >
                                      {d?.documentUrl ? (
                                        <VisibilityIcon
                                          color="action"
                                          onClick={() =>
                                            d?.documentUrl
                                              ? handleReadImage(d?.documentUrl)
                                              : null
                                          }
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {d?.documentUrl ? (
                                        <IconButton
                                          onClick={() => {
                                            return d?.documentUrl
                                              ? downloadDocument(
                                                  d,
                                                  clientData,
                                                  "",
                                                  selectedTask?.isClientTask
                                                )
                                              : null;
                                          }}
                                        >
                                          <DownloadIcon />
                                        </IconButton>
                                      ) : (
                                        "NA"
                                      )}
                                    </Grid>
                                  </TableCell>

                                  {/* OG FILE NAME  */}
                                  <TableCell>
                                    <Typography
                                    // style={{
                                    //   display: "flex",
                                    //   justifyContent: "center",
                                    // }}
                                    >
                                      {d?.fileInfo?.fileName || "NA"}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  )}
              </Grid>
            </Grid>
          </>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UpdateTaskAnswerDialog;
