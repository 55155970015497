import { createSlice } from "@reduxjs/toolkit";
import { getDocumentSubType } from "../thunks/documents";
import {} from "../thunks/instruction";

const documentSlice = createSlice({
  name: "documentData",
  initialState: {
    loading: false,
    documentData: [],
    notesData: [],
    emailData: [],
  },
  reducers: {
    // Reducers methods for update state for task comments add, update, remove documents
    documentData: (state, action) => {
      // Action to change task name
      const filesArray = Array.from(action.payload);
      const tempData = [...state.documentData];
      if (filesArray?.length > 0) {
        filesArray?.map((d) => {
          tempData?.push({
            clientName: null,
            clientId: null,
            documentSubType: [],
            documentTypeId: null,
            documentSubTypeId: null,
            documentTypeName: null,
            subdocumentTypeName: null,
            fileName: d,
            path: null,
            remark: null,
            fileInfo: null,
          });
        });
      }

      state.documentData = tempData;
    },

    updateFormData: (state, action) => {
      const { e, index, name, subType, selectedClient } = action?.payload;
      if (index >= 0 && index < state.documentData.length) {
        // If selected name is === documentTypeId then update document subType
        if (name === "documentTypeId") {
          state.documentData[index] = {
            ...state.documentData[index],
            ["documentSubType"]: subType?.SubLookup || [],
            [name]: e,
          };
        }
        // If selected name is === clientId the update firstName + lastName in clientName
        else if (name === "clientId") {
          state.documentData[index] = {
            ...state.documentData[index],
            ["clientName"]: selectedClient?.isClient
              ? `${selectedClient?.firstName} ${selectedClient?.lastName}`
              : selectedClient?.[0]
              ? `${selectedClient[0]?.firstName} ${selectedClient[0]?.lastName}`
              : "Both",
            [name]: e,
          };
        } else {
          state.documentData[index] = {
            ...state.documentData[index],
            [name]: e,
          };
        }
      }
    },
    // Action to remove row data from verify task table
    removeDocument: (state, action) => {
      const tempData = JSON.parse(JSON.stringify(state?.documentData));
      const indexToRemove = action.payload; // Assuming the index to remove is passed in action.payload
      if (indexToRemove >= 0 && indexToRemove < state.documentData.length) {
        state.documentData.splice(indexToRemove, 1); // Remove one item at the specified index
      }
    },

    uploadSuccessData: (state, action) => {
      const { index, path, documentTypeName, subdocumentTypeName, fileInfo } =
        action?.payload;
      if (index >= 0 && index < state.documentData.length) {
        state.documentData[index] = {
          ...state.documentData[index],
          ["path"]: path,
          ["documentTypeName"]: documentTypeName,
          ["subdocumentTypeName"]: subdocumentTypeName,
          ["fileInfo"]: action?.payload,
        };
      }
    },
    clearDocuments: (state, action) => {
      state.documentData = [];
    },

    // Reducers methods for update state for notes and documents add, update, remove documents
    addNotesData: (state, action) => {
      // Action to change task name
      const filesArray = Array.from(action.payload);
      const tempData = [...state.notesData];
      if (filesArray?.length > 0) {
        filesArray?.map((d) => {
          tempData?.push({
            documentSubType: [],
            documentTypeId: null,
            documentSubTypeId: null,
            documentTypeName: null,
            subdocumentTypeName: null,
            fileName: d,
            path: null,
            fileInfo: null,
          });
        });
      }
      state.notesData = tempData;
    },

    updateNotesFormData: (state, action) => {
      const { e, index, name, subType, selectedClient } = action?.payload;
      if (index >= 0 && index < state.notesData.length) {
        // If selected name is === documentTypeId then update document subType
        if (name === "documentTypeId") {
          state.notesData[index] = {
            ...state.notesData[index],
            ["documentSubType"]: subType?.SubLookup || [],
            [name]: e,
          };
        }

        // If selected name is === clientId the update firstName + lastName in clientName
        else if (name === "clientId") {
          state.notesData[index] = {
            ...state.notesData[index],
            ["clientName"]: selectedClient?.isClient
              ? `${selectedClient?.firstName} ${selectedClient?.lastName}`
              : selectedClient?.[0]
              ? `${selectedClient[0]?.firstName} ${selectedClient[0]?.lastName}`
              : "Both",
            [name]: e,
          };
        } else {
          state.notesData[index] = {
            ...state.notesData[index],
            [name]: e,
          };
        }
      }
    },
    // Action to remove row data from verify task table
    removeNotesDocument: (state, action) => {
      const tempData = JSON.parse(JSON.stringify(state?.notesData));
      const indexToRemove = action.payload; // Assuming the index to remove is passed in action.payload
      if (indexToRemove >= 0 && indexToRemove < state.notesData.length) {
        state.notesData.splice(indexToRemove, 1); // Remove one item at the specified index
      }
    },
    // clear notes documents
    clearNotesDocuments: (state, action) => {
      state.notesData = [];
    },

    // Reducers methods for update state for email attatchments add, update, remove documents
    addEmailData: (state, action) => {
      // Action to change task name
      const filesArray = Array.from(action.payload);
      const tempData = [...state.emailData];
      if (filesArray?.length > 0) {
        filesArray?.map((d) => {
          tempData?.push({
            documentSubType: [],
            documentTypeId: null,
            documentSubTypeId: null,
            documentTypeName: null,
            subdocumentTypeName: null,
            fileName: d,
            path: null,
            fileInfo: null,
          });
        });
      }
      state.emailData = tempData;
    },

    addEmailPortalData: (state, action) => {
      // Action to change task name
      const filesArray = Array.from(action.payload);
      const tempData = [...state.emailData];
      if (filesArray?.length > 0) {
        filesArray?.map((d) => {
          tempData?.push({
            id: d?.id,
            path: d?.path,
            fileName: d?.fileName,
            version: d?.version,
            forClient: d?.forClient,
            documentType: d?.documentType,
            documentSubType: d?.documentSubType,
            LookupView: d?.LookupView,
            ClientInstruction: d?.ClientInstruction,
          });
        });
      }
      state.emailData = tempData;
    },

    updateEmailFormData: (state, action) => {
      const { e, index, name, subType, selectedClient } = action?.payload;
      if (index >= 0 && index < state.emailData.length) {
        // If selected name is === documentTypeId then update document subType
        if (name === "documentTypeId") {
          state.emailData[index] = {
            ...state.emailData[index],
            ["documentSubType"]: subType?.SubLookup || [],
            [name]: e,
          };
        }
        // If selected name is === clientId the update firstName + lastName in clientName
        else if (name === "clientId") {
          state.emailData[index] = {
            ...state.emailData[index],
            ["clientName"]: selectedClient?.isClient
              ? `${selectedClient?.firstName} ${selectedClient?.lastName}`
              : selectedClient?.[0]
              ? `${selectedClient[0]?.firstName} ${selectedClient[0]?.lastName}`
              : "Both",
            [name]: e,
          };
        } else {
          state.emailData[index] = {
            ...state.emailData[index],
            [name]: e,
          };
        }
      }
    },
    // Action to remove row data from verify task table
    removeEmailDocument: (state, action) => {
      const tempData = JSON.parse(JSON.stringify(state?.emailData));
      const indexToRemove = action.payload; // Assuming the index to remove is passed in action.payload
      if (indexToRemove >= 0 && indexToRemove < state.emailData.length) {
        state.emailData.splice(indexToRemove, 1); // Remove one item at the specified index
      }
    },
    // clear email documents
    clearEmailDocuments: (state, action) => {
      state.emailData = [];
    },
  },
  extraReducers: (builder) => {
    builder

      // Get Task Triggers thunk
      .addCase(getDocumentSubType.pending, (state, action) => {
        state.loading = true;
      });
  },
});
export const {
  documentData,
  updateFormData,
  clearDocuments,
  uploadSuccessData,
  removeDocument,
  addNotesData,
  updateNotesFormData,
  removeNotesDocument,
  clearNotesDocuments,
  addEmailData,
  addEmailPortalData,
  updateEmailFormData,
  removeEmailDocument,
  clearEmailDocuments,
} = documentSlice.actions;
export default documentSlice.reducer;
