import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React from "react";

const ProspectNotesQuestionAnswer = ({ label, client1, client2, data }) => {
  return (
    <>
      <Grid item xs={4}>
        <Typography className="normal_normal_medium_14_Manropee dark_grey">
          {label}
        </Typography>
      </Grid>

      <Grid item xs={data?.length > 1 ? 4 : 8}>
        <FormControl display={"flex"} justifyContent={"center"}>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={client1}
            row
          >
            <FormControlLabel
              control={
                <Radio
                  value={true}
                  sx={{
                    "&, &.Mui-checked": {
                      color: "#00CE3F",
                    },
                  }}
                />
              }
              label="Yes"
            />
            <FormControlLabel
              control={
                <Radio
                  value={false}
                  sx={{
                    "&, &.Mui-checked": {
                      color: "#00CE3F",
                    },
                  }}
                />
              }
              label="No"
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      {data?.length > 1 && (
        <Grid item xs={4} display={"flex"} justifyContent={"center"}>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={client2}
              row
            >
              <FormControlLabel
                control={
                  <Radio
                    value={true}
                    sx={{
                      "&, &.Mui-checked": {
                        color: "#00CE3F",
                      },
                    }}
                  />
                }
                label="Yes"
              />
              <FormControlLabel
                control={
                  <Radio
                    value={false}
                    sx={{
                      "&, &.Mui-checked": {
                        color: "#00CE3F",
                      },
                    }}
                  />
                }
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      )}
    </>
  );
};

export default ProspectNotesQuestionAnswer;
