import {
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Loader from "../../../../components/Loader";
import { showSnackbar } from "../../../../slice/snackbarSlice";
import {
  getDisbursementPrice,
  updateDisbursementPrice,
} from "../../../../thunks/DisPrice";
import { NOTIFICATION_MESSAGE } from "../../../../utils/constant";
import AlertComponent from "../../../../components/alert";

const DisPrice = () => {
  const [disData, setDisData] = useState([]);
  const [isLoader, setLoader] = useState(false);
  const [isUpdateDisPriceDialog, setUpdateDisPriceDialog] = useState(false);

  const dispatch = useDispatch();

  /* Method to get disbursement price */
  const getDisPrice = async () => {
    try {
      setLoader(true);
      const res = await dispatch(getDisbursementPrice()).unwrap();
      if (res) {
        setDisData(res);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      dispatch(
        showSnackbar({
          show: true,
          message: `${error?.message}`,
          severity: "error",
        })
      );
    }
  };

  /* Method to handle open update disbursement price dialog */
  const handleOpenUpdateDisPriceDialog = () => {
    setUpdateDisPriceDialog(true);
  };

  /*Method to update disbursement price */
  const handelupdateDisPrice = async () => {
    setUpdateDisPriceDialog(false);
    const tempData = [];
    if (disData?.length > 0) {
      disData?.map((parentItem) => {
        parentItem?.DisbursementFeesLookup?.map((childItem) => {
          tempData.push({
            id: childItem?.id,
            price: Number(childItem?.price),
            disbursementFeesLookupTypeId:
              childItem?.disbursementFeesLookupTypeId,
            percentageFees: Number(childItem?.percentageFees),
            isRowPriceInPercentage: childItem?.isRowPriceInPercentage,
          });
        });
      });
    }
    try {
      setLoader(true);
      const response = await dispatch(
        updateDisbursementPrice(tempData)
      ).unwrap();
      if (response) {
        setLoader(false);
        dispatch(
          showSnackbar({
            show: true,
            message: NOTIFICATION_MESSAGE?.DISBURSEMENT_UPDATE_SUCCESS,
            severity: "success",
          })
        );
      }
    } catch (error) {
      setLoader(false);
      setUpdateDisPriceDialog(false);
      dispatch(
        showSnackbar({
          show: true,
          message: `${error?.message}`,
          severity: "error",
        })
      );
    }
  };

  /* Method to handleChange disbursement price */
  const handleChangeDisbursementPrice = (e, parentId, childId, key) => {
    const updateData = disData?.map((item) => {
      if (item.id === parentId) {
        const updateFeesLookup = item.DisbursementFeesLookup.map((d) => {
          if (d.id === childId) {
            return { ...d, price: Number(e) };
          }
          return d;
        });
        return { ...item, DisbursementFeesLookup: updateFeesLookup };
      }
      return item;
    });
    setDisData(updateData);
  };
  useEffect(() => {
    getDisPrice();
  }, []);
  return (
    <>
      <Grid item xs={12} mt={1} ml={6}>
        {/* Loader Component */}
        {isLoader && <Loader />}

        {/* Update Disbursement Price Dialog */}
        {isUpdateDisPriceDialog && (
          <AlertComponent
            open={isUpdateDisPriceDialog}
            handleSuccess={handelupdateDisPrice}
            handleClose={() => setUpdateDisPriceDialog(false)}
            message={`Are you sure, want to update Disbursement Price?`}
          />
        )}

        <Grid
          container
          spacing={3}
          mt={1}
          className="instruction_dashboard_scrollbar"
          style={{
            height: "90vh",
            overflow: "auto",
          }}
        >
          {/* Dynamic mapping disbursment price values */}
          {disData?.length > 0 &&
            disData?.map((parentItem) => (
              <>
                <Grid xs={8} mb={3} mt={3}>
                  <Typography className="serif_display_regular_22 dark_grey">
                    {parentItem?.name}
                  </Typography>
                </Grid>

                <Grid container spacing={3}>
                  {parentItem?.DisbursementFeesLookup?.map((childItem) => (
                    <>
                      <Grid item xs={4}>
                        <Grid ml={1}>
                          <Typography className="normal_normal_16_Manrope dark_grey">
                            {childItem?.name || ""}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid xs={8} mt={2}>
                        <TextField
                          style={{ width: "20%" }}
                          className="inputTextField normal_normal_16_Manrope dark_grey"
                          value={childItem?.price || ""}
                          variant="standard"
                          onChange={(e) =>
                            handleChangeDisbursementPrice(
                              Number(e?.target?.value),
                              parentItem?.id,
                              childItem?.id,
                              "price"
                            )
                          }
                          InputProps={{
                            endAdornment: (
                              <>
                                {childItem?.isRowPriceInPercentage ? (
                                  <InputAdornment>
                                    <Typography>%</Typography>
                                  </InputAdornment>
                                ) : null}
                              </>
                            ),
                          }}
                        ></TextField>
                      </Grid>
                    </>
                  ))}
                </Grid>
              </>
            ))}
        </Grid>

        {/* Save button */}
        <Grid
          item
          xs={11}
          sx={{ display: "flex", justifyContent: "flex-end" }}
          m={1}
          className="Save_Button_Container"
        >
          <Button
            className="save_button"
            onClick={handleOpenUpdateDisPriceDialog}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default DisPrice;
