import { CircularProgress, Grid } from "@mui/material";
import React from "react";

function Loader(props) {
  return (
    <Grid className="cover-spin-cls">
      <CircularProgress
        size={60}
        style={{
          color: "#01ce3f",
          position: "relative",
          left: "calc(50% - 50px)",
          top: "50%",
        }}
        disableShrink
        thickness={4}
      />
    </Grid>
  );
}

export default Loader;
