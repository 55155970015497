import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Loader from "../../../components/Loader";
import { showSnackbar } from "../../../slice/snackbarSlice";
import { UpdateDocument, getDocumentTypeById } from "../../../thunks/documents";
import { NOTIFICATION_MESSAGE } from "../../../utils/constant";

const DocumentEdit = ({
  open,
  handleClose,
  doc,
  documentType,
  clientData,
  getDocumentsByType,
}) => {
  const dispatch = useDispatch();
  const [isLoader, setLoader] = useState(false);
  const [docSubType, setDocSubType] = useState([]);
  const [selectedClient, setSelectedClient] = useState(
    doc?.clientId || (doc?.clientId == null && null)
  );
  const [selectedDocumentType, setSelectedDocumentType] = useState(
    doc?.documentTypeId || ""
  );
  console.log("selectedDocumentType", selectedDocumentType);
  const [selectedDocumentSubType, setSelectedDocumentSubType] = useState(
    doc?.subLookupId || ""
  );
  const columns = [
    {
      id: "clientName",
      label: (
        <Typography className="table_cell_heading">Client Name</Typography>
      ),
      minWidth: 200,
    },
    {
      id: "documentTypeId",
      label: (
        <Typography className="table_cell_heading">Document Type</Typography>
      ),
      minWidth: 100,
    },
    {
      id: "documentSubTypeId",
      label: (
        <Typography className="table_cell_heading">Document SubType</Typography>
      ),
      minWidth: 150,
    },
  ];
  // Method to close dialog
  const handleCloseDialog = () => {
    handleClose();
  };

  const getDocumentTypeDetails = async (id) => {
    try {
      setLoader(true);
      const response = await dispatch(getDocumentTypeById(id)).unwrap();
      if (response) {
        setLoader(false);
        setDocSubType(response?.SubLookup);
      }
    } catch (error) {
      setLoader(false);
      return error;
    }
  };

  //METHOD TO GET DATA
  const handleChangeFormData = async (value, key) => {
    if (key === "clientId") {
      setSelectedClient(value);
    } else if (key === "documentTypeId") {
      setSelectedDocumentType(value);
      await getDocumentTypeDetails(value);
    } else if (key === "documentSubTypeId") {
      setSelectedDocumentSubType(value);
    }
  };

  //METHOD TO UPDATE DOC APICALL
  const handleUpdateDoc = async () => {
    const foundClient =
      selectedClient === "Both"
        ? undefined
        : clientData.find((client) => client.id === selectedClient);

    const payload = {
      instructionId: doc?.id,
      clientId: selectedClient === "Both" ? undefined : selectedClient,
      documentTypeId: selectedDocumentType,
      subDocumentTypeId: selectedDocumentSubType,
      forClient: foundClient
        ? `${foundClient.firstName} ${foundClient.lastName}`
        : "Both",
    };
    try {
      setLoader(true);
      const response = await dispatch(UpdateDocument(payload)).unwrap();
      setLoader(false);
      if (response) {
        getDocumentsByType();
        handleClose();
        dispatch(
          showSnackbar({
            show: true,
            message: NOTIFICATION_MESSAGE?.DOC_UPDATE_SUCCESS,
            severity: "success",
          })
        );
      }
    } catch (error) {
      setLoader(false);
      dispatch(
        showSnackbar({
          show: true,
          message: NOTIFICATION_MESSAGE?.DOC_UPDATE_FAILURE,
          severity: "error",
        })
      );
    }
  };

  useEffect(() => {
    getDocumentTypeDetails(selectedDocumentType);
  }, []);
  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        fullWidth
        maxWidth="md"
        padding={40}
      >
        {isLoader && <Loader />}
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>
          <Typography className="serif_display_regular_26 green">
            Edit docs
          </Typography>
        </DialogTitle>
        <Grid container xs={12}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer
              style={{
                maxWidth: "100%",
                cursor: "pointer",
              }}
              sx={{ maxHeight: 550 }}
              className="instruction_dashboard_scrollbar"
            >
              <Table
                stickyHeader
                aria-label="sticky-table a dense table"
                size="small"
              >
                <TableHead>
                  {/* Columns section */}
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    {/* CLIENT NAME  */}
                    <TableCell>
                      <FormControl fullWidth>
                        <Select
                          className="formControlField"
                          value={selectedClient}
                          variant="standard"
                          size="small"
                          onChange={(event) =>
                            handleChangeFormData(
                              event?.target?.value,
                              "clientId"
                            )
                          }
                        >
                          {clientData?.map((data) => (
                            <MenuItem key={data?.id} value={data?.id}>
                              {`${data?.firstName} ${data?.lastName}`}
                            </MenuItem>
                          ))}
                          <MenuItem
                            value={"Both"}
                            disabled={clientData?.length < 2}
                          >
                            Both
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                    {/* DOC TYPE  */}
                    <TableCell>
                      <FormControl fullWidth>
                        <Select
                          className="formControlField"
                          value={selectedDocumentType}
                          variant="standard"
                          size="small"
                          onChange={(event) =>
                            handleChangeFormData(
                              event?.target?.value,
                              "documentTypeId"
                            )
                          }
                        >
                          {documentType?.map((data) => (
                            <MenuItem key={data?.id} value={data?.id}>
                              {data?.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    {/* DOC SUB TYPE  */}
                    <TableCell>
                      <FormControl fullWidth>
                        <Select
                          className="formControlField"
                          value={selectedDocumentSubType}
                          variant="standard"
                          size="small"
                          onChange={(event) =>
                            handleChangeFormData(
                              event?.target?.value,
                              "documentSubTypeId"
                            )
                          }
                        >
                          {docSubType?.map((data) => (
                            <MenuItem key={data?.id} value={data?.id}>
                              {data?.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {/* BUTTON SECTION  */}
          <Grid
            item
            xs={12}
            style={{
              marginTop: "1rem",
              display: "flex",
              gap: 10,
              padding: 20,
              justifyContent: "center",
            }}
          >
            <Button className="cancel_button" onClick={() => handleClose()}>
              Cancel
            </Button>

            <Button className="save_button" onClick={() => handleUpdateDoc()}>
              {" "}
              Save
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default DocumentEdit;
