import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../../../components/Loader";
import { OutlinedGreenButton } from "../../../../../components/buttons";
import { ADD_BOOK_ROLES } from "../../../../../utils/constant";
import {
  getAddressbooksFromQuestionnairs,
  getUniqueID,
} from "../../../../../utils/util";
import ViewAddressBookPage from "./ViewAddressbook";

const basePath = `InstructionAddressBook.[0].addressBook`;
const AddressBookPage = (props) => {
  // hooks
  const { getValues } = useFormContext();
  const navigate = useNavigate();
  const navParams = useParams();

  // states
  const [isLoader, setLoader] = useState(false);
  const [addressBooks, setAddressBooks] = useState([]);
  const [filteredBooks, setFilteredBooks] = useState([]);
  const [filterPayload, setFilterPayload] = useState({
    page: 1,
    limit: 10,
    search: "",
    roles: [],
    milestones: "",
    status: "",
  });

  // address book columns
  const columns = [
    {
      field: "fullName",
      headerName: "Name",
      minWidth: 200,
    },
    {
      field: "personRole",
      headerName: "Roles",
      minWidth: 320,
      renderCell: (params) => (
        <Box py={2}>
          <Typography whiteSpace={"wrap"}>
            {params?.row?.personRole?.length
              ? params?.row?.personRole?.join(", ")
              : ""}
          </Typography>
        </Box>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 300,
    },
    {
      field: "phoneNumber",
      headerName: "Mobile",
      minWidth: 200,
    },
    // { field: "identification.certificationType", headerName: "Certification Type", minWidth: 150 }
  ];

  // Functionality for  filter Instruction type
  const handleChangeRole = (value) => {
    setFilterPayload({
      ...filterPayload,
      roles: value,
    });
  };

  // Functionality for filter name
  const handleChangeName = (value) => {
    setFilterPayload({ ...filterPayload, search: value, page: 1 });
  };

  // handle add address book
  const handleAddAddressbook = () => {
    props.changeActiveAddressbook({
      data: {
        isNew: true,
        index: addressBooks?.length,
        personID: getUniqueID(),
      },
      pathName: `${basePath}.[${addressBooks?.length}]`,
      instructionId: navParams.id,
    });
  };
  // use effect
  useEffect(() => {
    let instruction = getValues();
    if (Object.keys(instruction)?.length) {
      // let clonedInstruction = deepClone(instruction)
      let list = getAddressbooksFromQuestionnairs({ instruction });
      setAddressBooks(list);
    }
  }, []);

  let initialRoles = Object.entries(ADD_BOOK_ROLES).map((d) => ({
    label: d?.[0],
    value: d?.[1],
  }));

  useEffect(() => {
    if (addressBooks?.length) {
      let filteredAdd = addressBooks?.map((obj, i) => ({ ...obj, index: i }));
      filteredAdd = filteredAdd?.filter((obj) => {
        let isTrue =
          obj?.fullName
            ?.toLowerCase()
            ?.includes(filterPayload?.search?.toLowerCase()) ||
          obj?.email
            ?.toLowerCase()
            ?.includes(filterPayload?.search?.toLowerCase());

        // Roles
        if (filterPayload?.roles?.length)
          isTrue =
            isTrue &&
            filterPayload?.roles?.some((name) =>
              name?.value === ADD_BOOK_ROLES.Client
                ? obj?.personRole?.toString()?.includes(name?.value)
                : obj?.personRole?.includes(name?.value)
            );

        return isTrue;
      });
      setFilteredBooks(filteredAdd);
    }
  }, [filterPayload, addressBooks]);

  // ____________________________________

  return (
    <>
      {/* Loader Component */}
      {isLoader && <Loader />}
      <Grid container spacing={3} mt={5} alignItems={"center"}>
        {/* Filter Container */}
        <Grid item xs={3}>
          <TextField
            fullWidth
            placeholder="Search"
            className="textField_outlined_border"
            size="small"
            value={filterPayload.search}
            onChange={(e) => handleChangeName(e?.target?.value)}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "30px",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {/* Role */}
        <Grid item>
          <Autocomplete
            multiple
            className="textField_outlined_border"
            options={initialRoles}
            onChange={(event, value) => handleChangeRole(value)}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                className="normal_normal_medium_14_Manropee"
                variant="outlined"
                size="small"
                label="Roles"
                placeholder="Select Roles"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "30px",
                    minWidth: "260px",
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item ml="auto" pr={1}>
          <OutlinedGreenButton
            onClick={() => handleAddAddressbook()}
            label={"Add"}
          />
        </Grid>
        <Grid item xs={12} style={{ width: "100%", height: "100vh" }}>
          {/* address book list table */}
          <DataGrid
            style={{ border: "none" }}
            rows={filteredBooks}
            columns={columns}
            autoHeight
            getRowId={(row) => row?.personID}
            onRowClick={(data) => {
              props.changeActiveAddressbook({
                data: data?.row,
                pathName: `InstructionAddressBook.[0].addressBook.[${data?.row?.index}]`,
              });
            }}
            sx={{
              "& .MuiDataGrid-row:hover": {
                backgroundColor: "#e5faeb",
                cursor: "pointer",
                borderRadius: "20px",
              },
            }}
            hideFooter
            columnHeaderHeight={45}
            density="comfortable"
            disableColumnFilter={true}
            disableColumnMenu
            className="normal_normal_18_Manrope dark_grey instructionList__table__body"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {/* <Pagination
                count={Math.ceil(totalCount / filterPayload?.limit)}
                onChange={handleChangePage}
                page={filterPayload?.page}
              /> */}
        </Grid>
      </Grid>
    </>
  );
};

const AddressBookRoot = (props) => {
  const [activeAddressbook, setActiveAddressbook] = useState(null);
  const { getValues, setValue } = useFormContext();
  function handleDiscardChanges() {
    if (activeAddressbook) {
      let { index, isNew } = activeAddressbook?.data || {};
      if (isNew) {
        let prevList = getValues(basePath);
        prevList = prevList?.filter((obj, i) => i !== index);
        setValue(basePath, prevList);
      }
      setActiveAddressbook(null);
    }
  }
  return (
    <>
      <Grid display={"flex"} flexDirection={"column"} width={"100%"} gap={0}>
        {/* <FormHeader arr={[]} formName={formNames.addressBook} /> */}
        {activeAddressbook ? (
          // Add person in address book form component
          <ViewAddressBookPage
            changeActiveAddressbook={setActiveAddressbook}
            handleDiscardChanges={handleDiscardChanges}
            activeAddressbook={activeAddressbook}
          />
        ) : (
          // view list of address book clients data
          <AddressBookPage
            changeActiveAddressbook={setActiveAddressbook}
            activeAddressbook={activeAddressbook}
          />
        )}
      </Grid>
    </>
  );
};
export default AddressBookRoot;
