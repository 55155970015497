import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Dashboard from "./components/dashboard";
const ConsultantDashboard = () => {
  const Location = useLocation();
  return (
    <>
      <div className="main-page-container">
        <Routes>
          <Route path={`/`} element={<Dashboard />}></Route>
          {/* <Route path={"list"} element={<Dashboard />}></Route> */}
          {/* <Route path={"setupNewMeeting"} element={<SetupNewMeeting />}></Route> */}
        </Routes>
      </div>
    </>
  );
};

export default ConsultantDashboard;
