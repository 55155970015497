import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Loader from "../../../components/Loader";
import { getMeetingLogs } from "../../../thunks/instruction";
import ProspectNotesDialog from "./ProspectNotesDialog";

const MeetingLogs = ({ open, handleClose, instructionId }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isLoader, setLoader] = useState(false);
  const [showProspectNotesDialog, setShowProspectNotesDialog] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  const handleDialogClose = () => {
    handleClose();
    setShowProspectNotesDialog(false);
    setSelectedAppointment(null);
  };

  const handleViewNotes = (data) => {
    setSelectedAppointment(data);
    setShowProspectNotesDialog(true);
  };

  const handleProspectDialogClose = () => {
    setShowProspectNotesDialog(false);
    setSelectedAppointment(null);
  };

  const getMeetingLogsData = async () => {
    try {
      setLoader(true);
      const res = await dispatch(getMeetingLogs(instructionId)).unwrap();
      if (res?.length > 0) {
        setData(res);
        setLoader(false);
      } else {
        setData([]);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      return error;
    }
  };
  useEffect(() => {
    getMeetingLogsData();
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        fullWidth={true}
        maxWidth={"sm"}
        PaperProps={{ sx: { borderRadius: "28px", paddingBottom: 0 } }}
      >
        {/* Loader Component */}
        {isLoader && <Loader />}
        <IconButton
          aria-label="close"
          onClick={handleDialogClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          dividers={true}
          sx={{ marginRight: "5px" }}
          className="instruction_dashboard_scrollbar"
        >
          <>
            <Typography
              className="serif_display_regular_26 green"
              textAlign={"center"}
            >
              Meeting Logs
            </Typography>
            <Grid container spacing={3} mt={1} pb={3}>
              {data?.length > 0 ? (
                data?.map((d) => (
                  <>
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={9}>
                          <Typography className="normal_normal_semibold_14_Manrope green">
                            {d?.meetingType || "NA"}
                          </Typography>

                          <Typography className="normal_normal_600_16_Manrope dark_grey">
                            {moment(d?.startTime).format("DD/MM/YYYY") || "NA"};{" "}
                            {d?.location || "NA"}{" "}
                          </Typography>
                          <Typography className="normal_normal_18_Manrope dark_grey">
                            {d?.startTime
                              ? moment(d?.startTime).format("HH:mm")
                              : "NA"}{" "}
                            -{" "}
                            {d?.endTime
                              ? moment(d?.endTime).format("HH:mm")
                              : "NA"}
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          xs={3}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "end",
                          }}
                        >
                          <Typography className="normal_normal_bold_18_Manrope dark_grey">
                            {d?.endTime && d?.startTime
                              ? `${moment(d?.endTime).diff(
                                  moment(d?.startTime),
                                  "minutes"
                                  // "second",
                                  // "true"
                                )} min`
                              : "NA"}
                          </Typography>
                        </Grid>
                        {d?.appointmentDeclartion && (
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            onClick={() => handleViewNotes(d || null)}
                          >
                            <Button
                              variant="contained"
                              style={{
                                boxShadow: "none",
                                backgroundColor: "#00ce3f",
                                borderRadius: "24px",
                              }}
                            >
                              {d?.appointmentDeclartion?.[
                                "Instructed Attendance Note"
                              ]
                                ? "Instructed Attendance Note"
                                : "Prospect Attendance Note"}
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </>
                ))
              ) : (
                <Grid item xs={12}>
                  <Typography>No data found.</Typography>
                </Grid>
              )}
            </Grid>
          </>
        </DialogContent>

        {/* Prospect notes */}
        {showProspectNotesDialog ? (
          <ProspectNotesDialog
            prospectDialogOpen={showProspectNotesDialog}
            handleProspectDialogClose={handleProspectDialogClose}
            data={selectedAppointment}
            instructedAttendenceNote={selectedAppointment}
          />
        ) : null}
      </Dialog>
    </>
  );
};

export default MeetingLogs;
