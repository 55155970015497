import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { RadioInput, TextInput } from "../../../../../components/inputs";

const FuneralWishes = () => {
  const isJoint = useSelector((state) => state?.instruction?.isJoint);
  const arr = useSelector((state) => state?.instruction?.arr);

  return (
    <>
      <Grid width={"100%"} display={"flex"} flexDirection={"column"}>
        <Grid
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={isJoint ? "space-between" : "center"}
          borderTop={`1px solid #DCDCE1`}
          borderBottom={`1px solid #DCDCE1`}
        >
          {arr?.map((item, index) => {
            return (
              <Grid
                width={isJoint ? "50%" : "62%"}
                borderRight={
                  index === 0 && isJoint ? `1px solid #DCDCE1` : null
                }
                padding={2}
                display={"flex"}
                flexDirection={"column"}
                gap={2}
              >
                <RadioInput
                  label="Funeral Type"
                  name={`InstructionWillQuestionnaire.[${index}].funeralWishes.type`}
                  options={[
                    { value: "Burial", label: "Burial" },
                    { value: "Cremation", label: "Cremation" },
                    { value: "No Wishes", label: "No Wishes" },
                  ]} // todo get from lookup
                />
                <TextInput
                  label={`Do you wish to specify any funeral requests?`}
                  name={`InstructionWillQuestionnaire.[${index}].funeralWishes.requests`}
                  placeholder="Enter request"
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
};

export default FuneralWishes;
