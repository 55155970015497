import { Grid, Typography } from "@mui/material";
import { useMemo } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  AddButton,
  ContainedButton,
  ContainedGreenButton,
  OutlinedGreenButton,
  RemoveButton,
} from "../../../../../../components/buttons";
import { DropdownInput, RadioInput } from "../../../../../../components/inputs";
import { mapLabelValue } from "../../../../../../utils/util";
import {
  beneficiariesObject,
  ihtCard,
  ihtTrusteesObject,
} from "../willQuestionnaireFormConstants";
import RDTForm from "./RDTForm";
import TrusteeForm from "./TrusteeForm";

const InheritanceTaxPlanningItem = ({ arr, isJoint }) => {
  const client1 = useFieldArray({
    name: `InstructionWillQuestionnaire.[0].willTrusts.inheritanceTaxPlanning`,
  });

  const { append, remove, fields } = client1;

  const client2 = useFieldArray({
    name: `InstructionWillQuestionnaire.[1].willTrusts.inheritanceTaxPlanning`,
  });

  const {
    append: secondaryAppend,
    remove: secondaryRemove,
    fields: secondaryFields,
  } = client2;

  const handleAdd = (clientNumber) => {
    clientNumber === 0 ? append(ihtCard) : secondaryAppend(ihtCard);
  };

  const handleRemove = (clientNumber, ind) => {
    if (clientNumber === 1) {
      remove(ind);
    } else {
      secondaryRemove(ind);
    }
  };
  return (
    <>
      <Grid width={"100%"}>
        <Grid
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          padding={2}
        >
          <Grid width={"85%"} display={"flex"} flexDirection={"column"} gap={1}>
            <Typography sx={{ fontSize: "16px" }}>
              Inheritance Tax Planning: Business / Agricultural Property Relief
            </Typography>
          </Grid>
          <Grid width={"15%"}>
            <RadioInput
              label=""
              name={`InstructionWillQuestionnaire.[0].willTrusts.isIHT`}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
            />
          </Grid>
        </Grid>

        <Grid
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={isJoint ? "space-between" : "center"}
          borderTop={`1px solid #DCDCE1`}
          borderBottom={`1px solid #DCDCE1`}
        >
          {arr?.map((item, index) => {
            return (
              <Grid
                width={isJoint ? "50%" : "62%"}
                borderRight={
                  index === 0 && isJoint ? `1px solid #DCDCE1` : null
                }
                padding={2}
                display={"flex"}
                flexDirection={"column"}
                gap={2}
              >
                <Grid width={"100%"} display={"flex"} flexDirection={"row"}>
                  <Grid
                    width={"100%"}
                    paddingY={4}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={3}
                  >
                    {(index === 0 ? fields : secondaryFields).map(
                      (card, ind) => {
                        return (
                          <InheritanceTaxPlanningForm
                            isInstructionJoint={isJoint}
                            index={index}
                            cardNo={ind}
                            pathName={`InstructionWillQuestionnaire.[${index}].willTrusts.inheritanceTaxPlanning.[${ind}]`}
                            handleRemove={() => handleRemove(item, ind)}
                          />
                        );
                      }
                    )}
                    <Grid textAlign={"center"}>
                      <AddButton onClick={() => handleAdd(index)} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
};

export default InheritanceTaxPlanningItem;

const InheritanceTaxPlanningForm = ({
  index,
  cardNo,
  pathName,
  handleRemove,
  isInstructionJoint,
}) => {
  let { "Business Type Asset": businessAssetTypes } = useSelector(
    (state) => state?.lookupData
  );
  businessAssetTypes = useMemo(
    () => mapLabelValue(businessAssetTypes),
    [businessAssetTypes]
  );
  const trustees = useFieldArray({
    name: `${pathName}.trustee`,
  });

  const beneficiaries = useFieldArray({
    name: `${pathName}.beneficiary`,
  });

  const { append, remove, fields } = trustees;

  const {
    append: secondaryAppend,
    remove: secondaryRemove,
    fields: secondaryFields,
  } = beneficiaries;

  const handleAddCard = (type) => {
    type === "trustees"
      ? append(ihtTrusteesObject)
      : secondaryAppend(beneficiariesObject);
  };

  const handleRemoveCard = (type, ind) => {
    if (type === "trustees") {
      remove(ind);
    } else {
      secondaryRemove(ind);
    }
  };

  // handle joint
  const { getValues, setValue } = useFormContext();
  const handleJoint = () => {
    let isJoint = getValues(`${pathName}.isJoint`) || false;
    setValue(`${pathName}.isJoint`, !isJoint);
  };

  const isCardJoint = useWatch({
    name: `${pathName}.isJoint`,
  });

  return (
    <Grid
      boxShadow={"1px 2px 6px #00000029"}
      borderRadius={"16px"}
      borderRight={index === 0 ? `1px solid #DCDCE1` : null}
      padding={2}
      paddingY={3}
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      position={"relative"}
    >
      {index === 0 && isInstructionJoint ? (
        <div className="card-mirror-btn">
          {isCardJoint ? (
            <ContainedGreenButton label={"Joint"} onClick={handleJoint} />
          ) : (
            <OutlinedGreenButton label={"Joint"} onClick={handleJoint} />
          )}
        </div>
      ) : null}

      <DropdownInput
        name={`${pathName}.property`}
        label="Property"
        options={businessAssetTypes}
      />

      <Grid display={"flex"} flexDirection={"column"} gap={2}>
        <RadioInput
          label="Are there any assets which qualify for BPR / APR"
          name={`${pathName}.hold`}
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
        />

        {/* trustee child card */}
        <Grid
          width={"100%"}
          paddingY={1}
          display={"flex"}
          flexDirection={"column"}
          gap={3}
        >
          {fields.map((card, ind) => {
            return (
              <TrusteeForm
                index={index}
                cardNo={ind}
                pathName={`${pathName}.trustee.[${ind}]`}
                handleRemove={() => handleRemoveCard("trustees", ind)}
              />
            );
          })}
          <Grid textAlign={"left"}>
            <ContainedButton
              label="Add Trustee"
              onClick={() => handleAddCard("trustees")}
            />
          </Grid>
        </Grid>

        {/* child card */}
        <Grid
          width={"100%"}
          paddingY={1}
          display={"flex"}
          flexDirection={"column"}
          gap={3}
        >
          {secondaryFields.map((card, ind) => {
            return (
              <RDTForm
                index={index}
                cardNo={ind}
                pathName={`${pathName}.beneficiary.[${ind}]`}
                handleRemove={() => handleRemoveCard("beneficiaries", ind)}
              />
            );
          })}
          <Grid textAlign={"left"}>
            <ContainedButton
              label="Add Beneficiary"
              onClick={() => handleAddCard("beneficiaries")}
            />
          </Grid>
        </Grid>
      </Grid>
      <div style={{ textAlign: "right" }}>
        <RemoveButton label={"Joint"} onClick={handleRemove} />
      </div>
    </Grid>
  );
};
