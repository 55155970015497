import { Grid } from "@mui/material";
import { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { OutlinedButton } from "../../../../../components/buttons";
import { TextInput } from "../../../../../components/inputs";

const CertificateProvideItem = () => {
  const isJoint = useSelector((state) => state?.instruction?.isJoint);
  const arr = useSelector((state) => state?.instruction?.arr);

  return (
    <>
      <Grid width={"100%"} display={"flex"} flexDirection={"row"}>
        {arr?.map((item, index) => {
          return (
            <Grid
              width={isJoint ? "50%" : "100%"}
              borderTop={`1px solid #DCDCE1`}
              borderBottom={`1px solid #DCDCE1`}
              borderRight={index === 0 ? `1px solid #DCDCE1` : null}
              paddingY={4}
              display={"flex"}
              flexDirection={"column"}
              gap={3}
              paddingRight={index === 0 ? 2 : 0}
              paddingLeft={index === 1 ? 2 : 0}
            >
              <CertificateProvidePropertyAndFinancial
                label="For Health & Welfare"
                name={`InstructionLPA_Questionnaire.${index}.certificateProviderDetails.forHealthWelfare`}
              />
              <CertificateProvidePropertyAndFinancial
                label="For Health & Welfare"
                name={`InstructionLPA_Questionnaire.${index}.certificateProviderDetails.forPropertyFinancialAffairs`}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default CertificateProvideItem;

const CertificateProvidePropertyAndFinancial = ({ label, name }) => {
  const consultantName =
    useSelector(
      (state) =>
        state?.instruction?.tempInstructionData?.ownerOfInstructionByName
    ) || "dummy name";

  const { setValue, getValues } = useFormContext();
  const [showChange, setShowChange] = useState(
    getValues(`${name}.changed`) || true
  );
  const cName = "John Doe";

  const handleChange = (pathName) => {
    setValue(`${pathName}.name`, "");
    setValue(`${pathName}.changed`, true);
  };

  const handleRemove = (pathName) => {
    setValue(`${pathName}.name`, consultantName);
    setValue(`${pathName}.reason`, "");
    setValue(`${pathName}.changed`, false);
  };

  const nameOfPerson = useWatch({
    name: `${name}.name`,
  });

  return (
    <Grid display={"flex"} flexDirection={"row"} gap={2}>
      <Grid>
        {showChange ? (
          <OutlinedButton
            label="Change"
            color="#3D4740"
            onClick={() => {
              setShowChange(!showChange);
              handleChange(name);
            }}
          />
        ) : (
          <OutlinedButton
            label="Remove"
            color="#ff0000"
            onClick={() => {
              setShowChange(!showChange);
              handleRemove(name);
            }}
          />
        )}
      </Grid>
      <Grid display={"flex"} flexDirection={"column"} gap={2} width={"100%"}>
        {!showChange ? (
          <>
            <TextInput
              label={label}
              name={`${name}.name`}
              placeholder="Enter name"
            />
            <TextInput
              label={`Reason to appoint someone else`}
              name={`${name}.reason`}
              placeholder="Enter reason"
            />
          </>
        ) : (
          <Grid display={"flex"} flexDirection={"column"}>
            <div style={{ color: "#00CE3F", fontSize: "12px" }}>{label}</div>
            <div style={{ color: "#3D4740", fontSize: "16px" }}>
              {nameOfPerson}
            </div>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
