import { Grid, Typography } from "@mui/material";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import {
  AddButton,
  ContainedGreenButton,
  OutlinedGreenButton,
  RemoveButton,
} from "../../../../../../components/buttons";
import {
  DropdownInput,
  RadioInput,
  TextInput,
} from "../../../../../../components/inputs";
import { VALIDATION_FORMAT } from "../../../../../../utils/constant";
import { rtoPropertyObject } from "../willQuestionnaireFormConstants";

const RightToOccupyItem = ({ arr, isJoint }) => {
  const client1 = useFieldArray({
    name: `InstructionWillQuestionnaire.[0].willTrusts.rightToOccupy`,
  });

  const { append, remove, fields } = client1;

  const client2 = useFieldArray({
    name: `InstructionWillQuestionnaire.[1].willTrusts.rightToOccupy`,
  });

  const {
    append: secondaryAppend,
    remove: secondaryRemove,
    fields: secondaryFields,
  } = client2;

  const handleAdd = (clientNumber) => {
    clientNumber === 0
      ? append(rtoPropertyObject)
      : secondaryAppend(rtoPropertyObject);
  };

  const handleRemove = (clientNumber, ind) => {
    if (clientNumber === 1) {
      remove(ind);
    } else {
      secondaryRemove(ind);
    }
  };
  return (
    <>
      <Grid width={"100%"}>
        <Grid
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          borderBottom={`1px solid #DCDCE1`}
          padding={2}
        >
          <Grid width={"85%"}>
            <Typography>Right to Occupy</Typography>
          </Grid>
          <Grid width={"15%"}>
            <RadioInput
              label=""
              name={`InstructionWillQuestionnaire.[0].willTrusts.isRTO`}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
            />
          </Grid>
        </Grid>

        <Grid
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={isJoint ? "space-between" : "center"}
          borderTop={`1px solid #DCDCE1`}
          borderBottom={`1px solid #DCDCE1`}
        >
          {arr?.map((item, index) => {
            return (
              <Grid
                width={isJoint ? "50%" : "62%"}
                borderRight={
                  index === 0 && isJoint ? `1px solid #DCDCE1` : null
                }
                padding={2}
                display={"flex"}
                flexDirection={"column"}
                gap={2}
              >
                <Grid width={"100%"} display={"flex"} flexDirection={"row"}>
                  <Grid
                    width={"100%"}
                    paddingY={4}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={3}
                  >
                    {(index === 0 ? fields : secondaryFields).map(
                      (card, ind) => {
                        return (
                          <RightToOccupyForm
                            isInstructionJoint={isJoint}
                            index={index}
                            cardNo={ind}
                            pathName={`InstructionWillQuestionnaire.[${index}].willTrusts.rightToOccupy[${ind}]`}
                            handleRemove={() => handleRemove(item, ind)}
                          />
                        );
                      }
                    )}
                    <Grid textAlign={"center"}>
                      <AddButton onClick={() => handleAdd(index)} />
                    </Grid>
                  </Grid>
                  {/* ); */}
                  {/* })} */}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
};

export default RightToOccupyItem;

const RightToOccupyForm = ({
  index,
  cardNo,
  pathName,
  handleRemove,
  isInstructionJoint,
}) => {
  const { getValues, setValue } = useFormContext();
  const handleJoint = () => {
    let isJoint = getValues(`${pathName}.isJoint`) || false;
    setValue(`${pathName}.isJoint`, !isJoint);
  };

  const isCardJoint = useWatch({
    name: `${pathName}.isJoint`,
  });

  // Get client properties list for dropdown option

  let client1Props = getValues(
    "InstructionClients.[0].InstructionFactFind.properties"
  );
  let clientProperties_2 = [];
  const clientPeroperties_1 = client1Props?.length
    ? client1Props?.map((item) => {
        return { label: item?.address?.street, value: item?.address?.street };
      })
    : [];

  if (index == 1) {
    let client2Props = getValues(
      "InstructionClients.[1].InstructionFactFind.properties"
    );

    const clientPeroperties_1_joint = client1Props?.length
      ? client1Props
          ?.filter((prop) => prop.isJoint === true)
          .map((item) => {
            return {
              label: item?.address?.street,
              value: item?.address?.street,
            };
          })
      : [];

    const clientPeroperties_2_data = client2Props?.length
      ? client2Props?.map((item) => {
          return {
            label: item?.address?.street,
            value: item?.address?.street,
          };
        })
      : [];
    clientProperties_2 = [
      ...clientPeroperties_1_joint,
      ...clientPeroperties_2_data,
    ];
  }

  return (
    <Grid
      boxShadow={"1px 2px 6px #00000029"}
      borderBottom={`1px solid #DCDCE1`}
      // border={"2px solid red"}
      borderRadius={"16px"}
      borderRight={index === 0 ? `1px solid #DCDCE1` : null}
      padding={2}
      paddingY={3}
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      position={"relative"}
    >
      {index === 0 && isInstructionJoint ? (
        <div className="card-mirror-btn">
          {isCardJoint ? (
            <ContainedGreenButton label={"Joint"} onClick={handleJoint} />
          ) : (
            <OutlinedGreenButton label={"Joint"} onClick={handleJoint} />
          )}
        </div>
      ) : null}

      {/* todo drp ref: rto_dropdown_values*/}
      <DropdownInput
        name={`${pathName}.property.name`}
        label="Property"
        options={index == 1 ? clientProperties_2 : clientPeroperties_1}
      />

      <Grid>
        <TextInput
          label={`Name of Occupier`}
          name={`${pathName}.occupierName`}
          placeholder="Enter name"
        />
      </Grid>

      <Grid display={"flex"} flexDirection={"column"} gap={2}>
        <Typography sx={{ fontSize: "12px", color: "#00CE3F" }}>
          Do you wish to impose any of the following restrictions?
        </Typography>
        <Grid display={"flex"} justifyContent={"space-between"}>
          <Grid width={"65%"}>Payment of all outgoings?</Grid>
          <Grid width={"30%"}>
            <RadioInput
              label=""
              name={`${pathName}.outgoingPaymentRestriction`}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
            />
          </Grid>
        </Grid>
        <Grid display={"flex"} justifyContent={"space-between"}>
          <Grid width={"65%"}>Death of Life Tenant</Grid>
          <Grid width={"30%"}>
            <RadioInput
              label=""
              name={`${pathName}.lifeTenantDeath`}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
            />
          </Grid>
        </Grid>
        <Grid display={"flex"} justifyContent={"space-between"}>
          <Grid width={"65%"}>Years/Months after Death</Grid>
          <Grid width={"10%"} marginRight={5}>
            <TextInput
              label=""
              name={`${pathName}.timeAfterDeath.year`}
              rules={{
                pattern: {
                  value: VALIDATION_FORMAT?.quantityOnly?.pattern,
                  message: VALIDATION_FORMAT?.quantityOnly?.message,
                },
              }}
            />
          </Grid>
          <Grid width={"10%"}>
            <TextInput
              label=""
              name={`${pathName}.timeAfterDeath.month`}
              rules={{
                pattern: {
                  value: VALIDATION_FORMAT?.quantityOnly?.pattern,
                  message: VALIDATION_FORMAT?.quantityOnly?.message,
                },
              }}
            />
          </Grid>
        </Grid>

        <Grid display={"flex"} justifyContent={"space-between"}>
          <Grid width={"65%"}>Specific Age</Grid>
          <Grid width={"30%"}>
            <TextInput
              label=""
              name={`${pathName}.specificAge.age`}
              placeholder="Enter age"
            />
          </Grid>
        </Grid>
        <Grid display={"flex"} justifyContent={"space-between"}>
          <Grid width={"65%"}>Marriage/Remarriage</Grid>
          <Grid width={"30%"}>
            <RadioInput
              label=""
              name={`${pathName}.marriageOrRemarriage`}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
            />
          </Grid>
        </Grid>
        <Grid display={"flex"} justifyContent={"space-between"}>
          <Grid width={"65%"}>Cohabitation with new partner</Grid>
          <Grid width={"30%"}>
            <RadioInput
              label=""
              name={`${pathName}.cohabitationWithNewPartner`}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
      <div style={{ textAlign: "right" }}>
        <RemoveButton label={"Joint"} onClick={handleRemove} />
      </div>
    </Grid>
  );
};
