import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import GroupIcon from "@mui/icons-material/Group";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import {
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import {
  addNotesData,
  clearNotesDocuments,
  documentData,
  removeNotesDocument,
  updateNotesFormData,
} from "../../../slice/documents";
import { showSnackbar } from "../../../slice/snackbarSlice";
import { getDocumentTypeById, uploadFile } from "../../../thunks/documents";
import { createNotesAndDocument } from "../../../thunks/instruction";
import { NOTIFICATION_MESSAGE } from "../../../utils/constant";
import { useLocation, useParams } from "react-router-dom";
import { createNote } from "../../../thunks/client";
import instruction from "../../../slice/instruction";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const AddNotesAndDocument = ({
  handleClose,
  open,
  instructionId,
  handleSuccessCreateNote,
  clientData,
  clientInstruction,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  let isClientNote = location.pathname.includes("update-client");
  const notesData = useSelector((state) => state?.document?.notesData);
  const lookups = useSelector((state) => state?.lookupData);
  let { "Document Type": documentType } = lookups || {};
  const DocType = documentType?.filter((d) =>
    d?.LookupView?.some((view) =>
      location?.pathname?.includes("/app/instructions/dashboard/")
        ? view?.viewOn === "Case"
        : view?.viewOn === "Client"
    )
  );
  const [notesAndDocumentData, setNotesAndDocumentsData] = useState({
    notes: "",
    documentUrl: "",
  });
  const [isLoader, setLoader] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [progress, setProgress] = useState("0/0");
  const [isUploading, setUploading] = useState(false);
  const [fileDrag, setFileDrag] = useState(false);
  const [isBoth, setIsBoth] = useState([]);
  const columns = [
    {
      id: "clientName",
      label: (
        <Typography className="table_cell_heading">Client Name</Typography>
      ),
      minWidth: 200,
    },
    {
      id: "documentTypeId",
      label: (
        <Typography className="table_cell_heading">Document Type</Typography>
      ),
      minWidth: 100,
    },
    {
      id: "documentSubTypeId",
      label: (
        <Typography className="table_cell_heading">Document SubType</Typography>
      ),
      minWidth: 150,
    },
    {
      id: "fileName",
      label: (
        <Typography className="table_cell_heading">
          Original file name
        </Typography>
      ),
      maxWidth: 100,
    },
    {
      id: "action",
      label: <Typography className="table_cell_heading">Action</Typography>,
      maxWidth: 100,
    },
  ];
  const clientColumns = [
    {
      id: "documentTypeId",
      label: (
        <Typography className="table_cell_heading">Document Type</Typography>
      ),
      minWidth: 100,
    },
    {
      id: "clienInstructions",
      label: (
        <Typography className="table_cell_heading">
          Client's Instruction
        </Typography>
      ),
      minWidth: 400,
    },
    {
      id: "documentSubTypeId",
      label: (
        <Typography className="table_cell_heading">Document SubType</Typography>
      ),
      minWidth: 150,
    },
    {
      id: "fileName",
      label: (
        <Typography className="table_cell_heading">
          Original file name
        </Typography>
      ),
      maxWidth: 100,
    },
    {
      id: "modifiedFileName",
      label: <Typography className="table_cell_heading">File name</Typography>,
      maxWidth: 100,
    },
    {
      id: "action",
      label: <Typography className="table_cell_heading">Action</Typography>,
      maxWidth: 100,
    },
  ];
  // Handle file upload
  const handleFileUpload = (e, key) => {
    dispatch(addNotesData(e?.target?.files));
  };

  const handleChangeData = (e, key) => {
    setNotesAndDocumentsData({ ...notesAndDocumentData, [key]: e });
  };

  const handleCreateNote = async () => {
    // Check if document type is not selected as both if client has no instruction
    let idsOdDocumentTypeBoth = DocType?.filter(
      (d) => d?.LookupView?.length > 1
    )?.map((d) => d.id);
    let rowNumbers = [];

    for (let i = 0; i < notesData?.length; i++) {
      let doc = notesData[i];
      if (
        clientInstruction?.Instruction?.length === 0 &&
        idsOdDocumentTypeBoth?.includes(doc?.documentTypeId)
      ) {
        rowNumbers?.push(i + 1);
      }
    }

    if (rowNumbers?.length) {
      let errorStr =
        rowNumbers?.length === 1
          ? `${rowNumbers[0]}`
          : rowNumbers
              ?.map((i, iIndex, iArr) => {
                if (iIndex === 0) {
                  return `${i}`;
                } else if (iIndex === iArr.length - 1) {
                  return ` & ${i}`;
                } else {
                  return `, ${i}`;
                }
              })
              .join("");

      dispatch(
        showSnackbar({
          show: true,
          message:
            `Change document type on row ` +
            errorStr +
            ` since client has no instruction`,
          severity: "error",
        })
      );
      return;
    }

    let notesPayload = [];
    if (notesData?.length > 0) {
      // Method to check clientName, documentType,documentSubType are present..
      const validateData = notesData?.some((document) => {
        return (
          document.clientName === null ||
          document.clientId === null ||
          document.documentTypeId === null ||
          document.documentSubTypeId === null ||
          (idsOdDocumentTypeBoth?.includes(document?.documentTypeId) &&
            !document?.clientId)
        );
      });
      if (validateData) {
        dispatch(
          showSnackbar({
            show: true,
            message:
              "Required client name or document type or document subType",
            severity: "error",
          })
        );
      } else {
        let completed = 0;
        const total = notesData?.length;
        let uploadFileResponse = "";
        if (notesData?.length > 0) {
          for (let index = 0; index < notesData?.length; index++) {
            const data = notesData[index];
            let formData = new FormData();
            formData.append("myfile", data?.fileName, data?.fileName?.name);
            // Serialize the clientBody object to JSON
            const clientBodyData = {
              clientName: data?.clientName,
              clientId: data?.clientId,
              documentTypeId: data?.documentTypeId,
              subDocumentTypeId: data?.documentSubTypeId,
              index: index,
            };
            const clientScreenData = {
              clientName: data?.clientName
                ? data?.clientName
                : `${clientInstruction?.firstName} ${clientInstruction?.lastName}`,
              instructionId: data?.clientId,
              documentTypeId: data?.documentTypeId,
              subDocumentTypeId: data?.documentSubTypeId,
              clientId: Number(params?.id),
              index: index,
            };
            formData.append(
              "clientBody",
              JSON.stringify(isClientNote ? clientScreenData : clientBodyData)
            );
            const payload = {
              formData,
              url: isClientNote
                ? `/leadclient/${params?.id}/uploadDocs`
                : `/instruction/${instructionId}/uploadInstructionClientDocuments`,
            };
            try {
              setUploading(true);
              uploadFileResponse = await dispatch(uploadFile(payload)).unwrap();
              if (uploadFileResponse) {
                completed += 1;
                setProgress(`${completed}/${total}`);
                notesPayload.push({
                  fileInfo: {
                    ...uploadFileResponse,
                    instructionId: isClientNote
                      ? data?.clientId
                      : instructionId,
                  },
                  forClient: isClientNote
                    ? `${clientInstruction?.firstName} ${clientInstruction?.lastName}`
                    : data?.clientName,
                  documentTypeId: data?.documentTypeId,
                  subDocumentTypeId: data?.documentSubTypeId,
                  clientId: isClientNote
                    ? Number(params?.id)
                    : data?.clientId || null,
                });
                setUploading(false);
              } else {
                setUploading(false);
              }
            } catch (error) {
              setUploading(false);
              dispatch(
                showSnackbar({
                  show: true,
                  message: `${error?.message}`,
                  severity: "error",
                })
              );
            }
          }
          await publishNote(notesPayload);
        }
      }
    } else {
      publishNote(notesPayload);
    }
  };

  // Method to publish note + documents
  const publishNote = async (notesPayload) => {
    let payload = {
      id: instructionId,
      data: {
        notes: {
          notes: notesAndDocumentData?.notes,
        },
        files: notesPayload,
      },
    };

    if (!instructionId) {
      payload = {
        id: Number(params.id),
        documentUrl: "",
        note: notesAndDocumentData?.notes,
        files: notesPayload,
      };
    }

    if (notesPayload) {
      try {
        setLoader(true);
        const response = !isClientNote
          ? await dispatch(createNotesAndDocument(payload)).unwrap()
          : await dispatch(createNote(payload)).unwrap();
        if (response) {
          setLoader(false);
          handleCloseDialog();
          dispatch(
            showSnackbar({
              show: true,
              message: NOTIFICATION_MESSAGE?.NOTE_CREATE_SUCCESS,
              severity: "success",
            })
          );
          handleSuccessCreateNote();
        }
      } catch (error) {
        setLoader(false);
        handleCloseDialog();
        dispatch(
          showSnackbar({
            show: true,
            message: `${error?.message}`,
            severity: "error",
          })
        );
      }
    }
  };

  // HandleChange form data
  const handleChangeFormData = async (e, index, name) => {
    // If name === documentTypeId get subType and pass to slice method
    if (name === "documentTypeId") {
      const subType = await getDocumentTypeDetails(e);
      const payload = {
        e,
        index,
        name,
        subType,
      };
      dispatch(updateNotesFormData(payload));
      //To identify selected DocumentType is Both case and client
      const DocumentType = DocType?.filter((type) => e === type?.id);
      DocumentType?.[0]?.LookupView?.length > 1
        ? setIsBoth((prev) => [...prev, index])
        : setIsBoth((prev) => prev.filter((i) => i !== index));
    }
    // If name === clientId get client selected ID client details
    else if (name === "clientId") {
      const selectedClient = clientData?.filter((d) => d?.id === e);
      const payload = {
        e,
        index,
        name,
        selectedClient: isClientNote
          ? {
              firstName: clientInstruction?.firstName,
              lastName: clientInstruction?.lastName,
              isClient: isClientNote && true,
            }
          : selectedClient || "Both",
      };
      dispatch(updateNotesFormData(payload));
    } else {
      const payload = {
        e,
        index,
        name,
      };
      dispatch(updateNotesFormData(payload));
    }
  };

  // Method to delete document locally
  const handleDeleteDocument = (index) => {
    dispatch(removeNotesDocument(index));
  };

  const getDocumentTypeDetails = async (id) => {
    try {
      const response = await dispatch(getDocumentTypeById(id)).unwrap();
      if (response) {
        return response;
      }
    } catch (error) {
      return error;
    }
  };

  // Method to clos dialog
  const handleCloseDialog = () => {
    dispatch(clearNotesDocuments());
    handleClose();
  };

  // handle default drag behaviour
  const handleFileDragOver = (e) => {
    e.preventDefault();
    setFileDrag(true);
  };

  // file drag function to add files to table
  const handleFileDrop = (e) => {
    e.preventDefault();
    dispatch(addNotesData(e.dataTransfer.files));
    setFileDrag(false);
  };

  return (
    <>
      <Dialog onClose={handleCloseDialog} open={open} fullWidth maxWidth="xl">
        {isLoader && <Loader />}
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>
          <Typography className="serif_display_regular_26 green">
            Add Note
          </Typography>
        </DialogTitle>

        <Grid container spacing={3} p={2}>
          <Grid item xs={3}>
            <TextField
              label="Add Note"
              fullWidth
              placeholder="Enter a Note"
              multiline
              rows={10}
              value={notesAndDocumentData?.notes || ""}
              onChange={(e) => handleChangeData(e?.target?.value, "notes")}
            />

            <Button
              fullWidth
              style={{
                padding: "20px 0",
                backgroundColor: "#E5FAEB",
                cursor: "pointer",
                borderRadius: "10px",
                boxShadow: "none",
                marginTop: "1rem",
              }}
              className="normal_normal_16_Manrope green"
              component="label"
              variant="contained"
              startIcon={
                <FileUploadOutlinedIcon style={{ fontSize: "30px" }} />
              }
              type="file"
              onChange={(event) => handleFileUpload(event)}
            >
              Add File <VisuallyHiddenInput type="file" multiple />
            </Button>

            <Grid
              style={{
                marginTop: "1rem",
                display: "flex",
                gap: 10,
                alignItems: "center",
              }}
            >
              <Button className="cancel_button" onClick={() => handleClose()}>
                Cancel
              </Button>

              <Button className="save_button" onClick={handleCreateNote}>
                Save
              </Button>
            </Grid>
          </Grid>

          <Grid
            item
            xs={9}
            onDragOver={handleFileDragOver}
            onDragEnter={() => setFileDrag(true)}
            onDragLeave={() => setFileDrag(false)}
            onDrop={handleFileDrop}
            backgroundColor={fileDrag ? "#f0fbff" : null}
            boxSizing={"border-box"}
          >
            {isUploading && (
              <Grid item xs={12} style={{ marginBottom: "0.5rem" }}>
                <LinearProgress color="success" fullWidth />
                <Typography className="normal_normal_18_Manrope dark_grey">
                  Uploading... {progress}
                </Typography>
              </Grid>
            )}
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              {isClientNote && (
                <Grid item>
                  <Grid sx={{ width: "100%", overflow: "hidden" }}>
                    <TableContainer
                      style={{ cursor: "pointer", maxHeight: 250 }}
                      className="instruction_dashboard_scrollbar"
                    >
                      <Table
                        stickyHeader
                        aria-label="sticky-table a dense table"
                        size="small"
                      >
                        <TableHead>
                          {/* Columns section */}
                          <TableRow>
                            {clientColumns.map((column) => (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {notesData?.map((d, index) => (
                            <TableRow key={index}>
                              {/* doc type  */}
                              <TableCell>
                                <Grid
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 3,
                                  }}
                                >
                                  <FormControl fullWidth>
                                    <Select
                                      className="formControlField"
                                      value={d?.documentTypeId}
                                      variant="standard"
                                      size="small"
                                      onChange={(event) =>
                                        handleChangeFormData(
                                          event?.target?.value,
                                          index,
                                          "documentTypeId"
                                        )
                                      }
                                    >
                                      {DocType?.map((data) => (
                                        <MenuItem
                                          key={data?.id}
                                          value={data?.id}
                                        >
                                          {data?.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                  {isBoth?.includes(index) && (
                                    <Tooltip title="This document type is applicable to both case & client">
                                      <GroupIcon style={{ color: "#01ce3f" }} />
                                    </Tooltip>
                                  )}
                                </Grid>
                              </TableCell>
                              {/* clients instructions  */}
                              <TableCell>
                                <FormControl fullWidth>
                                  <Tooltip
                                    title={
                                      !isBoth?.includes(index)
                                        ? "Can't select instruction when doc type is client"
                                        : ""
                                    }
                                  >
                                    <Select
                                      disabled={!isBoth?.includes(index)}
                                      className="formControlField"
                                      value={d?.clientId}
                                      variant="standard"
                                      size="small"
                                      onChange={(event) =>
                                        handleChangeFormData(
                                          event?.target?.value,
                                          index,
                                          "clientId"
                                        )
                                      }
                                    >
                                      {clientInstruction &&
                                        clientInstruction?.Instruction &&
                                        clientInstruction?.Instruction?.length >
                                          0 &&
                                        clientInstruction?.Instruction?.map(
                                          (data) => (
                                            <MenuItem
                                              key={data?.id}
                                              value={data?.id}
                                            >
                                              <div>
                                                {`case:- ${
                                                  data?.id
                                                } , ${data?.name?.replace(
                                                  /&/g,
                                                  " & "
                                                )} `}
                                                <br />
                                                {`${
                                                  data
                                                    ?.InstructionPlanSelections
                                                    ?.plan?.planName &&
                                                  data
                                                    ?.InstructionPlanSelections
                                                    ?.plan?.planName !== ""
                                                    ? data
                                                        ?.InstructionPlanSelections
                                                        ?.plan?.planName
                                                    : "No Plan"
                                                } , ${
                                                  data
                                                    ?.InstructionPlanSelections
                                                    ?.individualProducts?.length
                                                    ? data
                                                        ?.InstructionPlanSelections
                                                        ?.individualProducts?.[0]
                                                        ?.product
                                                    : "No Products"
                                                }`}
                                              </div>
                                            </MenuItem>
                                          )
                                        )}
                                    </Select>
                                  </Tooltip>
                                </FormControl>
                                {isBoth?.includes(index) &&
                                clientInstruction &&
                                !clientInstruction?.Instruction?.length ? (
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      color: "red",
                                      mt: "2px",
                                    }}
                                  >
                                    Client has no instructions
                                  </span>
                                ) : null}
                              </TableCell>
                              {/* doc sub type  */}
                              <TableCell>
                                <FormControl fullWidth>
                                  <Select
                                    className="formControlField"
                                    value={d?.documentSubTypeId}
                                    variant="standard"
                                    size="small"
                                    onChange={(event) =>
                                      handleChangeFormData(
                                        event?.target?.value,
                                        index,
                                        "documentSubTypeId"
                                      )
                                    }
                                  >
                                    {d?.documentSubType?.map((data) => (
                                      <MenuItem key={data?.id} value={data?.id}>
                                        {data?.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </TableCell>

                              <TableCell>{d?.fileName?.name || "NA"}</TableCell>
                              <TableCell>
                                {d?.fileInfo?.modifiedFileName || "NA"}
                              </TableCell>
                              <TableCell>
                                <IconButton
                                  onClick={() => handleDeleteDocument(index)}
                                >
                                  <DeleteIcon style={{ color: "#ff0000" }} />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              )}
              {!isClientNote && (
                <TableContainer
                  style={{
                    maxWidth: "100%",
                    cursor: "pointer",
                  }}
                  sx={{ maxHeight: 550 }}
                  className="instruction_dashboard_scrollbar"
                >
                  <Table
                    stickyHeader
                    aria-label="sticky-table a dense table"
                    size="small"
                  >
                    <TableHead>
                      {/* Columns section */}
                      <TableRow>
                        {columns?.map((column) => (
                          <TableCell
                            key={column?.id}
                            align={column?.align}
                            style={{ minWidth: column?.minWidth }}
                          >
                            {column?.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {notesData?.map((d, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <FormControl fullWidth>
                              <Select
                                className="formControlField"
                                value={d?.clientId}
                                variant="standard"
                                size="small"
                                onChange={(event) =>
                                  handleChangeFormData(
                                    event?.target?.value,
                                    index,
                                    "clientId"
                                  )
                                }
                              >
                                {clientData?.map((data) => (
                                  <MenuItem key={data?.id} value={data?.id}>
                                    {`${data?.firstName} ${data?.lastName}`}
                                  </MenuItem>
                                ))}
                                <MenuItem
                                  value={"Both"}
                                  disabled={clientData?.length < 2}
                                >
                                  Both
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </TableCell>

                          <TableCell>
                            <Grid
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 3,
                              }}
                            >
                              <FormControl fullWidth>
                                <Select
                                  className="formControlField"
                                  value={d?.documentTypeId}
                                  variant="standard"
                                  size="small"
                                  onChange={(event) =>
                                    handleChangeFormData(
                                      event?.target?.value,
                                      index,
                                      "documentTypeId"
                                    )
                                  }
                                >
                                  {DocType?.map((data) => {
                                    return (
                                      <MenuItem key={data?.id} value={data?.id}>
                                        {data?.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                              {isBoth?.includes(index) && (
                                <Tooltip title="This document type is applicable to both case & client">
                                  <GroupIcon style={{ color: "#01ce3f" }} />
                                </Tooltip>
                              )}
                            </Grid>
                          </TableCell>

                          <TableCell>
                            <FormControl fullWidth>
                              <Select
                                className="formControlField"
                                value={d?.documentSubTypeId}
                                variant="standard"
                                size="small"
                                onChange={(event) =>
                                  handleChangeFormData(
                                    event?.target?.value,
                                    index,
                                    "documentSubTypeId"
                                  )
                                }
                              >
                                {d?.documentSubType?.map((data) => (
                                  <MenuItem key={data?.id} value={data?.id}>
                                    {data?.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </TableCell>

                          <TableCell>{d?.fileName?.name || "NA"}</TableCell>

                          <TableCell>
                            <IconButton
                              onClick={() => handleDeleteDocument(index)}
                            >
                              <DeleteIcon style={{ color: "#ff0000" }} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default AddNotesAndDocument;
