import { Grid, Typography } from "@mui/material";
import { useMemo } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { AddButton, RemoveButton } from "../../../../../components/buttons";
import {
  DateInput,
  DropdownInput,
  RadioInput,
  TextInput,
} from "../../../../../components/inputs";
import { VALIDATION_MSG } from "../../../../../utils/constant";
import { mapLabelValue } from "../../../../../utils/util";
import { ultimateBeneficiaryObject } from "./trustConstant";

const DefaultBeneficiary = ({ arr, isJoint, trustIndex }) => {
  const { getValues } = useFormContext();
  const trustees = useFieldArray({
    name: `InstructionTrustQuestionnaire.[${trustIndex}].ultimateBeneficiary`,
  });

  let watchClassification = getValues(
    `InstructionTrustQuestionnaire.[${trustIndex}].ultimateBeneficiary.classification`
  ); // todo get correct name with the help of dev

  const { append, remove, fields } = trustees;

  const handleAdd = () => {
    append(ultimateBeneficiaryObject);
  };

  const handleRemove = (ind) => {
    remove(ind);
  };

  return (
    <>
      <Grid
        width={"100%"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Grid
          width={"100%"}
          paddingY={4}
          display={"flex"}
          flexDirection={"column"}
          borderTop={`1px solid #DCDCE1`}
          borderBottom={`1px solid #DCDCE1`}
          gap={0}
          // border={"1px solid red"}
        >
          <Typography className="normal_normal_semibold_20_Manrope dark_grey">
            Default Beneficiary
          </Typography>
        </Grid>
        <Grid
          width={"65%"}
          paddingY={4}
          display={"flex"}
          flexDirection={"column"}
          gap={3}
          // border={"1px solid red"}
        >
          {fields.map((card, ind) => {
            return (
              <DefaultBeneficiaryForm
                key={card.id}
                cardNo={ind}
                handleRemove={() => handleRemove(ind)}
                pathName={`InstructionTrustQuestionnaire.[${trustIndex}].ultimateBeneficiary[${ind}]`}
              />
            );
          })}
          <Grid textAlign={"center"}>
            <AddButton onClick={handleAdd} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DefaultBeneficiary;

const DefaultBeneficiaryForm = ({ handleRemove, pathName, cardNo }) => {
  const {
    "Relation With Client": relationWithClient,
    Gender,
    beneficiary_classification,
  } = useSelector((state) => state?.lookupData);
  const { watch } = useFormContext();

  let watchClassification = watch(`${pathName}.classification`);

  let genderList = useMemo(() => mapLabelValue(Gender), [Gender]);

  return (
    <Grid
      boxShadow={"1px 2px 6px #00000029"}
      borderBottom={`1px solid #DCDCE1`}
      borderRadius={"16px"}
      padding={2}
      paddingY={3}
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      position={"relative"}
    >
      <Grid
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Grid
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          gap={1}
        >
          <Grid display={"flex"} flexDirection={"column"} gap={2}>
            <Grid width={"100%"} display={"flex"} flexDirection={"row"} gap={1}>
              <TextInput
                name={`${pathName}.beneficiaryName`}
                label={`Full Name`}
                placeholder="Enter name"
              />
            </Grid>
            <Grid item xs={12}>
              <RadioInput
                label={`Beneficiaries Type`}
                name={`${pathName}.classification`}
                options={beneficiary_classification || []}
                rules={{
                  required: { value: true, message: VALIDATION_MSG.REQUIRED },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {watchClassification === "Named Beneficiary" && (
        <>
          <Grid
            width={"48%"}
            display={"flex"}
            flexDirection={"column"}
            gap={2}
            border={"0px solid red"}
          >
            <Grid
              width={"100%"}
              display={"flex"}
              flexDirection={"row"}
              gap={1}
              // border={"1px solid red"}
            >
              <DropdownInput
                name={`${pathName}.relationshipToSettlors`}
                label="Relationship"
                options={relationWithClient}
              />
            </Grid>
          </Grid>
          <Grid
            width={"100%"}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
          >
            <Grid md={6} display={"flex"} flexDirection={"row"} gap={1}>
              <DateInput name={`${pathName}.dob`} label="Date Of Birth" />
            </Grid>
            <Grid md={6}>
              <RadioInput
                name={`${pathName}.gender`}
                label="Gender"
                options={genderList}
              />
            </Grid>
          </Grid>

          <Grid
            display={"flex"}
            width={"100%"}
            justifyContent={"space-between"}
            flexWrap={"wrap"}
          >
            <Grid width={"100%"}>
              <TextInput
                label={`Address`}
                name={`${pathName}.address.street1`}
                placeholder="Street 1"
              />
            </Grid>
            <Grid width={"100%"} pt={1.4}>
              <TextInput
                // label={`Mention Full Name`}
                name={`${pathName}.address.street2`}
                placeholder="Street 2"
              />
            </Grid>
            <Grid width={"48%"} pt={1.4}>
              <TextInput
                // label={`Mention Full Name`}
                name={`${pathName}.address.city`}
                placeholder="Town"
              />
            </Grid>
            <Grid width={"48%"} pt={1.4}>
              <TextInput
                // label={`Mention Full Name`}
                name={`${pathName}.address.state`}
                placeholder="County"
              />
            </Grid>
            <Grid width={"48%"} pt={1.4}>
              <TextInput
                // label={`Mention Full Name`}
                name={`${pathName}.address.zip`}
                placeholder="Post Code"
              />
            </Grid>
            <Grid width={"48%"} pt={1.4}>
              <TextInput
                // label={`Mention Full Name`}
                name={`${pathName}.address.country`}
                placeholder="Country"
                disabled={true}
              />
            </Grid>
          </Grid>
        </>
      )}

      <div style={{ textAlign: "right" }}>
        <RemoveButton onClick={handleRemove} />
      </div>
    </Grid>
  );
};
