export const attorneyObject = {
  fullName: "",
  appointFor: "",
  other_config: { isWeb: true },
};

export const attorneyAppointFor = [
  {
    label: "Property & Financial Affairs",
    value: "Property & Financial Affairs",
  },
  { label: "Health & Welfare", value: "Health & Welfare" },
  { label: "Both", value: "Both" },
];
