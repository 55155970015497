import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Loader from "../../../components/Loader";
import { showSnackbar } from "../../../slice/snackbarSlice";
import { getDocumentDetails } from "../../../thunks/documents";
import { getInstructionDocumentsAsPerDocumentType } from "../../../thunks/instruction";
import {
  MAX_EMAIL_FILE_SIZE,
  NOTIFICATION_MESSAGE,
} from "../../../utils/constant";
import { getModifiedFileName } from "../../../utils/util";
import { getClientDocumentsAsPerDocumentType } from "../../../thunks/client";

const ClientPortalDocuments = ({ handleClose, open, clientId, handleSave }) => {
  const dispatch = useDispatch();
  const [isLoader, setLoader] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  // Method to change selection of document to download
  const handleChangeSelectDocuments = (id) => {
    const selectedIDs = new Set(id);
    const selectedRows = documents.filter((row) => selectedIDs.has(row.id));
    setSelectedDocuments(selectedRows);
  };

  const columns = [
    {
      field: "fileName",
      headerName: "File Name",
      width: 350,
      sortable: false,
      renderCell: (params) => {
        let displayFileName =
          params?.row?.fileInfo?.modifiedFileName ||
          getModifiedFileName(params?.row?.documentUrl) ||
          "NA";

        let parts = displayFileName?.split("_");
        parts[0] = !params?.row?.isThisOrigin
          ? params?.row?.instructionId
          : parts[0];
        parts = parts?.join("_");
        return <Typography>{parts}</Typography>;
      },
    },
    {
      field: "version",
      headerName: "Version",
      width: 100,
      sortable: false,
    },
    {
      field: "documentType",
      headerName: "Doc Type",
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <Typography>{params?.row?.documentType?.name}</Typography>
      ),
    },
    {
      field: "createdAt",
      sortable: false,
      headerName: "Created On",
      minWidth: 150,
      renderCell: (params) => {
        const createdDate = moment(new Date(params?.row?.createdAt)).format(
          "DD/MM/YYYY"
        );

        return (
          <div className="d-flex justify-content-center">
            <Typography className="normal_normal_16_Manrope dark_grey">
              {createdDate}
            </Typography>
          </div>
        );
      },
    },
  ];

  const getDocumentsByType = async () => {
    try {
      setLoader(true);
      const response = await dispatch(
        getClientDocumentsAsPerDocumentType({
          clientId,
          query: {
            isMiscellaneous: false,
          },
        })
      ).unwrap();
      if (response) {
        setDocuments(response?.allDocumentsOfClient || []);
        setLoader(false);
      } else {
        setDocuments([]);
        setLoader(false);
      }
    } catch (error) {
      setDocuments([]);
      setLoader(false);
      return error;
    }
  };

  // Method to select documents
  const handleSelectDocuments = async () => {
    // get only path and id from selected documents
    const payload = selectedDocuments?.map((doc) => {
      return {
        documentId: doc?.id,
        path: doc?.documentUrl,
      };
    });
    try {
      if (payload?.length > 0) {
        setLoader(true);
        const response = await dispatch(getDocumentDetails(payload)).unwrap();
        // Create a function to check above response size is not greater then 20MB
        const totalSize = response?.reduce((acc, file) => acc + file?.size, 0);
        if (totalSize > MAX_EMAIL_FILE_SIZE) {
          dispatch(
            showSnackbar({
              message: NOTIFICATION_MESSAGE?.EMAIL_FILE_SIZE,
              severity: "error",
            })
          );
          setLoader(false);
        } else {
          handleSave(selectedDocuments);
          setLoader(false);
          handleClose();
        }
      }
    } catch (error) {
      setLoader(false);
      dispatch(
        showSnackbar({
          message: `${error?.message}`,
          severity: "error",
        })
      );
    }
  };
  useEffect(() => {
    getDocumentsByType();
  }, []);
  return (
    <>
      <Dialog open={open} fullWidth maxWidth="md">
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>

        <Button
          className="save_button"
          sx={{
            position: "absolute",
            right: 60,
            top: 10,
          }}
          onClick={handleSelectDocuments}
        >
          Save
        </Button>

        <DialogTitle>
          {" "}
          <Typography className="serif_display_regular_26 green">
            Documents
          </Typography>
        </DialogTitle>
        {isLoader && <Loader />}
        <Grid container spacing={3} style={{ padding: "0.8rem" }}>
          <Grid item xs={12}>
            <DataGrid
              rows={documents}
              columns={columns}
              checkboxSelection
              disableRowSelectionOnClick
              onRowSelectionModelChange={(id) => {
                handleChangeSelectDocuments(id);
              }}
              hideFooter
              className="normal_normal_16_Manrope dark_grey instructionList__table__body"
              sx={{
                "& .MuiDataGrid-row:hover": {
                  backgroundColor: "#e5faeb",
                  cursor: "pointer",
                  borderRadius: "20px",
                },
                "& .MuiDataGrid-cellCheckbox .MuiCheckbox-root .MuiSvgIcon-root":
                  {
                    position: "absolute",
                  },
                "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                  {
                    display: "none",
                  },
              }}
              disableColumnFilter={false}
              disableColumnMenu
              disableMultipleRowSelection={true}
              style={{ border: "none" }}
            />
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default ClientPortalDocuments;
