import { createSlice } from "@reduxjs/toolkit";
import { getPlan } from "../thunks/plan";

const planDataSlice = createSlice({
  name: "planDataSlice",
  initialState: {
    loading: false,
    planData: [],
  },
  extraReducers: (builder) => {
    builder

      // Get Plan thunk
      .addCase(getPlan.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(getPlan.fulfilled, (state, action) => {
        state.loading = false;
        state.planData = action?.payload;
      })

      .addCase(getPlan.rejected, (state, action) => {
        state.loading = false;
        state.planData = [];
      });
  },
});

export default planDataSlice.reducer;
