import { createAsyncThunk } from "@reduxjs/toolkit";
import getAxios from "../helpers/axiosInterceptor";
import { API_ROUTES_PATHS } from "../utils/constant";

// Method to get all tasks.
export const getAllTasks = createAsyncThunk(
  "client/getAllTasks",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(`${API_ROUTES_PATHS.GET_ALL_TASKS}`, {
        params: model?.query,
      });

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to create new task (Master Data)
export const createTask = createAsyncThunk(
  "task/createTask",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(`${API_ROUTES_PATHS.CREATE_TASK}`, model);

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to update  task (Master Data)
export const updateTask = createAsyncThunk(
  "task/updateTask",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().patch(
        `${API_ROUTES_PATHS.CREATE_TASK}/${model?.id}`,
        model
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get master tasks.
export const getMasterTasks = createAsyncThunk(
  "task/getMasterTasks",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(`${API_ROUTES_PATHS.GET_MASTER_TASK}`);

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get task by ID.
export const getTaskById = createAsyncThunk(
  "task/getTaskById",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.GET_TASK_BY_ID}/${model}`
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get task triggers.
export const getTaskTriggers = createAsyncThunk(
  "client/getTaskTriggers",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(`${API_ROUTES_PATHS.GET_TASK_TRIGGERS}`, {
        params: model?.query,
      });

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to update  task (Master Data)
export const updateTaskConfig = createAsyncThunk(
  "task/updateTaskConfig",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(
        `${API_ROUTES_PATHS.UPDATE_TASK_CONFIG}`,
        model
      );

      const responseData = res?.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);
