import { Grid } from "@mui/material";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { AddButton, RemoveButton } from "../../../../../components/buttons";
import {
  DropdownInput,
  NumberInput,
  TextInput,
} from "../../../../../components/inputs";
import { liabilitiesObject } from "./factFindFormConstants";

const LiabilitiesForm = () => {
  const isJoint = useSelector((state) => state?.instruction?.isJoint);
  const arr = useSelector((state) => state?.instruction?.arr);

  const client1 = useFieldArray({
    name: `InstructionClients.[0].InstructionFactFind.liabilities`,
  });

  const { append, remove, fields } = client1;

  const client2 = useFieldArray({
    name: `InstructionClients.[1].InstructionFactFind.liabilities`,
  });

  const {
    append: secondaryAppend,
    remove: secondaryRemove,
    fields: secondaryFields,
  } = client2;

  const handleAdd = (clientNumber, arrayLength) => {
    clientNumber === 0
      ? append(liabilitiesObject)
      : secondaryAppend(liabilitiesObject);
  };

  const handleRemove = (clientNumber, ind) => {
    if (clientNumber === 1) {
      remove(ind);
    } else {
      secondaryRemove(ind);
    }
  };
  return (
    <>
      <Grid
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        borderTop={`1px solid #DCDCE1`}
        justifyContent={"center"}
      >
        {" "}
        {arr?.map((item, index) => {
          return (
            <Grid
              key={item}
              width={!isJoint ? "60%" : "100%"}
              borderRight={index === 0 && isJoint ? `1px solid #DCDCE1` : null}
              paddingY={4}
              display={"flex"}
              flexDirection={"column"}
              gap={3}
              paddingRight={index === 0 ? 2 : 0}
              paddingLeft={index === 1 ? 2 : 0}
            >
              {(index === 0 ? fields : secondaryFields).map((card, ind) => {
                return (
                  <LiabilitiesFormSection
                    key={card.id}
                    isJoint={isJoint}
                    index={index}
                    cardNo={ind}
                    handleRemove={() => handleRemove(item, ind)}
                    pathName={`InstructionClients.[${index}].InstructionFactFind.liabilities.${ind}`}
                    isApplicationJoint={isJoint}

                  />
                );
              })}
              <Grid textAlign={"center"}>
                <AddButton
                  onClick={() =>
                    handleAdd(
                      index,
                      (item === 1 ? fields : secondaryFields).length
                    )
                  }
                />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default LiabilitiesForm;

const LiabilitiesFormSection = ({ index, cardNo, handleRemove, pathName, isApplicationJoint }) => {
  const { getValues, setValue } = useFormContext();
  let { liabilitiesData } = useSelector((state) => state?.lookupData);
  const handleJoint = () => {
    let isJoint = getValues(`${pathName}.isJoint`) || false;
    setValue(`${pathName}.isJoint`, !isJoint);
  };

  const isCardJoint = useWatch({
    name: `${pathName}.isJoint`,
  });

  return (
    <Grid
      boxShadow={"1px 2px 6px #00000029"}
      borderBottom={`1px solid #DCDCE1`}
      // border={"2px solid red"}
      borderRadius={"16px"}
      borderRight={index === 0 ? `1px solid #DCDCE1` : null}
      padding={2}
      paddingY={3}
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      position={"relative"}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DropdownInput
            name={`${pathName}.type`}
            label="Type"
            options={liabilitiesData}
          />
        </Grid>

        <Grid item xs={6}>
          <TextInput
            name={`${pathName}.lender`}
            label="Lender"
            placeholder="Lender"
          />
        </Grid>

        <Grid item xs={6}>
          <NumberInput
            isCurrency
            name={`${pathName}.value`}
            label="Value"
            placeholder="Value"
          />
        </Grid>
      </Grid>

      <div style={{ textAlign: "right" }}>
        <RemoveButton label={"Joint"} onClick={handleRemove} />
      </div>
    </Grid>
  );
};
