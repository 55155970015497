import { Grid, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  AddButton,
  ContainedGreenButton,
  OutlinedGreenButton,
  RemoveButton,
} from "../../../../../components/buttons";
import {
  CustomCheckboxGroup,
  DropdownInput,
  NumberInput,
  RadioInput,
  TextInput,
} from "../../../../../components/inputs";
import AddressSearchInput from "../../AddressSearch";
import { propertyObject } from "./factFindFormConstants";

const PropertiesForm = () => {
  const isJoint = useSelector((state) => state?.instruction?.isJoint);
  const arr = useSelector((state) => state?.instruction?.arr);

  const client1 = useFieldArray({
    name: `InstructionClients.[0].InstructionFactFind.properties`,
  });

  const { append, remove, fields } = client1;

  const client2 = useFieldArray({
    name: `InstructionClients.[1].InstructionFactFind.properties`,
  });

  const {
    append: secondaryAppend,
    remove: secondaryRemove,
    fields: secondaryFields,
  } = client2;

  const handleAdd = (clientNumber, arrayLength) => {
    clientNumber === 0
      ? append(propertyObject)
      : secondaryAppend(propertyObject);
  };

  const handleRemove = (clientNumber, ind) => {
    if (clientNumber === 1) {
      remove(ind);
    } else {
      secondaryRemove(ind);
    }
  };
  return (
    <>
      <Grid
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        borderTop={`1px solid #DCDCE1`}
        justifyContent={"center"}
      >
        {arr?.map((item, index) => {
          return (
            <Grid
              key={item}
              width={!isJoint ? "60%" : "100%"}
              borderRight={index === 0 && isJoint ? `1px solid #DCDCE1` : null}
              paddingY={4}
              display={"flex"}
              flexDirection={"column"}
              gap={3}
              paddingRight={index === 0 ? 2 : 0}
              paddingLeft={index === 1 ? 2 : 0}
            >
              {(index === 0 ? fields : secondaryFields).map((card, ind) => {
                return (
                  <PropertiesFormSection
                    key={card.id}
                    isJoint={isJoint}
                    index={index}
                    cardNo={ind}
                    handleRemove={() => handleRemove(item, ind)}
                    pathName={`InstructionClients.${index}.InstructionFactFind.properties.[${ind}]`}
                    isApplicationJoint={isJoint}
                  />
                );
              })}
              <Grid textAlign={"center"}>
                <AddButton
                  onClick={() =>
                    handleAdd(
                      index,
                      (item === 1 ? fields : secondaryFields).length
                    )
                  }
                />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default PropertiesForm;

const PropertiesFormSection = ({
  index,
  cardNo,
  handleRemove,
  pathName,
  isApplicationJoint,
}) => {
  const { getValues, setValue } = useFormContext();
  const electedResidence = useSelector(
    (state) => state?.lookupData?.ElectedResidence
  );
  const handleJoint = () => {
    let isJoint = getValues(`${pathName}.isJoint`) || false;
    setValue(`${pathName}.isJoint`, !isJoint);
  };

  const isCardJoint = useWatch({
    name: `${pathName}.isJoint`,
  });

  const handleSelectedAddress = (data) => {
    const pathName = `InstructionClients.${index}.InstructionFactFind.properties.[${cardNo}]`;
    const { city, country, zip } = data || {};

    setValue(`${pathName}.address.city`, city || "");
    setValue(`${pathName}.country`, country || "");
    setValue(`${pathName}.address.zip`, zip || "");
  };

  // netValue calculation
  let propValue = useWatch({
    name: `${pathName}.value`,
  });
  let propMortgage =
    useWatch({
      name: `${pathName}.mortgage`,
    }) || 0;

  let propertyValue = parseFloat(propValue);
  let propertyMortgage = parseFloat(propMortgage);
  const netValue = useMemo(
    () =>
      propertyMortgage < propertyValue ? propertyValue - propertyMortgage : 0,
    [propertyMortgage, propertyValue]
  );

  useEffect(() => {
    setValue(`${pathName}.netValue`, netValue.toFixed(2));
  }, [netValue]);

  return (
    <Grid
      boxShadow={"1px 2px 6px #00000029"}
      borderBottom={`1px solid #DCDCE1`}
      // border={"2px solid red"}
      borderRadius={"16px"}
      borderRight={index === 0 ? `1px solid #DCDCE1` : null}
      padding={2}
      paddingY={3}
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      position={"relative"}
    >
      {index === 0 && isApplicationJoint ? (
        <div className="card-mirror-btn">
          {isCardJoint ? (
            <ContainedGreenButton label={"Joint"} onClick={handleJoint} />
          ) : (
            <OutlinedGreenButton label={"Joint"} onClick={handleJoint} />
          )}
        </div>
      ) : null}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DropdownInput
            name={`InstructionClients.${index}.InstructionFactFind.properties.[${cardNo}].electedResidence`}
            label="Residence"
            options={electedResidence}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography className="instruction_fact_find_address_heading_typo">
            Address
          </Typography>
        </Grid>

        {/* Todo Add google address component */}
        <Grid item xs={12}>
          <AddressSearchInput
            name={`InstructionClients.${index}.InstructionFactFind.properties.[${cardNo}].address.street`}
            label="Line"
            placeholder="Line"
            handleSelectedAddress={(data) => handleSelectedAddress(data)}
          />
        </Grid>

        <Grid item xs={6}>
          <TextInput
            name={`InstructionClients.${index}.InstructionFactFind.properties.[${cardNo}].address.city`}
            label="Town"
            placeholder="Town"
          />
        </Grid>

        <Grid item xs={6}>
          <TextInput
            name={`InstructionClients.${index}.InstructionFactFind.properties.[${cardNo}].country`}
            label="Country"
            placeholder="Country"
          />
        </Grid>

        <Grid item xs={6}>
          <TextInput
            name={`InstructionClients.${index}.InstructionFactFind.properties.[${cardNo}].address.zip`}
            label="Post Code"
            placeholder="Post Code"
          />
        </Grid>

        <Grid item xs={12}>
          <RadioInput
            label={``}
            name={`InstructionClients.${index}.InstructionFactFind.properties.[${cardNo}].tenant`}
            options={[
              {
                label: "Joint Tenants",
                value: "Joint Tenants",
              },
              {
                label: "Tenants in Common",
                value: "Tenants in Common",
              },
            ]}
          />
        </Grid>

        <Grid item xs={12}>
          <CustomCheckboxGroup
            // label="Owned"
            name={`InstructionClients.${index}.InstructionFactFind.properties.[${cardNo}].isRegistered`}
            options={[{ label: "Register", value: true }]}
          />
        </Grid>

        <Grid item xs={6}>
          <NumberInput
            name={`InstructionClients.${index}.InstructionFactFind.properties.[${cardNo}].value`}
            label="Value"
            placeholder="Value"
            isCurrency
          />
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            name={`InstructionClients.${index}.InstructionFactFind.properties.[${cardNo}].mortgage`}
            label="Mortgage"
            placeholder="Mortgage"
            isCurrency
          />
        </Grid>

        <Grid item xs={6}>
          <TextInput
            name={`InstructionClients.${index}.InstructionFactFind.properties.[${cardNo}].term`}
            label="Term"
            placeholder="Term"
            isCurrency
          />
        </Grid>

        <Grid item xs={6}>
          <NumberInput
            name={`InstructionClients.${index}.InstructionFactFind.properties.[${cardNo}].netValue`}
            label="Net Value"
            placeholder="Net Value"
            isCurrency
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            name={`InstructionClients.${index}.InstructionFactFind.properties.[${cardNo}].notes`}
            label="Add a note"
            placeholder="Add a note"
          />
        </Grid>
      </Grid>

      <div style={{ textAlign: "right" }}>
        <RemoveButton label={"Joint"} onClick={handleRemove} />
      </div>
    </Grid>
  );
};
