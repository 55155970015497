import { Delete } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { NumberInput, TextInput } from "../../../../../components/inputs";

const PropertyCard = ({ pathName, index, handleRemoveProperty, isTrading }) => {
  const { setValue } = useFormContext();

  let propertyNetValue = useWatch({
    name: `${pathName}.${index}.netValue`,
  });
  let propertyMortgage =
    useWatch({
      name: `${pathName}.${index}.mortgage`,
    }) || 0;
  let propertyNotIncludedInEstate = useWatch({
    name: `${pathName}.${index}.notIncludedInEstate`,
  });
  let propertyIncludedInEstate = useWatch({
    name: `${pathName}.${index}.includedInEstate`,
  });

  propertyNetValue = parseFloat(propertyNetValue);
  propertyMortgage = parseFloat(propertyMortgage);

  useEffect(() => {
    let calc_propertyNotIncludedInEstate =
      propertyMortgage <= propertyNetValue &&
      propertyMortgage >= 0 &&
      propertyNetValue !== 0
        ? isTrading
          ? (propertyNetValue - propertyMortgage) * 0.5
          : 0
        : 0;
    calc_propertyNotIncludedInEstate = parseFloat(
      calc_propertyNotIncludedInEstate
    );
    setValue(
      `${pathName}.${index}.notIncludedInEstate`,
      +calc_propertyNotIncludedInEstate
    );

    propertyIncludedInEstate =
      propertyMortgage <= propertyNetValue && propertyMortgage >= 0
        ? isTrading
          ? (propertyNetValue - propertyMortgage) * 0.5
          : propertyNetValue - propertyMortgage
        : 0;
    propertyIncludedInEstate = parseFloat(propertyIncludedInEstate);
    setValue(
      `${pathName}.${index}.includedInEstate`,
      +propertyIncludedInEstate
    );
  }, [propertyNetValue, propertyMortgage, isTrading]);

  return (
    <Grid
      p={2}
      rowGap={3}
      boxShadow={"1px 2px 6px #00000029"}
      borderBottom={`1px solid #DCDCE1`}
      borderRadius={"16px"}
      borderRight={index === 0 ? `1px solid #DCDCE1` : null}
      container
    >
      <Grid
        justifyContent={"space-between"}
        alignItems={"center"}
        item
        container
      >
        <Typography>Property {index + 1}</Typography>

        <div style={{ textAlign: "right" }}>
          <IconButton onClick={handleRemoveProperty}>
            <Delete
              fontSize="small"
              style={{ color: "#ff0000", fontSize: "16px" }}
            />
          </IconButton>
        </div>
        <TextInput
          label={"Business Property"}
          labelFontSize={null}
          placeholder={"Input Text"}
          // labelColor={COLORS.primary}
          name={`${pathName}.${index}.businessProperty`}
        />
      </Grid>
      <Grid item container spacing={3}>
        <Grid item xs={6}>
          <NumberInput
            isCurrency
            label={"Net Value"}
            placeholder={"Input Number"}
            name={`${pathName}.${index}.netValue`}
          />
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            isCurrency
            w={"90%"}
            isNumeric
            label={"Mortgage"}
            placeholder={"Input Number"}
            name={`${pathName}.${index}.mortgage`}
            // leftEuro
            // isLocale
          />
        </Grid>
      </Grid>
      <Grid item container spacing={3}>
        <Grid item xs={6}>
          <NumberInput
            isCurrency
            name={`${pathName}.${index}.includedInEstate`}
            label="Included in Estate"
            placeholder={`${
              isNaN(propertyIncludedInEstate) ? "-" : propertyIncludedInEstate
            }`}
          />
        </Grid>

        <Grid item xs={6}>
          <NumberInput
            isCurrency
            name={`${pathName}.${index}.notIncludedInEstate`}
            label="Not included in Estate"
            placeholder={`${
              isNaN(propertyNotIncludedInEstate)
                ? "-"
                : propertyNotIncludedInEstate
            }`}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PropertyCard;
