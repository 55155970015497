import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: false,
  message: "",
  severity: "",
  duration: 2500,
};

const SnackbarSlice = createSlice({
  name: "Snackbar",
  initialState: initialState,
  reducers: {
    showSnackbar(state, action) {
      state.show = true;
      state.message = action.payload.message;
      state.severity = action.payload.severity;
      state.duration = action.payload.duration || 2500;
    },
    hideSnackbar(state) {
      state.show = false;
      state.message = "";
      state.severity = "";
      state.duration = 2500;
    },
  },
});

export const { showSnackbar, hideSnackbar } = SnackbarSlice.actions;

export default SnackbarSlice.reducer;
