import { Grid, Typography } from "@mui/material";
import React, { useMemo } from "react";

import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  CustomCheckboxGroup,
  DateInput,
  DropdownInput,
  MobileNumberInput,
  NumberInput,
  RadioInput,
  TextInput,
} from "../../../../../components/inputs";
import {
  NATIONALITY_DATA,
  VALIDATION_FORMAT,
  VALIDATION_MSG,
} from "../../../../../utils/constant";
import { mapLabelValue } from "../../../../../utils/util";
import AddressSearchInput from "../../AddressSearch";

const ClientDetailsForm = () => {
  const { setValue } = useFormContext();
  const isJoint = useSelector((state) => state?.instruction?.isJoint);
  const arr = useSelector((state) => state?.instruction?.arr);
  let { "Employment Status": employeementStatus, Gender } = useSelector(
    (state) => state?.lookupData
  );
  let genderList = useMemo(() => mapLabelValue(Gender), [Gender]);
  employeementStatus = employeementStatus?.length
    ? employeementStatus?.map((obj) => ({ label: obj?.name, value: obj?.name }))
    : [];

  // populate address in other address fields got from autocomplete address result
  const handleSelectedAddress = (data, ind) => {
    const pathName = `InstructionClients[${ind}].InstructionFactFind.clientDetails`;
    const { city, country, street2, street1, zip, state } = data || {};

    setValue(`${pathName}.address.city`, city || "");
    setValue(`${pathName}.address.country`, country || "");
    setValue(`${pathName}.address.state`, state || "");
    setValue(`${pathName}.address.zip`, zip || "");
    setValue(`${pathName}.address.street2`, street1 || street2 || "");
  };
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ padding: "1rem" }}>
          <Grid
            width={"100%"}
            display={"flex"}
            flexDirection={"row"}
            borderTop={`1px solid #DCDCE1`}
            justifyContent={"center"}
          >
            {" "}
            {arr?.map((clientIndex, ind) => (
              <>
                <Grid
                  key={clientIndex}
                  width={!isJoint ? "60%" : "100%"}
                  borderRight={
                    ind === 0 && isJoint ? `1px solid #DCDCE1` : null
                  }
                  display={"flex"}
                  flexDirection={"column"}
                  gap={3}
                  paddingRight={ind === 0 ? 2 : 0}
                  paddingLeft={ind === 1 ? 2 : 0}
                >
                  <Grid container spacing={3} mt={1}>
                    <Grid item xs={6}>
                      <RadioInput
                        label={`Gender`}
                        name={`InstructionClients[${ind}].InstructionFactFind.clientDetails.gender`}
                        options={genderList}
                        rules={{
                          required: {
                            value: true,
                            message: VALIDATION_MSG.REQUIRED,
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={6} pr={1}>
                      <DateInput
                        name={`InstructionClients[${ind}].InstructionFactFind.clientDetails.dob`}
                        label={`Date Of Birth`}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <DropdownInput
                        name={`InstructionClients[${ind}].InstructionFactFind.clientDetails.nationality`}
                        label="Nationality"
                        options={NATIONALITY_DATA}
                        rules={{
                          required: {
                            value: true,
                            message: VALIDATION_MSG.DROPDOWN,
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <DropdownInput
                        name={`InstructionClients[${ind}].InstructionFactFind.clientDetails.residency`}
                        label="Residency"
                        options={[{ label: "UK", value: "UK" }]}
                        rules={{
                          required: {
                            value: true,
                            message: VALIDATION_MSG.DROPDOWN,
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <NumberInput
                        name={`InstructionClients[${ind}].InstructionFactFind.clientDetails.telephone`}
                        label="Home Telephone"
                        placeholder="Home Telephone"
                        rules={{
                          required: {
                            value: false,
                            message: VALIDATION_MSG.NUM_ONLY,
                          },
                          valueAsString: true,
                          pattern: {
                            value: VALIDATION_FORMAT?.elevenDigit.pattern,
                            message: VALIDATION_FORMAT?.elevenDigit?.message,
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <MobileNumberInput
                        name={`InstructionClients[${ind}].InstructionFactFind.clientDetails.mobileNo`}
                        rules={{
                          required: {
                            value: true,
                            message: VALIDATION_MSG.REQUIRED,
                          },
                        }}
                      />
                      {/* <NumberInput
                          name={`InstructionClients[${ind}].InstructionFactFind.clientDetails.mobileNo`}
                          label="Mobile Number"
                          placeholder="Mobile Number"
                          rules={{
                            required: {
                              value: true,
                              message: VALIDATION_MSG.NUM_ONLY,
                            },
                            valueAsString: true,
                            validate: {
                              pattern: (value) => {
                                let isValid = value
                                  ?.toString()
                                  ?.match(/^\d{11}$/);
                                if (!isValid) {
                                  return VALIDATION_FORMAT?.phoneNumOnly
                                    ?.message;
                                }
                              },
                            },
                          }}
                        /> */}
                    </Grid>

                    <Grid item xs={12}>
                      <TextInput
                        name={`InstructionClients[${ind}].email`}
                        label="Email"
                        placeholder="Email"
                        rules={{
                          required: {
                            value: false,
                            message: VALIDATION_FORMAT?.emailOnly?.message,
                          },
                          pattern: {
                            required: { message: VALIDATION_MSG.REQUIRED },
                            value: VALIDATION_FORMAT?.emailOnly?.pattern,
                            message: VALIDATION_FORMAT?.emailOnly?.message,
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography className="instruction_fact_find_address_heading_typo">
                        Address
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <AddressSearchInput
                        name={`InstructionClients[${ind}].InstructionFactFind.clientDetails.address.street1`}
                        label="Line 1"
                        placeholder="Line 1"
                        handleSelectedAddress={(data) =>
                          handleSelectedAddress(data, ind)
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextInput
                        name={`InstructionClients[${ind}].InstructionFactFind.clientDetails.address.street2`}
                        label="Line 2"
                        placeholder="Line 2"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextInput
                        name={`InstructionClients[${ind}].InstructionFactFind.clientDetails.address.city`}
                        label="Town"
                        placeholder="Town"
                        rules={{
                          required: {
                            value: true,
                            message: VALIDATION_MSG?.require,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextInput
                        name={`InstructionClients[${ind}].InstructionFactFind.clientDetails.address.state`}
                        label="County"
                        placeholder="County"
                        rules={{
                          required: {
                            value: true,
                            message: VALIDATION_MSG?.require,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextInput
                        name={`InstructionClients[${ind}].InstructionFactFind.clientDetails.address.zip`}
                        label="Post Code"
                        placeholder="Post Code"
                        rules={{
                          required: {
                            value: true,
                            message: VALIDATION_MSG?.require,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextInput
                        name={`InstructionClients[${ind}].InstructionFactFind.clientDetails.address.country`}
                        label="Country"
                        placeholder="Country"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <CustomCheckboxGroup
                        // label="Owned"
                        name={`InstructionClients[${ind}].InstructionFactFind.clientDetails.isOwned`}
                        options={[{ label: "Owned", value: true }]}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <CustomCheckboxGroup
                        name={`InstructionClients[${ind}].InstructionFactFind.clientDetails.isMainResidence`}
                        options={[{ label: "Main Residence", value: true }]}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <DropdownInput
                        name={`InstructionClients[${ind}].InstructionFactFind.clientDetails.employmentStatus.status`}
                        label="Employment Status"
                        options={employeementStatus}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextInput
                        name={`InstructionClients[${ind}].InstructionFactFind.clientDetails.employmentStatus.notes`}
                        label="Notes"
                        placeholder="Notes"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <DropdownInput
                        name={`InstructionClients[${ind}].InstructionFactFind.clientDetails.healthStatus.status`}
                        label="Health Status"
                        options={[
                          { label: "Poor", value: "Poor" },
                          { label: "Average", value: "Average" },
                          { label: "Good", value: "Good" },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextInput
                        name={`InstructionClients[${ind}].InstructionFactFind.clientDetails.healthStatus.notes`}
                        label="Notes"
                        placeholder="Notes"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <RadioInput
                        label={`Are you able to read & sign unaided?`}
                        name={`InstructionClients[${ind}].InstructionFactFind.clientDetails.isReadAndSignUnaided.isReadAndSignUnaided`}
                        options={[
                          { label: "Yes", value: true },
                          { label: "No", value: false },
                        ]}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextInput
                        name={`InstructionClients[${ind}].InstructionFactFind.clientDetails.isReadAndSignUnaided.notes`}
                        label="Notes"
                        placeholder="Notes"
                      />
                    </Grid>

                    {clientIndex === 1 && (
                      <>
                        <Grid item xs={6}>
                          <RadioInput
                            label={`Are you about to marry or enter a civil partnership?`}
                            name={`InstructionClients[${ind}].InstructionFactFind.clientDetails.partnershipStatus.marryOrCivilPartnership`}
                            options={[
                              { label: "Yes", value: true },
                              { label: "No", value: false },
                            ]}
                          />
                        </Grid>

                        <Grid item xs={6} pr={1}>
                          <DateInput
                            name={`InstructionClients[${ind}].InstructionFactFind.clientDetails.partnershipStatus.date`}
                            label="Date"
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              </>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ClientDetailsForm;
