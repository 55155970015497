import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import BackButton from "../../../assets/images/BUTTON_Back.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EditIconButton, HistoryIconButton } from "../../../components/buttons";
import moment from "moment";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import { CheckboxRaw } from "../../../components/inputs";
import CreateNewTaskClient from "./CreateNewTaskClient";
import DoneIcon from "@mui/icons-material/Done";
import TransferIntroducerDialog from "../../Instructions/components/TransferIntroducerDialog";
import TransferConsultantDialog from "../../Instructions/components/TransferConsultantDialog";
import {
  IMG_BASE_URL,
  NOTIFICATION_MESSAGE,
  USER_ROLE,
  VALIDATION_MSG,
} from "../../../utils/constant";
import { useDispatch, useSelector } from "react-redux";
import {
  getTimeDifference,
  replaceNullWithUndefined,
} from "../../../utils/util";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getMyUsers } from "../../../thunks/user";
import {
  getClientAuthorizedPeople,
  getClientById,
  updateClient,
} from "../../../thunks/client";
import { showSnackbar } from "../../../slice/snackbarSlice";
import { uploadFile } from "../../../thunks/documents";
import { Delete, DeleteForever, Sms } from "@mui/icons-material";
import UpdateClient from "./UpdateClient";
import ClientAnniversaryDialog from "./ClientAnniversaryDialog";
import { getLookups } from "../../../thunks/lookup";
import ClientAuthorisedPersonDialog from "./ClientAuthorisedPersonDialog";
import Loader from "../../../components/Loader";

const ClientSpecificDetails = ({
  setReload,
  handleUpdateDashboardMarketingPreference,
}) => {
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const { fromCase, caseId } = location?.state || {};
  const userDetails = useSelector((state) => state?.auth?.currentUser);
  const lookups = useSelector((state) => state?.lookupData);
  let { "Lead Status": leadStatus } = lookups || {};

  const [authPeopleHistory, setAuthPeopleHistory] = useState([]);
  const [createTaskDialogOpen, setCreateTaskDialogOpen] = useState(false);
  const [isLoader, setLoader] = useState(false);
  const [isTransferConsultant, setIsTransferConsultant] = useState(false);
  const [isTransferIntroducer, setIsTransferIntroducer] = useState(false);
  const [isNote, setNote] = useState(false);
  const [isError, setIsError] = useState(false);
  const [marketingPreferencesLookup, setMarketingPreferencesLookup] = useState(
    []
  );
  const [channelLookup, setChannelLookup] = useState([]);
  const [tempRiskAlert, setTempRiskAlert] = useState({
    mentalCapacity: true,
    sightImpairment: false,
    hearingImpairment: false,
    deceased: true,
  });

  const [tempMarketingPreference, setTempMarketingPreference] = useState(
    channelLookup && marketingPreferencesLookup
      ? channelLookup?.flatMap((channel) =>
          marketingPreferencesLookup?.map((type) => ({
            preferenceTypeId: type?.id,
            channelId: channel?.id,
            isChannel: false,
          }))
        )
      : []
  );

  const [editMarketing, setEditMarketing] = useState(
    channelLookup && marketingPreferencesLookup
      ? channelLookup?.reduce((acc = {}, c) => {
          acc[c?.name] = marketingPreferencesLookup?.reduce(
            (mpAcc = {}, mp) => {
              mpAcc[mp?.name] = false;
              return mpAcc;
            },
            {}
          );
          return acc;
        }, {})
      : {}
  );

  const [isExpanded, setIsExpanded] = useState(false);
  const [clientData, setClientData] = useState({
    id: 0,
    firstName: "-",
    lastName: "-",
    phoneNumber: "-",
    middleName: "-",
    dob: "2024-09-30T12:54:45.841Z",
    leadLookupStatusId: null,
    email: "-",
    mobileNo: "-",
    nationality: "-",
    gender: "-",
    postalCode: "-",
    location: "-",
    sourceTypeLookupId: 0,
    sourceType: "-",
    sourceSubType: "-",
    sourceTypeValue: "-",
    address: {
      street1: "",
      street2: "",
      zip: "",
      city: "",
      country: "",
      county: "",
    },
    isReadAndSignUnaided: {
      status: "Yes",
      notes: "Cough",
    },
    partnershipStatus: {
      status: "Yes",
      date: "2023-10-03T08:49:03.103Z",
    },
    isMainResidence: false,
    isOwned: false,
    homeTelephone: "-",
    residency: "-",
    ownerOfClientById: 0,
    ownerOfClient: {},
    ownerOfClientByRole: "",
    ownerOfClientByEmail: "",
    marketingPreferences: [],
    aniversaries: [],
    authorisedPersons: [],
    profileUrl: "",
    introducerNote: "",
    riskAlert: {
      mentalCapacity: false,
      sightImpairment: false,
      hearingImpairment: false,
      deceased: false,
    },
    prefferedName: "-",
    pronouns: "-",
    title: "-",
  });

  const [editState, setEditState] = useState({
    marketingPreferences: false,
    riskAlert: false,
    nameChange: false,
    mobileAndEmail: false,
    authorisedPerson: false,
    aniversaries: false,
    introducerNote: false,
  });

  //Get history of auth people
  const getAuthorizedPeople = async () => {
    try {
      setLoader(true);
      const data = await dispatch(
        getClientAuthorizedPeople(params?.id)
      ).unwrap();
      if (data) {
        setAuthPeopleHistory(data?.LeadClientAuthorisedPerson);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const handleGetClientById = async () => {
    try {
      // get lookups
      const lookupResponse = await dispatch(
        getLookups({
          searchStrings: JSON.stringify(["Channel", "Marketing Preferences"]),
        })
      ).unwrap();

      let tempChannelLookup = lookupResponse?.find(
        (i) => i?.name === "Channel"
      );
      let tempMarketingPreferencesLookup = lookupResponse?.find(
        (i) => i?.name === "Marketing Preferences"
      );
      if (lookupResponse) {
        setChannelLookup(tempChannelLookup?.Lookup);
        setMarketingPreferencesLookup(tempMarketingPreferencesLookup?.Lookup);
      }

      // client data
      let response = await dispatch(getClientById(params?.id)).unwrap();

      // example to be built using channel and marketing --> {email: {transaction:true, marketing:true}, phone: {transaction:true, marketing:true}}
      let markObj = {};

      tempChannelLookup?.Lookup?.forEach((channelItem) => {
        // add channel
        markObj[channelItem?.name] = {};
        tempMarketingPreferencesLookup?.Lookup?.forEach((type) => {
          // add marketing preference type name
          markObj[channelItem?.name][type?.name] =
            response?.LeadClientMarketingPreference?.find(
              (obj) =>
                obj?.channelId === channelItem?.id &&
                obj?.preferenceTypeId === type?.id
            )?.isChannel || false;
        });
      });

      setEditMarketing(markObj);
      handleUpdateDashboardMarketingPreference(markObj);

      setTempRiskAlert(
        response?.riskAlert
          ? response?.riskAlert
          : {
              mentalCapacity: false,
              sightImpairment: false,
              hearingImpairment: false,
              deceased: false,
            }
      );
      setClientData({
        ...response,
        marketingPreferences: response?.LeadClientMarketingPreference,
        authorisedPersons: response?.LeadClientAuthorisedPerson,
        aniversaries: response?.LeadClientAnniversary,
        leadLookupStatusId: response?.leadLookupStatusId,
        introducerNote: response?.introducerNote || "",
      });
    } catch (error) {
      dispatch(
        showSnackbar({
          message: error,
          severity: "error",
        })
      );
    }
  };

  const handleSaveClientData = async () => {
    setEditState(false);
    setLoader(true);
    try {
      let data = {
        ...clientData,
        marketingPreferences: channelLookup?.flatMap((channel) => {
          return marketingPreferencesLookup?.map((type) => ({
            preferenceTypeId: type?.id,
            channelId: channel?.id,
            isChannel:
              tempRiskAlert?.deceased !== true
                ? editMarketing[channel?.name][type?.name] || false
                : false,
          }));
        }),
        riskAlert: tempRiskAlert,
        authorisedPersons: clientData?.authorisedPersons?.length
          ? clientData?.authorisedPersons
              ?.filter(
                (p) => p?.authorisedPersonId && p?.authorisedPersonId !== null
              )
              .map((p) => ({
                authorisedPersonId: p?.AuthorisedPerson?.id,
              }))
          : [],
      };

      delete data["LeadClientMarketingPreference"];
      delete data["LeadClientAuthorisedPerson"];
      delete data["LeadClientAnniversary"];
      delete data["Instruction"];

      // save data to db
      let response = await dispatch(updateClient(data)).unwrap();

      if (response) {
        setLoader(false);
        dispatch(
          showSnackbar({
            message: NOTIFICATION_MESSAGE?.SUCCESS_CLIENT_UPDATE,
            severity: "success",
          })
        );

        // get clientData to update states
        handleGetClientById();
      } else {
        setLoader(false);
        // reset to original values of the checkboxes
        setTempMarketingPreference(clientData?.marketingPreferences);
        // reset to original values of the checkboxes
        setTempRiskAlert(clientData?.riskAlert);

        dispatch(
          showSnackbar({
            message: NOTIFICATION_MESSAGE?.SOMETHING_WENT_WRONG,
            severity: "error",
          })
        );
      }
    } catch (error) {
      setLoader(false);
      dispatch(
        showSnackbar({
          message: error?.message,
          severity: "error",
        })
      );
    }
  };

  const handleUploadImage = async (file) => {
    let uploadFileResponse = "";
    try {
      let formData = new FormData();
      let blobImg = new Blob([file]);
      formData.append("myfile", blobImg, file?.name);

      const imagePayload = {
        formData,
        url: "/file/upload",
      };

      uploadFileResponse = await dispatch(uploadFile(imagePayload)).unwrap();
    } catch (error) {}

    return uploadFileResponse;
  };

  const handleLocalImageUpload = async (e) => {
    try {
      setLoader(true);
      let uploadFileResponse = "";
      const selectedFile = e?.target?.files[0];
      if (selectedFile) {
        const blobUrl = URL.createObjectURL(selectedFile);
        setClientData({ ...clientData, profileUrl: blobUrl });
      }
      uploadFileResponse = await handleUploadImage(selectedFile);
      if (uploadFileResponse?.length) {
        let payload = {
          ...clientData,
          profileUrl: uploadFileResponse,
        };

        // remove unwanted data from payload while updating client data
        delete payload["LeadClientMarketingPreference"];
        delete payload["LeadClientAuthorisedPerson"];
        delete payload["LeadClientAnniversary"];
        delete payload["Instruction"];
        delete payload["aniversaries"];
        delete payload["authorisedPersons"];
        delete payload["marketingPreferences"];
        delete payload["riskAlert"];

        // save user profile image to DB
        const clientUpdateResponse = await dispatch(
          updateClient(payload)
        ).unwrap();

        if (clientUpdateResponse) {
          setLoader(false);
          dispatch(
            showSnackbar({
              message: NOTIFICATION_MESSAGE?.SUCCESS_CLIENT_UPDATE,
              severity: "success",
            })
          );
        } else {
          setLoader(false);
          dispatch(
            showSnackbar({
              message: "Failed to update",
              severity: "error",
            })
          );
        }
      }
    } catch (e) {
      setLoader(false);
      dispatch(
        showSnackbar({
          message: e?.message,
          severity: "error",
        })
      );
    }
  };

  const handleChangeRiskAlert = (value, key) => {
    setTempRiskAlert((prevState) => ({
      ...prevState,
      [key]: !value,
    }));
  };

  const handleUpdateMarketingPreferencesEdit = () => {
    // show/hide checkboxes
    setEditState({
      ...editState,
      marketingPreferences: !editState?.marketingPreferences,
      riskAlerts: false,
    });
    // copy OG fetched data from API to temporary state of checkboxes
    setTempMarketingPreference(clientData?.marketingPreferences);
  };

  const handleUpdateMarketingPreferenceSave = () => {
    setEditState({
      ...editState,
      marketingPreferences: !editState?.marketingPreferences,
      riskAlerts: false,
    });
    // update main checkbox state with checkbox values
    setClientData((prev) => ({
      ...prev,
      marketingPreferences: tempMarketingPreference,
    }));
    handleSaveClientData();
  };
  const handleUpdateMarketingPreferenceCancel = () => {
    setEditState({
      ...editState,
      marketingPreferences: !editState?.marketingPreferences,
      riskAlerts: false,
    });

    // reset to original values of the checkboxes
    setTempMarketingPreference(clientData?.marketingPreferences);

    handleGetClientById();
  };

  const handleToggleRiskAlertEditState = () =>
    setEditState({
      ...editState,
      riskAlert: !editState?.riskAlert,
      marketingPreferences: false,
    });

  const handleUpdateRiskAlertSave = () => {
    setEditState({
      ...editState,
      riskAlert: !editState?.riskAlert,
      marketingPreferences: false,
    });

    // update main checkbox state with checkbox values
    setClientData((prev) => ({
      ...prev,
      riskAlert: tempRiskAlert,
    }));

    handleSaveClientData();
  };

  const handleUpdateRiskAlertCancel = () => {
    setEditState({
      ...editState,
      riskAlert: !editState?.riskAlert,
      marketingPreferences: false,
    });

    // reset to original values of the checkboxes
    setTempRiskAlert(clientData?.riskAlert);
  };

  const UpdateOpportunityStatus = async (value) => {
    setClientData((prevData) => ({
      ...prevData,
      leadLookupStatusId: value,
    }));

    //PATCH REQUEST CALL
    setLoader(true);
    try {
      let data = {
        ...clientData,
        leadLookupStatusId: value,
        marketingPreferences: channelLookup?.flatMap((channel) =>
          marketingPreferencesLookup?.map((type) => ({
            preferenceTypeId: type?.id,
            channelId: channel?.id,
            isChannel: editMarketing[channel?.name][type?.name] || false,
          }))
        ),
        riskAlert: tempRiskAlert,
        authorisedPersons: clientData?.authorisedPersons?.length
          ? clientData?.authorisedPersons
              ?.filter(
                (p) => p?.authorisedPersonId && p?.authorisedPersonId !== null
              )
              .map((p) => ({
                authorisedPersonId: p?.AuthorisedPerson?.id,
              }))
          : [],
      };

      delete data["LeadClientMarketingPreference"];
      delete data["LeadClientAuthorisedPerson"];
      delete data["LeadClientAnniversary"];
      delete data["Instruction"];

      // save data to db
      let response = await dispatch(updateClient(data)).unwrap();

      if (response) {
        setLoader(false);
        dispatch(
          showSnackbar({
            message: NOTIFICATION_MESSAGE?.SUCCESS_CLIENT_UPDATE,
            severity: "success",
          })
        );

        // get clientData to update states
        handleGetClientById();
      } else {
        setLoader(false);
        // reset to original values of the checkboxes
        setTempMarketingPreference(clientData?.marketingPreferences);
        // reset to original values of the checkboxes
        setTempRiskAlert(clientData?.riskAlert);

        dispatch(
          showSnackbar({
            message: NOTIFICATION_MESSAGE?.SOMETHING_WENT_WRONG,
            severity: "error",
          })
        );
      }
    } catch (error) {
      setLoader(false);
      dispatch(
        showSnackbar({
          message: error?.message,
          severity: "error",
        })
      );
    }
  };
  //TO show Client names initials
  const getInitials = (first, last) => {
    const firstInitial = first?.charAt(0)?.toUpperCase() ?? "N/";
    const lastInitial = last?.charAt(0)?.toUpperCase() ?? "A";
    return `${firstInitial}${lastInitial}`;
  };
  const initials = getInitials(clientData?.firstName, clientData?.lastName);

  const circleStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    aspectRatio: "1/1",
    backgroundColor: "#01ce3f",
    fontSize: "80px",
    fontWeight: "bold",
    textAlign: "center",
  };

  const handleNoteChange = (e) => {
    const newNote = e.target.value;
    if (newNote.length > 100) {
      setIsError(true);
    } else {
      setIsError(false);
    }
    setClientData((prev) => ({
      ...prev,
      introducerNote: newNote,
    }));
  };

  useEffect(() => {
    handleGetClientById();
    //GET AUTHORIZED PEOPLE HISTORY DATA
    getAuthorizedPeople();
  }, []);

  return (
    <>
      {isLoader && <Loader />}

      {/* update client dialog */}
      {editState?.nameChange ? (
        <UpdateClient
          open={editState?.nameChange}
          onClose={() => setEditState({ ...editState, nameChange: false })}
          clientData={clientData}
          setClientData={setClientData}
        />
      ) : null}

      {createTaskDialogOpen ? (
        <CreateNewTaskClient
          open={createTaskDialogOpen}
          handleClose={() => setCreateTaskDialogOpen(false)}
          clientId={params?.id}
          setReload={setReload}
        />
      ) : null}

      {/* Dialog for Transfer Instruction to Consultant */}
      {isTransferConsultant ? (
        <TransferConsultantDialog
          open={isTransferConsultant}
          handleClose={() => setIsTransferConsultant(false)}
          instructionId={params?.id}
        />
      ) : null}

      {/* Dialog for Transfer Instruction to Introducer */}
      {isTransferIntroducer ? (
        <TransferIntroducerDialog
          open={isTransferIntroducer}
          handleClose={() => setIsTransferIntroducer(false)}
          instructionId={params?.id}
        />
      ) : null}

      {/* Anniversary dialog */}
      {editState?.aniversaries ? (
        <ClientAnniversaryDialog
          aniversaries={clientData?.aniversaries}
          setAniversaries={() => {}}
          onClose={() => {
            setEditState((prev) => ({ ...prev, aniversaries: false }));
            handleGetClientById();
          }}
          open={editState?.aniversaries}
          clientData={{
            ...clientData,
            marketingPreferences: channelLookup?.flatMap((channel) =>
              marketingPreferencesLookup?.map((type) => ({
                preferenceTypeId: type?.id,
                channelId: channel?.id,
                isChannel: editMarketing[channel?.name][type?.name] || false,
              }))
            ),
            riskAlert: tempRiskAlert,
          }}
          setClientData={setClientData}
          isLoader={isLoader}
          setLoader={setLoader}
        />
      ) : null}

      {/* Authorised Persons dialog */}
      {editState?.authorisedPerson ? (
        <ClientAuthorisedPersonDialog
          persons={[]}
          setPersons={() => {}}
          onClose={() => {
            setEditState((prev) => ({ ...prev, authorisedPerson: false }));
            handleGetClientById();
            getAuthorizedPeople();
          }}
          open={editState?.authorisedPerson}
          clientData={{
            ...clientData,
            marketingPreferences: channelLookup?.flatMap((channel) =>
              marketingPreferencesLookup?.map((type) => ({
                preferenceTypeId: type?.id,
                channelId: channel?.id,
                isChannel: editMarketing[channel?.name][type?.name] || false,
              }))
            ),
            riskAlert: tempRiskAlert,
          }}
          setClientData={setClientData}
          isLoader={isLoader}
          setLoader={setLoader}
          authPeopleHistory={authPeopleHistory}
          getUpdatedAuthHistory={getAuthorizedPeople}
        />
      ) : null}

      <Grid
        container
        item
        xs={2.5}
        className="InstructionDashboardWrapper instruction_dashboard_scrollbar"
        style={{
          overflow: "auto",
          height: "100vh",
          marginLeft: "-12px",
          paddingTop: "10px",
          paddingLeft: "15px",
          paddingRight: "15px",
          paddingBottom: "10px",
          marginRight: "0px",
          boxSizing: "border-box",
        }}
      >
        <Grid xs={1}>
          {/* Back Button Section --> navigate to instructionDashboard/ClientList */}
          <img
            src={BackButton}
            onClick={() => {
              // dispatch(ClearTaskData());
              navigate(
                fromCase
                  ? `/app/instructions/dashboard/${caseId}`
                  : "/app/client/list"
              );
            }}
            style={{ cursor: "pointer" }}
          />
        </Grid>
        <Grid border={0} xs={11}>
          {/* back button + Avatar + Name types */}
          <Grid
            container
            display={"flex"}
            flexDirection={"row"}
            alignItems={"flex-start"}
            gap={2}
            mt={2}
            border={0}
          >
            {/* Avatar */}
            <Grid xs={12} display={"flex"} justifyContent={"center"}>
              <Avatar
                sx={{
                  height: "60%",
                  width: "60%",
                  border: `4px solid #00CE3F`,
                  cursor:
                    userDetails?.role === USER_ROLE?.INTRODUCER
                      ? ""
                      : "pointer",
                  textAlign: "center",
                  alignSelf: "center",
                }}
                onClick={() => {
                  if (userDetails?.role !== USER_ROLE?.INTRODUCER) {
                    inputRef?.current?.click();
                  }
                }}
              >
                {clientData?.profileUrl ? (
                  <img
                    src={
                      !clientData?.profileUrl?.includes("http")
                        ? `${process.env.REACT_APP_API_PATH}${IMG_BASE_URL}${clientData?.profileUrl}`
                        : clientData?.profileUrl?.includes("http")
                        ? clientData?.profileUrl
                        : ""
                    }
                    alt="Squiggle"
                    style={{
                      width: "100%",
                      aspectRatio: "1/1",
                      objectFit: "contain",
                      borderRadius: "50%",
                    }}
                  />
                ) : (
                  <div style={circleStyles}>{initials || "--"}</div>
                )}
                <input
                  type="file"
                  ref={inputRef}
                  style={{ display: "none" }}
                  onChange={handleLocalImageUpload}
                />
              </Avatar>
            </Grid>
            {/* names types */}
            <Grid xs={12}>
              <Grid>
                <Grid
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography className="normal_normal_600_12_Manrope green ">
                    Full Name
                  </Typography>
                  <Tooltip title={"edit"}>
                    <EditIconButton
                      size={"small"}
                      onClick={() => {
                        setEditState({
                          ...editState,
                          nameChange: !editState?.nameChange,
                        });
                      }}
                    />
                  </Tooltip>
                </Grid>
                <Typography
                  style={{ marginBottom: "0.5rem" }}
                  // className="normal_normal_18_Manrope dark_grey singleClientClick"
                  className="normal_normal_18_Manrope dark_grey"
                  onClick={() => {
                    // navigate(`/app/client/update-client/${d?.id}`)
                  }}
                >
                  {clientData?.title ? clientData?.title + " " : ""}
                  {clientData?.firstName || "--"} {clientData?.middleName || ""}{" "}
                  {clientData?.lastName || "--"}
                </Typography>
                <Typography className="normal_normal_600_12_Manrope green ">
                  Preferred Name
                </Typography>
                <Typography
                  style={{ marginBottom: "0.5rem" }}
                  className="normal_normal_18_Manrope dark_grey"
                >
                  {clientData?.prefferedName || "--"}
                </Typography>
                <Typography className="normal_normal_600_12_Manrope green">
                  Pronouns
                </Typography>
                <Typography
                  style={{ marginBottom: "0.5rem" }}
                  className="normal_normal_18_Manrope dark_grey"
                >
                  {clientData?.pronouns || "--"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* divider */}
          <Divider
            style={{
              border: "1px solid #00ce3f",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          />

          {/* Marketing Preferences */}
          <Grid>
            <Grid
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              mb={2}
            >
              <Typography className="normal_normal_600_12_Manrope green ">
                Marketing Preferences
              </Typography>
              {!editState?.marketingPreferences &&
              clientData?.riskAlert?.deceased === false ? (
                <Tooltip
                  title={
                    userDetails?.role === USER_ROLE?.INTRODUCER ? "" : "edit"
                  }
                >
                  <Grid>
                    <EditIconButton
                      disabled={userDetails?.role === USER_ROLE?.INTRODUCER}
                      size={"small"}
                      onClick={handleUpdateMarketingPreferencesEdit}
                    />
                  </Grid>
                </Tooltip>
              ) : editState?.marketingPreferences &&
                clientData?.riskAlert?.deceased === false ? (
                <Grid display={"flex"} alignItems={"center"}>
                  <Tooltip title="save">
                    <IconButton onClick={handleUpdateMarketingPreferenceSave}>
                      <CheckCircleIcon
                        sx={{ color: "#00CE3F", cursor: "pointer" }}
                        size="medium"
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="cancel">
                    <IconButton onClick={handleUpdateMarketingPreferenceCancel}>
                      <CancelIcon
                        sx={{ color: "grey", cursor: "pointer" }}
                        size="medium"
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              ) : null}
            </Grid>
            <Grid container my={1}>
              {" "}
              <Grid item xs={3}></Grid>
              {marketingPreferencesLookup?.map((item) => {
                return (
                  <Grid item xs={4.5} textAlign={"center"}>
                    {item?.name}
                  </Grid>
                );
              })}
            </Grid>
            {channelLookup?.map((channelItem) => {
              return (
                <Grid container mb={0.5}>
                  {" "}
                  <Grid item xs={3}>
                    {channelItem?.name}
                  </Grid>
                  {marketingPreferencesLookup?.map((preferenceItem) => {
                    let isChecked =
                      editMarketing?.[channelItem?.name]?.[
                        preferenceItem?.name
                      ] || false;

                    return (
                      <Grid item xs={4.5} textAlign={"center"}>
                        <CheckboxRaw
                          disabled={!editState?.marketingPreferences}
                          checked={isChecked}
                          onChange={() => {
                            setEditMarketing((prev) => {
                              let temp = {
                                ...prev,
                                [channelItem?.name]: {
                                  ...prev[channelItem?.name],
                                  [preferenceItem?.name]:
                                    !editMarketing[channelItem?.name][
                                      preferenceItem?.name
                                    ],
                                },
                              };
                              return temp;
                            });
                          }}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              );
            })}
          </Grid>

          {/* divider */}
          <Divider
            style={{
              border: "1px solid #00ce3f",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          />

          {/* Risk Alerts */}
          <Grid display={"flex"} flexDirection={"column"} gap={1}>
            <Grid
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              mb={0.5}
            >
              <Typography className="normal_normal_600_12_Manrope green ">
                Risk Alerts
              </Typography>
              {!editState?.riskAlert ? (
                <Tooltip
                  title={
                    userDetails?.role === USER_ROLE?.INTRODUCER ? "" : "edit"
                  }
                >
                  <Grid>
                    <EditIconButton
                      disabled={userDetails?.role === USER_ROLE?.INTRODUCER}
                      size={"small"}
                      onClick={handleToggleRiskAlertEditState}
                    />
                  </Grid>
                </Tooltip>
              ) : (
                <Grid display={"flex"} alignItems={"center"}>
                  <Tooltip title="save">
                    <IconButton onClick={handleUpdateRiskAlertSave}>
                      <CheckCircleIcon
                        sx={{ color: "#00CE3F", cursor: "pointer" }}
                        size="medium"
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="cancel">
                    <IconButton onClick={handleUpdateRiskAlertCancel}>
                      <CancelIcon
                        sx={{ color: "grey", cursor: "pointer" }}
                        size="medium"
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
            <Grid container>
              {" "}
              <Grid item xs={7.5}>
                Mental Capacity
              </Grid>
              <Grid item xs={4.5} textAlign={"center"}>
                <CheckboxRaw
                  disabled={!editState?.riskAlert}
                  checked={tempRiskAlert?.mentalCapacity}
                  onChange={() =>
                    handleChangeRiskAlert(
                      tempRiskAlert?.mentalCapacity,
                      "mentalCapacity"
                    )
                  }
                />
              </Grid>
            </Grid>
            <Grid container>
              {" "}
              <Grid item xs={7.5}>
                Sight Impairment
              </Grid>
              <Grid item xs={4.5} textAlign={"center"}>
                <CheckboxRaw
                  disabled={!editState?.riskAlert}
                  checked={tempRiskAlert?.sightImpairment ? true : false}
                  onChange={() =>
                    handleChangeRiskAlert(
                      tempRiskAlert?.sightImpairment,
                      "sightImpairment"
                    )
                  }
                />
              </Grid>
            </Grid>
            <Grid container>
              {" "}
              <Grid item xs={7.5}>
                Hearing Impairment
              </Grid>
              <Grid item xs={4.5} textAlign={"center"}>
                <CheckboxRaw
                  disabled={!editState?.riskAlert}
                  checked={tempRiskAlert?.hearingImpairment ? true : false}
                  onChange={() =>
                    handleChangeRiskAlert(
                      tempRiskAlert?.hearingImpairment,
                      "hearingImpairment"
                    )
                  }
                />
              </Grid>
            </Grid>
            <Grid container>
              {" "}
              <Grid item xs={7.5}>
                Deceased
              </Grid>
              <Grid item xs={4.5} textAlign={"center"}>
                <CheckboxRaw
                  disabled={!editState?.riskAlert}
                  checked={tempRiskAlert?.deceased ? true : false}
                  onChange={() =>
                    handleChangeRiskAlert(tempRiskAlert?.deceased, "deceased")
                  }
                />
              </Grid>
            </Grid>
          </Grid>

          {/* divider */}
          <Divider
            style={{
              border: "1px solid #00ce3f",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          />

          {/* Authorized people */}
          <Grid>
            <Grid
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              mb={2}
            >
              <Typography className="normal_normal_600_12_Manrope green ">
                Authorized People
              </Typography>

              {/* {!editState.authorisedPerson ? ( */}
              <Grid display={"flex"} alignItems={"center"}>
                <Tooltip
                  title={
                    userDetails?.role === USER_ROLE?.INTRODUCER ? "" : "edit"
                  }
                >
                  <Grid>
                    <EditIconButton
                      disabled={userDetails?.role === USER_ROLE?.INTRODUCER}
                      size={"small"}
                      onClick={() =>
                        setEditState((prev) => ({
                          ...prev,
                          authorisedPerson: !prev?.authorisedPerson,
                        }))
                      }
                    />
                  </Grid>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid display={"flex"} flexDirection={"column"} gap={1.5}>
              {clientData?.authorisedPersons?.map((p) => {
                let personName =
                  p?.AuthorisedPerson?.firstName +
                    " " +
                    p?.AuthorisedPerson?.lastName ?? "";

                return (
                  <Grid
                    display={"flex"}
                    alignItems={"flex-start"}
                    justifyContent={"space-between"}
                    container
                  >
                    <Grid xs={11} key={clientData?.authorisedPersons?.id}>
                      <Typography style={{ cursor: "pointer" }}>
                        {personName}
                      </Typography>
                      <Typography
                        style={{
                          cursor: "pointer",
                          fontSize: "13px",
                          color: "grey",
                        }}
                      >
                        {moment(p?.createdAt).format("DD/MM/YYYY")} - Present
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
              {authPeopleHistory?.map((d) => (
                <>
                  <Grid
                    display={"flex"}
                    alignItems={"flex-start"}
                    justifyContent={"space-between"}
                    container
                  >
                    <Grid xs={11} key={d?.AuthorisedPersons?.id}>
                      <Typography key={d?.AuthorisedPerson?.id}>
                        {`${d?.AuthorisedPerson?.firstName} ${d?.AuthorisedPerson?.lastName}`}
                      </Typography>
                      <Typography
                        style={{
                          cursor: "pointer",
                          fontSize: "13px",
                          color: "grey",
                        }}
                      >
                        {moment(d?.createdAt).format("DD/MM/YYYY") || "NA"} -
                        {moment(d?.updatedAt).format("DD/MM/YYYY") || "NA"}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              ))}
            </Grid>
          </Grid>

          {/* divider */}
          <Divider
            style={{
              border: "1px solid #00ce3f",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          />

          {/* Mobile no, home number, address, anniversaries */}
          <Grid display={"flex"} flexDirection={"column"} gap={2}>
            <Grid>
              <Typography className="normal_normal_600_12_Manrope green ">
                Mobile Number
              </Typography>
              <Typography className="">
                {clientData?.mobileNo || "-"}
              </Typography>
            </Grid>
            <Grid>
              <Typography className="normal_normal_600_12_Manrope green ">
                Email
              </Typography>
              <Typography className="">{clientData?.email || "-"}</Typography>
            </Grid>
            <Grid>
              <Typography
                className="normal_normal_600_12_Manrope green "
                mb={0}
              >
                Address
              </Typography>
              <Typography className="">
                {clientData?.address?.street1
                  ? clientData?.address?.street1
                  : null}
              </Typography>
              <Typography className="">
                {clientData?.address?.street2
                  ? clientData?.address?.street2
                  : null}
              </Typography>
              <Typography className="">
                {clientData?.address?.city ? clientData?.address?.city : null}
              </Typography>
              <Typography className="">
                {clientData?.address?.county
                  ? clientData?.address?.county
                  : null}
              </Typography>
              <Typography className="">
                {clientData?.address?.country
                  ? clientData?.address?.country
                  : null}
              </Typography>
              <Typography className="">
                {clientData?.postalCode || clientData?.address?.zip || ""}
              </Typography>
            </Grid>

            {/* Anniversaries */}
            <Grid mt={3}>
              <Grid
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography
                  className="normal_normal_600_12_Manrope green"
                  mb={1}
                >
                  Anniversaries
                </Typography>
                <Tooltip
                  title={
                    userDetails?.role === USER_ROLE?.INTRODUCER ? "" : "edit"
                  }
                >
                  <Grid>
                    <EditIconButton
                      disabled={userDetails?.role === USER_ROLE?.INTRODUCER}
                      size={"small"}
                      onClick={() => {
                        setEditState({
                          ...editState,
                          aniversaries: true,
                        });
                      }}
                    />
                  </Grid>
                </Tooltip>
              </Grid>

              {clientData?.aniversaries?.map((a) => {
                return (
                  <Grid
                    display={"flex"}
                    alignItems={"flex-start"}
                    justifyContent={"space-between"}
                    mb={1}
                    container
                  >
                    <Grid xs={8}>
                      <Typography>{a?.name}</Typography>
                    </Grid>
                    <Grid xs={4}>
                      <Typography>
                        {moment(a?.date).format("DD/MM/yyyy") || "--"}
                        {/* {a?.date} */}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>

            <Grid>
              <Typography className="normal_normal_600_12_Manrope green ">
                Added since
              </Typography>
              <Typography className="">
                {getTimeDifference(clientData?.createdAt)} -{" "}
                {moment(clientData?.createdAt).format("DD/MM/yyyy")}
                {/* {clientData?.createdAt} */}
              </Typography>
            </Grid>
          </Grid>

          {/* divider */}
          <Divider
            style={{
              border: "1px solid #00ce3f",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          />

          <Grid item xs={12} py={2}>
            <Typography className="normal_normal_600_12_Manrope green" mb={1}>
              Client Status
            </Typography>
            <FormControl fullWidth error={false}>
              <Select
                disabled={userDetails?.role === USER_ROLE?.INTRODUCER}
                className="formControlField"
                value={clientData?.leadLookupStatusId}
                label="Opportunity Status"
                onChange={(e) => {
                  UpdateOpportunityStatus(e?.target?.value);
                }}
                variant="standard"
                size="small"
              >
                <MenuItem value={null}> --</MenuItem>
                {leadStatus?.map((d) => (
                  <MenuItem key={d?.id} value={d?.id}>
                    {d?.name}
                  </MenuItem>
                ))}
              </Select>
              {false && !clientData?.opportunityStatus && (
                <FormHelperText className="red">
                  {VALIDATION_MSG?.REQUIRED}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          {/* divider */}
          <Divider
            style={{
              border: "1px solid #00ce3f",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          />

          {/* Introducer, consultant and campaign type details */}
          <Grid display={"flex"} flexDirection={"column"} gap={2}>
            <Grid>
              <Typography className="normal_normal_600_12_Manrope green ">
                INTRODUCER
              </Typography>
              <Grid
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography className="normal_normal_18_Manrope dark_grey">
                  {clientData?.leadCreator && clientData?.leadCreator?.firstName
                    ? `${clientData?.leadCreator?.firstName} ${clientData?.leadCreator?.lastName}`
                    : "NA"}
                </Typography>
                {userDetails?.role === USER_ROLE?.SUPER_ADMIN ? (
                  <Tooltip title="click to update introducer">
                    <IconButton onClick={() => setIsTransferIntroducer(true)}>
                      <TransferWithinAStationIcon />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </Grid>
            </Grid>

            {/* Introducer Note */}
            <Grid
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography className="normal_normal_600_12_Manrope green">
                INTRODUCER NOTE
              </Typography>

              <Grid>
                {editState.introducerNote ? (
                  <>
                    {/* Save Icon */}
                    <Tooltip title="Save">
                      <IconButton onClick={handleSaveClientData}>
                        <CheckCircleIcon
                          sx={{ color: "#00CE3F", cursor: "pointer" }}
                          size="medium"
                        />
                      </IconButton>
                    </Tooltip>
                    {/* Cancel Icon */}
                    <Tooltip title="Cancel">
                      <IconButton
                        onClick={() =>
                          setEditState((prev) => ({
                            ...prev,
                            introducerNote: false,
                          }))
                        }
                      >
                        <CancelIcon
                          sx={{ color: "grey", cursor: "pointer" }}
                          size="medium"
                        />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  /* Edit Icon */
                  <Tooltip
                    title={
                      userDetails?.role === USER_ROLE.SUPER_ADMIN ||
                      userDetails?.role === USER_ROLE.OFFICE_ADMIN
                        ? "edit"
                        : null
                    }
                  >
                    <EditIconButton
                      disabled={
                        !(
                          userDetails?.role === USER_ROLE.SUPER_ADMIN ||
                          userDetails?.role === USER_ROLE.OFFICE_ADMIN
                        )
                      }
                      size={"small"}
                      onClick={() =>
                        setEditState((prev) => ({
                          ...prev,
                          introducerNote: true,
                        }))
                      }
                    />
                  </Tooltip>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} style={{ marginBottom: "1.5rem" }}>
              {editState.introducerNote ? (
                <>
                  <TextField
                    fullWidth
                    placeholder="Add Note"
                    multiline
                    rows={10}
                    value={clientData?.introducerNote || ""}
                    onChange={handleNoteChange}
                    inputProps={{
                      maxLength: 101,
                    }}
                    InputProps={{
                      style: { backgroundColor: "white" },
                    }}
                    variant="outlined"
                    error={isError} // Error state is now tied directly to isError
                    helperText={
                      isError ? "You have exceeded 100 characters" : ""
                    }
                  />
                </>
              ) : (
                <>
                  <Grid
                    item
                    className="normal_normal_18_Manrope dark_grey"
                    style={{
                      maxHeight: isExpanded ? "none" : "190px",
                      overflowY: isExpanded ? "auto" : "hidden",
                      whiteSpace: "pre-wrap",
                      transition: "max-height 0.3s ease",
                      width: "100%",
                      wordBreak: "break-word",
                      maxWidth: "100ch",
                    }}
                  >
                    {clientData?.introducerNote || "No notes added."}
                  </Grid>
                  {/* See More/See Less */}
                  {clientData?.introducerNote?.length > 100 && (
                    <Typography
                      onClick={() => setIsExpanded(!isExpanded)}
                      style={{
                        color: "#00CE3F",
                        cursor: "pointer",
                        marginTop: "0.5rem",
                      }}
                    >
                      {isExpanded ? "See Less" : "See More"}
                    </Typography>
                  )}
                </>
              )}
            </Grid>

            <Grid>
              <Typography className="normal_normal_600_12_Manrope green ">
                CONSULTANT
              </Typography>
              <Grid
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography className="normal_normal_18_Manrope dark_grey">
                  {clientData?.ownerOfClient &&
                  clientData?.ownerOfClient?.firstName
                    ? `${clientData?.ownerOfClient?.firstName} ${clientData?.ownerOfClient?.lastName}`
                    : "NA"}
                </Typography>
                {userDetails?.role === USER_ROLE?.SUPER_ADMIN ? (
                  <Tooltip title="click to update consultant">
                    <IconButton onClick={() => setIsTransferConsultant(true)}>
                      <TransferWithinAStationIcon />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </Grid>
            </Grid>
            <Grid>
              <Typography className="normal_normal_600_12_Manrope green ">
                CAMPAIGN TYPE
              </Typography>
              <Typography className="normal_normal_18_Manrope dark_grey">
                {clientData?.sourceType}
              </Typography>
            </Grid>
          </Grid>

          {/* divider */}
          <Divider
            style={{
              border: "1px solid #00ce3f",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          />

          <Grid
            item
            xs={12}
            style={{ marginBottom: "1.3rem", marginTop: "1rem" }}
          >
            <Button
              disabled={userDetails?.role === USER_ROLE?.INTRODUCER}
              fullWidth
              size="small"
              variant="outlined"
              onClick={() => {
                setCreateTaskDialogOpen(true);
              }}
              className="meeting_log_btn normal_normal_semibold_14_Manrope green"
              style={{
                borderRadius: "24px",
                border:
                  userDetails?.role === USER_ROLE?.INTRODUCER
                    ? "1px solid darkgrey"
                    : "1px solid #00ce3f",
                marginTop: 5,
              }}
            >
              Create Task
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ClientSpecificDetails;
