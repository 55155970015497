// Form Validation for email template
export const formValidation = (data) => {
  if (
    !data?.name ||
    !data?.html ||
    data?.rolesAccessIds.length <= 0 ||
    !data?.templatelookupId
  ) {
    return false;
  } else {
    return true;
  }
};
