import { Grid, Typography } from "@mui/material";
import { useFieldArray } from "react-hook-form";
import { AddButton, RemoveButton } from "../../../../../components/buttons";
import { NumberInput, RadioInput, TextInput } from "../../../../../components/inputs";
import { VALIDATION_FORMAT } from "../../../../../utils/constant";

const SpecificDistribution = ({ arr, isJoint, trustIndex }) => {
  const trustees = useFieldArray({
    name: `InstructionTrustQuestionnaire.[${trustIndex}].specificDistribution`,
  });

  const { append, remove, fields } = trustees;

  const handleAdd = () => {
    append();
  };

  const handleRemove = (ind) => {
    remove(ind);
  };

  return (
    <>
      <Grid
        width={"100%"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Grid
          width={"100%"}
          paddingY={4}
          display={"flex"}
          flexDirection={"column"}
          borderTop={`1px solid #DCDCE1`}
          borderBottom={`1px solid #DCDCE1`}
          gap={0}
          // border={"1px solid red"}
        >
          <Typography>Specific Distribution</Typography>
          <Typography className="manropeSemiBold12" sx={{ color: "#00CE3F" }}>
            Specific and individual distributions should be made prior to the
            distribution of the residue
          </Typography>
        </Grid>
        <Grid
          width={"65%"}
          paddingY={4}
          display={"flex"}
          flexDirection={"column"}
          gap={3}
          // border={"1px solid red"}
        >
          {fields.map((card, ind) => {
            return (
              <SpecificDistributionForm
                key={card.id}
                cardNo={ind}
                handleRemove={() => handleRemove(ind)}
                pathName={`InstructionTrustQuestionnaire.[${trustIndex}].specificDistribution[${ind}]`}
              />
            );
          })}
          <Grid textAlign={"center"}>
            <AddButton onClick={handleAdd} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SpecificDistribution;

const SpecificDistributionForm = ({ handleRemove, pathName, cardNo }) => {
  return (
    <Grid
      boxShadow={"1px 2px 6px #00000029"}
      borderRadius={"16px"}
      padding={2}
      paddingY={3}
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      position={"relative"}
    >
      <Grid
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Grid
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          gap={1}
        >
          <Grid width={"48%"} display={"flex"} flexDirection={"column"} gap={2}>
            <Grid width={"100%"} display={"flex"} flexDirection={"row"} gap={1}>
              <TextInput
                name={`${pathName}.beneficiaryName`}
                label={`Beneficiary ${cardNo + 1}`}
                placeholder="Enter name"
              />
            </Grid>
            <Grid
              width={"100%"}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <Grid width={"48%"}>
                <RadioInput
                  name={`${pathName}.perStirpes`}
                  label="Per Stirpes"
                  options={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                />
              </Grid>
              <Grid width={"48%"}>
                <TextInput
                  name={`${pathName}.ageOfInheritance`}
                  label="Age of Inheritance"
                  placeholder="Enter age"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            width={"48%"}
            display={"flex"}
            flexDirection={"column"}
            gap={2}
            border={"0px solid red"}
          >
            <Grid
              width={"100%"}
              display={"flex"}
              flexDirection={"row"}
              gap={1}
              // border={"1px solid red"}
            >
              <TextInput
                name={`${pathName}.assetValue`}
                label="Asset or Monetary Value"
                placeholder="Enter value"
                isCurrency
              />
            </Grid>
            <Grid width={"100%"} display={"flex"} flexDirection={"row"} gap={1}>
              
              <NumberInput
            name={`${pathName}.percentageShare`}
            label="Percentage Share"
            placeholder="Percentage Share"
            rules={{
              pattern: { value: VALIDATION_FORMAT.percentageOnly.pattern, message: VALIDATION_FORMAT.percentageOnly.message },
            }}
          />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ textAlign: "right" }}>
        <RemoveButton onClick={handleRemove} />
      </div>
    </Grid>
  );
};
