export const executorsAndTrustees = {
  isJoint: false,
  fullName: "",
  role: "",
  other_config: { isWeb: true },
};

export const executorAndTrusteeFormObject = {
  fullName: "",
  role: "",
  isJoint: false,
  other_config: { isWeb: true },
};

export const guardiansObject = {
  isJoint: false,
  fullName: "",
  guardianFor: "",
  other_config: { isWeb: true },
};

export const guardiansObject1 = {
  isJoint: false,
  fullName: "",
  goingToAct: "",
  guardianFor: "",
  other_config: { isWeb: true },
};

export const chattelsAndGifts = {
  isJoint: false,
  recipientName: "",
  relationship: "",
  giftDetails: "",
  perStripes: null,
  onDeathOf: "",
  other_config: { isWeb: true },
};

export const monetaryLegaciesObject = {
  isJoint: false,
  recipientName: "",
  relationship: "",
  giftDetails: "",
  perStripes: null,
  onDeathOf: "",
  other_config: { isWeb: true },
};

export const beneficiariesObject = {
  ultimateBeneficiary: "",
  perStripes: null,
  ageOfInheritance: 18,
  percentageShare: null,
  other_config: { isWeb: true },
};

export const severancePropertiesObject = {
  property: "",
  titleNumber: "",
  percentageShare: null,
  type: null,
};

export const rtoPropertyObject = {
  property: "",
  fullName: "",
  payment: null,
  death: null,
  age: null,
  marriage: null,
  cohabitation: null,
};

export const pptPropertyObject = {
  property: "",
  fullName: "",
  subject: null,
  rightToSell: null,
  beneficiaries: [],
};

export const exclusionObject = {
  fullName: "",
  gender: "",
  dob: "",
  relationship: "",
  reasonOfExclusion: "",
  other_config: { isWeb: true },
};

export const ihtTrusteesObject = {
  fullName: "",
  other_config: { isWeb: true },
};

export const ihtCard = {
  trustee: [],
  property: "Other",
  bprAssets: false,
  beneficiaries: [],
  companyName: "",
  other_config: { isWeb: true },
};
