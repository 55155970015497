import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../components/Loader";
import { AddButton } from "../../../../components/buttons";
import {
  getInstructionTaskAnswerDetails,
  getMailListThunk,
  getNotesAndDocumentById,
} from "../../../../thunks/instruction";
import { getColorCodeForQc } from "../../../../utils/util";
import AddNotesAndDocument from "../AddNotesAndDocument";
import DocumentsByType from "../DocumentsByType";
import EmailBox from "../EmailBox";
import IndividualTask from "../IndividualTaskList";
import InstructionEmails from "../InstructionEmails";
import NotesAndRemarks from "../NotesAndRemarks";
import UpdateTaskAnswerDialog from "../UpdateTaskAnswerDialog";
import { getPinnedClientCommunication } from "../../../../thunks/client";

const Doc_notes_email_form = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userDetails = useSelector((state) => state?.auth?.currentUser);
  const { taskType, instructionId, taskId } = useSelector(
    (state) => state?.task?.TaskTypeData
  );
  const instructionData = useSelector(
    (state) => state.instruction.instructionDashboardData
  );
  const emailList = useSelector((state) => state?.instruction?.emailList);
  const [isLoader, setLoader] = useState(false);
  const [expanded, setExpanded] = useState({
    isDocument: false,
    isNotesAndRemarks: false,
    isEmail: false,
  });
  const [notesAndDocumentsData, setNotesAndDocumentsData] = useState([]);
  const [pinnedNotesAndDocumentsData, setPinnedNotesAndDocumentsData] =
    useState([]);
  const [openNotesAndDocuments, setOpenNotesAndDocuments] = useState(false);
  const [openMailbox, setOpenMailbox] = useState(false);
  const [isIndividualTask, setIsIndividualTask] = useState(false);
  const [isDocumentsOpen, setDocumentsOpen] = useState(false);
  const [updateTaskAnswerDialog, setUpdateTaskAnswerDialog] = useState(false);
  const [selectedTaskData, setSelectedTaskData] = useState(null);
  const [selectedAnswerData, setSelectedAnswerData] = useState(null);
  const [isViewOnly, setViewOnly] = useState(false);
  const [clientData, setClientData] = useState([]);

  /* Set default mail if role === officeadmin for local testing */
  // const sender =
  //   userDetails?.role?.toLowerCase() == "officeadmin"
  //     ? OFFICE_ADMIN_MAIL_ID
  //     : userDetails?.email;

  /* UAT and Production logic */
  const sender = userDetails?.email || null;

  /* Method to hide and open accordion as per key */
  const handleAccordionChange = (event, key) => {
    const updatedExpanded = {};
    for (const accordionKey in expanded) {
      updatedExpanded[accordionKey] = accordionKey === key ? event : false;
    }
    setExpanded(updatedExpanded);

    if (event && key === "isNotesAndRemarks") {
      getNotesAndDocumentsData();
      getClients();
    } else if (event && key === "isEmail") {
      getInstructionEmails();
    }
  };

  /* Method to get notes and documents data-- used in right side section accordion */
  const getNotesAndDocumentsData = async () => {
    setLoader(true);
    const response = await dispatch(
      getNotesAndDocumentById(instructionData?.id)
    ).unwrap();
    if (response) {
      setLoader(false);
      setNotesAndDocumentsData(response);
    } else {
      setLoader(false);
      setNotesAndDocumentsData([]);
    }
  };

  /* Method to get pinned notes and documents data-- used in right side section accordion */
  const getPinnedNotesAndDocumentsData = async () => {
    setLoader(true);
    const response = await dispatch(
      getPinnedClientCommunication({
        id: instructionData?.id,
        regardingType: "notes",
      })
    ).unwrap();
    if (response) {
      setLoader(false);
      setPinnedNotesAndDocumentsData(response);
    } else {
      setLoader(false);
      setPinnedNotesAndDocumentsData([]);
    }
  };

  /* Method to get instruction emails */
  const getInstructionEmails = async () => {
    try {
      setLoader(true);
      await dispatch(
        getMailListThunk({
          params: { instructionId: instructionData?.id },
        })
      ).unwrap();
    } catch (error) {
    } finally {
      setLoader(false);
    }
  };

  /* Success Method to create note */
  const handleSuccessCreateNote = () => {
    getNotesAndDocumentsData();
  };

  /* Method to get Duplicate Tasks */
  const handleClickIndividualTask = () => {
    setIsIndividualTask(true);
  };

  const openUpdateTaskAnswerDialog = (answer, data, isIndividualTask) => {
    setSelectedTaskData({ ...data, isIndividualTask });
    setSelectedAnswerData(answer);
    setUpdateTaskAnswerDialog(true);
  };

  // Method to view task comments on dialog to open updateTaskAnswer
  const viewTaskComment = (data) => {
    setSelectedTaskData({ ...data, isIndividualTask });
    setViewOnly(true);
    setUpdateTaskAnswerDialog(true);
  };

  // Method to open Document dialog
  const handleClickOpenDocument = () => {
    setDocumentsOpen(true);
  };

  // email config
  const mailReceivers = useMemo(() => {
    return instructionData?.InstructionClients?.filter(
      (obj) => obj?.email
    )?.map((obj) => ({ label: obj?.email, value: obj?.email }));
  }, [instructionData?.InstructionClients]);

  // merge instruction and client pinned notes and sort by created at date
  const sortedNotesAndDocumentsData = [
    ...notesAndDocumentsData,
    ...pinnedNotesAndDocumentsData,
  ].sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt));

  /* Method to get Clients details by passing instruction ID */
  const getClients = async () => {
    try {
      const response = await dispatch(
        getInstructionTaskAnswerDetails(instructionData?.id)
      ).unwrap();
      if (response) {
        setClientData(response);
      } else {
        setClientData([]);
      }
    } catch (error) {
      return error;
    }
  };

  //METHOD TO OPEN DIALOG
  useEffect(() => {
    taskType && setIsIndividualTask(true);
  }, [taskType]);

  useEffect(() => {
    if (instructionData?.id) {
      getClients();
      getPinnedNotesAndDocumentsData();
    }
  }, [instructionData?.id]);
  return (
    <>
      {/* Loader Component */}
      {isLoader && <Loader />}
      {/* Dialog for notes & documents */}
      {openNotesAndDocuments && (
        <AddNotesAndDocument
          open={openNotesAndDocuments}
          handleClose={() => setOpenNotesAndDocuments(false)}
          instructionId={instructionData?.id}
          handleSuccessCreateNote={handleSuccessCreateNote}
          clientData={clientData}
        />
      )}

      {/* Dialog for Duplicate Task List */}
      {isIndividualTask && (
        <IndividualTask
          open={isIndividualTask}
          taskType={taskType && taskType}
          taskId={taskId && taskId}
          handleClose={() => setIsIndividualTask(false)}
          instructionId={taskType ? instructionId : instructionData?.id}
          openUpdateTaskAnswerDialog={openUpdateTaskAnswerDialog}
          viewTaskComment={viewTaskComment}
        />
      )}

      {/* Dialog for Update task Answers */}
      {updateTaskAnswerDialog && (
        <UpdateTaskAnswerDialog
          open={updateTaskAnswerDialog}
          handleClose={() => {
            setUpdateTaskAnswerDialog(false);
            setViewOnly(false);
          }}
          selectedAnswer={selectedAnswerData}
          selectedTask={selectedTaskData}
          instructionId={instructionData?.id}
          isViewOnly={isViewOnly}
        />
      )}

      {/* Dialog for Document */}
      {isDocumentsOpen && (
        <DocumentsByType
          open={isDocumentsOpen}
          handleClose={() => setDocumentsOpen(false)}
          instructionId={instructionData?.id}
          clientData={clientData}
        />
      )}

      {/* QC Accuracy section */}
      {instructionData?.InstructionQcRatingHistory?.colour && (
        <Grid item xs={12} display={"flex"} gap={4} m={2}>
          <Typography className="serif_display_regular_18 dark_grey">
            QC Accuracy
          </Typography>
          <Typography
            className="normal_normal_16_Manrope"
            style={{
              color: getColorCodeForQc(
                instructionData?.InstructionQcRatingHistory?.colour
              ),
            }}
          >
            {instructionData?.InstructionQcRatingHistory?.colour || "NA"}
          </Typography>
        </Grid>
      )}

      {/* Send email dialog */}
      {openMailbox && (
        <EmailBox
          open={openMailbox}
          onClose={() => setOpenMailbox(false)}
          emailConfigData={{
            from: sender,
            to: mailReceivers,
          }}
          userDetails={userDetails}
          instructionId={instructionData?.id}
          instructionData={instructionData}
          clientData={clientData}
        />
      )}

      {/* Accordion for Documents Section */}
      <Grid item xs={12}>
        <Accordion
          fullWidth
          className="dashboard__accordion"
          onClick={handleClickOpenDocument}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
          >
            <Typography className="serif_display_regular_18">
              Documents
            </Typography>
          </AccordionSummary>
        </Accordion>
      </Grid>

      {/* Section form instruction forms redirect */}
      <Grid item xs={12}>
        <Accordion
          fullWidth
          className="dashboard__accordion"
          // expanded={expanded}
          onClick={() =>
            navigate(`/app/instructions/forms/${instructionData?.id}`)
          }
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
          >
            <Typography className="serif_display_regular_18 dark_grey">
              Forms
            </Typography>
          </AccordionSummary>
        </Accordion>
      </Grid>

      {/* Notes and remarks section */}
      <Grid item xs={12}>
        <Accordion
          fullWidth
          className="dashboard__accordion"
          onChange={() =>
            handleAccordionChange(
              !expanded?.isNotesAndRemarks,
              "isNotesAndRemarks"
            )
          }
          expanded={expanded?.isNotesAndRemarks}
          sx={{
            "& .Mui-expanded": {
              color: "#00ce3f",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
          >
            <Typography className="serif_display_regular_18">
              Notes/Remarks
            </Typography>
          </AccordionSummary>

          <Grid item xs={12} style={{ marginLeft: "10px" }}>
            <Divider
              orientation="horizontal"
              sx={{ border: "1px solid black " }}
            />
          </Grid>
          <AccordionDetails>
            <Grid>
              <Box textAlign={"center"}>
                <AddButton onClick={() => setOpenNotesAndDocuments(true)} />
              </Box>
            </Grid>
          </AccordionDetails>
          {/* Notes And Remarks Component */}
          <NotesAndRemarks
            notesAndDocumentsData={sortedNotesAndDocumentsData}
            instructionId={instructionData?.id}
            clientData={clientData}
            isPinned={false}
          />
        </Accordion>
      </Grid>

      {/* Email section */}
      <Grid item xs={12}>
        <Accordion
          fullWidth
          className="dashboard__accordion"
          onChange={() => handleAccordionChange(!expanded?.isEmail, "isEmail")}
          expanded={expanded?.isEmail}
          sx={{
            "& .Mui-expanded": {
              color: "#00ce3f",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
          >
            <Typography className="serif_display_regular_18">Emails</Typography>
          </AccordionSummary>

          <Grid item xs={12} style={{ marginLeft: "10px" }}>
            <Divider
              orientation="horizontal"
              sx={{ border: "1px solid black " }}
            />
          </Grid>
          <AccordionDetails>
            <Grid>
              <Box display={"flex"} justifyContent={"center"}>
                <AddButton onClick={() => setOpenMailbox(true)} />
              </Box>
            </Grid>
          </AccordionDetails>
          {/* emailData Component */}
          <InstructionEmails list={emailList} clientData={clientData} />
        </Accordion>
      </Grid>

      {/* Generic Task section */}
      <Grid item xs={12}>
        <Accordion
          fullWidth
          className="dashboard__accordion"
          onClick={handleClickIndividualTask}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
          >
            <Typography className="serif_display_regular_18 dark_grey">
              Tasks
            </Typography>
          </AccordionSummary>
        </Accordion>
      </Grid>
    </>
  );
};

export default Doc_notes_email_form;
