import { Grid } from "@mui/material";
import { useMemo } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  AddButton,
  ContainedGreenButton,
  OutlinedGreenButton,
  RemoveButton,
} from "../../../../../components/buttons";
import {
  DateInput,
  DropdownInput,
  RadioInput,
  TextInput,
} from "../../../../../components/inputs";
import { VALIDATION_MSG } from "../../../../../utils/constant";
import { mapLabelValue } from "../../../../../utils/util";
import { beneficiariesObject } from "./factFindFormConstants";

const BeneficiariesForm = () => {
  const isJoint = useSelector((state) => state?.instruction?.isJoint);
  const arr = useSelector((state) => state?.instruction?.arr);

  const client1 = useFieldArray({
    name: `InstructionClients.[0].InstructionFactFind.beneficiaries`,
  });

  const { append, remove, fields } = client1;

  const client2 = useFieldArray({
    name: `InstructionClients.[1].InstructionFactFind.beneficiaries`,
  });

  const {
    append: secondaryAppend,
    remove: secondaryRemove,
    fields: secondaryFields,
  } = client2;

  const handleAdd = (clientNumber, arrayLength) => {
    clientNumber === 0
      ? append(beneficiariesObject)
      : secondaryAppend(beneficiariesObject);
  };

  const handleRemove = (clientNumber, ind) => {
    if (clientNumber === 1) {
      remove(ind);
    } else {
      secondaryRemove(ind);
    }
  };
  return (
    <>
      <Grid
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        borderTop={`1px solid #DCDCE1`}
        justifyContent={"center"}
      >
        {" "}
        {arr?.map((item, index) => {
          return (
            <Grid
              key={item}
              width={!isJoint ? "60%" : "100%"}
              borderRight={index === 0 && isJoint ? `1px solid #DCDCE1` : null}
              paddingY={4}
              display={"flex"}
              flexDirection={"column"}
              gap={3}
              paddingRight={index === 0 ? 2 : 0}
              paddingLeft={index === 1 ? 2 : 0}
            >
              {(index === 0 ? fields : secondaryFields).map((card, ind) => {
                return (
                  <BeneficiariesFormSection
                    key={card.id}
                    isJoint={isJoint}
                    index={index}
                    cardNo={ind}
                    handleRemove={() => handleRemove(item, ind)}
                    pathName={`InstructionClients.[${index}].InstructionFactFind.beneficiaries.${ind}`}
                    isApplicationJoint={isJoint}
                  />
                );
              })}
              <Grid textAlign={"center"}>
                <AddButton
                  onClick={() =>
                    handleAdd(
                      index,
                      (item === 1 ? fields : secondaryFields).length
                    )
                  }
                />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default BeneficiariesForm;

const BeneficiariesFormSection = ({
  index,
  cardNo,
  handleRemove,
  pathName,
  isApplicationJoint,
}) => {
  const { getValues, setValue, watch } = useFormContext();
  const handleJoint = () => {
    let isJoint = getValues(`${pathName}.isJoint`) || false;
    setValue(`${pathName}.isJoint`, !isJoint);
  };

  const isCardJoint = useWatch({
    name: `${pathName}.isJoint`,
  });

  let { relationshipAddr, Gender, beneficiary_classification } = useSelector(
    (state) => state?.lookupData
  );
  let genderList = useMemo(() => mapLabelValue(Gender), [Gender]);

  let watchClassification = watch(`${pathName}.classification`);

  return (
    <>
      {cardNo == 0 && (
        <Grid container>
          <Grid item xs={12}>
            <RadioInput
              orientation="row"
              label={`If you were to die first, are all assets solely in your name to be left to the other client in the first instance?`}
              name={`${pathName}.question1`}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
            />
          </Grid>

          {index == 0 ? (
            <Grid item xs={12}>
              <RadioInput
                orientation="row"
                label={`Are you leaving assets to the same beneficiaries upon second death?`}
                name={`${pathName}.question2`}
                options={[
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ]}
                rules={{
                  required: {
                    value: false,
                    message: VALIDATION_MSG.REQUIRED,
                  },
                }}
              />
            </Grid>
          ) : (
            <Grid item xs={12} style={{ marginTop: "4rem" }}></Grid>
          )}
        </Grid>
      )}
      <Grid
        boxShadow={"1px 2px 6px #00000029"}
        borderBottom={`1px solid #DCDCE1`}
        // border={"2px solid red"}
        borderRadius={"16px"}
        borderRight={index === 0 ? `1px solid #DCDCE1` : null}
        padding={2}
        paddingY={3}
        display={"flex"}
        flexDirection={"column"}
        gap={2}
        position={"relative"}
      >
        <Grid container spacing={3}>
          {index === 0 && isApplicationJoint ? (
            <div className="card-mirror-btn">
              {isCardJoint ? (
                <ContainedGreenButton label={"Joint"} onClick={handleJoint} />
              ) : (
                <OutlinedGreenButton label={"Joint"} onClick={handleJoint} />
              )}
            </div>
          ) : null}
          <Grid item xs={12}>
            <TextInput
              name={`${pathName}.name`}
              label="Name"
              placeholder="Name"
              rules={{
                required: { value: true, message: VALIDATION_MSG.REQUIRED },
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <RadioInput
              label={`Beneficiaries Type`}
              name={`${pathName}.classification`}
              options={beneficiary_classification || []}
              rules={{
                required: { value: true, message: VALIDATION_MSG.REQUIRED },
              }}
            />
          </Grid>
          {watchClassification === "Named Beneficiary" && (
            <>
              <Grid item xs={6}>
                <RadioInput
                  label={`Gender`}
                  name={`${pathName}.gender`}
                  options={genderList}
                  rules={{
                    required: { value: true, message: VALIDATION_MSG.REQUIRED },
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <DateInput name={`${pathName}.dob`} label="Date Of Birth" />
              </Grid>

              <Grid item xs={6}>
                <DropdownInput
                  name={`${pathName}.relationship`}
                  label="Relationship"
                  options={relationshipAddr}
                  rules={{
                    required: { value: true, message: VALIDATION_MSG.DROPDOWN },
                  }}
                />
              </Grid>
            </>
          )}

          <Grid item xs={6}>
            <RadioInput
              orientation="row"
              label={`Vulnerable`}
              name={`${pathName}.isVulnerable`}
              options={[
                { label: "Yes", value: "true" },
                { label: "No", value: "false" },
              ]}
            />
          </Grid>

          <Grid item xs={12}>
            <TextInput
              name={`${pathName}.notes`}
              label="Add a note"
              placeholder="Add a note"
            />
          </Grid>
        </Grid>

        <div style={{ textAlign: "right" }}>
          <RemoveButton label={"Joint"} onClick={handleRemove} />
        </div>
      </Grid>
    </>
  );
};
