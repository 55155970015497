import { Grid } from "@mui/material";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import "../../../../../custom-bootstrap.scss";
import { updateInstruction } from "../../../../../thunks/instruction";
import ArtificialNutritionForm from "./ArtificialNutritionForm";
import ArtificialVentilationForm from "./ArtificialVentilationForm";
import BasicDetails from "./BasicDetails";
import BloodTransfusionsForm from "./BloodTransfusionsForm";
import ConsciousnessForm from "./ConsciousnessForm";
import ExistingDocForm1 from "./ExistingDocForm1";
import ExistingDocForm2 from "./ExistingDocForm2";
import GeneralPractitionalForm from "./GeneralPractitionalForm";
import OtherScenariosForm from "./OtherScenariosForm";
import PainOrDistressForm from "./PainOrDistressForm";
import PeoplePresentAtMyDeathForm from "./PeoplePresentAtMyDeathForm";
import PregnancyForm from "./PregnancyForm";
import ResuscitationForm from "./ResuscitationForm";
import TerminalIllnessInjuryForm from "./TerminalIllnessInjuryForm";
import UnremitingPainForm from "./UnremitingPainForm";

const LivingWillQuestionnaireForm = () => {
  const dispatch = useDispatch();
  const instructionDataObject = useSelector(
    (state) => state?.instruction?.tempInstructionData
  );
  const isJoint = useSelector((state) => state?.instruction?.isJoint);
  const arr = useSelector((state) => state?.instruction?.arr);

  const methods = useFormContext();
  const { control } = methods;

  const clientArray = useFieldArray({
    control,
    name: "InstructionLivingWillQuestionnaire",
  });

  const handleSave = (data) => {
    const updatedData = {
      InstructionData: {
        ...instructionDataObject,
        // id: instructionDataObject.id,
        InstructionLivingWillQuestionnaire: data.clients,
      },
    };
    const res = dispatch(updateInstruction(updatedData));
  };
  const handleErrors = (errors) => {};

  return (
    <>
      {/* <> */}
      <Grid
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        width={"100%"}
        className="normal_normal_semibold_20_Manrope green"
      >
        {/* <FormHeader formName={formNames.livingWillQuestionnaire} /> */}
        <Grid width={"100%"} mb={4} xs={12}>
          <BasicDetails />
        </Grid>
        <Grid item xs={12}>
          <GeneralPractitionalForm />
        </Grid>
        <Grid item xs={12}>
          <ExistingDocForm1 />
        </Grid>
        <Grid item xs={12}>
          <ExistingDocForm2 />
        </Grid>
        <Grid item xs={12}>
          <ConsciousnessForm />
        </Grid>
        <Grid item xs={12}>
          <UnremitingPainForm />
        </Grid>
        <Grid item xs={12}>
          <TerminalIllnessInjuryForm />
        </Grid>
        <Grid item xs={12}>
          <OtherScenariosForm />
        </Grid>
        <Grid item xs={12}>
          <ResuscitationForm />
        </Grid>
        <Grid item xs={12}>
          <ArtificialNutritionForm />
        </Grid>
        <Grid item xs={12}>
          <ArtificialVentilationForm />
        </Grid>
        <Grid item xs={12}>
          <BloodTransfusionsForm />
        </Grid>
        <Grid item xs={12}>
          <PainOrDistressForm />
        </Grid>
        <Grid item xs={12}>
          <PregnancyForm />
        </Grid>
        <Grid item xs={12}>
          <PeoplePresentAtMyDeathForm />
        </Grid>
        {/* <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1rem",
            }}
          >
            <SubmitButton onClick={handleSubmit(handleSave, handleErrors)} />
          </Grid> */}
      </Grid>
      {/*</> */}
    </>
  );
};

export default LivingWillQuestionnaireForm;
