import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { RemoveButton } from "../../../../../../components/buttons";
import { NumberInput, RadioInput, TextInput } from "../../../../../../components/inputs";
import { VALIDATION_FORMAT, VALIDATION_MSG } from "../../../../../../utils/constant";

const RDTForm = ({ index, cardNo, pathName, handleRemove }) => {
  let { beneficiary_classification } = useSelector(
    (state) => state?.lookupData
  );

  return (
    <Grid
      boxShadow={"1px 2px 6px #00000029"}
      borderRadius={"16px"}
      borderRight={index === 0 ? `1px solid #DCDCE1` : null}
      padding={2}
      paddingY={3}
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      position={"relative"}
    >
      <TextInput
        label={`Ultimate Beneficiary ${cardNo + 1}`}
        name={`${pathName}.ultimateBeneficiary`}
        placeholder="Enter name"
      />

      <Grid item xs={12}>
        <RadioInput
          label={`Beneficiaries Type`}
          name={`${pathName}.classification`}
          options={beneficiary_classification || []}
          rules={{
            required: { value: true, message: VALIDATION_MSG.REQUIRED },
          }}
        />
      </Grid>

      <Grid
        display={"flex"}
        width={"100%"}
        justifyContent={"space-between"}
        gap={2}
      >
        <Grid width={"32%"}>
          <RadioInput
            label="Per Stirpes"
            name={`${pathName}.perStripes`}
            options={[
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
          />
        </Grid>
        <Grid width={"30%"}>
          <TextInput
            label="Age of Inheritance"
            name={`${pathName}.ageOfInheritance`}
            placeholder="Enter age"
          />
        </Grid>
        <Grid width={"32%"}>
          <NumberInput
            name={`${pathName}.percentageShare`}
            label="Percentage Share"
            placeholder="Percentage Share"
            rules={{
              pattern: { value: VALIDATION_FORMAT.percentageOnly.pattern, message: VALIDATION_FORMAT.percentageOnly.message },
            }}
          />
        </Grid>
      </Grid>

      <div style={{ textAlign: "right" }}>
        <RemoveButton label={"Joint"} onClick={handleRemove} />
      </div>
    </Grid>
  );
};

export default RDTForm;
