// library item imports
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import thunk from "redux-thunk";
// slice imports
import authReducer from "../slice/auth";
import clientReducer from "../slice/client";
import commissionReducer from "../slice/commission";
import dashboardReducer from "../slice/dashboard";
import documentReducer from "../slice/documents";
import emailTemplate from "../slice/email_template";
import instructionReducer from "../slice/instruction";
import lookupDataReducer from "../slice/lookup";
import planReducer from "../slice/plan";
import productReducer from "../slice/product";
import snackbarReducer from "../slice/snackbarSlice";
import taskReducer from "../slice/task";
import userReducer from "../slice/user";

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  snackbar: snackbarReducer,
  lookupData: lookupDataReducer,
  product: productReducer,
  plan: planReducer,
  instruction: instructionReducer,
  client: clientReducer,
  commission: commissionReducer,
  emailTemplate: emailTemplate,
  dashboard: dashboardReducer,
  task: taskReducer,
  document: documentReducer,
});

export const Store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
});

export default Store;
