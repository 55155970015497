import { Grid } from "@mui/material";
import { useMemo } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  AddButton,
  ContainedGreenButton,
  OutlinedGreenButton,
  RemoveButton,
} from "../../../../../components/buttons";
import {
  DropdownInput,
  NumberInput,
  RadioInput,
  TextInput,
} from "../../../../../components/inputs";
import { lifeInsurenceObject } from "./factFindFormConstants";

const LifeInsurenceForm = () => {
  const isJoint = useSelector((state) => state?.instruction?.isJoint);
  const arr = useSelector((state) => state?.instruction?.arr);

  const client1 = useFieldArray({
    name: `InstructionClients.[0].InstructionFactFind.lifeInsurance`,
  });

  const { append, remove, fields } = client1;

  const client2 = useFieldArray({
    name: `InstructionClients.[1].InstructionFactFind.lifeInsurance`,
  });

  const {
    append: secondaryAppend,
    remove: secondaryRemove,
    fields: secondaryFields,
  } = client2;

  const handleAdd = (clientNumber, arrayLength) => {
    clientNumber === 0
      ? append(lifeInsurenceObject)
      : secondaryAppend(lifeInsurenceObject);
  };

  const handleRemove = (clientNumber, ind) => {
    if (clientNumber === 1) {
      remove(ind);
    } else {
      secondaryRemove(ind);
    }
  };
  return (
    <>
      <Grid
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        borderTop={`1px solid #DCDCE1`}
        justifyContent={"center"}
      >
        {" "}
        {arr?.map((item, index) => {
          return (
            <Grid
              key={item}
              width={!isJoint ? "60%" : "100%"}
              borderRight={index === 0 && isJoint ? `1px solid #DCDCE1` : null}
              paddingY={4}
              display={"flex"}
              flexDirection={"column"}
              gap={3}
              paddingRight={index === 0 ? 2 : 0}
              paddingLeft={index === 1 ? 2 : 0}
            >
              {(index === 0 ? fields : secondaryFields).map((card, ind) => {
                return (
                  <LifeInsurenceFormSection
                    key={card.id}
                    isJoint={isJoint}
                    index={index}
                    cardNo={ind}
                    handleRemove={() => handleRemove(item, ind)}
                    pathName={`InstructionClients.[${index}].InstructionFactFind.lifeInsurance.${ind}`}
                    isApplicationJoint={isJoint}
                  />
                );
              })}
              <Grid textAlign={"center"}>
                <AddButton
                  onClick={() =>
                    handleAdd(
                      index,
                      (item === 1 ? fields : secondaryFields).length
                    )
                  }
                />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default LifeInsurenceForm;

const LifeInsurenceFormSection = ({
  index,
  cardNo,
  handleRemove,
  pathName,
  isApplicationJoint,
}) => {
  const { getValues, setValue } = useFormContext();
  const handleJoint = () => {
    let isJoint = getValues(`${pathName}.isJoint`) || false;
    setValue(`${pathName}.isJoint`, !isJoint);
  };

  const isCardJoint = useWatch({
    name: `${pathName}.isJoint`,
  });

  let { "Insurance Type": insuranceTypes } = useSelector(
    (state) => state?.lookupData
  );
  insuranceTypes = useMemo(
    () =>
      insuranceTypes?.length
        ? insuranceTypes?.map((obj) => ({ label: obj?.name, value: obj?.name }))
        : [],
    [insuranceTypes]
  );

  return (
    <Grid
      boxShadow={"1px 2px 6px #00000029"}
      borderBottom={`1px solid #DCDCE1`}
      // border={"2px solid red"}
      borderRadius={"16px"}
      borderRight={index === 0 ? `1px solid #DCDCE1` : null}
      padding={2}
      paddingY={3}
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      position={"relative"}
    >
      {index === 0 && isApplicationJoint ? (
        <div className="card-mirror-btn">
          {isCardJoint ? (
            <ContainedGreenButton label={"Joint"} onClick={handleJoint} />
          ) : (
            <OutlinedGreenButton label={"Joint"} onClick={handleJoint} />
          )}
        </div>
      ) : null}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DropdownInput
            name={`${pathName}.type`}
            label="Insurence Type"
            options={insuranceTypes}
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            name={`${pathName}.provider`}
            label="Provider"
            placeholder="Provider"
          />
        </Grid>

        <Grid item xs={12}>
          <RadioInput
            label={`Up to date Death Benefit Nomination Forms`}
            name={`${pathName}.isUptoDateDeathBenefitForms`}
            options={[
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
          />
        </Grid>

        <Grid item xs={12}>
          <NumberInput
            isCurrency
            name={`${pathName}.value`}
            label="Value"
            placeholder="Value"
          />
        </Grid>
      </Grid>

      <div style={{ textAlign: "right" }}>
        <RemoveButton label={"Joint"} onClick={handleRemove} />
      </div>
    </Grid>
  );
};
