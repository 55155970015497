import { Add, Check, Delete, EditSharp, LocalPhone } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import { Button } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import SmsIcon from "@mui/icons-material/Sms";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HistoryIcon from "@mui/icons-material/History";

//ROUND BUTTON
export const RoundButton = (props) => {
  const { label, onClick, style = {} } = props;
  return (
    <button
      type="button"
      style={{ ...style }}
      onClick={onClick}
      className="roundOutlinedButton"
    >
      {label}
    </button>
  );
};

// SECTION - RECORD ICON
export const RecordIcon = ({ type }) => {
  return (
    <IconButton
      onClick={() => {
        alert("Meeting is still going on.");
      }}
      color="secondary"
    >
      {type === "icon" ? (
        <Check fontSize="small" style={{ color: "red" }} />
      ) : (
        <Button variant="outlined" color="secondary">
          Meeting
        </Button>
      )}
    </IconButton>
  );
};

// SECTION - SUBMIT BUTTON
export const SubmitButton = ({ onClick, isDisabled = false }) => {
  return (
    <Button
      type="submit"
      onTouchMove={false}
      disableElevation
      onClick={onClick}
      variant="contained"
      disabled={isDisabled}
      className="QC_submit_button"
      size="small"
    >
      Save
    </Button>
  );
};

// SECTION - JOINT BUTTON
export const OutlinedGreenButton = ({ onClick, label, style }) => {
  return (
    <Button
      capitalize={false}
      variant="outlined"
      onClick={onClick}
      className="outlined_green_button"
      style={{ ...style }}
    >
      {label}
    </Button>
  );
};

// SECTION - JOINT BUTTON
export const ContainedGreenButton = ({
  isJointValue,
  setValue,
  namePrefix,
  onClick,
  label,
  sx,
  hideIcon,
}) => {
  return (
    <Button
      capitalize={false}
      variant="outlined"
      onClick={onClick}
      className="contained_green_button"
      sx={sx ? { ...sx } : undefined}
    >
      {label}
      {!hideIcon && (
        <DoneIcon style={{ fontSize: "16px", marginTop: "-4px" }} />
      )}
    </Button>
  );
};
// OUTLINED BUTTON
export const OutlinedButton = ({ onClick, label, color }) => {
  return (
    <Button
      capitalize={false}
      className="normal_normal_semibold_14_Manrope dark_grey outlined_button"
      variant="outlined"
      onClick={onClick}
      style={{
        textTransform: "none",
        paddingLeft: "30px",
        paddingRight: "30px",
        height: "max-content",
        border: `1px solid ${color}`,
        color: color,
        borderRadius: "20px",
        backgroundColor: "#FFFFFF",
        display: "inline-block",
      }}
    >
      {label}
    </Button>
  );
};

// SECTION - Add bene button
export const ContainedButton = ({
  isJointValue,
  setValue,
  namePrefix,
  onClick,
  label,
  textColor = "white",
  disabled = false,
  backgroundColor = disabled ? "#DCDCE1" : "#3D4740",
}) => {
  return (
    <Button
      disabled={disabled}
      capitalize={false}
      autoCapitalize={false}
      disableElevation
      variant="contained"
      onClick={onClick}
      className="normal_normal_600_14_Manrope"
      style={{
        textTransform: "none",
        border: disabled ? "#00000029" : null,
        backgroundColor: disabled
          ? "#00000029"
          : backgroundColor
          ? backgroundColor
          : "#3D4740",
        color: textColor ? textColor : "white",
        borderRadius: "32px",
        display: "inline-block",
        padding: "8px",
        paddingLeft: "12px",
        paddingRight: "12px",
        boxSizing: "border-box",
      }}
    >
      {label}
    </Button>
  );
};

// SECTION - ADD BUTTON
export const AddButton = ({ onClick, children, fontSize = "large" }) => {
  return (
    <Button
      onClick={onClick}
      style={{
        textTransform: "none",
        padding: 2,
        width:
          fontSize === "large"
            ? "80px"
            : fontSize === "medium"
            ? "60px"
            : "40px",
        borderRadius:
          fontSize === "large"
            ? "20px"
            : fontSize === "medium"
            ? "15px"
            : "10px",
        color: "white",
        backgroundColor: "#00CE3F",
      }}
    >
      {children || <Add fontSize={fontSize} style={{}} />}
    </Button>
  );
};

// SECTION - REMOVE BUTTON
export const RemoveButton = ({ onClick, isDisabled = false }) => {
  return (
    <Button
      autoCapitalize={false}
      onClick={onClick}
      variant="outlined"
      color="secondary"
      disabled={isDisabled}
      style={{
        textTransform: "none",
        border: `1px solid #ff0000`,
        color: "#ff0000",
        width: "100px",
        borderRadius: "20px",
        fontSize: "12px",
        alignItems: "center",
      }}
      endIcon={
        <Delete
          fontSize="small"
          style={{ color: "#ff0000", fontSize: "16px" }}
        />
      }
    >
      Remove
    </Button>
  );
};

// SECTION - Add bene button
export const FormExitButton = ({ onClick }) => {
  return (
    <Button
      capitalize={false}
      autoCapitalize={false}
      disableElevation
      variant="contained"
      onClick={onClick}
      style={{
        border: `1px solid white`,
        backgroundColor: "white",
        color: "#00CE3F",
        borderRadius: "30px",
        fontSize: "12px",
        display: "inline-block",
        padding: "8px",
        paddingLeft: "12px",
        paddingRight: "12px",
      }}
    >
      Exit
    </Button>
  );
};

// EMAIL ICON
export const EmailIconButton = ({
  onClick = () => {},
  disabled = false,
  size = "large",
}) => {
  return (
    <IconButton onClick={onClick} color="secondary" disabled={disabled}>
      <EmailIcon
        fontSize={size}
        style={{ color: disabled ? "grey" : "#00CE3F" }}
      />
    </IconButton>
  );
};

// ICON
export const NoteIconButton = ({
  onClick = () => {},
  disabled = false,
  size = "large",
}) => {
  return (
    <IconButton onClick={onClick} color="secondary" disabled={disabled}>
      <NoteAltIcon
        fontSize={size}
        style={{ color: disabled ? "grey" : "#00CE3F" }}
      />
    </IconButton>
  );
};

// PHONE ICON
export const PhoneIconButton = ({
  onClick = () => {},
  disabled = false,
  size = "large",
}) => {
  return (
    <IconButton onClick={onClick} color="secondary" disabled={disabled}>
      <LocalPhoneIcon
        fontSize={size}
        style={{ color: disabled ? "grey" : "#00CE3F" }}
      />
    </IconButton>
  );
};

// SMS ICON
export const SmsIconButton = ({
  onClick = () => {},
  disabled = false,
  size = "large",
}) => {
  return (
    <IconButton onClick={onClick} color="secondary" disabled={disabled}>
      <SmsIcon
        fontSize={size}
        style={{ color: disabled ? "grey" : "#00CE3F" }}
      />
    </IconButton>
  );
};
// Edit ICON
export const EditIconButton = ({
  onClick = () => {},
  disabled = false,
  size = "large",
}) => {
  return (
    <IconButton onClick={onClick} color="secondary" disabled={disabled}>
      <EditSharpIcon
        fontSize={size}
        style={{ color: disabled ? "grey" : "#00CE3F" }}
      />
    </IconButton>
  );
};

export const HistoryIconButton = ({
  onClick = () => {},
  disabled = false,
  size = "large",
}) => {
  return (
    <IconButton onClick={onClick} color="secondary" disabled={disabled}>
      <HistoryIcon
        fontSize={size}
        style={{ color: disabled ? "grey" : "#00CE3F" }}
      />
    </IconButton>
  );
};
