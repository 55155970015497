import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "../../../../assets/images/BUTTON_Back.svg";
import Loader from "../../../../components/Loader";
import AlertComponent from "../../../../components/alert";
import "../../../../custom-bootstrap.scss";
import { showSnackbar } from "../../../../slice/snackbarSlice";
import { getInstructionType } from "../../../../thunks/instruction";
import {
  getPlanDataById,
  getPlansWhatIfs,
  updatePlan,
} from "../../../../thunks/plan";
import { getProduct } from "../../../../thunks/product";
import {
  NOTIFICATION_MESSAGE,
  VALIDATION_MSG,
} from "../../../../utils/constant";
import { NumberValidation, getUniqueArray } from "../../../../utils/util";
import { formValidation } from "./formValidation";

const UpdatePlan = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const instructionTypes = useSelector(
    (state) => state?.instruction?.instructionTypeData
  );
  const [showField, setShowField] = useState(false);
  const [productList, setProductList] = useState([]);
  const [whatIfsList, setWhatIfsList] = useState([]);
  //source type
  const [planPricing, setPlanPricing] = useState([]);
  const [filterCampignType, setfilterCampignType] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isDisableDialog, setDisableDialog] = useState(false);
  const [isUpdatePlanDialog, setUpdatePlanDialog] = useState(false);

  const [planData, setPlanData] = useState({
    name: "",
    description: "",
    isActive: null,
    instructionTypeId: null,
    singleStandardFees: null,
    jointStandardFees: null,
    perVat: null,
    InstructionTypeProduct: [],
    PlanProduct: [],
    PlantoFeatureWhatIfAns: [],
    lossOfCapacity: {
      forSingle: 2170,
      forJoint: 4340,
    },
    costOfProbate: 0,
  });

  const [isLoader, setLoader] = useState(false);
  const [isShowButton, setIsShowButton] = useState(false);

  /* Get plan by ID */
  const getPlanById = async () => {
    try {
      setLoader(true);
      let data = await dispatch(getPlanDataById(params.id)).unwrap();
      if (data?.id) {
        const tempPlanProduct = [];
        const tempPlanPricing = [];
        const tempWhatIfQues = [];

        if (data?.PlanProduct?.length > 0) {
          data?.PlanProduct?.map((d) => {
            tempPlanProduct.push({
              id: d?.productId,
              name: d?.productName,
            });
          });
        }

        if (data?.PlanPricing?.length > 0) {
          data?.PlanPricing?.map((d) => {
            tempPlanPricing.push({
              sourceTypeLookupId: d?.sourceTypeLookupId,
              sourceType: d?.sourceType,
              costOfProbate: Number(d?.costOfProbate),
              singleSourceTypeStandardFees:
                Number(d?.singleSourceTypeStandardFees) || null,
              jointSourceTypeStandardFees:
                Number(d?.jointSourceTypeStandardFees) || null,
            });
          });
        }

        if (data?.PlantoFeatureWhatIfAns?.length > 0) {
          data?.PlantoFeatureWhatIfAns.map((d) => {
            tempWhatIfQues.push({
              question: d?.question,
              whatIfId: d?.whatIfId,
              ans: d?.ans?.toString(),
            });
          });
        }

        const conditionMet = data?.PlanProduct?.some(
          (item) =>
            item?.productName === "Single LPA" ||
            item.productName === "Both LPA"
        );
        setShowField(conditionMet);
        let tempLossOfCapacity = {};

        if (conditionMet) {
          tempLossOfCapacity = {
            forJoint: 0,
            forSingle: 0,
          };
        } else {
          tempLossOfCapacity = {
            forJoint: Number(data?.lossOfCapacity?.forJoint),
            forSingle: Number(data?.lossOfCapacity?.forSingle),
          };
        }

        setPlanData({
          ...planData,
          isActive: data?.isActive,
          instructionTypeId: data?.instructionType,
          name: data?.planName,
          description: data?.description,
          singleStandardFees: Number(data?.singleStandardFees),
          jointStandardFees: Number(data?.jointStandardFees),
          costOfProbate: Number(data?.costOfProbate),
          perVat: Number(data?.perVat),
          PlanProduct: tempPlanProduct || [],
          lossOfCapacity: tempLossOfCapacity,
        });
        setPlanPricing(tempPlanPricing);
        setfilterCampignType(tempPlanPricing);
        setWhatIfsList(tempWhatIfQues);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  /* Method to Get instruction type data */
  const getInstructionTypeData = async () => {
    try {
      const response = dispatch(getInstructionType()).unwrap();
    } catch (error) {}
  };

  /* Method to change form fields */
  const handleChangeFormFields = (value, key) => {
    if (key === "forSingle" || key === "forJoint") {
      setPlanData({
        ...planData,
        lossOfCapacity: {
          ...planData?.lossOfCapacity,
          [key]: value,
        },
      });
    } else {
      setPlanData({ ...planData, [key]: value });
    }
  };

  /* Method to get plans whatif questions */
  const handleGetPlansWhatIfs = async () => {
    try {
      const whatIfsList = await dispatch(getPlansWhatIfs()).unwrap();
      const tempWhatIfQues = [];
      if (whatIfsList?.length > 0) {
        whatIfsList?.map((d) => {
          tempWhatIfQues.push({
            question: d?.question,
            whatIfId: d?.id,
            ans: "false",
          });
        });
        setWhatIfsList(tempWhatIfQues);
      } else {
        setWhatIfsList([]);
      }
    } catch (error) {}
  };

  /* Method to open confirm dialog to update plan */
  const handleConfirmUpdatePlan = () => {
    setUpdatePlanDialog(true);
  };

  /* Method to update plan */
  const handleUpdatePlan = async () => {
    setUpdatePlanDialog(false);
    const isValidForm = formValidation(planData);
    // was null is use to validate productPricing number validations
    let wasNull = false;
    for (var i in planPricing) {
      if (
        (planPricing[i]?.singleSourceTypeStandardFees &&
          !NumberValidation(planPricing[i]?.singleSourceTypeStandardFees)) ||
        (planPricing[i]?.jointSourceTypeStandardFees &&
          !NumberValidation(planPricing[i]?.jointSourceTypeStandardFees))
      ) {
        wasNull = true;
      }
    }
    if (!isValidForm || wasNull) {
      setIsError(true);
    } else {
      let temp = JSON.parse(JSON.stringify(planData));
      temp.id = Number(params.id);
      setIsError(false);
      // Converting productPricing sourceTypeStandardFees to number
      planPricing?.length > 0 &&
        planPricing?.forEach((obj) => {
          if (
            obj?.singleSourceTypeStandardFees !== null ||
            obj?.jointSourceTypeStandardFees !== null
          ) {
            obj.singleSourceTypeStandardFees = Number(
              obj?.singleSourceTypeStandardFees
            );
            obj.jointSourceTypeStandardFees = Number(
              obj?.jointSourceTypeStandardFees
            );
          }
        });
      // remove all unwanted data from object of PlanProduct array and keep only productId property
      let PlanProduct = [];
      if (temp?.PlanProduct?.length > 0) {
        // get unique values
        const arrayUniqueByKey = getUniqueArray(temp?.PlanProduct, "id");
        arrayUniqueByKey?.map((d) => {
          PlanProduct.push({
            productId: d?.id,
          });
        });
      }

      temp.PlanProduct = PlanProduct || [];

      // extract instructionTypeId from InstructionTypeProduct array and delete InstructionTypeProduct array from temp object since is it not required in payload
      delete temp.InstructionTypeProduct;
      delete temp.instructionTypeId;

      temp.PlanPricing = planPricing || [];
      temp.singleStandardFees = Number(temp.singleStandardFees) || 0;
      temp.jointStandardFees = Number(temp.jointStandardFees) || 0;
      temp.perVat = Number(temp.perVat) || 0;
      temp.lossOfCapacity = {
        forJoint: Number(temp.lossOfCapacity.forJoint) || 0,
        forSingle: Number(temp.lossOfCapacity.forSingle) || 0,
      };
      temp.costOfProbate = Number(temp.costOfProbate) || 0;

      temp.PlantoFeatureWhatIfAns = whatIfsList.map((d, index) => ({
        ans: JSON.parse(d?.ans.toLowerCase()),
        whatIfId: d?.whatIfId,
      }));

      try {
        setLoader(true);
        const response = await dispatch(updatePlan(temp)).unwrap();
        if (response?.id) {
          dispatch(
            showSnackbar({
              message: NOTIFICATION_MESSAGE?.PLAN_UPDATE_SUCCESS,
              severity: "success",
            })
          );
        }
      } catch (error) {
        setUpdatePlanDialog(false);
        dispatch(
          showSnackbar({
            message: `${error?.message}`,
            severity: "error",
          })
        );
      } finally {
        setLoader(false);
      }
    }
  };

  /* Success Method to disable plan */
  const handleSuccessDisable = async () => {
    try {
      const payload = {
        id: Number(params?.id),
        name: planData?.name,
        isActive: planData?.isActive === true ? false : true,
      };
      setLoader(true);
      const response = await dispatch(updatePlan(payload)).unwrap();
      if (response) {
        setLoader(false);
        setDisableDialog(false);
        if (
          planData?.isActive
            ? dispatch(
                showSnackbar({
                  show: true,
                  message: NOTIFICATION_MESSAGE?.PLAN_DEACTIVATE_SUCCESS,
                  severity: "success",
                })
              )
            : dispatch(
                showSnackbar({
                  show: true,
                  message: NOTIFICATION_MESSAGE?.PLAN_ACTIVATE_SUCCESS,
                  severity: "success",
                })
              )
        )
          navigate("/app/manage/plan/list");
      }
    } catch (error) {
      setLoader(false);
      setDisableDialog(false);
      dispatch(
        showSnackbar({
          show: true,
          message: `${error?.message}`,
          severity: "error",
        })
      );
      navigate("/app/manage/plan/list");
    }
  };

  /* Method to get product data */
  const getProductsData = async () => {
    try {
      let productList = await dispatch(getProduct()).unwrap();
      if (productList?.length > 0) {
        let mapProductList = productList?.map((product, index) => ({
          id: product?.id,
          name: product?.productName,
        }));
        setProductList(mapProductList);
      } else {
        setProductList([]);
      }
    } catch (error) {}
  };

  /* Method to change products */
  const handleChangeProduct = (value) => {
    const products = [];
    if (value) {
      value?.map((d) => {
        products?.push({
          id: d?.id,
          name: d?.name,
        });
      });
      setPlanData({ ...planData, PlanProduct: products });
    }
  };

  /* Method to change plan pricing */
  const handleChangePlanPricing = (e, id, key) => {
    setPlanPricing((prevItems) =>
      prevItems.map((item) =>
        item.sourceTypeLookupId === id ? { ...item, [key]: e } : item
      )
    );
    setfilterCampignType((prevItems) =>
      prevItems.map((item) =>
        item.sourceTypeLookupId === id ? { ...item, [key]: e } : item
      )
    );
  };

  // method to filter source type
  const handleChangeSearch = (search) => {
    if (search?.trim()?.length === 0) {
      setfilterCampignType(planPricing);
    } else {
      const filtered = planPricing?.filter((item) =>
        item?.sourceType?.toLowerCase()?.includes(search?.toLowerCase())
      );
      setfilterCampignType(filtered);
    }
  };

  /* Method to change whatIf Questions */
  const handleChangeWhatIfQues = (e, id, key) => {
    setWhatIfsList((prevItems) =>
      prevItems.map((item) =>
        item?.whatIfId === id ? { ...item, [key]: e } : item
      )
    );
  };

  /* Confirmation method to disbale plan */
  const handleDisablePlan = () => {
    setDisableDialog(true);
  };

  useEffect(() => {
    getPlanById();
    getInstructionTypeData();
    // handleGetPlansWhatIfs();
    getProductsData();
  }, []);

  return (
    <>
      {/* Loader component */}
      {isLoader && <Loader />}
      {/* Confirmation dialog to disable plan */}
      {isDisableDialog && (
        <AlertComponent
          open={isDisableDialog}
          handleSuccess={handleSuccessDisable}
          handleClose={() => setDisableDialog(false)}
          message={`Are you sure, want to ${
            planData?.isActive === true ? "Deactivate" : "Activate"
          } plan?`}
        />
      )}

      {/* Confirmation dialog to update plan */}
      {isUpdatePlanDialog && (
        <AlertComponent
          open={isUpdatePlanDialog}
          handleSuccess={handleUpdatePlan}
          handleClose={() => setUpdatePlanDialog(false)}
          message={`Are you sure, want to update plan?`}
        />
      )}

      {/* Form Layout */}
      <Grid
        container
        spacing={2}
        justify="flex-start"
        className="create-user instruction_dashboard_scrollbar"
        style={{
          height: "90vh",
          overflow: "auto",
        }}
        pl={5}
        pr={5}
      >
        {/* ADD NEW PLAN BUTTON */}
        <Grid item xs={12}>
          <Typography className="serif_display_regular_26">
            <img
              src={BackButton}
              onClick={() => navigate("/app/manage/plan/list")}
              style={{
                cursor: "pointer",
                marginRight: "10px",
              }}
            />
            Manage Plan
          </Typography>
        </Grid>

        {/* Plan Name */}
        <Grid item xs={2.5} mt={0.5}>
          <TextField
            InputProps={{
              readOnly: true,
            }}
            required
            className="inputTextField normal_normal_600_12_Manropee"
            label="Plan Name"
            placeholder="Plan Name"
            fullWidth
            size="small"
            error={isError && !planData?.name}
            helperText={isError && !planData?.name && VALIDATION_MSG.REQUIRED}
            value={planData?.name}
            onChange={(e) => handleChangeFormFields(e?.target?.value, "name")}
            InputLabelProps={{ shrink: true }}
            variant="standard"
          />
        </Grid>

        {/* Instruction Type */}
        <Grid item xs={2.5}>
          <FormControl placeholder="Select Instruction Type" fullWidth>
            <InputLabel
              className="normal_normal_600_12_Manropee"
              style={{ lineHeight: "2.0375", marginLeft: "-12px" }}
              shrink={true}
            >
              Instruction Type
            </InputLabel>
            <Select
              disabled
              className="formControlField"
              value={planData?.instructionTypeId?.id || null}
              label="Instruction Type"
              variant="standard"
            >
              {instructionTypes?.length > 0 &&
                instructionTypes.map((d) => (
                  <MenuItem key={d?.id} value={d?.id}>
                    {d?.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Plan Description */}
        <Grid item xs={5}>
          <TextField
            required
            className="inputTextField normal_normal_600_12_Manropee"
            fullWidth
            label="Plan Description"
            placeholder="Plan Description"
            multiline
            maxRows={4}
            error={isError && !planData?.description}
            helperText={
              isError && !planData?.description && VALIDATION_MSG.REQUIRED
            }
            value={planData?.description}
            onChange={(e) =>
              handleChangeFormFields(e?.target?.value, "description")
            }
            InputLabelProps={{ shrink: true }}
            variant="standard"
          />
        </Grid>

        {/* Standared Fees for single */}
        <Grid item xs={2.5} display={"inline-flex"}>
          <TextField
            required
            className="inputTextField normal_normal_600_12_Manropee"
            label="Standard Fees"
            placeholder="Single"
            fullWidth
            size="small"
            error={
              (isError && !planData?.singleStandardFees) ||
              (isError && !NumberValidation(planData?.singleStandardFees))
            }
            helperText={
              (isError &&
                !planData?.singleStandardFees &&
                VALIDATION_MSG.REQUIRED) ||
              (isError &&
                !NumberValidation(planData?.singleStandardFees) &&
                VALIDATION_MSG.NUM_ONLY)
            }
            value={planData?.singleStandardFees}
            onChange={(e) =>
              handleChangeFormFields(e?.target?.value, "singleStandardFees")
            }
            InputLabelProps={{ shrink: true }}
            variant="standard"
          />

          {/* Standared Fees for joint */}
          <TextField
            style={{ marginTop: 16, marginLeft: 8 }}
            required
            className="inputTextField normal_normal_600_12_Manropee"
            placeholder="Joint"
            fullWidth
            size="small"
            error={
              (isError && !planData?.jointStandardFees) ||
              (isError && !NumberValidation(planData?.jointStandardFees))
            }
            helperText={
              (isError &&
                !planData?.jointStandardFees &&
                VALIDATION_MSG.REQUIRED) ||
              (isError &&
                !NumberValidation(planData?.jointStandardFees) &&
                VALIDATION_MSG.NUM_ONLY)
            }
            value={planData?.jointStandardFees}
            onChange={(e) =>
              handleChangeFormFields(e?.target?.value, "jointStandardFees")
            }
            InputLabelProps={{ shrink: true }}
            variant="standard"
          />
        </Grid>

        {/* Per VAT */}
        <Grid item xs={2.5}>
          <TextField
            required
            className="inputTextField normal_normal_600_12_Manropee"
            label="VAT In %"
            placeholder="VAT In %"
            fullWidth
            size="small"
            error={
              (isError && !planData?.perVat) ||
              (isError && !NumberValidation(planData?.perVat))
            }
            helperText={
              (isError && !planData?.perVat && VALIDATION_MSG.REQUIRED) ||
              (isError &&
                !NumberValidation(planData?.perVat) &&
                VALIDATION_MSG.NUM_ONLY)
            }
            value={planData?.perVat}
            onChange={(e) => handleChangeFormFields(e?.target?.value, "perVat")}
            InputLabelProps={{ shrink: true }}
            variant="standard"
          />
        </Grid>

        {/* Cost Of Probate */}
        <Grid item xs={2}>
          <TextField
            className="inputTextField normal_normal_600_12_Manropee"
            label="Cost of Probate"
            placeholder="Cost of Probate"
            fullWidth
            size="small"
            error={
              isError &&
              planData?.costOfProbate &&
              !NumberValidation(planData?.costOfProbate)
            }
            helperText={
              isError &&
              planData?.costOfProbate &&
              !NumberValidation(planData?.costOfProbate)
                ? VALIDATION_MSG.NUM_ONLY
                : ""
            }
            value={planData?.costOfProbate}
            onChange={(e) =>
              handleChangeFormFields(e?.target?.value, "costOfProbate")
            }
            InputLabelProps={{ shrink: true }}
            variant="standard"
          />
        </Grid>

        <>
          {/* Loss of capcity single */}
          <Grid item xs={3} display={"inline-flex"}>
            <TextField
              disabled={showField}
              className="inputTextField disabledLabelColor normal_normal_600_12_Manropee"
              label="Loss of Capacity"
              placeholder="Single"
              fullWidth
              size="small"
              error={
                isError &&
                planData?.lossOfCapacity?.forSingle &&
                !NumberValidation(planData?.lossOfCapacity?.forSingle)
              }
              helperText={
                isError &&
                planData?.lossOfCapacity?.forSingle &&
                !NumberValidation(planData?.lossOfCapacity?.forSingle)
                  ? VALIDATION_MSG.NUM_ONLY
                  : ""
              }
              value={planData?.lossOfCapacity?.forSingle}
              onChange={(e) =>
                handleChangeFormFields(e?.target?.value, "forSingle")
              }
              InputLabelProps={{ shrink: true }}
              variant="standard"
            />

            {/* Loss of capacity joint */}
            <TextField
              style={{ marginTop: 16, marginLeft: 8 }}
              disabled={showField}
              className="inputTextField disabledLabelColor normal_normal_600_12_Manropee"
              placeholder="Joint"
              fullWidth
              size="small"
              error={
                isError &&
                planData?.lossOfCapacity?.forJoint &&
                !NumberValidation(planData?.lossOfCapacity?.forJoint)
              }
              helperText={
                isError &&
                planData?.lossOfCapacity?.forJoint &&
                !NumberValidation(planData?.lossOfCapacity?.forJoint)
                  ? VALIDATION_MSG.NUM_ONLY
                  : ""
              }
              value={planData?.lossOfCapacity?.forJoint}
              onChange={(e) =>
                handleChangeFormFields(e?.target?.value, "forJoint")
              }
              InputLabelProps={{ shrink: true }}
              variant="standard"
            />
          </Grid>
        </>

        {/* Source Type & Pricing section */}
        <Grid item xs={3.5} mt={1}>
          <Typography className="normal_normal_bold_18_Manrope dark_grey">
            Campaign Pricing
          </Typography>
          <TextField
            mt={1}
            fullWidth
            className="inputTextField normal_normal_600_12_Manropee"
            placeholder="search..."
            variant="standard"
            onChange={(e) => handleChangeSearch(e?.target?.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Grid container spacing={3} mt={0}>
            <Grid
              item
              xs={6}
              sx={{ display: "flex", justifyContent: "flex-start" }}
              mb={2}
            >
              <Typography
                variant="h6"
                className="normal_normal_bold_18_Manrope dark_grey"
              >
                Single
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              mb={2}
            >
              <Typography
                variant="h6"
                sx={{ marginRight: "2rem" }}
                className="normal_normal_bold_18_Manrope dark_grey"
              >
                Joint
              </Typography>
            </Grid>

            <Grid
              container
              spacing={3}
              className="instruction_dashboard_scrollbar"
              style={{
                height: "280px",
                overflow: "auto",
                zIndex: 1,
              }}
            >
              {filterCampignType?.length > 0 &&
                filterCampignType?.map((d, i) => (
                  <>
                    <Grid item xs={5.5}>
                      <TextField
                        value={d?.singleSourceTypeStandardFees || ""}
                        className="inputTextField normal_normal_600_12_Manropee"
                        label={`${d?.sourceType} price`}
                        placeholder={`Enter ${d?.sourceType} price`}
                        fullWidth
                        size="small"
                        variant="standard"
                        error={
                          isError &&
                          d?.singleSourceTypeStandardFees &&
                          !NumberValidation(d?.singleSourceTypeStandardFees)
                        }
                        helperText={
                          isError &&
                          d?.singleSourceTypeStandardFees &&
                          !NumberValidation(d?.singleSourceTypeStandardFees) &&
                          VALIDATION_MSG.NUM_ONLY
                        }
                        onChange={(e) =>
                          handleChangePlanPricing(
                            e?.target?.value,
                            d.sourceTypeLookupId,
                            "singleSourceTypeStandardFees"
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={5.5}>
                      <TextField
                        className="inputTextField normal_normal_600_12_Manropee"
                        label={`${d?.sourceType} price`}
                        placeholder={`Enter ${d?.sourceType} price`}
                        fullWidth
                        size="small"
                        variant="standard"
                        value={d?.jointSourceTypeStandardFees || ""}
                        error={
                          isError &&
                          d?.jointSourceTypeStandardFees &&
                          !NumberValidation(d?.jointSourceTypeStandardFees)
                        }
                        helperText={
                          isError &&
                          d?.jointSourceTypeStandardFees &&
                          !NumberValidation(d?.jointSourceTypeStandardFees) &&
                          VALIDATION_MSG.NUM_ONLY
                        }
                        onChange={(e) =>
                          handleChangePlanPricing(
                            e?.target?.value,
                            d.sourceTypeLookupId,
                            "jointSourceTypeStandardFees"
                          )
                        }
                      />
                    </Grid>
                  </>
                ))}
            </Grid>
          </Grid>
        </Grid>

        {/* Products list */}
        <Grid item xs={3} mt={1}>
          <Typography
            mb={2}
            className="normal_normal_bold_18_Manrope dark_grey"
          >
            Products
          </Typography>
          <Autocomplete
            readOnly={true}
            multiple
            value={planData?.PlanProduct}
            id="tags-outlined"
            options={productList || []}
            getOptionLabel={(option) => option?.name}
            onChange={(event, value) => handleChangeProduct(value)}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                className="normal_normal_600_12_Manropee"
                size="small"
                label="Products"
                placeholder="Select Products"
              />
            )}
          />
        </Grid>

        {/* Wish List Section */}
        <Grid item xs={5.5} mt={1}>
          <Grid>
            <Typography className="normal_normal_bold_18_Manrope dark_grey">
              Wish List
            </Typography>
          </Grid>
          <Grid
            mt={0}
            container
            spacing={2}
            className="instruction_dashboard_scrollbar"
            style={{ height: "350px", overflow: "auto" }}
          >
            {whatIfsList?.length > 0 &&
              whatIfsList?.map((d) => (
                <>
                  <Grid item xs={8}>
                    <Typography className="normal_normal_18_Manrope dark_grey">
                      {d?.question}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="false"
                        value={d?.ans || false}
                        name="radio-buttons-group"
                        onChange={(e) =>
                          handleChangeWhatIfQues(
                            e?.target?.value,
                            d?.whatIfId,
                            "ans"
                          )
                        }
                      >
                        <FormControlLabel
                          value="true"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                "&, &.Mui-checked": {
                                  color: "#00CE3F",
                                },
                                marginTop: -1,
                              }}
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              value="false"
                              size="small"
                              sx={{
                                "&, &.Mui-checked": {
                                  color: "#00CE3F",
                                },
                                marginTop: -1,
                              }}
                            />
                          }
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </>
              ))}
          </Grid>
        </Grid>
      </Grid>

      {/* Plan activate / deactivate / save button */}

      <Grid container spacing={2} className="Save_Button_Container">
        <Grid
          item
          xs={6}
          sx={{ display: "flex", justifyContent: "space-evenly" }}
          m={1}
        >
          <Grid>
            <Button
              disabled={!planData?.isActive}
              variant="outlined"
              className="deactivate_button"
              onClick={handleDisablePlan}
            >
              Deactivate
            </Button>
          </Grid>
        </Grid>

        <Grid
          item
          xs={5}
          sx={{ display: "flex", justifyContent: "flex-end" }}
          m={1}
        >
          <Button
            disabled={!planData?.isActive}
            variant="contained"
            className="save_button"
            onClick={handleConfirmUpdatePlan}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default UpdatePlan;
