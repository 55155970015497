import { Button, Grid, TextField, Typography } from "@mui/material";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import googleIcon from "../../assets/images/Google__G__Logo.png";
import squiggleLogo from "../../assets/images/Squiggle_Logo_White.png";
import backgroundImage from "../../assets/images/login_background_image.png";
import Loader from "../../components/Loader";
import { showSnackbar } from "../../slice/snackbarSlice";
import { getLoginUser, userLogin } from "../../thunks/auth";
import {
  API_ROUTES_PATHS,
  API_URL,
  VALIDATION_MSG,
} from "../../utils/constant";

const Login = () => {
  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const jwtToken = urlParams.get("jwt-token");

  // local state
  const [userData, setUserData] = useState({ email: "", password: "" });
  const [isLoader, setLoader] = useState(false);
  const [isError, setIsError] = useState(false);

  // Method to handle form data (email, password)
  const handleChangeUserData = (e, key) => {
    setUserData({ ...userData, [key]: e });
  };

  // Method to login by passing email and password
  const handleLogin = async () => {
    if (userData?.email === "" || userData?.password === "") {
      setIsError(true);
    } else {
      // proceed to login
      setIsError(false);
      setLoader(true);
      try {
        const response = await dispatch(userLogin(userData)).unwrap();
        // Get login user details by JWT token
        if (response?.access_token) {
          getLoginUserDetails();
        }
      } catch (error) {
        setLoader(false);
        dispatch(
          showSnackbar({
            message: `${error?.message}`,
            severity: "error",
          })
        );
      }
    }
  };
  // Method for login by pressing Enter Key
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  // Method to get login user details once JWT token is valid
  const getLoginUserDetails = async () => {
    try {
      const response = await dispatch(getLoginUser()).unwrap();
      if (response) {
        setLoader(false);
        navigate("/app");
      }
    } catch (error) {
      setLoader(false);
    }
  };

  // Method for login with google
  const handleGoogle = () => {
    window.open(
      `${API_URL}${API_ROUTES_PATHS.GOOGLE_LOGIN}`,
      "_self",
      "height=200,width=150"
    );
  };

  useEffect(() => {
    if (jwtToken) {
      setLoader(true);
      Cookies.set("jwt-token", jwtToken);
      navigate("/app");
    } else {
      Cookies.set("jwt-token", "");
      setLoader(false);
      navigate("/login");
    }
  }, [jwtToken]);

  return (
    <div style={{ margin: "0px" }}>
      {/* Loader Component */}
      {isLoader && <Loader />}
      <Container fluid className="h-100">
        <Row>
          <Col xs={7} className="left-col">
            {/* Content for the left column */}
            <div className="backgroundImageWrapper">
              <div
                className="backgroundImageContainer"
                style={{
                  backgroundImage: `url(${backgroundImage})`,
                }}
              >
                <img
                  style={{ height: "100px" }}
                  src={squiggleLogo}
                  alt="squiggle"
                />
                <Grid
                  item
                  xs={6}
                  style={{
                    color: "#3D4740",
                    marginTop: "30px",
                  }}
                >
                  Version:- {process.env.REACT_APP_VERSION}
                </Grid>
              </div>
            </div>
          </Col>
          <Col xs={5} className="right-col">
            {/* Content for the right column */}
            <div className="content">
              <Grid container spacing={3} style={{ marginTop: 0 }}>
                {/* Email Container */}
                <Grid item xs={12} sx={{ marginBottom: "1rem" }}>
                  <TextField
                    fullWidth
                    className="inputTextField label"
                    placeholder="Email"
                    label="Email ID"
                    variant="standard"
                    size="small"
                    error={isError && !userData?.email}
                    helperText={
                      isError && !userData?.email && VALIDATION_MSG.REQUIRED
                    }
                    onChange={(e) =>
                      handleChangeUserData(e?.target?.value, "email")
                    }
                    value={userData.email}
                    onKeyDown={handleKeyPress}
                    sx={{
                      "&:hover": {
                        label: {
                          color: "#3D4740",
                        },
                      },
                    }}
                  />
                </Grid>
                {/* Password Container */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    className="inputTextField"
                    placeholder="Password"
                    label="Password"
                    variant="standard"
                    size="small"
                    type="password"
                    error={isError && !userData?.password}
                    helperText={
                      isError && !userData?.password && VALIDATION_MSG.REQUIRED
                    }
                    onChange={(e) =>
                      handleChangeUserData(e?.target?.value, "password")
                    }
                    value={userData.password}
                    onKeyDown={handleKeyPress}
                    sx={{
                      "&:hover": {
                        label: {
                          color: "#3D4740",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    className="loginButton normal_normal_semibold_14_Manrope"
                    onClick={handleLogin}
                  >
                    Login
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <div>
                    <Typography
                      style={{ width: "100%" }}
                      className="forgotPasswordTitle"
                      onClick={() => navigate("/forgot-password")}
                    >
                      Forgot Password ?
                    </Typography>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    position: "relative",
                    top: 80,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    item
                    xs={10}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      width: "80%",
                    }}
                    className="googleIconTitle"
                  >
                    <img
                      style={{
                        height: "30px",
                        width: "29px",
                        marginRight: "10px",
                      }}
                      src={googleIcon}
                    />
                    <Typography
                      onClick={handleGoogle}
                      className="normal_normal_600_16_Manrope dark_grey"
                    >
                      Login with Google
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
