import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";
import { showSnackbar } from "../../../slice/snackbarSlice";
import { createClient } from "../../../thunks/client";
import { getMyUsers } from "../../../thunks/user";
import {
  NOTIFICATION_MESSAGE,
  USER_ROLE,
  VALIDATION_MSG,
} from "../../../utils/constant";
import { emailValidation } from "../../../utils/util";
import { formValidation } from "./formValidation";

const AddClient = ({ open, handleClose, data, handlesucess }) => {
  ///hooks
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.auth?.currentUser);
  const lookups = useSelector((state) => state?.lookupData);
  let { "Source Type": sourceType } = lookups || {};
  // states
  const [isError, setIsError] = useState(false);
  const [isLoader, setLoader] = useState(false);
  const [clientData, setClientData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    mobileNo: "",
    email: "",
    postalCode: "",
    sourceSubType: "",
    sourceTypeLookupId: null,
    ownerOfClientByEmail:
      userData?.role !== USER_ROLE?.CONSULTANT ? "" : userData?.email || "",
    ownerOfClientById:
      userData?.role !== USER_ROLE?.CONSULTANT ? "" : userData?.id || "",
    ownerOfClientByRole:
      userData?.role !== USER_ROLE?.CONSULTANT ? "" : userData?.role || "",
  });
  const [consultants, setConsultants] = useState([]);

  // handle change formData
  const handleChangeFormData = (value, key) => {
    setClientData({ ...clientData, [key]: value });
  };

  // handle submit client data
  const handleSubmitClientData = async () => {
    const isValidForm = formValidation(clientData);
    if (!isValidForm || !clientData?.ownerOfClientById) {
      setIsError(true);
    } else {
      setIsError(false);
      try {
        setLoader(true);
        const payload = {
          firstName: clientData?.firstName?.trim(),
          middleName: clientData?.middleName?.trim(),
          lastName: clientData?.lastName?.trim(),
          mobileNo: clientData?.mobileNo,
          email: clientData?.email,
          postalCode: clientData?.postalCode,
          sourceSubType: clientData?.sourceSubType?.trim(),
          sourceTypeLookupId: clientData?.sourceTypeLookupId,
          ownerOfClientByEmail: clientData?.ownerOfClientByEmail,
          ownerOfClientById: clientData?.ownerOfClientById,
          ownerOfClientByRole: clientData?.ownerOfClientByRole,
        };
        const response = await dispatch(createClient(payload)).unwrap();
        if (response?.id) {
          setLoader(false);
          dispatch(
            showSnackbar({
              show: true,
              message: NOTIFICATION_MESSAGE?.SUCCESS_CLIENT_CREATE,
              severity: "success",
            })
          );
          handleClose();
          handlesucess();
        }
      } catch (error) {
        setLoader(false);
        dispatch(
          showSnackbar({
            show: true,
            message: `${error?.message}`,
            severity: "error",
          })
        );
      }
    }
  };

  // This method is to fetch login user consultants.
  const getMyUsersData = async () => {
    try {
      const response = await dispatch(getMyUsers()).unwrap();
      if (response?.length > 0) {
        setConsultants(response);
      } else {
        setConsultants([]);
      }
    } catch (error) {}
  };

  // Method to get consultants.
  const handleChangeConsultant = (value) => {
    if (value) {
      setClientData({
        ...clientData,
        ownerOfClientByEmail: value?.email,
        ownerOfClientById: value?.id,
        ownerOfClientByRole: value?.role,
      });
    }
  };

  // use effect
  useEffect(() => {
    getMyUsersData();
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
      >
        {/* Loader component */}
        {isLoader && <Loader />}

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers={true}>
          <>
            {/* Create Client Form */}
            <Grid container spacing={3} p={2} mb={2}>
              <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                <Typography className="serif_display_regular_26 green" mb={2}>
                  Create a New Client
                </Typography>
              </Grid>

              {/* First Name */}
              <Grid item xs={4}>
                <TextField
                  value={clientData?.firstName}
                  error={isError && !clientData?.firstName}
                  helperText={
                    isError && !clientData?.firstName && VALIDATION_MSG.REQUIRED
                  }
                  required
                  fullWidth
                  className="inputTextField border_color normal_normal_600_12_Manropee"
                  label="First Name"
                  placeholder="First Name"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) =>
                    handleChangeFormData(e?.target?.value, "firstName")
                  }
                />
              </Grid>

              {/* Middle Name */}
              <Grid item xs={4}>
                <TextField
                  value={clientData?.middleName}
                  fullWidth
                  className="inputTextField border_color normal_normal_600_12_Manropee"
                  label="Middle Name"
                  placeholder="Middle Name"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) =>
                    handleChangeFormData(e?.target?.value, "middleName")
                  }
                />
              </Grid>

              {/* Last Name */}
              <Grid item xs={4}>
                <TextField
                  value={clientData?.lastName}
                  error={isError && !clientData?.lastName}
                  helperText={
                    isError && !clientData?.lastName && VALIDATION_MSG.REQUIRED
                  }
                  fullWidth
                  required
                  className="inputTextField border_color normal_normal_600_12_Manropee"
                  label="Last Name"
                  placeholder="Last Name"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) =>
                    handleChangeFormData(e?.target?.value, "lastName")
                  }
                />
              </Grid>

              {/* Mobile Number */}
              <Grid item xs={8}>
                <label
                  style={{ position: "relative", bottom: 5 }}
                  className="normal_normal_600_10_Manropee"
                >
                  Mobile Number
                </label>
                <PhoneInput
                  country="UK"
                  defaultCountry="GB"
                  placeholder="Mobile Number"
                  className="inputTextField border_color normal_normal_600_12_Manropee"
                  value={clientData?.mobileNo}
                  onChange={(e) => handleChangeFormData(e, "mobileNo")}
                />
                {isError && !clientData?.mobileNo && (
                  <FormHelperText className="red">
                    {VALIDATION_MSG.REQUIRED}
                  </FormHelperText>
                )}
              </Grid>

              {/* Email */}
              <Grid item xs={8}>
                <TextField
                  className="inputTextField border_color normal_normal_600_12_Manropee"
                  value={clientData?.email}
                  fullWidth
                  label="Email"
                  placeholder="Email"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) =>
                    handleChangeFormData(e?.target?.value, "email")
                  }
                  error={
                    isError &&
                    clientData?.email &&
                    !emailValidation(clientData?.email)
                  }
                  helperText={
                    isError &&
                    clientData?.email &&
                    !emailValidation(clientData?.email) &&
                    VALIDATION_MSG.VALID_EMAIL_ID
                  }
                />
              </Grid>

              {/* Post Code */}
              <Grid item xs={6} mb={2}>
                <TextField
                  className="inputTextField border_color normal_normal_600_12_Manropee"
                  label="Post Code"
                  placeholder="Post Code"
                  fullWidth
                  value={clientData?.postalCode}
                  onChange={(e) =>
                    handleChangeFormData(e?.target?.value, "postalCode")
                  }
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                  // error={isError && !clientData?.postalCode}
                  // helperText={
                  //   isError &&
                  //   !clientData?.postalCode &&
                  //   VALIDATION_MSG.REQUIRED
                  // }
                />
              </Grid>

              {/* Select Consultant dropdown */}
              {userData?.role !== USER_ROLE?.CONSULTANT && (
                <Grid item xs={6}>
                  <Autocomplete
                    options={consultants || []}
                    getOptionLabel={(option) =>
                      `${option?.firstName} ${option?.lastName}`
                    }
                    onChange={(event, value) => handleChangeConsultant(value)}
                    filterSelectedOptions
                    renderInput={(params, InputLabelProps) => (
                      <TextField
                        variant="standard"
                        {...params}
                        required
                        InputLabelProps={{
                          shrink: true,
                          ...InputLabelProps,
                        }}
                        className="normal_normal_medium_14_Manropee inputTextField border_color"
                        size="small"
                        label="Consultant"
                        placeholder="Select consultant"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            height: 38,
                          },
                        }}
                      />
                    )}
                  />
                  {isError && !clientData?.ownerOfClientById && (
                    <FormHelperText className="red">
                      {VALIDATION_MSG.REQUIRED}
                    </FormHelperText>
                  )}
                </Grid>
              )}
              {/* Source Type */}
              <Grid item xs={5}>
                <FormControl
                  fullWidth
                  className="border_color normal_normal_600_12_Manropee"
                  required
                  error={isError && !clientData?.sourceTypeLookupId}
                >
                  <InputLabel
                    shrink={true}
                    style={{
                      lineHeight: "2.0375",
                      marginLeft: "-12px",
                      color: "#01ce3f",
                    }}
                  >
                    Campaign Type
                  </InputLabel>
                  <Select
                    className="formControlField"
                    value={clientData?.sourceTypeLookupId}
                    label="Campaign Type"
                    placeholder="Select Campaign type"
                    onChange={(e) =>
                      handleChangeFormData(
                        e?.target?.value,
                        "sourceTypeLookupId"
                      )
                    }
                    variant="standard"
                  >
                    {sourceType?.length > 0 &&
                      sourceType?.map((d) => (
                        <MenuItem
                          className="normal__normal__600__12__Manrope"
                          value={d?.id}
                        >
                          {d?.name}
                        </MenuItem>
                      ))}
                  </Select>
                  {isError && !clientData?.sourceTypeLookupId && (
                    <FormHelperText className="red">
                      {VALIDATION_MSG.REQUIRED}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              {/* Source Sub Type */}
              <Grid item xs={7}>
                <TextField
                  value={clientData?.sourceSubType}
                  error={isError && !clientData?.sourceSubType}
                  placeholder="Campaign Sub Type"
                  helperText={
                    isError &&
                    !clientData?.sourceSubType &&
                    VALIDATION_MSG.REQUIRED
                  }
                  fullWidth
                  required
                  className="inputTextField border_color normal_normal_600_12_Manropee"
                  label="Campaign Sub Type"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) =>
                    handleChangeFormData(e?.target?.value, "sourceSubType")
                  }
                />
              </Grid>

              {/* Create Button */}
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "space-between" }}
                mt={3}
              >
                <Button className="cancel_button" onClick={() => handleClose()}>
                  Cancel
                </Button>

                <Button
                  onClick={handleSubmitClientData}
                  className="normal_normal_bold_14_Manropee save_button"
                  variant="contained"
                >
                  Create
                </Button>
              </Grid>
            </Grid>
          </>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddClient;
