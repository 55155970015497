import { Grid, Typography } from "@mui/material";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  AddButton,
  ContainedGreenButton,
  OutlinedGreenButton,
  RemoveButton,
} from "../../../../../components/buttons";
import { RadioInput, TextInput } from "../../../../../components/inputs";
import { VALIDATION_MSG } from "../../../../../utils/constant";
import { getUniqueID } from "../../../../../utils/util";
import { beneficiariesObject } from "./willQuestionnaireFormConstants";

const ResidueOfEstate = () => {
  const isJoint = useSelector((state) => state?.instruction?.isJoint);
  const arr = useSelector((state) => state?.instruction?.arr);
  const { getValues } = useFormContext();
  return (
    <>
      <Grid width={"100%"} display={"flex"} flexDirection={"column"}>
        <Grid
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={isJoint ? "space-between" : "center"}
          borderTop={`1px solid #DCDCE1`}
          borderBottom={`1px solid #DCDCE1`}
        >
          {arr?.map((item, index) => {
            return (
              <Grid
                key={"AA" + index}
                width={isJoint ? "50%" : "62%"}
                borderRight={
                  index === 0 && isJoint ? `1px solid #DCDCE1` : null
                }
                padding={2}
                display={"flex"}
                flexDirection={"column"}
                gap={2}
              >
                {isJoint ? (
                  <RadioInput
                    label="Pass to Spouse/Partner?"
                    name={`InstructionWillQuestionnaire.${index}.residueOfEstate.passToSpouseOrPartner`}
                    options={[
                      { label: "Yes", value: true },
                      { label: "No", value: false },
                    ]}
                    defaultValue={false}
                  />
                ) : null}

                <RadioInput
                  label="And / or then to pass directly to Children / Grandchildren?"
                  name={`InstructionWillQuestionnaire.${index}.residueOfEstate.passToChildrenOrGrandChildren`}
                  options={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                  defaultValue={false}
                />
              </Grid>
            );
          })}
        </Grid>
        <Grid width={"100%"}>
          <BeneficiaryItem arr={arr} isJoint={isJoint} />
          <ReserveBeneficiaryItem arr={arr} isJoint={isJoint} />
        </Grid>
      </Grid>
    </>
  );
};

export default ResidueOfEstate;

const BeneficiaryItem = ({ arr, isJoint }) => {
  const { getValues, setValue } = useFormContext();
  const client1 = useFieldArray({
    name: `InstructionWillQuestionnaire.[0].residueOfEstate.beneficiaries`,
  });

  const { append, remove, fields } = client1;

  const client2 = useFieldArray({
    name: `InstructionWillQuestionnaire.[1].residueOfEstate.beneficiaries`,
  });

  const {
    append: secondaryAppend,
    remove: secondaryRemove,
    fields: secondaryFields,
  } = client2;

  const handleAdd = (clientNumber) => {
    let newObj = { ...beneficiariesObject, id: getUniqueID() };
    clientNumber === 0 ? append(newObj) : secondaryAppend(newObj);
  };

  const handleRemove = (clientNumber, ind, pathName1, pathName2, card) => {
    if (clientNumber === 1) {
      let isMirrored = getValues(`${pathName1}`)?.mirrored || false;
      if (isMirrored) {
        secondaryRemove(ind);
      }
      remove(ind);
    } else {
      let isMirrored = getValues(`${pathName2}`)?.mirrored || false;
      if (clientNumber === 2 && isMirrored) {
        let findIndex = fields.findIndex((f) => f.id === card.mirroredBy);

        setValue(
          `InstructionWillQuestionnaire.[0].residueOfEstate.beneficiaries.[${findIndex}].mirrored`,
          false
        );
      }
      secondaryRemove(ind);
    }
  };

  return (
    <>
      <Grid width={"100%"}>
        <Grid
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          borderBottom={`1px solid #DCDCE1`}
          padding={2}
        >
          <Grid width={"100%"}>
            <Typography>Beneficiaries</Typography>
          </Grid>
        </Grid>
        <Grid
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={isJoint ? "space-between" : "center"}
          borderTop={`1px solid #DCDCE1`}
          borderBottom={`1px solid #DCDCE1`}
        >
          {arr?.map((item, index) => {
            return (
              <Grid
                key={"BB" + index}
                width={isJoint ? "50%" : "62%"}
                borderRight={
                  index === 0 && isJoint ? `1px solid #DCDCE1` : null
                }
                padding={2}
                display={"flex"}
                flexDirection={"column"}
                gap={2}
              >
                <Grid width={"100%"} display={"flex"} flexDirection={"row"}>
                  <Grid
                    width={"100%"}
                    paddingY={4}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={3}
                  >
                    {(index === 0 ? fields : secondaryFields).map(
                      (card, ind) => {
                        return (
                          <div key={card?.id}>
                            <BeneficiaryForm
                              isInstructionJoint={isJoint}
                              index={index}
                              cardNo={ind}
                              handleRemove={() =>
                                handleRemove(
                                  item,
                                  ind,
                                  `InstructionWillQuestionnaire.[0].residueOfEstate.beneficiaries.[${ind}]`,
                                  `InstructionWillQuestionnaire.[1].residueOfEstate.beneficiaries.[${ind}]`,
                                  card
                                )
                              }
                              pathName={`InstructionWillQuestionnaire.[${index}].residueOfEstate.beneficiaries.[${ind}]`}
                              cardData={card}
                              cardObject={client2}
                              removeCard={secondaryRemove}
                            />
                          </div>
                        );
                      }
                    )}
                    <Grid textAlign={"center"}>
                      <AddButton onClick={() => handleAdd(index)} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
};

const BeneficiaryForm = ({
  index,
  cardNo,
  handleRemove,
  pathName,
  cardObject,
  cardData,
  removeCard,
  isInstructionJoint,
}) => {
  const { getValues, setValue, watch } = useFormContext();
  let { beneficiary_classification } = useSelector(
    (state) => state?.lookupData
  );
  const isMirrored = watch(`${pathName}.mirrored`);

  const handleMirror = () => {
    if (!isMirrored) {
      let itemToCopy = getValues(`${pathName}`);
      // cardObject.append( {
      cardObject.insert(cardNo, {
        ...itemToCopy,
        mirrored: true,
        mirroredBy: cardData?.id,
      });
      setValue(`${pathName}.mirrored`, true);
    } else {
      let index = cardObject?.fields.findIndex(
        (f) => f.mirroredBy === cardData.id
      );
      setValue(`${pathName}.mirrored`, false);
      index !== -1 && removeCard(index);
    }
  };

  return (
    <Grid
      boxShadow={"1px 2px 6px #00000029"}
      borderRadius={"16px"}
      borderRight={index === 0 ? `1px solid #DCDCE1` : null}
      padding={2}
      paddingY={3}
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      position={"relative"}
    >
      {index === 0 && isInstructionJoint ? (
        <div className="card-mirror-btn">
          {isMirrored ? (
            <ContainedGreenButton label={"Mirror"} onClick={handleMirror} />
          ) : (
            <OutlinedGreenButton label={"Mirror"} onClick={handleMirror} />
          )}
        </div>
      ) : null}

      <TextInput
        label={`Beneficiary ${cardNo + 1}`}
        name={`${pathName}.beneficiary`}
        placeholder="Enter name"
      />
      <Grid item xs={12}>
        <RadioInput
          label={`Beneficiaries Type`}
          name={`${pathName}.classification`}
          options={beneficiary_classification || []}
          rules={{
            required: { value: true, message: VALIDATION_MSG.REQUIRED },
          }}
        />
      </Grid>

      <Grid
        display={"flex"}
        width={"100%"}
        justifyContent={"space-between"}
        gap={2}
      >
        <Grid width={"32%"}>
          <RadioInput
            label="Per Stirpes"
            name={`${pathName}.perStripes`}
            options={[
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
          />
        </Grid>
        <Grid width={"32%"}>
          <TextInput
            label={`Age of Inheritance`}
            name={`${pathName}.ageOfInheritance`}
            placeholder="Enter value"
          />
        </Grid>
        <Grid width={"32%"}>
          <TextInput
            label={`Percentage Share`}
            name={`${pathName}.percentageShare`}
            placeholder="Enter value"
          />
        </Grid>
      </Grid>
      <div style={{ textAlign: "right" }}>
        <RemoveButton onClick={handleRemove} />
      </div>
    </Grid>
  );
};

const ReserveBeneficiaryItem = ({ arr, isJoint }) => {
  const { getValues, setValue } = useFormContext();

  const client1 = useFieldArray({
    name: `InstructionWillQuestionnaire.[0].residueOfEstate.reserveBeneficiaries`,
  });

  const { append, remove, fields } = client1;

  const client2 = useFieldArray({
    name: `InstructionWillQuestionnaire.[1].residueOfEstate.reserveBeneficiaries`,
  });

  const {
    append: secondaryAppend,
    remove: secondaryRemove,
    fields: secondaryFields,
  } = client2;

  const handleAdd = (clientNumber) => {
    clientNumber === 0
      ? append(beneficiariesObject)
      : secondaryAppend(beneficiariesObject);
  };

  const handleRemove = (clientNumber, ind, pathName1, pathName2) => {
    if (clientNumber === 1) {
      let isMirrored = getValues(`${pathName1}`)?.mirrored || false;
      remove(ind);
      if (isMirrored) {
        secondaryRemove(ind);
      }
    } else {
      let obj = getValues(`${pathName2}`);
      let isMirrored = getValues(`${pathName2}`)?.mirrored || false;
      if (clientNumber === 2 && isMirrored) {
        setValue(`${pathName1}.mirrored`, false);
        client1.update(ind, { ...obj, mirrored: false });
      }
      secondaryRemove(ind);
    }
  };
  return (
    <>
      <Grid width={"100%"}>
        <Grid
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          borderBottom={`1px solid #DCDCE1`}
          padding={2}
        >
          <Grid width={"100%"}>
            <Typography>Reserve / Backstop Beneficiaries</Typography>
          </Grid>
        </Grid>

        <Grid
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={isJoint ? "space-between" : "center"}
          borderTop={`1px solid #DCDCE1`}
          borderBottom={`1px solid #DCDCE1`}
        >
          {arr?.map((item, index) => {
            return (
              <Grid
                key={"DD" + index}
                width={isJoint ? "50%" : "62%"}
                borderRight={
                  index === 0 && isJoint ? `1px solid #DCDCE1` : null
                }
                padding={2}
                display={"flex"}
                flexDirection={"column"}
                gap={2}
              >
                <Grid width={"100%"} display={"flex"} flexDirection={"row"}>
                  <Grid
                    width={"100%"}
                    paddingY={4}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={3}
                  >
                    {(index === 0 ? fields : secondaryFields).map(
                      (card, ind) => {
                        return (
                          <BeneficiaryForm
                            isJoint={isJoint}
                            index={index}
                            cardNo={ind}
                            key={card?.id}
                            handleRemove={() =>
                              handleRemove(
                                item,
                                ind,
                                `InstructionWillQuestionnaire.[0].residueOfEstate.reserveBeneficiaries.[${ind}]`,
                                `InstructionWillQuestionnaire.[1].residueOfEstate.reserveBeneficiaries.[${ind}]`
                              )
                            }
                            pathName={`InstructionWillQuestionnaire.[${index}].residueOfEstate.reserveBeneficiaries.[${ind}]`}
                            cardData={card}
                            cardObject={client2}
                            removeCard={secondaryRemove}
                          />
                        );
                      }
                    )}
                    <Grid textAlign={"center"}>
                      <AddButton onClick={() => handleAdd(index)} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
};
