import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CircleIcon from "@mui/icons-material/Circle";
import SearchIcon from "@mui/icons-material/Search";
import DataGridComponent from "../../../components/DataGridComponent";
import Loader from "../../../components/Loader";
import PaginationComponent from "../../../components/PaginationComponent";
import { showSnackbar } from "../../../slice/snackbarSlice";
import { transferEmail } from "../../../thunks/email_template";
import {
  getAllInstructions,
  getInstructionType,
} from "../../../thunks/instruction";
import { getLookups, getMilestone } from "../../../thunks/lookup";
import { getConsultant } from "../../../thunks/user";
import { NOTIFICATION_MESSAGE, USER_ROLE } from "../../../utils/constant";

const TransferEmailDialog = ({ open, handleClose, selectedEmail }) => {
  const instructionData = useSelector(
    (state) => state.instruction.instructionDashboardData
  );
  const dispatch = useDispatch();
  const milestone = useSelector((state) => state?.lookupData?.milestoneData);
  const instructionTypes = useSelector(
    (state) => state?.instruction?.instructionTypeData
  );
  const userData = useSelector((state) => state?.auth?.currentUser);

  const [timer, setTimer] = useState(null);
  const [isLoader, setLoader] = useState(false);
  const [instructionsData, setInstructionsData] = useState([]);
  const [filteredInstructionData, setFilteredInstructionData] = useState([]);
  const [totalCount, setTotalCount] = useState("");
  const [isTransfer, setIsTransfer] = useState(false);
  const [selectedInstruction, setSelectedInstruction] = useState(null);
  const [reason, setReason] = useState("");
  const [filterPayload, setFilterPayload] = useState({
    page: 1,
    limit: 10,
    search: "",
    type: "",
    milestones: "",
    statusId: null,
    consultantId: null,
    orderByOnCreatedAt: "desc",
  });

  const columns = [
    {
      field: "clients",
      headerName: "Clients",
      minWidth: 250,
      sortable: false,
      renderCell: (params) => {
        return (
          <ul
            style={{
              marginTop: "1rem",
              listStyleType: "none",
              marginLeft: "-1rem",
            }}
          >
            {params?.row?.clients?.map((client, index) => (
              <li
                role="button"
                key={index}
                // onClick={() =>
                //   navigate(`/app/client/update-client/${client?.id}`, {
                //     state: { clientData: client },
                //   })
                // }
              >
                {params?.row?.isMigrated && (
                  <CircleIcon
                    style={{ fontSize: "8px", marginRight: "0.2rem" }}
                    className="red"
                  />
                )}
                {client.fullName}
              </li>
            ))}
          </ul>
        );
      },
    },

    {
      field: "ownerOfInstruction",
      headerName: "Consultant",
      minWidth: 200,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="d-flex justify-content-center">
            <Typography className="normal_normal_16_Manrope dark_grey">{`${params?.row?.ownerOfInstruction?.firstName} ${params?.row?.ownerOfInstruction?.lastName}`}</Typography>
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      sortable: false,
      renderCell: (params) => (
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            size="small"
            fullWidth
            variant="outlined"
            className="meeting_log_btn normal_normal_semibold_14_Manrope green"
            style={{
              borderRadius: "24px",
              margin: 1,
              border: "1px solid #00ce3f",
            }}
            onClick={() => handleClickTransferEmail(params)}
          >
            Transfer
          </Button>
        </Grid>
      ),
    },
  ];

  // Functionality for Pagination
  const handleChangePage = (event, value) => {
    setFilterPayload({ ...filterPayload, page: value });
    const payload = {
      ...filterPayload,
      page: value,
    };
    getInstructionsData(payload);
  };

  // Functionality for  filter Instruction type
  const handleChangeInstructionType = (value, name) => {
    const selectedRole = value === "" ? "" : value;

    setFilterPayload({
      ...filterPayload,
      type: value,
      page: 1,
    });

    const payload = {
      ...filterPayload,
      type: selectedRole,
      page: 1,
    };

    getInstructionsData(payload);
  };

  // Functionality for filter milestone
  const handleChangeMilestone = (value, name) => {
    const selectedRole = value === "" ? "" : value;

    setFilterPayload({
      ...filterPayload,
      milestones: value,
      page: 1,
    });

    const payload = {
      ...filterPayload,
      milestones: selectedRole,
      page: 1,
    };
    getInstructionsData(payload);
  };
  const handleChangeFilter = (value, name) => {
    setFilterPayload({
      ...filterPayload,
      page: 1,
      [name]: value,
    });

    const payload = {
      ...filterPayload,
      page: 1,
      [name]: value,
    };
    getInstructionsData(payload);
  };

  // Functionality for filter name
  const handleChangeName = (value) => {
    setFilterPayload({ ...filterPayload, search: value, page: 1 });

    if (value?.length >= 3) {
      clearTimeout(timer);
      const newTimer = setTimeout(() => {
        const payload = {
          ...filterPayload,
          page: 1,
          search: value,
        };
        getInstructionsData(payload);
      }, 500);
      setTimer(newTimer);
    }
    if (value?.length === 0) {
      clearTimeout(timer);
      const newTimer = setTimeout(() => {
        const payload = {
          ...filterPayload,
          page: 1,
          search: value,
        };
        getInstructionsData(payload);
      }, 500);
      setTimer(newTimer);
    }
  };

  const getInstructionTypeData = async () => {
    try {
      const response = await dispatch(getInstructionType()).unwrap();
    } catch (error) {}
  };

  const instructionStatusList = useSelector(
    (state) => state?.lookupData?.["Instruction Status"]
  );

  const getInstructionStatus = async () => {
    try {
      if (!instructionStatusList?.length) {
        await dispatch(
          getLookups({ searchStrings: JSON.stringify(["Instruction Status"]) })
        );
      }
    } catch (error) {}
  };
  const getMilestoneType = async () => {
    try {
      const response = await dispatch(getMilestone()).unwrap();
    } catch (error) {}
  };
  let consultantList = useSelector((state) =>
    state?.user?.consultantsData?.map((obj) => ({
      ...obj,
      label: `${obj?.firstName || "NA"} ${obj?.lastName || "NA"}`,
    }))
  );

  const getInstructionsData = async (payload = filterPayload) => {
    try {
      setLoader(true);
      const responseData = await dispatch(getAllInstructions(payload)).unwrap();
      setInstructionsData(responseData?.findAllInstructions || []);
      setFilteredInstructionData(responseData?.findAllInstructions || []);
      setTotalCount(responseData?.recordLength);

      if (!consultantList?.length) dispatch(getConsultant({}));

      setLoader(false);
    } catch (error) {
      setFilteredInstructionData([]);
      dispatch(
        showSnackbar({
          message: `${error?.message}`,
          severity: "error",
        })
      );
      setLoader(false);
    }
  };

  const handleClickTransferEmail = (params) => {
    console.log("params", params?.row);
    setSelectedInstruction(params?.row?.id);
    setIsTransfer(true);
  };

  const successTransferEmail = async () => {
    const payload = {
      id: instructionData?.id,
      Data: {
        reason: reason,
        transferToId: selectedInstruction,
        emailId: selectedEmail?.id,
      },
      forCase: true,
    };
    setIsTransfer(false);
    try {
      setLoader(true);
      const response = await dispatch(transferEmail(payload)).unwrap();
      if (response) {
        setLoader(false);
        dispatch(
          showSnackbar({
            show: true,
            message: NOTIFICATION_MESSAGE?.SUCCESS_TRANSFER_MAIL,
            severity: "success",
          })
        );
        handleClose();
      }
    } catch (error) {
      setLoader(false);
      dispatch(
        showSnackbar({
          show: true,
          message: error?.message,
          severity: "error",
        })
      );
      handleClose();
    }
  };

  useEffect(() => {
    getInstructionsData();
    getInstructionTypeData();
    getMilestoneType();
    getInstructionStatus();
  }, []);

  return (
    <>
      <Dialog
        sx={{ border: 1 }}
        open={open}
        fullWidth={true}
        maxWidth={"md"}
        // fullScreen
      >
        {isTransfer && (
          <Dialog
            sx={{ border: 1 }}
            open={open}
            fullWidth={true}
            maxWidth={"sm"}
          >
            <DialogContent dividers={true}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    label="Add Reason"
                    fullWidth
                    placeholder="Add Reason"
                    multiline
                    rows={10}
                    value={reason || ""}
                    onChange={(e) => setReason(e?.target?.value)}
                    // error={isError && !note}
                    // helperText={
                    //   isError && !note && "Required note or document."
                    // }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 10,
                  }}
                >
                  <Button
                    className="cancel_button"
                    onClick={() => setIsTransfer(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="save_button"
                    onClick={successTransferEmail}
                  >
                    Send
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        )}
        <DialogContent dividers={true}>
          <Grid container spacing={2} style={{ marginTop: "0px" }}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 0,
                padding: 1,
              }}
            >
              <CloseIcon />
            </IconButton>
            {/* Loader Component */}
            {isLoader && <Loader />}
            {/* Filter Container */}
            <Grid item xs={2.4}>
              <TextField
                fullWidth
                className="textField_outlined_border"
                placeholder="Search"
                size="small"
                value={filterPayload.search}
                onChange={(e) => handleChangeName(e?.target?.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "30px",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {/* TYPE */}
            <Grid item xs={2.4}>
              <FormControl
                fullWidth
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "30px",
                  },
                }}
              >
                <InputLabel className="normal_normal_medium_14_Manrope dark_grey">
                  Type
                </InputLabel>
                <Select
                  className="textField_outlined_border"
                  label="Type"
                  onChange={(e) =>
                    handleChangeInstructionType(e?.target?.value, "type")
                  }
                >
                  <MenuItem>--</MenuItem>
                  {instructionTypes?.length > 0 &&
                    instructionTypes.map((d) => (
                      <MenuItem
                        key={d?.id}
                        value={d?.name}
                        className="normal_normal_medium_14_Manrope dark_grey"
                      >
                        {d?.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            {/* MILESTONE */}
            <Grid item xs={2.4}>
              <FormControl
                fullWidth
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "30px",
                  },
                }}
              >
                <InputLabel className="normal_normal_medium_14_Manrope dark_grey">
                  Milestone
                </InputLabel>
                <Select
                  className="textField_outlined_border"
                  label="Milestone"
                  onChange={(e) =>
                    handleChangeMilestone(e?.target?.value, "milestones")
                  }
                >
                  <MenuItem>--</MenuItem>
                  {milestone?.length > 0 &&
                    milestone.map((d) => (
                      <MenuItem
                        key={d?.id}
                        value={d?.name}
                        className="normal_normal_medium_14_Manrope dark_grey"
                      >
                        {d?.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>{" "}
            {/* STATUS */}
            <Grid item xs={2.4}>
              <FormControl
                fullWidth
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "30px",
                  },
                }}
              >
                <InputLabel className="normal_normal_medium_14_Manrope dark_grey">
                  Status
                </InputLabel>
                <Select
                  className="textField_outlined_border"
                  label="Status"
                  onChange={(e) =>
                    handleChangeFilter(e?.target?.value, "statusId")
                  }
                >
                  <MenuItem>--</MenuItem>
                  {instructionStatusList?.length > 0 &&
                    instructionStatusList.map((d) => (
                      <MenuItem
                        key={d?.id}
                        value={d?.id}
                        className="normal_normal_medium_14_Manrope dark_grey"
                      >
                        {d?.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            {/* CONSULTANT */}
            {(userData?.role === USER_ROLE?.OFFICE_ADMIN ||
              userData?.role === USER_ROLE?.SUPER_ADMIN) && (
              <Grid item xs={2.4}>
                <Autocomplete
                  options={consultantList}
                  onChange={(e, value) =>
                    handleChangeFilter(value?.id, "consultantId")
                  }
                  filterSelectedOptions={Boolean(consultantList?.length)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="normal_normal_medium_14_Manropee textField_outlined_border"
                      variant="outlined"
                      size="small"
                      label="Consultant"
                      placeholder="Select Consultant"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "30px",
                          height: 38,
                        },
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} style={{ width: "100%", height: "60vh" }}>
              <DataGridComponent
                columns={columns}
                rows={instructionsData}
                onRowClick={() => {}}
              />
            </Grid>
            {/* PAGINATION */}
            <PaginationComponent
              totalCount={totalCount}
              limit={filterPayload?.limit}
              page={filterPayload?.page}
              onChange={handleChangePage}
            />
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TransferEmailDialog;
