export const partyObject = {
  fullName: "",
  contribution: null,
  monthlyMortgageContribution: null,
  share: null,
};
export const ultimateBeneficiaryObject = {
  beneficiaryName: "",
  classification: "Class of Beneficiary",
  relationshipToSettlors: "",
  gender: "Female",
  dob: "",
  address: {
    street1: "",
    street2: "",
    city: "",
    state: "",
    country: "United Kingdom",
    zip: "",
  },
  other_config: { isWeb: true },
};
export const generalResidueDistributionObject = {
  fullname: "",
  classification: "Class of Beneficiary",
  perStripes: false,
  age: 18,
  percentageShare: 0,
  other_config: { isWeb: true },
};
