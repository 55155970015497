import { createSlice } from "@reduxjs/toolkit";
import {
  createEmailTemplate,
  getEmailTemplates,
} from "../thunks/email_template";

const emailTemplateSlice = createSlice({
  name: "emailTemplateSlice",
  initialState: {
    loading: false,
    emailTemplates: [],
  },
  extraReducers: (builder) => {
    builder

      // Get emailTemplates slice
      .addCase(getEmailTemplates.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(getEmailTemplates.fulfilled, (state, action) => {
        state.loading = false;
        state.emailTemplates = action?.payload;
      })

      .addCase(getEmailTemplates.rejected, (state, action) => {
        state.loading = false;
        state.emailTemplates = [];
      })
      // createEmailTemplate slice
      .addCase(createEmailTemplate.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(createEmailTemplate.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.id) {
          state.emailTemplates.unshift(action?.payload);
        }
      })

      .addCase(createEmailTemplate.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default emailTemplateSlice.reducer;
