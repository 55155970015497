import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Loader from "../../../components/Loader";
import {
  getCustomInstructionTaskAnswerHistory,
  getInstructionTaskAnswerHistory,
  readImage,
} from "../../../thunks/instruction";
import { downloadDocument } from "../../../utils/util";

const CommentsDrawer = ({ open, handleClose, selectedComment }) => {
  const dispatch = useDispatch();
  const [taskComments, setTaskComments] = useState([]);
  const [isLoader, setLoader] = useState(false);
  const [expandedNotes, setExpandedNotes] = useState({});

  /* HandleClose Task comment drawer */
  const handleCloseTaskComments = () => {
    setTaskComments([]);
    handleClose();
  };

  /* Method to get Task Comments */
  const getTaskComments = async () => {
    if (selectedComment?.taskType === "Custom") {
      try {
        setLoader(true);
        const response = await dispatch(
          getCustomInstructionTaskAnswerHistory(selectedComment?.id)
        ).unwrap();
        if (response) {
          setTaskComments(response);
          setLoader(false);
        } else {
          setTaskComments([]);
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);
        return error;
      }
    } else {
      try {
        setLoader(true);
        const id =
          selectedComment?.taskType === "Instance"
            ? selectedComment?.instructionTaskId
            : selectedComment?.id;
        const response = await dispatch(
          getInstructionTaskAnswerHistory(id)
        ).unwrap();
        if (response) {
          setTaskComments(response);
          setLoader(false);
        } else {
          setTaskComments([]);
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);
        return error;
      }
    }
  };

  /* Method to read more and read less */
  const toggleExpand = (id) => {
    setExpandedNotes((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  // Method to read image
  const handleReadImage = async (doc) => {
    try {
      const response = await dispatch(
        readImage({ query: { filename: doc?.documentUrl } })
      ).unwrap();
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    getTaskComments();
  }, []);
  return (
    <>
      {/* Loader Component */}
      {isLoader && <Loader />}

      <Drawer
        className="sidebar_comment_drawer"
        anchor="right"
        open={open}
        onClose={handleCloseTaskComments}
      >
        <Box sx={{ width: 600, padding: "1rem" }} role="presentation">
          <Grid container spacing={3}>
            {taskComments?.length > 0 ? (
              taskComments?.map((task) => (
                <>
                  <Grid item xs={5}>
                    <Typography className="normal_normal_600_14_Manrope dark_grey">
                      {task?.instructionTaskDocumentCreatedBy.firstName || "NA"}{" "}
                      {task?.instructionTaskDocumentCreatedBy.lastName || "NA"}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={7}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Typography className="normal_normal_600_14_Manrope dark_grey">
                      {moment(task?.updatedAt).format("DD/MM/YYYY hh:mm A")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className="normal_normal_18_Manrope dark_gray">
                      {expandedNotes[task?.id]
                        ? task?.notes
                        : `${task.notes.slice(0, 80)}`}
                      {task?.notes?.length > 80 && (
                        <Typography
                          onClick={() => toggleExpand(task?.id)}
                          className="normal_normal_600_14_Manrope green"
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                        >
                          {expandedNotes[task?.id] ? "Show Less" : "Show More"}
                        </Typography>
                      )}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {task.documentUrl && (
                      <IconButton onClick={() => handleReadImage(task)}>
                        <VisibilityIcon color="action" />
                      </IconButton>
                    )}

                    {task.documentUrl && (
                      <IconButton
                        onClick={() =>
                          task.documentUrl ? downloadDocument(task) : null
                        }
                      >
                        <DownloadIcon color="action" />
                      </IconButton>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <Divider style={{ border: "1px solid #00ce3f" }} />
                  </Grid>
                </>
              ))
            ) : (
              <Grid item xs={12} m={1}>
                <Typography className="normal_normal_18_Manrope dark_gray">
                  No Data Found.
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      </Drawer>
    </>
  );
};

export default CommentsDrawer;
