import CancelIcon from "@mui/icons-material/Cancel";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import Loader from "../../../components/Loader";
import { REGEX, VALIDATION_MSG } from "../../../utils/constant";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const UploadFile = ({ handleClose, open, onSubmit, isLoader }) => {
  const [isError, setIsError] = useState(false);
  const [isUploadFile, setIsUploadFile] = useState(false);
  const [documentData, setDocumentsData] = useState({
    documentUrl: "",
  });
  // const [isLoader, setLoader] = useState(false);

  const handleFileUpload = (e, key) => {
    const selectedFile = e?.target?.files[0];

    // isError(true);
    const filename = e?.target?.files[0]?.name;
    if (filename) {
      let extension = filename.substr(filename.lastIndexOf("."));

      let isAllowed = REGEX?.UPLOAD_DOCUMENT.test(extension);
      if (isAllowed) {
        setIsUploadFile(false);
      } else {
        setIsUploadFile(true);
      }
    }
    setDocumentsData({
      ...documentData,
      ["documentUrl"]: selectedFile,
    });
  };

  const handleRemoveDocument = () => {
    setDocumentsData({
      ...documentData,
      ["documentUrl"]: "",
    });
    setIsUploadFile(false);
  };

  const handleSubmit = () => {
    if (!documentData?.documentUrl) {
      setIsError(true);
    } else {
      setIsError(false);
      onSubmit(documentData);
    }
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        fullWidth
        maxWidth="sm"
        sx={{ zIndex: 100 }}
      >
        {isLoader && <Loader />}

        <Grid container spacing={3} style={{ padding: "1rem" }}>
          <Grid item xs={12}>
            <DialogTitle
              style={{ textAlign: "center" }}
              className="serif_display_regular_26 green"
            >
              Upload File
            </DialogTitle>
          </Grid>

          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              fullWidth
              style={{
                padding: "20px 0",
                backgroundColor: "#E5FAEB",
                cursor: "pointer",
                borderRadius: "10px",
                boxShadow: "none",
              }}
              className="normal_normal_16_Manrope green"
              component="label"
              variant="contained"
              startIcon={
                <FileUploadOutlinedIcon style={{ fontSize: "30px" }} />
              }
              type="file"
              onChange={(event) => handleFileUpload(event)}
            >
              UPLOAD THE DOCUMENTS
              <VisuallyHiddenInput type="file" />
            </Button>
          </Grid>

          {documentData?.documentUrl && (
            <Grid item xs={12} m={1} p={2}>
              <Paper
                style={{
                  padding: "1rem",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography>{documentData?.documentUrl?.name}</Typography>
                <CancelIcon
                  style={{ cursor: "pointer", color: "#d32f2f" }}
                  onClick={handleRemoveDocument}
                />
              </Paper>
            </Grid>
          )}

          {isError && !documentData?.documentUrl && (
            <Grid item xs={12}>
              <Typography style={{ color: "#d32f2f" }}>
                {VALIDATION_MSG.REQUIRED}
              </Typography>
            </Grid>
          )}
          {/* --------------------------------------------  */}
          {isUploadFile && (
            <Grid item xs={12}>
              <Typography style={{ color: "#d32f2f" }}>
                {VALIDATION_MSG.VALID_DOCUMENT}
              </Typography>
            </Grid>
          )}

          <Grid
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "2rem 0 0 0",
              gap: "1rem",
            }}
          >
            <Button
              style={{
                border: "1px solid red",
                borderRadius: "20px",
                padding: "0 30px ",
                hover: { color: "red" },
                color: "red",
              }}
              className="cancel_button"
              onClick={() => handleClose()}
            >
              Cancel
            </Button>
            {isUploadFile ? (
              <Button
                variant="outlined"
                disabled
                style={{
                  borderRadius: "20px",
                  padding: "5px 40px ",
                }}
                onClick={handleSubmit}
              >
                Save
              </Button>
            ) : (
              <Button
                style={{
                  backgroundColor: "#00ce3f",
                  borderRadius: "20px",
                  padding: "5px 40px ",
                  color: "white",
                }}
                onClick={handleSubmit}
              >
                Save
              </Button>
            )}
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default UploadFile;
