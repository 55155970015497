import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import PaginationComponent from "../../components/PaginationComponent";
import { getAllNotes } from "../../thunks/client";

const NotesDrawer = ({ open, handleClose, selectedComment }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [notes, setNotes] = useState([]);
  const [isLoader, setLoader] = useState(false);
  const [expandedNotes, setExpandedNotes] = useState({});
  const [filterPayload, setFilterPayload] = useState({
    page: 1,
    limit: 10,
    search: "",
  });
  const [totalCount, setTotalCount] = useState("");
  /* HandleClose Task comment drawer */
  const handleCloseNotes = () => {
    setNotes([]);
    handleClose();
  };

  /* Method to get Task Comments */
  const getNotesData = async (payload = filterPayload) => {
    try {
      setLoader(true);
      const response = await dispatch(getAllNotes(payload)).unwrap();
      if (response) {
        setNotes(response?.notesOnClient);
        setTotalCount(response?.recordLength);
        setLoader(false);
      } else {
        setNotes([]);
        setTotalCount(0);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      return error;
    }
  };

  /* Method to read more and read less */
  const toggleExpand = (id) => {
    setExpandedNotes((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  /* Method to change page --> Pagination */
  const handleChangePage = (event, value) => {
    setFilterPayload({ ...filterPayload, page: value });
    const payload = {
      ...filterPayload,
      page: value,
    };
    getNotesData(payload);
  };

  const handleClickCreatedBy = (note) => {
    handleClose();
    navigate(`/app/client/update-client/${note?.leadCliet?.id}`);
  };

  // Method to handle Change text
  const handleChangeName = (value) => {
    const payload = {
      ...filterPayload,
      search: value,
    };
    setFilterPayload(payload);
  };

  /* Method to filter  --> By Name */
  const handleClickSearchByName = () => {
    const payload = {
      ...filterPayload,
      page: 1,
    };
    getNotesData(payload);
  };

  // Method to clear text field
  const handleClearSearch = () => {
    const payload = {
      ...filterPayload,
      page: 1,
      search: "",
    };
    setFilterPayload(payload);
    getNotesData(payload);
  };

  // useEffect
  useEffect(() => {
    getNotesData();
  }, []);
  return (
    <>
      {/* Loader Component */}
      {isLoader && <Loader />}
      <Drawer
        className="sidebar_comment_drawer"
        anchor="right"
        open={open}
        onClose={handleCloseNotes}
      >
        <Box sx={{ width: 600, padding: "1rem" }} role="presentation">
          <Grid
            container
            spacing={3}
            p={1}
            m={-2}
            style={{
              backgroundColor: "#e5faeb",
              position: "fixed",
              zIndex: 10000,
            }}
          >
            {/* Search by name field */}
            <Grid item xs={2} pb={1}>
              <TextField
                fullWidth
                placeholder="search by client.."
                className="textField__outlined__border"
                size="small"
                value={filterPayload?.search}
                onChange={(e) => handleChangeName(e?.target?.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "30px",
                    height: 38,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <HighlightOffIcon
                        style={{
                          color: "#000000",
                          fontSize: "17px",
                          cursor: "pointer",
                          display: filterPayload?.search ? "" : "none",
                        }}
                        onClick={() => handleClearSearch()}
                      />
                      <IconButton onClick={() => handleClickSearchByName()}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={2}>
              {/* PAGINATION */}
              <PaginationComponent
                totalCount={totalCount}
                limit={filterPayload?.limit}
                page={filterPayload?.page}
                onChange={handleChangePage}
                siblingCount={0}
                boundaryCount={1}
                justifyContent=""
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            mt={7}
            display={"flex"}
            justifyContent={"flex-end"}
          >
            {notes?.length > 0 ? (
              notes?.map((note) => (
                <>
                  <Grid item xs={8}>
                    <Typography
                      onClick={() => handleClickCreatedBy(note)}
                      style={{ cursor: "pointer" }}
                      className="normal_normal_medium_14_Manrope green"
                    >
                      {note?.leadCliet?.firstName || "NA"}{" "}
                      {note?.leadCliet?.lastName || "NA"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid>
                      <Typography className="normal_normal_medium_14_Manrope green">
                        {note?.createdBy?.role || "NA"}
                      </Typography>
                      <Typography className="normal_normal_medium_14_Manrope dark_gray">
                        {note?.createdBy?.firstName || "NA"}{" "}
                        {note?.createdBy?.lastName || "NA"}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography className="normal_normal_medium_14_Manrope dark_gray">
                      {expandedNotes[note?.id]
                        ? note?.note
                        : `${note?.note.slice(0, 100)}`}
                      {note?.note?.length > 100 && (
                        <Typography
                          onClick={() => toggleExpand(note?.id)}
                          className="normal_normal_medium_14_Manrope green"
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                        >
                          {expandedNotes[note?.id] ? "Show Less" : "Show More"}
                        </Typography>
                      )}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Divider style={{ border: "1px solid #00ce3f" }} />
                  </Grid>
                </>
              ))
            ) : (
              <Grid item xs={12} m={1}>
                <Typography className="normal_normal_18_Manrope dark_gray">
                  No Data Found.
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      </Drawer>
      ;
    </>
  );
};

export default NotesDrawer;
