import { Grid, Typography } from "@mui/material";
import { useFieldArray } from "react-hook-form";
import { AddButton } from "../../../../../../components/buttons";
import { RadioInput, TextInput } from "../../../../../../components/inputs";
import { beneficiariesObject } from "../willQuestionnaireFormConstants";
import RDTForm from "./RDTForm";

const RDTItem = ({ arr, isJoint }) => {
  const client1 = useFieldArray({
    name: `InstructionWillQuestionnaire.[0].willTrusts.residuaryDiscretionaryTrust.RDT`,
  });

  const { append, remove, fields } = client1;

  const client2 = useFieldArray({
    name: `InstructionWillQuestionnaire.[1].willTrusts.residuaryDiscretionaryTrust.RDT`,
  });

  const {
    append: secondaryAppend,
    remove: secondaryRemove,
    fields: secondaryFields,
  } = client2;

  const handleAdd = (clientNumber) => {
    clientNumber === 0
      ? append(beneficiariesObject)
      : secondaryAppend(beneficiariesObject);
  };

  const handleRemove = (clientNumber, ind) => {
    if (clientNumber === 1) {
      remove(ind);
    } else {
      secondaryRemove(ind);
    }
  };

  return (
    <>
      <Grid width={"100%"}>
        <Grid
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          borderBottom={`1px solid #DCDCE1`}
          padding={2}
        >
          <Grid width={"85%"}>
            <Typography>Residuary Discretionary Trust</Typography>
          </Grid>
          <Grid width={"15%"}>
            <RadioInput
              label=""
              name={`InstructionWillQuestionnaire.[0].willTrusts.isRDT`}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
            />
          </Grid>
        </Grid>

        <Grid
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={isJoint ? "space-between" : "center"}
          borderTop={`1px solid #DCDCE1`}
          borderBottom={`1px solid #DCDCE1`}
        >
          {arr?.map((item, index) => {
            return (
              <Grid
                width={isJoint ? "50%" : "62%"}
                borderRight={
                  index === 0 && isJoint ? `1px solid #DCDCE1` : null
                }
                padding={2}
                display={"flex"}
                flexDirection={"column"}
                gap={2}
              >
                <Grid width={"100%"} display={"flex"} flexDirection={"row"}>
                  <Grid
                    width={"100%"}
                    paddingY={4}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={3}
                  >
                    {(index === 0 ? fields : secondaryFields).map(
                      (card, ind) => {
                        return (
                          <RDTForm
                            isJoint={isJoint}
                            index={index}
                            cardNo={ind}
                            pathName={`InstructionWillQuestionnaire.[${index}].willTrusts.residuaryDiscretionaryTrust.RDT.[${ind}]`}
                            handleRemove={() => handleRemove(item, ind)}
                          />
                        );
                      }
                    )}
                    <Grid textAlign={"center"}>
                      <AddButton onClick={() => handleAdd(index)} />
                    </Grid>
                  </Grid>
                  {/* ); */}
                  {/* })} */}
                </Grid>
                <TextInput
                  label={`Guidance Notes`}
                  name={`InstructionWillQuestionnaire.[${index}].willTrusts.residuaryDiscretionaryTrust.guidanceNotes`}
                  placeholder="Enter note"
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
};

export default RDTItem;
