import { Grid } from "@mui/material";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  AddButton,
  ContainedGreenButton,
  OutlinedGreenButton,
  RemoveButton,
} from "../../../../../components/buttons";
import {
  DropdownInput,
  RadioInput,
  TextInput,
} from "../../../../../components/inputs";
import { monetaryLegaciesObject } from "./willQuestionnaireFormConstants";

const MonetaryLegacies = () => {
  const isJoint = useSelector((state) => state?.instruction?.isJoint);
  const arr = useSelector((state) => state?.instruction?.arr);

  const client1 = useFieldArray({
    name: `InstructionWillQuestionnaire.[0].monetaryLegacies`,
  });

  const { append, remove, fields } = client1;

  const client2 = useFieldArray({
    name: `InstructionWillQuestionnaire.[1].monetaryLegacies`,
  });

  const {
    append: secondaryAppend,
    remove: secondaryRemove,
    fields: secondaryFields,
  } = client2;

  const handleAdd = (clientNumber) => {
    clientNumber === 0
      ? append(monetaryLegaciesObject)
      : secondaryAppend(monetaryLegaciesObject);
  };

  const handleRemove = (clientNumber, ind) => {
    if (clientNumber === 1) {
      remove(ind);
    } else {
      secondaryRemove(ind);
    }
  };

  return (
    <>
      <Grid
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={isJoint ? "space-between" : "center"}
        borderTop={`1px solid #DCDCE1`}
        borderBottom={`1px solid #DCDCE1`}
      >
        {arr?.map((item, index) => {
          return (
            <Grid
              width={isJoint ? "50%" : "62%"}
              borderRight={index === 0 && isJoint ? `1px solid #DCDCE1` : null}
              paddingY={4}
              display={"flex"}
              flexDirection={"column"}
              gap={3}
              paddingRight={index === 0 ? 2 : 0}
              paddingLeft={index === 1 ? 2 : 0}
            >
              {(index === 0 ? fields : secondaryFields).map((card, ind) => {
                return (
                  <MonetaryLegaciesForm
                    isJoint={isJoint}
                    index={index}
                    cardNo={ind}
                    handleRemove={() => handleRemove(item, ind)}
                    isApplicationJoint={isJoint}
                  />
                );
              })}
              <Grid textAlign={"center"}>
                <AddButton onClick={() => handleAdd(index)} />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default MonetaryLegacies;

const MonetaryLegaciesForm = ({
  index,
  cardNo,
  handleRemove,
  isApplicationJoint,
}) => {
  const { getValues, setValue } = useFormContext();
  const { relationshipAddr } = useSelector((state) => state?.lookupData);

  const handleJoint = () => {
    let isJoint = getValues(
      `InstructionWillQuestionnaire.${index}.monetaryLegacies.${cardNo}.isJoint`
    );
    setValue(
      `InstructionWillQuestionnaire.${index}.monetaryLegacies.${cardNo}.isJoint`,
      !isJoint
    );
  };

  const isCardJoint = useWatch({
    name: `InstructionWillQuestionnaire.${index}.monetaryLegacies.${cardNo}.isJoint`,
  });
  return (
    <Grid
      boxShadow={"1px 2px 6px #00000029"}
      borderBottom={`1px solid #DCDCE1`}
      // border={"2px solid red"}
      borderRadius={"16px"}
      borderRight={index === 0 ? `1px solid #DCDCE1` : null}
      padding={2}
      paddingY={3}
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      position={"relative"}
    >
      {index === 0 && isApplicationJoint ? (
        <div className="card-mirror-btn">
          {isCardJoint ? (
            <ContainedGreenButton label={"Joint"} onClick={handleJoint} />
          ) : (
            <OutlinedGreenButton label={"Joint"} onClick={handleJoint} />
          )}
        </div>
      ) : null}
      <TextInput
        label={`Recipient Name`}
        name={`InstructionWillQuestionnaire.${index}.monetaryLegacies.[${cardNo}].recipientName`}
        placeholder="Enter name"
      />
      <DropdownInput
        name={`InstructionWillQuestionnaire.${index}.monetaryLegacies.[${cardNo}].relationship`}
        label="Relationship"
        options={relationshipAddr}
      />
      <TextInput
        label={`Gift Details`}
        name={`InstructionWillQuestionnaire.${index}.monetaryLegacies.[${cardNo}].giftDetails`}
        placeholder="Enter gift details"
      />

      <Grid
        display={"flex"}
        width={"100%"}
        justifyContent={"space-between"}
        gap={2}
      >
        <Grid width={"28%"}>
          <RadioInput
            label="Per Stirpes"
            name={`InstructionWillQuestionnaire.${index}.monetaryLegacies.[${cardNo}].perStripes`}
            options={[
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
          />
        </Grid>
        <Grid width={"68%"}>
          <RadioInput
            label="On death of"
            name={`InstructionWillQuestionnaire.${index}.monetaryLegacies.[${cardNo}].onDeathOf`}
            options={[
              { value: "On First Death", label: "On First Death" },
              { value: "On Second Death", label: "On Second Death" },
            ]}
          />
        </Grid>
      </Grid>

      <div style={{ textAlign: "right" }}>
        <RemoveButton label={"Joint"} onClick={handleRemove} />
      </div>
    </Grid>
  );
};
