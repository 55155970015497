import { createSlice } from "@reduxjs/toolkit";
import { getLookups, getMilestone } from "../thunks/lookup";
import { QC_LookupNames } from "../utils/constant";

const lookupSlice = createSlice({
  name: "lookup",
  initialState: {
    qcLookups: [],
    milestoneData: [],
  },
  extraReducers: (builder) => {
    builder

      // Milestone thunk
      .addCase(getMilestone.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(getMilestone.fulfilled, (state, action) => {
        state.loading = false;
        state.milestoneData = action?.payload;
      })

      .addCase(getMilestone.rejected, (state, action) => {
        state.loading = false;
        state.milestoneData = [];
      })

      // getLookups Type thunk
      .addCase(getLookups.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(getLookups.fulfilled, (state, action) => {
        state.loading = false;
        let lookupsData = action?.payload?.filter(
          (obj) => QC_LookupNames.includes(obj?.name) && obj?.Lookup?.length
        );
        lookupsData.forEach((b) => {
          state[b?.name] = b?.Lookup;
        });
      })

      .addCase(getLookups.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default lookupSlice.reducer;
