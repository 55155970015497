import { Grid } from "@mui/material";
import { useFieldArray } from "react-hook-form";
import { AddButton, RemoveButton } from "../../../../../components/buttons";
import { RadioInput, TextInput } from "../../../../../components/inputs";
import { VALIDATION_MSG } from "../../../../../utils/constant";

import { partyObject } from "./decOfTrustConstant";

const PropertyItem = ({ isJoint, propertyIndex }) => {
  const clientParties = useFieldArray({
    name: `InstructionDecOfTrustQuestionnaire.[${propertyIndex}].partyDetails`,
    rules: { minLength: 1 },
  });

  const { append, remove, fields } = clientParties;

  const handleAdd = () => {
    append(partyObject);
  };

  const handleRemove = (ind) => {
    remove(ind);
  };

  return (
    <>
      <Grid
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"center"}
        borderTop={`1px solid #DCDCE1`}
        borderBottom={`1px solid #DCDCE1`}
      >
        <Grid width={"65%"} display={"flex"} flexDirection={"row"}>
          <Grid
            width={"100%"}
            paddingY={4}
            display={"flex"}
            flexDirection={"column"}
            gap={4}
          >
            {/* <DropdownInput
              name={`InstructionDecOfTrustQuestionnaire.[${propertyIndex}].property`}
              label="Property"
              options={[
                { label: "property 1", value: "property 1" },
                { label: "property 2", value: "property 2" },
              ]}
            /> */}
            <TextInput
              name={`InstructionDecOfTrustQuestionnaire.[${propertyIndex}].property`}
              label="Property"
              placeholder="Enter value"
            />
            <Grid
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <Grid width={"23%"}>
                <TextInput
                  label={`Title Number`}
                  name={`InstructionDecOfTrustQuestionnaire.[${propertyIndex}].titleNum`}
                  placeholder="Enter value"
                />
              </Grid>
              <Grid width={"23%"}>
                <TextInput
                  label={`Purchase Price`}
                  name={`InstructionDecOfTrustQuestionnaire.[${propertyIndex}].purchasePrice`}
                  placeholder="Enter value"
                />
              </Grid>
              <Grid width={"23%"}>
                <TextInput
                  label={`Current Value`}
                  name={`InstructionDecOfTrustQuestionnaire.[${propertyIndex}].currentVal`}
                  placeholder="Enter value"
                />
              </Grid>
              <Grid width={"23%"}>
                <TextInput
                  label={`Mortgage Value`}
                  name={`InstructionDecOfTrustQuestionnaire.[${propertyIndex}].mortgageValue`}
                  placeholder="Enter value"
                />
              </Grid>
            </Grid>
            <RadioInput
              label="Would you like an AP1 / RX1 Restriction registered at Land Registry?"
              name={`InstructionDecOfTrustQuestionnaire.[${propertyIndex}].restrictionAtLandRegistry`}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
            />
            {/* 🛑 map this party component */}
            {fields.map((party, ind) => {
              return (
                <Party
                  key={party.id}
                  isJoint={isJoint}
                  cardNo={ind}
                  handleRemove={() => handleRemove(ind)}
                  pathName={`InstructionDecOfTrustQuestionnaire.[${propertyIndex}].partyDetails.[${ind}]`}
                />
              );
            })}
            <Grid textAlign={"center"}>
              <AddButton onClick={handleAdd} />
            </Grid>
            {/* 🛑 */}
            <RadioInput
              label="If there are loans, are they interest bearing?"
              name={`InstructionDecOfTrustQuestionnaire.[${propertyIndex}].isInterestBearing`}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
            />
            <Grid width={"100%"}>
              <TextInput
                label={`Detail if any of the contributions are loans & on what terms`}
                name={`InstructionDecOfTrustQuestionnaire.[${propertyIndex}].loanTerms`}
                placeholder="Enter details"
              />
            </Grid>
            <Grid width={"100%"}>
              <TextInput
                label={`Are there any other special terms?`}
                name={`InstructionDecOfTrustQuestionnaire.[${propertyIndex}].specialTerms`}
                placeholder="Enter terms"
              />
            </Grid>
            <Grid width={"100%"}>
              <TextInput
                label={`Detail in your own words the nature of the declaration of trust?`}
                name={`InstructionDecOfTrustQuestionnaire.[${propertyIndex}].natureOfTrustDeclaration`}
                placeholder="Enter details"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PropertyItem;

const Party = ({ pathName, handleRemove, cardNo }) => {
  return (
    <Grid
      boxShadow={"1px 2px 6px #00000029"}
      borderBottom={`1px solid #DCDCE1`}
      borderRadius={"16px"}
      padding={2}
      paddingY={3}
      display={"flex"}
      flexDirection={"column"}
      gap={2}
    >
      <TextInput
        label={`Party ${cardNo + 1}`}
        name={`${pathName}.name`}
        placeholder="Enter name"
        rules={{
          required: { value: true, message: VALIDATION_MSG.REQUIRED },
        }}
      />

      <Grid
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Grid width={"30%"}>
          <TextInput
            label={`Contribution`}
            name={`${pathName}.contribution`}
            placeholder="Enter value"
            rules={{
              required: { value: true, message: VALIDATION_MSG.REQUIRED },
            }}
          />
        </Grid>
        <Grid width={"30%"}>
          <TextInput
            label={`Monthly Mortgage Contribution`}
            name={`${pathName}.mortgageContribution`}
            placeholder="Enter value"
            rules={{
              required: { value: true, message: VALIDATION_MSG.REQUIRED },
            }}
          />
        </Grid>
        <Grid width={"30%"}>
          <TextInput
            label={`Share`}
            name={`${pathName}.share`}
            placeholder="Enter value"
            rules={{
              required: { value: true, message: VALIDATION_MSG.REQUIRED },
            }}
          />
        </Grid>
      </Grid>

      <div style={{ textAlign: "right" }}>
        <RemoveButton label={"Joint"} onClick={handleRemove} />
      </div>
    </Grid>
  );
};
