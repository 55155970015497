import { Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import {
  CustomCheckboxGroup,
  RadioInput,
} from "../../../../../components/inputs";

const LPATop = () => {
  const isJoint = useSelector((state) => state?.instruction?.isJoint);
  const arr = useSelector((state) => state?.instruction?.arr);

  return (
    <Grid width={"100%"} display={"flex"} flexDirection={"row"} mt={10}>
      {arr?.map((item, index) => {
        return (
          <Grid
            width={isJoint ? "50%" : "100%"}
            borderTop={`1px solid #DCDCE1`}
            borderBottom={`1px solid #DCDCE1`}
            borderRight={index === 0 ? `1px solid #DCDCE1` : null}
            paddingY={4}
            display={"flex"}
            flexDirection={"column"}
            gap={3}
            paddingX={2}
          >
            <Grid display={"flex"} flexDirection={"column"} gap={2}>
              <RadioInput
                label="Is Spouse/Partner to be named first Attorney?"
                name={`InstructionLPA_Questionnaire.[${index}].isRelationshipToBeNamedFirstAttorney`}
                options={[
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ]}
                // rules={{ required: "select value", message: "select value" }}
              />
              <CustomCheckboxGroup
                label="Select LPA Type (only in case of one LPA)"
                name={`InstructionLPA_Questionnaire.[${index}].lpaType.[${0}]`}
                options={[
                  {
                    value: "Property & Financial Affairs",
                    label: "Property & Financial Affairs",
                  },
                ]}
              />
              <CustomCheckboxGroup
                name={`InstructionLPA_Questionnaire.[${index}].lpaType.[${1}]`}
                options={[
                  { value: "Health & Welfare", label: "Health & Welfare" },
                ]}
              />
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default LPATop;
