import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Button, Divider, Grid, IconButton, Typography } from "@mui/material";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Loader from "../../../components/Loader";
import { showSnackbar } from "../../../slice/snackbarSlice";
import { getAllMeetings, updateMeetingShow } from "../../../thunks/client";
import { NOTIFICATION_MESSAGE } from "../../../utils/constant";

const UpcomingMeeting = () => {
  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // states
  const [isLoader, setLoader] = useState(false);
  const [meetings, setMeetings] = useState([]);

  /* Method to get meetings */
  const getAllMeetingsData = async () => {
    setLoader(true);
    try {
      const response = await dispatch(
        getAllMeetings({
          query: { status: "scheduled", milestone: "Not Instructed" },
        })
      ).unwrap();
      if (response?.length > 0) {
        setMeetings(response);
        setLoader(false);
      } else {
        setMeetings([]);
        setLoader(false);
        dispatch(
          showSnackbar({
            show: true,
            message: NOTIFICATION_MESSAGE.MEETING_DATA_NOT_FOUND_ERROR,
            severity: "error",
          })
        );
      }
    } catch (error) {
      setLoader(false);
      return error;
    }
  };

  const handleShow = async (showValue, meetingId) => {
    try {
      setLoader(true);
      let response = await dispatch(
        updateMeetingShow({ meetingId, isShow: showValue })
      ).unwrap();
      if (response) {
        let tempData = meetings?.map((m) => {
          if (meetingId === m.id) {
            return { ...m, isNoShow: !m.isNoShow };
          }
          return m;
        });

        setMeetings(tempData);
        setLoader(false);
        dispatch(
          showSnackbar({
            show: true,
            message: NOTIFICATION_MESSAGE.MEETING_STATUS_UPDATED_SUCCESS,
            severity: "success",
          })
        );
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      dispatch(
        showSnackbar({
          show: true,
          message: error.message,
          severity: "error",
        })
      );
    }
  };

  // use effect
  useEffect((d) => {
    getAllMeetingsData();
  }, []);

  return (
    <>
      {/* Loader Component */}
      {isLoader && <Loader />}{" "}
      <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
        <Typography
          className="serif_display_regular_22 dark_grey"
          sx={{
            width: "100%",
          }}
        >
          Upcoming Meetings
        </Typography>
        <Button
          className="setUpNewMeeting normal_normal_600_12_Manrope"
          onClick={() => {
            navigate("/app/setupNewMeeting");
          }}
          style={{ marginRight: "2rem" }}
        >
          Setup a New Meeting
        </Button>
      </Grid>
      {/* Upcomming Meeting List Section */}
      <Grid
        item
        xs={12}
        overflow={"auto"}
        className="dashboard-scrollbar"
        height={"80vh"}
      >
        {meetings?.length > 0 &&
          meetings?.map((meeting, index) => {
            let isMeetingDatePassedAndURIExists =
              new Date() > new Date(meeting?.meetingDate) &&
              meeting?.scheduled_events_URI;
            return (
              <>
                <Grid item xs={12} display={"flex"} flexDirection={"row"}>
                  <Grid
                    item
                    xs={6}
                    mt={1}
                    mb={1}
                    style={{ borderRight: "1px solid #dcdce1" }}
                  >
                    <Typography className="normal_normal_34_Manrope green">
                      {moment(meeting?.meetingDate).format("HH:mm")}
                    </Typography>
                    <Typography className="normal_normal_18_Manrope dark_grey">
                      {moment(meeting?.meetingDate).format("ddd, MMM D")}
                    </Typography>
                    <Typography className="normal_normal_18_Manrope dark_grey">
                      {meeting.location || "NA"}
                    </Typography>
                    <Typography className="normal_normal_18_Manrope dark_grey">
                      {meeting?.eventTypeName || "NA"}
                    </Typography>
                  </Grid>

                  <Grid item xs={6} mt={1} mb={1} ml={2}>
                    <Typography className="normal_normal_600_16_Manrope green">
                      {`${meeting?.LeadClientFirst?.firstName} ${meeting?.LeadClientFirst?.lastName}`}
                      {meeting?.isFromWebOrCalendly && (
                        <IconButton>
                          <CalendarMonthIcon style={{ color: "#00CE3F" }} />
                        </IconButton>
                      )}
                    </Typography>

                    {isMeetingDatePassedAndURIExists ? (
                      <Button
                        className="normal_normal_600_12_Manrope"
                        onClick={() =>
                          handleShow(meeting?.isNoShow, meeting?.id)
                        }
                        style={{
                          width: "max-content",
                          border: "1px solid #00CE3F",
                          borderRadius: "50px",
                          padding: "2px 10px",
                        }}
                      >
                        {meeting?.isNoShow === true
                          ? "Undo Mark No Show"
                          : "Mark No Show"}
                      </Button>
                    ) : null}

                    {meeting?.LeadClientSecond && (
                      <Typography
                        className="normal_normal_600_16_Manrope green"
                        sx={{
                          marginBottom: 1,
                        }}
                      >
                        {`${meeting?.LeadClientSecond?.firstName} ${meeting?.LeadClientSecond?.lastName}`}
                      </Typography>
                    )}

                    <Typography className="normal_normal_14_Manrope dark_grey">
                      {meeting?.instructionType || "NA"}
                    </Typography>
                    <Typography className="normal_normal_14_Manrope dark_grey">
                      CREATED BY -{" "}
                      <span className="normal_normal_600_14_Manrope dark_grey">
                        {`${
                          meeting?.leadClientMeetingCreator?.firstName || "NA"
                        } ${
                          meeting?.leadClientMeetingCreator?.lastName || "NA"
                        }`}
                        , {meeting?.leadClientMeetingCreatedByRole || "NA"}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
                <Divider
                  orientation="horizontal"
                  sx={{ border: `1px solid #DCDCE1` }}
                />
              </>
            );
          })}
      </Grid>
    </>
  );
};

export default UpcomingMeeting;
