import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import AddClient from "./components/CreateClient";
import ImportExcel from "./components/ImportExcel";
import ClientList from "./components/list";
import ClientDashboard from "./components/ClientDashboard";

const Client = () => {
  const Location = useLocation();
  return (
    <>
      <div className="main-page-container">
        <Routes>
          <Route
            path={`/`}
            element={<Navigate replace="/" to={`${Location.pathname}/list`} />}
          ></Route>
          <Route path={"list"} element={<ClientList />}></Route>
          <Route path={"update-client/:id"} element={<ClientDashboard />}></Route>
          <Route path={"create-client"} element={<AddClient />}></Route>
          <Route path={"import-list"} element={<ImportExcel />}></Route>
        </Routes>
      </div>
    </>
  );
};

export default Client;
