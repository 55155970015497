import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateDocumentSubType } from "../../../thunks/documents";
import { showSnackbar } from "../../../slice/snackbarSlice";
import Loader from "../../../components/Loader";
import AlertComponent from "../../../components/alert";

const UpdateDocSubType = ({
  selectedData,
  getDocumentSubTypeData,
  setOpenDocSubType,
}) => {
  const dispatch = useDispatch();
  const [documentSubType, setDocumentSubType] = useState(null);
  const [isLoader, setLoader] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [isUpdateDocumentSubTypeDialog, setUpdateDocumentSubTypeDialog] =
    useState(false);
  console.log("isActuve", isActive);
  console.log("selectedData", selectedData);

  const handleOpenUpdateDocumentSubTypeDialog = () => {
    setUpdateDocumentSubTypeDialog(true);
  };

  const handleClickUpdate = async () => {
    setUpdateDocumentSubTypeDialog(false);
    const payload = {
      id: selectedData?.id,
      name: documentSubType,
      isActive: isActive,
    };
    console.log("payload", payload);
    try {
      setLoader(true);
      let checkDispatch = updateDocumentSubType(payload);
      const response = await dispatch(checkDispatch).unwrap();
      if (response) {
        dispatch(
          showSnackbar({
            message: "Document sub type updated successfully",
            severity: "success",
          })
        );
        setLoader(false);
        setOpenDocSubType(null);
        getDocumentSubTypeData();
      } else {
        setLoader(false);
        setUpdateDocumentSubTypeDialog(false);
      }
    } catch (error) {
      setLoader(false);
      setUpdateDocumentSubTypeDialog(false);

      dispatch(
        showSnackbar({
          message: `${error?.message}`,
          severity: "error",
        })
      );
      return error;
    }
  };

  useEffect(() => {
    if (selectedData) {
      setDocumentSubType(selectedData?.name);
    } else {
      setDocumentSubType(null);
    }
  }, [selectedData]);
  return (
    <Box>
      {isLoader && <Loader />}

      {/* confirmation model to update document sub type */}
      {isUpdateDocumentSubTypeDialog && (
        <AlertComponent
          open={isUpdateDocumentSubTypeDialog}
          handleSuccess={handleClickUpdate}
          handleClose={() => setUpdateDocumentSubTypeDialog(false)}
          message={`Are you sure, want to update document sub type?`}
        />
      )}

      <Grid container spacing={3} paddingTop={2} paddingBottom={2}>
        {/* Task Name Field Section */}
        <Grid item xs={12}>
          <TextField
            className="inputTextField normal_normal_600_12_Manropee"
            label="Document Sub Type"
            placeholder="Document Sub Type"
            fullWidth
            required
            size="small"
            value={documentSubType || null}
            onChange={(e) => setDocumentSubType(e?.target?.value)}
            // error={isError && !taskData?.name}
            // helperText={
            //   isError && !taskData?.name && VALIDATION_MSG.REQUIRED
            // }
            InputLabelProps={{ shrink: true }}
            variant="standard"
          />
        </Grid>

        {/* Is Active section */}
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  color: "#00CE3F",
                  "&.Mui-checked": {
                    color: "#00CE3F",
                  },
                }}
                checked={isActive || false}
                onChange={(e) => setIsActive(e?.target?.checked)}
              />
            }
            label="Is Active?"
          />
        </Grid>
        {/* Submit Button */}
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            className="save_button"
            onClick={handleOpenUpdateDocumentSubTypeDialog}
          >
            Update
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UpdateDocSubType;
