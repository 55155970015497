import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updateInstruction } from "../../../../../thunks/instruction";
import TrustItem from "./TrustItem";

const TrustQuestionnaireForm = () => {
  const dispatch = useDispatch();
  const instructionDataObject = useSelector(
    (state) => state?.instruction?.tempInstructionData
  );
  const isJoint = instructionDataObject?.isJoint;
  const arr = useSelector((state) => state?.instruction?.arr);

  let trustData = instructionDataObject?.InstructionTrustQuestionnaire || [];

  // const methods = useForm({
  //   defaultValues: {
  //     trusts: [...trustData],
  //   },
  // });

  const methods = useFormContext();
  const { control } = methods;

  const clientArray = useFieldArray({
    control,
    name: "InstructionTrustQuestionnaire",
  });

  const handleSave = (data) => {
    const updatedData = {
      InstructionData: {
        ...instructionDataObject,
        InstructionTrustQuestionnaire: data.trusts,
      },
    };
    const res = dispatch(updateInstruction(updatedData));
  };
  const handleErrors = (errors) => {};

  return (
    <>
      <Grid
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        width={"100%"}
        mt={7}
      >
        {/* <FormHeader arr={arr} formName={formNames.trustQuestionnaire} /> */}
        <Grid width={"100%"} mb={4}>
          {trustData?.length ? (
            trustData.map((trust, index) => {
              return (
                <Accordion
                  elevation={0}
                  key={index}
                  style={{
                    borderBottom: "1px solid #DCDCE1",
                    width: "100%",
                    justifyContent: "center",
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <AccordionSummary
                    style={{
                      width: "100%",
                      padding: "10px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid width={"100%"} display={"flex"} flexDirection={"row"}>
                      <span
                        className="serif_display_regular_20 dark_grey"
                        style={{
                          width: "100%",
                        }}
                      >
                        {`Trust ${index + 1} / `}
                        <span
                          style={{
                            width: "100%",
                            fontStyle: "italic",
                            marginRight: "auto",
                            color: "#3D4740",
                          }}
                        >
                          {trust?.trustName?.name}
                        </span>
                      </span>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TrustItem arr={arr} isJoint={isJoint} trustIndex={index} />
                  </AccordionDetails>
                </Accordion>
                // </Grid>
              );
            })
          ) : (
            <Typography p={3}>No data available</Typography>
          )}
        </Grid>

        {/* <Grid width={"100%"} textAlign={"right"} mt={5}>
          <SubmitButton onClick={handleSubmit(handleSave, handleErrors)} />
        </Grid> */}
      </Grid>
    </>
  );
};

export default TrustQuestionnaireForm;
