import { Alert } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import React from "react";
import { useDispatch } from "react-redux";
import { hideSnackbar } from "../slice/snackbarSlice";

const SnackbarComponent = ({ open, autoHideDuration, severity, message }) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideSnackbar());
  };

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
        severity={severity}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert variant="filled" severity={severity || "success"}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SnackbarComponent;
