import React, { useEffect, useState } from "react";
import ClientCareLetter from "../../ClientCareLetter";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getClientLetter } from "../../../../../thunks/instruction";
import { Box } from "@mui/material";
import moment from "moment";

const ClientCarePage = ({ show }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const instructionId = params.id;
  const instructionData = useSelector(
    (state) => state.instruction.instructionDashboardData
  );
  const [isLoader, setLoader] = useState(false);

  const [data, setData] = useState(null);

  const getClientCareDataById = async () => {
    try {
      setLoader(true);
      const response = await dispatch(getClientLetter(instructionId)).unwrap();
      if (response?.data) {
        let responseData = response?.data;
        setData({
          ...responseData,
          consultantName: responseData?.ownerOfInstructionByName,
          parties: responseData?.InstructionClients,
          clientCare: responseData?.InstructionClientCare?.clientCare,
        });
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    getClientCareDataById();
  }, []);

  return (
    <Box sx={{ padding: 4 }}>
      {/* header  */}
      <div className="">
        <div className="header_top">
          <div className="form_name_div">
            <span className="text-style text-bold">Client Care Letter</span>
          </div>
        </div>
      </div>
      {/* Body */}
      <div className="flex-row-wrap-center center_container">
        <div className="">
          {/* Client Information */}
          <div className="flex-column gap-30 mb-50">
            <div>
              <div className="applicationTypeHeading">Consultant</div>
              <div className="text-big">{`${
                data?.ownerOfInstruction?.firstName || "NA"
              } ${data?.ownerOfInstruction?.lastName || "NA"}`}</div>
            </div>

            <div>
              <div className="applicationTypeHeading">Client(s) Name</div>
              <div className="text-big">
                {data?.parties?.[0]?.firstName +
                  " " +
                  data?.parties?.[0]?.lastName}
                {data?.applicationType === "Joint" &&
                  ` & ${
                    data?.parties?.[1]?.firstName +
                    " " +
                    data?.parties?.[1]?.lastName
                  }`}
              </div>
            </div>

            <div>
              <div className="applicationTypeHeading">Address</div>
              <div className="text-big">
                {data?.parties?.[0]?.InstructionFactFind?.clientDetails?.address
                  ?.street1 ?? ""}
                ,
                {data?.parties?.[0]?.InstructionFactFind?.clientDetails?.address
                  ?.street2 ?? ""}
                ,
                {data?.parties?.[0]?.InstructionFactFind?.clientDetails?.address
                  ?.city ?? ""}
                ,
                {data?.parties?.[0]?.InstructionFactFind?.clientDetails?.address
                  ?.state ?? ""}
                ,
                {data?.parties?.[0]?.InstructionFactFind?.clientDetails?.address
                  ?.zip ?? ""}
                ,
                {data?.parties?.[0]?.InstructionFactFind?.clientDetails?.address
                  ?.country ?? ""}
              </div>
            </div>
          </div>

          {/* Introduction */}
          <div className="flex-column gap-20 mb-50">
            <div className="flex-column gap-2 break-avoid">
              <div className="applicationTypeHeading">Introduction</div>
              <div className="text-big">
                The template for this Client Care document has been created by
                Squiggle Consult LTD (Squiggle) and it sets out the basis on
                which you are instructing your Consultant to provide you with
                your chosen Legacy and Estate Planning documents and/or
                solutions. It also provides an overview of the standard Terms of
                Engagement that are at the back of this booklet and which should
                be read in conjunction with this letter. Please read both
                carefully.
                <br />
                Your Consultant’s membership of Squiggle includes the provision
                of professional Indemnity Insurance that is maintained by
                Squiggle on their behalf and is there for your protection.
              </div>
            </div>
            <div className="flex-column gap-2 break-avoid">
              <div className="applicationTypeHeading">
                Your Consultants obligations to you and how you have arrived at
                your Legacy and Estate Planning decision
              </div>
              <div className="text-big">
                Your Consultant will have completed a standard fact-finding
                document with you, collecting personal information that is
                relevant to Legacy & Estate Planning. They will have accurately
                recorded your wishes, made you aware of current and potential
                rules and circumstances that could impact on your wishes and how
                your assets and heirs may be affected. They will also have
                recorded your personal attitude towards the need for protection,
                certainty, and risk. They will have presented you with all of
                the planning options available to you, giving you the ability to
                make a personal and informed decision on what type of planning
                you consider to be appropriate to your attitude, circumstances,
                and requirements.
              </div>
            </div>
            <div className="flex-column gap-2 break-avoid">
              <div className="applicationTypeHeading">
                Your obligations to your Consultant
              </div>
              <div className="text-big">
                Full details of your obligations can be found in section 3 of
                the Terms of Engagement, but the most important is that the
                information you provide during the consultation and thereafter
                is accurate and complete.
              </div>
            </div>
            <div className="flex-column gap-2 break-avoid">
              <div className="applicationTypeHeading">
                Consultation limitations
              </div>
              <div className="text-big">
                Your Consultant will not be drafting any of your Legacy and
                Estate Planning documents. Within the limitations of their
                Squiggle membership, your Consultant is neither able to advise
                on or provide financial services nor are they able to provide
                tax advice, although they can relay standard rules and
                information for you to consider in relation to certain relevant
                taxes such as Inheritance Tax and other associated taxes. It may
                be that your Consultant is suitably qualified to provide this
                kind of advice independently and at your request, but it does
                not fall within the parameters of their membership. Should you
                require additional legal, financial, or tax advice, you can
                request that Squiggle refer you to an appropriate professional.
              </div>
            </div>
          </div>

          {/* BREAK */}
          <div className="section flex-column gap-20 mb-50">
            <div className="flex-column gap-2 break-avoid">
              <div className="applicationTypeHeading">
                Who will be working for you?
              </div>
              <div className="text-big">
                Your Consultant is responsible for helping you, (as described in
                the ‘Your Consultant’s obligations to you and how you have
                arrived at your Legacy and Estate Planning decision’ section
                above), to make an informed choice and holds full responsibility
                for that element of the process.
                <br />
                Squiggle will collate and quality assess the information
                collected by your Consultant. They will then appoint the
                appropriate individuals and/or organisations, (including
                Solicitors and/or Barristers) from their approved panel of
                professionals to provide/produce your chosen Legacy and Estate
                Planning solution. Squiggle panel of professionals have been
                selected based on experience, knowledge, customer care, and
                cost-effectiveness. All have the requisite Professional
                Indemnity Insurance for the task.
              </div>
            </div>
            <div className="flex-column gap-2 break-avoid">
              <div className="applicationTypeHeading">Fees & Charges</div>
              <div className="text-big">
                Creating transparency in relation to fees and charges is a
                fundamental element of our standards. Fees charged for the
                provision of Legacy & Estate Planning solutions are on a fixed
                price basis. However, your specific planning may include the
                need to involve certain authorities or government agencies who
                will charge a disbursement. These disbursements can vary, but
                your consultant will make you aware of the additional costs from
                the outset.
                <br />
                Squiggle Members agree to keep the fees charged within
                parameters set and monitored by Squiggle so that they are fair
                and commensurate with the market, reflecting the quality of the
                products and services.
              </div>
            </div>
            <div className="flex-column gap-2 break-avoid">
              <div className="applicationTypeHeading">
                Confidentiality & Your Information
              </div>
              <div className="text-big">
                Squiggle standards insist on compliance with the Data Protection
                Act 2018 and the General Data Protection Regulation. As such,
                Squiggle and your Consultant have their own Data Protection
                Licence issued by the Information Commissioners Office and we
                undertake to keep your information safe and will not share it
                with anyone without your express permission.
                <br />
                To create your Legacy & Estate Planning solution and for quality
                control purposes, it will be necessary for your Consultant to
                share the information collected from you with Squiggle. In turn,
                Squiggle will need to instruct and share your information with
                third party professionals on your behalf and in some
                circumstances, with certain authorities and government agencies.
                However, only essential information will be shared, it will be
                done securely and only when necessary and under a strict
                condition that your information can only be used and recorded
                for the specific purposes relating to your chosen Legacy and
                Estate Planning solution.
              </div>
            </div>
            <div className="flex-column gap-2 break-avoid">
              <div className="applicationTypeHeading">
                Should you decide to cancel
              </div>
              <div className="text-big">
                Should you decide to cancel your instructions your Consultant
                will respect your right to change your mind, but we ask you to
                let us know within 14 days of signing this instruction. However,
                we may need to make a charge for the works undertaken up until
                the point of cancellation. Full details in relation to your
                rights to cancel can be found in section 7 of the Terms of
                Engagement.
              </div>
            </div>
            <div className="flex-column gap-2 break-avoid">
              <div className="applicationTypeHeading">
                Should you have cause to complain
              </div>
              <div className="text-big">
                We hope that you will never have cause to complain, but if you
                are dissatisfied in any way you should contact your Consultant
                in the first instance. If you feel that your issues are not
                resolved satisfactorily by your Consultant, you should write to
                Squiggle Consult LTD who will take up your complaint and try to
                resolve things to your satisfaction within the shortest possible
                time frame. A full explanation of the complaints procedure can
                be found in section 10 of the Terms of Engagement.
              </div>
            </div>
          </div>

          {/* BREAK */}
          <div className="section mb-40">
            <div className="text-style text-bold text-center mb-40">
              Your Legacy and Estate Planning Instructions
            </div>

            <div className="mb-30">
              <div className="key mb-20 text-big">
                The documents and services you are instructing your Consultant
                to arrange are:
              </div>
              <div className="flex-column gap-10">
                <div className="flex-row">
                  <div className="width-70 key">Particulars</div>
                  <div className="width-15 key text-center">Instructed</div>
                  <div className="width-15 key text-center">Quantity</div>
                </div>
                {data?.clientCare?.documentsToBeArranged?.map(function (
                  item,
                  index
                ) {
                  return (
                    <div className="flex-row" key={index}>
                      <div className="width-70">{item.particular ?? ""}</div>
                      <div className="width-15 text-center text-green">
                        {item?.isInstructed ? "✔" : "-"}
                      </div>
                      <div className="width-15 text-center">
                        {item.quantity ?? "0"}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            {/* How much will my Estate Planning cost? */}
            <div className="mb-30">
              <div className="text-green text-big mb-20">
                How much will my Estate Planning cost?
              </div>
              <div className="flex-column gap-10">
                <div className="flex-row-sb">
                  <div className="applicationTypeHeading">Particulars</div>
                  <div className="applicationTypeHeading">Cost</div>
                </div>
                <div className="flex-row-sb">
                  <div className="">
                    Fixed Cost of Legacy & Estate Planning services
                  </div>
                  <div className="">
                    &#163;{" "}
                    {Number(
                      data?.clientCare?.estatePlanningCost?.fixedLegacyCost
                    )?.toFixed(2) || "NA"}
                  </div>
                </div>
                <div className="flex-row-sb">
                  <div className="">Discount</div>
                  <div className="">
                    &#163;{" "}
                    {Number(
                      data?.clientCare?.estatePlanningCost?.discount?.cost
                    )?.toFixed(2) || "NA"}
                  </div>
                </div>
                <div className="flex-row-sb">
                  <div className="">VAT (@ 20% of qualifying costs)</div>
                  <div className="">
                    &#163;{" "}
                    {Number(data?.clientCare?.estatePlanningCost?.vat)?.toFixed(
                      2
                    ) || "NA"}
                  </div>
                </div>
                <div className="flex-row-sb">
                  <div className="">Total</div>
                  <div className="">
                    &#163;{" "}
                    {Number(
                      data?.clientCare?.estatePlanningCost?.total
                    )?.toFixed(2) || "NA"}
                  </div>
                </div>
                <div className="flex-row-sb">
                  <div className="">
                    Total Payments (including below disbursements)
                  </div>
                  <div className="">
                    &#163;{" "}
                    {Number(
                      data?.clientCare?.estatePlanningCost
                        ?.totalIncludingDisbursements
                    )?.toFixed(2) || "NA"}
                  </div>
                </div>
              </div>
            </div>

            {/* Payment information & Breakdown */}
            <div>
              <div className="text-green text-big mb-20">
                Payment information & Breakdown
              </div>
              <div className="text-big mb-20">
                Your Consultant has agreed that your payment for the above
                services should be made payable to Squiggle Consult LTD. Your
                fees will not be distributed to your Consultant until your
                instructions have been audited, passed through our Quality
                Control Process. Any document drafting fees and service fees for
                Solicitors, Barristers and other professionals have been
                included within the fixed price cost and we will be responsible
                for paying those fees. You will not receive any additional
                requests for payment.
              </div>
              <div className="flex-column gap-10">
                <div className="flex-row-sb">
                  <div className="">Payment to Squiggle Consult LTD.</div>
                  <div className="">
                    &#163;{" "}
                    {Number(
                      data?.clientCare?.paymentBreakdownInformation
                        ?.toSquiggleConsult
                    )?.toFixed(2) || "NA"}
                  </div>
                </div>
                <div className="flex-row-sb">
                  <div className="">Payment to Land Registry</div>
                  <div className="">
                    &#163;{" "}
                    {
                      data?.clientCare?.paymentBreakdownInformation
                        ?.toLandRegistry
                    }
                  </div>
                </div>
                <div className="flex-row-sb">
                  <div className="">
                    Payment to the Office of Public Guardian
                  </div>
                  <div className="">
                    &#163;{" "}
                    {
                      data?.clientCare?.paymentBreakdownInformation
                        ?.toPublicGuardianOffice
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* BREAK */}
          {/* Your Instruction & Declaration */}
          <div className="section">
            <div className="text-style text-bold text-center mb-10">
              Your Instruction & Declaration
            </div>
            <div className="text-big mb-20">
              It is important that you read and understand this Client Care
              Letter & Instruction in conjunction with the Terms of Engagement
              document, before signing below. If there is anything that you do
              not understand or do not agree with, please discuss it with your
              Consultant before signing. Only if this document is a fair and
              accurate representation of your interaction with your Consultant
              and the instructions you wish to provide should you sign below.
            </div>
            <div className="text-style text-bold text-center mb-10">
              Please check as appropriate
            </div>
            <div className="flex-column gap-20">
              {/* Each declaration */}
              {/* Declaration 1 */}
              <div className="flex-row-only gap-20 vertical-top">
                <div className="text-center">
                  {data?.clientCare?.declarations?.permissionToShareDetails ? (
                    <div className="checked">✔</div>
                  ) : (
                    <div className="notChecked"></div>
                  )}
                </div>
                <div className="">
                  I/We give our Consultant permission to share my/our details
                  along with any and all information collected, with Squiggle
                  for quality control purposes in relation to our Legacy and
                  Estate Planning instructions.
                </div>
              </div>
              {/* Declaration 2 */}
              <div className="flex-row-only gap-20 vertical-top">
                <div className="text-center">
                  {data?.clientCare?.declarations
                    ?.permissionToInstructReleventInformation ? (
                    <div className="checked">✔</div>
                  ) : (
                    <div className="notChecked"></div>
                  )}
                </div>
                <div className="">
                  I/We give Squiggle permission to instruct and to share my/our
                  relevant information with one or more third parties as per the
                  ‘Confidentiality and your information’ section of this
                  document, for the purposes of providing Legacy & Estate
                  Planning documents and services.
                </div>
              </div>
              {/* Declaration 3 */}
              <div className="flex-row-only gap-20 vertical-top">
                <div className="text-center">
                  {data?.clientCare?.declarations
                    ?.acceptingSoleResponsibility ? (
                    <div className="checked">✔</div>
                  ) : (
                    <div className="notChecked"></div>
                  )}
                </div>
                <div className="">
                  it has been explained to me/us that my/our Consultant accepts
                  sole responsibility for the consulting process that resulted
                  in me/us providing these specific instructions and that the
                  professionals who draft the documents or provide the services
                  hold responsibility for the quality of the document(s) /
                  service only.
                </div>
              </div>
              {/* Declaration 4 */}
              <div className="flex-row-only gap-20 vertical-top">
                <div className="text-center">
                  {data?.clientCare?.declarations
                    ?.commenseArrangementInstruct ? (
                    <div className="checked">✔</div>
                  ) : (
                    <div className="notChecked"></div>
                  )}
                </div>
                <div className="">
                  I/We have elected to instruct Squiggle to commence
                  arrangements for the provision of my/our chosen Legacy and
                  Estate Planning documents and/or services immediately. I/We
                  understand that if I/we cancel my/our instructions during or
                  after the 14 day cancellation period I/we may be liable to pay
                  the cost of any works undertaken.
                </div>
              </div>
              {/* Declaration 5 */}
              <div className="flex-row-only gap-20 vertical-top">
                <div className="text-center">
                  {data?.clientCare?.declarations
                    ?.requestToReferingFinancialAdvisor ? (
                    <div className="checked">✔</div>
                  ) : (
                    <div className="notChecked"></div>
                  )}
                </div>
                <div className="">
                  I/We request that either my Consultant or Squiggle refer me to
                  an appropriate Financial Adviser or Protection Adviser and
                  give permission to share with them my/our contact details.
                </div>
              </div>
              {/* Declaration 6 */}
              <div className="flex-row-only gap-20 vertical-top">
                <div className="text-center">
                  {data?.clientCare?.declarations
                    ?.requestToReferingPlanningAdvisor ? (
                    <div className="checked">✔</div>
                  ) : (
                    <div className="notChecked"></div>
                  )}
                </div>
                <div className="">
                  I/We request that either my Consultant or Squiggie refer me to
                  an appropriate Tax Planning Adviser and give permission to
                  share with them my/our contact details.
                </div>
              </div>
              {/* Declaration 7 */}
              <div className="flex-row-only gap-20 vertical-top">
                <div className="text-center">
                  {data?.clientCare?.declarations
                    ?.instructingToNotifyExecutors ? (
                    <div className="checked">✔</div>
                  ) : (
                    <div className="notChecked"></div>
                  )}
                </div>
                <div className="">
                  I/We instruct Squiggle to notify my Executors, Trustees and
                  Attorneys of their responsibilities and the nature of my/our
                  Legacy & Estate Planning but not the specific details.
                </div>
              </div>
            </div>
            {/* Signature */}
            <div className="flex-row-sb my-10">
              <div className="flex-column gap-10 width-45">
                <div className="text-22">
                  {" "}
                  {`${data?.parties?.[0]?.firstName} ${data?.parties?.[0]?.lastName}`}{" "}
                </div>
                <div className="applicationTypeHeading">Signature</div>
                <div className="">
                  <img
                    className="address_document_image"
                    src={data?.clientCare?.signature?.[0]?.img ?? ""}
                    width="200px"
                    height="100px"
                    alt="squiggle"
                  />
                </div>
                <div className="text-right">
                  {moment(data?.clientCare?.signature?.[0]?.date).format(
                    "DD/MM/YYYY"
                  ) ?? ""}
                </div>
              </div>
              {data?.applicationType === "Joint" && (
                <div className="flex-column gap-10 width-45">
                  <div className="text-22">{`${data?.parties?.[1]?.firstName} ${data?.parties?.[1]?.lastName}`}</div>
                  <div className="applicationTypeHeading">Signature</div>
                  <div className="">
                    <img
                      className="address_document_image"
                      src={data?.clientCare?.signature?.[1]?.img ?? ""}
                      width="200px"
                      height="100px"
                      alt="squiggle"
                    />
                  </div>
                  <div className="text-right">
                    {moment(data?.clientCare?.signature?.[1]?.date).format(
                      "DD/MM/YYYY"
                    ) ?? ""}
                  </div>
                </div>
              )}
            </div>
            <div className="flex-column gap-10">
              <div className="text-small">
                This agreement is between you and your Consultant of Squiggle.
              </div>
              <div className="text-small">
                Squiggle Consult LTD. A company registered in England & Wales
                and with the registration number 10816512.
              </div>
              <div className="text-small">
                Suite 2069, Letraset Building, Wotton Road, Ashford, Kent, TN23
                6LN01233 659796 hello@squiggleconsult.co.uk
                www.squiggleconsult.co.uk
              </div>
            </div>
          </div>

          {/* Terms & Conditions */}

          <div className="section">
            <div className="text-style text-bold text-center my-10">
              Terms & Conditions
            </div>
            <div className="flex-column gap-10">
              <div className="text-small">
                This document explains the terms on which a Consultant of
                Squiggle Consult LTD (Squiggle) and Squiggle itself will provide
                services to a customer (You) whilst offering a product or
                service from Squiggle.
              </div>
              <div className="text-small">
                You should read this document carefully and be satisfied that
                you understand it.
              </div>
              <div className="text-small">
                To ensure you always receive the best possible service and
                assistance when accessing any reserved legal activity, legal
                advice or legal representation via Squiggle the provision of any
                reserved legal activity, legal advice or legal representation
                will be through a Solicitor or a Barrister in independent
                practice.
              </div>
              <div className="text-small">
                The terms of this agreement are governed by English laws and any
                dispute shall be resolved within the jurisdiction of the courts
                of England and Wales.
              </div>
            </div>

            {/* 1 - Fees */}
            <div>
              {/* item */}
              <div className="text-big text-green mt-20 mb-10">1. Fees</div>
              <div className="flex-column gap-20">
                <div className="flex-row-only text-top gap-20">
                  <div className="text-small">1.1</div>
                  <div className="flex-column gap-10">
                    <div className="text-small">
                      The fees for preparing and administering your elected
                      product, legal claim or assistance, and for any additional
                      products or services delivered in the name or brand of
                      Squiggle are:
                    </div>
                    <div className="text-small">
                      • either set out in your Client Care Letter that you have
                      received;
                    </div>
                    <div className="text-small">
                      • or will be provided as a quote for the legal service or
                      product that you require.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 2. Squiggles' and its consultants' undertaking */}
            <div>
              {/* item */}
              <div className="text-big text-green mt-20 mb-10">
                2. Squiggles' and its consultants' undertaking
              </div>
              <div className="flex-column gap-20">
                <div className="flex-row-only text-top gap-20">
                  <div className="text-small">2.1</div>
                  <div className="flex-column gap-10">
                    <div className="text-small">
                      Unless directed otherwise, Squiggle Consultants will
                      always endeavour to discuss your situation and your
                      requirements and obtain as much information as is needed
                      to enable us deal with your enquiry accurately.
                    </div>
                  </div>
                </div>
                <div className="flex-row-only text-top gap-20">
                  <div className="text-small">2.2</div>
                  <div className="flex-column gap-10">
                    <div className="text-small">
                      All reserved activity documents will be reviewed and
                      drafted by nominated Solicitors, or Barristers in
                      Independent practice.
                    </div>
                  </div>
                </div>
                <div className="flex-row-only text-top gap-20">
                  <div className="text-small">2.3</div>
                  <div className="flex-column gap-10">
                    <div className="text-small">
                      You are under no obligation to take up any product or
                      service, however, you may be asked to sign a disclaimer if
                      you either decline to take up any planning or instruct the
                      drafting of, or provision of any product or service in a
                      way that is contrary to our guidance or that of the
                      Consultant or the instructed Solicitor or Barrister.
                    </div>
                  </div>
                </div>
                <div className="flex-row-only text-top gap-20">
                  <div className="text-small">2.4</div>
                  <div className="flex-column gap-10">
                    <div className="text-small">
                      Squiggle’s obligation is to provide you with the documents
                      or service requested. Squiggle will make every reasonable
                      effort to assist you in arranging the correct signing
                      (attestation) of your documents but are under no legal
                      obligation to ensure they are attested fully.
                    </div>
                  </div>
                </div>
                <div className="flex-row-only text-top gap-20">
                  <div className="text-small">2.5</div>
                  <div className="flex-column gap-10">
                    <div className="text-small">
                      Any advice that is given by a Consultant of Squiggle is
                      based on a current understanding of law, practices and
                      procedures at the date of this agreement. Neither
                      Consultants, nor Squiggle, are responsible for any
                      consequences arising from any future changes in law,
                      practices or procedures and the consequences of any such
                      changes are specifically excluded from any liability
                      Squiggle may otherwise hold.
                    </div>
                  </div>
                </div>
                <div className="flex-row-only text-top gap-20">
                  <div className="text-small">2.6</div>
                  <div className="flex-column gap-10">
                    <div className="text-small">
                      Squiggle activities are protected by a Professional
                      Indemnity Policy which provides £2 million indemnity
                      against proven negligent acts of a listed Consultant in
                      their conduct of the sale of approved and scheduled
                      products of Squiggle. You may request a copy of the policy
                      from Squiggle by making a written application to the
                      address on this document.
                    </div>
                  </div>
                </div>
                <div className="flex-row-only text-top gap-20">
                  <div className="text-small">2.7</div>
                  <div className="flex-column gap-10">
                    <div className="text-small">
                      Any Solicitor, Barrister or Product provider that are
                      partners of Squiggle must also carry their own
                      professional Indemnity Insurance and details of their
                      cover can be obtained by contacting Squiggle.
                    </div>
                  </div>
                </div>
                <div className="flex-row-only text-top gap-20">
                  <div className="text-small">2.8</div>
                  <div className="flex-column gap-10">
                    <div className="text-small">
                      Any fees that have been agreed will be paid either
                      directly to Squiggle, or, if advised otherwise, to the
                      solicitor partner that has been instructed on your behalf.
                    </div>
                  </div>
                </div>
                <div className="flex-row-only text-top gap-20">
                  <div className="text-small">2.9</div>
                  <div className="flex-column gap-10">
                    <div className="text-small">
                      Squiggle reserves the right to withdraw from any
                      transaction if they are unable to complete the transaction
                      in whole, or in part, but if they do so, they will write
                      to you immediately and you will not be liable to pay any
                      fees to them. You may however remain liable to pay the
                      cost of any third-party fees or expenses that have been
                      incurred with your consent up to the date they withdraw.
                    </div>
                  </div>
                </div>
                <div className="flex-row-only text-top gap-20">
                  <div className="text-small">2.10</div>
                  <div className="flex-column gap-10">
                    <div className="text-small">
                      The costs of correcting any error or omission on the part
                      of Squiggle or of a Consultant shall be borne entirely by
                      Squiggle.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 3. Your Obligations */}
            <div>
              {/* item */}
              <div className="text-big text-green mt-20 mb-10">
                3. Your Obligations
              </div>
              <div className="flex-column gap-20">
                <div className="flex-row-only text-top gap-20">
                  <div className="text-small">3.1</div>
                  <div className="flex-column gap-10">
                    <div className="text-small">
                      The validity, accuracy and suitability of any documents or
                      legal services that Squiggle provide will partly depend
                      upon the completeness and accuracy of your answers to the
                      Consultant’s questions.
                    </div>
                  </div>
                </div>
                <div className="flex-row-only text-top gap-20">
                  <div className="text-small">3.2</div>
                  <div className="flex-column gap-10">
                    <div className="text-small">
                      Squiggle require you to be open and honest with the
                      information that you provide. Neither the Consultant, nor
                      Squiggle, are, or shall be held at any future time,
                      responsible for any consequences arising from inaccurate
                      or incomplete information provided by you.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 4. Amendments to Documents */}
            <div>
              {/* item */}
              <div className="text-big text-green mt-20 mb-10">
                4. Amendments to Documents
              </div>
              <div className="flex-column gap-20">
                <div className="flex-row-only text-top gap-20">
                  <div className="text-small">4.1</div>
                  <div className="flex-column gap-10">
                    <div className="text-small">
                      Squiggle will make any amendments you require or request
                      right up until FINAL documents are provided. From this
                      point, if you change your mind on any of the details,
                      Squiggle reserves the right to charge £50 per document.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 5. Timescales */}
            <div>
              {/* item */}
              <div className="text-big text-green mt-20 mb-10">
                5. Timescales
              </div>
              <div className="flex-column gap-20">
                <div className="flex-row-only text-top gap-20">
                  <div className="text-small">5.1</div>
                  <div className="flex-column gap-10">
                    <div className="text-small">
                      The timescales for the delivery of documents is typically
                      18 working days from receipt of all necessary information,
                      but can vary slightly depending on the complexity of your
                      instructions or, in very extreme circumstances, where
                      Squiggle experiences extraordinary volumes of work, but
                      you will be kept informed of any difference to the normal
                      drafting timescales. If you are unable to provide all the
                      information that Squiggle and/or the Barrister requires to
                      draft your documents within the timescales set out, then
                      this will adversely affect the agreed service standards
                      and timescales.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 6. Payments */}
            <div>
              {/* item */}
              <div className="text-big text-green mt-20 mb-10">6. Payments</div>
              <div className="flex-column gap-20">
                <div className="flex-row-only text-top gap-20">
                  <div className="text-small">6.1</div>
                  <div className="flex-column gap-10">
                    <div className="text-small">
                      Unless agreed with Squiggle, our fee is due at
                      instruction. Final documents will not be made available
                      until full payment is received.
                    </div>
                  </div>
                </div>
                <div className="flex-row-only text-top gap-20">
                  <div className="text-small">6.2</div>
                  <div className="flex-column gap-20">
                    <div className="text-small">
                      You can request to make payment by cheque (made payable to
                      “Squiggle Consult LTD”), Bank Transfer, Debit Card or by
                      Credit Card although Squiggle reserves the right to pass
                      on any credit card provider fees.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 7. Disclosure of Information & Confidentiality */}
            <div>
              {/* item */}
              <div className="text-big text-green mt-20 mb-10">
                7. Disclosure of Information & Confidentiality
              </div>
              <div className="flex-column gap-20">
                <div className="flex-row-only text-top gap-20">
                  <div className="text-small">7.1</div>
                  <div className="flex-column gap-10">
                    <div className="text-small">
                      Squiggle and its Consultants undertake to maintain the
                      strictest confidentiality and will not pass on your name
                      or details to any other organisations without your express
                      permission. Squiggle are registered under the Data
                      Protection Act 1998, and therefore all information
                      disclosed to us will remain confidential and secure in
                      compliance with the terms of this Act.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 8. Cancellation Policy */}
            <div>
              {/* item */}
              <div className="text-big text-green mt-20 mb-10">
                8. Cancellation Policy
              </div>
              <div className="flex-column gap-20">
                <div className="flex-row-only text-top gap-20">
                  <div className="text-small">8.1</div>
                  <div className="flex-column gap-20">
                    <div className="text-small">
                      You have a right to cancel this agreement with us within
                      14 days of the date of the Client Care Letter issued to
                      you by writing to Squiggle at the postal address or the
                      email listed below. However, work will have commenced on
                      your instructions and drafting costs will have been
                      incurred so, unless the cancellation is due to a delay on
                      our part that is beyond the timescale referred to in
                      section 5, Squiggle reserves the right to retain fees
                      sufficient to cover any work undertaken.
                    </div>
                  </div>
                </div>
                <div className="flex-row-only text-top gap-20">
                  <div className="text-small">8.2</div>
                  <div className="flex-column gap-20">
                    <div className="text-small">
                      Where notice of cancellation is posted, it is recommended
                      that it is sent by Recorded Delivery.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 9. Document Storage */}
            <div>
              {/* item */}
              <div className="text-big text-green mt-20 mb-10">
                9. Document Storage
              </div>
              <div className="flex-column gap-20">
                <div className="flex-row-only text-top gap-20">
                  <div className="text-small">9.1</div>
                  <div className="flex-column gap-20">
                    <div className="text-small">
                      Squiggle may keep an electronic copy of all documents
                      provided to a client by an accredited Consultant or any
                      instructed Barrister, Solicitor partner or third party
                      free of charge. Squiggle will maintain client files for a
                      period but only for as long as required to do so by GDPR
                      Legislation, or until you have asked us to remove any data
                      we may hold.
                    </div>
                  </div>
                </div>
                <div className="flex-row-only text-top gap-20">
                  <div className="text-small">9.2</div>
                  <div className="flex-column gap-20">
                    <div className="text-small">
                      Where you have original documents stored directly with
                      Squiggle, Squiggle reserves the right to charge you or
                      your representative a small administration fee - currently
                      £35 - for the recovery of these documents.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 10. Right Not to Act */}
            <div>
              {/* item */}
              <div className="text-big text-green mt-20 mb-10">
                10. Right Not to Act
              </div>
              <div className="flex-column gap-20">
                <div className="flex-row-only text-top gap-20">
                  <div className="text-small">10.1</div>
                  <div className="flex-column gap-10">
                    <div className="text-small">
                      Squiggle and any other instructed third party reserve the
                      right to refuse to process some or all the legal documents
                      for you at its absolute discretion. If Squiggle decline to
                      act or decline to prepare a specific document then
                      Squiggle will inform you in writing and no fee will be
                      payable.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 11. Client Care / Complaints Procedure */}
            <div>
              {/* item */}
              <div className="text-big text-green mt-20 mb-10">
                11. Client Care / Complaints Procedure
              </div>
              <div className="flex-column gap-20">
                <div className="flex-row-only text-top gap-20">
                  <div className="text-small">11.1</div>
                  <div className="flex-column gap-10">
                    <div className="text-small">
                      Squiggle is committed to ensuring that all Consultants of
                      Squiggle provide services to the highest standards and in
                      compliance with Squiggle’s Code of Conduct. In the
                      unlikely event that you feel you need to make a complaint
                      regarding an allegation of non-compliance with the code of
                      conduct, you should address the complaint to the Director
                      at Squiggle at the address below or by email
                      hello@squiggleconsult.co.uk
                    </div>
                  </div>
                </div>
                <div className="flex-row-only text-top gap-20">
                  <div className="text-small">11.2</div>
                  <div className="flex-column gap-10">
                    <div className="text-small">
                      Squiggle will acknowledge your letter within 5 working
                      days of receipt.
                    </div>
                  </div>
                </div>
                <div className="flex-row-only text-top gap-20">
                  <div className="text-small">11.3</div>
                  <div className="flex-column gap-10">
                    <div className="text-small">
                      Squiggle will instigate a Complaints and Conciliation
                      Process to seek to reach a mutually satisfactory agreement
                      and write to you with the results of the investigation
                      within a further 28 days. These complaints procedures do
                      not prevent you from seeking other means of redress.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 12. Force Majeure */}
            <div>
              {/* item */}
              <div className="text-big text-green mt-20 mb-10">
                12. Force Majeure
              </div>
              <div className="flex-column gap-20">
                <div className="flex-row-only text-top gap-20">
                  <div className="text-small">12.1</div>
                  <div className="flex-column gap-10">
                    <div className="text-small">
                      Neither Squiggle, nor its Consultants, shall have any
                      liability whatsoever or be deemed to be in default for any
                      delays or failure in performance under these Terms of
                      Engagement resulting from acts beyond its control,
                      including but not limited to acts of God, acts or
                      regulations of any government or supranational authority,
                      war or national emergency, terrorist activities, drone
                      attack, accident or fire.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 13. Legal Notice */}
            <div>
              {/* item */}
              <div className="text-big text-green mt-20 mb-10">
                13. Legal Notice
              </div>
              <div className="flex-column gap-20">
                <div className="flex-row-only text-top gap-20">
                  <div className="text-small">13.1</div>
                  <div className="flex-column gap-10">
                    <div className="text-small">
                      Any provision of this Terms of Engagement which is
                      declared void or unenforceable by any competent authority
                      or court shall to the extent of such invalidity or
                      unenforceability be deemed severable and the other
                      provisions of the Terms of Engagement shall continue
                      unaffected.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 14. Third Party Liability */}
            <div>
              {/* item */}
              <div className="text-big text-green mt-20 mb-10">
                14. Third Party Liability
              </div>
              <div className="flex-column gap-20">
                <div className="flex-row-only text-top gap-20">
                  <div className="text-small">14.1</div>
                  <div className="flex-column gap-10">
                    <div className="text-small">
                      Squiggle regards you as their client and as such these
                      terms and condition set out the relationship between
                      Squiggle and you. Squiggle does not accept any liability
                      for any claim made by a third party who is not their
                      client
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default ClientCarePage;
