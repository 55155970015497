import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Tab,
  Tabs,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  DialogActions,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DataGridComponent from "../../../components/DataGridComponent";
import {
  getClientById,
  getClients,
  updateClient,
} from "../../../thunks/client";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  NOTIFICATION_MESSAGE,
  PAGINATION_LIMIT,
} from "../../../utils/constant";
import moment from "moment";
import { showSnackbar } from "../../../slice/snackbarSlice";
import Loader from "../../../components/Loader";
import PaginationComponent from "../../../components/PaginationComponent";

const ClientAuthorisedPersonDialog = ({
  open,
  onClose,
  clientData,
  setClientData,
  authPeopleHistory,
  getUpdatedAuthHistory,
}) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [authorisedPerson, setAuthorisedPerson] = useState(
    clientData?.authorisedPersons?.length
      ? clientData?.authorisedPersons?.map((p) => p?.AuthorisedPerson?.id)
      : []
  );
  const [timer, setTimer] = useState(null);
  const [isLoader, setLoader] = useState(false);
  const [totalCount, setTotalCount] = useState("");
  const [filterPayload, setFilterPayload] = useState({
    page: 1,
    limit: PAGINATION_LIMIT,
    search: "",
    sourceTypeLookupId: null,
    instructionStatusId: null,
    orderByOnCreatedAt: "desc",
  });

  /* Method to get client data */
  const getClientData = async (payload = {}) => {
    try {
      setLoader(true);
      const responseData = await dispatch(getClients(payload)).unwrap();

      if (responseData?.findAllLeadClient?.length > 0) {
        setTotalCount(responseData?.recordLength || 0);
        setData(responseData?.findAllLeadClient);
        setLoader(false);
      } else {
        setData([]);
        setTotalCount(responseData?.recordLength || 0);
        setLoader(false);
      }
    } catch (error) {
      setData([]);
      setTotalCount(0);
      setLoader(false);
    }
  };

  const handleChangePage = (event, value) => {
    setFilterPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
    const payload = {
      ...filterPayload,
      page: value,
    };
    getClientData(payload);
  };

  const handleSaveClientData = async (people) => {
    try {
      setLoader(true);
      setTimeout(() => {}, 100);

      let data = {
        ...clientData,
        authorisedPersons: people?.filter((p) => p?.authorisedPersonId),
      };

      delete data["LeadClientMarketingPreference"];
      delete data["LeadClientAuthorisedPerson"];
      delete data["LeadClientAnniversary"];
      delete data["Instruction"];

      // save data to db
      let response = await dispatch(updateClient(data)).unwrap();

      if (response) {
        // get removed authorised person data
        getUpdatedAuthHistory();

        // get client data from db
        let clientsDataFromDb = await dispatch(
          getClientById(clientData?.id)
        ).unwrap();
        // update local client state of authorised person
        if (clientsDataFromDb) {
          setClientData({
            ...clientData,
            authorisedPersons: clientsDataFromDb?.LeadClientAuthorisedPerson,
          });
        }

        setLoader(false);
        dispatch(
          showSnackbar({
            message: NOTIFICATION_MESSAGE?.SUCCESS_CLIENT_UPDATE,
            severity: "success",
          })
        );
      }
    } catch (error) {
      setLoader(false);
      dispatch(
        showSnackbar({
          message: error?.message,
          severity: "error",
        })
      );
    }
  };

  /* handleChange Name */
  const handleChangeName = (value) => {
    setFilterPayload({ ...filterPayload, search: value, page: 1 });
    if (value?.length >= 3) {
      clearTimeout(timer);
      const newTimer = setTimeout(() => {
        const payload = {
          ...filterPayload,
          page: 1,
          search: value,
        };
        getClientData(payload);
      }, 500);
      setTimer(newTimer);
    }

    if (value?.length === 0) {
      clearTimeout(timer);
      const newTimer = setTimeout(() => {
        const payload = {
          ...filterPayload,
          page: 1,
          search: value,
        };
        getClientData(payload);
      }, 500);
      setTimer(newTimer);
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 310,
      sortable: false,
      renderCell: (params) => (
        <Typography style={{ whiteSpace: "normal", wordBreak: "break-word" }}>
          {params?.row?.firstName} {params?.row?.middleName}{" "}
          {params?.row?.lastName}
        </Typography>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      width: 280,
      sortable: false,
      renderCell: (params) => (
        <Typography
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            overflowWrap: "break-word",
          }}
        >
          {params?.row?.email}
        </Typography>
      ),
    },
    {
      field: "mobileNo",
      headerName: "Mobile Number",
      width: 160,
      sortable: false,
      renderCell: (params) => <Typography>{params?.row?.mobileNo}</Typography>,
    },
    {
      field: "action",
      headerName: "Action",
      width: 90,
      sortable: false,
      renderCell: (params) => {
        return (
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {!authorisedPerson?.includes(params?.row?.id) ? (
              <Button
                size="small"
                fullWidth
                variant="outlined"
                className="meeting_log_btn normal_normal_semibold_14_Manrope green"
                style={{
                  borderRadius: "24px",
                  margin: 1,
                  border: "1px solid #00ce3f",
                }}
                onClick={() => {
                  setData((prev) =>
                    prev?.map((i, index) => {
                      if (i?.id === params?.row?.id) {
                        return { ...i, added: true };
                      }
                      return i;
                    })
                  );
                  handleSaveClientData(
                    [...authorisedPerson, params?.row?.id].map((p) => ({
                      authorisedPersonId: p,
                    }))
                  );
                  setAuthorisedPerson((prev) => {
                    if (!prev?.includes(params?.row?.id)) {
                      return [...prev, params?.row?.id];
                    } else {
                      return prev;
                    }
                  });
                }}
              >
                Add
              </Button>
            ) : (
              <Button
                size="small"
                fullWidth
                variant="outlined"
                className="normal_normal_semibold_14_Manrope red"
                style={{
                  borderRadius: "24px",
                  margin: 1,
                  border: "1px solid #D32F2F",
                }}
                onClick={() => {
                  setData((prev) =>
                    prev?.map((i, index) => {
                      if (i?.id === params?.row?.id) {
                        return { ...i, added: false };
                      }
                      return i;
                    })
                  );

                  handleSaveClientData(
                    authorisedPerson
                      .filter((p) => p !== params?.row?.id)
                      .map((p) => ({
                        authorisedPersonId: p,
                      }))
                  );
                  setAuthorisedPerson(
                    authorisedPerson?.filter((p) => p !== params?.row?.id)
                  );
                }}
              >
                Remove
              </Button>
            )}
          </Grid>
        );
      },
    },
  ];

  useEffect(() => {
    getClientData(filterPayload);
  }, []);

  return (
    <>
      <Dialog
        maxWidth={"md"}
        open={open}
        onClose={onClose}
        fullWidth
        // sx={{ width: "100vw", border: 1, height: "100vh", alignSelf: "center" }}
      >
        <DialogTitle>
          <Grid item xs={12}>
            {/* close dialog button */}
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: "absolute",
                right: 12,
                top: 12,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {isLoader && <Loader />}

          <Grid container spacing={3} p={2}>
            {/* Filter Container */}
            <Grid item xs={4}>
              <TextField
                fullWidth
                placeholder="Search"
                className="textField_outlined_border"
                size="small"
                value={filterPayload?.search}
                onChange={(e) => handleChangeName(e?.target?.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "30px",
                    height: 38,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            {/* Table pagination container */}
            <Grid item xs={8}>
              <PaginationComponent
                totalCount={totalCount}
                limit={filterPayload?.limit}
                page={filterPayload?.page}
                onChange={handleChangePage}
              />
            </Grid>
          </Grid>

          {/* Table data section */}
          <Grid item xs={12} style={{ height: "65vh", overflow: "auto" }}>
            <DataGridComponent
              columns={columns}
              rows={data || []}
              onRowClick={() => {}}
            />
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ClientAuthorisedPersonDialog;
