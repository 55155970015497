import { DataGrid } from "@mui/x-data-grid";
import { uniqueId } from "lodash";
import React from "react";

const DataGridComponent = ({ columns, rows, onRowClick }) => {
  return (
    <>
      <DataGrid
        style={{ border: "none" }}
        rows={rows}
        columns={columns}
        onRowClick={(e) => onRowClick(e)}
        getRowId={() => uniqueId()}
        sx={{
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "#e5faeb",
            cursor: "pointer",
            borderRadius: "20px",
          },
        }}
        hideFooter
        columnHeaderHeight={45}
        density="standard"
        disableColumnFilter={false}
        disableColumnMenu
        className="normal_normal_16_Manrope dark_grey instructionList__table__body"
      />
    </>
  );
};

export default DataGridComponent;
