import { Grid } from "@mui/material";
import { useMemo } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  AddButton,
  ContainedButton,
  ContainedGreenButton,
  OutlinedGreenButton,
  RemoveButton,
} from "../../../../../components/buttons";
import {
  CustomCheckboxGroup,
  DropdownInput,
  NumberInput,
  RadioInput,
  TextInput,
} from "../../../../../components/inputs";
import { VALIDATION_FORMAT } from "../../../../../utils/constant";
import { getUniqueID } from "../../../../../utils/util";
import PropertyCard from "./BuisinessPropertiesForm";
import {
  businessAssetObject,
  businessProperyState,
} from "./factFindFormConstants";

const BusinessAssetForm = () => {
  const isJoint = useSelector((state) => state?.instruction?.isJoint);
  const arr = useSelector((state) => state?.instruction?.arr);

  const client1 = useFieldArray({
    name: `InstructionClients.[0].InstructionFactFind.businessAssets`,
  });

  const { append, remove, fields } = client1;

  const client2 = useFieldArray({
    name: `InstructionClients.[1].InstructionFactFind.businessAssets`,
  });

  const {
    append: secondaryAppend,
    remove: secondaryRemove,
    fields: secondaryFields,
  } = client2;

  const handleAdd = (clientNumber, arrayLength) => {
    clientNumber === 0
      ? append(businessAssetObject)
      : secondaryAppend(businessAssetObject);
  };

  const handleRemove = (clientNumber, ind) => {
    if (clientNumber === 1) {
      remove(ind);
    } else {
      secondaryRemove(ind);
    }
  };
  return (
    <>
      <Grid
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        borderTop={`1px solid #DCDCE1`}
        justifyContent={"center"}
      >
        {" "}
        {arr?.map((item, index) => {
          return (
            <Grid
              key={item}
              width={!isJoint ? "60%" : "100%"}
              borderRight={index === 0 && isJoint ? `1px solid #DCDCE1` : null}
              paddingY={4}
              display={"flex"}
              flexDirection={"column"}
              gap={3}
              paddingRight={index === 0 ? 2 : 0}
              paddingLeft={index === 1 ? 2 : 0}
            >
              {(index === 0 ? fields : secondaryFields).map((card, ind) => {
                return (
                  <BusinessAssetFormSection
                    key={card.id}
                    isJoint={isJoint}
                    index={index}
                    cardNo={ind}
                    handleRemove={() => handleRemove(item, ind)}
                    pathName={`InstructionClients.[${index}].InstructionFactFind.businessAssets.${ind}`}
                    isApplicationJoint={isJoint}
                  />
                );
              })}
              <Grid textAlign={"center"}>
                <AddButton
                  onClick={() =>
                    handleAdd(
                      index,
                      (item === 1 ? fields : secondaryFields).length
                    )
                  }
                />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default BusinessAssetForm;

const BusinessAssetFormSection = ({
  index,
  cardNo,
  handleRemove,
  pathName,
  isApplicationJoint,
}) => {
  let { "Business Type Asset": businessAssetTypes } = useSelector(
    (state) => state?.lookupData
  );
  businessAssetTypes = useMemo(
    () =>
      businessAssetTypes?.length
        ? businessAssetTypes?.map((obj) => ({
            label: obj?.name,
            value: obj?.name,
          }))
        : [],
    [businessAssetTypes]
  );
  const { getValues, setValue, trigger, watch } = useFormContext();
  const handleJoint = () => {
    let isJoint = getValues(`${pathName}.isJoint`) || false;
    setValue(`${pathName}.isJoint`, !isJoint);
  };

  const isCardJoint = useWatch({
    name: `${pathName}.isJoint`,
  });

  let [isTrading] = watch([`${pathName}.isTrading`]);

  const propertyObj = useFieldArray({
    name: `${pathName}.properties`,
  });

  const {
    fields: propertyFields,
    append: propertyAppend,
    remove: propertyRemove,
  } = propertyObj;

  async function handleAddProperty() {
    try {
      if (propertyFields?.length) {
        let lastIndex = propertyFields?.length - 1;
        let lastItem = propertyFields[lastIndex];
        let isValid = true;
        let keys = Object.keys(lastItem);
        let activeKeys = keys?.map(
          (key) => `${pathName}.properties.${lastIndex}.${key}`
        );
        isValid = await trigger(activeKeys); // validate objects
        if (!isValid) {
          return;
        }
      }

      propertyAppend({
        ...businessProperyState,
        _id: getUniqueID(),
      });
    } catch (err) {
      console.log(err);
    }
  }

  async function handleRemoveProperty(clientPropIndex) {
    propertyRemove(clientPropIndex);
  }

  return (
    <Grid
      boxShadow={"1px 2px 6px #00000029"}
      borderBottom={`1px solid #DCDCE1`}
      // border={"2px solid red"}
      borderRadius={"16px"}
      borderRight={index === 0 ? `1px solid #DCDCE1` : null}
      padding={2}
      paddingY={3}
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      position={"relative"}
    >
      {index === 0 && isApplicationJoint ? (
        <div className="card-mirror-btn">
          {isCardJoint ? (
            <ContainedGreenButton label={"Joint"} onClick={handleJoint} />
          ) : (
            <OutlinedGreenButton label={"Joint"} onClick={handleJoint} />
          )}
        </div>
      ) : null}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextInput
            name={`${pathName}.companyName`}
            label="Company Name"
            placeholder="Company Name"
          />
        </Grid>

        <Grid item xs={6}>
          <DropdownInput
            name={`${pathName}.type`}
            label="Type"
            options={businessAssetTypes}
          />
        </Grid>

        <Grid item xs={6}>
          <NumberInput
            name={`${pathName}.percentageShare`}
            label="Percentage Share"
            placeholder="Percentage Share"
            rules={{
              pattern: {
                value: VALIDATION_FORMAT.percentageOnly.pattern,
                message: VALIDATION_FORMAT.percentageOnly.message,
              },
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <CustomCheckboxGroup
            // label="Owned"
            name={`${pathName}.isTrading`}
            options={[
              {
                label: "Trading Business",
                value: true,
              },
            ]}
          />
        </Grid>

        <Grid item xs={6}>
          <NumberInput
            name={`${pathName}.shareValue`}
            label="Share Value"
            placeholder="Share Value"
            isCurrency
          />
        </Grid>

        <Grid item xs={12}>
          <RadioInput
            orientation="row"
            label={`Is there a Partnership Agreement or Shareholders Agreement in place?`}
            name={`${pathName}.partnership`}
            options={[
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
          />
        </Grid>
      </Grid>

      {propertyFields?.length
        ? propertyFields.map((obj, i) => {
            return (
              <PropertyCard
                key={obj?.id}
                pathName={`${pathName}.properties`}
                isTrading={isTrading}
                index={i}
                handleRemoveProperty={() => handleRemoveProperty(i)}
              />
            );
          })
        : null}
      <Grid textAlign={"left"}>
        <ContainedButton label="Add Property" onClick={handleAddProperty} />
      </Grid>

      <div style={{ textAlign: "right" }}>
        <RemoveButton label={"Joint"} onClick={handleRemove} />
      </div>
    </Grid>
  );
};
