import { createSlice } from "@reduxjs/toolkit";
import {
  createUser,
  deactivateUser,
  getConsultant,
  getIntroducer,
  getUser,
  getUserDataById,
  updateUser,
} from "../thunks/user";

const userSlice = createSlice({
  name: "user",
  initialState: {
    loading: false,
    userData: [],
    totalCount: 0,
    introducersData: [],
    consultantsData: [],
    createdOrUpdatedUser: {},
    selectedUserData: {},
  },

  extraReducers: (builder) => {
    builder

      // Get User thunk
      .addCase(getUser.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = false;
        state.userData = action?.payload?.allUsers;
        state.totalCount = action?.payload?.recordLength;
      })

      .addCase(getUser.rejected, (state, action) => {
        state.loading = false;
        state.userData = [];
      })

      // Get Introducer thunk
      .addCase(getIntroducer.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(getIntroducer.fulfilled, (state, action) => {
        state.loading = false;
        state.introducersData = action?.payload;
      })

      .addCase(getIntroducer.rejected, (state, action) => {
        state.loading = false;
        state.introducersData = [];
      })

      // Get Consultant thunk
      .addCase(getConsultant.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(getConsultant.fulfilled, (state, action) => {
        state.loading = false;
        state.consultantsData = action?.payload;
      })

      .addCase(getConsultant.rejected, (state, action) => {
        state.loading = false;
        state.consultantsData = [];
      })

      // Add User thunk
      .addCase(createUser.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(createUser.fulfilled, (state, action) => {
        state.loading = false;
        state.createdOrUpdatedUser = action?.payload;
      })

      .addCase(createUser.rejected, (state, action) => {
        state.loading = false;
        state.createdOrUpdatedUser = {};
      })

      // Update User thunk
      .addCase(updateUser.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(updateUser.fulfilled, (state, action) => {
        state.loading = false;
        state.createdOrUpdatedUser = action?.payload;
      })

      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        state.createdOrUpdatedUser = {};
      })

      // Get User thunk
      .addCase(getUserDataById.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(getUserDataById.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedUserData = action?.payload || {};
      })

      .addCase(getUserDataById.rejected, (state, action) => {
        state.loading = false;
        state.selectedUserData = action?.payload || {};
      })

      // Deactivate User thunk
      .addCase(deactivateUser.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(deactivateUser.fulfilled, (state, action) => {
        state.loading = false;
      })

      .addCase(deactivateUser.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default userSlice.reducer;
