import { Grid, Typography } from "@mui/material";
import { useFieldArray } from "react-hook-form";
import { useSelector } from "react-redux";
import { AddButton, RemoveButton } from "../../../../../components/buttons";
import {
  DropdownInput,
  RadioInput,
  TextInput,
} from "../../../../../components/inputs";
import { IS_VAT_VERIFIED_DATA } from "../../../../../utils/constant";
import {
  attorneyAppointFor,
  attorneyObject,
} from "./lpaQuestionnaireFormConstants";

const ReplacementAttorneysItem = () => {
  const isJoint = useSelector((state) => state?.instruction?.isJoint);
  const arr = useSelector((state) => state?.instruction?.arr);
  const { "Property Financial Affairs": propertyFinancialAffairs } =
    useSelector((state) => state?.lookupData);

  const client1 = useFieldArray({
    name: `InstructionLPA_Questionnaire.[0].replacementAttorneys.replacementAttorneyDetails`,
  });

  const { append, remove, fields } = client1;

  const client2 = useFieldArray({
    name: `InstructionLPA_Questionnaire.[1].replacementAttorneys.replacementAttorneyDetails`,
  });

  const {
    append: secondaryAppend,
    remove: secondaryRemove,
    fields: secondaryFields,
  } = client2;

  const handleAdd = (clientNumber, arrayLength) => {
    clientNumber === 0
      ? append(arrayLength === 0 ? attorneyObject : attorneyObject)
      : secondaryAppend(arrayLength === 0 ? attorneyObject : attorneyObject);
  };

  const handleRemove = (clientNumber, ind) => {
    if (clientNumber === 1) {
      remove(ind);
    } else {
      secondaryRemove(ind);
    }
  };
  return (
    <Grid display={"flex"} flexDirection={"column"} width={"100%"}>
      <Grid width={"100%"} display={"flex"} flexDirection={"row"}>
        {arr?.map((item, index) => {
          return (
            <Grid
              width={isJoint ? "50%" : "100%"}
              borderTop={`1px solid #DCDCE1`}
              borderBottom={`1px solid #DCDCE1`}
              borderRight={index === 0 ? `1px solid #DCDCE1` : null}
              paddingY={4}
              display={"flex"}
              flexDirection={"column"}
              gap={3}
              paddingRight={index === 0 ? 2 : 0}
              paddingLeft={index === 1 ? 2 : 0}
            >
              {(index === 0 ? fields : secondaryFields).map((card, ind) => {
                return (
                  <ReplacementAttorneysForm
                    key={card.id}
                    isJoint={isJoint}
                    index={index}
                    cardNo={ind}
                    handleRemove={() => handleRemove(item, ind)}
                    pathName={`InstructionLPA_Questionnaire.${index}.replacementAttorneys.replacementAttorneyDetails.${ind}`}
                  />
                );
              })}
              <Grid
                width={"100%"}
                textAlign={"center"}
                paddingBottom={2}
                paddingRight={index === 0 ? -2 : 0}
                paddingLeft={index === 1 ? -2 : 0}
              >
                <AddButton
                  onClick={() =>
                    handleAdd(
                      index,
                      (item === 1 ? fields : secondaryFields).length
                    )
                  }
                />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <Grid width={"100%"} display={"flex"} flexDirection={"row"}>
        {arr?.map((item, index) => {
          return (
            <Grid
              width={isJoint ? "50%" : "100%"}
              borderTop={`1px solid #DCDCE1`}
              borderBottom={`1px solid #DCDCE1`}
              borderRight={index === 0 ? `1px solid #DCDCE1` : null}
              paddingY={4}
              display={"flex"}
              flexDirection={"column"}
              gap={3}
              paddingRight={index === 0 ? 2 : 0}
              paddingLeft={index === 1 ? 2 : 0}
            >
              <Grid width={"100%"} gap={1} mt={3}>
                <Typography>
                  If appointing more than one attorney, how are they to act?
                </Typography>
                <Grid
                  width={"100%"}
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  gap={2}
                  my={2}
                >
                  <Grid item xs={6}>
                    <DropdownInput
                      name={`InstructionLPA_Questionnaire.${index}.replacementAttorneys.howAttorneysAct.propertyFinancialAffairs`}
                      label={`Property & Financial Affairs`}
                      options={propertyFinancialAffairs}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DropdownInput
                      name={`InstructionLPA_Questionnaire.${index}.replacementAttorneys.howAttorneysAct.healthWelfare`}
                      label={`Health & Welfare`}
                      options={propertyFinancialAffairs}
                    />
                  </Grid>
                </Grid>
                <Grid display={"flex"} flexDirection={"column"} gap={1}>
                  <TextInput
                    label={`Are there any conditions on appointment of Attorneys?`}
                    name={`InstructionLPA_Questionnaire.${index}.replacementAttorneys.howAttorneysAct.conditionsOnAppointmentOfAttorneys`}
                    placeholder="Enter condition"
                  />
                  <TextInput
                    label={`Are there any restrictions on appointment of Attorneys?`}
                    name={`InstructionLPA_Questionnaire.${index}.replacementAttorneys.howAttorneysAct.restrictionsOnAppointmentOfAttorneys`}
                    placeholder="Enter restrictions"
                  />
                  <TextInput
                    label={`Is there any guidance to the Attorneys?`}
                    name={`InstructionLPA_Questionnaire.${index}.replacementAttorneys.howAttorneysAct.guidanceToAttorneys`}
                    placeholder="Enter guidance"
                  />

                  <RadioInput
                    label={`Are the Attorneys to be paid?`}
                    name={`InstructionLPA_Questionnaire.${index}.replacementAttorneys.howAttorneysAct.paidAttorneys`}
                    options={IS_VAT_VERIFIED_DATA}
                  />
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default ReplacementAttorneysItem;

const ReplacementAttorneysForm = ({
  index,
  cardNo,
  handleRemove,
  pathName,
}) => {
  return (
    <Grid
      boxShadow={"1px 2px 6px #00000029"}
      borderRadius={"16px"}
      borderRight={index === 0 ? `1px solid #DCDCE1` : null}
      padding={2}
      paddingY={3}
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      position={"relative"}
    >
      <TextInput
        label={`Full Name`}
        name={`${pathName}.fullName`}
        placeholder="Full Name"
      />

      <RadioInput
        label="Replacement for"
        name={`${pathName}.replacementFor`}
        options={attorneyAppointFor}
        orientation="column"
      />

      <div style={{ textAlign: "right" }}>
        <RemoveButton label={"Joint"} onClick={handleRemove} />
      </div>
    </Grid>
  );
};
