import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment/moment";
import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../components/Loader";
import AlertComponent from "../../../components/alert";
import { showSnackbar } from "../../../slice/snackbarSlice";
import {
  deleteClient,
  getClientById,
  updateClient,
} from "../../../thunks/client";
import {
  DATE_FORMAT,
  NOTIFICATION_MESSAGE,
  pronounLookup,
  USER_ROLE,
  VALIDATION_MSG,
} from "../../../utils/constant";
import { formValidation } from "./formValidation";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { changeDateFormatToISO } from "../../../utils/util";

const UpdateClient = ({ open, onClose, clientData, setClientData }) => {
  // hooks
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const lookups = useSelector((state) => state?.lookupData);
  let { "Source Type": sourceType, Salutation: salutationData } = lookups || {};
  const { clientUserData } = useSelector((store) => store.client);
  const userDetails = useSelector((state) => state?.auth?.currentUser);
  // states
  const [isError, setError] = useState(false);
  const [formData, setFormData] = useState(clientUserData);
  const [otherPronoun, setOtherPronoun] = useState("");
  const [isLoader, setLoader] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const [isUpdateClientDialog, setUpdateClientDialog] = useState(false);
  const createdAtDate = moment(formData?.createdAt);
  const dateDiff = createdAtDate.fromNow();
  const formattedDate = createdAtDate.format("Do MMMM, YYYY");

  // Method to open update client dialog
  const handleUpdateClientDialog = () => {
    setUpdateClientDialog(true);
  };

  // Method for update client
  const handleSave = async () => {
    setUpdateClientDialog(false);
    const isValidForm = formValidation(formData);
    if (!isValidForm) {
      setError(true);
    } else {
      setError(false);
      try {
        setLoader(true);
        const payload = {
          id: formData?.id,
          title: formData?.title?.trim(),
          firstName: formData?.firstName?.trim(),
          middleName: formData?.middleName?.trim(),
          lastName: formData?.lastName?.trim(),
          prefferedName: formData?.prefferedName?.trim(),
          pronouns:
            formData?.pronouns?.trim() === "Other"
              ? otherPronoun
              : formData?.pronouns?.trim(),
          mobileNo: formData?.mobileNo,
          homeTelephone: formData?.homeTelephone,
          email: formData?.email,
          postalCode: formData?.postalCode,
          sourceTypeLookupId: formData?.sourceTypeLookupId,
          sourceSubType: formData?.sourceSubType?.trim(),
          address: formData?.address,
          dob: formData?.dob,
          aniversaries: [...formData?.LeadClientAnniversary] || [],
        };
        const res = await dispatch(updateClient(payload)).unwrap();
        if (res) {
          let response = await dispatch(getClientById(params?.id)).unwrap();
          let tempData = { ...clientData, ...payload };
          // update client data
          setClientData(
            response
              ? {
                  ...response,
                  marketingPreferences: response?.LeadClientMarketingPreference,
                  authorisedPersons: response?.LeadClientAuthorisedPerson,
                  aniversaries: response?.LeadClientAnniversary,
                  leadLookupStatusId: response?.leadLookupStatusId,
                }
              : tempData
          );

          setLoader(false);
          dispatch(
            showSnackbar({
              show: true,
              message: NOTIFICATION_MESSAGE?.SUCCESS_CLIENT_UPDATE,
              severity: "success",
            })
          );
          onClose();
        }
      } catch (error) {
        setLoader(false);
        setUpdateClientDialog(false);
        dispatch(
          showSnackbar({
            message: error?.message,
            severity: "error",
          })
        );
      }
    }
  };

  // handle change update client form data
  const handleChangeFormData = (value, key) => {
    if (!key.includes("address")) {
      setFormData({ ...formData, [key]: value });
    } else {
      let tempKey = key.split(".")[1];
      setFormData({
        ...formData,
        address: { ...formData?.address, [tempKey]: value },
      });
    }
  };

  /* Delete client success */
  const handleSuccessDeleteClient = async () => {
    try {
      setDelete(false);
      setLoader(true);
      const response = await dispatch(deleteClient(params?.id)).unwrap();
      if (response) {
        setLoader(false);
        navigate("/app/client/list");
        dispatch(
          showSnackbar({
            message: NOTIFICATION_MESSAGE?.SUCCESS_CLIENT_DELETE,
            severity: "success",
          })
        );
      } else {
        setLoader(false);
        navigate("/app/client/list");
        dispatch(
          showSnackbar({
            message: NOTIFICATION_MESSAGE?.SOMETHING_WENT_WRONG,
            severity: "error",
          })
        );
      }
    } catch (error) {
      setDelete(false);
      setLoader(false);
      navigate("/app/client/list");
      dispatch(
        showSnackbar({
          message: error?.message,
          severity: "error",
        })
      );
    }
  };

  return (
    <>
      {/* confirmation dialog to update client */}
      {isUpdateClientDialog && (
        <AlertComponent
          open={isUpdateClientDialog}
          handleSuccess={handleSave}
          handleClose={() => setUpdateClientDialog(false)}
          message={"Are you sure you want to update client?"}
        />
      )}

      <Dialog open={open} onClose={onClose} maxWidth={"md"}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 10,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle textAlign={"center"}>
          <Typography className="serif_display_regular_26 green">
            {userDetails?.role !== USER_ROLE?.INTRODUCER ? "Edit" : "Client"}{" "}
            Details
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid
            className="instruction_dashboard_scrollbar"
            overflow={"auto"}
            height={"auto"}
          >
            {/* Loader Component */}
            {isLoader && <Loader />}
            {/* Confirmation Dialog for delete client */}
            {isDelete && (
              <AlertComponent
                open={isDelete}
                handleSuccess={handleSuccessDeleteClient}
                handleClose={() => setDelete(false)}
                message={"Are you sure you want to delete client?"}
              />
            )}
            {/* Generate Dynamic forms */}
            <Grid
              container
              spacing={2}
              columnSpacing={3}
              py={3}
              px={2}
              boxSizing={"border-box"}
            >
              {/* Title */}
              <Grid item xs={4}>
                <FormControl
                  fullWidth
                  className="border_color normal_normal_600_12_Manropee"
                  // error={isError && !formData?.title}
                >
                  <InputLabel
                    className="normal_normal_600_12_Manrope"
                    shrink={true}
                    style={{
                      lineHeight: "2.0375",
                      marginLeft: "-12px",
                      color: "#00ce3f",
                    }}
                  >
                    Title
                  </InputLabel>
                  <Select
                    className="formControlField"
                    value={formData?.title}
                    label="Title"
                    placeholder="Select Title"
                    onChange={(e) =>
                      handleChangeFormData(e?.target?.value, "title")
                    }
                    variant="standard"
                  >
                    {salutationData?.length > 0 &&
                      salutationData?.map((d) => (
                        <MenuItem
                          className="normal_normal_600_12_Manrope_DropDown_Menu"
                          value={d?.value}
                        >
                          {d?.label}
                        </MenuItem>
                      ))}
                  </Select>
                  {/* {isError && !formData?.title && (
                    <FormHelperText className="red">
                      {VALIDATION_MSG?.REQUIRED}
                    </FormHelperText>
                  )} */}
                </FormControl>
              </Grid>

              {/* First Name */}
              <Grid item xs={4}>
                <TextField
                  value={formData?.firstName}
                  error={isError && !formData?.firstName}
                  helperText={
                    isError && !formData?.firstName && VALIDATION_MSG?.REQUIRED
                  }
                  required
                  fullWidth
                  className="inputTextField border_color normal_normal_600_12_Manropee"
                  label="First Name"
                  placeholder="First Name"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) =>
                    handleChangeFormData(e?.target?.value, "firstName")
                  }
                />
              </Grid>

              {/* Middle Name */}
              <Grid item xs={4}>
                <TextField
                  value={formData?.middleName}
                  fullWidth
                  className="inputTextField border_color normal_normal_600_12_Manropee"
                  label="Middle Name"
                  placeholder="Middle Name"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) =>
                    handleChangeFormData(e?.target?.value, "middleName")
                  }
                />
              </Grid>

              {/* Last Name */}
              <Grid item xs={4}>
                <TextField
                  value={formData?.lastName}
                  error={isError && !formData?.lastName}
                  helperText={
                    isError && !formData?.lastName && VALIDATION_MSG?.REQUIRED
                  }
                  required
                  fullWidth
                  className="inputTextField border_color normal_normal_600_12_Manropee"
                  label="Last Name"
                  placeholder="Last Name"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) =>
                    handleChangeFormData(e?.target?.value, "lastName")
                  }
                />
              </Grid>

              {/* Preferred  Name */}
              <Grid item xs={4}>
                <TextField
                  value={formData?.prefferedName}
                  // error={isError && !formData?.prefferedName}
                  // helperText={
                  //   isError &&
                  //   !formData?.prefferedName &&
                  //   VALIDATION_MSG?.REQUIRED
                  // }
                  fullWidth
                  className="inputTextField border_color normal_normal_600_12_Manropee"
                  label="Preferred Name"
                  placeholder="Preferred Name"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) =>
                    handleChangeFormData(e?.target?.value, "prefferedName")
                  }
                />
              </Grid>

              {/* pronouns */}
              <Grid item xs={4}>
                <FormControl
                  fullWidth
                  className="border_color normal_normal_600_12_Manropee"
                  // required
                  // error={isError && !formData?.pronouns}
                >
                  <InputLabel
                    className="normal_normal_600_12_Manrope"
                    shrink={true}
                    style={{
                      lineHeight: "2.0375",
                      marginLeft: "-12px",
                      color: "#00ce3f",
                    }}
                  >
                    Pronouns
                  </InputLabel>
                  <Select
                    className="formControlField"
                    value={formData?.pronouns}
                    label="pronouns"
                    placeholder="Select pronouns"
                    onChange={(e) =>
                      handleChangeFormData(e?.target?.value, "pronouns")
                    }
                    variant="standard"
                  >
                    {pronounLookup?.length > 0 &&
                      (pronounLookup.find(
                        (item) => item?.value === formData?.pronouns
                      )
                        ? pronounLookup
                        : [
                            ...pronounLookup,
                            {
                              label: formData?.pronouns,
                              value: formData?.pronouns,
                            },
                          ]
                      )?.map((d) => {
                        return (
                          <MenuItem
                            className="normal_normal_600_12_Manrope_DropDown_Menu"
                            value={d?.value}
                          >
                            {d?.label}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  {/* {isError && !formData?.pronouns && (
                    <FormHelperText className="red">
                      {VALIDATION_MSG?.REQUIRED}
                    </FormHelperText>
                  )} */}
                </FormControl>
              </Grid>

              {/* Other Pronouns */}
              {formData?.pronouns === "Other" ? (
                <Grid item xs={4}>
                  <TextField
                    value={otherPronoun}
                    error={isError && !otherPronoun?.length}
                    helperText={
                      isError &&
                      !otherPronoun?.length &&
                      VALIDATION_MSG?.REQUIRED
                    }
                    required
                    fullWidth
                    className="inputTextField border_color normal_normal_600_12_Manropee"
                    label="Custom Pronouns"
                    placeholder="Custom pronouns"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                      setOtherPronoun(e?.target?.value, "pronouns")
                    }
                  />
                </Grid>
              ) : null}

              {/* Mobile Number */}
              <Grid item xs={4}>
                <label
                  style={{ position: "relative", bottom: 5 }}
                  className="normal_normal_600_10_Manropee"
                >
                  Mobile Number
                </label>
                <PhoneInput
                  required
                  country="UK"
                  defaultCountry="GB"
                  placeholder="Mobile Number"
                  className="inputTextField border_color normal_normal_600_12_Manropee"
                  value={formData?.mobileNo}
                  onChange={(e) => handleChangeFormData(e, "mobileNo")}
                />
                {isError && !formData?.mobileNo && (
                  <FormHelperText className="red">
                    {VALIDATION_MSG?.REQUIRED}
                  </FormHelperText>
                )}
              </Grid>
              {/* Home Telephone */}
              <Grid item xs={4}>
                <label
                  style={{ position: "relative", bottom: 5 }}
                  className="normal_normal_600_10_Manropee"
                >
                  Home Telephone
                </label>
                <PhoneInput
                  required
                  country="UK"
                  defaultCountry="GB"
                  placeholder="Home Telephone"
                  className="inputTextField border_color normal_normal_600_12_Manropee"
                  value={formData?.homeTelephone}
                  onChange={(e) => handleChangeFormData(e, "homeTelephone")}
                />
                {isError && !formData?.homeTelephone && (
                  <FormHelperText className="red">
                    {VALIDATION_MSG?.REQUIRED}
                  </FormHelperText>
                )}
              </Grid>

              {/* Email */}
              <Grid item xs={4}>
                <TextField
                  value={formData?.email}
                  fullWidth
                  className="inputTextField border_color normal_normal_600_12_Manropee"
                  label="Email"
                  placeholder="Email"
                  variant="standard"
                  // InputProps={{
                  //   readOnly: true,
                  // }}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) =>
                    handleChangeFormData(e?.target?.value, "email")
                  }
                />
              </Grid>
              {/* DOB */}
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      format={DATE_FORMAT}
                      slotProps={{
                        textField: {
                          size: "small",
                          variant: "standard",
                          InputLabelProps: { shrink: true },
                          className: "inputTextField",
                        },
                      }}
                      value={formData?.dob ? new Date(formData?.dob) : null}
                      label="Date Of Birth"
                      onChange={(e) => {
                        let isoDate = changeDateFormatToISO(e);
                        handleChangeFormData(
                          e !== "Invalid Date" || e !== null ? isoDate : e,
                          "dob"
                        );
                      }}
                      renderInput={(params) => (
                        <TextField {...params} size="small" />
                      )}
                      className="normal_normal_600_12_Manropee outlined_border"
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>

              {/* Source Type */}
              <Grid item xs={4}>
                <FormControl
                  fullWidth
                  className="border_color normal_normal_600_12_Manropee"
                  required
                  error={isError && !formData?.sourceTypeLookupId}
                >
                  <InputLabel
                    className="normal_normal_600_12_Manrope"
                    shrink={true}
                    style={{
                      lineHeight: "2.0375",
                      marginLeft: "-12px",
                      color: "#00ce3f",
                    }}
                  >
                    Campaign Type
                  </InputLabel>
                  <Select
                    className="formControlField"
                    value={formData?.sourceTypeLookupId}
                    label="Campaign Type"
                    placeholder="Select Campaign type"
                    onChange={(e) =>
                      handleChangeFormData(
                        e?.target?.value,
                        "sourceTypeLookupId"
                      )
                    }
                    variant="standard"
                  >
                    {sourceType?.length > 0 &&
                      sourceType.map((d) => (
                        <MenuItem
                          className="normal_normal_600_12_Manrope_DropDown_Menu"
                          value={d?.id}
                        >
                          {d?.name}
                        </MenuItem>
                      ))}
                  </Select>
                  {isError && !formData?.sourceTypeLookupId && (
                    <FormHelperText className="red">
                      {VALIDATION_MSG?.REQUIRED}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              {/* Source Sub Type */}

              <Grid item xs={4}>
                <TextField
                  value={`${formData?.leadCreator?.firstName || "NA"} ${
                    formData?.leadCreator?.lastName || "NA"
                  }`}
                  // error={isError && !formData?.sourceSubType}
                  // helperText={
                  //   isError && !formData?.sourceSubType && VALIDATION_MSG?.REQUIRED
                  // }
                  // required
                  fullWidth
                  className="inputTextField border_color normal_normal_600_12_Manropee"
                  label="Campaign Sub Type"
                  placeholder="Campaign Sub Type"
                  variant="standard"
                  InputLabelProps={{ shrink: true, readOnly: true }}
                  onChange={(e) =>
                    handleChangeFormData(e?.target?.value, "sourceSubType")
                  }
                />
              </Grid>
              <Grid item xs={8}></Grid>

              {/* ---------ADDRESS------------ */}
              {/* Street 1 */}
              <Grid item xs={12}>
                <Typography className="normal_normal_600_12_Manrope green">
                  Address
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  value={formData?.address?.street1}
                  // error={isError && !formData?.postalCode}
                  // helperText={
                  //   isError && !formData?.postalCode && VALIDATION_MSG?.REQUIRED
                  // }
                  fullWidth
                  className="inputTextField border_color normal_normal_600_12_Manropee"
                  label=""
                  placeholder="Street 1"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) =>
                    handleChangeFormData(e?.target?.value, "address.street1")
                  }
                />
              </Grid>
              {/* Street 2 */}
              <Grid item xs={4}>
                <TextField
                  value={formData?.address?.street2}
                  // error={isError && !formData?.postalCode}
                  // helperText={
                  //   isError && !formData?.postalCode && VALIDATION_MSG?.REQUIRED
                  // }
                  fullWidth
                  className="inputTextField border_color normal_normal_600_12_Manropee"
                  label=""
                  placeholder="Street 2"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) =>
                    handleChangeFormData(e?.target?.value, "address.street2")
                  }
                />
              </Grid>

              {/* City */}
              <Grid item xs={4}>
                <TextField
                  value={formData?.address?.city}
                  // error={isError && !formData?.city}
                  // helperText={
                  //   isError && !formData?.city && VALIDATION_MSG?.REQUIRED
                  // }
                  fullWidth
                  className="inputTextField border_color normal_normal_600_12_Manropee"
                  label=""
                  placeholder="City"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) =>
                    handleChangeFormData(e?.target?.value, "address.city")
                  }
                />
              </Grid>
              {/* County */}
              <Grid item xs={4}>
                <TextField
                  value={formData?.address?.county}
                  // error={isError && !formData?.county}
                  // helperText={
                  //   isError && !formData?.county && VALIDATION_MSG?.REQUIRED
                  // }
                  fullWidth
                  className="inputTextField border_color normal_normal_600_12_Manropee"
                  label=""
                  placeholder="County"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) =>
                    handleChangeFormData(e?.target?.value, "address.county")
                  }
                />
              </Grid>
              {/* Country */}
              <Grid item xs={4}>
                <TextField
                  value={formData?.address?.country}
                  // error={isError && !formData?.country}
                  // helperText={
                  //   isError && !formData?.country && VALIDATION_MSG?.REQUIRED
                  // }
                  fullWidth
                  className="inputTextField border_color normal_normal_600_12_Manropee"
                  label=""
                  placeholder="Country"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) =>
                    handleChangeFormData(e?.target?.value, "address.country")
                  }
                />
              </Grid>

              {/* Postal Code */}
              <Grid item xs={4}>
                <TextField
                  value={formData?.postalCode || formData?.address?.zip || ""}
                  // error={isError && !formData?.country}
                  // helperText={
                  //   isError && !formData?.country && VALIDATION_MSG?.REQUIRED
                  // }
                  fullWidth
                  className="inputTextField border_color normal_normal_600_12_Manropee"
                  label=""
                  placeholder="Post Code"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) =>
                    handleChangeFormData(e?.target?.value, "postalCode")
                  }
                />
              </Grid>
              <Grid item xs={4}></Grid>

              {/*  Added Since */}

              <Grid
                item
                xs={4}
                display={"flex"}
                flexDirection={"column"}
                gap={0.75}
              >
                <Grid className="normal_normal_600_10_Manropee">
                  Added Since
                </Grid>
                <Grid className="normal_normal_16_Manrope">
                  {dateDiff} - {formattedDate}
                </Grid>
              </Grid>

              {/*  Ongoing Instruction */}

              <Grid
                item
                xs={4}
                display={"flex"}
                flexDirection={"column"}
                gap={0.75}
              >
                <Grid className="normal_normal_600_10_Manropee">
                  Ongoing Instruction
                </Grid>
                <Grid className="normal_normal_16_Manrope">
                  {formData?.onGoingInstructions || 0}
                </Grid>
              </Grid>
            </Grid>

            {/* Save Button */}

            {userDetails?.role !== USER_ROLE?.INTRODUCER ? (
              <Grid item xs={11} m={2} className="">
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                  border={0}
                >
                  <Button
                    className="save_button"
                    onClick={handleUpdateClientDialog}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UpdateClient;
