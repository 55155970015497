import { Grid } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RadioInput } from "../../../../../components/inputs";

const BasicDetails = () => {
  const isJoint = useSelector((state) => state?.instruction?.isJoint);
  const arr = useSelector((state) => state?.instruction?.arr);
  const [edit, setEdit] = useState(arr.length > 1 ? [false, false] : [false]);

  const handleEdit = (clientNumber, value) => {
    setEdit((prev) => {
      let temp = prev;
      temp[clientNumber] = value;
      return [...temp];
    });
  };
  return (
    <>
      <Grid width={"100%"} display={"flex"} flexDirection={"row"} mt={10}>
        {arr?.map((item, index) => {
          return (
            <Grid
              width={isJoint ? "50%" : "100%"}
              borderTop={`1px solid #DCDCE1`}
              borderBottom={`1px solid #DCDCE1`}
              borderRight={index === 0 ? `1px solid #DCDCE1` : null}
              paddingY={4}
              display={"flex"}
              flexDirection={"column"}
              gap={3}
              paddingRight={index === 0 ? 2 : 0}
              paddingLeft={index === 1 ? 2 : 0}
            >
              <Grid item xs={12}>
                <RadioInput
                  orientation="row"
                  label={`Have you considered a Health and Welfare LPA instead which provides greater flexibility?`}
                  name={`InstructionLivingWillQuestionnaire.[${index}].isConsideredHealthWelfare`}
                  options={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default BasicDetails;
