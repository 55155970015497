import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import {
  downloadDocument,
  downloadEmailDocument,
  getModifiedFileName,
} from "../../../utils/util";
import moment from "moment";
import { ContainedGreenButton } from "../../../components/buttons";

const FullEmailDialog = ({
  emailData,
  showFullEmail,
  setShowFullEmail,
  clientData,
  isClientEmail = false,
}) => {
  let activeEmail = emailData;
  return (
    <Dialog
      open={showFullEmail}
      onClose={() => setShowFullEmail(false)}
      maxWidth={"md"}
      fullWidth
      scroll="paper"
      id="instruction-email"
    >
      <DialogTitle
        style={{ textAlign: "center" }}
        className="serif_display_regular_26 green"
      >
        Email
      </DialogTitle>
      <DialogContent p={2}>
        <Grid item xs={12} direction={"column"} container rowSpacing={2}>
          <Grid item>
            <Typography className="normal_normal_600_14_Manrope green">
              From
            </Typography>
            <Typography className="normal_normal_600_14_Manrope dark_grey">
              {activeEmail?.from}
            </Typography>

            <Typography className="normal_normal_600_14_Manrope">
              {moment(new Date(activeEmail?.createdAt))?.format(
                "DD/MM/YY hh:mm A"
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className="normal_normal_600_14_Manrope green">
              To
            </Typography>
            <Typography className="normal_normal_600_14_Manrope">
              {activeEmail?.to?.join(", ")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className="normal_normal_600_14_Manrope green">
              Subject
            </Typography>
            <Typography
              className=" normal_normal_600_14_Manrope dark_grey"
              component={"span"}
            >
              {activeEmail?.subject}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className="normal_normal_600_14_Manrope green">
              Message
            </Typography>
            <Box dangerouslySetInnerHTML={{ __html: activeEmail?.html }} />
          </Grid>
          <Grid item>
            <Typography className="normal_normal_600_14_Manrope green">
              Attachments
            </Typography>

            {activeEmail?.attachments?.length > 0 && (
              <>
                {activeEmail?.attachments?.map((file, j) => {
                  return (
                    <Chip
                      style={{ marginRight: "10px" }}
                      key={`attachment${j}`}
                      label="Attachment"
                      onClick={() => downloadEmailDocument(file)}
                    />
                  );
                })}
              </>
            )}

            {!isClientEmail ? (
              activeEmail?.InstructionTaskDocuments?.length
            ) : activeEmail?.LeadClientDocuments?.length ? (
              <Box paddingBottom={1}>
                <hr />
                <Box display={"flex"} columnGap={1} pt={1} gap={1}>
                  {(!isClientEmail
                    ? activeEmail?.InstructionTaskDocuments
                    : activeEmail?.LeadClientDocuments
                  )?.map((file, j) => {
                    return (
                      <Chip
                        key={`attachment${j}`}
                        label={
                          file?.fileInfo?.modifiedFileName ||
                          getModifiedFileName(file?.documentUrl) ||
                          "NA"
                        }
                        onClick={() => downloadDocument(file, clientData, "", isClientEmail)}
                      />
                    );
                  })}
                </Box>
              </Box>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: 2, borderTop: "1px solid" }}>
        <ContainedGreenButton onClick={setShowFullEmail} label={"OK"} />
      </DialogActions>
    </Dialog>
  );
};

export default FullEmailDialog;
