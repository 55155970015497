import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import getAxios from "../helpers/axiosInterceptor";
import { API_ROUTES_PATHS } from "../utils/constant";

// Method for user login API
export const userLogin = createAsyncThunk(
  "login/userLogin",
  async (model, thunkApi) => {
    try {
      const { email, password } = model;
      let res = await getAxios().post(API_ROUTES_PATHS.LOGIN, {
        email: email,
        password: password,
      });

      const responseData = res.data;
      Cookies.set("jwt-token", responseData.access_token);
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get login user details API
export const getLoginUser = createAsyncThunk(
  "login/getLoginUser",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(API_ROUTES_PATHS.GET_USER_ME);
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get user details in manage profile screen
export const getManageProfileUser = createAsyncThunk(
  "login/getManageProfileUser",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(API_ROUTES_PATHS.GET_USER_SHOW_ME);
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method for verify email API
export const verifyEmailAction = createAsyncThunk(
  "login/verifyEmailAction",
  async (arg, thunkApi) => {
    try {
      const { email } = arg;
      const response = await getAxios().post(API_ROUTES_PATHS.VERIFY_EMAIL, {
        email: email,
      });
      const responseData = response.data;
      localStorage.setItem(
        "userEmail",
        JSON.stringify(responseData.data.email)
      );
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to verify otp API
export const verifyOtpAction = createAsyncThunk(
  "login/verifyOtpAction",
  async (arg, thunkApi) => {
    try {
      const { otp, email } = arg;
      const response = await getAxios().post(API_ROUTES_PATHS.VERIFY_OTP, {
        otp: otp,
        email: email,
      });
      const responseData = response.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to reset password API
export const resetPasswordAction = createAsyncThunk(
  "login/resetPasswordAction",
  async (arg, thunkApi) => {
    try {
      const { password, email } = arg;
      const response = await getAxios().post(API_ROUTES_PATHS.RESET_PASSWORD, {
        password: password,
        email: email,
      });
      const responseData = response.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to generate XERO invoice API
export const xeroPay = createAsyncThunk(
  "xero/xeroPay",
  async (model, thunkApi) => {
    try {
      const response = await getAxios().post(
        `${API_ROUTES_PATHS.XERO_PAY}/${model?.id}/xero`,
        model
      );
      const responseData = response.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to generate calendly user ID
export const genrateCalendlyUserID = createAsyncThunk(
  "login/getManageProfileUser",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().patch(
        API_ROUTES_PATHS.GENERATE_CALENDLY_USER_ID
      );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);
