/* eslint-disable jsx-a11y/alt-text */
import { Grid, Typography } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import { SingleCheckbox } from "../../../../../components/inputs";

const LPADeclaration = ({ arr, isJoint }) => {
  let { getValues } = useFormContext();

  let signature = (pathName) => {
    return getValues(pathName) || "";
  };
  return (
    <Grid sx={{ pl: "10px" }}>
      <Grid
        display={"flex"}
        flexDirection={"row"}
        sx={{
          marginRight: "5px",
          width: "70%",
          alignItems: "flex-start",
          verticalAlign: "top",
        }}
      >
        <SingleCheckbox
          name="InstructionLPA_Questionnaire.[0].declaration"
          label=""
          border="1px solid red"
        />
        <div style={{ fontWeight: "bold", color: "#3D4740" }}>
          I hereby declare that, in my opinion, the donor has the capacity to
          create a Lasting Power of Attorney in accordance with s.2 of the
          Mental capacity Act 2005 and under Banks V Goodfellow 1871
        </div>
      </Grid>
      <Grid
        display={"flex"}
        flexDirection={"row"}
        gap={"7%"}
        width={"100%"}
        mt={4}
      >
        {arr?.map((itemNo, sigIndex) => {
          return (
            <Grid
              display={"flex"}
              flexDirection={"column"}
              gap={2}
              width={"35%"}
            >
              <Typography sx={{ color: "#00CE3F", fontSize: "12px" }}>
                Signature to declare Donor {itemNo}
              </Typography>
              <Grid
                sx={{
                  border: `1px solid #DCDCE1`,
                  borderRadius: "10px",
                  height: "150px",
                  width: "100%",
                }}
              >
                <img
                  style={{ width: "100%", objectFit: "contain" }}
                  src={signature(
                    `InstructionLPA_Questionnaire.${sigIndex}.signature`
                  )}
                />{" "}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default LPADeclaration;
