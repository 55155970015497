import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { InlineWidget } from "react-calendly";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { showSnackbar } from "../../slice/snackbarSlice";
import { getConsultant } from "../../thunks/user";
import { USER_ROLE } from "../../utils/constant";

const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state?.auth?.currentUser);
  const [consultants, setConsultants] = useState([]);
  const [isConsultantUrl, setIsConsultantUrl] = useState(false);
  const [scheduling_url, setScheduling_url] = useState(
    userDetails?.scheduling_url
  );
  // Method to fetch all consultants.
  const getAllConsultants = async () => {
    try {
      const response = await dispatch(getConsultant()).unwrap();
      if (response?.length > 0) {
        setConsultants(response);
      } else {
        setConsultants([]);
      }
    } catch (error) {}
  };

  const handleChangeConsultant = (value) => {
    if (value?.scheduling_url === null) {
      setIsConsultantUrl(true);
      dispatch(
        showSnackbar({
          show: true,
          message: "Calendly ID not Found",
          severity: "error",
        })
      );
    } else {
      setIsConsultantUrl(false);
      if (value) {
        setScheduling_url(value?.scheduling_url);
      } else {
        setScheduling_url(userDetails?.scheduling_url);
      }
    }
  };

  useEffect(() => {
    getAllConsultants();
  }, []);

  return (
    <Grid container spacing={3} mt={1}>
      {userDetails?.role === USER_ROLE?.SUPER_ADMIN ||
      userDetails?.role === USER_ROLE?.OFFICE_ADMIN ? (
        <>
          <Grid
            item
            xs={5}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Typography className="green">
              Setup meeting for consultant
            </Typography>
          </Grid>

          <Grid item xs={2}>
            <Autocomplete
              options={consultants || []}
              getOptionLabel={(option) =>
                `${option?.firstName || ""} ${option?.lastName || ""}`
              }
              onChange={(event, value) => handleChangeConsultant(value)}
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  {...params}
                  className="normal_normal_medium_14_Manropee inputTextField border_color"
                  size="small"
                  label="Consultant"
                  placeholder="Select consultant"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: 24,
                      borderRadius: "24px",
                    },
                  }}
                />
              )}
            />
          </Grid>
        </>
      ) : null}

      {scheduling_url ? (
        <Grid item xs={12}>
          <InlineWidget
            url={scheduling_url || "NA"}
            prefill={{
              firstName: location?.state?.data?.firstName,
              lastName: location?.state?.data?.lastName,
              email: location?.state?.data?.email,
              customAnswers: {
                a1: Number(location?.state?.data?.mobileNo),
              },
            }}
          />
        </Grid>
      ) : (
        <Grid
          item
          xs={12}
          mt={4}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {!isConsultantUrl && (
            <Typography className="serif_display_regular_18">
              Calendly ID is not updated.
              <span
                style={{
                  marginLeft: "1rem",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                className="serif_display_regular_18 green"
                onClick={() => navigate("/app/profile")}
              >
                Please click here to update information
              </span>
            </Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default Dashboard;
