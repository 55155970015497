import SearchIcon from "@mui/icons-material/Search";
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DataGridComponent from "../../../components/DataGridComponent";
import Loader from "../../../components/Loader";
import PaginationComponent from "../../../components/PaginationComponent";
import { getCommission } from "../../../thunks/commission";
import { getLookups, getMilestone } from "../../../thunks/lookup";
import { USER_ROLE } from "../../../utils/constant";

const CommissionList = () => {
  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state?.auth?.currentUser);
  const commissionStatus = useSelector(
    (state) => state?.lookupData?.["Commission Status"]
  );

  // states
  const [commissionData, setCommissionData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoader, setLoader] = useState(false);
  const [timer, setTimer] = useState(null);

  const [filterPayload, setFilterPayload] = useState({
    page: 1,
    limit: 10,
    orderByOnCreatedAt: "asc",
    instructionMilestoneId: null,
    commissionStatusId: null,
    search: "",
  });

  /* Columns_Introducer for commission list */
  const columns_INTRODUCER = [
    {
      field: "name",
      headerName: "Name Of Instruction",
      minWidth: 450,
      sortable: false,
      renderCell: (params) => (
        <Grid style={{ display: "flex", flexDirection: "column" }}>
          <Typography>{`${params?.row?.InstructionLeadClient?.[0]?.firstName} ${params?.row?.InstructionLeadClient?.[0]?.lastName}`}</Typography>
          {params?.row?.InstructionLeadClient?.length > 1 && (
            <Typography>{`${params?.row?.InstructionLeadClient?.[1]?.firstName} ${params?.row?.InstructionLeadClient?.[1]?.lastName}`}</Typography>
          )}
        </Grid>
      ),
    },
    {
      field: "ownerOfInstruction",
      headerName: "Consultant",
      minWidth: 160,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {`${params?.row?.ownerOfInstruction?.firstName || "NA"} ${
              params?.row?.ownerOfInstruction?.lastName || "NA"
            }`}
          </>
        );
      },
    },
    {
      field: "sourceSubType",
      headerName: "Introducer",
      minWidth: 200,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {`${params?.row?.leadCreator?.firstName || "NA"} ${
              params?.row?.leadCreator?.lastName || "NA"
            }`}
          </>
        );
      },
    },
    {
      field: "commissionName",
      headerName: "Commission",
      minWidth: 200,
      sortable: false,
      align: "center",
      renderCell: (params) => (
        <Typography>
          £
          {` ${
            Number(params?.row?.InstructionCommissions?.[0]?.amount)?.toFixed(
              2
            ) || 0
          }`}
        </Typography>
      ),
    },

    {
      field: "commissionStatus",
      headerName: "Status",
      minWidth: 100,
      sortable: false,
      renderCell: (params) => (
        <Typography>
          {params?.row?.InstructionCommissions?.[0]?.status || "NA"}
        </Typography>
      ),
    },
  ];

  /* Columns_Consultant for commission list */
  const columns_CONSULTANT = [
    {
      field: "name",
      headerName: "Name Of Instruction",
      minWidth: 450,
      sortable: false,
      renderCell: (params) => (
        <Grid style={{ display: "flex", flexDirection: "column" }}>
          <Typography>{`${params?.row?.InstructionLeadClient?.[0]?.firstName} ${params?.row?.InstructionLeadClient?.[0]?.lastName}`}</Typography>
          {params?.row?.InstructionLeadClient?.length > 1 && (
            <Typography>{`${params?.row?.InstructionLeadClient?.[1]?.firstName} ${params?.row?.InstructionLeadClient?.[1]?.lastName}`}</Typography>
          )}
        </Grid>
      ),
    },
    {
      field: "ownerOfInstruction",
      headerName: "Consultant",
      minWidth: 160,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {`${params?.row?.ownerOfInstruction?.firstName || "NA"} ${
              params?.row?.ownerOfInstruction?.lastName || "NA"
            }`}
          </>
        );
      },
    },
    {
      field: "sourceSubType",
      headerName: "Introducer",
      minWidth: 200,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {`${params?.row?.leadCreator?.firstName || "NA"} ${
              params?.row?.leadCreator?.lastName || "NA"
            }`}
          </>
        );
      },
    },
    {
      field: "commissionName",
      headerName: "Instructed Commission",
      minWidth: 200,
      sortable: false,
      align: "center",
      renderCell: (params) => (
        <Typography>
          £
          {` ${
            Number(params?.row?.InstructionCommissions?.[0]?.amount)?.toFixed(
              2
            ) || 0
          }`}
        </Typography>
      ),
    },

    {
      field: "commissionStatus",
      headerName: "Status",
      minWidth: 100,
      sortable: false,
      renderCell: (params) => (
        <Typography>
          {params?.row?.InstructionCommissions?.[0]?.status || "NA"}
        </Typography>
      ),
    },

    {
      field: "commissionName1",
      headerName: "Attested Commission",
      minWidth: 200,
      sortable: false,
      align: "center",
      renderCell: (params) => (
        <Typography>
          £
          {Number(params?.row?.InstructionCommissions?.[1]?.amount)?.toFixed(
            2
          ) || 0}
        </Typography>
      ),
    },
    {
      field: "commissionStatus1",
      headerName: "Status",
      minWidth: 200,
      sortable: false,
      renderCell: (params) => (
        <Typography>
          {params?.row?.InstructionCommissions?.[1]?.status || "NA"}
        </Typography>
      ),
    },
  ];

  /* Method to get commission data */
  const getCommissionData = async (payload = filterPayload) => {
    if (userDetails?.role === USER_ROLE.INTRODUCER) {
      if (!userDetails?.isDashBoard) {
        return navigate("/app/dashboard");
      }
    }
    try {
      setLoader(true);
      const response = await dispatch(getCommission(payload)).unwrap();
      // Redirect to dashboard if user is not campaign head or commission applicable
      setCommissionData(response?.findAllInstructions);
      setTotalCount(response?.recordLength);

      setLoader(false);
    } catch (error) {
      setCommissionData([]);
      setLoader(false);
    }
  };

  /* Method to get commission status */
  const getCommissionStatus = async () => {
    try {
      if (!commissionStatus?.length) {
        await dispatch(
          getLookups({ searchStrings: JSON.stringify(["Commission Status"]) })
        );
      }
    } catch (error) {}
  };

  /* Method to get milestone */
  const getMilestoneType = async () => {
    try {
      const response = await dispatch(getMilestone()).unwrap();
    } catch (error) {}
  };

  // Functionality for filter milestone
  const handleChangeMilestone = (value, key) => {
    const selectedRole = value === null ? null : value;

    setFilterPayload({
      ...filterPayload,
      instructionMilestoneId: selectedRole,
    });

    const payload = {
      ...filterPayload,
      instructionMilestoneId: selectedRole,
    };
    getCommissionData(payload);
  };

  /* Method to change page --> Pagination */
  const handleChangePage = (event, value) => {
    const payload = {
      ...filterPayload,
      page: value,
    };
    setFilterPayload(payload);
    getCommissionData(payload);
  };

  /* Method to filter  --> Status */
  const handleChangeFilter = (key, value) => {
    const payload = {
      ...filterPayload,
      [key]: value,
    };
    setFilterPayload(payload);
    getCommissionData(payload);
  };

  /* Method to filter  --> Name */

  const handleChangeName = (value) => {
    const payload = {
      ...filterPayload,
      page: 1,
      search: value,
    };

    setFilterPayload(payload);

    if (value?.length >= 3) {
      clearTimeout(timer);
      const newTimer = setTimeout(() => {
        getCommissionData(payload);
      }, 500);
      setTimer(newTimer);
    } else if (value?.length === 0) {
      clearTimeout(timer);
      const newTimer = setTimeout(() => {
        getCommissionData(payload);
      }, 500);
      setTimer(newTimer);
    }
  };

  useEffect(() => {
    getCommissionData();
    getMilestoneType();
    getCommissionStatus();
  }, []);

  return (
    <>
      {/* Commission Dialog Component */}

      <Grid container spacing={2} style={{ marginTop: "0px" }} mb={2}>
        {/* Loader Component */}
        {isLoader && <Loader />}

        {/* Search container */}
        <Grid item xs={4}>
          <TextField
            fullWidth
            placeholder="Search"
            size="small"
            className="textField_outlined_border"
            value={filterPayload?.search}
            onChange={(e) => handleChangeName(e?.target?.value)}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "30px",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        {/* Milestone */}
        {/* 
        <Grid item xs={4}>
          <FormControl
            fullWidth
            size="small"
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "30px",
              },
            }}
          >
            <InputLabel className="normal_normal_medium_14_Manrope dark_grey">
              Milestone
            </InputLabel>
            <Select
              className="textField_outlined_border"
              label="Milestone"
              onChange={(e) =>
                handleChangeMilestone(
                  e?.target?.value,
                  "instructionMilestoneId"
                )
              }
            >
              <MenuItem>--</MenuItem>
              {milestone?.length > 0 &&
                milestone.map((d) => (
                  <MenuItem
                    key={d?.id}
                    value={d?.id}
                    className="normal_normal_medium_14_Manrope dark_grey"
                  >
                    {d?.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid> */}

        {/* Commission Status */}

        <Grid item xs={4}>
          <FormControl
            fullWidth
            size="small"
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "30px",
              },
            }}
          >
            <InputLabel className="normal_normal_medium_14_Manrope dark_grey">
              Status
            </InputLabel>
            <Select
              className="textField_outlined_border"
              label="Status"
              onChange={(e) =>
                handleChangeFilter("commissionStatusId", e?.target?.value)
              }
            >
              <MenuItem>--</MenuItem>
              {commissionStatus?.length > 0 &&
                commissionStatus.map((d) => (
                  <MenuItem
                    key={d?.id}
                    value={d?.id}
                    className="normal_normal_medium_14_Manrope dark_grey"
                  >
                    {d?.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Commissions List  Table  */}
        <Grid item xs={12} style={{ width: "100%", height: "75vh" }}>
          <DataGridComponent
            columns={
              userDetails?.role === USER_ROLE.CONSULTANT
                ? columns_CONSULTANT
                : columns_INTRODUCER
            }
            rows={commissionData || []}
            onRowClick={() => null}
          />
        </Grid>

        {/* PAGINATION */}
        <PaginationComponent
          totalCount={totalCount}
          limit={filterPayload?.limit}
          page={filterPayload?.page}
          onChange={handleChangePage}
        />
      </Grid>
    </>
  );
};
export default CommissionList;
