import { Grid, Pagination } from "@mui/material";
import React from "react";

const PaginationComponent = ({
  totalCount,
  limit,
  page,
  onChange,
  siblingCount = 1,
  boundaryCount = 1,
  justifyContent = "flex-end",
}) => {
  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: justifyContent,
          alignItems: "center",
        }}
      >
        <Pagination
          count={Math.ceil(totalCount / limit)}
          onChange={onChange}
          page={page}
          siblingCount={siblingCount}
          boundaryCount={boundaryCount}
        />
      </Grid>
    </>
  );
};

export default PaginationComponent;
