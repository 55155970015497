import ConsultantCommissionIcon from "../../../assets/images/consultant_commission_icon.svg";
import ConsultantDashboardIcon from "../../../assets/images/consultant_dashboard_icon.svg";
import loginUser from "../../../assets/images/loginUser_icon.png";

const introducerMenu = [
  {
    key: "routekey-home",
    label: "sidebar.dashboard",
    path: "/app/dashboard",
    icon: ConsultantDashboardIcon,
    vissbleOnSmallScrren: true,
  },
  {
    key: "routekey-leads",
    label: "sidebar.clients",
    path: "/app/client/list",
    icon: loginUser,
    vissbleOnSmallScrren: true,
  },
  {
    key: "routekey-commissions-introducer",
    label: "sidebar.commissions",
    path: "/app/commissions/list",
    icon: ConsultantCommissionIcon,
    vissbleOnSmallScrren: true,
  },
];

export default introducerMenu;
