import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../../assets/images/BUTTON_Back.svg";
import Loader from "../../../../components/Loader";
import "../../../../custom-bootstrap.scss";
import { showSnackbar } from "../../../../slice/snackbarSlice";
import { createEmailTemplate } from "../../../../thunks/email_template";
import {
  NOTIFICATION_MESSAGE,
  VALIDATION_MSG,
} from "../../../../utils/constant";
import { formValidation } from "./formValidation";
import JoditEditotRichText from "../../../../components/JoditEditotRichText";

const CreateEmailTemplate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isError, setIsError] = useState(false);

  const lookups = useSelector((state) => state?.lookupData);
  let { Role, "Template Type": templateType } = lookups || {};

  const [emailTemplateData, setEmailTemplateData] = useState({
    name: "",
    html: "",
    description: "",
    isActive: true,
    templatelookupId: null,
    rolesAccessIds: [],
  });

  const [isLoader, setLoader] = useState(false);

  /* Method to change form fields */
  const handleChangeFormFields = (value, key) => {
    setEmailTemplateData({ ...emailTemplateData, [key]: value });
  };

  const htmlRef = useRef(null);

  /* Method to create emailTemplate */
  const handleCreateEmailTemplate = async () => {
    // let htmlStr = htmlRef.current.getContent();
    // let payload = { ...emailTemplateData, html: htmlStr };
    let payload = { ...emailTemplateData };
    setEmailTemplateData(payload); // to update html prop
    const isValidForm = formValidation(payload);
    if (!isValidForm) {
      setIsError(true);
    } else {
      setIsError(false);
      try {
        setLoader(true);
        const response = await dispatch(createEmailTemplate(payload)).unwrap();
        if (response.id) {
          dispatch(
            showSnackbar({
              message: NOTIFICATION_MESSAGE?.EMAIL_TEMPLATE_CREATE_SUCCESS,
              severity: "success",
            })
          );
          navigate("../list");
        }
      } catch (error) {
        dispatch(
          showSnackbar({
            message: `${error?.message}`,
            severity: "error",
          })
        );
        navigate("../list");
      } finally {
        setLoader(false);
      }
    }
  };

  return (
    <>
      {/* Loader Component */}
      {isLoader && <Loader />}
      {/* Form Layout */}
      <Grid
        container
        spacing={2}
        rowGap={3}
        justify="flex-start"
        className="create-user instruction_dashboard_scrollbar"
        style={{
          height: "90vh",
          overflow: "auto",
        }}
        pl={5}
        pr={5}
      >
        {/* ADD NEW  Email Template Button */}
        <Grid item xs={12}>
          <Typography className="serif_display_regular_26">
            <img
              src={BackButton}
              onClick={() => navigate("../list")}
              style={{
                cursor: "pointer",
                marginRight: "10px",
              }}
            />
            Add New Email Template
          </Typography>
        </Grid>

        {/* EmailTemplate Name */}
        <Grid item xs={4} mt={0.5}>
          <TextField
            required
            className="inputTextField normal_normal_600_12_Manropee"
            label="Name"
            placeholder="Name"
            fullWidth
            size="small"
            error={isError && !emailTemplateData?.name}
            helperText={
              isError && !emailTemplateData?.name && VALIDATION_MSG.REQUIRED
            }
            value={emailTemplateData?.name}
            onChange={(e) => handleChangeFormFields(e?.target?.value, "name")}
            InputLabelProps={{ shrink: true }}
            variant="standard"
          />
        </Grid>

        {/* EmailTemplate Description */}
        <Grid item xs={5}>
          <TextField
            className="inputTextField normal_normal_600_12_Manropee"
            fullWidth
            label="Description"
            placeholder="Description"
            multiline
            maxRows={4}
            value={emailTemplateData?.description}
            onChange={(e) =>
              handleChangeFormFields(e?.target?.value, "description")
            }
            InputLabelProps={{ shrink: true }}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className="inputTextField normal_normal_600_12_Manropee"
            fullWidth
            label="Subject"
            placeholder="Subject"
            multiline
            maxRows={4}
            value={emailTemplateData?.subject}
            onChange={(e) =>
              handleChangeFormFields(e?.target?.value, "subject")
            }
            InputLabelProps={{ shrink: true }}
            variant="standard"
          />
        </Grid>

        {/* Role and Template type section  */}
        <Grid container xs={12} sx={{ marginLeft: 2, gap: 3 }}>
          {/* tmplate type  */}
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel
                shrink={true}
                style={{
                  lineHeight: "2.0375",
                  marginLeft: "-12px",
                  color: "#01ce3f",
                }}
              >
                Template Type
              </InputLabel>
              <Select
                className="formControlField"
                variant="standard"
                size="small"
                name="templatetype"
                value={emailTemplateData?.templatelookupId || ""}
                onChange={(event) =>
                  handleChangeFormFields(
                    event?.target?.value,
                    "templatelookupId"
                  )
                }
                fullWidth
              >
                {templateType?.map((d) => (
                  <MenuItem
                    className="normal_normal_18_Manrope dark_grey"
                    value={d?.id}
                    key={d?.id}
                  >
                    {d?.label}
                  </MenuItem>
                ))}
              </Select>
              {isError && !emailTemplateData?.templatelookupId && (
                <FormHelperText className="red">
                  {VALIDATION_MSG.REQUIRED}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          {/* Role  */}
          <Grid item xs={5}>
            <Autocomplete
              multiple
              options={Role || []}
              getOptionLabel={(option) => option?.label}
              onChange={(event, value) => {
                const ids = value?.map((option) => option?.id);
                handleChangeFormFields(ids, "rolesAccessIds");
              }}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  className="normal_normal_600_12_Manropee"
                  size="small"
                  label="Select Roles"
                  placeholder="Select Roles"
                />
              )}
            />

            {/* Display Selected Roles Below the Autocomplete
            {emailTemplateData?.rolesAccessIds?.length > 0 ? (
              <Grid item xs={12} mt={2}>
                <Grid
                  style={{
                    border: "1px solid #dcdce1",
                    borderRadius: "6px",
                    padding: "3px",
                  }}
                >
                  {emailTemplateData?.rolesAccessIds?.map((id) => {
                    const role = lookupData?.role?.find(
                      (option) => option?.id === id
                    );
                    return (
                      <Chip
                        style={{ margin: 6 }}
                        key={id}
                        label={role?.label || "NA"}
                      />
                    );
                  })}
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12} mt={2}>
                <Typography>No roles added.</Typography>
              </Grid>
            )} */}

            {isError && emailTemplateData?.rolesAccessIds?.length <= 0 && (
              <FormHelperText className="red">
                {VALIDATION_MSG.REQUIRED}
              </FormHelperText>
            )}
          </Grid>
        </Grid>
        {/* template editor */}
        <Grid xs={12} px={2}>
          <Typography color="#00CE3F" mb={1}>
            Content *
          </Typography>
          {/* <RichTextEditor
            initialValue={emailTemplateData?.html}
            ref={htmlRef}
            height={248}
          /> */}
          <JoditEditotRichText
            value={emailTemplateData?.html || ""}
            height={248}
            onChange={(value) => handleChangeFormFields(value, "html")}
            ref={htmlRef}
          />
          {isError && !emailTemplateData?.html && (
            <Typography color={"error"}>{VALIDATION_MSG.REQUIRED}</Typography>
          )}
        </Grid>
      </Grid>

      {/* Save EmailTemplate Button */}
      <Grid
        item
        xs={11}
        sx={{ display: "flex", justifyContent: "flex-end", pb: 2 }}
        m={1}
        className="Save_Button_Container normal_normal_600_12_Manropee"
      >
        <Button className="save_button" onClick={handleCreateEmailTemplate}>
          Save
        </Button>
      </Grid>
    </>
  );
};

export default CreateEmailTemplate;
