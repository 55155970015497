import { createAsyncThunk } from "@reduxjs/toolkit";
import getAxios from "../helpers/axiosInterceptor";
import { API_ROUTES_PATHS } from "../utils/constant";

// Method to get comminssion API
export const getCommission = createAsyncThunk(
  "commission/getCommission",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(
        API_ROUTES_PATHS.GET_INSTRUCTION_COMMISSIONS,
        model
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);
