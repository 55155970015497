import { Grid } from "@mui/material";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { RadioInput, TextInput } from "../../../../../../components/inputs";
import { findValues } from "../../../../../../utils/util";
import DDTItem from "./DDTItem";
import FlitItem from "./FlitItem";
import InheritanceTaxPlanningItem from "./InheritanceTaxPlanningItem";
import PPTItem from "./PPTItem";
import RDTItem from "./RDTItem";
import RightToOccupyItem from "./RightToOccupyItem";
import SOTDOSItem from "./SOTDOSItem";

const WillTrusts = () => {
  const isJoint = useSelector((state) => state?.instruction?.isJoint);
  const arr = useSelector((state) => state?.instruction?.arr);
  const { getValues, watch } = useFormContext();
  const deed_dropdown_values = useMemo(() => {
    let formstate = getValues();
    let includeKeys = [
      "InstructionClients.InstructionFactFind.properties.address.street",
    ];
    let list = findValues(formstate, includeKeys);
    list = list?.map((item) => ({
      value: item,
      label: item,
    }));
    return list;
  }, []);
  return (
    <>
      <Grid width={"100%"} display={"flex"} flexDirection={"column"}>
        <Grid
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={isJoint ? "space-between" : "center"}
          borderTop={`1px solid #DCDCE1`}
          borderBottom={`1px solid #DCDCE1`}
        >
          {arr?.map((item, index) => {
            return (
              <Grid
                width={isJoint ? "50%" : "62%"}
                borderRight={
                  index === 0 && isJoint ? `1px solid #DCDCE1` : null
                }
                padding={2}
                display={"flex"}
                flexDirection={"column"}
                gap={2}
              >
                <TextInput
                  label={`Reason for establishing the Will Trust`}
                  name={`InstructionWillQuestionnaire.${index}.willTrusts.willTrustEstablishmentReason`}
                  placeholder="notes field"
                />
                <RadioInput
                  label="Does the client wish to include a clause in their Will to preserve their Residence Nil Rate Band ?"
                  name={`InstructionWillQuestionnaire.${index}.willTrusts.isClauseIncluded`}
                  options={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                />
              </Grid>
            );
          })}
        </Grid>
        <Grid width={"100%"}>
          {/* FLIT */}
          <FlitItem arr={arr} isJoint={isJoint} />
          <RDTItem arr={arr} isJoint={isJoint} />
          <SOTDOSItem
            arr={arr}
            isJoint={isJoint}
            deed_dropdown_values={deed_dropdown_values}
          />
          <RightToOccupyItem arr={arr} isJoint={isJoint} />
          <PPTItem arr={arr} isJoint={isJoint} />
          <DDTItem arr={arr} isJoint={isJoint} />
          <InheritanceTaxPlanningItem arr={arr} isJoint={isJoint} />
        </Grid>
      </Grid>
    </>
  );
};

export default WillTrusts;
