import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Button,
  Divider,
  Fab,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../../../../components/Loader";
import AlertComponent from "../../../../components/alert";
import { updateTaskCompleteAndPick } from "../../../../slice/instruction";
import { showSnackbar } from "../../../../slice/snackbarSlice";
import { ClearTaskData } from "../../../../slice/task";
import {
  allocateOrCompleteTask,
  getGenericInstructionTasks,
  getInstructionProducts,
  getInstructionProductsTaskById,
} from "../../../../thunks/instruction";
import { NOTIFICATION_MESSAGE } from "../../../../utils/constant";
import CommentsDrawer from "../CommentsDrawer";
import DueDateDialog from "../DueDateDialog";
import UpdateTaskAnswerDialog from "../UpdateTaskAnswerDialog";

const TaskList = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { instructionProductId, instructionId, taskId } = useSelector(
    (state) => state?.task?.TaskTypeData
  );
  const instructionData = useSelector(
    (state) => state.instruction.instructionDashboardData
  );
  // const instructionProducts = useSelector(
  //   (state) => state?.instruction?.instructionProductList
  // );
  // Convert following to condition if TaskData.instructionProductId is null then filter with id is null else if TaskData.instructionProductId is present then filter with id else take without filter
  const instructionProducts = useSelector((state) =>
    instructionProductId
      ? state?.instruction?.instructionProductList.filter(
          (d) => d?.id === instructionProductId
        )
      : instructionProductId === null
      ? state?.instruction?.instructionProductList.filter((d) => d?.id === null)
      : state?.instruction?.instructionProductList
  );
  const instructionProductsLoader = useSelector(
    (state) => state?.instruction?.instructionProductListLoader
  );

  const instructionTasks = useSelector((state) =>
    taskId
      ? state?.instruction?.instructionTasksData.filter((d) => d?.id === taskId)
      : state?.instruction?.instructionTasksData.filter((d) => d?.isVisible)
  );
  const instructionTasksLoader = useSelector(
    (state) => state?.instruction?.instructionTaskLoader
  );

  const [isLoader, setLoader] = useState(false);
  const [selectedDueDateData, setSelectedDueDateData] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isTaskCompleted, setTaskComplete] = useState(false);
  const [completeTaskId, setCompleteTaskId] = useState(null);
  const [selectedTaskData, setSelectedTaskData] = useState(null);
  const [selectedAnswerData, setSelectedAnswerData] = useState(null);
  const [updateTaskAnswerDialog, setUpdateTaskAnswerDialog] = useState(false);
  const [isTaskPick, setTaskPick] = useState(false);
  const [pickTaskId, setPickTaskId] = useState(null);
  const [isTaskDroped, setTaskDroped] = useState(false);
  const [dropeTaskId, setDropTaskId] = useState(null);
  const [isTaskComment, setIsTaskComment] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);
  const [isOpenDueDate, setOpenDueDate] = useState(false);
  const [isViewOnly, setViewOnly] = useState(false);

  /* Method to change tabs for products list at top side */
  const handleChangeTabs = (event, newVal) => {
    dispatch(ClearTaskData());
    setTabValue(newVal);
  };

  /* Method to get instruction task once click on any product */
  const handleClickInstructionProducts = (data) => {
    setSelectedProduct(data);
    getInstructionTasks(data);
  };
  //FUNCTION TO HANDLE AUTO-REFRESH AFTER COMPLETING TASK
  const Autorefresh = async () => {
    if (!completeTaskId?.instructionProductId) {
      getGenericTasksForInstructions();
    } else {
      const payload = {
        instructionId: instructionData?.id || instructionId,
        productId: completeTaskId?.instructionProductId,
      };
      try {
        const response = await dispatch(
          getInstructionProductsTaskById(payload)
        );
      } catch (error) {
        return error;
      }
    }
  };

  /* Method to get instruction task by providing product ID as parameter
  --> If product name === Instruction it returns generic task else product basis.
  */
  const getInstructionTasks = async (data = {}) => {
    if (data?.productName === "Instruction") {
      getGenericTasksForInstructions();
    } else {
      const payload = {
        instructionId: instructionData?.id || instructionId,
        productId: data?.id,
      };
      try {
        const response = await dispatch(
          getInstructionProductsTaskById(payload)
        );
      } catch (error) {
        return error;
      }
    }
  };

  /* Method to get instruction generic task --> this is only for
  product === 'instruction'
  */
  const getGenericTasksForInstructions = async () => {
    try {
      const response = await dispatch(
        getGenericInstructionTasks(params?.id)
      ).unwrap();
    } catch (error) {}
  };

  /* Method to complete task */
  const handleClickCompleteTask = (data) => {
    setTaskComplete(true);
    setCompleteTaskId(data);
  };

  /* Method to open update tasks answer dialog 
    open once click on answer in tasks section. */
  const openUpdateTaskAnswerDialog = (answer, data, isIndividualTask) => {
    setSelectedTaskData({ ...data, isIndividualTask });
    setSelectedAnswerData(answer);
    setUpdateTaskAnswerDialog(true);
  };

  /* Method to click pick task */
  const handleClickPickTask = (data) => {
    setTaskPick(true);
    setPickTaskId(data?.id);
  };

  /* Method to drop task */
  const handleClickDropTask = (data) => {
    setTaskDroped(true);
    setDropTaskId(data?.id);
  };
  /* Method to get Task Comments */
  const viewTaskComments = (data) => {
    setSelectedTaskData({ ...data });
    // setIsTaskComment(true);
    setViewOnly(true);
    setUpdateTaskAnswerDialog(true);
  };

  /* Success method to update task status to Picked */
  const handlePickTaskSuccess = async () => {
    setTaskPick(false);
    const payload = {
      id: pickTaskId,
      status: "Picked",
    };
    try {
      setLoader(true);
      const response = await dispatch(allocateOrCompleteTask(payload)).unwrap();
      if (response) {
        setLoader(false);
        const updateTaskLocallyPayload = {
          id: pickTaskId,
          data: response,
        };
        dispatch(updateTaskCompleteAndPick(updateTaskLocallyPayload));
        dispatch(
          showSnackbar({
            show: true,
            message: NOTIFICATION_MESSAGE?.ASSIGN_TASK_SUCCESS,
            severity: "success",
          })
        );
      }
      setTaskPick(false);
    } catch (error) {
      setTaskPick(false);
      setLoader(false);
      dispatch(
        showSnackbar({
          show: true,
          message: error?.message,
          severity: "error",
        })
      );
    }
  };

  /* Success method to update task status to Completed */
  const handleCompleteTaskSuccess = async () => {
    setTaskComplete(false);
    const payload = {
      id: completeTaskId?.id,
      status: "Completed",
    };
    try {
      setLoader(true);
      const response = await dispatch(allocateOrCompleteTask(payload)).unwrap();
      if (response) {
        // calling fuction to refresh screen
        Autorefresh();
        // calling clear method to get all tasks after redirection from dashboard
        taskId && handleClearFilter();
        setLoader(false);
        const updateTaskLocallyPayload = {
          id: completeTaskId?.id,
          data: response,
        };
        dispatch(updateTaskCompleteAndPick(updateTaskLocallyPayload));
        dispatch(
          showSnackbar({
            show: true,
            message: NOTIFICATION_MESSAGE?.COMPLETE_TASK_SUCCESS,
            severity: "success",
          })
        );
      }
      setTaskPick(false);
    } catch (error) {
      setTaskPick(false);
      setLoader(false);
      dispatch(
        showSnackbar({
          show: true,
          message: error?.message,
          severity: "error",
        })
      );
    }
  };

  /* Success method to update task status to Droped */
  const handleDropTaskSuccess = async () => {
    setTaskDroped(false);
    const payload = {
      id: dropeTaskId,
      status: "Dropped",
    };
    try {
      setLoader(true);
      const response = await dispatch(allocateOrCompleteTask(payload)).unwrap();
      if (response) {
        setLoader(false);
        const updateTaskLocallyPayload = {
          id: dropeTaskId,
          data: response,
        };
        dispatch(updateTaskCompleteAndPick(updateTaskLocallyPayload));
        dispatch(
          showSnackbar({
            show: true,
            message: NOTIFICATION_MESSAGE?.DROP_TASK_SUCCESS,
            severity: "success",
          })
        );
      }
      setTaskDroped(false);
    } catch (error) {
      setTaskDroped(false);
      setLoader(false);
      dispatch(
        showSnackbar({
          show: true,
          message: error?.message,
          severity: "error",
        })
      );
    }
  };

  // Method to check date if date is greater then current date
  const checkDate = (data) => {
    // Parse the provided date into a Moment.js object
    const tempCurrentDate = moment().format("DD/MM/YYYY hh:mm A");
    const tempProvidedDate = moment(data?.dueDate || null).format(
      "DD/MM/YYYY hh:mm A"
    );
    const tempEndDate = moment(data?.endDate || null).format(
      "DD/MM/YYYY hh:mm A"
    );
    const providedDate = moment(tempProvidedDate, "DD/MM/YYYY hh:mm A");
    const currentDate = moment(tempCurrentDate, "DD/MM/YYYY hh:mm A");
    const endDate = moment(tempEndDate, "DD/MM/YYYY hh:mm A");
    // Compare the dates
    const isDateBeforeComplete = providedDate.isBefore(currentDate);
    const isDateAfterComplete = providedDate.isBefore(endDate);
    if (isDateBeforeComplete && !data?.isCompleted) {
      return true;
    } else if (isDateAfterComplete && data?.isCompleted) {
      return true;
    } else {
      return false;
    }
  };

  /* Method to open due date dialog if task status is not completed */
  const handleClickOpenDueDate = (data) => {
    let tempObj = {
      id: data.id,
      dueDate: data.dueDate,
      taskType: data?.taskType,
    };
    setSelectedDueDateData(tempObj);
    if (data?.status !== "Completed") {
      setOpenDueDate(true);
    }
  };

  const handleRedirect = () => {
    setLoader(true);
    const index = instructionProducts.findIndex(
      (product) => product.id === instructionProductId
    );
    setTabValue(index === -1 ? 0 : index);
    getInstructionProductsDataById(instructionId);
    setLoader(false);
  };

  /* Methond to get instruction product on first render called in useEffect */
  const getInstructionProductsDataById = async (instructionId) => {
    try {
      if (instructionId) {
        setLoader(true);
        const response = await dispatch(
          getInstructionProducts(instructionId)
        ).unwrap();
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  //METHOD TO CLEAR FILTERS
  const handleClearFilter = () => {
    const prodName = instructionProducts.filter(
      (product) => product.id === instructionProductId
    );
    dispatch(ClearTaskData());
    getGenericTasksForInstructions();
  };

  // Calling useEffect only received instruction product ID from dashboard
  useEffect(() => {
    if (taskId && instructionProducts?.length > 0) {
      instructionProductId && handleRedirect();
      const prodName = instructionProducts.filter(
        (product) => product.id === instructionProductId
      );
      getInstructionTasks(prodName[0]);
    } else {
      getGenericTasksForInstructions();
    }
  }, [taskId && instructionProducts?.length > 0]);

  // useEffect(() => {
  //   if (instructionProducts?.length > 0 && !instructionProductId) {
  //     getGenericTasksForInstructions();
  //   }
  // }, [instructionProducts]);

  return (
    <>
      {/* Tasks Comments Drawer */}
      {isTaskComment && (
        <CommentsDrawer
          open={isTaskComment}
          handleClose={() => setIsTaskComment(false)}
          selectedComment={selectedComment}
        />
      )}

      {/* Dialog for Pick Task Confirmation */}
      {isTaskPick && (
        <AlertComponent
          open={isTaskPick}
          handleSuccess={handlePickTaskSuccess}
          handleClose={() => setTaskPick(false)}
          message="Are you sure, want to assign the task to yourself"
        />
      )}

      {/* Dialog for Complete Task Confirmation */}
      {isTaskCompleted && (
        <AlertComponent
          open={isTaskCompleted}
          handleSuccess={handleCompleteTaskSuccess}
          handleClose={() => setTaskComplete(false)}
          message="Are you sure you want to complete the task?"
        />
      )}

      {/* Dialog for Drop Task Confirmation */}
      {isTaskDroped && (
        <AlertComponent
          open={isTaskDroped}
          handleSuccess={handleDropTaskSuccess}
          handleClose={() => setTaskDroped(false)}
          message="Are you sure you want to drop the task?"
        />
      )}

      {/* Dialog for Update task Answers */}
      {updateTaskAnswerDialog && (
        <UpdateTaskAnswerDialog
          open={updateTaskAnswerDialog}
          handleClose={() => {
            setUpdateTaskAnswerDialog(false);
            setViewOnly(false);
          }}
          selectedAnswer={selectedAnswerData}
          selectedTask={selectedTaskData}
          instructionId={instructionData?.id}
          isViewOnly={isViewOnly}
        />
      )}

      {/* Dialog for update due date */}
      {isOpenDueDate && (
        <DueDateDialog
          open={isOpenDueDate}
          handleClose={() => setOpenDueDate(false)}
          selectedData={selectedDueDateData}
        />
      )}

      {/* Loader Component */}
      {isLoader && <Loader />}
      {taskId && (
        <Tooltip title="Clear filter">
          <Fab
            color="primary"
            aria-label="add"
            style={{
              position: "absolute",
              top: "9.8%",
              left: "24.5%",
              backgroundColor: "#00ce3f",
              backgroundColor: "#00ce3f",
            }}
            size="small"
            onClick={() => handleClearFilter()}
          >
            <FilterAltOffIcon />
          </Fab>
        </Tooltip>
      )}

      <Grid style={{ borderRight: "1px solid #E5FAEB", marginTop: "15px" }}>
        <Grid item xs={12}>
          <Tabs
            style={{ width: "100%" }}
            variant="scrollable"
            value={tabValue}
            onChange={handleChangeTabs}
            aria-label="tabs"
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "#00CE3F",
              },
              "& .MuiTab-root.Mui-selected": {
                color: "#00CE3F",
                fontWeight: 600,
              },
              "& .MuiTab-root": {
                color: "#3D4740",
              },
            }}
          >
            {instructionProductsLoader ? (
              <Typography style={{ color: "#00CE3F", marginTop: "1rem" }}>
                Loading Products...
              </Typography>
            ) : (
              instructionProducts?.length > 0 &&
              instructionProducts?.map((d, ind) => (
                <Tab
                  key={ind}
                  onClick={() => handleClickInstructionProducts(d)}
                  label={`${d?.productName || "NA"} * ${d?.quantity || ""}`}
                  className="normal_normal_semibold_14_Manrope instruction-nav-label"
                />
              ))
            )}
          </Tabs>
        </Grid>

        <Grid
          container
          spacing={2}
          justify="flex-start"
          mt={1}
          mb={1}
          className="instruction_dashboard_scrollbar"
          style={{ overflow: "auto", height: "85vh", padding: "1rem" }}
        >
          {instructionTasksLoader ? (
            <Grid
              item
              xs={12}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography style={{ color: "#00CE3F", marginTop: "1rem" }}>
                Loading Tasks...
              </Typography>
            </Grid>
          ) : instructionTasks?.length > 0 ? (
            instructionTasks?.map((d) => (
              <>
                {/* <Grid
                  key={d?.id}
                  item
                  xs={8}
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography className="serif_display_regular_20">
                    {d?.milestoneName || "NA"}
                    <span
                      role="button"
                      onClick={() => handleClickOpenDueDate(d)}
                      className={`normal_normal_600_14_Manrope ${
                        checkDate(d) ? "red" : "green"
                      }`}
                      style={{ marginLeft: "1rem" }}
                    >
                      (Due by:-{" "}
                      {d?.dueDate
                        ? moment(new Date(d?.dueDate)).format(
                            "DD/MM/YYYY hh:mm A"
                          )
                        : "NA"}
                      )
                    </span>
                  </Typography>
                </Grid> */}
                <Grid
                  key={d?.id}
                  item
                  xs={8}
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography className="serif_display_regular_20">
                    {d?.milestoneName || "NA"}
                    {d?.status === "Completed" ? (
                      <Tooltip title="Task is already completed">
                        <span
                          className={`normal_normal_600_14_Manrope ${
                            checkDate(d) ? "red" : "green"
                          }`}
                          style={{ marginLeft: "1rem" }}
                        >
                          (Due by:-{" "}
                          {d?.dueDate
                            ? moment(new Date(d?.dueDate)).format(
                                "DD/MM/YYYY hh:mm A"
                              )
                            : "NA"}
                          )
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Update Due Date">
                        <span
                          onClick={() => handleClickOpenDueDate(d)}
                          className={`normal_normal_600_14_Manrope ${
                            checkDate(d) ? "red" : "green"
                          }`}
                          style={{ marginLeft: "1rem", cursor: "pointer" }}
                        >
                          (Due by:-{" "}
                          {d?.dueDate
                            ? moment(new Date(d?.dueDate)).format(
                                "DD/MM/YYYY hh:mm A"
                              )
                            : "NA"}
                          )
                        </span>
                      </Tooltip>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={4} display="flex" justifyContent="flex-end">
                  <Typography className="normal_normal_medium_14_Manrope dark_grey">
                    <span className="normal_normal_600_14_Manrope dark_grey">
                      Actor:-
                    </span>
                    {` ${d?.actor || "NA"}`}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography className="normal_normal_18_Manrope">
                    <Tooltip
                      title={
                        d?.status === "Completed"
                          ? "Completed Task"
                          : "Click to complete task"
                      }
                    >
                      <IconButton
                        // onClick={
                        //   d?.isCompleted || d?.status === null
                        //     ? null
                        //     : () => handleClickCompleteTask(d)
                        // }
                        onClick={
                          d?.isCompleteButton
                            ? () => handleClickCompleteTask(d)
                            : null
                        }
                        // onClick={() => handleClickCompleteTask(d)}
                      >
                        <CheckCircleIcon
                          sx={{
                            color: `${
                              d?.status === "Completed" ? "#00CE3F" : "#dee2e6"
                            }`,
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                    {d?.name || "NA"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Typography className="normal_normal_medium_14_Manrope dark_grey">
                    <span className="normal_normal_600_14_Manrope dark_grey">
                      {`${
                        d?.status === "Completed" ? "Completed" : "Modified"
                      } By:-`}
                    </span>
                    {`${
                      d?.modifiedBy
                        ? d?.modifiedBy?.firstName +
                          " " +
                          d?.modifiedBy?.lastName
                        : d?.actor || "NA"
                    }`}
                  </Typography>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  mt={1}
                  // style={{
                  //   display: `${
                  //     d?.status === "Picked" || d?.status === "Completed"
                  //       ? ""
                  //       : "none"
                  //   }`,
                  // }}
                >
                  {d?.TaskAns?.length > 0 &&
                    d?.TaskAns?.map((answer) => (
                      <Grid
                        key={d?.id}
                        item
                        xs={4}
                        style={{
                          display: "flex",
                          gap: 10,
                          alignItems: "center",
                        }}
                      >
                        <Button
                          disabled={d?.isCompleted}
                          // disabled={
                          //   !d?.finalTruthWhatActionCanHeTakeOnTaskLikeGivingComment ||
                          //   d?.status === null
                          // }
                          size="small"
                          style={{
                            borderRadius: "24px",
                            boxShadow: "none",
                            borderColor: "#3D4740",
                            marginLeft: "1rem",
                          }}
                          variant="outlined"
                          // className={`normal_normal_semibold_14_Manrope ${
                          //   !d?.finalTruthWhatActionCanHeTakeOnTaskLikeGivingComment ||
                          //   d?.status === null
                          //     ? ""
                          //     : "dark_grey"
                          // } roundOutlinedButton`}
                          className="normal_normal_semibold_14_Manrope  dark_grey roundOutlinedButton"
                          onClick={() => openUpdateTaskAnswerDialog(answer, d)}
                        >
                          {answer?.name || ""}
                        </Button>
                        <IconButton onClick={() => viewTaskComments(d)}>
                          <RemoveRedEyeIcon className="green" />
                        </IconButton>
                      </Grid>
                    ))}

                  <Grid
                    item
                    xs={8}
                    style={{
                      display: "flex",
                      gap: 10,
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    {/* Assign task to yourself */}
                    {d?.isPickedButton ? (
                      <Button
                        size="small"
                        style={{
                          boxShadow: "none",
                          backgroundColor: "#00ce3f",
                          borderRadius: "24px",
                          textTransform: "none",
                          display: `${
                            d?.status === "Picked" || d?.status === "Completed"
                              ? "none"
                              : ""
                          }`,
                        }}
                        variant="contained"
                        onClick={
                          d?.status === "Picked" || d?.status === "Completed"
                            ? null
                            : () => handleClickPickTask(d)
                        }
                      >
                        Pick a task
                      </Button>
                    ) : null}
                    <Grid>
                      {/* Assign task to yourself */}
                      {d?.isDroppedButton ? (
                        <Button
                          size="small"
                          style={{
                            boxShadow: "none",
                            backgroundColor: "#3d4740",
                            borderRadius: "24px",
                            textTransform: "none",
                            // display: `${
                            //   d?.status === "Picked" ||
                            //   d?.status === "Completed"
                            //     ? "none"
                            //     : ""
                            // }`,
                          }}
                          variant="contained"
                          onClick={() => handleClickDropTask(d)}
                        >
                          Drop a task
                        </Button>
                      ) : null}

                      {d?.isCompleted ? (
                        <span className="normal_normal_600_14_Manrope green">
                          (Completed on:-{" "}
                          {d?.endDate
                            ? moment(new Date(d?.endDate)).format(
                                "DD/MM/YYYY hh:mm A"
                              )
                            : "NA"}
                          )
                        </span>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} mt={1} style={{ marginLeft: "30px" }}>
                  <Divider
                    orientation="horizontal"
                    sx={{ border: `1px solid #DCDCE1` }}
                  />
                </Grid>
              </>
            ))
          ) : (
            <Grid
              item
              xs={12}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography style={{ color: "#00CE3F", marginTop: "1rem" }}>
                No Tasks For this Product...
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default TaskList;
