/* eslint-disable jsx-a11y/alt-text */
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment/moment";
import React, { useEffect, useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BackButton from "../../../../../assets/images/BUTTON_Back.svg";
import DataGridComponent from "../../../../../components/DataGridComponent";
import { OutlinedGreenButton } from "../../../../../components/buttons";
import {
  AutoCompleteInput,
  DateInput,
  DropdownInput,
  MobileNumberInput,
  RadioInput,
  SingleCheckbox,
  TextInput,
} from "../../../../../components/inputs";
import { updateTempInstructionData } from "../../../../../slice/instruction";
import { showSnackbar } from "../../../../../slice/snackbarSlice";
import {
  getAdditionalDocuments,
  uploadFile,
} from "../../../../../thunks/documents";
import {
  ADD_BOOK_ROLES,
  API_URL,
  AddressBookSourceFullNames,
  IMG_BASE_URL,
  VALIDATION_MSG,
} from "../../../../../utils/constant";
import {
  downloadDocument,
  downloadEmailDocument,
  findValues,
  mapLabelValue,
  toTitleCase,
  uniqBy,
} from "../../../../../utils/util";
import AddressSearchInput from "../../AddressSearch";
import UploadFileDialog from "../../UploadFile";
import { downloadAddressBookDocumentPdf } from "../../../../../thunks/instruction";
import Loader from "../../../../../components/Loader";

const ViewAddressBookPage = (props) => {
  const { getValues, setValue, watch, trigger } = useFormContext();
  let params = useParams();
  const dispatch = useDispatch();
  const isJoint = useSelector((state) => state?.instruction?.isJoint);
  let instructionId = params?.id;
  const { data, pathName } = props?.activeAddressbook || {};
  const [isLoader, setLoader] = useState(false);
  let { index, personID, isNew, ...personData } = data || {};
  const [isOpenUploadDialog, setOpenUploadPopup] = useState("");
  const [additionalDocuments, setAdditionalDocuments] = useState([]);
  const [additinalDocHistory, setAdditinalDocHistory] = useState([]);
  const [progress, setProgress] = useState("0/0");
  const [isUploading, setUploading] = useState(false);
  // Columns for additional documents
  const columns = [
    {
      id: "identificationProofId",
      label: (
        <Typography className="table_cell_heading">
          Identification Proof
        </Typography>
      ),
      minWidth: 100,
    },
    {
      id: "file",
      label: (
        <Typography className="table_cell_heading">Upload Document</Typography>
      ),
      minWidth: 60,
    },
    {
      id: "fileName",
      label: (
        <Typography className="table_cell_heading">Document Name</Typography>
      ),
      minWidth: 100,
    },
    {
      id: "action",
      label: <Typography className="table_cell_heading">Action</Typography>,
      minWidth: 100,
    },
  ];

  // Columns for additional documents history
  const DOC_HISTORY_COL = [
    {
      field: "identificationProofType",
      headerName: "Identification Type",
      width: 200,
      sortable: false,
    },
    {
      field: "azureImageUrl",
      headerName: "Document",
      width: 200,
      sortable: false,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_API_PATH}/file/download-image?path=${params?.row?.azureImageUrl}`
              )
            }
          >
            <VisibilityIcon />
          </IconButton>
        );
      },
    },
  ];
  const goBack = () => {
    props.handleDiscardChanges();
  };

  let [firstName, middleName, lastName] = watch([
    `${pathName}.firstName`,
    `${pathName}.middleName`,
    `${pathName}.lastName`,
  ]);
  let newFullname = useMemo(() => {
    return (
      firstName +
      (middleName ? ` ${middleName}` : "") +
      (lastName ? ` ${lastName}` : "")
    );
  }, [firstName, middleName, lastName]);

  const saveAndGoback = async () => {
    let isValidForm = await trigger();
    if (isValidForm) {
      if (!isNew) {
        let sourcePaths = personData?.sourcePaths;
        // it will update fullname from source questionair
        updateQuesName({
          isJoint,
          prevFullname: personData?.fullName,
          newFullname,
          sourcePaths,
        });

        // as form is missing fullname, personID here updating respective fields
        setValue(`${pathName}.fullName`, newFullname);
        setValue(`${pathName}.personID`, personID);
      } else {
        setValue(`${pathName}.fullName`, newFullname);
      }
      // update data to redux store to sync form data and redux store
      let formName = "InstructionAddressBook";
      let formData = getValues(formName);
      await dispatch(
        updateTempInstructionData({
          prop: formName,
          data: formData,
        })
      );

      // goback
      props?.changeActiveAddressbook(null);
    }
  };

  // Mui styling for imges
  const docStyle = {
    backgroundColor: "#E5FAEB",
    borderRadius: "10px",
    textAlign: "center",
    height: "150px",
    aspectRatio: 4 / 2,
    margin: "6px 0",
    "& > img": {
      height: "inherit",
    },
    position: "relative",
  };

  let {
    Salutation,
    relationshipAddr,
    PERSONAL_PROOF_TYPE,
    RESIDENCY_PROOF_TYPE,
    CERTIFICATION_TYPE,
    Gender,
  } = useSelector((state) => state?.lookupData);

  let genderList = useMemo(() => mapLabelValue(Gender), [Gender]);
  let salutationData = useMemo(() => mapLabelValue(Salutation), [Salutation]);

  const isClient = personData?.personRole?.some((role) => {
    return role?.includes(`${ADD_BOOK_ROLES.Client}`);
  });

  // get dependant address list, ref. from iPad code
  const getAddressArray = () => {
    let tempKeys = [
      "InstructionClients.InstructionFactFind.clientDetails.address",
      "InstructionClients.InstructionFactFind.properties.address",
      "InstructionAddressbook.addressBook.address",
    ];
    let instruction = getValues();
    const temp = findValues(instruction, tempKeys);
    return temp;
  };
  let _dropdownProperty = getAddressArray()
    .map((value) => {
      if (
        !(value?.street || value?.street1) &&
        !value?.street2 &&
        !value?.city &&
        !value?.state &&
        !value?.zip
      )
        return null;

      return {
        value:
          (value?.street || value?.street1 + "_" + value?.street2) +
          "_" +
          value?.city +
          "_" +
          value?.state +
          "_" +
          value?.zip +
          "_" +
          value?.country,
        street1: value?.street || value?.street1,
        street2: value?.street2 || "",
        zip: value?.zip,
        state: value?.state,
        city: value?.city,
        country: value?.country,
        backgroundColor: value?.backgroundColor,
        label: removeComma(
          (toTitleCase(value?.street) ||
            toTitleCase(value?.street1) + ", " + toTitleCase(value?.street2)) +
            ", " +
            toTitleCase(value?.city) +
            ", " +
            toTitleCase(value?.zip) +
            ", " +
            toTitleCase(value?.country || value?.state) +
            ". "
        ),
      };

      function removeComma(data) {
        return data.replace(/ ,/g, "");
      }
    })
    .filter((item) => item);

  let addressList = useMemo(() => {
    return uniqBy(_dropdownProperty, (item) => item?.value) || [];
  }, []);

  useEffect(() => {
    if (isNew) {
      let personId = getValues(`${pathName}.personID`);
      !personId && setValue(`${pathName}.personID`, personID);
    }
  }, [isNew]);

  useEffect(() => {
    // if fullname exists and firstname not, populate data
    const fullName = personData?.fullName;
    if (fullName) {
      let names = fullName?.split();
      if (!firstName) setValue(`${pathName}.firstName`, names[0]);
      if (!lastName && names?.length > 1)
        setValue(`${pathName}.lastName`, names[names?.length - 1]);
      if (!middleName && names?.length > 2)
        setValue(`${pathName}.middleName`, names[1]);
    }

    !data?.phoneNumber &&
      setValue(
        `${pathName}.phoneNumber`,
        getValues(
          `InstructionClients[${data?.index}].InstructionFactFind.clientDetails.mobileNo`
        )
      );
    !data?.email &&
      setValue(
        `${pathName}.email`,
        getValues(`InstructionClients[${data?.index}].email`)
      );

    !data?.address?.city &&
      setValue(
        `${pathName}.address.city`,
        getValues(
          `InstructionClients[${data?.index}].InstructionFactFind.clientDetails.address.city`
        )
      );

    !data?.address?.street1 &&
      setValue(
        `${pathName}.address.street1`,
        getValues(
          `InstructionClients[${data?.index}].InstructionFactFind.clientDetails.address.street1`
        )
      );

    !data?.address?.street2 &&
      setValue(
        `${pathName}.address.street2`,
        getValues(
          `InstructionClients[${data?.index}].InstructionFactFind.clientDetails.address.street2`
        )
      );

    !data?.address?.state &&
      setValue(
        `${pathName}.address.state`,
        getValues(
          `InstructionClients[${data?.index}].InstructionFactFind.clientDetails.address.state`
        )
      );

    !data?.address?.zip &&
      setValue(
        `${pathName}.address.zip`,
        getValues(
          `InstructionClients[${data?.index}].InstructionFactFind.clientDetails.address.zip`
        )
      );

    !data?.address?.country &&
      setValue(
        `${pathName}.address.country`,
        getValues(
          `InstructionClients[${data?.index}].InstructionFactFind.clientDetails.address.country`
        )
      );
  }, []);

  // update fullname in source Qn.
  const updateQuesName = ({ newFullname, sourcePaths }) => {
    if (sourcePaths?.length) {
      sourcePaths?.forEach((path, i) => {
        let data = getValues(path);
        if (data) {
          for (
            let index = 0;
            index < AddressBookSourceFullNames.length;
            index++
          ) {
            const prop = AddressBookSourceFullNames[index];
            if (data?.hasOwnProperty(prop)) {
              let fullPath = path + "." + prop;
              setValue(fullPath, newFullname);
              break;
            }
          }
        }
      });
    }
  };

  let roles = useMemo(
    () =>
      isNew
        ? Object.entries(ADD_BOOK_ROLES)?.map((item) => ({
            label: item?.[0],
            value: item?.[1],
          }))
        : [],
    []
  );

  const imagePath = {
    residencyProof: `${pathName}.identification.residencyProof.img`,
    personalProof: `${pathName}.identification.personalProof.img`,
    sign: `sign`,
  };
  let [residencyProofImg, personalProofImg] = watch([
    imagePath.residencyProof,
    imagePath.personalProof,
  ]);
  const handleUpload = async (documentData, path) => {
    try {
      if (!documentData?.documentUrl) {
      } else {
        setLoader(true);

        let addressBookInfo = { personID, path: pathName };
        if (!isNew) {
          switch (path) {
            case imagePath.personalProof:
              addressBookInfo = {
                ...addressBookInfo,
                ...(personData?.identification?.personalProof?.img || {}),
              };
              break;
            case imagePath.residencyProof:
              addressBookInfo = {
                ...addressBookInfo,
                ...(personData?.identification?.residencyProof?.img || {}),
              };
              break;
          }
        }

        let uploadFileResponse = "";
        if (documentData?.documentUrl) {
          let formData = new FormData();
          formData.append(
            "myfile",
            documentData?.documentUrl,
            documentData?.documentUrl?.name
          );
          formData.append("addressBookInfo", JSON.stringify(addressBookInfo));
          const payload = {
            formData,
            url: `/instruction/${instructionId}/uploadInstructionAddressBookDocs`,
          };
          uploadFileResponse = await dispatch(uploadFile(payload)).unwrap();

          if (uploadFileResponse?.id) {
            setValue(path, uploadFileResponse);
          }
        }
      }
    } catch (error) {
      return error;
    } finally {
      setLoader(false);
      setOpenUploadPopup("");
    }
  };

  const isClient1 = personData?.personRole?.some((str) =>
    str?.includes(`${ADD_BOOK_ROLES.Client} 1`)
  );
  const isClient2 = personData?.personRole?.some((str) =>
    str?.includes(`${ADD_BOOK_ROLES.Client} 2`)
  );

  // populate address in other address fields got from autocomplete address result
  const handleSelectedAddress = (data) => {
    setValue(`${pathName}.address.street1`, data?.street1 || "");
    setValue(`${pathName}.address.country`, data?.country || "");
    setValue(`${pathName}.address.state`, data?.state || "");
    setValue(`${pathName}.address.street2`, data?.street2 || "");
    setValue(`${pathName}.address.zip`, data?.zip || "");
  };

  const handleSelectedAddressProfessional = (data) => {
    setValue(`${pathName}.address.street1`, data?.street1 || "");
    setValue(`${pathName}.address.country`, data?.country || "");
    setValue(`${pathName}.address.state`, data?.state || "");
    setValue(`${pathName}.address.street2`, data?.street2 || "");
    setValue(`${pathName}.address.zip`, data?.zip || "");
  };
  const [isProfessionalPerson, personalProof, residencyProof] = useWatch({
    name: [
      `${pathName}.identification.certificationType`,
      `${pathName}.identification.personalProof.proofType`,
      `${pathName}.identification.residencyProof.proofType`,
    ],
  });

  // Method to add additional documents
  const handleAddDocuments = () => {
    let temp = [...additionalDocuments];
    temp.push({
      identificationProofId: null,
      identificationProofName: null,
      file: null,
    });
    setAdditionalDocuments(temp);
  };

  // Method to handle file change
  const handleFileChange = (event, index) => {
    let temp = [...additionalDocuments];
    let file = event.target.files[0];
    temp[index].file = file;
    setAdditionalDocuments(temp);
  };

  // Method to delete document
  const handleDeleteDocument = (index) => {
    let temp = [...additionalDocuments];
    temp.splice(index, 1);
    setAdditionalDocuments(temp);
  };

  const handleDocumentTypeChange = (e, index) => {
    const identificationProofType = identificationProofs.find(
      (data) => data.id === e.target.value
    );
    let temp = [...additionalDocuments];
    temp[index].identificationProofId = e.target.value;
    temp[index].identificationProofName = identificationProofType?.name;
    setAdditionalDocuments(temp);
  };
  // Combining all identification proof types
  const identificationProofs = [
    ...PERSONAL_PROOF_TYPE,
    ...RESIDENCY_PROOF_TYPE,
  ];

  // Method to upload address book documents one by one
  const handleUploadAddressBookDoc = async () => {
    // write function to validate file and identification proof ID from additionalDocuments array
    const isValid = additionalDocuments?.every(
      (doc) => doc?.file && doc?.identificationProofId
    );
    // If any of the document is not valid, show error message
    if (!isValid) {
      dispatch(
        showSnackbar({
          show: true,
          message: "Please select file and identification proof type",
          severity: "error",
        })
      );
      return;
    } else {
      // If all documents are valid, upload them one by one
      let completed = 0;
      const total = additionalDocuments?.length;
      let uploadFileResponse = "";
      if (additionalDocuments?.length > 0) {
        for (let index = 0; index < additionalDocuments.length; index++) {
          const documentData = additionalDocuments[index];
          let formData = new FormData();
          formData.append(
            "myfile",
            documentData?.file,
            documentData?.file?.name
          );
          // Serialize the clientBody object to JSON
          const addressBookInfo = {
            personID: data?.personID || null,
            firstName: data?.firstName || null,
            lastName: data?.lastName || null,
            proofType: documentData?.identificationProofName,
            proofTypeId: documentData?.identificationProofId,
            identificationProofType: documentData?.identificationProofName,
          };
          formData.append("addressBookInfo", JSON.stringify(addressBookInfo));
          const payload = {
            formData,
            url: `/instruction/${instructionId}/uploadInstructionAddressBookDocs`,
          };
          try {
            setUploading(true);
            uploadFileResponse = await dispatch(uploadFile(payload)).unwrap();
            if (uploadFileResponse) {
              completed += 1;
              setProgress(`${completed}/${total}`);
              setUploading(false);
            } else {
              setUploading(false);
            }
          } catch (error) {
            setUploading(false);
            dispatch(
              showSnackbar({
                show: true,
                message: `${error?.message}`,
                severity: "error",
              })
            );
          }
        }
        // If all documents are uploaded successfully, show success message
        await dispatch(
          showSnackbar({
            show: true,
            message: "Documents uploaded successfully",
            severity: "success",
          })
        );
        // Reset the additional documents array
        await setAdditionalDocuments([]);
        // Fetch additional documents again
        await fetchAdditionalDocuments();
      }
    }
  };

  // Method to get additional documents
  const fetchAdditionalDocuments = async () => {
    const payload = {
      instructionId,
      userId: data?.personID || null,
    };
    try {
      const response = await dispatch(getAdditionalDocuments(payload)).unwrap();
      if (response) {
        setAdditinalDocHistory(response);
      } else {
        setAdditinalDocHistory([]);
      }
    } catch (error) {
      setAdditinalDocHistory([]);
      return error;
    }
  };

  const handleDocumentDownload = async (documentType, documentId) => {
    setLoader(true);
    try {
      await dispatch(
        downloadAddressBookDocumentPdf({
          instructionId: instructionId,
          personId: data?.personID,
          documentType: documentType,
          documentId: documentId,
          signatureId: data?.identification?.signature?.img?.id ?? 0,
          filename: `${getValues(`${pathName}.firstName`)}_${getValues(
            `${pathName}.lastName`
          )}_${documentType}_${instructionId}`,
        })
      ).unwrap();
    } catch (error) {
      console.log("ERROR :: handleDocumentDownload :: ", error);
      dispatch(
        showSnackbar({
          show: true,
          message: `${error?.message}`,
          severity: "error",
        })
      );
    }
    setLoader(false);
  };

  // Method to get additional documents on component mount if not new
  useEffect(() => {
    if (!isNew) {
      fetchAdditionalDocuments();
    }
  }, [!isNew]);
  return (
    <>
      {isLoader && <Loader />}
      {/* Card CLient names */}
      <Grid mt={5} mb={1}>
        <Grid
          style={{ position: "absolute", top: "12px", zIndex: 1, left: "10%" }}
        >
          {/* <OutlinedGreenButton label={"Cancel"} onClick={goBack} /> */}
          <img
            src={BackButton}
            onClick={goBack}
            style={{ cursor: "pointer", marginRight: "10px" }}
          />
        </Grid>
        <Grid
          display={"flex"}
          gap={2}
          py={2}
          borderBottom={`1px solid #DCDCE1`}
        >
          <Grid>
            <Typography sx={{ color: "#00CE3F" }} className="manropeSemiBold16">
              {personData?.fullName}
            </Typography>
            <Grid display={"flex"} flexDirection={"row"} gap={1}>
              {personData?.personRole?.map((role, roleIndex, arr) => {
                return role ? (
                  <Typography className="manropeRegular12">
                    {role} {roleIndex !== arr.length - 1 ? "/" : null}
                  </Typography>
                ) : (
                  ""
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* Details */}

      <Grid container spacing={3}>
        {/* Title Section */}
        <Grid
          item
          xs={3}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <DropdownInput
            name={`${pathName}.title`}
            label="Title"
            options={salutationData}
            // required but removed validation due to client demanded so
            // rules={{
            //   required: { value: true, message: VALIDATION_MSG.REQUIRED },
            // }}
          />
        </Grid>

        {/* First Name Section */}
        <Grid item xs={3}>
          <TextInput
            label={`First Name`}
            name={`${pathName}.firstName`}
            placeholder="First Name"
            rules={{
              required: { value: true, message: VALIDATION_MSG.REQUIRED },
            }}
            disabled={isClient}
          />
        </Grid>

        {/* Middle Name Section */}
        <Grid item xs={3}>
          <TextInput
            label={`Middle Name`}
            name={`${pathName}.middleName`}
            placeholder="Middle Name"
            disabled={isClient}
          />
        </Grid>

        {/* Last Name */}
        <Grid item xs={3}>
          <TextInput
            label={`Last Name`}
            name={`${pathName}.lastName`}
            placeholder="Last Name"
            // rules={{
            //   required: { value: true, message: VALIDATION_MSG.REQUIRED },
            // }}
            disabled={isClient}
          />
        </Grid>

        {/* Alias Section */}
        <Grid item xs={3}>
          <TextInput
            label={`Alias`}
            name={`${pathName}.alias`}
            placeholder="Alias"
          />
        </Grid>

        {/* Gender Section */}
        <Grid item xs={3}>
          <RadioInput
            orientation="row"
            label={`Gender`}
            name={`${pathName}.gender`}
            options={[
              { label: "Male", value: "Male" },
              { label: "Female", value: "Female" },
            ]}
          />
        </Grid>

        {isNew && (
          <Grid item xs={3}>
            <DropdownInput
              name={`${pathName}.personRole.[0]`}
              label="Role"
              options={roles}
              rules={{
                required: { value: true, message: "Role is required" },
              }}
            />
          </Grid>
        )}

        {/* Date Of Birth */}
        <Grid item xs={3}>
          <DateInput name={`${pathName}.dob`} label="Date Of Birth" />
        </Grid>

        <Grid item xs={6}>
          <Grid container spacing={3}>
            {/* Relationship to clients section */}
            <Grid item xs={12}>
              {!isClient1 && (
                <Grid sx={{ width: "48%" }}>
                  <AutoCompleteInput
                    name={`${pathName}.relationshipToClientOne`}
                    label="Relationship to client 1"
                    options={relationshipAddr}
                    variant="standard"
                    isRelationShip={true}
                    updateRelationship={(data) =>
                      setValue(
                        `${pathName}.relationshipToClientOne`,
                        data?.value
                      )
                    }
                  />
                </Grid>
              )}
              {isJoint && !isClient2 && (
                <Grid sx={{ width: "48%" }}>
                  <AutoCompleteInput
                    name={`${pathName}.relationshipToClientTwo`}
                    label="Relationship to client 2"
                    options={relationshipAddr}
                    variant="standard"
                    isRelationShip={true}
                    updateRelationship={(data) =>
                      setValue(
                        `${pathName}.relationshipToClientTwo`,
                        data?.value
                      )
                    }
                  />
                </Grid>
              )}
            </Grid>

            {/* Home Telephone Section */}
            <Grid item xs={6}>
              <TextInput
                label={`Home Telephone`}
                name={`${pathName}.telephone`}
                placeholder="Home Telephone"
                // required but removed validation due to client demanded so
                // rules={{
                //   required: {
                //     value: false,
                //     message: VALIDATION_MSG.NUM_ONLY,
                //   },
                //   valueAsString: true,
                // }}
              />
            </Grid>

            {/* Mobile Number Section */}
            <Grid item xs={6}>
              <MobileNumberInput name={`${pathName}.phoneNumber`} />
              {/* <TextInput
                label={`Mobile Number`}
                name={`${pathName}.phoneNumber`}
                placeholder="Mobile Number"
                // required but removed validation due to client demanded so
                // rules={{
                //   required: {
                //     value: false,
                //     message: VALIDATION_MSG.NUM_ONLY,
                //   },
                //   valueAsString: true,
                // }}
              /> */}
            </Grid>

            {/* Email Section */}
            <Grid item xs={12}>
              <TextInput
                label={`Email`}
                name={`${pathName}.email`}
                placeholder="abc@mail.com"
              />
            </Grid>

            {/* Occupation Section */}
            <Grid item xs={6}>
              <TextInput
                label={`Occupation`}
                name={`${pathName}.occupation`}
                placeholder="Occupation"
              />
            </Grid>

            {/* National Insurence Number Section */}
            <Grid item xs={6}>
              <TextInput
                label={`National Insurance Number`}
                name={`${pathName}.nationalInsuranceNumber`}
                placeholder="National Insurance Number"
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Address Section */}
        <Grid item xs={6} display={"flex"} flexDirection={"column"} gap={2}>
          {/* Address search section */}
          <AddressSearchInput
            name={`${pathName}.address.city`}
            label="Select Address"
            placeholder="Select Address"
            defaultOptions={addressList}
            handleSelectedAddress={(data) => handleSelectedAddress(data)}
          />

          {/* Street 1 Section */}
          <TextInput
            label={`Address`}
            name={`${pathName}.address.street1`}
            placeholder="Street 1"
          />

          {/* Street 2 Section */}
          <TextInput
            name={`${pathName}.address.street2`}
            placeholder="Street 2"
          />
          <Grid container spacing={3}>
            {/* Town Section */}
            <Grid item xs={6}>
              <TextInput name={`${pathName}.address.city`} placeholder="Town" />
            </Grid>

            {/* State Section */}
            <Grid item xs={6}>
              <TextInput
                name={`${pathName}.address.state`}
                placeholder="County"
              />
            </Grid>

            {/* Zip Section */}
            <Grid item xs={6}>
              <TextInput
                name={`${pathName}.address.zip`}
                placeholder="Post Code"
              />
            </Grid>

            {/* Country Section */}
            <Grid item xs={6}>
              <TextInput
                name={`${pathName}.address.country`}
                placeholder="Country"
                disabled={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Identification */}
      <Grid>
        <Typography
          style={{
            marginTop: "20px",
            marginBottom: "20px",
          }}
          className="serif_display_regular_22"
        >
          Identification
        </Typography>

        <Grid display={"flex"} flexDirection={"column"} gap={4}>
          <Grid xs={4}>
            <DropdownInput
              name={`${pathName}.identification.certificationType`}
              label="Certification Type"
              options={CERTIFICATION_TYPE}
            />
          </Grid>
          <Grid xs={12} display={"flex"} flexDirection={"row"} gap={3}>
            <Grid xs={4} display={"flex"} flexDirection={"column"} gap={1}>
              <DropdownInput
                name={`${pathName}.identification.personalProof.proofType`}
                label="Personal ID Proof"
                options={PERSONAL_PROOF_TYPE}
              />
              <Typography className="normal_normal_14_Manrope">
                Take photograph in landscape aspect ratio
              </Typography>
            </Grid>
            <Grid sx={docStyle}>
              <img
                src={API_URL + IMG_BASE_URL + personalProofImg?.azureImageUrl}
                className="img-fluid"
              />
              {personalProofImg?.azureImageUrl && personalProof !== "" ? (
                <IconButton
                  sx={{
                    position: "absolute",
                    right: -40,
                    top: -5,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    let documentType = getValues(
                      `${pathName}.identification.personalProof.proofType`
                    );
                    handleDocumentDownload(documentType, personalProofImg?.id);
                  }}
                >
                  <DownloadIcon
                    sx={{
                      color: "#00CE3F",
                    }}
                  />
                </IconButton>
              ) : null}
            </Grid>
            <Grid
              xs={4}
              display={"flex"}
              flexDirection={"column"}
              gap={1}
              pr={1}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <OutlinedGreenButton
                onClick={() => setOpenUploadPopup(imagePath.personalProof)}
                label={"Upload"}
                style={{ width: "190px" }}
              />

              <UploadFileDialog
                open={isOpenUploadDialog == imagePath.personalProof}
                onSubmit={(data) => handleUpload(data, imagePath.personalProof)}
                handleClose={() => setOpenUploadPopup("")}
                isLoader={isLoader}
              />
            </Grid>
          </Grid>
          <Grid xs={12} display={"flex"} flexDirection={"row"} gap={3}>
            <Grid xs={4} display={"flex"} flexDirection={"column"} gap={1}>
              <DropdownInput
                name={`${pathName}.identification.residencyProof.proofType`}
                label="Proof of Residency"
                options={RESIDENCY_PROOF_TYPE}
              />
              <Typography className="normal_normal_14_Manrope">
                Take photograph in landscape aspect ratio
              </Typography>
            </Grid>
            <Grid sx={docStyle}>
              <img
                src={API_URL + IMG_BASE_URL + residencyProofImg?.azureImageUrl}
                className="img-fluid"
              />
              {residencyProofImg?.azureImageUrl && residencyProof !== "" ? (
                <IconButton
                  sx={{
                    position: "absolute",
                    right: -40,
                    top: -5,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    let documentType = getValues(
                      `${pathName}.identification.residencyProof.proofType`
                    );
                    handleDocumentDownload(documentType, residencyProofImg?.id);
                  }}
                >
                  <DownloadIcon
                    sx={{
                      color: "#00CE3F",
                    }}
                  />
                </IconButton>
              ) : null}
            </Grid>
            <Grid
              xs={4}
              display={"flex"}
              flexDirection={"column"}
              gap={1}
              pr={1}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <OutlinedGreenButton
                onClick={() => setOpenUploadPopup(imagePath.residencyProof)}
                label={"Upload"}
                style={{ width: "190px" }}
              />

              <UploadFileDialog
                open={isOpenUploadDialog == imagePath.residencyProof}
                onSubmit={(data) =>
                  handleUpload(data, imagePath.residencyProof)
                }
                handleClose={() => setOpenUploadPopup("")}
                isLoader={isLoader}
              />
            </Grid>
          </Grid>

          {!isNew && (
            <>
              {/* Additional Document Upload + History Section */}
              <Grid item xs={12}>
                <Typography className="serif_display_regular_22">
                  Additional Documents{" "}
                  <IconButton>
                    <AddCircleOutlineIcon
                      className="green"
                      onClick={handleAddDocuments}
                    />
                  </IconButton>
                  <Button
                    size="small"
                    onClick={() => handleUploadAddressBookDoc()}
                    variant="contained"
                    style={{
                      boxShadow: "none",
                      backgroundColor: "rgb(0, 206, 63)",
                      borderRadius: "24px",
                      display: `${
                        additionalDocuments?.length > 0 ? "" : "none"
                      }`,
                    }}
                  >
                    Upload
                  </Button>
                </Typography>
                {
                  // If uploading is in progress
                  isUploading && (
                    <Grid item xs={6} mt={1}>
                      <LinearProgress
                        color="success"
                        style={{ width: "80%" }}
                      />
                      <Typography className="normal_normal_18_Manrope dark_grey">
                        Uploading... {progress}
                      </Typography>
                    </Grid>
                  )
                }
              </Grid>

              {/* Local Additional Documents */}
              {additionalDocuments?.length > 0 && (
                <>
                  {/* UPLOADED FILES --> Local  */}
                  <Grid item xs={12}>
                    <Grid sx={{ width: "100%", overflow: "hidden" }}>
                      <TableContainer
                        style={{ cursor: "pointer", maxHeight: 250 }}
                        className="instruction_dashboard_scrollbar"
                      >
                        <Table
                          stickyHeader
                          aria-label="sticky-table a dense table"
                          size="small"
                        >
                          <TableHead>
                            {/* Columns section */}
                            <TableRow>
                              {columns.map((column) => (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{ minWidth: column.minWidth }}
                                >
                                  {column.label}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {additionalDocuments?.map((d, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  <FormControl fullWidth>
                                    <Select
                                      className="formControlField"
                                      variant="standard"
                                      size="small"
                                      value={d?.identificationProofId}
                                      onChange={(e) =>
                                        handleDocumentTypeChange(e, index)
                                      }
                                    >
                                      {identificationProofs.length > 0 &&
                                        identificationProofs.map((data) => (
                                          <MenuItem
                                            key={data.id}
                                            value={data.id}
                                          >
                                            {data.name}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                  </FormControl>
                                </TableCell>

                                <TableCell>
                                  <Button
                                    style={{
                                      cursor: "pointer",
                                      borderRadius: "10px",
                                      color: "rgb(0, 206, 63)",
                                      boxShadow: "none",
                                      marginTop: 10,
                                    }}
                                    component="label"
                                    startIcon={<FileUploadOutlinedIcon />}
                                  >
                                    Add file
                                    <input
                                      type="file"
                                      hidden
                                      onChange={(event) =>
                                        handleFileChange(event, index)
                                      }
                                    />
                                  </Button>
                                </TableCell>

                                <TableCell>
                                  {d?.file?.name || "No file selected"}
                                </TableCell>

                                <TableCell>
                                  <Grid>
                                    <IconButton
                                      onClick={() =>
                                        handleDeleteDocument(index)
                                      }
                                    >
                                      <DeleteIcon
                                        style={{ color: "#ff0000" }}
                                      />
                                    </IconButton>
                                  </Grid>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </>
              )}

              {additinalDocHistory?.length > 0 && (
                <Grid
                  item
                  xs={6}
                  style={{ position: "relative", bottom: "27px" }}
                >
                  <DataGridComponent
                    columns={DOC_HISTORY_COL}
                    rows={additinalDocHistory}
                    onRowClick={() => null}
                  />
                </Grid>
              )}
            </>
          )}
          {/* If certificate type === 'Professional Person' */}
          {isProfessionalPerson === "Professional Person" && (
            <Grid item xs={6} display={"flex"} flexDirection={"column"} gap={2}>
              {/* Professional Person Name */}
              <TextInput
                label={`Professional Person Name`}
                name={`${pathName}.identification.otherProofs.professionalName`}
                placeholder="Street 1"
              />

              {/* occupation */}
              <TextInput
                label={`occupation`}
                name={`${pathName}.identification.otherProofs.occupation`}
                placeholder="Street 1"
              />

              {/* Address search section */}
              <AddressSearchInput
                name={`${pathName}.identification.otherProofs.address.city`}
                label="Professional Person Address"
                placeholder="Select Address"
                defaultOptions={addressList}
                handleSelectedAddress={(data) =>
                  handleSelectedAddressProfessional(data)
                }
              />

              {/* Street 1 Section */}
              <TextInput
                label={`Address`}
                name={`${pathName}.identification.otherProofs.address.street1`}
                placeholder="Street 1"
              />

              {/* Street 2 Section */}
              <TextInput
                name={`${pathName}.identification.otherProofs.address.street2`}
                placeholder="Street 2"
              />
              <Grid container spacing={3}>
                {/* Town Section */}
                <Grid item xs={6}>
                  <TextInput
                    name={`${pathName}.identification.otherProofs.address.city`}
                    placeholder="Town"
                  />
                </Grid>

                {/* State Section */}
                <Grid item xs={6}>
                  <TextInput
                    name={`${pathName}.identification.otherProofs.address.state`}
                    placeholder="County"
                  />
                </Grid>

                {/* Zip Section */}
                <Grid item xs={6}>
                  <TextInput
                    name={`${pathName}.identification.otherProofs.address.zip`}
                    placeholder="Post Code"
                  />
                </Grid>

                {/* Country Section */}
                <Grid item xs={6}>
                  <TextInput
                    name={`${pathName}.identification.otherProofs.address.country`}
                    placeholder="Country"
                  />
                </Grid>
              </Grid>
            </Grid>
          )}

          <Grid
            display={"flex"}
            flexDirection={"column"}
            gap={2}
            mb={4}
            border={0}
            height={"auto"}
            py={2}
            mt={4}
          >
            <Grid
              display={"flex"}
              flexDirection={"row"}
              alignItems={"flex-center"}
              // border={1}
              sx={{
                marginRight: "5px",
                width: "100%",
              }}
            >
              <SingleCheckbox
                name={`${pathName}.identification.clarification`}
                label=""
              />
              <div className="normal_normal_600_16_Manrope">
                I certify that the photo(s) above is a true copy of the
                original.
              </div>
            </Grid>
            <Grid
              display={"flex"}
              flexDirection={"row"}
              width={"100%"}
              border={0}
            >
              <Grid
                display={"flex"}
                flexDirection={"column"}
                gap={1}
                width={"20%"}
              >
                <Grid className="normal_normal_bold_12_Manrope green">
                  Consultant
                </Grid>{" "}
                <Grid>
                  {" "}
                  {`${getValues()?.ownerOfInstruction?.firstName} ${
                    getValues()?.ownerOfInstruction?.lastName
                  }`}{" "}
                </Grid>
              </Grid>
              <Grid
                display={"flex"}
                flexDirection={"column"}
                gap={1}
                width={"20%"}
              >
                <Grid className="normal_normal_bold_12_Manrope green">
                  Date
                </Grid>
                {/* Added Instruction created date at address book bottom */}
                <Grid>
                  {moment(getValues()?.createdAt).format("DD-MM-YYYY")}
                </Grid>
              </Grid>

              {/* Hide consultant signature on create address book */}
              {!isNew && (
                <Grid
                  display={"flex"}
                  flexDirection={"column"}
                  gap={2}
                  width={"30%"}
                >
                  <Typography className="normal_normal_bold_12_Manrope green">
                    Consultant's Signature
                  </Typography>
                  <Grid
                    sx={{
                      border: `1px solid #DCDCE1`,
                      borderRadius: "10px",
                      height: "150px",
                      width: "100%",
                    }}
                  >
                    <div className="">
                      <img
                        className="address_document_image"
                        src={
                          API_URL +
                            IMG_BASE_URL +
                            personData?.identification?.signature?.img
                              ?.azureImageUrl || "NA"
                        }
                        width="200px"
                        height="100px"
                        alt="squiggle"
                      />
                    </div>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid>
              <Box sx={{ display: "inline-block", mr: 2 }}>
                <OutlinedGreenButton
                  label={"Save & Go Back"}
                  onClick={saveAndGoback}
                />
              </Box>
              {isNew && (
                <OutlinedGreenButton label={"Cancel"} onClick={goBack} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ViewAddressBookPage;
