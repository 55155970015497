import React, { useEffect, useRef, useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PushPinIcon from "@mui/icons-material/PushPin";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  EmailIconButton,
  NoteIconButton,
  PhoneIconButton,
  SmsIconButton,
} from "../../../components/buttons";
import { useDispatch, useSelector } from "react-redux";
import {
  getInstructionTaskAnswerDetails,
  getNotesAndDocumentById,
  readImage,
} from "../../../thunks/instruction";
import PhoneDialog from "./PhoneDialog";
import SmsDialog from "./SmsDialog";
import moment from "moment";
import EmailWithStatus from "../../Instructions/components/EmailWithStatus";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import {
  INBOUND__CLIENT_PARSE_URL,
  NOTIFICATION_MESSAGE,
  USER_ROLE,
} from "../../../utils/constant";
import TransferEmailDialog from "../../Instructions/components/TransferEmailDialog";
import SingleNoteContainer from "../../Instructions/components/SingleNoteContainer";
import { downloadClientNotesDocument } from "../../../utils/util";
import { getClientCommunication } from "../../../thunks/client";
import ClientEmailBox from "./ClientEmailBox";
import ClientDocumentsByType from "./ClientDocumentsByType";
import { useParams } from "react-router-dom";
import FullEmailDialog from "./FullEmailDialog";
import AddNotesAndDocument from "../../Instructions/components/AddNotesAndDocument";
import TransferClientEmailDialog from "./TransferClientEmailDialog";
import LinkCommunicationDialog from "./LinkCommunicationDialog";
import { showSnackbar } from "../../../slice/snackbarSlice";
import { CopyAll } from "@mui/icons-material";

const ClientDocumentsAndCommunication = ({ marketingPreferenceState }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const userDetails = useSelector((state) => state?.auth?.currentUser);
  const { clientUserData: clientInstruction } = useSelector(
    (store) => store?.client
  );
  const [isLoader, setLoader] = useState(false);
  const [openMailbox, setOpenMailbox] = useState(false);
  const [isDocumentsOpen, setDocumentsOpen] = useState(false);
  const [clientData, setClientData] = useState([]);
  const [showPhoneDialog, setShowPhoneDialog] = useState(false);
  const [showSmsDialog, setShowSmsDialog] = useState(false);
  const [notesDialog, setNotesDialog] = useState(false);
  const [clientCommunication, setClientCommunication] = useState([]);
  const [scrollPage, setScrollPage] = useState(1);
  const [hasMore, setHasMore] = useState(true); // Track if more items can be loaded
  const loader = useRef(null);
  const [showLinkCommunicationDialog, setShowLinkCommunicationDialog] =
    useState(false);
  const [selectedDataForLinking, setSelectedDataForLinking] = useState({});

  /* UAT and Production logic */
  const sender = userDetails?.email || null;
  const mailReceivers = clientInstruction?.email
    ? [
        {
          label: clientInstruction?.email,
          value: clientInstruction?.email?.email,
        },
      ]
    : [];

  const [isEmailTransfer, setIsEmailTransfer] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);

  const handleGetClientCommunication = async () => {
    try {
      setLoader(true);
      const response = await dispatch(
        getClientCommunication({ id: params?.id, page: scrollPage })
      ).unwrap();
      if (response?.activitiesWithRegardingData?.length > 0) {
        setClientCommunication((prev) => {
          if (scrollPage === 1) {
            // If it's the first page, replace the current data with the new data
            setHasMore(
              response?.activitieCount >
                response?.activitiesWithRegardingData?.length
            );
            return response?.activitiesWithRegardingData;
          } else {
            // If it's not the first page, concatenate the new data to the existing data
            const temp = [...prev, ...response?.activitiesWithRegardingData];
            setHasMore(response?.activitieCount > temp?.length);
            return temp;
          }
        });

        setLoader(false);
      } else {
        setHasMore(false); // No more items to load
      }
    } catch (error) {
      setLoader(false);
    } finally {
      setLoader(false);
    }
  };

  // this link is setup with sendgrid, which will perform inbound parse, so that email data can be saved to backend
  const emailBccLink = `client+${clientInstruction?.id}${INBOUND__CLIENT_PARSE_URL}`;

  const copyBccLink = () => {
    navigator?.clipboard
      .writeText(emailBccLink)
      .then(() => {
        dispatch(
          showSnackbar({
            message: NOTIFICATION_MESSAGE?.LINK_COPY_SUCCESS,
            severity: "success",
          })
        );
      })
      .catch((err) => {
        dispatch(
          showSnackbar({
            message: "Unable to copy text to clipboard",
            severity: "error",
          })
        );
        console.error("Unable to copy text to clipboard", err);
      });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setScrollPage((prev) => prev + 1);
        }
      },
      { threshold: 1 }
    );

    if (loader?.current) {
      observer?.observe(loader?.current);
    }

    return () => {
      if (loader?.current) {
        observer?.unobserve(loader?.current);
      }
    };
  }, []);

  useEffect(() => {
    handleGetClientCommunication(); // Initial fetch when component mounts
  }, [scrollPage]);

  return (
    <>
      {/* Dialog for Document */}
      {isDocumentsOpen ? (
        <ClientDocumentsByType
          open={isDocumentsOpen}
          handleClose={() => setDocumentsOpen(false)}
          clientId={params?.id}
          clientData={clientData}
        />
      ) : null}

      {/* Dialog for phone */}
      {showPhoneDialog ? (
        <PhoneDialog
          open={showPhoneDialog}
          handleClose={() => setShowPhoneDialog(false)}
        />
      ) : null}

      {/* Dialog for sms */}
      {showSmsDialog ? (
        <SmsDialog
          open={showSmsDialog}
          handleClose={() => setShowSmsDialog(false)}
        />
      ) : null}

      {/* Dialog for client notes  */}
      {notesDialog ? (
        <AddNotesAndDocument
          handleClose={() => setNotesDialog(false)}
          open={notesDialog}
          instructionId={null}
          isClientTask={true}
          handleSuccessCreateNote={() => handleGetClientCommunication()}
          clientData={[clientInstruction]}
          clientInstruction={clientInstruction}
        />
      ) : null}

      {/* Send email dialog */}
      {openMailbox && (
        <ClientEmailBox
          open={openMailbox}
          onClose={() => setOpenMailbox(false)}
          emailConfigData={{
            from: sender,
            to: mailReceivers,
          }}
          onSave={() => handleGetClientCommunication()}
          userDetails={userDetails}
          instructionData={{}}
          clientInstruction={clientInstruction}
        />
      )}

      {/* Link communication dialog */}
      {showLinkCommunicationDialog ? (
        <LinkCommunicationDialog
          handleClose={() => setShowLinkCommunicationDialog(false)}
          handleSave={() => handleGetClientCommunication()}
          open={showLinkCommunicationDialog}
          clientInstructions={clientInstruction?.Instruction}
          regardingType={selectedDataForLinking?.regardingType}
          regardingTypeId={selectedDataForLinking?.regardingTypeId}
        />
      ) : null}

      <Grid item xs={12} sx={{ height: "100vh", overflowY: "scroll" }}>
        {/* Accordion for Documents Section */}
        <Grid mt={1}>
          <Accordion
            fullWidth
            className="dashboard__accordion"
            expanded={isDocumentsOpen}
            onClick={
              userDetails?.role === USER_ROLE?.INTRODUCER
                ? () => setDocumentsOpen(false)
                : () => setDocumentsOpen(true)
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
            >
              <Typography
                className="serif_display_regular_18"
                disabled={userDetails?.role === USER_ROLE?.INTRODUCER}
                style={{
                  color:
                    userDetails?.role === USER_ROLE?.INTRODUCER ? "gray" : "",
                }}
              >
                Documents
              </Typography>
            </AccordionSummary>
          </Accordion>
        </Grid>
        <Grid>
          {userDetails?.role === USER_ROLE?.INTRODUCER && (
            <Accordion
              expanded={false} // Keep Accordion closed
              className="dashboard__accordion"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                onClick={(e) => e.stopPropagation()} // Prevent click from expanding the accordion
              >
                <Typography
                  className="serif_display_regular_18"
                  style={{
                    color: "gray", // Gray color for INTRODUCER role
                  }}
                >
                  Communication
                </Typography>
              </AccordionSummary>
            </Accordion>
          )}

          {userDetails?.role !== USER_ROLE?.INTRODUCER && (
            <Accordion
              fullWidth
              className="dashboard__accordion"
              // onClick={handleClickOpenDocument}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
              >
                <Typography
                  className="serif_display_regular_18"
                  disabled={userDetails?.role === USER_ROLE?.INTRODUCER}
                  style={{
                    color:
                      userDetails?.role === USER_ROLE?.INTRODUCER ? "gray" : "",
                  }}
                >
                  Communication
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                border={0}
                sx={{ height: "80vh", flexGrow: 1, overflowY: "auto" }}
              >
                <Grid border={0}>
                  <Box display={"flex"} justifyContent={"center"} gap={1.5}>
                    <Tooltip title="email">
                      <Grid>
                        <EmailIconButton
                          onClick={() => setOpenMailbox(true)}
                          size="large"
                          disabled={
                            !marketingPreferenceState?.Email?.Transactional &&
                            !marketingPreferenceState?.Email?.Marketing
                          }
                        />
                      </Grid>
                    </Tooltip>

                    <Tooltip title="note">
                      <Grid>
                        <NoteIconButton
                          onClick={() => setNotesDialog(true)}
                          size="large"
                        />
                      </Grid>
                    </Tooltip>
                    <Tooltip title="phone">
                      <Grid>
                        <PhoneIconButton
                          onClick={() => {
                            setShowPhoneDialog(true);
                          }}
                          size="large"
                          disabled={
                            !marketingPreferenceState?.Phone?.Transactional &&
                            !marketingPreferenceState?.Phone?.Marketing
                          }
                        />
                      </Grid>
                    </Tooltip>
                    <Tooltip title="sms">
                      <Grid>
                        <SmsIconButton
                          onClick={() => {
                            setShowSmsDialog(true);
                          }}
                          size="large"
                          disabled={
                            !marketingPreferenceState?.SMS?.Transactional &&
                            !marketingPreferenceState?.SMS?.Marketing
                          }
                        />
                      </Grid>
                    </Tooltip>
                    {userDetails?.role !== USER_ROLE?.INTRODUCER && (
                      <Tooltip title="Copy link to send email" placement="top">
                        <IconButton
                          aria-label=""
                          onClick={copyBccLink}
                          sx={{ marginLeft: "auto" }}
                        >
                          <CopyAll fontSize="large" />{" "}
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                </Grid>
                <Grid mt={2}>
                  {clientCommunication?.map((item, itemIndex, items) => {
                    return item?.html ? (
                      <>
                        <SingleEmailView
                          data={item}
                          dataIndex={itemIndex}
                          clientData={clientData}
                          setShowLinkCommunicationDialog={
                            setShowLinkCommunicationDialog
                          }
                          setSelectedDataForLinking={setSelectedDataForLinking}
                        />
                        {itemIndex !== items?.length - 1 ? (
                          <Divider
                            sx={{
                              marginY: "1rem",
                              border: "0.5px solid lightgrey",
                            }}
                          />
                        ) : null}
                      </>
                    ) : (
                      <>
                        <SingleNoteView
                          d={item}
                          index={itemIndex}
                          clientData={clientData}
                          setShowLinkCommunicationDialog={
                            setShowLinkCommunicationDialog
                          }
                          setSelectedDataForLinking={setSelectedDataForLinking}
                        />
                        {itemIndex !== items?.length - 1 ? (
                          <Divider
                            sx={{
                              marginY: "1rem",
                              border: "0.5px solid lightgrey",
                            }}
                          />
                        ) : null}
                      </>
                    );
                  })}
                  {hasMore && (
                    <div
                      ref={loader}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100px",
                        textAlign: "center",
                      }}
                    >
                      Loading more items...
                    </div>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ClientDocumentsAndCommunication;

const SingleEmailView = ({
  data,
  clientData,
  setShowLinkCommunicationDialog,
  setSelectedDataForLinking,
}) => {
  const userDetails = useSelector((state) => state?.auth?.currentUser);
  const [showFullEmail, setShowFullEmail] = useState(false);
  const [isEmailTransfer, setIsEmailTransfer] = useState(false);
  const handleClickEmailTransper = (data) => {
    setIsEmailTransfer(true);
  };

  return (
    <>
      {isEmailTransfer && (
        <TransferClientEmailDialog
          open={isEmailTransfer}
          onClose={() => {
            setIsEmailTransfer(false);
          }}
          selectedEmailId={data?.id}
        />
      )}

      {showFullEmail ? (
        <FullEmailDialog
          emailData={data}
          showFullEmail={showFullEmail}
          setShowFullEmail={() => setShowFullEmail(false)}
          clientData={clientData}
          isClientEmail={true}
        />
      ) : null}
      <div>
        <Grid item sx={{ paddingTop: "0 !important", mb: 2 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography className="normal_normal_600_14_Manrope green">
              From{" "}
            </Typography>
            <Tooltip title="Pin to instruction">
              <IconButton disabled={data?.instructionId !== null}>
                <PushPinIcon
                  size="small"
                  color="action"
                  sx={{
                    color:
                      data?.instructionId !== null ? "lightgrey" : "#00ce3f",
                    fontSize: "18px",
                  }}
                  onClick={() => {
                    setSelectedDataForLinking({
                      regardingType: data?.activity?.regardingType,
                      regardingTypeId: data?.activity?.regardingTypeId,
                    });
                    setShowLinkCommunicationDialog(true);
                  }}
                />
              </IconButton>
            </Tooltip>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography
              className="normal_normal_600_14_Manrope dark_grey"
              style={{
                flexGrow: 1,
                marginRight: "10px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                // whiteSpace: "normal", // Allows the text to wrap to the next line
                wordWrap: "break-word", // Breaks long words if necessary
              }}
            >
              {data?.from}
            </Typography>
          </div>

          <Grid
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography className="normal_normal_600_14_Manrope">
              {data?.createdAt
                ? moment(new Date(data?.createdAt))?.format("DD/MM/YY hh:mm A")
                : "NA"}
            </Typography>
            {/* Transfer email section only for super admin */}
            {userDetails?.role === USER_ROLE?.SUPER_ADMIN && (
              <Tooltip title="Transfer Email">
                <IconButton onClick={() => handleClickEmailTransper(data)}>
                  <TransferWithinAStationIcon />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
        <Grid item sx={{ mb: 2 }}>
          <Typography className="normal_normal_600_14_Manrope green">
            To
          </Typography>
          {data?.toDeliverability?.map((d) => (
            <EmailWithStatus d={d} />
          ))}
        </Grid>
        {data?.ccDeliverability?.length ? (
          <Grid item sx={{ mb: 2 }}>
            <Typography className="normal_normal_600_14_Manrope green">
              CC
            </Typography>
            {data?.ccDeliverability?.map((d) => (
              <EmailWithStatus d={d} />
            ))}
          </Grid>
        ) : null}
        {data?.bccDeliverability?.length ? (
          <Grid item sx={{ mb: 2 }}>
            <Typography className="normal_normal_600_14_Manrope green">
              BCC
            </Typography>
            {data?.bccDeliverability?.map((d) => (
              <EmailWithStatus d={d} />
            ))}
          </Grid>
        ) : null}

        <Grid item sx={{ mb: 2 }}>
          <Typography className="normal_normal_600_14_Manrope green">
            Subject
          </Typography>
          <Typography
            className=" normal_normal_600_14_Manrope dark_grey"
            component={"span"}
          >
            {data?.subject}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            size="small"
            onClick={() => setShowFullEmail(true)}
            className="normal_normal_600_14_Manrope green"
          >
            READ FULL EMAIL
          </Button>
        </Grid>
      </div>
    </>
  );
};
const SingleNoteView = ({
  d,
  index,
  setShowLinkCommunicationDialog,
  setSelectedDataForLinking,
}) => {
  const dispatch = useDispatch();
  const [selectedDocuments, setSelectedDocuments] = useState(null);
  const [showFullFileName, setShowFullFileName] = useState(false);
  const [showAllDocuments, setShowAllDocuments] = useState(false);

  // Method to read image
  const handleReadImage = async (doc, docIndex = null) => {
    try {
      let requiredDocIndex = docIndex === null ? 0 : docIndex;
      const response = await dispatch(
        readImage({
          query: {
            filename:
              doc?.LeadClientDocuments[requiredDocIndex]?.documentUrl ||
              doc?.documentUrl,
          },
        })
      ).unwrap();
    } catch (error) {
      return error;
    }
  };

  const handleClickDownload = async (documentData) => {
    try {
      for (const document of documentData) {
        let path = encodeURIComponent(document?.documentUrl);
        let leadClientId = document?.leadClientId || null;
        let subType =
          document?.subDocumentType?.name || document?.documentType?.name;
        let version = document?.version || null;
        let forClient = `${document?.LeadClient?.firstName}_${document?.LeadClient?.lastName}`;
        let finalUrl = `${process.env.REACT_APP_API_PATH}/file/download-image?path=${path}&filename=${leadClientId}_${forClient}_${subType}`;
        if (version !== null) {
          finalUrl += `&version=${version}`;
        }
        window?.open(finalUrl, "_blank");
      }
    } catch (error) {
      // console.log("error :: ", error);
    }
  };

  // Method to open documents dialog and pass document to component
  const handleClickOpenDocuments = (documents) => {
    // setIsDocuments(true);
    setShowAllDocuments(!showAllDocuments);
    setSelectedDocuments(documents || []);
  };

  return (
    <Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography className="normal_normal_600_14_Manrope dark_grey">
          {`${d?.createdBy?.firstName || "NA"} ${
            d?.createdBy?.lastName || "NA"
          }`}
        </Typography>
        <Typography className="normal_normal_600_14_Manrope">
          {moment(d?.createdAt).format("HH:mm")}
          <span
            style={{
              marginLeft: "0.5rem",
              marginRight: "0.5rem",
            }}
          >
            |
          </span>
          {moment(d?.createdAt).format("MMM DD")}
        </Typography>
        <Tooltip title={"Pin to instruction"}>
          <IconButton disabled={d?.instructionId !== null}>
            <PushPinIcon
              size="small"
              color="action"
              sx={{
                color: d?.instructionId !== null ? "lightgrey" : "#00ce3f",
                fontSize: "18px",
              }}
              onClick={() => {
                setSelectedDataForLinking({
                  regardingType: d?.activity?.regardingType,
                  regardingTypeId: d?.activity?.regardingTypeId,
                });
                setShowLinkCommunicationDialog(true);
              }}
            />
          </IconButton>
        </Tooltip>
      </Grid>

      <Grid item xs={12}>
        <Typography>
          {d?.note ? (
            typeof d?.note === "object" ? (
              Object.keys(d?.note).map((key) => {
                return (
                  <Grid className="normal_normal_16_Manrope" key={key}>
                    {d?.note[key] !== "" ? (
                      <SingleNoteContainer note={d?.note[key]} />
                    ) : (
                      "N/A"
                    )}
                  </Grid>
                );
              })
            ) : (
              <Grid className="normal_normal_16_Manrope">
                {d?.note !== "" ? (
                  <SingleNoteContainer note={d?.note} />
                ) : (
                  "N/A"
                )}
              </Grid>
            )
          ) : (
            <Grid className="normal_normal_16_Manrope">N/A</Grid>
          )}
        </Typography>
      </Grid>
      {/* If Document URL available then show attaatchment application only for old notes. */}
      {/* TO SHOW OLD INSTRUCTIONS DOCS  */}
      {d?.documentUrl && (
        <Grid
          item
          xs={12}
          mt={1}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              color: "rgb(0, 206, 63)",
              cursor: "pointer",
            }}
            onClick={() => handleReadImage(d)}
            className="normal_normal_600_14_Manrope"
          >
            Attached Documents
          </Typography>
          <Grid item>
            <IconButton
              // onClick={() => downloadClientNotesDocument(d, clientData)}
              onClick={() => {}}
            >
              <DownloadIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}

      {d?.LeadClientDocuments?.length ? (
        <Grid
          item
          xs={12}
          mt={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Typography
            style={{
              color: "rgb(0, 206, 63)",
              cursor: "pointer",
            }}
            className="normal_normal_600_14_Manrope"
            onClick={() => handleClickDownload(d?.LeadClientDocuments)}
          >
            Download all
          </Typography>
        </Grid>
      ) : null}

      {/* PREVIEW OF 1'ST DOCUMENT TO SHOW NEW INSTUCTION'S DOCS */}
      {d?.LeadClientDocuments?.length > 0 &&
      !d?.documentUrl &&
      !showAllDocuments ? (
        <Grid
          container
          xs={12}
          sx={{ display: "flex", alignItems: "center" }}
          key={index}
        >
          <Grid
            item
            xs={8}
            key={index}
            style={{ cursor: "pointer" }}
            onClick={() => setShowFullFileName(!showFullFileName)}
          >
            <Tooltip
              title={
                d?.LeadClientDocuments[0]?.fileInfo?.modifiedFileName || ""
              }
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    whiteSpace:
                      showFullFileName === index ? "normal" : "nowrap",
                    overflow: showFullFileName === index ? "visible" : "hidden",
                    textOverflow:
                      showFullFileName === index ? "clip" : "ellipsis",
                  }}
                >
                  {d?.LeadClientDocuments[0]?.fileInfo?.modifiedFileName}
                </div>
              </div>
            </Tooltip>
          </Grid>
          <Grid
            item
            xs={4}
            mt={1}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <IconButton>
              <VisibilityIcon
                color="action"
                onClick={() => handleReadImage(d, index)}
              />
            </IconButton>

            <IconButton
              onClick={() =>
                downloadClientNotesDocument(d?.LeadClientDocuments[0])
              }
            >
              <DownloadIcon />
            </IconButton>
          </Grid>
        </Grid>
      ) : null}

      {/* PREVIEW OF ALL DOCUMENT TO SHOW NEW INSTUCTION'S DOCS */}
      {d?.LeadClientDocuments?.length > 0 && !d?.documentUrl && showAllDocuments
        ? d?.LeadClientDocuments?.map((doc, docIndex) => {
            return (
              <Grid
                container
                xs={12}
                sx={{ display: "flex", alignItems: "center" }}
                key={docIndex}
              >
                <Grid
                  item
                  xs={8}
                  key={docIndex}
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowFullFileName(!showFullFileName)}
                >
                  <Tooltip title={doc?.fileInfo?.modifiedFileName || ""}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          whiteSpace:
                            showFullFileName === docIndex ? "normal" : "nowrap",
                          overflow:
                            showFullFileName === docIndex
                              ? "visible"
                              : "hidden",
                          textOverflow:
                            showFullFileName === docIndex ? "clip" : "ellipsis",
                        }}
                      >
                        {doc?.fileInfo?.modifiedFileName}
                      </div>
                    </div>
                  </Tooltip>
                </Grid>
                <Grid
                  item
                  xs={4}
                  mt={1}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <IconButton>
                    <VisibilityIcon
                      color="action"
                      onClick={() => handleReadImage(d, docIndex)}
                    />
                  </IconButton>

                  <IconButton onClick={() => downloadClientNotesDocument(doc)}>
                    <DownloadIcon />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })
        : null}

      {/* BTN TO SHOW ALL DOCS WHEN NO OF DOCS GREATER THAN 1 */}
      {d?.LeadClientDocuments?.length > 1 && (
        <>
          <Grid
            item
            xs={12}
            mt={1}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Typography
              style={{
                color: "rgb(0, 206, 63)",
                cursor: "pointer",
              }}
              className="normal_normal_600_14_Manrope"
              onClick={() =>
                handleClickOpenDocuments(d?.InstructionTaskDocuments || [])
              }
            >
              Load {showAllDocuments ? "Less" : " More"}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};
