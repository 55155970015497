import { Grid } from "@mui/material";
import { useFieldArray } from "react-hook-form";
import { RemoveButton } from "../../../../../components/buttons";
import { RadioInput, TextInput } from "../../../../../components/inputs";
import AddingNewBeneficiary from "./AddingNewBeneficiary";
import GeneralResidueDistribution from "./GeneralResidueDistribution";
import Restrictions from "./Restrictions";
import SpecialBenefitInterest from "./SpecialBenefitInterest";
import SpecificDistribution from "./SpecificDistribution";
import DefaultBeneficiary from "./UltimateBeneficiary";

const GuidanceToTrustees = ({ arr, isJoint, trustIndex }) => {
  const trustees = useFieldArray({
    name: `InstructionTrustQuestionnaire.[${trustIndex}].trustee`,
  });

  const { append, remove, fields } = trustees;

  const handleAdd = () => {
    append();
  };

  const handleRemove = (ind) => {
    remove(ind);
  };

  return (
    <>
      <Grid
        width={"100%"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        borderTop={`1px solid #DCDCE1`}
        borderBottom={`1px solid #DCDCE1`}
      >
        <Grid
          width={"100%"}
          paddingY={4}
          display={"flex"}
          flexDirection={"column"}
          gap={0}
          // border={"1px solid red"}
        >
          <SpecificDistribution
            arr={arr}
            isJoint={isJoint}
            trustIndex={trustIndex}
          />
          <SpecialBenefitInterest
            arr={arr}
            isJoint={isJoint}
            trustIndex={trustIndex}
          />
          <GeneralResidueDistribution
            arr={arr}
            isJoint={isJoint}
            trustIndex={trustIndex}
          />
          <DefaultBeneficiary
            arr={arr}
            isJoint={isJoint}
            trustIndex={trustIndex}
          />
          <AddingNewBeneficiary
            arr={arr}
            isJoint={isJoint}
            trustIndex={trustIndex}
          />
          <Restrictions arr={arr} isJoint={isJoint} trustIndex={trustIndex} />
        </Grid>
      </Grid>
    </>
  );
};

export default GuidanceToTrustees;

const GuidanceToTrusteesForm = ({ handleRemove, pathName, cardNo }) => {
  return (
    <Grid
      boxShadow={"1px 2px 6px #00000029"}
      borderBottom={`1px solid #DCDCE1`}
      borderRadius={"16px"}
      padding={2}
      paddingY={3}
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      position={"relative"}
    >
      <Grid
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Grid width={"48%"}>
          <TextInput
            label={`Trustee ${cardNo + 1}'s Full Name`}
            name={`${pathName}.trusteeName`}
            placeholder="Enter name"
          />
        </Grid>

        <Grid width={"48%"}>
          <RadioInput
            name={`${pathName}.isProfessionalCapacity`}
            label="Acting in a professional capacity?"
            options={[
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
          />
        </Grid>
      </Grid>
      <div style={{ textAlign: "right" }}>
        <RemoveButton label={"Joint"} onClick={handleRemove} />
      </div>
    </Grid>
  );
};
