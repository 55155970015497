import { Grid, Typography } from "@mui/material";
import { useFieldArray, useForm, useFormContext } from "react-hook-form";
import { AddButton, RemoveButton } from "../../../../../components/buttons";
import {
  NumberInput,
  RadioInput,
  TextInput,
} from "../../../../../components/inputs";
import { VALIDATION_FORMAT } from "../../../../../utils/constant";

const GeneralResidueDistribution = ({ arr, isJoint, trustIndex }) => {
  const methods = useFormContext();
  const { control, getValues } = methods;
  const generalDistributionArray = useFieldArray({
    control,
    name: `InstructionTrustQuestionnaire.[${trustIndex}].generalDistribution.generalResidueDistribution`,
  });

  return (
    <>
      <Grid
        width={"100%"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Grid
          width={"100%"}
          paddingY={4}
          display={"flex"}
          flexDirection={"column"}
          borderTop={`1px solid #DCDCE1`}
          borderBottom={`1px solid #DCDCE1`}
          gap={0}
          // border={"1px solid red"}
        >
          <Typography className="normal_normal_semibold_20_Manrope dark_grey">
            General / Residue Distribution
          </Typography>
        </Grid>

        <Grid
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          borderTop={`1px solid #DCDCE1`}
          justifyContent={"center"}
        >
          {" "}
          {arr?.map((item, index) => {
            return (
              <Grid
                key={item}
                width={!isJoint ? "60%" : "100%"}
                borderRight={
                  index === 0 && isJoint ? `1px solid #DCDCE1` : null
                }
                paddingY={4}
                display={"flex"}
                flexDirection={"column"}
                gap={3}
                paddingRight={index === 0 ? 2 : 0}
                paddingLeft={index === 1 ? 2 : 0}
              >
                {/* form  */}
                <GeneralFormParent
                  fieldPathName={`InstructionTrustQuestionnaire.[${trustIndex}].generalDistribution.generalResidueDistribution.${index}`}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
};

const GeneralFormParent = ({ fieldPathName }) => {
  const beneficiariesArray = useFieldArray({
    name: `${fieldPathName}.beneficiary`,
  });

  const { fields, append, remove } = beneficiariesArray;

  const handleAdd = () => {
    append({
      name: "",
      classification: "Class of Beneficiary",
      perStripes: false,
      age: 18,
      percentageShare: 0,
    });
  };
  const handleRemove = (index) => {
    remove(index);
  };

  return (
    <Grid
      width={"100%"}
      paddingY={4}
      display={"flex"}
      flexDirection={"column"}
      gap={3}
      // border={"1px solid red"}
    >
      <Grid display={"flex"} flexDirection={"column"} gap={0}>
        <Typography className="manropeRegular16" sx={{ color: "#00CE3F" }}>
          Trustees should use their discretion to distribute Trust assets to the
          following beneficiaries on the basis of?
        </Typography>
        <RadioInput
          name={`${fieldPathName}.trustAssetBasisOf`}
          label=""
          options={[
            { label: "Percentage", value: "Percentage" },
            { label: "Equal", value: "Equal" },
          ]}
        />
      </Grid>
      {fields.map((card, ind) => {
        return (
          <GeneralResidueDistributionForm
            key={card.id}
            cardNo={ind}
            handleRemove={() => handleRemove(ind)}
            pathName={`${fieldPathName}.beneficiary.${ind}`}
          />
        );
      })}
      <Grid textAlign={"center"}>
        <AddButton onClick={handleAdd} />
      </Grid>
    </Grid>
  );
};

export default GeneralResidueDistribution;

const GeneralResidueDistributionForm = ({ handleRemove, pathName, cardNo }) => {
  return (
    <Grid
      boxShadow={"1px 2px 6px #00000029"}
      borderBottom={`1px solid #DCDCE1`}
      borderRadius={"16px"}
      padding={2}
      paddingY={3}
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      position={"relative"}
    >
      <Grid
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Grid
          width={"100%"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          gap={1}
        >
          <Grid
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            gap={2}
          >
            <Grid width={"100%"} display={"flex"} flexDirection={"row"} gap={1}>
              <TextInput
                name={`${pathName}.name`}
                label={`Beneficiary ${cardNo + 1}`}
                placeholder="Enter name"
              />
            </Grid>
            <Grid width={"100%"}>
              <RadioInput
                name={`${pathName}.classification`}
                label="Beneficiaries Type"
                options={[
                  { label: "Named Beneficiary", value: "Named Beneficiary" },
                  {
                    label: "Class of Beneficiary",
                    value: "Class of Beneficiary",
                  },
                ]}
              />
            </Grid>
          </Grid>
          <Grid
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            gap={2}
            border={"0px solid red"}
          >
            <Grid
              width={"100%"}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <Grid width={"31%"}>
                <RadioInput
                  name={`${pathName}.perStripes`}
                  label="Per Stirpes"
                  options={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                />
              </Grid>
              <Grid width={"31%"}>
                <TextInput
                  name={`${pathName}.ageOfInheritance`}
                  label="Age of Inheritance"
                  placeholder="Enter age"
                />
              </Grid>
              <Grid width={"31%"}>
                <NumberInput
                  name={`${pathName}.percentageShare`}
                  label="Percentage Share"
                  placeholder="Enter value"
                  rules={{
                    pattern: {
                      value: VALIDATION_FORMAT.percentageOnly.pattern,
                      message: VALIDATION_FORMAT.percentageOnly.message,
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ textAlign: "right" }}>
        <RemoveButton onClick={handleRemove} />
      </div>
    </Grid>
  );
};
