import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";

const QcPercentageDialog = ({ open, handleClose, percentage }) => {
  return (
    <>
      <Dialog
        sx={{ border: 1 }}
        open={open}
        fullWidth
        maxWidth={"xs"}
        PaperProps={{
          sx: {
            borderRadius: "28px",
            paddingBottom: 0,
            height: "auto",
            width: "80%",
          },
        }}
      >
        <DialogTitle>
          {" "}
          <Typography
            className="serif_display_regular_26 green"
            textAlign={"center"}
          >
            The score based on your QC is
          </Typography>
        </DialogTitle>

        <DialogContent dividers={true}>
          <>
            <Grid
              container
              spacing={3}
              pb={3}
              sx={{ display: "flex", justifyContent: "center", border: 0 }}
            >
              <Grid item xs={12}>
                <Typography
                  className="normal_normal_34_Manrope green"
                  align="center"
                >
                  {`${percentage?.totalObtainedMarks || 0} / ${
                    percentage?.totalInstructionQcRating || 0
                  }`}
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  className="normal_normal_medium_14_Manrope"
                  variant="contained"
                  style={{
                    boxShadow: "none",
                    backgroundColor: "#00ce3f",
                    borderRadius: "24px",
                  }}
                  onClick={handleClose}
                >
                  Okay
                </Button>
              </Grid>
            </Grid>
          </>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default QcPercentageDialog;
