import React, { useEffect, useState } from "react";
import Loader from "../../../../components/Loader";
import {
  Button,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import DataGridComponent from "../../../../components/DataGridComponent";
import { showSnackbar } from "../../../../slice/snackbarSlice";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import {
  addCampaignData,
  getLookups,
  updateCampaignData,
} from "../../../../thunks/lookup";
import {
  NOTIFICATION_MESSAGE,
  VALIDATION_MSG,
} from "../../../../utils/constant";

const ClientStatus = () => {
  const dispatch = useDispatch();
  const [isLoader, setLoader] = useState(false);
  const [isError, setIsError] = useState({
    isRequired: false,
  });
  const [filterData, setFilterData] = useState([]);
  const lookups = useSelector((state) => state?.lookupData);
  let { "Lead Status": leadStatus } = lookups || {};
  const lookupTypeId = leadStatus?.[0]?.lookuptypeid;

  const [clientStatus, setClientStatus] = useState({
    name: "",
  });

  // Method to update status from the table row
  const handleUpdateStatus = (data) => {
    setClientStatus({
      id: data?.id,
      name: data?.name,
    });
  };

  // Table columns
  const columns = [
    {
      field: "name",
      headerName: " Name",
      width: 250,
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => (
        <>
          <Tooltip title="Update Task">
            <IconButton onClick={() => handleUpdateStatus(params?.row)}>
              <EditIcon className="green" />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  // Fetching status data from lookups
  const getStatusData = async () => {
    try {
      setLoader(true);
      const lookupKeys = ["Lead Status"];
      const response = await dispatch(
        getLookups({ searchStrings: JSON.stringify(lookupKeys) })
      ).unwrap();
      if (response) {
        const filteredLookup =
          response?.[0]?.Lookup?.filter(
            (lookup) => lookup?.name && lookup?.id
          ) || [];

        setFilterData(filteredLookup);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      dispatch(
        showSnackbar({
          show: true,
          message: `${NOTIFICATION_MESSAGE?.SOMETHING_WENT_WRONG}`,
          severity: "error",
        })
      );
    }
  };

  // Handle input field changes
  const handleChange = (data, key) => {
    setClientStatus((prevStatus) => ({
      ...prevStatus,
      [key]: data,
    }));
  };

  // Save or update client status
  const handleSaveStatus = async () => {
    if (!clientStatus?.name) {
      setIsError({
        ...isError,
        isRequired: true,
      });
    } else {
      setIsError({
        ...isError,
        isRequired: false,
      });
      try {
        setLoader(true);
        const payload = {
          name: clientStatus?.name,
          lookupId: clientStatus?.id || 0,
          id: lookupTypeId,
        };

        let res;
        if (clientStatus?.id) {
          // Update
          res = await dispatch(updateCampaignData({ ...payload })).unwrap();
        } else {
          // Create
          res = await dispatch(addCampaignData(payload)).unwrap();
        }

        if (res) {
          // Refresh the status data and reset form
          getStatusData();
          setClientStatus({ name: "", id: null });
          dispatch(
            showSnackbar({
              show: true,
              message: `${
                clientStatus?.id
                  ? NOTIFICATION_MESSAGE?.CLIENT_STATUS_SUCESS
                  : NOTIFICATION_MESSAGE?.CLIENT_STATUS_CREATE
              }`,
              severity: "success",
            })
          );
        }
        setLoader(false);
      } catch (error) {
        setLoader(false);
        dispatch(
          showSnackbar({
            show: true,
            message: `${error?.message}`,
            severity: "error",
          })
        );
      }
    }
  };

  useEffect(() => {
    getStatusData();
  }, []);

  return (
    <Grid container spacing={3}>
      {isLoader && <Loader />}
      <Grid item xs={7} mt={2}>
        <Grid container xs={12}>
          <Grid item xs={12}>
            <Typography className="serif_display_regular_22 green">
              Client Status
            </Typography>
          </Grid>
          <Grid
            container
            spacing={2}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid item xs={3}>
              <TextField
                name="name"
                label="Name"
                className="inputTextField border_color"
                variant="standard"
                size="small"
                value={clientStatus?.name}
                onChange={(event) => handleChange(event?.target?.value, "name")}
                error={isError?.isRequired && !clientStatus?.name}
                helperText={
                  isError?.isRequired &&
                  !clientStatus?.name &&
                  VALIDATION_MSG.REQUIRED
                }
                required
                fullWidth
              />
            </Grid>
            <Grid
              item
              mt={3}
              mb={3}
              xs={6}
              sx={{ display: "flex", justifyContent: "flex-end" }}
              border={0}
            >
              <Button className="save_button" onClick={handleSaveStatus}>
                {clientStatus?.id ? "Update" : "Save"}
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={6} style={{ width: "100%", height: "70vh" }}>
            <DataGridComponent
              columns={columns}
              rows={filterData}
              onRowClick={() => {}}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ClientStatus;
