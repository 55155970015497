import { createAsyncThunk } from "@reduxjs/toolkit";
import getAxios from "../helpers/axiosInterceptor";
import { API_ROUTES_PATHS } from "../utils/constant";

// Method to Get Product API
export const getProduct = createAsyncThunk(
  "product/getProduct",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(API_ROUTES_PATHS.GET_PRODUCTS);

      const responseData = res?.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method Get Product questionnaire API
export const getProductQuestionnaireAsPerInstructionType = createAsyncThunk(
  "product/getProductQuestionnaireAsPerInstructionType",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.GET_PRODUCT_QUES_TASK_DISBUS_BY_INSTRUCTION_TYPE}/${model}/questionnaire`
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to Get Product tasks API
export const getProductTaskAsPerInstructionType = createAsyncThunk(
  "product/getProductTaskAsPerInstructionType",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.GET_PRODUCT_QUES_TASK_DISBUS_BY_INSTRUCTION_TYPE}/${model}/task`
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to Get Product tasks API
export const getProductDisbursementAsPerInstructionType = createAsyncThunk(
  "product/getProductDisbursementAsPerInstructionType",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.GET_PRODUCT_QUES_TASK_DISBUS_BY_INSTRUCTION_TYPE}/${model}/disbursementfeeslookup`
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to Create Product API
export const createProduct = createAsyncThunk(
  "product/createProduct",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().patch(API_ROUTES_PATHS.CREATE_PRODUCT, model);

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to Update Product API
export const updateProduct = createAsyncThunk(
  "product/updateProduct",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().patch(API_ROUTES_PATHS.UPDATE_PRODUCT, model);
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to Create Get Product By ID  API
export const getProductById = createAsyncThunk(
  "product/getProductById",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.GET_PRODUCT_BY_ID}/${model}`
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to Get All Product questionnaire API
export const getProductQuestionnaire = createAsyncThunk(
  "product/getProductQuestionnaire",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(API_ROUTES_PATHS.GET_ALL_PROD_QUESTIONS);

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to Update Product Task
export const updateProductTask = createAsyncThunk(
  "product/updateProductTask",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().patch(
        `${API_ROUTES_PATHS.UPDATE_PRODUCT_TASK}/${model?.productId}/updateProductsTasks`,
        model?.taskIdArr
      );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);
