import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Loader from "../../../components/Loader";
import CloseIcon from "@mui/icons-material/Close";
import CircleIcon from "@mui/icons-material/Circle";
import { useDispatch } from "react-redux";
import { linkClientCommunication } from "../../../thunks/client";
import DataGridComponent from "../../../components/DataGridComponent";
import { useParams } from "react-router-dom";
import { NOTIFICATION_MESSAGE } from "../../../utils/constant";
import { showSnackbar } from "../../../slice/snackbarSlice";

const LinkCommunicationDialog = ({
  handleClose,
  open,
  handleSave,
  clientInstructions,
  regardingType,
  regardingTypeId,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [loader, setLoader] = useState(false);

  const columns = [
    {
      field: "clients",
      headerName: "Clients",
      minWidth: 250,
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography className="normal_normal_16_Manrope dark_grey">
            {params?.row?.name}
          </Typography>
        );
      },
    },
    {
      field: "InstructionPlanSelectionsAndIndividualProducts",
      headerName: "Plans & Products",
      minWidth: 350,
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography className="normal_normal_16_Manrope dark_grey">
            {
              params?.row?.InstructionPlanSelections
                ?.InstructionPlanSelectionsAndIndividualProducts
            }
          </Typography>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 200,
      sortable: false,
    },

    {
      field: "action",
      headerName: "Action",
      width: 90,
      sortable: false,
      renderCell: (params) => {
        return (
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              size="small"
              fullWidth
              variant="outlined"
              className="meeting_log_btn normal_normal_semibold_14_Manrope green"
              style={{
                borderRadius: "24px",
                margin: 1,
                border: "1px solid #00ce3f",
              }}
              onClick={() => {
                handleUpdate(params?.row?.id);
              }}
            >
              Link
            </Button>
          </Grid>
        );
      },
    },
  ];

  const handleUpdate = async (instructionId) => {
    setLoader(true);
    try {
      let payload = {
        regardingType,
        regardingTypeId,
        linkingId: instructionId,
        id: params?.id,
      };

      let response = await dispatch(linkClientCommunication(payload)).unwrap();

      if (response) {
        // call to get client communication data in main parent component
        handleSave();
        setLoader(false);
        dispatch(
          showSnackbar({
            message: NOTIFICATION_MESSAGE?.SUCCESS_CLIENT_UPDATE,
            severity: "success",
          })
        );
        return;
      }
      setLoader(false);
      dispatch(
        showSnackbar({
          message: "Failed to update",
          severity: "error",
        })
      );
    } catch (error) {
      setLoader(false);
      dispatch(
        showSnackbar({
          message: error.message,
          severity: "error",
        })
      );
    }
  };

  return (
    <Dialog onClose={() => handleClose()} open={open} fullWidth maxWidth="lg">
      <IconButton
        aria-label="close"
        onClick={() => handleClose()}
        sx={{
          position: "absolute",
          right: 8,
          top: 10,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle textAlign={"center"}>
        <Typography className="serif_display_regular_26 green">
          Link Communication to Instruction
        </Typography>
      </DialogTitle>
      <DialogContent>
        {loader && <Loader />}
        <Grid
          style={{
            // minHeight: "300px",
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <DataGridComponent
            columns={columns}
            rows={clientInstructions || []}
            onRowClick={() => {}}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default LinkCommunicationDialog;
