import { createSlice } from "@reduxjs/toolkit";

const initState = {
  isNote: false,
  isAnniversaryDrawer:false
};

const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState: initState,
  reducers: {
    updateNotesDrawer: (state, action) => {
      const tempData = JSON.parse(JSON.stringify(state?.isNote));
      state.isNote = !tempData;
    },
    updateAnniversariesDrawer: (state, action) => {
      const tempData = JSON.parse(JSON.stringify(state?.isAnniversaryDrawer));
      state.isAnniversaryDrawer = !tempData;
    },
  },
  extraReducers: {},
});
export const { updateNotesDrawer, updateAnniversariesDrawer } = dashboardSlice.actions;
export default dashboardSlice.reducer;
