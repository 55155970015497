import { createAsyncThunk } from "@reduxjs/toolkit";
import getAxios from "../helpers/axiosInterceptor";
import { API_ROUTES_PATHS } from "../utils/constant";

// Method to get Milestone API
export const getMilestone = createAsyncThunk(
  "lookup/getMilestone",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(API_ROUTES_PATHS.GET_MILESTONE);

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to Get LOOKUP API
export const getLookups = createAsyncThunk(
  "lookup/getLookups",
  async ({ searchStrings, ...rest }, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.LOOKUP_ROOT}/${searchStrings}`
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to add campaign type
export const addCampaignData = createAsyncThunk(
  "campaign/addCampaignData",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().patch(
        `${API_ROUTES_PATHS.LOOKUP_ROOT}/${model?.id}/lookup/${0}`,
        model
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to update campaign type
export const updateCampaignData = createAsyncThunk(
  "campaign/updateCampaignData",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().patch(
        `${API_ROUTES_PATHS.LOOKUP_ROOT}/${model?.id}/lookup/${model?.lookupId}`,
        model
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// get XERO option set to create campaign
export const xeroOptionSet = createAsyncThunk(
  "xero/xeroOptionSet",
  async (model, thunkApi) => {
    try {
      const response = await getAxios().get(
        `${API_ROUTES_PATHS.GET_XERO_OPTION_SET}`,
        { params: model?.query }
      );
      const responseData = response.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);
