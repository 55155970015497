import { Grid } from "@mui/material";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  AddButton,
  ContainedGreenButton,
  OutlinedGreenButton,
  RemoveButton,
} from "../../../../../components/buttons";
import { DropdownInput, TextInput } from "../../../../../components/inputs";
import { executorAndTrusteeFormObject } from "./willQuestionnaireFormConstants";

const ExecutorAndTrustee = () => {
  const isJoint = useSelector((state) => state?.instruction?.isJoint);
  const arr = useSelector((state) => state?.instruction?.arr);

  const client1 = useFieldArray({
    name: `InstructionWillQuestionnaire.[0].executorsAndTrustees`,
  });

  const { append, remove, fields } = client1;

  const client2 = useFieldArray({
    name: `InstructionWillQuestionnaire.[1].executorsAndTrustees`,
  });

  const {
    append: secondaryAppend,
    remove: secondaryRemove,
    fields: secondaryFields,
  } = client2;

  const handleAdd = (clientNumber) => {
    clientNumber === 0
      ? append(executorAndTrusteeFormObject)
      : secondaryAppend(executorAndTrusteeFormObject);
  };

  const handleRemove = (clientNumber, ind) => {
    if (clientNumber === 1) {
      remove(ind);
    } else {
      secondaryRemove(ind);
    }
  };

  return (
    <>
      <Grid
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={isJoint ? "space-between" : "center"}
        borderTop={`1px solid #DCDCE1`}
        borderBottom={`1px solid #DCDCE1`}
      >
        {arr?.map((item, index) => {
          return (
            <Grid
              width={isJoint ? "50%" : "62%"}
              borderRight={index === 0 && isJoint ? `1px solid #DCDCE1` : null}
              paddingY={4}
              display={"flex"}
              flexDirection={"column"}
              gap={3}
              paddingRight={index === 0 ? 2 : 0}
              paddingLeft={index === 1 ? 2 : 0}
            >
              {(item === 1 ? fields : secondaryFields).map((card, ind) => {
                return (
                  <ExecutorAndTrusteeForm
                    key={card.id}
                    isJoint={isJoint}
                    index={index}
                    cardNo={ind}
                    handleRemove={() => handleRemove(item, ind)}
                    isApplicationJoint={isJoint}
                  />
                );
              })}
              <Grid textAlign={"center"}>
                <AddButton onClick={() => handleAdd(index)} />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default ExecutorAndTrustee;

const ExecutorAndTrusteeForm = ({
  index,
  cardNo,
  handleRemove,
  isApplicationJoint,
}) => {
  const { getValues, setValue } = useFormContext();
  let { "Executers & Trustee --> Role": executorsRoles } = useSelector(
    (state) => state?.lookupData
  );
  executorsRoles = executorsRoles?.length
    ? executorsRoles?.map((obj) => ({ label: obj?.name, value: obj?.name }))
    : [];
  const handleJoint = () => {
    let isJoint =
      getValues(
        `InstructionWillQuestionnaire.${index}.executorsAndTrustees.${cardNo}.isJoint`
      ) || false;
    setValue(
      `InstructionWillQuestionnaire.${index}.executorsAndTrustees.${cardNo}.isJoint`,
      !isJoint
    );
  };

  const isCardJoint = useWatch({
    name: `InstructionWillQuestionnaire.${index}.executorsAndTrustees.${cardNo}.isJoint`,
  });
  return (
    <Grid
      boxShadow={"1px 2px 6px #00000029"}
      borderBottom={`1px solid #DCDCE1`}
      borderRadius={"16px"}
      borderRight={index === 0 ? `1px solid #DCDCE1` : null}
      padding={2}
      paddingY={3}
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      position={"relative"}
    >
      {index === 0 && isApplicationJoint ? (
        <div className="card-mirror-btn">
          {isCardJoint ? (
            <ContainedGreenButton label={"Joint"} onClick={handleJoint} />
          ) : (
            <OutlinedGreenButton label={"Joint"} onClick={handleJoint} />
          )}
        </div>
      ) : null}
      <TextInput
        label={`Full Name`}
        name={`InstructionWillQuestionnaire.${index}.executorsAndTrustees.${cardNo}.fullName`}
        placeholder="Enter name"
      />

      <DropdownInput
        name={`InstructionWillQuestionnaire.${index}.executorsAndTrustees.${cardNo}.role`}
        label="Role"
        options={executorsRoles}
      />
      <div style={{ textAlign: "right" }}>
        <RemoveButton label={"Joint"} onClick={handleRemove} />
      </div>
    </Grid>
  );
};
