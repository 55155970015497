import { Grid, Typography } from "@mui/material";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import {
  AddButton,
  ContainedGreenButton,
  OutlinedGreenButton,
  RemoveButton,
} from "../../../../../../components/buttons";
import {
  DropdownInput,
  RadioInput,
  TextInput,
} from "../../../../../../components/inputs";
import { severancePropertiesObject } from "../willQuestionnaireFormConstants";

const SOTDOSItem = ({ arr, isJoint, deed_dropdown_values }) => {
  const client1 = useFieldArray({
    name: `InstructionWillQuestionnaire.[0].willTrusts.severanceOfTenancy`,
  });

  const { append, remove, fields } = client1;

  const client2 = useFieldArray({
    name: `InstructionWillQuestionnaire.[1].willTrusts.severanceOfTenancy`,
  });

  const {
    append: secondaryAppend,
    remove: secondaryRemove,
    fields: secondaryFields,
  } = client2;

  const handleAdd = (clientNumber) => {
    clientNumber === 0
      ? append(severancePropertiesObject)
      : secondaryAppend(severancePropertiesObject);
  };

  const handleRemove = (clientNumber, ind) => {
    if (clientNumber === 1) {
      remove(ind);
    } else {
      secondaryRemove(ind);
    }
  };

  return (
    <>
      <Grid width={"100%"}>
        <Grid
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          borderBottom={`1px solid #DCDCE1`}
          padding={2}
        >
          <Grid width={"85%"}>
            <Typography>Severance of Tenancy / Deed of Severance</Typography>
          </Grid>
          <Grid width={"15%"}>
            <RadioInput
              label=""
              name={`InstructionWillQuestionnaire.[0].willTrusts.isSOT`}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
            />
          </Grid>
        </Grid>

        <Grid
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={isJoint ? "space-between" : "center"}
          borderTop={`1px solid #DCDCE1`}
          borderBottom={`1px solid #DCDCE1`}
        >
          {arr?.map((item, index) => {
            return (
              <Grid
                width={isJoint ? "50%" : "62%"}
                borderRight={
                  index === 0 && isJoint ? `1px solid #DCDCE1` : null
                }
                padding={2}
                display={"flex"}
                flexDirection={"column"}
                gap={2}
              >
                <Grid width={"100%"} display={"flex"} flexDirection={"row"}>
                  <Grid
                    width={"100%"}
                    paddingY={4}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={3}
                  >
                    {(index === 0 ? fields : secondaryFields).map(
                      (card, ind) => {
                        return (
                          <SOTDOSForm
                            isInstructionJoint={isJoint}
                            deed_dropdown_values={deed_dropdown_values}
                            index={index}
                            cardNo={ind}
                            pathName={`InstructionWillQuestionnaire.[${index}].willTrusts.severanceOfTenancy.[${ind}]`}
                            handleRemove={() => handleRemove(item, ind)}
                          />
                        );
                      }
                    )}
                    <Grid textAlign={"center"}>
                      <AddButton onClick={() => handleAdd(index)} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
};

export default SOTDOSItem;

const SOTDOSForm = ({
  index,
  pathName,
  handleRemove,
  isInstructionJoint,
  deed_dropdown_values,
}) => {
  const { getValues, setValue } = useFormContext();
  const handleJoint = () => {
    let isJoint = getValues(`${pathName}.isJoint`) || false;
    setValue(`${pathName}.isJoint`, !isJoint);
  };

  const isCardJoint = useWatch({
    name: `${pathName}.isJoint`,
  });
  return (
    <Grid
      boxShadow={"1px 2px 6px #00000029"}
      borderBottom={`1px solid #DCDCE1`}
      borderRadius={"16px"}
      borderRight={index === 0 ? `1px solid #DCDCE1` : null}
      padding={2}
      paddingY={3}
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      position={"relative"}
    >
      {index === 0 && isInstructionJoint ? (
        <div className="card-mirror-btn">
          {isCardJoint ? (
            <ContainedGreenButton label={"Joint"} onClick={handleJoint} />
          ) : (
            <OutlinedGreenButton label={"Joint"} onClick={handleJoint} />
          )}
        </div>
      ) : null}
      {/* todo drp deed_dropdown_values */}
      <DropdownInput
        name={`${pathName}.property.name`}
        label="Property"
        options={deed_dropdown_values?.length ? deed_dropdown_values : []}
      />

      <Grid
        display={"flex"}
        width={"100%"}
        justifyContent={"space-between"}
        gap={2}
      >
        <Grid width={"48%"}>
          <TextInput
            label={`Title number of property`}
            name={`${pathName}.titleNumber`}
            placeholder="Enter name"
          />
        </Grid>
        <Grid width={"48%"}>
          <TextInput
            label={`Percentage Share`}
            name={`${pathName}.percentageShare`}
            placeholder="Enter name"
          />
        </Grid>
      </Grid>
      <RadioInput
        label=""
        name={`${pathName}.type`}
        options={[
          { label: "Freehold", value: "Freehold" },
          { label: "Leasehold", value: "Leasehold" },
        ]}
      />

      <div style={{ textAlign: "right" }}>
        <RemoveButton label={"Joint"} onClick={handleRemove} />
      </div>
    </Grid>
  );
};
