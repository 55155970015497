import {
  Box,
  Chip,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { getClientAnniversaries } from "../../thunks/client";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Loader from "../../components/Loader";

const AnniversariesDrawer = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoader, setLoader] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [queryPayload, setQueryPayload] = useState({
    weekOffset: 0,
    page: 1,
    limit: 50,
  });
  const loader = useRef(null);
  const observer = useRef(null);

  // Function to disconnect observer
  const disconnectObserver = () => {
    if (observer.current && loader.current) {
      observer.current.unobserve(loader.current);
    }
  };

  // Function to reconnect observer
  const reconnectObserver = () => {
    if (observer.current && loader.current && hasMore) {
      observer.current.observe(loader.current);
    }
  };

  // Function to handle arrow btn's
  const handleArrowBtn = async (direction) => {
    // Disconnect observer temporarily
    disconnectObserver();

    // Reset states
    setIsLoadingMore(false);
    setHasMore(true);
    setData([]);

    // Update query payload
    setQueryPayload({
      weekOffset: 0,
      page: 1,
      limit: 50,
      monthDirection: direction,
    });
  };
  /* HandleClose drawer */
  const handleCloseDrawer = () => {
    setData([]);
    disconnectObserver();
    handleClose();
  };

  const handleGetAnniversaries = async () => {
    if (isLoadingMore) return;

    setIsLoadingMore(true);
    setLoader(true);

    try {
      const response = await dispatch(
        getClientAnniversaries(queryPayload)
      ).unwrap();

      if (response?.data?.length > 0) {
        setData((prevData) => {
          if (queryPayload.page === 1) {
            setHasMore(response?.total > response?.data?.length);
            return response?.data;
          } else {
            const temp = [...prevData, ...response?.data];
            setHasMore(response?.total > temp?.length);
            return temp;
          }
        });
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching anniversaries:", error);
      setHasMore(false);
    } finally {
      setLoader(false);
      setIsLoadingMore(false);
      // Reconnect observer after data is loaded
      reconnectObserver();
    }
  };

  // Initialize Intersection Observer
  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !isLoadingMore) {
          setQueryPayload((prev) => ({
            ...prev,
            page: prev.page + 1,
          }));
        }
      },
      {
        threshold: 0.1,
        rootMargin: "100px",
      }
    );

    return () => {
      disconnectObserver();
    };
  }, []);

  // Reconnect observer when hasMore changes
  useEffect(() => {
    if (hasMore && !isLoadingMore) {
      reconnectObserver();
    }
  }, [hasMore]);

  // Fetch data when queryPayload changes
  useEffect(() => {
    handleGetAnniversaries();
  }, [queryPayload]);

  // Reset states when drawer closes
  useEffect(() => {
    if (!open) {
      setData([]);
      setHasMore(true);
      setIsLoadingMore(false);
      disconnectObserver();
    }
  }, [open]);

  return (
    <Drawer
      className="sidebar_comment_drawer"
      anchor="right"
      open={open}
      onClose={handleCloseDrawer}
    >
      <Box sx={{ width: 600, padding: "1rem" }} role="presentation">
        {/* Header */}
        {isLoadingMore && <Loader />}
        <Grid
          container
          spacing={2}
          p={1}
          m={-2}
          style={{
            backgroundColor: "#e5faeb",
            position: "fixed",
            zIndex: 10000,
          }}
        >
          <Grid item xs={12} display={"flex"} alignItems={"center"} gap={2}>
            <Typography className="serif_display_regular_26 green">
              Anniversaries{" "}
            </Typography>
            (
            {queryPayload?.weekOffset === 0
              ? "Current Week"
              : queryPayload?.weekOffset > 0
              ? `${queryPayload?.weekOffset} week${
                  queryPayload?.weekOffset > 1 ? "s" : ""
                } ahead`
              : `${queryPayload?.weekOffset.toString().slice(1)} week${
                  queryPayload?.weekOffset < -1 ? "s" : ""
                } ago`}
            )
          </Grid>
        </Grid>

        {/* Navigation Chips */}
        <Grid
          container
          spacing={2}
          mt={6}
          display={"flex"}
          justifyContent={"flex-start"}
          px={2}
          position={"fixed"}
          backgroundColor={"white"}
          zIndex={999}
        >
          <Grid
            display="flex"
            alignItems="center"
            justifyContent={"space-between"}
            width={"100%"}
          >
            <Grid
              border={0}
              sx={{ ml: 2 }}
              display="flex"
              alignItems="center"
              gap={2}
            >
              <Chip
                label="Previous Week"
                onClick={() => {
                  disconnectObserver();
                  setQueryPayload((prev) => ({
                    ...prev,
                    page: 1,
                    weekOffset: prev.weekOffset - 1,
                    monthDirection: "stay",
                  }));
                }}
                sx={{ zIndex: 999 }}
              />
              <Chip
                label="Current Week"
                onClick={() => {
                  disconnectObserver();
                  setQueryPayload((prev) => ({
                    ...prev,
                    page: 1,
                    weekOffset: 0,
                    monthDirection: "stay",
                  }));
                }}
                sx={{ zIndex: 999 }}
              />
              <Chip
                sx={{ my: 3 }}
                label="Next Week"
                onClick={() => {
                  disconnectObserver();
                  setQueryPayload((prev) => ({
                    ...prev,
                    page: 1,
                    weekOffset: prev.weekOffset + 1,
                    monthDirection: "stay",
                  }));
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* List Container */}
        <Grid style={{ position: "relative" }}>
          {/* Up Arrow Button */}
          <IconButton
            onClick={() => handleArrowBtn("back")}
            disabled={isLoadingMore}
            style={{
              position: "fixed",
              top: "100px",
              right: "20px",
              zIndex: 9999,
              backgroundColor: "#e5faeb",
              borderRadius: "50%",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <ArrowUpwardIcon style={{ color: "#01ce3f" }} />
          </IconButton>

          {/* Data List */}
          <Grid
            container
            spacing={2}
            mt={16}
            display="flex"
            justifyContent="flex-start"
            px={2}
            style={{ zIndex: 1 }}
          >
            {data?.length > 0 ? (
              data?.map((a, aIndex, aArray) => (
                <React.Fragment key={a?.id || aIndex}>
                  <Grid item xs={12}>
                    <Grid display="flex" flexDirection="column" gap={0.5}>
                      <Typography
                        style={{ cursor: "pointer" }}
                        className="normal_normal_medium_14_Manrope green"
                      >
                        {a?.firstName || "NA"} {a?.lastName || "NA"}
                      </Typography>
                      <Typography style={{ cursor: "pointer" }}>
                        {a?.name || "NA"}
                      </Typography>
                      <Typography style={{ cursor: "pointer" }}>
                        {moment(a?.date).format("DD/MM/yyyy") || "NA"}
                      </Typography>
                    </Grid>
                  </Grid>
                  {aIndex !== aArray.length - 1 && (
                    <Grid item xs={12} zIndex={1}>
                      <Divider
                        style={{ border: "0.5px solid lightgrey", zIndex: 1 }}
                      />
                    </Grid>
                  )}
                </React.Fragment>
              ))
            ) : (
              <Grid item xs={12} m={1}>
                <Typography className="normal_normal_18_Manrope dark_gray">
                  No Data Found.
                </Typography>
              </Grid>
            )}

            {/* Infinite Scroll Loading Indicator */}
            {hasMore && !isLoader && (
              <Grid item xs={12}>
                <div
                  ref={loader}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100px",
                    textAlign: "center",
                    marginBottom: "50px",
                  }}
                >
                  {isLoadingMore ? "Loading more items..." : ""}
                </div>
              </Grid>
            )}
          </Grid>

          {/* Down Arrow Button */}
          <IconButton
            onClick={() => handleArrowBtn("next")}
            disabled={isLoadingMore}
            style={{
              position: "fixed",
              bottom: "20px",
              right: "20px",
              zIndex: 1,
              backgroundColor: "#e5faeb",
              borderRadius: "50%",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <ArrowDownwardIcon style={{ color: "#01ce3f" }} />
          </IconButton>
        </Grid>
      </Box>
    </Drawer>
  );
};

export default AnniversariesDrawer;
