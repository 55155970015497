/* eslint-disable jsx-a11y/alt-text */
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "../../../assets/images/BUTTON_Back.svg";
import Loader from "../../../components/Loader";
import AlertComponent from "../../../components/alert";
import { OutlinedGreenButton, SubmitButton } from "../../../components/buttons";
import {
  clearTempInstructionData,
  updateTempInstructionData,
} from "../../../slice/instruction";
import { showSnackbar } from "../../../slice/snackbarSlice";
import {
  downloadFormPdf,
  getInstructionFormDataById,
  updateInstruction,
  updatewillSuiteThunk,
} from "../../../thunks/instruction";
import { getLookups } from "../../../thunks/lookup";
import {
  INSTRUCTION_FORMS,
  NOTIFICATION_MESSAGE,
  QC_LookupNames,
  USER_ROLE,
} from "../../../utils/constant";
import {
  deepCompare,
  findValueAndHeader,
  getAddressbooksFromQuestionnairs,
  replaceArrayIndex,
  toStartCase,
  updateValues,
} from "../../../utils/util";
import AddressBookPage from "./Forms/AddressBookPage/AddressBookPage";
import ClientCarePage from "./Forms/ClientCarePage";
import DecOfTrustQuestionnaireForm from "./Forms/DecOfTrustQuestionnaire";
import FactFindForm from "./Forms/FactFindForm";
import FormHeader from "./Forms/FormHeader";
import LPAQuestionnaireForm from "./Forms/LPAQuestionnaireForm";
import LivingWillQuestionnaireForm from "./Forms/LivingWillQuestionnaire";
import TrustQuestionnaireForm from "./Forms/TrustQuestionnaire";
import WillQuestionnaireForm from "./Forms/WillQuestionnaireForm";
import QcQuestions from "./QcQuestions";

const InstructionFormList = () => {
  // hooks
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state?.auth?.currentUser);
  const instructionDataObject = useSelector(
    (state) => state?.instruction?.tempInstructionData
  );

  const processedInstructionData = (instructionDataObject) => {
    // take instruction object, update trust questionnaire object by migrating the data structure

    let tempTrustQuestionnaireInstruction =
      instructionDataObject?.InstructionTrustQuestionnaire?.map(
        (singleTrust) => {
          let tempSingleTrust = singleTrust;
          let generalResidueDistributionObject = {
            ...singleTrust?.generalDistribution,
          };

          if (
            (singleTrust?.generalDistribution?.beneficiary &&
              singleTrust?.generalDistribution?.isMigrated === undefined) ||
            false
          ) {
            generalResidueDistributionObject = {
              isMigrated: true,
              generalResidueDistribution: [
                {
                  beneficiary: [
                    ...singleTrust?.generalDistribution?.beneficiary,
                  ],
                  trustAssetBasisOf:
                    singleTrust?.generalDistribution?.trustAssetBasisOf,
                },
              ],
            };

            if (
              (singleTrust?.generalDistribution?.beneficiary &&
                singleTrust?.generalDistribution?.isMigrated === undefined) ||
              (false &&
                instructionDataObject?.isJoint &&
                generalResidueDistributionObject?.generalResidueDistribution
                  ?.length === 1)
            ) {
              generalResidueDistributionObject?.generalResidueDistribution?.push(
                {
                  beneficiary: [],
                  trustAssetBasisOf: "Equal",
                }
              );
            }
          }

          tempSingleTrust = {
            ...singleTrust,
            generalDistribution: generalResidueDistributionObject,
          };

          return tempSingleTrust;
        }
      );

    // handle sync update of client1 and client2 adressbook data
    let updatedAddressBookData = instructionDataObject?.InstructionAddressBook
      ?.length
      ? instructionDataObject?.InstructionAddressBook[0]?.addressBook?.map(
          (userObj) => {
            if (
              userObj?.targetPath?.fieldName === "parties" &&
              userObj?.targetPath?.questionnair === "client"
            ) {
              // get client object from instructionClients array
              let clientData =
                instructionDataObject?.InstructionClients[
                  userObj?.targetPath?.clientIndex
                ];
              // update the client obj
              let temp = {
                ...userObj,
                firstName: clientData?.firstName,
                middleName: clientData?.middleName,
                lastName: clientData?.lastName,
                email: clientData?.email,
                mobileNo:
                  clientData?.InstructionFactFind?.clientDetails?.mobileNo ||
                  "",
                telephone:
                  clientData?.InstructionFactFind?.clientDetails?.telephone,
                address:
                  clientData?.InstructionFactFind?.clientDetails?.address,
                dob: clientData?.InstructionFactFind?.clientDetails?.dob || "",
                gender: clientData?.InstructionFactFind?.clientDetails?.gender,
              };
              return temp;
            }
            return userObj;
          }
        )
      : [];

    return {
      ...instructionDataObject,
      InstructionTrustQuestionnaire: tempTrustQuestionnaireInstruction,
      ...(instructionDataObject?.InstructionAddressBook?.length > 0 && {
        InstructionAddressBook: [
          {
            ...instructionDataObject?.InstructionAddressBook[0],
            addressBook: updatedAddressBookData,
          },
        ],
      }),
    };
  };

  const lookups = useSelector((state) => state?.lookupData);
  const instruction = useSelector((state) => state?.instruction);

  // states
  const [isLoader, setLoader] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const [unsavedForms, setUnsavedForms] = useState([]);

  let updateValueOfTrustQuestionnaireFormOfInstruction =
    processedInstructionData(instructionDataObject);
  const methods = useForm({
    defaultValues: { ...updateValueOfTrustQuestionnaireFormOfInstruction },
  });

  const { handleSubmit, reset, trigger } = methods;

  const [instructionForms, setInstructionForms] = useState(INSTRUCTION_FORMS);
  const [value, setValue] = React.useState(0);
  const [isWillSuit, setIsWillSuite] = useState(false);
  const [willsuiteErrors, setWillsuiteErrors] = useState("");
  const [isUnsaveChanges, setUnsaveChanges] = useState(false);
  const closeErrorDialog = () => {
    setErrorList([]);
  };

  // formats error location shown in popup
  const formatError = (str) => {
    if (!str || typeof str != "string") return "";

    // replace [0] with [0 +1] string
    let newStr = replaceArrayIndex(str);
    // .replace(arrayRegex, '');

    newStr = toStartCase(newStr);
    newStr = newStr.replace(/\./g, " ");
    newStr = newStr.replace(/Instruction/i, "");

    newStr = newStr.replace(
      /DecOfTrustQuestionnaire/i,
      "DeclarationOfTrustQuestionnaire"
    );

    // remove repeated word
    let words = newStr.split(/\s+/);
    var uniqueWords = [...new Set(words)];
    newStr = uniqueWords.join(" > ");
    return newStr;
  };

  // method to get data by id
  const getDataById = async () => {
    try {
      setLoader(true);
      const res = await dispatch(
        getInstructionFormDataById(params.id)
      ).unwrap();

      let isLookupExist = QC_LookupNames.every((str) => lookups?.[str]);
      if (!isLookupExist) {
        await dispatch(
          getLookups({
            searchStrings: JSON.stringify(QC_LookupNames),
          })
        ).unwrap();
      }
      if (res?.id) {
        let processInstruction = processedInstructionData(res);
        reset({
          ...processInstruction,
        });
        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      navigate("/app/instructions/list");
      dispatch(
        showSnackbar({
          message: `${error?.message}`,
          severity: "error",
        })
      );
    }
  };

  // handle change
  const handleChange = async (event, newValue) => {
    if (value === 6) {
      // addressbook error handling
      let isValid = await trigger();
      if (!isValid) {
        dispatch(
          showSnackbar({
            message: NOTIFICATION_MESSAGE?.RESOLVED_ERROR,
            severity: "error",
          })
        );
        return;
      }
    }
    setLoader(true);
    setValue(newValue);
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  };

  const checkIsFormDirty = (formData) => {
    let dirtySections = [];
    INSTRUCTION_FORMS.forEach((section, i) => {
      let isSame = deepCompare(
        formData?.[section?.prop],
        instructionDataObject?.[section?.prop]
      );

      !isSame && dirtySections.push({ section, index: i });
    });
    setUnsavedForms(dirtySections);
    return dirtySections;
  };

  const handleiswillsuit = () => {
    setIsWillSuite(true);
  };

  const handleClickWillSuit = async () => {
    setIsWillSuite(false);
    try {
      setLoader(true);
      const response = await dispatch(
        updatewillSuiteThunk({ id: params?.id, willsuiteCase })
      ).unwrap();
      // handle willsuite  UPDATE response
      if (willsuiteCase) {
        let errorList = response?.data?.filter((obj) => obj?.status !== 200);
        if (errorList?.length) {
          handleWillsuiteState({ errorList });
          return;
        }
      }
      dispatch(
        showSnackbar({
          message: `Successfully ${
            willsuiteCase ? "updated" : "created"
          } Instruction To WillSuite`,
          severity: "success",
        })
      );
    } catch (error) {
      dispatch(
        showSnackbar({
          message: error?.message || NOTIFICATION_MESSAGE?.SOMETHING_WENT_WRONG,
          severity: "error",
        })
      );
    } finally {
      setLoader(false);
    }
  };

  // Willsuite code block start
  const instructionDashboardData = useSelector(
    (state) => state?.instruction?.instructionDashboardData
  );
  const willsuiteCase = instruction?.tempInstructionData?.willsuiteCase;

  const handleWillsuiteState = ({ errorList }) => {
    // handle list of errors for updating addressbook persons
    if (errorList?.length) {
      let errorMsg = "";
      errorList?.forEach((e) => {
        let error = e?.error,
          person = e?.data ? JSON.parse(e?.data) : {};
        let fullName = person?.first_names + " " + person?.last_name;

        if (typeof error == "string") {
          errorMsg += `${error} for addressbook ${fullName}\n`;
        } else if (error) {
          for (const key in error) {
            if (Object.hasOwnProperty.call(error, key)) {
              const str = e?.error[key];
              errorMsg += `${str} for addressbook ${fullName} \n`;
            }
          }
        }
      });

      setWillsuiteErrors(errorMsg);
    }
  };

  // handle save
  const handleSave = async (data) => {
    try {
      let dirtySections = checkIsFormDirty(data); // form has some invalid, unsaved chnages
      if (dirtySections?.length) {
        setUnsaveChanges(true);
        // dispatch(
        //   showSnackbar({
        //     message: NOTIFICATION_MESSAGE?.UNSAVE_CHANGES,
        //     severity: "error",
        //   })
        // );
        return;
      }

      setLoader(true);

      data.InstructionAddressBook = data?.InstructionAddressBook || [];
      data.InstructionAddressBook[0] = data?.InstructionAddressBook[0] || {};
      data.InstructionAddressBook[0].addressBook =
        getAddressbooksFromQuestionnairs({ instruction: data }) || [];

      data = updateValues(data, "other_config"); // delete property that been added for development purpose
      // Condition is application if application type is single
      if (!instructionDataObject?.isJoint) {
        let tempPayload = data;
        if (tempPayload) {
          tempPayload?.InstructionClients.splice(1, 1);
          tempPayload?.InstructionWillQuestionnaire.splice(1, 1);
          tempPayload?.InstructionLPA_Questionnaire.splice(1, 1);
          tempPayload?.InstructionTrustQuestionnaire.splice(1, 1);
          tempPayload?.InstructionDecOfTrustQuestionnaire.splice(1, 1);
          tempPayload?.InstructionLivingWillQuestionnaire.splice(1, 1);
          tempPayload?.InstructionAddressBook.splice(1, 1);
        }
      }

      const updatedData = {
        id: params.id,
        Images: [],
        InstructionData: {
          ...data,
        },
      };
      const res = await dispatch(updateInstruction(updatedData)).unwrap();
      if (res?.id) {
        dispatch(
          showSnackbar({
            message: NOTIFICATION_MESSAGE?.UPDATE_QC_DATA_SUCCESS,
            severity: "success",
          })
        );
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      dispatch(
        showSnackbar({
          message: `${error?.message}`,
          severity: "error",
        })
      );
    }
  };

  const handleSaveDraft = async (formData, formState) => {
    // todo handle view addressbook
    setLoader(true);
    await dispatch(
      updateTempInstructionData({
        prop: formState?.prop,
        data: formData?.[formState?.prop],
      })
    );

    // if factFind, update addressbook data of client 1 and 2 respectively
    if (formState.prop === "InstructionClients") {
      //update object of clients in addressbook
      let updatedAddressBookData =
        formData.InstructionAddressBook[0]?.addressBook?.map((userObj) => {
          if (
            userObj?.targetPath?.fieldName === "parties" &&
            userObj?.targetPath?.questionnair === "client"
          ) {
            // get client object from instructionClients array
            let clientData =
              formData?.InstructionClients[userObj?.targetPath?.clientIndex];
            // update the client obj
            let temp = {
              ...userObj,
              firstName: clientData?.firstName,
              middleName: clientData?.middleName,
              lastName: clientData?.lastName,
              email: clientData?.email,
              mobileNo:
                clientData?.InstructionFactFind?.clientDetails?.mobileNo || "",
              telephone:
                clientData?.InstructionFactFind?.clientDetails?.telephone,
              address: clientData?.InstructionFactFind?.clientDetails?.address,
              dob: clientData?.InstructionFactFind?.clientDetails?.dob || "",
              gender: clientData?.InstructionFactFind?.clientDetails?.gender,
            };
            return temp;
          }
          return userObj;
        });

      // save tempData as draft
      await dispatch(
        updateTempInstructionData({
          prop: "InstructionAddressBook",
          data: [
            {
              ...formData.InstructionAddressBook[0],
              addressBook: updatedAddressBookData,
            },
          ],
        })
      );

      // update hook form state with updated addressbook data
      reset({
        ...formData,
        InstructionAddressBook: [
          {
            ...formData.InstructionAddressBook[0],
            addressBook: updatedAddressBookData,
          },
        ],
      });
    }

    if (unsavedForms?.length) {
      setUnsavedForms((forms) =>
        forms?.filter((obj) => obj?.section?.title !== formState?.title)
      );
    }
    setTimeout(() => {
      setLoader(false);
      dispatch(
        showSnackbar({
          message: `${formState?.title} data saved successfully`,
          severity: "success",
        })
      );
    }, 1000);
  };

  // generate PDF function
  const handleGeneratePDF = async (formNumber) => {
    setLoader(true);
    try {
      await dispatch(
        downloadFormPdf({ instructionId: params?.id, formNumber })
      ).unwrap();
    } catch (error) {
      setLoader(false);
      dispatch(
        showSnackbar({
          show: true,
          message: `${error?.message}`,
          severity: "error",
        })
      );
      console.log("Error :: ", error);
    }
    setLoader(false);
  };

  // handle errors
  const handleErrors = (errors) => {
    let errorList1 = findValueAndHeader(errors, ["ref", "message"]);
    setErrorList(errorList1); // it will cause to show popup with errorList1
  };

  // use effect
  useEffect(() => {
    getDataById();
  }, []);

  // Clear instruction form state when component unmount
  useEffect(() => {
    return () => {
      dispatch(clearTempInstructionData());
    };
  }, []);

  const isInstructed =
    instruction?.tempInstructionData?.milestone === "Prospect";

  return (
    <>
      {/* Will Suite */}
      <AlertComponent
        open={Boolean(willsuiteErrors)}
        handleClose={() => setWillsuiteErrors("")}
        message={willsuiteErrors}
        cancelBtnConfig={{ label: "Ok" }}
        successBtnConfig={{ hide: true }}
      />
      {isWillSuit && (
        <AlertComponent
          open={isWillSuit}
          handleSuccess={handleClickWillSuit}
          handleClose={() => setIsWillSuite(false)}
          message={`Are you sure you want to update WillSuite data?`}
        />
      )}

      {/* Unsave changes alert */}
      <AlertComponent
        open={isUnsaveChanges}
        handleClose={() => setUnsaveChanges(false)}
        message={NOTIFICATION_MESSAGE?.UNSAVE_CHANGES}
        cancelBtnConfig={{ label: "Ok" }}
        successBtnConfig={{ hide: true }}
      />
      <Dialog
        open={errorList?.length}
        onClose={closeErrorDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle borderBottom={"1px solid grey"}>
          Please resolve following errors in the form
        </DialogTitle>
        <DialogContent>
          <ul>
            {errorList?.map((obj, i) => (
              <Box key={i} p={2} component={"li"}>
                {/* <Typography> { 'At2 : ' + JSON.stringify(obj)} </Typography> */}
                <Typography>
                  {" "}
                  {"At : " + formatError(obj?.ref?.name)}{" "}
                </Typography>
                <Typography fontWeight={600}>
                  {" "}
                  {"Error :" + obj?.message}{" "}
                </Typography>
              </Box>
            ))}
          </ul>
        </DialogContent>
        <DialogActions>
          <Box px={3}>
            <OutlinedGreenButton onClick={closeErrorDialog} label={"OK"} />
          </Box>
        </DialogActions>
      </Dialog>
      <FormProvider {...methods}>
        {isLoader && <Loader />}
        <Grid
          container
          spacing={2}
          display={"flex"}
          flexDirection={"row"}
          width={"calc(100% + 26px)"}
        >
          {/* left */}
          <Grid
            item
            xs={1.1}
            display={"flex"}
            flexDirection={"column"}
            style={{
              backgroundColor: "#E5FAEB",
              paddingTop: "0px",
              height: "102.2vh",
              marginTop: "1rem",
            }}
          >
            <Grid
              item
              xs={3}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={BackButton}
                onClick={() => window.history.back()}
                style={{ cursor: "pointer", marginRight: "10px" }}
              />
              <Grid item xs={6}>
                <Typography className="serif_display_regular_26 dark_grey">
                  Forms
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} mr={2}>
              <Tabs
                orientation="vertical"
                variant="fullWidth"
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                sx={{
                  "& .MuiTabs-indicator": {
                    display: "none",
                  },
                  "& .MuiTab-root.Mui-selected": {
                    color: "#00CE3F",
                    borderBottom: "1px solid #00CE3F",
                    alignItems: "flex-start",
                    textAlign: "left",
                  },
                  "& .MuiTab-root": {
                    borderBottom: "1px solid #3D4740",
                    opacity: 1,
                    alignItems: "center",
                    textAlign: "left",
                  },
                }}
              >
                {instructionForms?.length > 0 &&
                  instructionForms?.map((d, i) => {
                    let unsavedForm = unsavedForms?.find(
                      (obj) => obj?.index == i
                    );
                    let isEnabled =
                      !d?.unlockKey ||
                      (instruction?.tempInstructionData?.questionnaireUnlock?.[
                        d?.unlockKey
                      ] === true &&
                        !isInstructed);
                    return (
                      <Tooltip title={d?.title}>
                        <Tab
                          key={d?.id}
                          className="form_list_tab"
                          disabled={!isEnabled}
                          sx={{
                            textTransform: "none",
                            // '&.MuiButtonBase-root-MuiTab-root'
                            "&.MuiTab-root": {
                              color: unsavedForm
                                ? "#ff0000"
                                : isEnabled
                                ? "#3D4740"
                                : "#00000029",
                              padding: "0px 0px",
                              justifyContent: "space-between",
                            },
                            "&.MuiTab-root.Mui-selected": {
                              color: unsavedForm ? "#ff0000" : "#00CE3F",
                            },
                          }}
                          label={
                            <Grid
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                                height: "100%",
                              }}
                            >
                              <Typography
                                className={
                                  d?.id === value
                                    ? "normal_normal_bold_18_Manrope"
                                    : "normal_normal_18_Manrope"
                                }
                              >
                                {d?.formTabLabels}
                              </Typography>
                              <Typography>
                                {unsavedForm && (
                                  <Tooltip
                                    title="Unsaved changes exists, Please save"
                                    arrow
                                  >
                                    <InfoIcon color={"error"} size={4} />
                                  </Tooltip>
                                )}
                                <ArrowRightIcon
                                  size={4}
                                  style={{
                                    marginLeft: "4px",
                                    display: `${d?.id === value ? "" : "none"}`,
                                  }}
                                />
                              </Typography>
                            </Grid>
                          }
                        />
                      </Tooltip>
                    );
                  })}
                {/* Custom Client Care Letter Tab - Not mapped */}
                <Tooltip title={"Client Care Letter"}>
                  <Tab
                    disabled={
                      instruction?.tempInstructionData?.milestone?.toLowerCase() ===
                      "prospect"
                    }
                    key={8}
                    value={8}
                    onClick={() => setValue(8)}
                    className="form_list_tab"
                    sx={{
                      textTransform: "none",
                      // '&.MuiButtonBase-root-MuiTab-root'
                      "&.MuiTab-root": {
                        color: false
                          ? "#ff0000"
                          : value === 8
                          ? "#00CE3F"
                          : "#3D4740",
                        padding: "0px 0px",
                        justifyContent: "space-between",
                      },
                      "&.MuiTab-root.Mui-selected": {
                        color: value === 8 ? "#00CE3F" : "#3D4740",
                      },
                    }}
                    label={
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <Typography
                          className={
                            value === 8
                              ? "normal_normal_bold_18_Manrope"
                              : "normal_normal_18_Manrope"
                          }
                        >
                          {"CCL"}
                        </Typography>
                        <Typography>
                          <ArrowRightIcon
                            size={4}
                            style={{
                              marginLeft: "4px",
                              display: `${value === 8 ? "" : "none"}`,
                            }}
                          />
                        </Typography>
                      </Grid>
                    }
                  />
                </Tooltip>
              </Tabs>
            </Grid>

            {/* will suite button */}
            <Grid item xs={12} p={1} mr={1}>
              {userDetails?.role === USER_ROLE?.SUPER_ADMIN ||
              userDetails?.role === USER_ROLE?.OFFICE_ADMIN ? (
                <Grid item xs={12} mb={1}>
                  <Button
                    variant="contained"
                    fullWidth
                    style={{
                      boxShadow: "none",
                      backgroundColor: "#00ce3f",
                      borderRadius: "24px",
                      marginTop: "15px",
                    }}
                    onClick={handleiswillsuit}
                  >
                    Will Suite
                  </Button>
                </Grid>
              ) : null}
            </Grid>
          </Grid>

          {/* center section  */}
          <Grid
            item
            xs={7.9}
            className="instruction_dashboard_scrollbar"
            style={{
              marginBottom: "1rem",
              paddingRight: "15px",
              height: "90vh",
              overflowY: "auto",
            }}
          >
            {/* qc form header */}
            {value <= 7 ? (
              <FormHeader
                formName={instructionForms[value]?.title}
                onSave={handleSubmit(
                  (data) => handleSaveDraft(data, instructionForms[value]),
                  handleErrors
                )}
                generatePDF={() => handleGeneratePDF(value)}
                // generatePDF={()=> console.log("handleSaveDraft something !!!")}
              />
            ) : null}
            {isLoader ? (
              <Loader />
            ) : (
              <>
                {/* qc form tabs */}
                {value === 0 && <FactFindForm />}
                {value === 1 && <WillQuestionnaireForm />}
                {value === 2 && <LPAQuestionnaireForm />}
                {value === 3 && <TrustQuestionnaireForm />}
                {value === 4 && <DecOfTrustQuestionnaireForm />}
                {value === 5 && <LivingWillQuestionnaireForm />}
                {value === 6 && <AddressBookPage />}
                {/* {value === 7 && <AttendanceNotes />} */}
                {value === 8 && <ClientCarePage />}
              </>
            )}

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                position: "fixed",
                bottom: 12,
                left: 160,
                right: 0,
                alignItems: "center",
                gap: 10,
              }}
              mt={1}
            >
              {/* submit qc form button */}
              <SubmitButton
                isDisabled={isInstructed}
                onClick={handleSubmit(handleSave, handleErrors)}
              />
              {isInstructed && (
                <Typography className="normal_normal_semibold_14_Manrope red">
                  *Milestone is not Instructed, Please change milestone and
                  save.
                </Typography>
              )}
            </Grid>
          </Grid>

          {/* right section  */}
          <Grid
            item
            xs={3}
            className="instruction_dashboard_scrollbar"
            style={{
              backgroundColor: "#ffff",
              paddingTop: "24px",
              paddingBottom: "55px",
              height: "102.2vh",
              overflow: "auto",
              borderLeft: "1px solid #DCDCE1",
            }}
          >
            {/* Display questions dynamically */}
            <QcQuestions
              instructionId={params?.id}
              isInstructed={isInstructed}
            />
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
};

export default InstructionFormList;
