import { Grid, Typography } from "@mui/material";
import { RadioInput } from "../../../../../components/inputs";

const AddingNewBeneficiary = ({ arr, isJoint, trustIndex }) => {
  return (
    <>
      <Grid
        width={"100%"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        borderTop={`1px solid #DCDCE1`}
        borderBottom={`1px solid #DCDCE1`}
      >
        <Grid
          width={"100%"}
          paddingY={4}
          display={"flex"}
          flexDirection={"column"}
          borderBottom={`1px solid #DCDCE1`}
          gap={0}
        >
          <Typography className="normal_normal_semibold_20_Manrope dark_grey">
            Adding New Beneficiary
          </Typography>
        </Grid>
        <Grid
          width={"65%"}
          paddingY={4}
          display={"flex"}
          flexDirection={"column"}
          gap={3}
        >
          <Grid display={"flex"} flexDirection={"column"} gap={0}>
            {/* todo check path with backend */}
            <RadioInput
              name={`InstructionTrustQuestionnaire.[${trustIndex}].newBeneficiary.issueAndRemoterIssues`}
              // orientation="vertical"
              label="Select one of the following statements-"
              options={[
                {
                  label:
                    "Issue & remoter issues of beneficiaries to be added as new beneficiaries as they are born",
                  value: true,
                  // "Issue & remoter issues of beneficiaries to be added as new beneficiaries as they are born",
                },
                {
                  label:
                    "Only 'Bloodline' descendants of the Settlors should be added as beneficiaries",
                  value: false,
                  // "Only 'Bloodline' descendants of the Settlors should be added as beneficiaries",
                },
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AddingNewBeneficiary;
