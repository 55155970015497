import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import DataGridComponent from "../../../components/DataGridComponent";
import ProspectNotesQuestionAnswer from "./prospectNotesQuestionAnswer";

const ProspectNotesDialog = ({
  prospectDialogOpen,
  handleProspectDialogClose,
  data,
}) => {
  const columns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 160,
      sortable: false,
    },
    {
      field: "isConcerns",
      headerName: "is Concerns",
      minWidth: 160,
      sortable: false,
      renderCell: (params) => {
        return (
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {params?.row?.isConcerns ? "Yes" : "No"}
          </Grid>
        );
      },
    },
    {
      field: "relationship",
      headerName: "Relationship",
      minWidth: 160,
      sortable: false,
    },
  ];
  return (
    <>
      <Dialog
        sx={{ border: 1 }}
        open={prospectDialogOpen}
        onClose={handleProspectDialogClose}
        fullWidth
        maxWidth={"md"}
      >
        <IconButton
          aria-label="close"
          onClick={handleProspectDialogClose}
          sx={{
            position: "absolute",
            right: 15,
            top: 15,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>
          {" "}
          <Typography
            className="serif_display_regular_26 green"
            textAlign={"center"}
          >
            {data?.appointmentDeclartion?.["Instructed Attendance Note"]
              ? "Instructed Attendance Note"
              : "Prospect Attendance Note"}
          </Typography>
        </DialogTitle>

        <DialogContent dividers={true}>
          {data?.appointmentDeclartion ? (
            <Grid container spacing={3}>
              {/* TextField for Instructed Attendance Note Or Prospect Attendance Note*/}
              {(data?.appointmentDeclartion?.["Instructed Attendance Note"] ||
                data?.appointmentDeclartion?.["Prospect Attendance Note"]) && (
                <Grid item xs={12}>
                  <TextField
                    label={
                      data?.appointmentDeclartion?.[
                        "Instructed Attendance Note"
                      ]
                        ? "Instructed Attendance Note"
                        : "Prospect Attendance Note"
                    }
                    placeholder="Note"
                    fullWidth
                    variant="standard"
                    value={
                      data?.appointmentDeclartion?.[
                        "Instructed Attendance Note"
                      ] ||
                      data?.appointmentDeclartion?.[
                        "Prospect Attendance Note"
                      ] ||
                      ""
                    }
                    disabled
                    multiline
                    maxRows={4}
                  />
                </Grid>
              )}

              {/* Client decleration Section */}
              {data?.appointmentDeclartion?.clientDeclaration ? (
                <Grid item xs={12}>
                  <>
                    <Accordion
                      elevation={0}
                      style={{
                        borderBottom: "1px solid #DCDCE1",
                        width: "100%",
                      }}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <AccordionSummary
                        style={{
                          width: "100%",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                        }}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <span
                          className="normal_normal_semibold_20_Manrope green"
                          style={{
                            width: "100%",
                            marginRight: "auto",
                          }}
                        >
                          Client Declaration
                        </span>
                      </AccordionSummary>

                      <AccordionDetails>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Typography className="normal_normal_medium_14_Manropee dark_grey">
                              I confirm that my needs and requirements have been
                              discussed and that the following products and
                              services were introduced and explained.
                            </Typography>
                          </Grid>
                          {/*Showing client 1 and client 2 headings section  */}
                          {data?.appointmentDeclartion?.clientDeclaration
                            ?.length > 0 &&
                            data?.appointmentDeclartion?.clientDeclaration?.map(
                              (d, ind) => (
                                <>
                                  <Grid
                                    item
                                    xs={
                                      data?.appointmentDeclartion
                                        ?.clientDeclaration?.length === 1
                                        ? 12
                                        : 6
                                    }
                                    display={"flex"}
                                    justifyContent={
                                      data?.appointmentDeclartion
                                        ?.clientDeclaration?.length === 1
                                        ? "center"
                                        : "flex-end"
                                    }
                                  >
                                    <Typography className="normal_normal_semibold_14_Manrope green">
                                      {`Client ${ind + 1}`}
                                    </Typography>
                                  </Grid>
                                </>
                              )
                            )}
                          {/* Will radio section */}
                          <ProspectNotesQuestionAnswer
                            label="Will"
                            client1={
                              data?.appointmentDeclartion?.clientDeclaration
                                ? data?.appointmentDeclartion
                                    ?.clientDeclaration[0]?.isAgeOver18
                                : null
                            }
                            client2={
                              data?.appointmentDeclartion?.clientDeclaration
                                ? data?.appointmentDeclartion
                                    ?.clientDeclaration[1]?.isAgeOver18
                                : null
                            }
                            data={
                              data?.appointmentDeclartion?.clientDeclaration
                            }
                          />

                          {/* Powers of Attorney radio section */}
                          <ProspectNotesQuestionAnswer
                            label="Powers of Attorney"
                            client1={
                              data?.appointmentDeclartion?.clientDeclaration
                                ? data?.appointmentDeclartion
                                    ?.clientDeclaration[0]?.isPowerOfAttorney
                                : []
                            }
                            client2={
                              data?.appointmentDeclartion?.clientDeclaration
                                ? data?.appointmentDeclartion
                                    ?.clientDeclaration[1]?.isPowerOfAttorney
                                : []
                            }
                            data={
                              data?.appointmentDeclartion?.clientDeclaration
                            }
                          />

                          {/* Will Trusts radio section */}
                          <ProspectNotesQuestionAnswer
                            label="Will Trusts"
                            client1={
                              data?.appointmentDeclartion?.clientDeclaration
                                ? data?.appointmentDeclartion
                                    ?.clientDeclaration[0]?.isWillTrusts
                                : []
                            }
                            client2={
                              data?.appointmentDeclartion?.clientDeclaration
                                ? data?.appointmentDeclartion
                                    ?.clientDeclaration[1]?.isWillTrusts
                                : []
                            }
                            data={
                              data?.appointmentDeclartion?.clientDeclaration
                            }
                          />

                          {/*Lifetime Trusts radio section */}
                          <ProspectNotesQuestionAnswer
                            label="Lifetime Trusts"
                            client1={
                              data?.appointmentDeclartion?.clientDeclaration
                                ? data?.appointmentDeclartion
                                    ?.clientDeclaration[0]?.isLifetimeTrusts
                                : []
                            }
                            client2={
                              data?.appointmentDeclartion?.clientDeclaration
                                ? data?.appointmentDeclartion
                                    ?.clientDeclaration[1]?.isLifetimeTrusts
                                : []
                            }
                            data={
                              data?.appointmentDeclartion?.clientDeclaration
                            }
                          />

                          {/* I confirm that I am overthe age radio section */}
                          <ProspectNotesQuestionAnswer
                            label="I confirm that I am overthe age of 18 and of sound mind."
                            client1={
                              data?.appointmentDeclartion?.clientDeclaration
                                ? data?.appointmentDeclartion
                                    ?.clientDeclaration[0]?.isAgeOver18
                                : []
                            }
                            client2={
                              data?.appointmentDeclartion?.clientDeclaration
                                ? data?.appointmentDeclartion
                                    ?.clientDeclaration[1]?.isAgeOver18
                                : []
                            }
                            data={
                              data?.appointmentDeclartion?.clientDeclaration
                            }
                          />

                          {/* I confirm the information given Section */}
                          <ProspectNotesQuestionAnswer
                            label="I confirm the information given to the consultant is correct and any information not provided may affect the appropriateness of any guidance received."
                            client1={
                              data?.appointmentDeclartion?.clientDeclaration
                                ? data?.appointmentDeclartion
                                    ?.clientDeclaration[0]?.isInformationCorrect
                                : []
                            }
                            client2={
                              data?.appointmentDeclartion?.clientDeclaration
                                ? data?.appointmentDeclartion
                                    ?.clientDeclaration[1]?.isInformationCorrect
                                : []
                            }
                            data={
                              data?.appointmentDeclartion?.clientDeclaration
                            }
                          />

                          {/* I understand I have a 14 day cooling off radio section */}
                          <ProspectNotesQuestionAnswer
                            label="I understand I have a 14 day cooling off period with a potential partial refund only."
                            client1={
                              data?.appointmentDeclartion?.clientDeclaration
                                ? data?.appointmentDeclartion
                                    ?.clientDeclaration[0]?.isCoolingOf
                                : []
                            }
                            client2={
                              data?.appointmentDeclartion?.clientDeclaration
                                ? data?.appointmentDeclartion
                                    ?.clientDeclaration[1]?.isCoolingOf
                                : []
                            }
                            data={
                              data?.appointmentDeclartion?.clientDeclaration
                            }
                          />

                          {/* I understand I have a 14 day cooling off radio section */}
                          <ProspectNotesQuestionAnswer
                            label="I consent to you informing my executors, trustees and/or attorneys what their roles are and the nature of my planning."
                            client1={
                              data?.appointmentDeclartion?.clientDeclaration
                                ? data?.appointmentDeclartion
                                    ?.clientDeclaration[0]?.isNatureOfPlan
                                : []
                            }
                            client2={
                              data?.appointmentDeclartion?.clientDeclaration
                                ? data?.appointmentDeclartion
                                    ?.clientDeclaration[1]?.isNatureOfPlan
                                : []
                            }
                            data={
                              data?.appointmentDeclartion?.clientDeclaration
                            }
                          />

                          {/* I confirm that I am aware of the fees radio section */}
                          <ProspectNotesQuestionAnswer
                            label="I confirm that I am aware of the fees involved in my elected planning."
                            client1={
                              data?.appointmentDeclartion?.clientDeclaration
                                ? data?.appointmentDeclartion
                                    ?.clientDeclaration[0]?.isAwareOfFees
                                : []
                            }
                            client2={
                              data?.appointmentDeclartion?.clientDeclaration
                                ? data?.appointmentDeclartion
                                    ?.clientDeclaration[1]?.isAwareOfFees
                                : []
                            }
                            data={
                              data?.appointmentDeclartion?.clientDeclaration
                            }
                          />
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </>
                </Grid>
              ) : null}

              {/* TextField for appointment decleration reason */}
              {data?.appointmentDeclartion?.reason && (
                <Grid item xs={12}>
                  <TextField
                    label={
                      "Please state the reason if the above statements are not answered"
                    }
                    placeholder="Please state the reason if the above statements are not answered"
                    fullWidth
                    variant="standard"
                    value={data?.appointmentDeclartion?.reason || ""}
                    disabled
                  />
                </Grid>
              )}

              {/* Consultant decleration Section */}
              {data?.appointmentDeclartion?.consultantDeclaration ? (
                <Grid item xs={12}>
                  <>
                    <Accordion
                      elevation={0}
                      style={{
                        borderBottom: "1px solid #DCDCE1",
                        width: "100%",
                      }}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <AccordionSummary
                        style={{
                          width: "100%",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                        }}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <span
                          className="normal_normal_semibold_20_Manrope green"
                          style={{
                            width: "100%",
                            marginRight: "auto",
                          }}
                        >
                          Consultant Declaration
                        </span>
                      </AccordionSummary>

                      <AccordionDetails>
                        <Grid container spacing={3}>
                          {data?.appointmentDeclartion?.consultantDeclaration
                            ?.referredBy && (
                            <Grid item xs={12}>
                              <TextField
                                label="Were client(s) referred by anyone? If yes, by whom?"
                                placeholder="Were client(s) referred by anyone? If yes, by whom?"
                                fullWidth
                                variant="standard"
                                value={
                                  data?.appointmentDeclartion
                                    ?.consultantDeclaration?.referredBy || ""
                                }
                                disabled
                              />
                            </Grid>
                          )}

                          <Grid item xs={12}>
                            <FormControl>
                              <FormLabel style={{ color: "#00CE3F" }}>
                                Was anyone present at the meeting?
                              </FormLabel>
                              <RadioGroup
                                row
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                                value={
                                  data?.appointmentDeclartion
                                    ?.consultantDeclaration?.isAnyonePresent
                                }
                              >
                                <FormControlLabel
                                  value={true}
                                  control={
                                    <Radio
                                      size="small"
                                      sx={{
                                        "&, &.Mui-checked": {
                                          color: "#00CE3F",
                                        },
                                      }}
                                    />
                                  }
                                  label={"Yes"}
                                />

                                <FormControlLabel
                                  value={false}
                                  control={
                                    <Radio
                                      size="small"
                                      sx={{
                                        "&, &.Mui-checked": {
                                          color: "#00CE3F",
                                        },
                                      }}
                                    />
                                  }
                                  label={"No"}
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>

                          {data?.appointmentDeclartion?.consultantDeclaration
                            ?.concernsRegards?.length > 0 &&
                            data?.appointmentDeclartion?.consultantDeclaration?.concernsRegards?.map(
                              (d, ind) => (
                                <>
                                  <Grid
                                    item
                                    xs={
                                      data?.appointmentDeclartion
                                        ?.consultantDeclaration?.concernsRegards
                                        ?.length === 1
                                        ? 12
                                        : 6
                                    }
                                    display={"flex"}
                                    justifyContent={
                                      data?.appointmentDeclartion
                                        ?.consultantDeclaration?.concernsRegards
                                        ?.length === 1
                                        ? "center"
                                        : "flex-end"
                                    }
                                  >
                                    <Typography className="normal_normal_semibold_14_Manrope green">
                                      {`Client ${ind + 1}`}
                                    </Typography>
                                  </Grid>
                                </>
                              )
                            )}

                          <ProspectNotesQuestionAnswer
                            label="Capacity"
                            client1={
                              data?.appointmentDeclartion?.consultantDeclaration
                                ?.concernsRegards[0]?.capacity
                            }
                            client2={
                              data?.appointmentDeclartion?.consultantDeclaration
                                ?.concernsRegards[1]?.capacity
                            }
                            data={
                              data?.appointmentDeclartion?.consultantDeclaration
                                ?.concernsRegards
                            }
                          />

                          <ProspectNotesQuestionAnswer
                            label="Confusion"
                            client1={
                              data?.appointmentDeclartion?.consultantDeclaration
                                ?.concernsRegards[0]?.confusion
                            }
                            client2={
                              data?.appointmentDeclartion?.consultantDeclaration
                                ?.concernsRegards[1]?.confusion
                            }
                            data={
                              data?.appointmentDeclartion?.consultantDeclaration
                                ?.concernsRegards
                            }
                          />

                          <ProspectNotesQuestionAnswer
                            label="Memory Loss"
                            client1={
                              data?.appointmentDeclartion?.consultantDeclaration
                                ?.concernsRegards[0]?.memoryLoss
                            }
                            client2={
                              data?.appointmentDeclartion?.consultantDeclaration
                                ?.concernsRegards[1]?.memoryLoss
                            }
                            data={
                              data?.appointmentDeclartion?.consultantDeclaration
                                ?.concernsRegards
                            }
                          />

                          {/* Present Person Details section */}
                          {data?.appointmentDeclartion?.consultantDeclaration
                            ?.presentPersonDetails?.length > 0 && (
                            <Grid
                              item
                              xs={12}
                              style={{ width: "100%", height: "30vh" }}
                            >
                              <DataGridComponent
                                columns={columns}
                                rows={
                                  data?.appointmentDeclartion
                                    ?.consultantDeclaration
                                    ?.presentPersonDetails || []
                                }
                                onRowClick={() => null}
                              />
                            </Grid>
                          )}
                          {/* Cofirmation checkbox and signature section */}
                          <Grid item xs={7}>
                            <FormControlLabel
                              fullWidth
                              control={
                                <Checkbox
                                  defaultChecked
                                  disabled
                                  sx={{
                                    color: "#00CE3F",
                                    "&.Mui-checked": {
                                      color: "#00CE3F",
                                    },
                                  }}
                                />
                              }
                              label="I confirm that the details above are an accurate representation of the circumstances of my meeting with the clients."
                            />
                          </Grid>

                          <Grid item xs={5}>
                            <Typography
                              className="normal_normal_600_12_Manrope green"
                              mb={1}
                            >
                              Consultant's Signature
                            </Typography>
                            <Grid
                              container
                              alignItems="center"
                              justifyContent="center"
                              sx={{
                                border: `1px solid #DCDCE1`,
                                borderRadius: "10px",
                                height: "150px",
                                width: "100%",
                              }}
                            >
                              <Avatar
                                style={{
                                  objectFit: "cover", // Use 'cover' to fill the entire container
                                  width: "100%",
                                  height: "100%", // Ensure the Avatar takes up the full height
                                }}
                                variant="square"
                                src={
                                  data?.appointmentDeclartion
                                    ?.consultantDeclaration?.signature
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </>
                </Grid>
              ) : null}
            </Grid>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography>No Data Found.</Typography>
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ProspectNotesDialog;
