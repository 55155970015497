import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { TextInput } from "../../../../../components/inputs";

const AdditionalNote = () => {
  const isJoint = useSelector((state) => state?.instruction?.isJoint);
  const arr = useSelector((state) => state?.instruction?.arr);

  return (
    <>
      <Grid width={"100%"} display={"flex"} flexDirection={"column"}>
        <Grid
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={isJoint ? "space-between" : "center"}
          borderTop={`1px solid #DCDCE1`}
          borderBottom={`1px solid #DCDCE1`}
        >
          {arr?.map((item, index) => {
            return (
              <Grid
                width={isJoint ? "50%" : "62%"}
                borderRight={
                  index === 0 && isJoint ? `1px solid #DCDCE1` : null
                }
                padding={2}
                display={"flex"}
                flexDirection={"column"}
                gap={2}
              >
                <TextInput
                  label={``}
                  name={`InstructionWillQuestionnaire.[${index}].informationNotes.notes`}
                  placeholder="Enter notes"
                  multiline={true}
                  maxRows={4}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
};

export default AdditionalNote;
