import React, { useState } from "react";
import { Grid } from "@mui/material";
import ClientSpecificDetails from "./ClientSpecificDetails";
import ClientTaskList from "./ClientTaskList";
import ClientDocumentsAndCommunication from "./ClientDocumentsAndCommunication";
import ClientInstructionDetails from "./ClientInstructionDetails";
import { useParams } from "react-router-dom";
import { getClientById } from "../../../thunks/client";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { resetClientUserData } from "../../../slice/client";
import { USER_ROLE } from "../../../utils/constant";

const ClientDashboard = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [reload, setReload] = useState(true);
  const [marketingPreferenceState, setMarketingPreferenceState] = useState({});
  const userDetails = useSelector((state) => state?.auth?.currentUser);

  //GET DATA OF CLIENT
  const getClientDataById = async () => {
    try {
      await dispatch(getClientById(params?.id)).unwrap();
    } catch (error) {}
  };

  // update marketing preferences state
  const handleUpdateMarketingPreference = (data) => {
    setMarketingPreferenceState(data);
  };

  const handleResetClientUserData = () => {
    dispatch(resetClientUserData());
  };

  useEffect(() => {
    getClientDataById(params?.id);

    return () => {
      handleResetClientUserData();
    };
  }, [params?.id]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} display={"flex"} flexDirection={"row"}>
          {/* LEFT - Client basic details */}
          <ClientSpecificDetails
            reload={reload}
            setReload={setReload}
            handleUpdateDashboardMarketingPreference={
              handleUpdateMarketingPreference
            }
          />

          {/* CENTER - Client task and Instructions */}
          <Grid
            item
            xs={6.5}
            style={{ height: "100vh", width: "100%" }}
            px={1}
            py={1}
          >
            {/* CENTER TOP - Client tasks */}
            {userDetails?.role !== USER_ROLE?.INTRODUCER && (
              <Grid
                style={{
                  height: "60%",
                  overflowY: "hidden",
                }}
              >
                <ClientTaskList reload={reload} />
              </Grid>
            )}

            {/* CENTER BOTTOM - Client Instructions */}
            <Grid style={{ height: "40%", overflowY: "scroll" }}>
              <ClientInstructionDetails />
            </Grid>
          </Grid>

          {/* RIGHT - Documents and Communication (email, notes, sms, phone) */}
          <Grid item xs={3}>
            <ClientDocumentsAndCommunication
              marketingPreferenceState={marketingPreferenceState}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ClientDashboard;
