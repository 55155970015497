import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DataGridComponent from "../../../components/DataGridComponent";
import { AddButton } from "../../../components/buttons";
import { DATE_FORMAT, NOTIFICATION_MESSAGE } from "../../../utils/constant";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { updateClient } from "../../../thunks/client";
import { useDispatch } from "react-redux";
import moment from "moment";
import Loader from "../../../components/Loader";
import { showSnackbar } from "../../../slice/snackbarSlice";
import { changeDateFormatToISO } from "../../../utils/util";

const ClientAnniversaryDialog = ({
  aniversaries,
  setAniversaries,
  open,
  onClose,
  clientData,
  setClientData,
}) => {
  const dispatch = useDispatch();
  let [anniversaryData, setAnniversaryData] = useState(aniversaries || []);
  let [anniversaryDataTemp, setAnniversaryDataTemp] = useState(
    aniversaries || []
  );
  let [edit, setEdit] = useState(false);
  const [formData, setFormData] = useState({ name: "", date: null });
  const [isLoader, setLoader] = useState(false);
  const [isError, setIsError] = useState(false);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 250,
      sortable: false,
      renderCell: (params) => <Typography>{params?.row?.name}</Typography>,
    },
    {
      field: "date",
      headerName: "Date",
      width: 200,
      sortable: false,
      renderCell: (params) => (
        <Typography>{moment(params.row.date).format("DD/MM/yyyy")}</Typography>
      ),
    },

    {
      field: "action",
      headerName: "Action",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={() => {
              setFormData({
                name: params?.row?.name,
                date: new Date(params?.row?.date),
              });
              setAnniversaryDataTemp(
                anniversaryDataTemp?.filter((i) => i.name !== params?.row?.name)
              );
              setEdit(true);
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              handleSave(
                anniversaryDataTemp.filter(
                  (a) =>
                    a?.name !== params?.row?.name &&
                    a?.date !== params?.row?.date
                ),
                true
              );
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      ),
    },
  ];

  const handleSave = async (data, isDelete = false) => {
    if ((!formData?.name?.length || formData?.date === null) && !isDelete) {
      dispatch(
        showSnackbar({
          message: "Please enter both name and date",
          severity: "error",
        })
      );
      setIsError(true);
      return;
    }
    setIsError(false);
    setLoader(true);
    try {
      let payload = {
        ...clientData,
        aniversaries: data?.map((item) => ({
          name: item?.name?.trim(),
          date: item?.date,
        })),
        isFromEdit: true,
      };

      let response = await dispatch(updateClient(payload)).unwrap();
      if (response) {
        setAnniversaryData(data);
        setAnniversaryDataTemp(data);
        setEdit(false);
        setFormData({ name: "", date: null });
        setClientData({
          ...clientData,
          aniversaries: data?.map((item) => ({
            name: item?.name,
            date: item?.date,
          })),
        });
        setLoader(false);
        dispatch(
          showSnackbar({
            message: NOTIFICATION_MESSAGE?.SUCCESS_CLIENT_ANNIVERSARY_UPDATE,
            severity: "success",
          })
        );
      }
    } catch (error) {
      setLoader(false);
      dispatch(
        showSnackbar({
          message: error?.message,
          severity: "error",
        })
      );
    }
  };

  return (
    <>
      <Dialog
        maxWidth={"md"}
        open={open}
        onClose={onClose}
        sx={{
          width: "100vw",
          border: 1,
          height: "100vh",
          alignSelf: "center",
          zIndex: 10,
        }}
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 10,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>
          <Grid display={"flex"} alignItems={"center"} gap={2}>
            <Typography className="serif_display_regular_26 green">
              Anniversaries
            </Typography>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {isLoader && <Loader />}
          <Grid container my={2} columnSpacing={2}>
            {edit ? (
              <>
                <Grid item width={"40%"}>
                  <TextField
                    value={formData?.name}
                    error={isError && !formData?.name?.length}
                    fullWidth
                    className="inputTextField border_color normal_normal_600_12_Manropee"
                    label="Name"
                    placeholder="Name"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        name: e?.target?.value,
                      }))
                    }
                  />
                </Grid>
                <Grid item width={"30%"}>
                  <FormControl fullWidth variant="standard">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        format={DATE_FORMAT}
                        slotProps={{
                          textField: {
                            variant: "standard",
                            InputLabelProps: { shrink: true },
                            size: "small",
                          },
                        }}
                        value={formData?.date ? new Date(formData?.date) : null}
                        label="Date"
                        onChange={(e) => {
                          let isoDate = changeDateFormatToISO(e);
                          setFormData((prev) => ({
                            ...prev,
                            date:
                              e !== "Invalid Date" || e !== null ? isoDate : e,
                          }));
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                        className="normal_normal_600_12_Manropee outlined_border"
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid
                  width={"30%"}
                  pl={2}
                  boxSizing="border-box"
                  display={"flex"}
                  alignItems={"center"}
                  justifyItems={"flex-end"}
                  textAlign={"right"}
                >
                  <Tooltip title="save">
                    <IconButton
                      onClick={() =>
                        handleSave([formData, ...anniversaryDataTemp])
                      }
                      sx={{ marginLeft: "auto" }}
                    >
                      <CheckCircleIcon
                        sx={{ color: "#00CE3F", cursor: "pointer" }}
                        fontSize="large"
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="cancel">
                    <IconButton
                      onClick={() => {
                        setEdit(false);
                        setFormData({ name: "", date: null });
                        setAnniversaryDataTemp(anniversaryData);
                      }}
                    >
                      <CancelIcon
                        sx={{ color: "grey", cursor: "pointer" }}
                        fontSize="large"
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </>
            ) : (
              <Grid px={2}>
                <AddButton onClick={() => setEdit(true)} fontSize="medium" />
              </Grid>
            )}
          </Grid>
          {/* Table data section */}
          <Grid
            item
            xs={12}
            style={{ width: "100%", height: "65vh", overflow: "auto" }}
          >
            <DataGridComponent
              columns={columns}
              rows={anniversaryDataTemp || []}
              onRowClick={() => {}}
            />
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ClientAnniversaryDialog;
