import {
  Autocomplete,
  Chip,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Loader from "../../../components/Loader";
import PaginationComponent from "../../../components/PaginationComponent";
import ThingsToPick from "../../../pages/ThingsToPick/thingsToPick";
import { setTaskData } from "../../../slice/task";
import { getThingsToDo } from "../../../thunks/dashboard";
import { getAllUser, getConsultant } from "../../../thunks/user";
import {
  NOTIFICATION_MESSAGE,
  PAGINATION_LIMIT,
  TASK_TYPES,
  USER_ROLE,
} from "../../../utils/constant";
import { handleTasktype } from "../../../utils/util";
import DueDateDialog from "../../Instructions/components/DueDateDialog";
import AlertComponent from "../../../components/alert";
import { updateTaskCompleteAndPick } from "../../../slice/instruction";
import {
  allocateOrCompleteIndividualTask,
  allocateOrCompleteTask,
} from "../../../thunks/instruction";
import { showSnackbar } from "../../../slice/snackbarSlice";
import { allocateOrCompleteClientTask } from "../../../thunks/client";

const ThingsToDo = () => {
  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state?.auth?.currentUser);
  console.log(userDetails);
  const lookups = useSelector((state) => state?.lookupData);
  let { Role: roles } = lookups || {};

  // states
  const [isLoader, setLoader] = useState(false);
  const [selectedDueDateData, setSelectedDueDateData] = useState(null);
  const [isOpenDueDate, setOpenDueDate] = useState(false);
  const [totalCount, setTotalCount] = useState("");
  const [filterPayload, setFilterPayload] = useState({
    page: 1,
    limit: PAGINATION_LIMIT,
    taskType: "All",
    userId: null,
    roleToSearch: [],
  });
  const [isTaskCompleted, setTaskComplete] = useState(false);
  const [completeTaskId, setCompleteTaskId] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [thingsToDoData, setThingsToDoData] = useState([]);
  const [isThingsToPick, setThingsToPick] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [consultants, setConsultants] = useState([]);

  /* Method to get things to do data for consultants 
    (Getting Todo Task list for consultant) */
  const getThingsToDoData = async (payload = filterPayload) => {
    setLoader(true);
    try {
      const response = await dispatch(getThingsToDo(payload)).unwrap();
      if (response) {
        setThingsToDoData(response);
        setTotalCount(
          response?.pickedInstructionTasksLength || response?.recordLength
        );
        setLoader(false);
      } else {
        setThingsToDoData([]);
        setTotalCount(0);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      return error;
    }
  };

  /* Method to change page --> Pagination */
  const handleChangePage = (event, value) => {
    setFilterPayload({ ...filterPayload, page: value });
    const payload = {
      ...filterPayload,
      page: value,
    };
    getThingsToDoData(payload);
  };

  // Method to Handle change tab value
  const handleChangeTabValue = (event, newVal) => {
    setTabValue(newVal);
    if (newVal === 0) {
      getThingsToDoData();
    }
  };

  // Method to handle change task type
  const handleChangeTaskType = (value) => {
    setFilterPayload({ ...filterPayload, page: 1, taskType: value });
    const payload = {
      ...filterPayload,
      taskType: value,
      page: 1,
    };
    getThingsToDoData(payload);
  };

  // Method to handle change role
  const handleChangeRole = (value) => {
    setFilterPayload({
      ...filterPayload,
      page: 1,
      roleToSearch: value !== null ? [value] : [],
    });
    const payload = {
      ...filterPayload,
      roleToSearch: value !== null ? [value] : [],
      page: 1,
    };

    getThingsToDoData(payload);
  };

  // Method to handle change user
  const handleChangeUser = (value) => {
    setFilterPayload({ ...filterPayload, page: 1, userId: value });
    const payload = {
      ...filterPayload,
      userId: value,
      page: 1,
    };
    getThingsToDoData(payload);
  };

  const handleChangeConsultant = (value) => {
    // Method to handle change user
    setFilterPayload({ ...filterPayload, page: 1, userId: value });
    const payload = {
      ...filterPayload,
      userId: value,
      page: 1,
    };
    getThingsToDoData(payload);
  };

  // Method to check date if date is greater then current date
  const checkDate = (data) => {
    // Parse the provided date into a Moment.js object
    const tempCurrentDate = moment().format("DD/MM/YYYY hh:mm A");
    const tempProvidedDate = moment(data?.dueDate || null).format(
      "DD/MM/YYYY hh:mm A"
    );
    const tempEndDate = moment(data?.endDate || null).format(
      "DD/MM/YYYY hh:mm A"
    );
    const providedDate = moment(tempProvidedDate, "DD/MM/YYYY hh:mm A");
    const currentDate = moment(tempCurrentDate, "DD/MM/YYYY hh:mm A");
    const endDate = moment(tempEndDate, "DD/MM/YYYY hh:mm A");
    // Compare the dates
    const isDateBeforeComplete = providedDate.isBefore(currentDate);
    const isDateAfterComplete = providedDate.isBefore(endDate);
    if (isDateBeforeComplete && !data?.isCompleted) {
      return true;
    } else if (isDateAfterComplete && data?.isCompleted) {
      return true;
    } else {
      return false;
    }
  };

  /* Method to open due date dialog if task status is not completed */
  const handleClickOpenDueDate = (data) => {
    let tempObj = {
      id: data?.id,
      dueDate: data.dueDate,
      taskType: data?.taskType,
      isClientTask: data?.taskType === "Client" && true,
    };
    setSelectedDueDateData(tempObj);
    setOpenDueDate(true);
  };

  // Method to get all users
  const getUsers = async () => {
    try {
      setLoader(true);
      const response = await dispatch(getAllUser()).unwrap();
      if (response) {
        setLoader(false);
        setUsersList(response);
      }
    } catch (error) {
      setLoader(false);
      return error;
    }
  };

  //METHOD TO HANDLE RE-DIRECTION ONCLICK OF DASHBOARD'S TASK
  const handleRedirect = (
    instructionId,
    leadClientId,
    taskType,
    taskId,
    instructionProductId
  ) => {
    if (taskType === "Client") {
      dispatch(setTaskData({ taskType, leadClientId, taskId }));
      navigate(`/app/client/update-client/${leadClientId}`);
    } else {
      if (taskType && ["Custom", "Instance"].includes(taskType)) {
        dispatch(setTaskData({ taskType, instructionId, taskId }));
      } else {
        dispatch(setTaskData({ instructionId, taskId, instructionProductId }));
      }
      navigate(`/app/instructions/dashboard/${instructionId}`);
    }
  };

  /* Method to complete task */
  const handleClickCompleteTask = (data, e) => {
    e.stopPropagation();
    setTaskComplete(true);
    setCompleteTaskId(data);
  };

  /* Success method to update task status to Completed */
  const handleCompleteTaskSuccess = async () => {
    setTaskComplete(false);
    const payload = {
      id: completeTaskId?.id,
      status: "Completed",
    };
    try {
      setLoader(true);
      const response = await dispatch(
        completeTaskId?.taskType === "Custom" ||
          completeTaskId?.taskType === "Instance"
          ? allocateOrCompleteIndividualTask(payload)
          : completeTaskId?.taskType === "Client"
          ? allocateOrCompleteClientTask(payload)
          : allocateOrCompleteTask(payload)
      ).unwrap();
      setLoader(false);
      if (response) {
        getThingsToDoData();
        dispatch(
          showSnackbar({
            show: true,
            message: NOTIFICATION_MESSAGE?.COMPLETE_TASK_SUCCESS,
            severity: "success",
          })
        );
      }
    } catch (error) {
      console.log("error from dashboard ", error);
      setLoader(false);
      dispatch(
        showSnackbar({
          show: true,
          message: error?.message,
          severity: "error",
        })
      );
    }
  };

  // Method to fetch all consultants.
  const getAllConsultants = async () => {
    try {
      const response = await dispatch(getConsultant()).unwrap();
      if (response?.length > 0) {
        setConsultants(response);
      } else {
        setConsultants([]);
      }
    } catch (error) {}
  };

  // use effect
  useEffect((d) => {
    getThingsToDoData();
    getUsers();
    getAllConsultants();
  }, []);

  return (
    <>
      {/* Dialog for update due date */}
      {isOpenDueDate && (
        <DueDateDialog
          isIndividual={
            selectedDueDateData?.taskType === "Instance" ||
            selectedDueDateData?.taskType === "Custom"
          }
          isDashboardTask={true}
          open={isOpenDueDate}
          handleClose={() => setOpenDueDate(false)}
          selectedData={selectedDueDateData}
          getThingsToDoData={getThingsToDoData}
          isClientTask={selectedDueDateData?.taskType === "Client" && true}
        />
      )}
      {/* Dialog for Complete Task Confirmation */}
      {isTaskCompleted && (
        <AlertComponent
          open={isTaskCompleted}
          handleSuccess={handleCompleteTaskSuccess}
          handleClose={() => setTaskComplete(false)}
          message="Are you sure you want to complete the task?"
        />
      )}
      {/* Loader Component */}
      {isLoader && <Loader />}{" "}
      <Grid
        maxHeight={"90vh"}
        display={"flex"}
        flexDirection={"column"}
        gap={1}
        pb={"15px"}
      >
        <Grid container spacing={3} mb={2}>
          <Grid item xs={12}>
            <Tabs
              fullWidth
              value={tabValue}
              onChange={handleChangeTabValue}
              aria-label="Things To Do"
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "#00CE3F",
                },
                "& .MuiTab-root.Mui-selected": {
                  color: "#00CE3F",
                },
                "& .MuiTab-root": {
                  color: "#3D4740",
                },
              }}
            >
              <Tab
                className="serif_display_regular_22"
                style={{ textTransform: "none" }}
                label="Things To Do"
              />
              <Tab
                className="serif_display_regular_22"
                style={{ textTransform: "none" }}
                label="Things To Pick"
              />
            </Tabs>
          </Grid>
          {tabValue === 0 && (
            <>
              {/* TASK TYPE  */}
              <Grid item xs={3}>
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "30px",
                    },
                  }}
                >
                  <InputLabel className="normal_normal_medium_14_Manrope dark_grey">
                    Task Type
                  </InputLabel>
                  <Select
                    className="textField_outlined_border"
                    label="Task Type"
                    value={filterPayload?.taskType}
                    onChange={(e) => handleChangeTaskType(e?.target?.value)}
                  >
                    {TASK_TYPES?.length > 0 &&
                      TASK_TYPES.map((d) => (
                        <MenuItem
                          key={d?.id}
                          value={d?.value}
                          className="normal_normal_medium_14_Manrope dark_grey"
                        >
                          {d?.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              {userDetails?.role === USER_ROLE.SUPER_ADMIN && (
                <>
                  {/* ROLE  */}
                  <Grid item xs={3}>
                    <FormControl
                      fullWidth
                      size="small"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "30px",
                        },
                      }}
                    >
                      <InputLabel className="normal_normal_medium_14_Manrope dark_grey">
                        Role
                      </InputLabel>
                      <Select
                        className="textField_outlined_border"
                        label="Task Type"
                        value={roles?.name}
                        onChange={(e) => handleChangeRole(e?.target?.value)}
                      >
                        <MenuItem value={null}>--</MenuItem>
                        {roles?.length > 0 &&
                          roles.map((d) => (
                            <MenuItem
                              key={d?.id}
                              value={d?.value}
                              className="normal_normal_medium_14_Manrope dark_grey"
                            >
                              {d?.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* USERID  */}
                  <Grid item xs={6}>
                    <Autocomplete
                      // value={userData?.TaskUser}
                      id="tags-outlined"
                      options={usersList || []}
                      getOptionLabel={(option) =>
                        `${option?.firstName} ${option?.lastName} (${option?.role})`
                      }
                      onChange={(event, value) => handleChangeUser(value?.id)}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "30px",
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="normal_normal_medium_14_Manropee textField_outlined_border"
                          variant="outlined"
                          size="small"
                          label="Select user"
                          placeholder="Select user"
                        />
                      )}
                    />
                  </Grid>
                </>
              )}

              {/* Consultant List  */}
              {userDetails?.permissions?.[0]?.isPermission && (
                <Grid item xs={4}>
                  <Autocomplete
                    options={consultants || []}
                    getOptionLabel={(option) =>
                      `${option?.firstName || ""} ${option?.lastName || ""}`
                    }
                    onChange={(event, value) =>
                      handleChangeConsultant(value?.id)
                    }
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        {...params}
                        className="normal_normal_medium_14_Manropee inputTextField border_color"
                        size="small"
                        label="Consultant"
                        placeholder="Select consultant"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            height: 24,
                            borderRadius: "24px",
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
              )}

              <Grid
                item
                xs={userDetails?.role === USER_ROLE.SUPER_ADMIN ? 12 : 9}
              >
                {/* PAGINATION */}
                <PaginationComponent
                  totalCount={totalCount}
                  limit={filterPayload?.limit}
                  page={filterPayload?.page}
                  onChange={handleChangePage}
                  siblingCount={0}
                  boundaryCount={1}
                />
              </Grid>
            </>
          )}
        </Grid>

        {tabValue === 0 && (
          <>
            {/* Things to do List section */}
            <Grid
              display={"flex"}
              flexDirection={"column"}
              overflow={"auto"}
              className="dashboard-scrollbar"
              gap={1}
              px={2}
              py={1}
            >
              {/* Instruction Task Type === All */}
              {filterPayload?.taskType === "All" &&
                thingsToDoData?.pickedInstructionTasks?.length > 0 &&
                thingsToDoData?.pickedInstructionTasks?.map((data) => (
                  <>
                    <Grid
                      item
                      xs={12}
                      display={"flex"}
                      flexDirection={"row"}
                      style={{
                        padding: "8px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleRedirect(
                          data?.instructionId,
                          data?.instruction?.InstructionLeadClient?.[0]
                            ?.LeadClient?.leadClientId,
                          data?.taskType,
                          data?.id,
                          data?.instructionProductId
                        )
                      }
                    >
                      <Grid item xs={5}>
                        {data?.instruction?.InstructionLeadClient?.length > 0 &&
                          data?.instruction?.InstructionLeadClient?.map(
                            (client) => {
                              return (
                                <Typography className="normal_normal_600_16_Manrope green">
                                  {`${client?.LeadClient?.firstName} ${client?.LeadClient?.lastName}`}
                                </Typography>
                              );
                            }
                          )}
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        style={{
                          padding: "12px",
                        }}
                      >
                        <Typography className="normal_normal_bold_14_Manrope dark_grey">
                          {data?.taskType === "Client"
                            ? ""
                            : data?.instruction?.milestone
                            ? data?.instruction?.milestone
                            : "NA"}
                        </Typography>
                        <Typography className="normal_normal_14_Manrope dark_grey">
                          {data?.name || "NA"}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Chip
                          label={handleTasktype(data?.taskType)?.taskType}
                          style={{
                            color: handleTasktype(data?.taskType)?.colorCode,
                          }}
                        />
                      </Grid>
                      <Grid>
                        <Tooltip
                          title={
                            data?.status === "Completed"
                              ? "Completed Task"
                              : "Click to complete task"
                          }
                        >
                          <IconButton
                            onClick={(e) => handleClickCompleteTask(data, e)}
                          >
                            <CheckCircleIcon
                              sx={{
                                color: `${
                                  data?.status === "Completed"
                                    ? "#00CE3F"
                                    : "#dee2e6"
                                }`,
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    {/* Due Date Section */}
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Typography className="serif_display_regular_20">
                        <Tooltip title="Update Due Date">
                          <span
                            className={`normal_normal_600_14_Manrope ${
                              checkDate(data) ? "red" : "green"
                            }`}
                            style={{ marginLeft: "1rem", cursor: "pointer" }}
                            onClick={() => handleClickOpenDueDate(data)}
                          >
                            (Due by:-{" "}
                            {data?.dueDate
                              ? moment(new Date(data?.dueDate)).format(
                                  "DD/MM/YYYY hh:mm A"
                                )
                              : "NA"}
                            )
                          </span>
                        </Tooltip>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider sx={{ border: `1px solid #DCDCE1` }} />
                    </Grid>
                  </>
                ))}

              {/* Instruction Task Type === Instruction */}
              {filterPayload?.taskType === "Instruction" &&
                thingsToDoData?.pickedInstructionTasks?.length > 0 &&
                thingsToDoData?.pickedInstructionTasks?.map((data) => (
                  <>
                    <Grid
                      item
                      xs={12}
                      display={"flex"}
                      flexDirection={"row"}
                      style={{
                        padding: "8px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleRedirect(
                          data?.instructionId,
                          data?.instruction?.InstructionLeadClient?.[0]
                            ?.LeadClient?.leadClientId,
                          data?.taskType,
                          data?.id,
                          data?.instructionProductId
                        )
                      }
                    >
                      <Grid item xs={5}>
                        {data?.instruction?.InstructionLeadClient?.length > 0 &&
                          data?.instruction?.InstructionLeadClient?.map(
                            (client) => {
                              return (
                                <Typography className="normal_normal_600_16_Manrope green">
                                  {`${client?.LeadClient?.firstName} ${client?.LeadClient?.lastName}`}
                                </Typography>
                              );
                            }
                          )}
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        style={{
                          padding: "12px",
                        }}
                      >
                        <Typography className="normal_normal_bold_14_Manrope dark_grey">
                          {data?.instruction?.milestone || "NA"}
                        </Typography>
                        <Typography className="normal_normal_14_Manrope dark_grey">
                          {data?.name || "NA"}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Chip
                          label={handleTasktype(data?.taskType)?.taskType}
                          style={{
                            color: handleTasktype(data?.taskType)?.colorCode,
                          }}
                        />
                      </Grid>
                      <Grid>
                        <Tooltip
                          title={
                            data?.status === "Completed"
                              ? "Completed Task"
                              : "Click to complete task"
                          }
                        >
                          <IconButton
                            onClick={(e) => handleClickCompleteTask(data, e)}
                          >
                            <CheckCircleIcon
                              sx={{
                                color: `${
                                  data?.status === "Completed"
                                    ? "#00CE3F"
                                    : "#dee2e6"
                                }`,
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    {/* Due Date Section */}
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Typography className="serif_display_regular_20">
                        <Tooltip title="Update Due Date">
                          <span
                            className={`normal_normal_600_14_Manrope ${
                              checkDate(data) ? "red" : "green"
                            }`}
                            style={{ marginLeft: "1rem", cursor: "pointer" }}
                            onClick={() => handleClickOpenDueDate(data)}
                          >
                            (Due by:-{" "}
                            {data?.dueDate
                              ? moment(new Date(data?.dueDate)).format(
                                  "DD/MM/YYYY hh:mm A"
                                )
                              : "NA"}
                            )
                          </span>
                        </Tooltip>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider sx={{ border: `1px solid #DCDCE1` }} />
                    </Grid>
                  </>
                ))}

              {/* Instruction Task Type === Individual */}
              {filterPayload?.taskType === "Individual" &&
                thingsToDoData?.individualTasks?.length > 0 &&
                thingsToDoData?.individualTasks?.map((data) => (
                  <>
                    <Grid
                      item
                      xs={12}
                      display={"flex"}
                      flexDirection={"row"}
                      style={{
                        padding: "8px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleRedirect(
                          data?.instructionId,
                          data?.instruction?.InstructionLeadClient?.[0]
                            ?.LeadClient?.leadClientId,
                          data?.taskType,
                          data?.id,
                          data?.instructionProductId
                        )
                      }
                    >
                      <Grid item xs={5}>
                        {data?.instruction?.InstructionLeadClient?.length > 0 &&
                          data?.instruction?.InstructionLeadClient?.map(
                            (client) => {
                              return (
                                <Typography className="normal_normal_600_16_Manrope green">
                                  {`${client?.LeadClient?.firstName} ${client?.LeadClient?.lastName}`}
                                </Typography>
                              );
                            }
                          )}
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        style={{
                          padding: "12px",
                        }}
                      >
                        <Typography className="normal_normal_bold_14_Manrope dark_grey">
                          {data?.instruction?.milestone || "NA"}
                        </Typography>
                        <Typography className="normal_normal_14_Manrope dark_grey">
                          {data?.instructionTasks?.name || "NA"}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Chip
                          label={handleTasktype(data?.taskType)?.taskType}
                          style={{
                            color: handleTasktype(data?.taskType)?.colorCode,
                          }}
                        />
                      </Grid>
                      <Grid>
                        <Tooltip
                          title={
                            data?.status === "Completed"
                              ? "Completed Task"
                              : "Click to complete task"
                          }
                        >
                          <IconButton
                            onClick={(e) => handleClickCompleteTask(data, e)}
                          >
                            <CheckCircleIcon
                              sx={{
                                color: `${
                                  data?.status === "Completed"
                                    ? "#00CE3F"
                                    : "#dee2e6"
                                }`,
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    {/* Due Date Section */}
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Typography className="serif_display_regular_20">
                        <Tooltip title="Update Due Date">
                          <span
                            className={`normal_normal_600_14_Manrope ${
                              checkDate(data) ? "red" : "green"
                            }`}
                            style={{ marginLeft: "1rem", cursor: "pointer" }}
                            onClick={() => handleClickOpenDueDate(data)}
                          >
                            (Due by:-{" "}
                            {data?.dueDate
                              ? moment(new Date(data?.dueDate)).format(
                                  "DD/MM/YYYY hh:mm A"
                                )
                              : "NA"}
                            )
                          </span>
                        </Tooltip>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider sx={{ border: `1px solid #DCDCE1` }} />
                    </Grid>
                  </>
                ))}

              {/* Instruction Task Type === Custom */}
              {filterPayload?.taskType === "Custom" &&
                thingsToDoData?.individualTasks?.length > 0 &&
                thingsToDoData?.individualTasks?.map((data) => (
                  <>
                    <Grid
                      item
                      xs={12}
                      display={"flex"}
                      flexDirection={"row"}
                      style={{
                        padding: "8px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleRedirect(
                          data?.instructionId,
                          data?.instruction?.InstructionLeadClient?.[0]
                            ?.LeadClient?.leadClientId,
                          data?.taskType,
                          data?.id,
                          data?.instructionProductId
                        )
                      }
                    >
                      <Grid item xs={5}>
                        {data?.instruction?.InstructionLeadClient?.length > 0 &&
                          data?.instruction?.InstructionLeadClient?.map(
                            (client) => {
                              return (
                                <Typography className="normal_normal_600_16_Manrope green">
                                  {`${client?.LeadClient?.firstName} ${client?.LeadClient?.lastName}`}
                                </Typography>
                              );
                            }
                          )}
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        style={{
                          padding: "12px",
                        }}
                      >
                        <Typography className="normal_normal_bold_14_Manrope dark_grey">
                          {data?.instruction?.milestone || "NA"}
                        </Typography>
                        <Typography className="normal_normal_14_Manrope dark_grey">
                          {data?.name || "NA"}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Chip
                          label={handleTasktype(data?.taskType)?.taskType}
                          style={{
                            color: handleTasktype(data?.taskType)?.colorCode,
                          }}
                        />
                      </Grid>
                      <Grid>
                        <Tooltip
                          title={
                            data?.status === "Completed"
                              ? "Completed Task"
                              : "Click to complete task"
                          }
                        >
                          <IconButton
                            onClick={(e) => handleClickCompleteTask(data, e)}
                          >
                            <CheckCircleIcon
                              sx={{
                                color: `${
                                  data?.status === "Completed"
                                    ? "#00CE3F"
                                    : "#dee2e6"
                                }`,
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    {/* Due Date Section */}
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Typography className="serif_display_regular_20">
                        <Tooltip title="Update Due Date">
                          <span
                            className={`normal_normal_600_14_Manrope ${
                              checkDate(data) ? "red" : "green"
                            }`}
                            style={{ marginLeft: "1rem", cursor: "pointer" }}
                            onClick={() => handleClickOpenDueDate(data)}
                          >
                            (Due by:-{" "}
                            {data?.dueDate
                              ? moment(new Date(data?.dueDate)).format(
                                  "DD/MM/YYYY hh:mm A"
                                )
                              : "NA"}
                            )
                          </span>
                        </Tooltip>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider sx={{ border: `1px solid #DCDCE1` }} />
                    </Grid>
                  </>
                ))}
              {/* Instruction Task Type === Client */}
              {filterPayload?.taskType === "Client" &&
                thingsToDoData?.individualTasks?.length > 0 &&
                thingsToDoData?.individualTasks?.map((data) => (
                  <>
                    <Grid
                      item
                      xs={12}
                      display={"flex"}
                      flexDirection={"row"}
                      style={{
                        padding: "8px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleRedirect(
                          data?.instructionId,
                          data?.instruction?.InstructionLeadClient?.[0]
                            ?.LeadClient?.leadClientId || data?.leadClientId,
                          data?.taskType,
                          data?.id,
                          data?.instructionProductId
                        )
                      }
                    >
                      <Grid item xs={5}>
                        <Typography className="normal_normal_600_16_Manrope green">
                          {`${data?.LeadClient?.firstName || "NA"} ${
                            data?.LeadClient?.lastName || "NA"
                          }`}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        style={{
                          padding: "12px",
                        }}
                      >
                        <Typography className="normal_normal_bold_14_Manrope dark_grey">
                          {/* {data?.instruction?.milestone || "NA"} */}
                        </Typography>
                        <Typography className="normal_normal_14_Manrope dark_grey">
                          {data?.name || "NA"}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Chip
                          label={handleTasktype(data?.taskType)?.taskType}
                          style={{
                            color: handleTasktype(data?.taskType)?.colorCode,
                          }}
                        />
                      </Grid>
                      <Grid>
                        <Tooltip
                          title={
                            data?.status === "Completed"
                              ? "Completed Task"
                              : "Click to complete task"
                          }
                        >
                          <IconButton
                            onClick={(e) => handleClickCompleteTask(data, e)}
                          >
                            <CheckCircleIcon
                              sx={{
                                color: `${
                                  data?.status === "Completed"
                                    ? "#00CE3F"
                                    : "#dee2e6"
                                }`,
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    {/* Due Date Section */}
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Typography className="serif_display_regular_20">
                        <Tooltip title="Update Due Date">
                          <span
                            className={`normal_normal_600_14_Manrope ${
                              checkDate(data) ? "red" : "green"
                            }`}
                            style={{ marginLeft: "1rem", cursor: "pointer" }}
                            onClick={() => handleClickOpenDueDate(data)}
                          >
                            (Due by:-{" "}
                            {data?.dueDate
                              ? moment(new Date(data?.dueDate)).format(
                                  "DD/MM/YYYY hh:mm A"
                                )
                              : "NA"}
                            )
                          </span>
                        </Tooltip>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider sx={{ border: `1px solid #DCDCE1` }} />
                    </Grid>
                  </>
                ))}
            </Grid>
          </>
        )}

        {/* Things To Pick List Component  */}
        {tabValue === 1 && <ThingsToPick />}
      </Grid>
    </>
  );
};

export default ThingsToDo;
