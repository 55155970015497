/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../../assets/images/Squiggle_S_Square_Green.png";
import LoginUserIcon from "../../../assets/images/loginUser_icon.png";

import { Button } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useThemeContext } from "../../../contexts/theme";
import MenuData from "../sidebars/adminMenu";

function Toggle({ label, value, checked, onChange, ...props }) {
  return (
    <div className="form-check form-switch">
      <input
        className="form-check-input danger"
        type="checkbox"
        value={value}
        checked={checked}
        onChange={onChange}
        {...props}
      />
      <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
        {label}
      </label>
    </div>
  );
}
function Iconbutton({
  id,
  icon,
  backgroundColor,
  color,
  isDropdownToggle,

  ...props
}) {
  const getClassName = () => {
    let clsName = `btn border-0 bg-${backgroundColor}`;
    if (isDropdownToggle) {
      clsName = clsName + " dropdown-toggle";
    }

    if (color) {
      clsName = clsName + ` text-${color}`;
    }
    return clsName;
  };
  return (
    <button
      data-testid="iconButtonTestId"
      className={getClassName()}
      type="button"
      id={id}
      {...props}
    >
      {icon}
    </button>
  );
}

const NavBar = () => {
  const themeObj = useThemeContext();

  const [isDarkMode, changeMode] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [t, I18n] = useTranslation();
  const Location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.currentUser);
  const toggleTheme = () => {
    setTimeout(() => {
      if (themeObj.theme === "light") {
        themeObj.changeTheme("dark");
      } else {
        themeObj.changeTheme("light");
      }
    }, 100);
  };

  const getThemedTextColor = () => {
    if (themeObj.theme === "light") {
      return "text-white";
    } else {
      return "text-white";
    }
  };
  const getThemedBgColor = () => {
    if (themeObj.theme === "light") {
      return "bg-light";
    } else {
      return "bg-dark";
    }
  };
  const onFirstlayerClick = (e) => {
    const elemennts = document.getElementsByClassName("nested-parent");
    Array.prototype.forEach.call(elemennts, (ele) => {
      ele.classList.remove("active");
    });
  };
  const nestedLinkClicked = (e) => {
    const elemennts = document.getElementsByClassName("nested-parent");
    Array.prototype.forEach.call(elemennts, (ele) => {
      ele.classList.remove("active");
    });
    e.target.parentElement.parentElement.parentElement.children[0].classList.add(
      "active"
    );
  };
  const changeLanguage = (lnCode) => {
    I18n.changeLanguage(lnCode);
  };

  const handleLogout = () => {
    // googleLogout();
    Cookies.remove("jwt-token");
    navigate("/login");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    changeMode(themeObj.theme === "dark");
  }, []);

  useEffect(() => {
    changeMode(themeObj.theme === "dark");
  }, [themeObj]);

  return (
    <div className="layout-container-nav-bar">
      <nav className={`navbar navbar-expand-md  ${getThemedBgColor()}`}>
        <div className="container-fluid">
          <a
            href="/"
            className={`d-flex align-items-start mb-md-0 me-md-auto flex-grow-1 ${getThemedTextColor()} text-decoration-none`}
          >
            <span
              className={`fs-4 px-3 py-1 ${
                themeObj.theme === "light" ? "bg-transparent" : "bg-transparent"
              }`}
            >
              <img src={Logo} alt="Squiggle" />
            </span>
          </a>

          <button
            className={`navbar-toggler ${getThemedTextColor()}`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <i style={{ fontSize: "26px" }} className="bi bi-list"></i>
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="d-flex justify-content-between aling-items-center w-100  flex-md-row">
              <ul className="navbar-nav" style={{ height: "60px" }}>
                {MenuData.map((item, index) => {
                  if (!item.subMenu) {
                    return (
                      <li
                        key={index}
                        className="nav-item"
                        style={{
                          backgroundColor: `${
                            item?.path === Location?.pathname ? "#E5FAEB" : ""
                          }`,
                        }}
                      >
                        <NavLink
                          to={item.path}
                          className={`nav-link text-start ${getThemedTextColor()} ${
                            item.vissbleOnSmallScrren ? "" : "d-none d-md-block"
                          }`}
                          aria-current="page"
                          onClick={onFirstlayerClick}
                          style={{ marginTop: "0.5rem" }}
                        >
                          <span
                            className="nav-label"
                            style={{
                              margin: "0.2rem",
                              color: `${
                                item?.path === Location?.pathname
                                  ? "#00CE3F"
                                  : ""
                              }`,
                              fontWeight: `${
                                item?.path === Location?.pathname
                                  ? "bolder"
                                  : ""
                              }`,
                            }}
                          >
                            {t(item.label)}
                          </span>
                        </NavLink>
                      </li>
                    );
                  } else {
                    return (
                      <li key={index} className="nav-item dropdown">
                        <a
                          className={`nav-link nested-parent text-start dropdown-toggle  ${getThemedTextColor()} ${
                            item.vissbleOnSmallScrren ? "" : "d-none d-md-block"
                          }`}
                          to={"#"}
                          id="navbarDropdownMenuLink"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {t(item.label)}
                        </a>
                        <ul
                          style={{ border: "0px" }}
                          className={`dropdown-menu ${getThemedBgColor()}`}
                          aria-labelledby="navbarDropdownMenuLink"
                        >
                          {item.subMenu.map((nestedItem, i) => {
                            return (
                              <li key={`nested-${i}`} className="nav-item">
                                <NavLink
                                  onClick={nestedLinkClicked}
                                  to={nestedItem.path}
                                  className={`nav-link text-start  ${getThemedTextColor()} ${
                                    item.vissbleOnSmallScrren
                                      ? ""
                                      : "d-none d-md-block"
                                  }`}
                                  aria-current="page"
                                >
                                  {nestedItem.icon}
                                  {t(nestedItem.label)}
                                </NavLink>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    );
                  }
                })}
              </ul>
              <div
                className="d-none d-md-flex me-3 aling-items-center"
                aria-expanded="true"
              >
                <Button
                  id="demo-positioned-button"
                  onClick={(event) => handleClick(event)}
                >
                  <span
                    className={`d-flex align-items-center ${getThemedTextColor()} text-decoration-none mx-2`}
                  >
                    {user?.photo ? (
                      <img
                        src={user?.photo || null}
                        alt=""
                        width="32"
                        height="32"
                        className="rounded-circle me-2"
                      />
                    ) : (
                      <img
                        src={LoginUserIcon}
                        style={{ marginRight: "1rem" }}
                      />
                    )}
                    <span className="nav-label">{user?.fullName || ""}</span>
                  </span>
                </Button>

                <Menu
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  open={open}
                  onClose={handleCloseMenu}
                  style={{
                    marginTop: "2.8rem",
                    left: -40,
                  }}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  // transformOrigin={{
                  //   vertical: "bottom",
                  //   horizontal: "right",
                  // }}
                  sx={{
                    "& .MuiList-root": {
                      background: "#00000029",
                      boxShadow: "1px 2px 4px #00000029",
                      opacity: 0.9,
                    },
                  }}
                >
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div
        className={`d-flex d-md-none  aling-items-end justify-content-end ${getThemedBgColor()}`}
      >
        <div className="dropdown dropstart">
          <a
            href="#"
            className={`d-flex align-items-center ${getThemedTextColor()} text-decoration-none dropdown-toggle mx-2`}
            id="dropdownUser1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            data-bs-auto-close="outside"
          >
            <img
              src={user?.photo || null}
              alt=""
              width="32"
              height="32"
              className="rounded-circle me-2"
            />
            <strong>{user?.fullName || ""}</strong>
          </a>
          <ul
            className="dropdown-menu"
            aria-labelledby="dropdownUser1"
            style={{ background: "#00000029" }}
          >
            <li>
              <a className="dropdown-item" href="#">
                Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
