import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updateInstruction } from "../../../../../thunks/instruction";
import { willQuestionnaireform_sections } from "../../../../../utils/constant";

const WillQuestionnaireForm = () => {
  const dispatch = useDispatch();
  const instructionDataObject = useSelector(
    (state) => state?.instruction?.tempInstructionData
  );
  const isJoint = useSelector((state) => state?.instruction?.isJoint);
  const arr = useSelector((state) => state?.instruction?.arr);

  const methods = useFormContext();
  const { handleSubmit, control } = methods;

  const clientArray = useFieldArray({
    control,
    name: "InstructionWillQuestionnaire",
  });

  const [formSections, setFormSection] = useState(
    willQuestionnaireform_sections(arr, isJoint)
  );

  useEffect(() => {
    setFormSection(willQuestionnaireform_sections(arr, isJoint));
  }, [instructionDataObject]);

  const handleSave = (data) => {
    const updatedData = {
      InstructionData: {
        ...instructionDataObject,
        InstructionWillQuestionnaire: data.client,
      },
    };
    const res = dispatch(updateInstruction(updatedData));
  };
  const handleErrors = (errors) => {};

  return (
    <Grid
      display={"flex"}
      flexDirection={"column"}
      border={"0px solid red"}
      mt={5}
    >
      {/* <FormHeader formName={"Will Questionnaire"} /> */}
      <Grid width={"100%"} mb={4}>
        {formSections.map((d) => {
          return (
            <Accordion
              elevation={0}
              style={{
                borderBottom: "1px solid #DCDCE1",
                width: "100%",
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <AccordionSummary
                style={{
                  width: "100%",
                  padding: "10px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Grid
                  width={"100%"}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={1}
                >
                  <Grid
                    width={"100%"}
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                  >
                    <span
                      style={{
                        width: "100%",
                        marginRight: "auto",
                      }}
                      className="normal_normal_semibold_20_Manrope green"
                    >
                      {d.title}
                      {d.titleBigBlack ? (
                        <span
                          style={{
                            width: "100%",
                            marginLeft: "5px",
                            marginRight: "auto",
                          }}
                          className="normal_normal_600_14_Manrope dark_grey"
                        >
                          {d.titleBigBlack}
                        </span>
                      ) : null}
                    </span>
                  </Grid>
                  <Grid
                    width={"100%"}
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                  >
                    <span
                      style={{
                        width: "100%",
                        marginRight: "auto",
                      }}
                      className="normal_normal_600_14_Manrope dark_grey"
                    >
                      {d.title2Big}
                    </span>
                  </Grid>
                  {d.title3Small ? (
                    <Grid
                      width={"100%"}
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                    >
                      <span
                        style={{
                          width: "100%",
                          marginRight: "auto",
                        }}
                        className="normal_normal_600_14_Manrope dark_grey"
                      >
                        {d.title3Small}
                      </span>
                    </Grid>
                  ) : null}
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <>{d?.component}</>
              </AccordionDetails>
            </Accordion>
            // </Grid>
          );
        })}
        {/* <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "1rem",
              }}
            >
              <SubmitButton onClick={handleSubmit(handleSave, handleErrors)} />
            </Grid> */}
      </Grid>
    </Grid>
  );
};

export default WillQuestionnaireForm;
