import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import { showSnackbar } from "../../../slice/snackbarSlice";
import {
  CreateQCCheckListQuestions,
  getQCCheckListById,
  updateQCCheckList,
} from "../../../thunks/instruction";
import { NOTIFICATION_MESSAGE, USER_ROLE } from "../../../utils/constant";
import QcPercentageDialog from "./QcPercentageDialog";

const QcQuestions = ({ instructionId, isInstructed }) => {
  // hooks
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state?.auth?.currentUser);

  // states
  const [qcQuestionsList, setQcQuestionsList] = useState([]);
  const [isQcQueLoader, setQcQueLoader] = useState(false);
  const [isLoader, setLoader] = useState(false);
  const [isQcPercentage, setIsQcPercentage] = useState(false);
  const [qcPercentage, setQcPercentage] = useState(null);

  // method to change qc checklist
  const handleChangeQcCheckList = (e, id, key) => {
    if (id) {
      setQcQuestionsList((prevList) => {
        const updatedArray = prevList.map((item) => {
          if (item.questionId === id) {
            // If the item has the specified id, update the key with the new value
            return {
              ...item,
              [key]: e,
            };
          }
          return item;
        });

        return updatedArray;
      });
    }
  };

  // method to get qc questions by id
  const getQCQuestionsByIdData = async () => {
    try {
      setQcQueLoader(true);
      const response = await dispatch(
        getQCCheckListById(instructionId)
      ).unwrap();
      if (response) {
        const tempQcQues = [];
        if (response?.length > 0) {
          response?.map((d) => {
            tempQcQues.push({
              name: d?.name || "",
              path: d?.path || "",
              questionId: d?.qcQuestionId,
              notes: d?.notes || "",
              ans: d?.ans,
              colourHexCode: d?.colourHexCode || "",
              isMandatory: d?.isMandatory,
            });
          });
        }
        setQcQuestionsList(tempQcQues);
        setQcQueLoader(false);
      } else {
        setQcQuestionsList([]);
        setQcQueLoader(false);
      }
    } catch (error) {
      setQcQuestionsList([]);
      setQcQueLoader(false);
      return error;
    }
  };

  // method to create qc checklist
  const handleCreateQcChecklist = async () => {
    try {
      setLoader(true);
      const response = await dispatch(
        CreateQCCheckListQuestions(instructionId)
      ).unwrap();
      if (response) {
        const tempQcQues = [];
        if (response?.length > 0) {
          response?.map((d) => {
            tempQcQues.push({
              name: d?.name || "",
              path: d?.path || "",
              questionId: d?.qcQuestionId,
              notes: d?.notes || "",
              ans: d?.ans,
              colourHexCode: d?.colourHexCode || "",
              isMandatory: d?.isMandatory,
            });
          });
        }
        setQcQuestionsList(tempQcQues);
        setLoader(false);
        dispatch(
          showSnackbar({
            message: NOTIFICATION_MESSAGE?.QC_CHECKLIST_CREATE_SUCCESS,
            severity: "success",
          })
        );
      } else {
        setQcQuestionsList([]);
        setLoader(false);
      }
    } catch (error) {
      setQcQuestionsList([]);
      setLoader(false);
      dispatch(
        showSnackbar({
          message: `${error?.message}`,
          severity: "error",
        })
      );
      return error;
    }
  };

  // method to submit qc checklist
  const handleSubmitQCCheckList = async () => {
    let wasNull = false;
    for (var i in qcQuestionsList) {
      if (qcQuestionsList[i].isMandatory && qcQuestionsList[i].ans === null) {
        wasNull = true;
      }
    }
    if (wasNull) {
      dispatch(
        showSnackbar({
          message: NOTIFICATION_MESSAGE?.REQUIRED_FIELD_ERROR,
          severity: "error",
        })
      );
    } else {
      setLoader(true);
      const tempData = qcQuestionsList.map((d, index) => ({
        ans: d?.ans || "",
        notes: d?.notes || "",
        questionId: d?.questionId || "",
      }));
      const payload = {
        id: instructionId,
        data: tempData,
      };
      try {
        const response = await dispatch(updateQCCheckList(payload)).unwrap();
        if (response) {
          setLoader(false);
          dispatch(
            showSnackbar({
              message: NOTIFICATION_MESSAGE?.QC_CHECKLIST_UPDATE_SUCCESS,
              severity: "success",
            })
          );
          setQcPercentage({
            totalObtainedMarks: response?.totalObtainedMarks,
            totalInstructionQcRating: response?.totalInstructionQcRating,
          });
          setIsQcPercentage(true);
        } else {
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);
        setIsQcPercentage(false);
        dispatch(
          showSnackbar({
            message: `${error?.message}`,
            severity: "error",
          })
        );
        return error;
      }
    }
  };

  // use effect
  useEffect(() => {
    getQCQuestionsByIdData();
  }, []);
  return (
    <>
      {/* Loader Component */}
      {isLoader && <Loader />}
      {/* Qc Percentage Component */}
      {isQcPercentage && (
        <QcPercentageDialog
          open={isQcPercentage}
          handleClose={() => setIsQcPercentage(false)}
          percentage={qcPercentage}
        />
      )}
      {qcQuestionsList?.length > 0 ? (
        <Grid
          item
          xs={12}
          mb={2}
          mt={-1}
          className="Qc_CheckList_position_fixed"
        >
          <Typography pl={1} className="serif_display_regular_26 dark_grey">
            QC Checklist
          </Typography>
          {qcQuestionsList?.length > 0 ? (
            <Grid
              item
              xs={3}
              style={{
                display: `${
                  userDetails?.role === USER_ROLE?.CONSULTANT ? "none" : ""
                }`,
              }}
            >
              {/* submit qc questions button */}
              <Button
                style={{ boxShadow: "none" }}
                disabled={isInstructed}
                onClick={handleSubmitQCCheckList}
                variant="contained"
                className="QC_checkList_submit_button"
              >
                Submit QC
              </Button>
            </Grid>
          ) : null}
        </Grid>
      ) : (
        <Grid
          item
          xs={7}
          mb={2}
          style={{
            display: `${
              userDetails?.role === USER_ROLE?.CONSULTANT ? "none" : ""
            }`,
          }}
        >
          {/* get qc checklist button */}
          <Button
            fullWidth
            variant="contained"
            style={{
              boxShadow: "none",
              backgroundColor: "#00ce3f",
              borderRadius: "30px",
            }}
            disabled={isInstructed}
            onClick={handleCreateQcChecklist}
          >
            Get QC Checklist
          </Button>
        </Grid>
      )}

      {/* qc checklist layout */}
      {isQcQueLoader ? (
        <Typography mt={5} className="serif_display_regular_18 dark_grey">
          Loading QC Checklist...
        </Typography>
      ) : (
        <Grid item xs={12} m={5}>
          {qcQuestionsList?.length > 0 ? (
            qcQuestionsList?.map((data, index) => (
              <>
                <Grid item xs={12} key={index}>
                  <Typography
                    className="normal_normal_600_12_Manrope"
                    style={{ color: data?.colourHexCode }}
                  >
                    {data?.path || "NA"}
                  </Typography>
                  <Typography className="normal_normal_16_Manrope dark_grey">
                    {data?.name || "NA"}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <RadioGroup
                    fullWidth
                    row
                    value={data?.ans || ""}
                    onChange={(e) =>
                      handleChangeQcCheckList(
                        e?.target?.value,
                        data?.questionId,
                        "ans"
                      )
                    }
                  >
                    <FormControlLabel
                      className="radioLabel"
                      value={"Yes"}
                      control={
                        <Radio
                          disabled={userDetails?.role === USER_ROLE?.CONSULTANT}
                          style={{ color: data?.colourHexCode }}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      className="radioLabel"
                      value={"No"}
                      control={
                        <Radio
                          disabled={userDetails?.role === USER_ROLE?.CONSULTANT}
                          style={{ color: data?.colourHexCode }}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </Grid>

                {/* Text field for additional  */}
                <Grid item xs={12} className="multiLine_css" mt={1}>
                  <TextField
                    disabled={userDetails?.role === USER_ROLE?.CONSULTANT}
                    fullWidth
                    label="Notes"
                    InputLabelProps={{
                      shrink: true,
                      className: "normal_normal_600_12_Manrope green",
                    }}
                    value={data?.notes}
                    multiline
                    rows={2}
                    placeholder="Enter note"
                    onChange={(e) =>
                      handleChangeQcCheckList(
                        e?.target?.value,
                        data?.questionId,
                        "notes"
                      )
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider
                    sx={{
                      border: `1px solid #DCDCE1`,
                      margin: "15px",
                      width: "100%",
                    }}
                  />
                </Grid>
              </>
            ))
          ) : userDetails?.role === USER_ROLE?.CONSULTANT ? (
            <Typography className="serif_display_regular_18 dark_grey">
              QC not yet done
            </Typography>
          ) : isInstructed ? (
            <Typography className="normal_normal_semibold_14_Manrope red">
              *Milestone is not Instructed, Please change milestone and get QC
              checklist .
            </Typography>
          ) : (
            <Typography className="serif_display_regular_18 dark_grey">
              Please Generate QC Checklist
            </Typography>
          )}
        </Grid>
      )}
    </>
  );
};

export default QcQuestions;
