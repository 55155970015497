import { Button, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import squiggleLogo from "../../assets/images/Squiggle_Logo_White.png";
import backgroundImage from "../../assets/images/login_background_image.png";
import Loader from "../../components/Loader";
import { showSnackbar } from "../../slice/snackbarSlice";
import { verifyEmailAction } from "../../thunks/auth";
import { NOTIFICATION_MESSAGE, VALIDATION_MSG } from "../../utils/constant";

const ForgotPassword = () => {
  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // states
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [formInputs, setFormInputs] = useState({ email: null });
  const [isLoader, setLoader] = useState(false);

  // handle change input
  const handleInputChange = (e) => {
    setFormInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // handle verify email
  const handleVerifyEmail = async () => {
    if (formInputs?.email === "") {
      setIsFormInvalid(true);
    } else {
      // proceed to Verify Email
      setIsFormInvalid(true);
      try {
        setLoader(true);
        const responseData = await dispatch(
          verifyEmailAction(formInputs)
        ).unwrap();
        setLoader(false);
        localStorage.setItem("email", formInputs?.email);
        navigate("/forgot-password/verify-otp", {
          state: {
            ...formInputs,
            email: responseData.data.email,
          },
        });
        dispatch(
          showSnackbar({
            message: NOTIFICATION_MESSAGE?.OTP_SUCCESS,
            severity: "success",
          })
        );
      } catch (error) {
        setLoader(false);
        dispatch(
          showSnackbar({
            show: true,
            message: `${error?.message}`,
            severity: "error",
          })
        );
      }
    }
  };

  return (
    <div className="forgotPasswordContainer">
      {/* Loader Component */}
      {isLoader && <Loader />}
      <Container fluid className="h-100">
        <Row>
          <Col xs={7} className="left-col">
            {/* Content for the left column */}
            <div className="backgroundImageWrapper">
              <div
                className="backgroundImageContainer"
                style={{
                  backgroundImage: `url(${backgroundImage})`,
                }}
              >
                <img
                  style={{ height: "100px" }}
                  src={squiggleLogo}
                  alt="squiggle"
                />
              </div>
            </div>
          </Col>
          <Col xs={5} className="right-col">
            {/* Content for the right column */}
            <div className="content">
              <Grid container spacing={3} className="forgotPasswordInputField">
                <Grid item xs={12} sx={{ marginBottom: "1rem" }}>
                  {/* verify email */}
                  <TextField
                    fullWidth
                    className="inputTextField"
                    placeholder="Email"
                    variant="standard"
                    size="small"
                    name="email"
                    error={isFormInvalid && !formInputs?.email}
                    helperText={
                      isFormInvalid &&
                      !formInputs?.email &&
                      VALIDATION_MSG.REQUIRED
                    }
                    onChange={handleInputChange}
                    value={formInputs.email}
                  />
                </Grid>
                {/* verify email button */}
                <Button
                  fullWidth
                  variant="contained"
                  className="save_button"
                  disableElevation
                  onClick={handleVerifyEmail}
                >
                  Verify Email
                </Button>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <div>
                    <Typography
                      className="forgotPasswordTitle"
                      onClick={() => navigate("/login")}
                    >
                      Back to Login ?
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ForgotPassword;
