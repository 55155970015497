import { Autocomplete, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  AddButton,
  ContainedGreenButton,
  OutlinedGreenButton,
  RemoveButton,
} from "../../../../../components/buttons";
import { RadioInput, TextInput } from "../../../../../components/inputs";
import {
  guardiansObject,
  guardiansObject1,
} from "./willQuestionnaireFormConstants";

const Guardians = () => {
  const isJoint = useSelector((state) => state?.instruction?.isJoint);
  const arr = useSelector((state) => state?.instruction?.arr);

  const client1 = useFieldArray({
    name: `InstructionWillQuestionnaire.[0].guardians`,
  });

  const { append, remove, fields } = client1;

  const client2 = useFieldArray({
    name: `InstructionWillQuestionnaire.[1].guardians`,
  });

  const {
    append: secondaryAppend,
    remove: secondaryRemove,
    fields: secondaryFields,
  } = client2;

  const handleAdd = (clientNumber, arrayLength) => {
    clientNumber === 0
      ? append(arrayLength === 0 ? guardiansObject : guardiansObject1)
      : secondaryAppend(arrayLength === 0 ? guardiansObject : guardiansObject1);
  };

  const handleRemove = (clientNumber, ind) => {
    if (clientNumber === 1) {
      remove(ind);
    } else {
      secondaryRemove(ind);
    }
  };
  return (
    <>
      <Grid
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={isJoint ? "space-between" : "center"}
        borderTop={`1px solid #DCDCE1`}
        borderBottom={`1px solid #DCDCE1`}
      >
        {arr?.map((item, index) => {
          return (
            <Grid
              width={isJoint ? "50%" : "62%"}
              borderRight={index === 0 && isJoint ? `1px solid #DCDCE1` : null}
              paddingY={4}
              display={"flex"}
              flexDirection={"column"}
              gap={3}
              paddingRight={index === 0 ? 2 : 0}
              paddingLeft={index === 1 ? 2 : 0}
            >
              {(index === 0 ? fields : secondaryFields).map((card, ind) => {
                return (
                  <GuardiansForm
                    key={card.id}
                    isJoint={isJoint}
                    index={index}
                    cardNo={ind}
                    handleRemove={() => handleRemove(item, ind)}
                    pathName={`InstructionWillQuestionnaire.${index}.guardians.${ind}`}
                    isApplicationJoint={isJoint}
                  />
                );
              })}
              <Grid textAlign={"center"}>
                <AddButton
                  onClick={() =>
                    handleAdd(
                      index,
                      (item === 1 ? fields : secondaryFields).length
                    )
                  }
                />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default Guardians;

const GuardiansForm = ({
  index,
  cardNo,
  handleRemove,
  pathName,
  isApplicationJoint,
}) => {
  const { getValues, setValue, formState, watch } = useFormContext();

  // guardianFor Dropdown
  const [dependant_array, setDependant_array] = useState([]);
  let guardianForName = `InstructionWillQuestionnaire.${index}.guardians.[${cardNo}].guardianFor`;
  let guarianForValue = watch(guardianForName);

  useEffect(() => {
    let depPathName_client1 = `InstructionClients[0].InstructionFactFind.dependants`;
    let depPathName_client2 = `InstructionClients[1].InstructionFactFind.dependants`;

    let defaultDependants = getValues(depPathName_client1);
    let defaultDependants_client2 = getValues(depPathName_client2);
    const dependant_array1_one = defaultDependants?.length
      ? defaultDependants?.map((item) => item?.fullName || "")
      : [];

    let dependant_array1 = [...dependant_array1_one];

    const dependant_array1_joint_values = defaultDependants?.length
      ? defaultDependants?.filter((dep) => dep.isJoint === true)
      : [];

    const dependant_array1_joint = dependant_array1_joint_values.length
      ? dependant_array1_joint_values.map((item) => {
          return item?.fullName || "";
        })
      : [];
    if (index == 1) {
      const dependant_array2_data = defaultDependants_client2?.length
        ? defaultDependants_client2?.map((item) => {
            return item?.fullName || "";
          })
        : [];

      dependant_array1 = [...dependant_array1_joint, ...dependant_array2_data];
    }

    setDependant_array(
      dependant_array1?.length ? [...dependant_array1, "Pets"] : ["Pets"]
    );
  }, []);

  const handleJoint = () => {
    let isJoint = getValues(`${pathName}.isJoint`) || false;
    setValue(`${pathName}.isJoint`, !isJoint);
  };

  const isCardJoint = useWatch({
    name: `${pathName}.isJoint`,
  });

  return (
    <Grid
      boxShadow={"1px 2px 6px #00000029"}
      borderRadius={"16px"}
      borderRight={index === 0 ? `1px solid #DCDCE1` : null}
      padding={2}
      paddingY={3}
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      position={"relative"}
    >
      {index === 0 && isApplicationJoint ? (
        <div className="card-mirror-btn">
          {isCardJoint ? (
            <ContainedGreenButton label={"Joint"} onClick={handleJoint} />
          ) : (
            <OutlinedGreenButton label={"Joint"} onClick={handleJoint} />
          )}
        </div>
      ) : null}
      <TextInput
        label={`Guardian ${cardNo + 1}`}
        name={`InstructionWillQuestionnaire.${index}.guardians.[${cardNo}].fullName`}
        placeholder="Enter name"
      />

      {cardNo > 0 ? (
        <RadioInput
          label="Going to act"
          name={`InstructionWillQuestionnaire.${index}.guardians.[${cardNo}].goingToAct`}
          options={[
            { label: "Jointly", value: "Jointly" },
            { label: "Reserve", value: "Reserve" },
          ]}
        />
      ) : null}

      <Autocomplete
        multiple
        className="textField_outlined_border"
        options={dependant_array || []}
        onChange={(e, data) => {
          setValue(guardianForName, data);
        }}
        value={Array.isArray(guarianForValue) ? guarianForValue : []}
        filterSelectedOptions={Boolean(dependant_array?.length)}
        renderInput={(params) => (
          <TextField
            {...params}
            className="normal_normal_medium_14_Manropee"
            variant="outlined"
            size="small"
            label="Guardian For"
            placeholder="Guardian For"
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "30px",
                minWidth: "260px",
              },
            }}
          />
        )}
      />

      <div style={{ textAlign: "right" }}>
        <RemoveButton label={"Joint"} onClick={handleRemove} />
      </div>
    </Grid>
  );
};
