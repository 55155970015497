import { Chip, Grid, Tooltip } from "@mui/material";
import React, { useState } from "react";

const EmailWithStatus = ({ d }) => {
  const [show, setShow] = useState(false);
  return (
    <Grid
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "3px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Tooltip title={d?.email}>
          <div
            className="normal_normal_600_14_Manrope dark_grey"
            style={{
              marginRight: "5px",
              flexGrow: 1,
              flexShrink: 1,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: !show ? "nowrap" : "normal",
              overflowWrap: show ? "break-word" : "normal",
              cursor: "pointer",
            }}
            onClick={() => setShow((prev) => !prev)}
          >
            {d?.email}
          </div>
        </Tooltip>
        <div
          style={{
            width: "80px",
            textAlign: "right",
          }}
        >
          <Chip
            label={d?.status}
            size="small"
            variant="outlined"
            className="white"
            style={{
              backgroundColor: d?.colourHex,
              borderColor: d?.colourHex,
            }}
          />
        </div>
      </div>
    </Grid>
  );
};

export default EmailWithStatus;
