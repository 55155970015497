import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
} from "@mui/material";
import React, { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import "../../../../../custom-bootstrap.scss";
import { factFindform_sections } from "../../../../../utils/constant";
import BasicDetailForm from "./BasicDetailForm";

const FactFindForm = () => {
  const methods = useFormContext();
  const { handleSubmit, control } = methods;

  const clientArray = useFieldArray({
    control,
    name: "InstructionClients",
  });

  const [formSections, setFormSection] = useState(factFindform_sections());

  const handleSave = (data) => {};
  const handleErrors = (errors) => {};

  return (
    <>
      <Box>
        {/* <FormHeader formName="Fact Find" /> */}
        <Grid width={"100%"} mb={4}>
          {/* Basic Detail Form */}
          <BasicDetailForm />
          {formSections.map((d) => {
            return (
              <Accordion
                key={d?.id}
                elevation={0}
                style={{
                  borderBottom: "1px solid #DCDCE1",
                  width: "100%",
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <AccordionSummary
                  style={{
                    width: "100%",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Grid
                    width={"100%"}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={1}
                  >
                    <Grid
                      width={"100%"}
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                    >
                      <span
                        className="normal_normal_semibold_20_Manrope green"
                        style={{
                          width: "100%",
                          marginRight: "auto",
                        }}
                      >
                        {d.title}
                        {d.titleSmallBlack ? (
                          <span
                            className="normal_normal_semibold_14_Manrope"
                            style={{
                              width: "100%",
                              marginLeft: "10px",
                              marginRight: "auto",
                              color: "#3D4740",
                            }}
                          >
                            {d.titleSmallBlack}
                          </span>
                        ) : null}
                      </span>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <>{d?.component}</>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Grid>
      </Box>
    </>
  );
};

export default FactFindForm;
