import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import { sideBarWithIconAndName } from "../utils/constant";
import { includesStringFromArray } from "../utils/util";
import AppBar from "./components/AppBar";
import BottomTab from "./components/bottomTabs";
import NavBar from "./components/navbar";
import SideBar from "./components/sidebars";

export const WithSideBar = (props) => {
  const ref = useRef();
  const Location = useLocation();
  const closeSidebar = () => {
    if (window.screen.width <= 600) {
      if (ref.current.classList.contains("sb-active")) {
        ref.current.classList.remove("sb-active");
      }
    }
  };
  return (
    <div>
      <div className="layout-container">
        <SideBar ref={ref} sidebarMenu={props?.sidebarMenu} />
        <div
          className={
            Location.pathname.includes("/app/instructions/forms")
              ? "instruction_form_list"
              : includesStringFromArray(
                  Location.pathname,
                  sideBarWithIconAndName
                )
              ? "content_section_instruction_dashboard"
              : "content_section"
          }
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

export const WithNavBar = (props) => {
  return (
    <>
      <div>
        <NavBar />
        <div className="w-100" id="content">
          {props.children}
        </div>
      </div>
    </>
  );
};

export const WithBottomTab = (props) => {
  return (
    <>
      <div className="layout-container-bottom-tab">
        <AppBar />

        <div className="w-100" id="content">
          {props.children}
        </div>
        <BottomTab />
      </div>
    </>
  );
};
