import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  addUpdateDocumentTypeData,
  getDocumentTypeById,
} from "../../../thunks/documents";
import { useDispatch, useSelector } from "react-redux";
import { showSnackbar } from "../../../slice/snackbarSlice";
import Loader from "../../../components/Loader";
import AlertComponent from "../../../components/alert";

const UpdateDocType = ({
  selectedData,
  subTypes,
  getDocumentTypesData,
  setOpenDocType,
}) => {
  const dispatch = useDispatch();
  const [documentType, setDocumentType] = useState(null);
  const [isActive, setIsActive] = useState(true);
  const [selectedSubType, setSelectedSubType] = useState([]);
  const [isLoader, setLoader] = useState(false);
  const [isApplicable, setIsApplicable] = useState("");
  const [isUpdateDocumentTypeDialog, setUpdateDocumentTypeDialog] =
    useState(false);
  const lookups = useSelector((state) => state?.lookupData);
  let { "Template Type": templateType } = lookups || {};
  const handleChangeSubTypes = (value) => {
    if (value) {
      setSelectedSubType(value);
    } else {
      selectedSubType([]);
    }
  };
  //Method to show is applicable
  const getApplicable = (lookupViews) => {
    const isClient = lookupViews?.some((view) => view?.viewOn === "Client");
    const isCase = lookupViews?.some((view) => view?.viewOn === "Case");

    if (isClient && isCase) {
      setIsApplicable("Both");
    } else if (isClient) {
      setIsApplicable("Client");
    } else if (isCase) {
      setIsApplicable("Case");
    }
  };
  // Method to get document type details by passing ID
  const getDocumentTypeDetails = async () => {
    try {
      setLoader(true);
      const response = await dispatch(
        getDocumentTypeById(selectedData?.id)
      ).unwrap();
      if (response) {
        getApplicable(response?.LookupView || []); //Method to show isApplicable
        setDocumentType(response?.name);
        setSelectedSubType(response?.SubLookup || []);
        setIsActive(response?.isActive);
        setLoader(false);
      }
    } catch (error) {
      return error;
    }
  };

  const handleOpenUpdateDocumentTypeDialog = () => {
    setUpdateDocumentTypeDialog(true);
  };

  //UPDATE API CALL
  const handleClickUpdate = async () => {
    setUpdateDocumentTypeDialog(false);
    const tempSubType = [];
    if (selectedSubType?.length > 0) {
      selectedSubType?.map((d) => {
        tempSubType.push({
          id: d?.id,
          name: d?.name,
        });
      });
    }
    const payload = {
      id: selectedData?.id,
      name: documentType,
      subLookups: tempSubType || [],
      isActive: isActive,
      isUpdate: selectedData ? true : false,
    };
    try {
      setLoader(true);
      const response = await dispatch(
        addUpdateDocumentTypeData(payload)
      ).unwrap();
      if (response) {
        setLoader(false);
        setOpenDocType(null);
        getDocumentTypesData();
        dispatch(
          showSnackbar({
            message: "Document type updated successfully",
            severity: "success",
          })
        );
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      dispatch(
        showSnackbar({
          message: `${error?.message}`,
          severity: "error",
        })
      );
    }
  };
  useEffect(() => {
    if (selectedData) {
      getDocumentTypeDetails();
    } else {
      setDocumentType(null);
      setSelectedSubType([]);
    }
  }, [selectedData]);
  return (
    <Box>
      {isLoader && <Loader />}

      {/* Confirmation model to update Doc Type */}
      {isUpdateDocumentTypeDialog && (
        <AlertComponent
          open={isUpdateDocumentTypeDialog}
          handleSuccess={handleClickUpdate}
          handleClose={() => setUpdateDocumentTypeDialog(false)}
          message={`Are you sure, want to update document type?`}
        />
      )}
      <Grid container spacing={3} paddingTop={2} paddingBottom={2}>
        {/* Task Name Field Section */}
        <Grid item xs={12}>
          <TextField
            className="inputTextField normal_normal_600_12_Manropee"
            label="Document Type"
            placeholder="Document Type"
            fullWidth
            required
            size="small"
            value={documentType || null}
            onChange={(e) => setDocumentType(e?.target?.value)}
            InputLabelProps={{ shrink: true }}
            variant="standard"
          />
        </Grid>

        {/* Add/Update subTypes */}
        <Grid item xs={12}>
          <Autocomplete
            multiple
            value={selectedSubType || []}
            id="tags-outlined"
            options={subTypes || []}
            getOptionLabel={(option) => option?.name}
            onChange={(event, value) => handleChangeSubTypes(value)}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                className="normal_normal_600_12_Manropee"
                size="small"
                label={
                  <Typography sx={{ color: "#01ce3f" }}>Sub Types</Typography>
                }
                placeholder="Select Sub Types"
                // InputProps={{
                //   ...params.InputProps,
                //   style: {
                //     maxHeight: "700px",
                //     display: "flex",
                //     flexDirection: "column",
                //     flexWrap: "wrap",
                //     overflowY: "auto",
                //     marginTop: 10,
                //     alignItems: "flex-start",
                //   },
                // }}
              />
            )}
          />
        </Grid>

        {/* Is Active section */}
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  color: "#00CE3F",
                  "&.Mui-checked": {
                    color: "#00CE3F",
                  },
                }}
                checked={isActive || false}
                onChange={(e) => setIsActive(e?.target?.checked)}
              />
            }
            label="Is Active?"
          />
        </Grid>
        {/* Applicable to both/not */}
        <Grid item xs={6}>
          <Tooltip title="Can't edit">
            <FormControl fullWidth>
              <InputLabel
                shrink={true}
                // className="normal_normal_600_12_Manropee"
                className="normal_normal_600_18_Manrope dark_grey"
                style={{
                  lineHeight: "2.0375",
                  marginLeft: "-12px",
                  color: "#01ce3f",
                }}
                required
              >
                Applicable to
              </InputLabel>
              <Select
                className="formControlField"
                variant="standard"
                size="small"
                name="templatetype"
                value={isApplicable || ""}
                // onChange={(event) => SetIsApplicable(event?.target?.value)}
                fullWidth
                disabled
              >
                {templateType?.map((d) => (
                  <MenuItem
                    className="normal_normal_18_Manrope dark_grey"
                    value={d?.value}
                    key={d?.id}
                  >
                    {d?.label}
                  </MenuItem>
                ))}
                <MenuItem
                  className="normal_normal_18_Manrope dark_grey"
                  value={"Both"}
                >
                  Applicable to both
                </MenuItem>
              </Select>
              {/* {isError && !isApplicable && (
              <FormHelperText className="red">
                {VALIDATION_MSG.REQUIRED}
              </FormHelperText>
            )} */}
            </FormControl>
          </Tooltip>
        </Grid>
        {/* Submit Button */}
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            className="save_button"
            onClick={handleOpenUpdateDocumentTypeDialog}
          >
            Update
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UpdateDocType;
