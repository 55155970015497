import { Grid, Typography } from "@mui/material";
import { useFieldArray } from "react-hook-form";
import { AddButton, RemoveButton } from "../../../../../components/buttons";
import { TextInput } from "../../../../../components/inputs";

const SpecialBenefitInterest = ({ arr, isJoint, trustIndex }) => {
  const trustees = useFieldArray({
    name: `InstructionTrustQuestionnaire.[${trustIndex}].beneficialInterest`,
  });

  const { append, remove, fields } = trustees;

  const handleAdd = () => {
    append();
  };

  const handleRemove = (ind) => {
    remove(ind);
  };

  return (
    <>
      <Grid
        width={"100%"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Grid
          width={"100%"}
          paddingY={4}
          display={"flex"}
          flexDirection={"column"}
          borderTop={`1px solid #DCDCE1`}
          borderBottom={`1px solid #DCDCE1`}
          gap={0}
        >
          <Typography className="normal_normal_semibold_20_Manrope dark_grey">
            Special Beneficial Interest
          </Typography>
          <Typography className="normal_normal_600_14_Manrope green">
            Trustees to use their discretion and delay distribution of an asset
            so that a beneficiary might enjoy it during their lifetime
          </Typography>
        </Grid>
        <Grid
          width={"65%"}
          paddingY={4}
          display={"flex"}
          flexDirection={"column"}
          gap={3}
          // border={"1px solid red"}
        >
          {fields.map((card, ind) => {
            return (
              <SpecialBenefitInterestForm
                key={card.id}
                cardNo={ind}
                handleRemove={() => handleRemove(ind)}
                pathName={`InstructionTrustQuestionnaire.[${trustIndex}].beneficialInterest[${ind}]`}
              />
            );
          })}
          <Grid textAlign={"center"}>
            <AddButton onClick={handleAdd} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SpecialBenefitInterest;

const SpecialBenefitInterestForm = ({ handleRemove, pathName, cardNo }) => {
  return (
    <Grid
      boxShadow={"1px 2px 6px #00000029"}
      borderRadius={"16px"}
      padding={2}
      paddingY={3}
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      position={"relative"}
    >
      <Grid
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Grid
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          gap={1}
        >
          <Grid
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            gap={2}
          >
            <Grid width={"100%"} display={"flex"} flexDirection={"row"} gap={1}>
              <TextInput
                name={`${pathName}.beneficiaryName`}
                label={`Beneficiary ${cardNo + 1}`}
                placeholder="Enter name"
              />
            </Grid>
            <Grid
              width={"100%"}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <Grid width={"48%"}>
                <TextInput
                  name={`${pathName}.assetValue`}
                  label="Asset or Monetary Value"
                  placeholder=""
                />
              </Grid>
              <Grid width={"48%"}>
                <TextInput
                  name={`${pathName}.assetName`}
                  label="Mention the Asset"
                  placeholder="Enter asset detail"
                />
              </Grid>
            </Grid>
            <Grid width={"100%"} display={"flex"} flexDirection={"row"} gap={1}>
              <TextInput
                name={`${pathName}.until`}
                label={`Until`}
                placeholder="Enter text"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ textAlign: "right" }}>
        <RemoveButton label={"Joint"} onClick={handleRemove} />
      </div>
    </Grid>
  );
};
