import { Grid } from "@mui/material";
import { useMemo } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { AddButton, RemoveButton } from "../../../../../components/buttons";
import {
  DateInput,
  DropdownInput,
  RadioInput,
  TextInput,
} from "../../../../../components/inputs";
import { findValues, mapLabelValue } from "../../../../../utils/util";
import { trustQuestionnaire_assetsBeingTransferredMethod } from "../../../../../utils/constant";

const GuidanceAssetsBeingTransferred = ({ arr, isJoint, trustIndex }) => {
  const assetDetails = useFieldArray({
    name: `InstructionTrustQuestionnaire.[${trustIndex}].assetBeingTransferred.assetDetails`,
  });
  let { Assets: assetTypes } = useSelector((state) => state?.lookupData);
  assetTypes = useMemo(() => mapLabelValue(assetTypes), [assetTypes]);

  const { append, remove, fields } = assetDetails;

  const { getValues, watch } = useFormContext();
  const assetsData = useMemo(() => {
    let formstate = getValues();
    let includeKeys = [
      "InstructionClients.InstructionFactFind.gifts.to",
      "InstructionClients.InstructionFactFind.properties.address.street1",
      "InstructionClients.InstructionFactFind.properties.address.street",
      "InstructionClients.InstructionFactFind.sharesAndInvestments.description",
      "InstructionClients.InstructionFactFind.pension.provider",
      "InstructionClients.InstructionFactFind.assets.description",
    ];
    let list = findValues(formstate, includeKeys);
    list = list?.map((item) => ({
      value: item,
      label: item,
    }));
    return list;
  }, []);

  const handleAdd = () => {
    append();
  };

  const handleRemove = (ind) => {
    remove(ind);
  };

  return (
    <>
      <Grid
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"center"}
        borderTop={`1px solid #DCDCE1`}
        borderBottom={`1px solid #DCDCE1`}
      >
        <Grid
          width={"65%"}
          paddingY={4}
          display={"flex"}
          flexDirection={"column"}
          gap={3}
        >
          {fields.map((card, ind) => {
            return (
              <GuidanceAssetsBeingTransferredForm
                key={card.id}
                cardNo={ind}
                assetsData={assetsData}
                handleRemove={() => handleRemove(ind)}
                pathName={`InstructionTrustQuestionnaire.[${trustIndex}].assetBeingTransferred.assetDetails.[${ind}]`}
              />
            );
          })}
          <Grid textAlign={"center"}>
            <AddButton onClick={handleAdd} />
          </Grid>
          <Grid
            display={"flex"}
            flexDirection={"column"}
            gap={2}
            mt={2}
            width={"100%"}
          >
            <Grid
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              <Grid width={"48%"}>
                <span
                  style={{
                    textAlign: "left",
                  }}
                  className="normal_normal_12_Manrope green"
                >
                  Particulars
                </span>
              </Grid>

              <Grid width={"46%"}>
                <span
                  style={{
                    fontSize: "12px",
                    color: "#00CE3F",
                    textAlign: "left",
                  }}
                >
                  Value
                </span>
              </Grid>
            </Grid>
            <Grid
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <Grid
                width={"48%"}
                className="normal_normal_16_Manrope dark_grey"
              >
                Total Value of Assets to be placed in Trust
              </Grid>

              <Grid width={"46%"}>
                <TextInput
                  label={``}
                  name={`InstructionTrustQuestionnaire.[${trustIndex}].assetBeingTransferred.totalAssetValue.netVal`}
                  placeholder="Enter value"
                />
              </Grid>
            </Grid>
            <Grid
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <Grid
                width={"48%"}
                className="normal_normal_16_Manrope dark_grey"
              >
                Above / Below Nil Rate Band
              </Grid>

              <Grid width={"46%"}>
                <TextInput
                  label={``}
                  name={`InstructionTrustQuestionnaire.[${trustIndex}].assetBeingTransferred.nilRateBand.netVal`}
                  placeholder="Enter value"
                />
              </Grid>
            </Grid>
            {/* <Grid
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <Grid
                width={"48%"}
                className="normal_normal_16_Manrope dark_grey"
              >
                Entry Tax payable
              </Grid>
              <Grid width={"23%"}></Grid>
              <Grid width={"23%"}>
                <TextInput
                  label={``}
                  name={`InstructionTrustQuestionnaire.[${trustIndex}].assetBeingTransferred.totalAssetValue.netVal`}
                  placeholder="Enter value"
                />
              </Grid>
            </Grid> */}

            {/* <Grid
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <Grid
                width={"48%"}
                className="normal_normal_16_Manrope dark_grey"
              >
                Portion liable to periodic charges
              </Grid>
              <Grid width={"23%"}>
                <TextInput
                  label={``}
                  name={`InstructionTrustQuestionnaire.[${trustIndex}].assetBeingTransferred.totalAssetValue.grossVal`}
                  placeholder="Enter value"
                />
              </Grid>
              <Grid width={"23%"}>
                <TextInput
                  label={``}
                  name={`InstructionTrustQuestionnaire.[${trustIndex}].assetBeingTransferred.totalAssetValue.netVal`}
                  placeholder="Enter value"
                />
              </Grid>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default GuidanceAssetsBeingTransferred;

const GuidanceAssetsBeingTransferredForm = ({
  handleRemove,
  pathName,
  cardNo,
  assetsData,
}) => {
  let [method, hasMortgageOrEquityRelease, awareOfOverridingInterest] =
    useWatch({
      name: [
        `${pathName}.method`,
        `${pathName}.mortgageRelease.hasMortgageOrEquityRelease`,
        `${pathName}.overridingInterest.awareOfOverridingInterest`,
      ],
    });
  const isAssetTransferred = method === "Full Transfer - TR1";
  return (
    <Grid
      boxShadow={"1px 2px 6px #00000029"}
      borderBottom={`1px solid #DCDCE1`}
      borderRadius={"16px"}
      padding={2}
      paddingY={3}
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      position={"relative"}
    >
      <Grid
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Grid width={"48%"}>
          <DropdownInput
            name={`${pathName}.assetName`}
            label="Asset Name"
            options={assetsData}
          />
        </Grid>

        <Grid width={"48%"}>
          <DropdownInput
            name={`${pathName}.assetType`}
            label="Type of Asset"
            options={[
              {
                label: "Main Residence",
                value: "Main Residence",
              },
              {
                label: "Other Property",
                value: "Other Property",
              },
              {
                label: "Cash Of Item",
                value: "Cash Of Item",
              },
              {
                label: "Item Of Value",
                value: "Item Of Value",
              },
            ]} // todo add in api lookup
          />
        </Grid>
      </Grid>
      <Grid
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Grid width={"48%"}>
          <TextInput
            label={`Asset of`}
            name={`${pathName}.assetOf`}
            placeholder="Enter name"
          />
        </Grid>

        <Grid width={"48%"}>
          <RadioInput
            name={`${pathName}.maintainInterest`}
            label="Maintain Interest"
            options={[
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
          />
        </Grid>
      </Grid>
      <Grid
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Grid width={"48%"}>
          <DropdownInput
            label={`Method`}
            name={`${pathName}.method`}
            options={trustQuestionnaire_assetsBeingTransferredMethod}
          />
        </Grid>

        <Grid
          width={"48%"}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <Grid width={"48%"}>
            <TextInput
              label={`Gross`}
              name={`${pathName}.grossVal`}
              placeholder="Enter value"
            />
          </Grid>
          <Grid width={"48%"}>
            <TextInput
              label={`Net`}
              name={`${pathName}.netVal`}
              placeholder="Enter value"
            />
          </Grid>
        </Grid>
      </Grid>

      {/* show below fields only when a property added in fact find is selected in asset name field */}
      {isAssetTransferred ? (
        <>
          <Grid width={"100%"}>
            <RadioInput
              label={`Are you sure this has no mortgage/equity release?`}
              name={`${pathName}.mortgageRelease.hasMortgageOrEquityRelease`}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
            />
          </Grid>
          {hasMortgageOrEquityRelease ? (
            <Grid
              width={"100%"}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <DateInput
                label={`When is mortgage due to be cleared?`}
                name={`${pathName}.mortgageRelease.mortgageOrEquityReleaseDate`}
                placeholder="Enter value"
                dateInput
              />
            </Grid>
          ) : null}

          <Grid width={"100%"}>
            <RadioInput
              label={`Are you aware of any overriding interest on the property, e.g. public footpaths, drainage rights or rights of way?`}
              name={`${pathName}.overridingInterest.awareOfOverridingInterest`}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
            />
          </Grid>
          {awareOfOverridingInterest ? (
            <Grid
              width={"100%"}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <TextInput
                label={`Mention overriding interest`}
                name={`${pathName}.overridingInterest.values`}
                placeholder="Enter value"
                multiline
              />
            </Grid>
          ) : null}
        </>
      ) : null}
      <div style={{ textAlign: "right" }}>
        <RemoveButton onClick={handleRemove} />
      </div>
    </Grid>
  );
};
