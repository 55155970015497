import { createSlice } from "@reduxjs/toolkit";
import {} from "../thunks/instruction";
import { getTaskTriggers } from "../thunks/task";

const taskSlice = createSlice({
  name: "taskData",
  initialState: {
    loading: false,
    taskData: [],
    TaskTypeData: {},
  },
  reducers: {
    // Action to add new row in verify task table
    addNewRow: (state, action) => {
      const tempData = JSON.parse(JSON.stringify(state?.taskData));
      const indexToAdd = action?.payload; // Extract the index from action payload
      const newData = [...tempData];
      const newRow = {
        name: "",
        sla: "",
        slaUnitName: "",
        isGeneric: "",
        actor: "",
        milestoneId: null,
        predecessorTaskIds: [],
        sucessorTaskIds: [],
        taskTriggerTypeId: null,
      };
      // Check if index is within valid range
      if (indexToAdd >= 0 && indexToAdd <= newData?.length) {
        newData.splice(indexToAdd, 0, newRow);
      }
      state.taskData = newData;
    },
    // Action to remove row data from verify task table
    removeRowData: (state, action) => {
      const tempData = JSON.parse(JSON.stringify(state?.taskData));
      const newData = [...tempData];
      const indexToRemove = action.payload; // Assuming the index to remove is passed in action.payload
      if (indexToRemove >= 0 && indexToRemove < newData?.length) {
        newData.splice(indexToRemove, 1);
      }
      // newData.pop();
      state.taskData = newData;
    },
    // Action to change sequence of task
    sequenceChange: (state, action) => {
      const { direction, index } = action?.payload;
      const tempData = JSON.parse(JSON.stringify(state?.taskData));
      const newData = [...tempData];
      if (direction === "up") {
        if (index === 0) return;
        newData[index - 1] = tempData[index];
        newData[index] = tempData[index - 1];
      } else {
        if (index === newData?.length - 1) return;
        newData[index + 1] = tempData[index];
        newData[index] = tempData[index + 1];
      }
      state.taskData = newData;
    },
    // Action to change task name
    changeTaskName: (state, action) => {
      const { newValue, index } = action?.payload;
      const tempData = JSON.parse(JSON.stringify(state?.taskData));
      const newDataList = [...tempData];
      const tempTaskData = {
        ...tempData[index],
        ["Task"]: newValue,
      };
      newDataList[index] = tempTaskData;
      state.taskData = newDataList;
    },
    // Action to change input
    inputChange: (state, action) => {
      const { e, index, name, getMilestoneName } = action?.payload;
      const tempData = JSON.parse(JSON.stringify(state?.taskData));
      const newDataList = [...tempData];
      let tempTask;
      if (name === "milestoneId") {
        const tempTaskData = {
          ...tempData[index],
          ["milestoneId"]: e,
          ["milestoneName"]: getMilestoneName?.name,
        };
        newDataList[index] = tempTaskData;
        state.taskData = newDataList;
      } else {
        const tempTaskData = {
          ...tempData[index],
          [name]: e,
        };
        newDataList[index] = tempTaskData;
        state.taskData = newDataList;
      }
    },
    // Action to change preSuccessor
    preSuccChange: (state, action) => {
      const { value, index, name } = action?.payload;
      const tempData = JSON.parse(JSON.stringify(state?.taskData));
      const newDataList = [...tempData];
      const tempTaskData = {
        ...tempData[index],
        [name]: value,
      };
      newDataList[index] = tempTaskData;
      state.taskData = newDataList;
    },
    clearTask: (state, action) => {
      state.taskData = [];
    },
    //METHOD TO SET TASK DATA IN REDUX STATE
    setTaskData: (state, action) => {
      state.TaskTypeData = action?.payload;
    },
    //METHOD TO CLEAR TASK DATA IN REDUX STATE
    ClearTaskData: (state, action) => {
      state.TaskTypeData = {};
    },
  },
  extraReducers: (builder) => {
    builder

      // Get Task Triggers thunk
      .addCase(getTaskTriggers.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(getTaskTriggers.fulfilled, (state, action) => {
        const tempTaskTriggers = [];
        state.loading = false;
        action?.payload?.map((d) => {
          tempTaskTriggers.push({
            Task: {
              name: d?.Task?.name || "",
              id: d?.Task?.id || null,
              sla: d?.Task?.sla || "",
              slaUnitName: d?.Task?.slaUnitName || "",
              isGeneric: d?.Task?.isGeneric || "",
              actor: d?.Task?.actor || "",
            },
            milestoneId: d?.milestoneId || "",
            milestoneName: d?.milestoneName,
            taskTriggerTypeId: d?.taskTriggerTypeId || "",
            predecessorTaskIds: [],
            sucessorTaskIds: [],
          });
        });
        state.taskData = tempTaskTriggers;
      })

      .addCase(getTaskTriggers.rejected, (state, action) => {
        state.loading = false;
        state.taskData = [];
      });
  },
});
export const {
  addNewRow,
  removeRowData,
  sequenceChange,
  changeTaskName,
  inputChange,
  preSuccChange,
  clearTask,
  setTaskData,
  ClearTaskData,
} = taskSlice.actions;
export default taskSlice.reducer;
