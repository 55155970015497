import { createAsyncThunk } from "@reduxjs/toolkit";
import getAxios from "../helpers/axiosInterceptor";
import { API_ROUTES_PATHS } from "../utils/constant";

/* Method to get Instruction meeting stats for Super Admin + Office Admin for
Dashboard screen */
export const getInstructionMeetingStats = createAsyncThunk(
  "client/getInstructionMeetingStats",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(
        `${API_ROUTES_PATHS.GET_MEETING_INSTRUCTION_STATS}`,
        model
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

/* Method to get Instruction detail report for Super Admin + Office Admin for
Dashboard screen */
export const getDetailReportStats = createAsyncThunk(
  "client/getDetailReportStats",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(
        `${API_ROUTES_PATHS.GET_DETAIL_REPORT}`,
        model
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

/* Method to get things to do list for all roles dashboard screen */
export const getThingsToDo = createAsyncThunk(
  "client/getThingsToDo",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(
        `${API_ROUTES_PATHS.GET_THINGS_TO_DO}`,
        model
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

/* Method to get stats report for introducer dashboard */
export const getStatsReport = createAsyncThunk(
  "client/getStatsReport",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(
        `${API_ROUTES_PATHS.GET_INTRODUCER_STATS}`,
        model
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

/* Method to get report section date range lookup */
export const getDateRangeFilterLookup = createAsyncThunk(
  "client/getDateRangeFilterLookup",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(`${API_ROUTES_PATHS.FILTER}`, model);

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

/* Method to get current week's instruction on dashboard screen */
export const getCurrentWeekInstruction = createAsyncThunk(
  "client/getCurrentWeekInstruction",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(
        `${API_ROUTES_PATHS.GET_CURRENT_WEEK_INSTRUCTION}`,
        model
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

/* Method to get things to pick list for all roles dashboard screen */
export const getThingsToPick = createAsyncThunk(
  "client/getThingsToPick",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(
        `${API_ROUTES_PATHS.GET_THINGS_TO_PICK}`,
        model
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);
