import { createSlice } from "@reduxjs/toolkit";
import { getCommission } from "../thunks/commission";

const commissionSlice = createSlice({
  name: "commission",
  initialState: {
    loading: false,
    commissionData: [],
    totalCount: 0,
  },

  extraReducers: (builder) => {
    builder

      // Get Commission thunk
      .addCase(getCommission.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(getCommission.fulfilled, (state, action) => {
        state.loading = false;
        state.commissionData = action?.payload?.findAllInstructions;
        state.totalCount = action?.payload?.recordLength;
      })

      .addCase(getCommission.rejected, (state, action) => {
        state.loading = false;
        state.commissionData = [];
      });
  },
});

export default commissionSlice.reducer;
