import LocationOnIcon from "@mui/icons-material/LocationOn";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as React from "react";
// import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";
import { Controller, useFormContext } from "react-hook-form";

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export default function AddressSearchInput(props) {
  const { name, label, placeholder, defaultOptions } = props;
  const { control, watch } = useFormContext();
  let value = watch(name);

  const [inputValue, setInputValue] = React.useState("");

  const initialOptions = defaultOptions?.length
    ? defaultOptions?.map((obj) => ({
        ...obj,
        description: obj?.value,
      }))
    : [];
  const [options, setOptions] = React.useState(initialOptions);

  const loaded = React.useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const getPlaceList = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  React.useEffect(() => {
    let active = true;
    if (!autocompleteService.current && window?.google?.maps?.places) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    getPlaceList({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [...initialOptions];
        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });
    return () => {
      active = false;
    };
  }, [value, inputValue, getPlaceList]);

  const getPlaceDetails = (data) => {
    const placeId = data?.place_id;
    if (placeId && window?.google?.maps?.places) {
      const service = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );

      let locationInfo = {};
      service.getDetails({ placeId }, (data, status) => {
        const address = data?.address_components;
        for (var i = 0; i < address.length; i++) {
          var component = address[i]?.types?.[0];
          switch (component) {
            case "country":
              locationInfo.country = address[i]["long_name"];
              break;
            case "administrative_area_level_1":
              locationInfo.state = address[i]["long_name"];
              break;
            case "locality":
              locationInfo.city = address[i]["long_name"];
              break;
            case "postal_code":
              locationInfo.zip = address[i]["long_name"];
              break;
            case "route":
              locationInfo.street1 = address[i]["long_name"];
              break;
            case "street_number":
              locationInfo.street2 = address[i]["long_name"];
              break;
            default:
              break;
          }
        }
        props.handleSelectedAddress(locationInfo);
      });
    } else {
      props.handleSelectedAddress(data);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      render={({ field }) => (
        <Autocomplete
          className="textField_outlined_border"
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option?.description
          }
          id="google-search"
          filterOptions={(x) => x}
          options={options}
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={field?.value}
          noOptionsText="No option to select"
          onChange={(event, newValue) => {
            setOptions(newValue ? [newValue, ...options] : options);
            field?.onChange(newValue?.description);
            getPlaceDetails(newValue);
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={placeholder || "Select Value"}
              label={label || "Select Value"}
              fullWidth
              multiline
              // rows={2}
              className="inputTextField border_color"
              variant="standard"
            />
          )}
          renderOption={(renderProps, option) => {
            return (
              <li {...renderProps}>
                <Grid container alignItems="center">
                  <Grid item sx={{ display: "flex", width: 44 }}>
                    <LocationOnIcon sx={{ color: "text.secondary" }} />
                  </Grid>
                  <Grid
                    item
                    sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
                  >
                    <Typography variant="body2" color="text.secondary">
                      {option?.description}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            );
          }}
        />
      )}
    />
  );
}
