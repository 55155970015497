import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import { useFieldArray, useFormContext } from "react-hook-form";
import { RadioInput, TextInput } from "../../../../../components/inputs";
import { VALIDATION_MSG } from "../../../../../utils/constant";
import { partyObject } from "../DecOfTrustQuestionnaire/decOfTrustConstant";
import GuidanceAssetsBeingTransferred from "./AssetsBeingTransferred";
import GuidanceToTrustees from "./GuidanceToTrustees";
import Trustees from "./Trustees";

const TrustItem = ({ arr, isJoint, trustIndex }) => {
  const { watch, control } = useFormContext();

  const trustInterests = useFieldArray({
    control,
    name: `InstructionTrustQuestionnaire.[${trustIndex}].trustName.trustInterest`,
  });

  const tempArray = [
    {
      id: 1,
      isExpanded: false,
      title: "Trustees",
      titleBigBlack: "",
      titleSmallBlack: "",
      title2Big: "",
      title3Small: "",
      component: (
        <Trustees arr={arr} isJoint={isJoint} trustIndex={trustIndex} />
      ),
    },
    {
      id: 2,
      isExpanded: false,
      title: "Guidance to Trustees",
      titleBigBlack: "",
      titleSmallBlack:
        "Guidance is not binding on the Trustees but it is incumbent on them to take a Settlor’s wishes into account. A Trustee must always act in the best interest of the beneficiaries.",
      title2Big: "",
      title3Small: "",
      component: (
        <GuidanceToTrustees
          arr={arr}
          isJoint={isJoint}
          trustIndex={trustIndex}
        />
      ),
    },
    {
      id: 3,
      isExpanded: false,
      title: "Assets being transferred",
      titleBigBlack: "",
      titleSmallBlack: "",
      title2Big: "",
      title3Small: "",
      component: (
        <GuidanceAssetsBeingTransferred
          arr={arr}
          isJoint={isJoint}
          trustIndex={trustIndex}
        />
      ),
    },
  ];

  const { append, remove, fields } = trustInterests;

  const handleAdd = () => {
    append(partyObject);
  };

  const handleRemove = (ind) => {
    remove(ind);
  };

  return (
    <>
      <Grid
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"center"}
        borderTop={`1px solid #DCDCE1`}
        borderBottom={`1px solid #DCDCE1`}
      >
        <Grid
          width={"100%"}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <Grid
            width={"65%"}
            paddingY={4}
            display={"flex"}
            flexDirection={"column"}
            alignContent={"center"}
            gap={4}
          >
            <TextInput
              name={`InstructionTrustQuestionnaire.[${trustIndex}].trustName.name`}
              label="Trust Name"
              placeholder="Enter name"
            />
            <Grid
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              {arr?.map((item, ind) => {
                let isProtectorAdded =
                  watch(
                    `InstructionTrustQuestionnaire.[${trustIndex}].trustName.trustInterest.[${ind}].addProtector`
                  ) ?? false;
                let isSettlorClient = watch(
                  `InstructionTrustQuestionnaire.[${trustIndex}].trustName.trustInterest.[${ind}].isSettlor`
                );
                return (
                  <Grid width={"48%"}>
                    <Grid display={"flex"} flexDirection={"column"} gap={2}>
                      <RadioInput
                        label={`Is Client ${ind + 1} going to be a Settlor?`}
                        name={`InstructionTrustQuestionnaire.[${trustIndex}].trustName.trustInterest.[${ind}].isSettlor`}
                        options={[
                          { label: "Yes", value: true },
                          { label: "No", value: false },
                        ]}
                      />
                      {!isSettlorClient && (
                        <Grid w={"100%"}>
                          <TextInput
                            label={`Name of the Settlor ${ind + 1}`}
                            placeholder={`Enter Settlor ${ind + 1} Name`}
                            // name={`trustDataObject.${trustIndex}.trustName.trustInterest.${ind}.settlorDetails.fullName`}
                            name={`InstructionTrustQuestionnaire.[${trustIndex}].trustName.trustInterest.[${ind}].settlorDetails.fullName`}
                            rules={{
                              required: {
                                value: true,
                                message: VALIDATION_MSG?.REQUIRED,
                              },
                            }}
                          />
                        </Grid>
                      )}
                      <RadioInput
                        label={`Do you Client ${
                          ind + 1
                        } wants to be a Trustee?`}
                        name={`InstructionTrustQuestionnaire.[${trustIndex}].trustName.trustInterest.[${ind}].isTrustee`}
                        options={[
                          { label: "Yes", value: true },
                          { label: "No", value: false },
                        ]}
                      />
                      <RadioInput
                        label={`Is Client ${
                          ind + 1
                        } going to retain a life interest?`}
                        name={`InstructionTrustQuestionnaire.[${trustIndex}].trustName.trustInterest.[${ind}].isInterested`}
                        options={[
                          { label: "Yes", value: true },
                          { label: "No", value: false },
                        ]}
                      />
                      <RadioInput
                        label={`Would you like to add a protector? (If applicable)`}
                        name={`InstructionTrustQuestionnaire.[${trustIndex}].trustName.trustInterest.[${ind}].addProtector`}
                        options={[
                          { label: "Yes", value: true },
                          { label: "No", value: false },
                        ]}
                      />
                      {isProtectorAdded && (
                        <Grid w={"100%"}>
                          <TextInput
                            label={`Name of the Protector`}
                            placeholder={`Enter Protector Name`}
                            name={`InstructionTrustQuestionnaire.[${trustIndex}].trustName.trustInterest.[${ind}].protectorDetails.fullName`}
                            rules={{
                              required: {
                                value: true,
                                message: VALIDATION_MSG?.REQUIRED,
                              },
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            <Grid
              width={"100%"}
              display={"flex"}
              gap={2}
              padding={2}
              fontSize={"12px"}
              borderRadius={3}
              sx={{
                backgroundColor: "#E5FAEB",
                color: "#00CE3F",
              }}
            >
              <Grid>
                <InfoOutlinedIcon />
              </Grid>

              <Grid display={"flex"} flexDirection={"column"} gap={1}>
                <Typography className="normal_normal_600_12_Manrope">
                  If you{" "}
                  <span className="normal_normal_600_12_Manrope">
                    {" "}
                    retain an interest
                  </span>{" "}
                  in assets placed into Trust, you retain the right to enjoy
                  those assets and receive income. Your rights to those assets
                  remain paramount and supersede those of the other
                  beneficiaries. Assets will still form part of your estate for
                  inheritance tax purposes.
                </Typography>
                <Typography className="normal_normal_600_12_Manrope">
                  {" "}
                  If you{" "}
                  <span className="normal_normal_600_12_Manrope">
                    do not retain an interest
                  </span>{" "}
                  in the Trust, assets will eventually come out of your estate
                  for inheritance tax purposes but, those assets can only be
                  utilized for the benefit of the named beneficiaries.
                </Typography>
                <Typography className="normal_normal_600_12_Manrope">
                  Also, protector of the trust cannot be a trustee.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/*  */}
          <Grid width={"100%"} mb={4} style={{ marginTop: "0rem" }}>
            {tempArray.map((d) => {
              return (
                <Accordion
                  style={{
                    borderBottom: "1px solid #DCDCE1",
                    width: "100%",
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <AccordionSummary
                    style={{
                      width: "100%",
                      padding: "10px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid
                      width={"100%"}
                      display={"flex"}
                      flexDirection={"column"}
                      gap={1}
                    >
                      <Grid
                        width={"100%"}
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        gap={2}
                      >
                        <span
                          className="normal_normal_semibold_20_Manrope"
                          style={{
                            width: "250px",
                          }}
                        >
                          {d.title}
                        </span>
                        <span>
                          {d.titleSmallBlack ? (
                            <span className="normal_normal_600_14_Manrope dark_grey">
                              {d.titleSmallBlack}
                            </span>
                          ) : null}
                          {d.titleBigBlack ? (
                            <span
                              className="normal_normal_600_14_Manrope dark_grey"
                              style={{
                                width: "100%",
                              }}
                            >
                              {d.titleBigBlack}
                            </span>
                          ) : null}
                        </span>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <>{d?.component}</>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default TrustItem;
