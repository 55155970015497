import { createSlice } from "@reduxjs/toolkit";
import {
  getAllInstructions,
  getCustomTasks,
  getGenericInstructionTasks,
  getIndividualTasks,
  getInstructionById,
  getInstructionFormDataById,
  getInstructionProducts,
  getInstructionProductsTaskById,
  getInstructionType,
  getMailListThunk,
  updateInstruction,
  updateInstructionProp,
  updatewillSuiteThunk,
} from "../thunks/instruction";
import { deepClone } from "../utils/util";

const instructionSlice = createSlice({
  name: "instructionData",
  initialState: {
    loading: false,
    totalCount: 0,
    instructionTypeData: [],
    instructionsData: [],
    tempInstructionData: null,
    instructionDashboardData: null,
    isJoint: false,
    arr: [],
    instructionProductList: [],
    instructionProductListLoader: false,
    instructionTasksData: [],
    instructionTaskLoader: false,
    qcQuestionsList: [],
    emailList: [],
    isQcQuestionLoader: false,
    individualTaskLoader: false,
    individualTaskData: [],
    customTaskLoader: false,
    customTaskData: [],
  },
  reducers: {
    updateTempInstructionData: (state, action) => {
      let { prop, data } = action?.payload;
      if (state.tempInstructionData?.hasOwnProperty(prop)) {
        let clonedData = deepClone(data); // to handle crash , causes due to directly assign
        state.tempInstructionData[prop] = clonedData;
      }
    },

    updateTaskCompleteAndPick: (state, action) => {
      const tempInstructionData = JSON.parse(
        JSON.stringify(state?.instructionTasksData)
      );
      const updatedTasksArray = tempInstructionData.map((task) => {
        if (task.id === action?.payload?.id) {
          // If the task has the target ID, update the property
          return {
            ...task,
            status: action?.payload?.data?.status,
            finalTruthWhatActionCanHeTakeOnTaskLikeGivingComment:
              action?.payload?.data
                ?.finalTruthWhatActionCanHeTakeOnTaskLikeGivingComment,
            isCompleted: action?.payload?.data?.isCompleted,
            isDroppedButton: action?.payload?.data?.isDroppedButton,
            isPickedButton: action?.payload?.data?.isPickedButton,
            isCompleteButton: action?.payload?.data?.isCompleteButton,
            modifiedBy: action?.payload?.data?.modifiedBy,
            endDate: action?.payload?.data?.endDate,
          };
        } else {
          // Otherwise, return the original task
          return task;
        }
      });
      state.instructionTasksData = updatedTasksArray;
    },

    // Method to clear instruction form data
    clearTempInstructionData: (state, action) => {
      state.tempInstructionData = {};
    },

    // Method to clear instruction dashboard data
    clearInstructionDashboardData: (state, action) => {
      state.instructionDashboardData = {};
    },
    // Method to update instruction dashboard redux state
    updateInstructionDashboardData: (state, action) => {
      const tempData = JSON.parse(
        JSON.stringify(state?.instructionDashboardData)
      );
      if (action?.payload?.key === "instructionStatus") {
        const updatedData = {
          ...tempData,
          status: action?.payload?.data?.status,
        };
        state.instructionDashboardData = updatedData;
      } else if (action?.payload?.key === "invoice") {
        const tempInvoice = {
          invoiceStatusLookupName:
            action?.payload?.data?.invoice?.invoiceStatusLookupName,
        };
        const updatedData = {
          ...tempData,
          invoice: tempInvoice,
        };
        state.instructionDashboardData = updatedData;
      } else if (action?.payload?.key === "isCommission") {
        const updatedData = {
          ...tempData,
          isIntroducersCommissionApplicable: action?.payload?.data,
        };
        state.instructionDashboardData = updatedData;
      } else {
        state.instructionDashboardData = tempData;
      }
    },
  },
  extraReducers: (builder) => {
    builder

      // Get Instruction thunk
      .addCase(getInstructionType.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(getInstructionType.fulfilled, (state, action) => {
        state.loading = false;
        state.instructionTypeData = action?.payload;
      })

      .addCase(getInstructionType.rejected, (state, action) => {
        state.loading = false;
        state.instructionTypeData = [];
      })
      // Get Instruction Emails thunk
      .addCase(getMailListThunk.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(getMailListThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.emailList = action?.payload?.data;
      })

      .addCase(getMailListThunk.rejected, (state, action) => {
        state.loading = false;
        state.emailList = [];
      })

      // Get All Instructions thunk
      .addCase(getAllInstructions.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(getAllInstructions.fulfilled, (state, action) => {
        state.loading = false;
        state.instructionsData = action?.payload.findAllInstructions;
        state.totalCount = action?.payload?.length;
      })

      .addCase(getAllInstructions.rejected, (state, action) => {
        state.loading = false;
        state.instructionsData = [];
      })

      // Get Dashboard Instructions thunk
      .addCase(getInstructionById.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(getInstructionById.fulfilled, (state, action) => {
        state.loading = false;
        state.instructionDashboardData = action?.payload || {};
      })

      .addCase(getInstructionById.rejected, (state, action) => {
        state.loading = false;
        state.instructionDashboardData = {};
      })
      // update willsuite data thunk
      .addCase(updatewillSuiteThunk.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.case?.api_case_id) {
          state.instructionDashboardData = state.instructionDashboardData
            ? {
                ...state.instructionDashboardData,
                willsuiteCase: action?.payload?.case?.api_case_id,
              }
            : { willsuiteCase: action?.payload?.case?.api_case_id };
        }
      })
      // Get Temp Instructions thunk
      .addCase(getInstructionFormDataById.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(getInstructionFormDataById.fulfilled, (state, action) => {
        state.loading = false;
        state.tempInstructionData = action?.payload || {};
        if (action?.payload?.isJoint) {
          state.isJoint = action?.payload?.isJoint;
          state.arr = [1, 2];
        } else {
          state.isJoint = action?.payload?.isJoint;
          state.arr = [1];
        }
      })

      .addCase(getInstructionFormDataById.rejected, (state, action) => {
        state.loading = false;
        state.instructionsData = {};
      })

      // update Instructions thunk
      .addCase(updateInstruction.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(updateInstruction.fulfilled, (state, action) => {
        state.loading = false;
        // state.tempInstructionData = action?.payload || {};
      })

      .addCase(updateInstruction.rejected, (state, action) => {
        state.loading = false;
      })
      // update updateInstructionProp thunk
      .addCase(updateInstructionProp.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(updateInstructionProp.fulfilled, (state, action) => {
        state.loading = false;
      })

      .addCase(updateInstructionProp.rejected, (state, action) => {
        state.loading = false;
      })

      // Get Instruction Products List thunk
      .addCase(getInstructionProducts.pending, (state, action) => {
        state.instructionProductListLoader = true;
      })

      .addCase(getInstructionProducts.fulfilled, (state, action) => {
        state.instructionProductListLoader = false;
        state.instructionProductList = action?.payload || [];
      })

      .addCase(getInstructionProducts.rejected, (state, action) => {
        state.instructionProductListLoader = false;
        state.instructionProductList = [];
      })

      // Get Instructions task by Product ID List thunk
      .addCase(getInstructionProductsTaskById.pending, (state, action) => {
        state.instructionTaskLoader = true;
      })

      .addCase(getInstructionProductsTaskById.fulfilled, (state, action) => {
        state.instructionTaskLoader = false;
        state.instructionTasksData = action?.payload || [];
      })

      .addCase(getInstructionProductsTaskById.rejected, (state, action) => {
        state.instructionTaskLoader = false;
        state.instructionTasksData = [];
      })

      // Get Generic Instructions task by Instruction ID List thunk
      .addCase(getGenericInstructionTasks.pending, (state, action) => {
        state.instructionTaskLoader = true;
      })

      .addCase(getGenericInstructionTasks.fulfilled, (state, action) => {
        state.instructionTaskLoader = false;
        state.instructionTasksData = action?.payload || [];
      })

      .addCase(getGenericInstructionTasks.rejected, (state, action) => {
        state.instructionTaskLoader = false;
        state.instructionTasksData = [];
      })

      // Get Duplicate Task thunk
      .addCase(getIndividualTasks.pending, (state, action) => {
        state.individualTaskLoader = true;
      })

      .addCase(getIndividualTasks.fulfilled, (state, action) => {
        state.individualTaskLoader = false;
        state.individualTaskData = action?.payload || [];
      })

      .addCase(getIndividualTasks.rejected, (state, action) => {
        state.individualTaskLoader = false;
        state.individualTaskData = [];
      })

      // Get Custom Task thunk
      .addCase(getCustomTasks.pending, (state, action) => {
        state.customTaskLoader = true;
      })

      .addCase(getCustomTasks.fulfilled, (state, action) => {
        state.customTaskLoader = false;
        state.customTaskData = action?.payload || [];
      })

      .addCase(getCustomTasks.rejected, (state, action) => {
        state.customTaskLoader = false;
        state.customTaskData = [];
      });
  },
});
export const {
  updateTempInstructionData,
  updateTaskCompleteAndPick,
  clearTempInstructionData,
  clearInstructionDashboardData,
  updateInstructionDashboardData,
} = instructionSlice.actions;
export default instructionSlice.reducer;
