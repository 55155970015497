import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updateInstruction } from "../../../../../thunks/instruction";
import PropertyItem from "./PropertyItem";

const DecOfTrustQuestionnaireForm = () => {
  const dispatch = useDispatch();
  const instructionDataObject = useSelector(
    (state) => state?.instruction?.tempInstructionData
  );
  const isJoint = instructionDataObject?.isJoint;

  let decOfTrustData =
    instructionDataObject?.InstructionDecOfTrustQuestionnaire || [];

  const methods = useFormContext();
  const { control } = methods;

  const clientArray = useFieldArray({
    control,
    name: "InstructionDecOfTrustQuestionnaire",
  });

  const properties = [];

  const handleSave = (data) => {
    const updatedData = {
      InstructionData: {
        ...instructionDataObject,
        InstructionDecOfTrustQuestionnaire: data.properties,
      },
    };
    const res = dispatch(updateInstruction(updatedData));
  };
  const handleErrors = (errors) => {};

  return (
    <>
      <Grid
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        width={"100%"}
        mt={7}
      >
        {/* <FormHeader
          arr={arr}
          formName={formNames.declarationOfTrustQuestionnaire}
        /> */}
        <Grid width={"100%"} mb={4}>
          {decOfTrustData?.length ? (
            decOfTrustData?.map((property, index) => {
              return (
                <Accordion
                  key={index}
                  elevation={0}
                  style={{
                    borderBottom: "1px solid #DCDCE1",
                    width: "100%",
                    justifyContent: "center",
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <AccordionSummary
                    style={{
                      width: "100%",
                      padding: "10px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid width={"100%"} display={"flex"} flexDirection={"row"}>
                      <span
                        className="serifBold22"
                        style={{
                          width: "100%",
                          fontWeight: "bold",
                          color: "#3D4740",
                        }}
                      >
                        {`Property ${index + 1} / `}
                        <span
                          className="serifBold22"
                          style={{
                            width: "100%",
                            fontStyle: "italic",
                            marginRight: "auto",
                            color: "#3D4740",
                          }}
                        >
                          {property?.property}
                        </span>
                      </span>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <PropertyItem isJoint={isJoint} propertyIndex={index} />
                  </AccordionDetails>
                </Accordion>
                // </Grid>
              );
            })
          ) : (
            <Typography p={3}>No data available</Typography>
          )}
        </Grid>
        {/* <Grid width={"100%"} textAlign={"right"} mt={5}>
          <SubmitButton onClick={handleSubmit(handleSave, handleErrors)} />
        </Grid> */}
      </Grid>
    </>
  );
};

export default DecOfTrustQuestionnaireForm;
