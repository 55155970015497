import { createSlice } from "@reduxjs/toolkit";
import { getLoginUser, userLogin, xeroPay } from "../thunks/auth";

const authSlice = createSlice({
  name: "authDetails",
  initialState: {
    loading: false,
    currentUser: null,
    isUser: false,
  },
  reducers: {
    updateEmailSignature: (state, action) => {
      state.currentUser.emailSignature = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder

      // User Login slice
      .addCase(userLogin.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(userLogin.fulfilled, (state, action) => {
        state.loading = false;
      })

      .addCase(userLogin.rejected, (state, action) => {
        state.loading = false;
      })

      // Get Login User thunk
      .addCase(getLoginUser.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(getLoginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.currentUser = action.payload;
        state.isUser = true;
      })

      .addCase(getLoginUser.rejected, (state, action) => {
        state.loading = false;
        state.isUser = false;
      })

      // XERO Pay thunk
      .addCase(xeroPay.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(xeroPay.fulfilled, (state, action) => {
        state.loading = false;
      })

      .addCase(xeroPay.rejected, (state, action) => {
        state.loading = false;
      });
  },
});
export const { updateEmailSignature } = authSlice.actions;
export default authSlice.reducer;
