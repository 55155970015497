import { createAsyncThunk } from "@reduxjs/toolkit";
import getAxios from "../helpers/axiosInterceptor";
import { API_ROUTES_PATHS } from "../utils/constant";

//Method to get All Clients API
export const getClients = createAsyncThunk(
  "lead/getClients",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(
        API_ROUTES_PATHS.GET_ALL_LEAD_CLIENT,
        model
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get client by ID API
export const getClientById = createAsyncThunk(
  "clientUser/getClientById",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.GET_CLIENT_BY_ID}/${model}/leadclient?isActiveAuthorizedPerson=true`
      );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);
// Method to get client by ID API
export const getClientAuthorizedPeople = createAsyncThunk(
  "clientUser/getClientAuthorizedPeople",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.GET_CLIENT_BY_ID}/${model}/leadclient?isActiveAuthorizedPerson=false`
      );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to create new client API
export const createClient = createAsyncThunk(
  "client/createClient",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(API_ROUTES_PATHS.CREATE_CLIENT, model);

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to Update existing client API
export const updateClient = createAsyncThunk(
  "client/updateClient",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().patch(
        `${API_ROUTES_PATHS.UPDATE_CLIENT}/${model?.id}`,
        model
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get client API for create meeting screen.
export const getClientsForMeeting = createAsyncThunk(
  "client/getClientsForMeeting",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.GET_CLIENTS_FOR_MEETING}`
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to create meeting API
export const createMeeting = createAsyncThunk(
  "client/createMeeting",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(API_ROUTES_PATHS.CREATE_MEETING, model);

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to create multiple clients API by import excel functionality
export const createMultipleClients = createAsyncThunk(
  "client/createMultipleClients",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(
        `${API_ROUTES_PATHS.IMPORT_CLIENT}`,
        model
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get all meetings API
export const getAllMeetings = createAsyncThunk(
  "client/getAllMeetings",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.GET_ALL_MEETINGS}/leadclientmeetings`,
        { params: model?.query }
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to delete client API
export const deleteClient = createAsyncThunk(
  "client/deleteClient",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().delete(
        `${API_ROUTES_PATHS.DELETE_CLIENT}/${model}`
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to create note for client API
export const createNote = createAsyncThunk(
  "client/createNote",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(
        `${API_ROUTES_PATHS.CREATE_NOTE}/${model?.id}/note`,
        model
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get all note for client API --> Dashboard
export const getAllNotes = createAsyncThunk(
  "client/getAllNotes",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(
        `${API_ROUTES_PATHS.GET_ALL_NOTES}/myClientsNotes`,
        model
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get all client communication activity API
export const getClientCommunication = createAsyncThunk(
  "client/getClientCommunication",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.LEAD_CLIENT}/${model?.id}${
          API_ROUTES_PATHS.ACTIVITY
        }?page=${model?.page || 1}`
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get all note for client API --> Dashboard
export const getNotesByClientId = createAsyncThunk(
  "client/getNotesByClientId",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.GET_NOTES_BY_CLIENT_ID}/${model}/getallnotes`
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to update consultant's meeting show/no-show status
export const updateMeetingShow = createAsyncThunk(
  "client/updateMeetingShow",
  async (model, thunkApi) => {
    try {
      let res = model?.isShow
        ? await getAxios().patch(
            `${API_ROUTES_PATHS.CALENDLY}/${model.meetingId}/${API_ROUTES_PATHS.MEETING_INVITEE_NO_SHOW_DELETE}`
          )
        : await getAxios().post(
            `${API_ROUTES_PATHS.CALENDLY}/${model.meetingId}/${API_ROUTES_PATHS.MEETING_INVITEE_SHOW}`
          );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to send Mail API
export const sendClientMailThunk = createAsyncThunk(
  "client/sendClientMailThunk",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(
        `${API_ROUTES_PATHS.LEAD_CLIENT}/${model?.clientId}/send_mail`,
        model
      );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get all mail API
export const getClientMailListThunk = createAsyncThunk(
  "client/getClientMailListThunk",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.GET_CLIENT_EMAILS}?leadClientId=${model.id}`
      );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to Get client Documents As Per Document Type
export const getClientDocumentsAsPerDocumentType = createAsyncThunk(
  "client/getClientDocumentsAsPerDocumentType",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.LEAD_CLIENT}/${model?.clientId}/documentsByDocumentsType`,
        { params: model?.query }
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);
//Method to create tasks on client dashboard
export const createClientTask = createAsyncThunk(
  "client/createClientTask",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(`${API_ROUTES_PATHS.CLIENT_TASK}`, model);
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);
//Method to create tasks on client dashboard
export const getClientTask = createAsyncThunk(
  "client/getClientTask",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.CLIENT_TASK}/${model}/customTasks`
      );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to allocate or complete Task (Instruction Dashboard)
export const allocateOrCompleteClientTask = createAsyncThunk(
  "client/allocateOrCompleteClientTask",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().patch(
        `${API_ROUTES_PATHS.CLIENT_TASK}/${model?.id}/changeStatusOfIndividualTask`,
        model
      );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

/* Method to update instruction task answers API */
export const updateClientTaskAnswer = createAsyncThunk(
  "client/updateClientTaskAnswer",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().patch(
        `${API_ROUTES_PATHS.CLIENT_TASK}/${model?.id}/individualLeadClientTasks`,
        model?.tempData
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

/* GEt Duplicate Task for instructions*/
export const updateClientTaskDueDate = createAsyncThunk(
  "client/updateClientTaskDueDate",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().patch(
        `${API_ROUTES_PATHS.LEAD_CLIENT_TASK}/${model?.id}/pushDueDate`,
        { dueDate: model?.dueDate, note: model?.note }
      );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

/* Get individual task due date history in due date dialog*/
export const getClientTaskDueDateHistory = createAsyncThunk(
  "client/getClientTaskDueDateHistory",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.LEAD_CLIENT_TASK}/${model}/dueDateHistory`
      );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get clients task answer history API
export const getClientTaskAnswerHistory = createAsyncThunk(
  "client/getClientTaskAnswerHistory",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.CLIENT_TASK}/${model}/documents`
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

export const getClientsUserForAssignTask = createAsyncThunk(
  "client/getUserForAssignTask",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.LEAD_CLIENT_TASK}/${model}/officeAdminsAndOwner`
      );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

export const getClientAnniversaries = createAsyncThunk(
  "client/getClientAnniversaries",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.LEAD_CLIENT}/nextAnniveries?page=${
          model?.page || 1
        }&limit=${model?.limit || 50}&weekOffset=${model?.weekOffset || 0}${
          model?.monthDirection
            ? `&monthDirection=${model?.monthDirection}`
            : ""
        }`
      );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to link/unlink client note/email with instruction
export const linkClientCommunication = createAsyncThunk(
  "client/linkClientCommunication",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().patch(
        `${API_ROUTES_PATHS.UPDATE_CLIENT}/${model?.id}${API_ROUTES_PATHS.LINK_CLIENT_COMMUNICATION_TO_INSTRUCTION}`,
        model
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

export const getPinnedClientCommunication = createAsyncThunk(
  "client/getPinnedClientCommunication",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.INSTRUCTION_ROUTE}/${model?.id}/linkedData?regarding=${model?.regardingType}`
      );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);
